import { Checkbox } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import MyLocation from "@material-ui/icons/LocationOn";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import PrintDialog from "../../dialogs/PrintDialog";
import { round } from "../../helpers/TaskRunners";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
    [theme.breakpoints.down("md")]: {
      width: "calc(100vw - 45px)",
    },
    [theme.breakpoints.up("md")]: {
      width: "auto",
    },
  },
  table: {
    minWidth: 700,
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  actionsContainer: {
    marginLeft: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  resetContainer: {
    padding: theme.spacing.unit * 3,
  },
  progress: {
    margin: theme.spacing.unit * 2,
    marginLeft: "20px",
  },
  mainContainer: {
    [theme.breakpoints.down("md")]: {
      width: "calc(100vw - 45px)",
    },
    [theme.breakpoints.up("md")]: {
      width: "auto",
    },
  },
});

class Pay1 extends React.Component {
  language = {
    PrintNotification: {
      FR: (
        <span>
          Veuillez prendre tous vos colis internationaux{" "}
          <u> (la destination est à l'extérieur du Canada) </u> et les placer
          dans une grande boîte. Saisissez les nouvelles dimensions et le poids
          de la grande boîte ci-dessous.
        </span>
      ),
      EN: (
        <strong>
          Have the selected dropoff location print
          <br />
          your labels{" "}
          {this.props.account &&
          this.props.account.user &&
          this.props.account.user.fixedDropoff
            ? ""
            : "($1.00 fee per label + applicable HST)"}
        </strong>
      ),
    },
    Receiver: {
      FR: "Le Destinataire",
      EN: "Receiver",
    },
    Origin: {
      FR: "Origine",
      EN: "Origin",
    },
    Mode: {
      FR: "Mode",
      EN: "Mode",
    },
    Pickup: {
      FR: "Ramasser",
      EN: "Pickup",
    },
    Cost: {
      FR: "Coût",
      EN: "Cost",
    },
    Attn: {
      FR: "Attn",
      EN: "Attn",
    },
    Dropoff: {
      FR: "Déposer",
      EN: "Dropoff",
    },
    FuelSurcharge: {
      FR: "Surcharge d'essence",
      EN: "Fuel Surcharge",
    },
    ExtraCare: {
      FR: "Soin supplémentaire",
      EN: "Extra Care",
    },
    ExtendedArea: {
      FR: "Zone étendue",
      EN: "Extended Area",
    },
    RuralArea: {
      FR: "Zone rurale",
      EN: "Rural Area",
    },
    ResidentialCharge: {
      FR: "Frais de résidence",
      EN: "Residential Charge",
    },
    Base: {
      FR: "Frais de base",
      EN: "Base",
    },
    InternationalPickupBundle: {
      FR: "Pack de ramassage international",
      EN: "International Pickup Bundle",
    },
    package: {
      FR: "paquet",
      EN: "package",
    },
    Total: {
      FR: "Total",
      EN: "Total",
    },
    Back: {
      FR: "Arrière",
      EN: "Back",
    },
    Continue: {
      FR: "Continuer",
      EN: "Continue",
    },
  };

  UNSAFE_componentWillMount() {
    let test = this.props.shipmentList
      .filter((e) => this.props.selectedShipments.indexOf(e.id) > -1)
      .map((e) => Number(e.rate.amount));
    let total = test.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );

    if (this.props.tab === 0 && this.props.hasUS) {
      if (this.props.printed) {
        total =
          Number(total) +
          this.props.shipmentList
            .filter((e) => this.props.selectedShipments.indexOf(e.id) > -1)
            .map((e) => Number(e.package.entered_pld.length) * 1.13)
            .reduce((a, b) => a + b, 0) +
          Number(this.props.bundleRate);
        this.props.setData("runningTotal", this.roundTo(total, 2));
        return;
      } else {
        this.props.setData(
          "runningTotal",
          this.roundTo(Number(total) + Number(this.props.bundleRate), 2)
        );
        return;
      }
    } else {
      if (this.props.printed) {
        total =
          Number(total) +
          this.props.shipmentList
            .filter((e) => this.props.selectedShipments.indexOf(e.id) > -1)
            .map((e) => Number(e.package.entered_pld.length) * 1.13)
            .reduce((a, b) => a + b, 0);
      }
      this.props.setData("runningTotal", this.roundTo(total, 2));
    }
  }

  roundTo = (n, digits) => {
    var negative = false;
    if (digits === undefined) {
      digits = 0;
    }
    if (n < 0) {
      negative = true;
      n = n * -1;
    }
    var multiplicator = Math.pow(10, digits);
    n = parseFloat((n * multiplicator).toFixed(11));
    n = (Math.round(n) / multiplicator).toFixed(2);
    if (negative) {
      n = (n * -1).toFixed(2);
    }
    return n;
  };

  state = {
    runningTotal: 0,
    checked: false,
    language: getCookie("language") || "EN",
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
    this.props.setData("printed", event.target.checked);
    if (event.target.checked) {
      let temp = this.props.runningTotal;
      temp =
        Number(temp) +
        this.props.shipmentList
          .filter((e) => this.props.selectedShipments.indexOf(e.id) > -1)
          .map((e) => Number(e.package.entered_pld.length) * 1.13)
          .reduce((a, b) => a + b, 0);
      this.props.setData("runningTotal", temp.toFixed(2));
    } else {
      let temp = this.props.runningTotal;
      temp =
        Number(temp) -
        this.props.shipmentList
          .filter((e) => this.props.selectedShipments.indexOf(e.id) > -1)
          .map((e) => Number(e.package.entered_pld.length) * 1.13)
          .reduce((a, b) => a + b, 0);
      this.props.setData("runningTotal", temp.toFixed(2));
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Grow in={true}>
        <Paper className={classes.root}>
          <PrintDialog
            open={
              this.props.account &&
              this.props.account.user &&
              this.props.account.user.fixedDropoff
                ? false
                : true
            }
            setData={this.props.setData}
            printed={this.props.printed}
            tab={this.props.tab}
            total={this.props.runningTotal}
            count={this.props.shipmentList
              .filter((e) => this.props.selectedShipments.indexOf(e.id) > -1)
              .map((e) => Number(e.package.entered_pld.length))
              .reduce((a, b) => a + b, 0)}
          />
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>
                  {this.language.Receiver[this.state.language]}
                </TableCell>
                <TableCell>
                  {this.language.Origin[this.state.language]}
                </TableCell>
                <TableCell>{this.language.Mode[this.state.language]}</TableCell>
                <TableCell style={{ textAlign: "right" }}>
                  {this.language.Cost[this.state.language]}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.shipmentList
                .filter((e) => this.props.selectedShipments.indexOf(e.id) > -1)
                .map((e) => {
                  return (
                    <TableRow key={e.id}>
                      <TableCell scope="row">
                        <br />
                        {e.receiver.contact.title}
                        <br />
                        {this.language.Attn[this.state.language]}:{" "}
                        {e.receiver.contact.fname} {e.receiver.contact.lname}
                        <br />
                        {"unit" in e.receiver
                          ? `#${e.receiver.address.unit} - `
                          : ""}
                        {e.receiver.address.street_number
                          ? `${e.receiver.address.street_number} `
                          : ""}
                        {e.receiver.address.street_name}
                        <br />
                        {e.receiver.address.city}, {e.receiver.address.state}
                        <br />
                        {e.receiver.address.postal} {e.receiver.address.country}
                        <br />
                        <br />
                      </TableCell>
                      <TableCell>
                        {e.sender.contact.title}
                        <br />
                        {this.language.Attn[this.state.language]}:{" "}
                        {e.sender.contact.fname} {e.sender.contact.lname}
                        <br />
                        {"unit" in e.sender
                          ? `#${e.sender.address.unit} - `
                          : ""}
                        {e.sender.address.street_number
                          ? `${e.sender.address.street_number} `
                          : ""}
                        {e.sender.address.street_name}
                        <br />
                        {e.sender.address.city}, {e.sender.address.state}
                        <br />
                        {e.sender.address.postal} {e.sender.address.country}
                      </TableCell>
                      <TableCell>
                        {this.props.tab === 1 ? (
                          <span>
                            {this.language.Dropoff[this.state.language]}
                            <br />
                            <MyLocation style={{ fontSize: "10px" }} />{" "}
                            {this.props &&
                              this.props.dropoffObj &&
                              this.props.dropoffObj.address}
                          </span>
                        ) : (
                          this.language.Pickup[this.state.language]
                        )}
                        <br />
                        {e.package.entered_pld.length}{" "}
                        {this.language.package[this.state.language]}
                        {e.package.entered_pld.length > 1 ? "s" : ""}
                      </TableCell>
                      <TableCell>
                        <strong>
                          <p style={{ textAlign: "right" }}>
                            {" "}
                            $ {e.rate.amount}
                            {e.receiver.address.country === "CA" && (
                              <p style={{ color: "grey" }}>
                                <small>
                                  {this.language.Base[this.state.language]}: $
                                  {round(e.rate.subtotal)} CAD
                                  <br />
                                  {e.rate.accessorial_1_name ? (
                                    <>
                                      {e.rate.accessorial_1_name}: $
                                      {round(e.rate.accessorial_1_charge)} CAD
                                      <br />
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {e.rate.accessorial_2_name ? (
                                    <>
                                      {e.rate.accessorial_2_name}: $
                                      {round(e.rate.accessorial_2_charge)} CAD
                                      <br />
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {e.rate.accessorial_3_name ? (
                                    <>
                                      {e.rate.accessorial_3_name}: $
                                      {round(e.rate.accessorial_3_charge)} CAD
                                      <br />
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {e.rate.accessorial_4_name ? (
                                    <>
                                      {e.rate.accessorial_4_name}: $
                                      {round(e.rate.accessorial_4_charge)} CAD
                                      <br />
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {e.rate.fuel_surcharge > 0 ? (
                                    <Fragment>
                                      {
                                        this.language.FuelSurcharge[
                                          this.state.language
                                        ]
                                      }
                                      : ${round(e.rate.fuel_surcharge)} CAD
                                      <br />
                                    </Fragment>
                                  ) : (
                                    ``
                                  )}
                                  {e.rate.xc_charge > 0 ? (
                                    <Fragment>
                                      {
                                        this.language.ExtraCare[
                                          this.state.language
                                        ]
                                      }
                                      : ${round(e.rate.xc_charge)} CAD
                                      <br />
                                    </Fragment>
                                  ) : (
                                    ``
                                  )}
                                  {e.rate.ea_charge > 0 ? (
                                    <Fragment>
                                      {
                                        this.language.ExtendedArea[
                                          this.state.language
                                        ]
                                      }
                                      : ${round(e.rate.ea_charge)} CAD
                                      <br />
                                    </Fragment>
                                  ) : (
                                    ``
                                  )}
                                  {e.rate.rural_charge > 0 ? (
                                    <Fragment>
                                      {
                                        this.language.RuralArea[
                                          this.state.language
                                        ]
                                      }
                                      : ${round(e.rate.rural_charge)} CAD
                                      <br />
                                    </Fragment>
                                  ) : (
                                    ``
                                  )}
                                  {e.rate.residential_charge > 0 ? (
                                    <Fragment>
                                      {
                                        this.language.ResidentialCharge[
                                          this.state.language
                                        ]
                                      }
                                      : ${round(e.rate.residential_charge)} CAD
                                      <br />
                                    </Fragment>
                                  ) : (
                                    ``
                                  )}
                                  {e.rate.tax1_code ? (
                                    <Fragment>
                                      {e.rate.tax1_code} (
                                      {Number(e.rate.tax1_rate) * 100}%): $
                                      {round(e.rate.tax1_amount)} CAD
                                      <br />
                                    </Fragment>
                                  ) : (
                                    ``
                                  )}
                                  {e.rate.tax2_code ? (
                                    <Fragment>
                                      {e.rate.tax2_code} (
                                      {Number(e.rate.tax2_rate) * 100}%): $
                                      {round(e.rate.tax2_amount)} CAD
                                      <br />
                                    </Fragment>
                                  ) : (
                                    ``
                                  )}
                                </small>
                              </p>
                            )}
                          </p>
                        </strong>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {this.props.tab === 1 ? (
                <TableRow key={99998}>
                  <TableCell scope="row" />
                  <TableCell />
                  <TableCell>
                    <br />
                    {this.language.PrintNotification[this.state.language]}
                    <br />
                    <br />
                  </TableCell>
                  <TableCell style={{ textAlign: "right" }}>
                    <Checkbox
                      checked={
                        this.props.account.user.fixedDropoff
                          ? 1
                          : this.props.printed
                      }
                      onChange={
                        this.props.account.user.fixedDropoff
                          ? () => {}
                          : this.handleChange("checked")
                      }
                      value="checked"
                      color="primary"
                    />
                  </TableCell>
                </TableRow>
              ) : this.props.hasUS ? (
                <TableRow key={99997}>
                  <TableCell scope="row">
                    <br />
                    Swiftpost
                    <br />
                    2130 S Service Rd W
                    <br />
                    Oakville, ON L6L 5N1
                    <br />
                    <br />
                  </TableCell>
                  <TableCell>
                    {this.props.senderLocation.title}
                    <br />
                    {this.props.senderLocation.unit !== null &&
                    this.props.senderLocation.unit !== undefined
                      ? `${this.props.senderLocation.unit} - `
                      : ``}
                    {this.props.senderLocation.address.street_number
                      ? `${this.props.senderLocation.address.street_number} `
                      : ""}
                    {this.props.senderLocation.address.street_name
                      ? this.props.senderLocation.address.street_name
                      : ""}{" "}
                    {this.props.senderLocation.street}
                    <br />
                    {this.props.senderLocation.address.city},{" "}
                    {this.props.senderLocation.address.state}{" "}
                    {this.props.senderLocation.address.postal}
                  </TableCell>
                  <TableCell>
                    {
                      this.language.InternationalPickupBundle[
                        this.state.language
                      ]
                    }
                    <br />
                    {this.props.entered_pld.length}{" "}
                    {this.language.package[this.state.language]}
                    {this.props.entered_pld.length > 1 ? "s" : ""}
                  </TableCell>
                  <TableCell style={{ textAlign: "right" }}>
                    <br />
                    <strong>
                      ${this.props.bundleRate}
                      <p style={{ color: "grey" }}>
                        <small>
                          {this.language.Base[this.state.language]}: $
                          {this.props.fullBundleRate.subtotal} CAD
                          <br />
                          {this.props.fullBundleRate.fuel_surcharge > 0 ? (
                            <Fragment>
                              {this.language.FuelSurcharge[this.state.language]}
                              : $
                              {round(this.props.fullBundleRate.fuel_surcharge)}{" "}
                              CAD
                              <br />
                            </Fragment>
                          ) : (
                            ``
                          )}
                          {this.props.fullBundleRate.xc_charge > 0 ? (
                            <Fragment>
                              {this.language.ExtraCare[this.state.language]}: $
                              {round(this.props.fullBundleRate.xc_charge)} CAD
                              <br />
                            </Fragment>
                          ) : (
                            ``
                          )}
                          {this.props.fullBundleRate.ea_charge > 0 ? (
                            <Fragment>
                              {this.language.ExtendedArea[this.state.language]}:
                              ${round(this.props.fullBundleRate.ea_charge)} CAD
                              <br />
                            </Fragment>
                          ) : (
                            ``
                          )}
                          {this.props.fullBundleRate.rural_charge > 0 ? (
                            <Fragment>
                              {this.language.RuralArea[this.state.language]}: $
                              {round(this.props.fullBundleRate.rural_charge)}{" "}
                              CAD
                              <br />
                            </Fragment>
                          ) : (
                            ``
                          )}
                          {this.props.fullBundleRate.residential_charge > 0 ? (
                            <Fragment>
                              {
                                this.language.ResidentialCharge[
                                  this.state.language
                                ]
                              }
                              : $
                              {round(
                                this.props.fullBundleRate.residential_charge
                              )}{" "}
                              CAD
                              <br />
                            </Fragment>
                          ) : (
                            ``
                          )}
                          {this.props.fullBundleRate.tax1_code ? (
                            <Fragment>
                              {this.props.fullBundleRate.tax1_code} (
                              {Number(this.props.fullBundleRate.tax1_rate) *
                                100}
                              %): $
                              {round(this.props.fullBundleRate.tax1_amount)} CAD
                              <br />
                            </Fragment>
                          ) : (
                            ``
                          )}
                          {this.props.fullBundleRate.tax2_code ? (
                            <Fragment>
                              {this.props.fullBundleRate.tax2_code} (
                              {Number(this.props.fullBundleRate.tax2_rate) *
                                100}
                              %): $
                              {round(this.props.fullBundleRate.tax2_amount)} CAD
                              <br />
                            </Fragment>
                          ) : (
                            ``
                          )}
                        </small>
                      </p>
                    </strong>
                  </TableCell>
                </TableRow>
              ) : (
                ""
              )}
              <TableRow key={99999}>
                <TableCell scope="row" />
                <TableCell />
                <TableCell style={{ fontSize: 20, textAlign: "left" }}>
                  <br />
                  <strong>{this.language.Total[this.state.language]}</strong>
                  <br />
                  <br />
                </TableCell>
                <TableCell style={{ fontSize: 20, textAlign: "right" }}>
                  <strong>${round(this.props.runningTotal)}</strong>
                </TableCell>
              </TableRow>
            </TableBody>
            <br />
            <div className={classes.actionsContainer}>
              <div>
                <Button
                  onClick={this.props.handleBack}
                  className={classes.button}
                >
                  {this.language.Back[this.state.language]}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    this.props.handleNext();
                  }}
                  className={classes.button}
                >
                  {this.language.Continue[this.state.language]}
                </Button>
              </div>
            </div>
          </Table>
        </Paper>
      </Grow>
    );
  }
}

Pay1.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Pay1);
