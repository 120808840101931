import {
  Button,
  Chip,
  Hidden,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { getLanguage, Language } from "helpers/language";
import React, { ChangeEvent, Fragment, MouseEvent } from "react";
import { Link } from "react-router-dom";
import AddFundsDialog from "../dialogs/AddFundsDialog";
import { round } from "../helpers/TaskRunners";
import { isDollarValue } from "../helpers/Validation";

export type Card = {
  exp: string;
  pan: string;
  token: string;
  type: string;
};

export type PaymentMethod = {
  id: string;
  card: Card;
  title: string;
};

class BalanceButton extends React.Component<
  {
    account: {
      user: {
        email: string;
      };
    };
    addPayment: () => void;
    balance: {
      balance: number;
    };
    checkQualify: () => void;
    createSnackBar: (succesMessage: string, doneMessage: string) => void;
    diff: number;
    handlePaymentChange: (paymentMethod: PaymentMethod) => void;
    onAppBar?: number;
    paymentmethods: PaymentMethod[];
    qualify?: () => void;
  },
  {
    activeStep: number;
    anchorEl: EventTarget | null;
    enteredValue: string | number;
    language: Language;
    open: boolean;
    selectedPayment: string | boolean;
    snackOpen: boolean;
    value: string;
  }
> {
  state = {
    activeStep: 0,
    anchorEl: null,
    enteredValue: "",
    language: getLanguage(),
    open: false,
    selectedPayment: false,
    snackOpen: false,
    value: "25",
  };

  language = {
    Balance: {
      FR: "Balance",
      EN: "Balance",
    },
    AddFunds: {
      FR: "Ajouter des fonds",
      EN: "Add Funds",
    },
    RecentActivity: {
      FR: "Transactions récentes",
      EN: "Recent Activity",
    },
  };

  handleClickOpen = () => {
    let value;
    this.props.diff > 25 ? (value = "50") : (value = "25");
    this.props.diff > 50 ? (value = "100") : (value = "50");
    this.props.diff > 100 ? (value = "other") : (value = "100");
    this.setState({
      open: true,
      activeStep: 0,
      selectedPayment: false,
      value: value,
      enteredValue: this.props.diff ? this.props.diff : "",
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleNext = async () => {
    if (this.state.activeStep === 0) {
      this.setState({
        enteredValue: Number(this.state.enteredValue),
      });
    }
    this.setState((prevState) => ({
      activeStep: prevState.activeStep + 1,
    }));
  };

  handleMenu = (event: MouseEvent<HTMLInputElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handlePaymentChange = (e: PaymentMethod) => {
    this.setState({ selectedPayment: e.id });
  };

  handleBack = () => {
    if (this.state.activeStep === 0) {
      return;
    }
    this.setState((prevState) => ({
      activeStep: prevState.activeStep - 1,
    }));
  };

  handleChange = (_: ChangeEvent<{}>, value: string) => {
    this.setState({
      value,
    });
  };

  handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "") {
      this.setState({
        enteredValue: event.target.value,
      });
      return;
    }
    if (!isDollarValue(event.target.value) || +event.target.value > 20000) {
      return;
    }
    this.setState({
      enteredValue: event.target.value,
    });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <div style={{ display: "inline" }}>
        {"onAppBar" in this.props ? (
          this.props.onAppBar === 1 ? (
            <Fragment>
              <Button
                aria-haspopup="true"
                aria-owns={open ? "topup-appbar" : undefined}
                color="inherit"
                onClick={this.handleMenu}
              >
                <Hidden xsDown>
                  <small>
                    {this.language.Balance[this.state.language]}&nbsp;&nbsp;
                  </small>
                </Hidden>
                ${round(this.props.balance.balance)}
              </Button>
              <Menu
                id="topup-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                open={open}
                onClose={this.handleMenuClose}
              >
                <MenuItem
                  onClick={() => {
                    this.handleClickOpen();
                    this.handleMenuClose();
                  }}
                >
                  {this.language.AddFunds[this.state.language]}
                </MenuItem>
                <Link to={"/payments"}>
                  <MenuItem onClick={this.handleMenuClose}>
                    {this.language.RecentActivity[this.state.language]}
                  </MenuItem>
                </Link>
              </Menu>
            </Fragment>
          ) : (
            <span>
              {this.language.Balance[this.state.language]}:{" "}
              <Chip
                color="secondary"
                label={`$${round(this.props.balance.balance)} CAD `}
                onClick={this.handleClickOpen}
              />
            </span>
          )
        ) : (
          <Typography variant="h4">
            <span>${round(this.props.balance.balance)}</span> <small>CAD</small>
            <Button
              style={{ marginLeft: "15px" }}
              variant="outlined"
              color="primary"
              onClick={this.handleClickOpen}
            >
              {this.language.AddFunds[this.state.language]}
            </Button>
          </Typography>
        )}

        <AddFundsDialog
          {...this.state}
          openModal={this.props.createSnackBar}
          checkQualify={this.props.checkQualify}
          account={this.props.account}
          balance={this.props.balance}
          handleClose={this.handleClose}
          handleNext={this.handleNext}
          handleChange={this.handleChange}
          handleTextChange={this.handleTextChange}
          handleBack={this.handleBack}
          handlePaymentChange={this.handlePaymentChange}
          addPayment={this.props.addPayment}
          diff={this.props.diff}
          paymentmethods={this.props.paymentmethods}
        />
      </div>
    );
  }
}

export default BalanceButton;
