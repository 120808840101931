import { API } from "aws-amplify";

const listShipments = (
  page,
  limit,
  country,
  status,
  courier,
  search,
  isParent,
  cognitoSub,
  platform
) =>
  new Promise(async (resolve, reject) => {
    // console.log({
    //   "page": page,
    //   "limit": limit,
    //   "country": country,
    //   "status": status,
    //   "courier": "none",
    //   "search": search ? search : "none"
    // })
    // console.log(page, limit, country, status, courier, search, isParent, cognitoSub, platform);
    let something = await API.post("sqs", "/shipments", {
      body: {
        page: page,
        limit: limit,
        country: country,
        status: status,
        courier: "none",
        search: search ? search : "none",
        isParent: isParent,
        cognitoSub: cognitoSub,
        platform: platform,
      },
    }).catch((e) => listShipments(page, limit, country, status, courier));
    // console.log(something)
    resolve(something);
  });

export default listShipments;
