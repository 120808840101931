import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { AddressBookRecord } from "@shared";
import { useAddressBook } from "providers/AddressBook";
import { useEffect, useState } from "react";
import { fullFormattedAddress } from "./helpers";

const styles = (theme: Theme) => ({});

export function DeleteAddressConfirmationDialog({
  address,
  open = false,
  onClose,
  classes,
}: {
  address: AddressBookRecord;
  open?: boolean;
  onClose?: () => void;
  classes?: any;
}) {
  const { deleteEntry } = useAddressBook();
  const [isOpen, setIsOpen] = useState(open);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
    onClose?.();
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    await deleteEntry(address.id);
    handleClose();
  };

  useEffect(() => setIsOpen(open), [open]);

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete address?</DialogTitle>
        <DialogContent>
          <div style={{ minWidth: 400, opacity: "70%" }}>
            <p>Confirm deletion of address:</p>
            <strong>{fullFormattedAddress(address)}</strong>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            color="primary"
            autoFocus
            disabled={isDeleting}
          >
            {isDeleting && (
              <CircularProgress
                size={16}
                style={{ marginRight: 12 }}
                color="inherit"
              />
            )}
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withStyles(styles)(DeleteAddressConfirmationDialog);
