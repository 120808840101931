import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import PropTypes from "prop-types";
import React from "react";

class EcommerceInterstitial extends React.Component {
  render() {
    const { fullScreen } = this.props;

    return (
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          {/* <DialogContent> */}
          <DialogTitle>Pull new orders from Etsy?</DialogTitle>
          {/* </DialogContent> */}
          <DialogActions>
            <Button onClick={this.props.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.props.refresh} color="primary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

EcommerceInterstitial.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

export default withMobileDialog()(EcommerceInterstitial);
