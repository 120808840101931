import { FormControl, Input, InputLabel, Select } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { API, graphqlOperation } from "aws-amplify";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import React from "react";
import createToken from "../api/moneris/createToken";
import {
  isNotEmpty,
  isNotInPast,
  isNumericOnly,
  isValidCreditCard,
} from "../helpers/Validation";
//snackbars
import SnackBar from "./Snackbar";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: 300,
  },
  formControl: {
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    minWidth: 120,
  },
});

class FormDialog extends React.Component {
  state = {
    isLoading: false,
    open: false,
    snackMessage: "",
    snackOpen: false,
    year: Number(new Date().getFullYear().toString().substr(-2)),
    month: new Date().getMonth() + 1,
    // email: this.props.user.account[0].email, //TODO, UPDATE
    pan: "",
    masked: "",
    email: this.props.account
      ? this.props.account.user.email
      : alert("ERROR. PLEASE CONTACT SUPPORT."),
    cust_id: this.props.account
      ? this.props.account.user.id
      : alert("ERROR. PLEASE CONTACT SUPPORT."),
    exp: `${Number(new Date().getFullYear().toString().substr(-2))}${(
      "00" + (new Date().getMonth() + 1).toString()
    ).slice(-2)}`,
    // cust_id: this.props.user.account[0].id, //TODO, UPDATE
    note: "",
    street_num: "", //TODO, UPDATE
    street_name: this.props.account
      ? this.props.account.user.address
      : alert("ERROR. PLEASE CONTACT SUPPORT."), //TODO, UPDATE,
    postal: this.props.account
      ? this.props.account.user.postal
      : alert("ERROR. PLEASE CONTACT SUPPORT."), //TODO, UPDATE
    token: "",
    type: "",
  };

  UNSAFE_componentWillMount = () => {
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });
  };

  language = {
    NewPayment: {
      FR: "Nouveau mode de paiement",
      EN: "New Payment",
    },
    PaymentName: {
      FR: "Nom du mode de paiement",
      EN: "Payment Name",
    },
    CardNumber: {
      FR: "Numéro de carte",
      EN: "Card Number",
    },
    Month: {
      FR: "Mois",
      EN: "Month",
    },
    Year: {
      FR: "Année",
      EN: "Year",
    },
    Loading: {
      FR: "Attendre",
      EN: "Loading",
    },
    AddPayment: {
      FR: "Ajouter la carte",
      EN: "Add Payment",
    },
    Cancel: {
      FR: "Annuler",
      EN: "Cancel",
    },
  };

  handleSelectChange = async (event) => {
    await this.setState({ [event.target.name]: event.target.value });
    this.setState({
      exp: `${this.state.year}${("00" + this.state.month.toString()).slice(
        -2
      )}`,
    });
  };

  handleChange = (event) => {
    // console.log("ACC=>",this.props.account)

    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleClose = async () => {
    this.setState({ pan: "" });
    await this.props.handleClose();
  };

  formEmpty = () => {
    return false;
  };

  validateForm = () => {
    this.setState({ type: isValidCreditCard(this.state.pan) });
    return (
      isValidCreditCard(this.state.pan) != "" &&
      isNotEmpty(this.state.pan) &&
      isNotInPast(this.state.month, this.state.year)
    );
  };

  handleSubmit = async () => {
    if (await !this.validateForm()) {
      this.handleClose();
      this.setState({ snackMessage: "Unable to verify information." });
      this.setState({ snackOpen: true });
      return;
    }
    // console.log(this.state.type)
    // console.log(this.props)
    createToken(this.state, this.props.account).then(async (e) => {
      // console.log("DONE!")
      // this.props.addPayment();
      this.setState({ token: e.token });
      this.setState({ pan: this.state.pan.replace(/.(?=.{4,}$)/g, "*") });
      // console.log("CALLING ADDPAYMENT");
      // await this.props.addPayment(this.state, this.props.account);
      await API.graphql(
        graphqlOperation(`
          mutation {
            createV1_PaymentMethod (input: {
              title:"${this.state.alias}"
              card: {
                token: "${this.state.token}"
                pan: "${this.state.pan}"
                year: "${this.state.year}"
                month: "${this.state.month}"
                type: "${this.state.type}"
                exp: "${this.state.exp}"
              }
            }){
              id
              cognitoUsername
            }
          }`)
      );

      this.handleClose();
    });
  };

  handleNumberChange = (event) =>
    isNumericOnly(event.target.value)
      ? this.setState({ [event.target.id]: event.target.value })
      : false;

  render() {
    const { classes } = this.props;
    return (
      <div>
        <SnackBar
          variant="error"
          open={this.state.snackOpen}
          message={this.state.snackMessage}
          handleClose={() => {
            this.setState({ snackOpen: false });
          }}
        />
        <Dialog open={this.props.open} aria-labelledby="form-dialog-title">
          <DialogTitle style={{ maxWidth: 300 }} id="form-dialog-title">
            {this.language.NewPayment[this.state.language]}
          </DialogTitle>
          <DialogContent>
            <DialogContentText></DialogContentText>
            <TextField
              autoFocus
              required
              disabled={this.state.isLoading}
              margin="dense"
              id="alias"
              name="alias"
              label={this.language.PaymentName[this.state.language]}
              type="text"
              fullWidth
              key={5}
              value={this.state.alias}
              onChange={this.handleChange}
            />
            <TextField
              required
              disabled={this.state.isLoading}
              margin="dense"
              id="pan"
              name="pan"
              label={this.language.CardNumber[this.state.language]}
              type="text"
              fullWidth
              key={0}
              value={this.state.pan}
              onChange={this.handleNumberChange}
            />
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="month-native-simple">
                {this.language.Month[this.state.language]}
              </InputLabel>
              <Select
                native
                disabled={this.state.isLoading}
                value={this.state.month}
                onChange={this.handleSelectChange}
                id="month"
                name="month"
                input={<Input id="month-native-simple" />}
              >
                <option value={1}>01</option>
                <option value={2}>02</option>
                <option value={3}>03</option>
                <option value={4}>04</option>
                <option value={5}>05</option>
                <option value={6}>06</option>
                <option value={7}>07</option>
                <option value={8}>08</option>
                <option value={9}>09</option>
                <option value={10}>10</option>
                <option value={11}>11</option>
                <option value={12}>12</option>
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="year-native-simple">
                {this.language.Year[this.state.language]}
              </InputLabel>
              <Select
                native
                disabled={this.state.isLoading}
                value={this.state.year}
                onChange={this.handleSelectChange}
                id="year"
                name="year"
                input={<Input id="year-native-simple" />}
              >
                <option
                  value={Number(new Date().getFullYear().toString().substr(-2))}
                >
                  {Number(new Date().getFullYear().toString().substr(-2))}
                </option>
                <option
                  value={
                    Number(new Date().getFullYear().toString().substr(-2)) + 1
                  }
                >
                  {Number(new Date().getFullYear().toString().substr(-2)) + 1}
                </option>
                <option
                  value={
                    Number(new Date().getFullYear().toString().substr(-2)) + 2
                  }
                >
                  {Number(new Date().getFullYear().toString().substr(-2)) + 2}
                </option>
                <option
                  value={
                    Number(new Date().getFullYear().toString().substr(-2)) + 3
                  }
                >
                  {Number(new Date().getFullYear().toString().substr(-2)) + 3}
                </option>
                <option
                  value={
                    Number(new Date().getFullYear().toString().substr(-2)) + 4
                  }
                >
                  {Number(new Date().getFullYear().toString().substr(-2)) + 4}
                </option>
                <option
                  value={
                    Number(new Date().getFullYear().toString().substr(-2)) + 5
                  }
                >
                  {Number(new Date().getFullYear().toString().substr(-2)) + 5}
                </option>
                <option
                  value={
                    Number(new Date().getFullYear().toString().substr(-2)) + 6
                  }
                >
                  {Number(new Date().getFullYear().toString().substr(-2)) + 6}
                </option>
                <option
                  value={
                    Number(new Date().getFullYear().toString().substr(-2)) + 7
                  }
                >
                  {Number(new Date().getFullYear().toString().substr(-2)) + 7}
                </option>
                <option
                  value={
                    Number(new Date().getFullYear().toString().substr(-2)) + 8
                  }
                >
                  {Number(new Date().getFullYear().toString().substr(-2)) + 8}
                </option>
                <option
                  value={
                    Number(new Date().getFullYear().toString().substr(-2)) + 9
                  }
                >
                  {Number(new Date().getFullYear().toString().substr(-2)) + 9}
                </option>
                <option
                  value={
                    Number(new Date().getFullYear().toString().substr(-2)) + 10
                  }
                >
                  {Number(new Date().getFullYear().toString().substr(-2)) + 10}
                </option>
                <option
                  value={
                    Number(new Date().getFullYear().toString().substr(-2)) + 11
                  }
                >
                  {Number(new Date().getFullYear().toString().substr(-2)) + 11}
                </option>
                <option
                  value={
                    Number(new Date().getFullYear().toString().substr(-2)) + 12
                  }
                >
                  {Number(new Date().getFullYear().toString().substr(-2)) + 12}
                </option>
                <option
                  value={
                    Number(new Date().getFullYear().toString().substr(-2)) + 13
                  }
                >
                  {Number(new Date().getFullYear().toString().substr(-2)) + 13}
                </option>
                <option
                  value={
                    Number(new Date().getFullYear().toString().substr(-2)) + 14
                  }
                >
                  {Number(new Date().getFullYear().toString().substr(-2)) + 14}
                </option>
                <option
                  value={
                    Number(new Date().getFullYear().toString().substr(-2)) + 15
                  }
                >
                  {Number(new Date().getFullYear().toString().substr(-2)) + 15}
                </option>
                <option
                  value={
                    Number(new Date().getFullYear().toString().substr(-2)) + 16
                  }
                >
                  {Number(new Date().getFullYear().toString().substr(-2)) + 16}
                </option>
                <option
                  value={
                    Number(new Date().getFullYear().toString().substr(-2)) + 17
                  }
                >
                  {Number(new Date().getFullYear().toString().substr(-2)) + 17}
                </option>
                <option
                  value={
                    Number(new Date().getFullYear().toString().substr(-2)) + 18
                  }
                >
                  {Number(new Date().getFullYear().toString().substr(-2)) + 18}
                </option>
                <option
                  value={
                    Number(new Date().getFullYear().toString().substr(-2)) + 19
                  }
                >
                  {Number(new Date().getFullYear().toString().substr(-2)) + 19}
                </option>
                <option
                  value={
                    Number(new Date().getFullYear().toString().substr(-2)) + 20
                  }
                >
                  {Number(new Date().getFullYear().toString().substr(-2)) + 20}
                </option>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              {this.language.Cancel[this.state.language]}
            </Button>
            <Button
              disabled={this.state.isLoading}
              onClick={() => {
                this.handleSubmit();
              }}
              color="primary"
            >
              {this.state.isLoading
                ? this.language.Loading[this.state.language]
                : this.language.AddPayment[this.state.language]}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

FormDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FormDialog);
