import axios from "axios";
import { parseStringPromise } from "xml2js";
import { Shipment } from "./shipment";

const userId = "313SWIFT4350";

async function confirmAddress(shipment: Shipment) {
  const { data } = await axios(
    `https://secure.shippingapis.com/ShippingAPI.dll?API=ZipCodeLookup&XML=%3CZipCodeLookupRequest%20USERID=%22${userId}%22%3E%3CAddress%20ID=%221%22%3E%3CAddress2%3E${
      shipment.receiver.address
    }%3C/Address2%3E%3CCity%3E${shipment.receiver.city}%3C/City%3E%3CState%3E${
      shipment.receiver.state
    }%3C/State%3E%3CZip5%3E${
      shipment.receiver.postal
        ? shipment.receiver.postal
        : shipment.receiver.zip.split("-")[0]
    }%3C/Zip5%3E%3CZip4%3E%3C/Zip4%3E%3C/Address%3E%3C/ZipCodeLookupRequest%3E`
  );

  const res = await parseStringPromise(data);

  return "Error" in res || "Error" in res.ZipCodeLookupResponse.Address[0]
    ? { status: false }
    : {
        status: true,
        result: {
          address: res.ZipCodeLookupResponse.Address[0].Address2[0],
          city: res.ZipCodeLookupResponse.Address[0].City[0],
          state: res.ZipCodeLookupResponse.Address[0].State[0],
          zip: res.ZipCodeLookupResponse.Address[0].Zip5[0],
          zip4: res.ZipCodeLookupResponse.Address[0].Zip4[0],
          unit_required: res.ZipCodeLookupResponse.Address[0].ReturnText
            ? res.ZipCodeLookupResponse.Address[0].ReturnText[0] ===
              "Default address: The address you entered was found but more information is needed (such as an apartment, suite, or box number) to match to a specific address."
            : false,
        },
        // certainty: similarity( data.address.toUpperCase(), res.ZipCodeLookupResponse.Address[0].Address2[0].toUpperCase()),
      };
}

export default confirmAddress;

//for testing only
// confirmAddress({
//     address: '115210 La Costa Ct',
//     city: 'Redding',
//     state: 'CA',
//     zip: '96003',
// }).then( e => console.log(JSON.stringify(e, null, 4)))
