import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import React from "react";
import Stepper from "../components/ca_shipment/Stepper";
import SnackBar from "../components/Snackbar";
// import LoadingIcon from '../components/loading/LoadingIcon';
import {
  isAlphaNumeric,
  isAlphaNumericWithSpace,
  isNumericOnly,
  isValidPhoneNumber,
  isValidPostal,
} from "../helpers/Validation";
import CreateShipment from "./create_shipment/CreateShipment";
import PagePaper from "./PagePaper";

const styles = (theme) => ({
  textField: {
    width: "100%",
    boxSizing: "border-box",
    fontSize: "1.5em",
  },
  menu: {
    width: 200,
  },
  formControl: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2,
  },
  resetContainer: {
    padding: theme.spacing.unit * 3,
  },
});

class CreateShipmentCA extends React.Component {
  state = {
    open_time: null,
    close_time: null,
    location_note: "",
    isLoading: false,
    openSnack: false,
    location: {
      company: "",
    },
    contact: {
      fname: "",
      lname: "",
      phone: "",
      email: "",
    },
    address: {
      street_name: "",
      street_number: "",
      street_direction: "",
      postal: "",
      city: "",
      state: "",
    },
    errors: {
      business: false,
      fname: false,
      lname: false,
      phone: false,
      email: false,
      address: false,
      city: false,
      postal: false,
    },
  };

  language = {
    CAShipment: {
      FR: "Expédition aux Canada",
      EN: "New Self-Delivery Shipment",
    },
  };

  UNSAFE_componentWillMount = async () => {
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });
    let edit_id =
      this.props.location.pathname.indexOf("edit") > -1 &&
      this.props.location.pathname.split("/")[
        this.props.location.pathname.split("/").length - 1
      ];
    // alert(edit_id);
    if (edit_id) {
      this.setState({ edit: edit_id });
      // console.log(this.props.shipments.filter(e=>e.id===edit_id)[0])
      let shipment = this.props.shipments.filter((e) => e.id === edit_id)[0];
      this.setState({ shipment });
    }

  };


  render() {
    const { classes } = this.props;

    return (
      <div className="Home">
        <div className="notes">
          <Typography variant="h1">
            {this.language.CAShipment[this.state.language]}
          </Typography>
          <Grid item xs={12} md={8} lg={5} xl={4}>
            <PagePaper>
              <Grid container>
                <p>Create a new shipment, print a label, and assign your driver to deliver it from the Routes dispatch app</p>
                <CreateShipment
                  editID={this.state.edit}
                  shipment={this.state.shipment}
                  sender_id={this.state.sender_id}
                  editRate={this.state.editRate}
                  currentStep={this.state.currentStep}
                  editSender={this.state.editSender}
                  editPkg={this.state.editPackage}
                  location_note={this.state.location_note}
                  open_time={this.state.open_time}
                  close_time={this.state.close_time}
                  editRec={this.state.editReceiver}
                  account={this.props.account}
                  goToOrders={() => {}}
                  locations={this.props.locations}
                  getRates={this.props.getRate}
                  handleLogout={this.props.handleLogout}
                />
              </Grid>
            </PagePaper>
          </Grid>
        </div>
      </div>
    );
  }
}

CreateShipmentCA.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CreateShipmentCA);
