import axios from "axios";
import { parseStringPromise } from "xml2js";

const userId = "313SWIFT4350";

async function searchZip(zip: string) {
  const { data } = await axios(
    `https://secure.shippingapis.com/ShippingApi.dll?API=CityStateLookup&XML=%3CCityStateLookupRequest%20USERID=%22${userId}%22%3E%20%3CZipCode%20ID=%270%27%3E%20%3CZip5%3E${zip}%3C/Zip5%3E%20%3C/ZipCode%3E%20%3C/CityStateLookupRequest%3E`
  );

  const res = await parseStringPromise(data);

  return "Error" in res.CityStateLookupResponse.ZipCode[0]
    ? { status: false }
    : {
        status: true,
        zip: res.CityStateLookupResponse.ZipCode[0].Zip5[0],
        city: res.CityStateLookupResponse.ZipCode[0].City[0],
        state: res.CityStateLookupResponse.ZipCode[0].State[0],
      };
}

export default searchZip;

// for testing only
// searchZip('96003').then( e => console.log(JSON.stringify(e, null, 4)) )
