import { API } from "aws-amplify";

const listInvoices = (page, limit) =>
  new Promise(async (resolve, reject) => {
    // console.log({
    //   "page": page,
    //   "limit": limit
    // })
    let something = await API.post("sqs", "/invoice/get", {
      body: {
        page: page,
        limit: limit,
      },
    }).catch((e) => console.log(e));
    // .catch(e=>resolve(listInvoices(page, limit)))
    // console.log(something)
    resolve(something);
  });

export default listInvoices;
