const serviceConstaints = [
  {
    name: "US Lightweight Air",
    rate_type: "FC",
    max_length: 22,
    max_width: 18,
    max_height: 15,
    max_weight: 0.999,
    delivery_min: 2,
    delivery_ground: 4,
    delivery_air: 6,
    additional_test1: {
      max: 108,
      multiplier: 2,
    },
  },
  {
    name: "US Air",
    rate_type: "PM",
    max_length: 0,
    max_width: 0,
    max_height: 0,
    max_weight: 70,
    delivery_min: 2,
    delivery_ground: 3,
    delivery_air: 4,
    additional_test1: {
      max: 108,
      multiplier: 2,
    },
    tube_test: {
      max_height: 26,
    },
  },
  {
    name: "US Air Cubic",
    rate_type: "PM-CBPCL",
    max_length: 18,
    max_width: 0,
    max_height: 0,
    max_weight: 20,
    delivery_min: 2,
    delivery_ground: 3,
    delivery_air: 4,
    cubic_test: {
      divisor: 1728,
      max: 0.5,
    },
  },
  // {
  //     permissionRequired:true,
  //     name: "Priority Mail Flat Rate Envelope",
  //     rate_type: "PM-FRE",
  //     max_length:0,
  //     max_width:0,
  //     max_height:0,
  //     max_weight:70,
  //     delivery_ground: 2,
  //     delivery_air: 2,
  //     package_type: 'fr_env',
  // },
  // {
  //     permissionRequired:true,
  //     name: "Priority Mail Flat Rate Legal Envelope",
  //     rate_type: "PM-LFE",
  //     max_length:0,
  //     max_width:0,
  //     max_height:0,
  //     max_weight:70,
  //     delivery_ground: 2,
  //     delivery_air: 2,
  //     package_type: 'fr_env',
  // },
  // {
  //     permissionRequired:true,
  //     name: "Priority Mail Flat Rate Padded Envelope",
  //     rate_type: "PM-PFE",
  //     max_length:0,
  //     max_width:0,
  //     max_height:0,
  //     max_weight:70,
  //     delivery_ground: 2,
  //     delivery_air: 2,
  //     package_type: 'fr_env',
  // },
  // {
  //     permissionRequired:true,
  //     name: "Priority Mail Flat Rate Large Box",
  //     rate_type: "PM-FRL",
  //     max_length:0,
  //     max_width:0,
  //     max_height:0,
  //     max_weight:70,
  //     delivery_ground: 2,
  //     delivery_air: 2,
  //     package_type: 'fr_box',
  // },
  // {
  //     permissionRequired:true,
  //     name: "Priority Mail Flat Rate Small Box",
  //     rate_type: "PM-FRS",
  //     max_length:0,
  //     max_width:0,
  //     max_height:0,
  //     max_weight:70,
  //     delivery_ground: 2,
  //     delivery_air: 2,
  //     package_type: 'fr_box',
  // },
  // {
  //     permissionRequired:true,
  //     name: "Priority Mail Flat Rate Medium Box",
  //     rate_type: "PM-FRX",
  //     max_length:0,
  //     max_width:0,
  //     max_height:0,
  //     max_weight:70,
  //     delivery_ground: 2,
  //     delivery_air: 2,
  //     package_type: 'fr_box',
  // },
  // {
  //   name: "US Ground",
  //   rate_type: "PS",
  //   max_length: 27,
  //   max_width: 17,
  //   max_height: 17,
  //   max_weight: 35,
  //   delivery_min: 2,
  //   delivery_ground: 2,
  //   delivery_air: 6,
  //   delivery_override: "2-6",
  //   // cubic_test: {
  //   //     divisor: 1728,
  //   //     max: 1,
  //   // },
  //   machinable_test: true,
  //   presort_test: true,
  // },
  // {
  //   name: "US Lightweight Ground",
  //   rate_type: "PSLW",
  //   max_length: 27,
  //   max_width: 17,
  //   max_height: 17,
  //   max_weight: 0.999,
  //   delivery_override: "2-6",
  //   delivery_min: 2,
  //   delivery_ground: 2,
  //   delivery_air: 6,
  //   // machinable_test:true,
  //   presort_test: true,
  // },
  {
    name: "US Express",
    rate_type: "EM",
    max_length: 0,
    max_width: 0,
    max_height: 0,
    max_weight: 70,
    delivery_min: 2,
    delivery_ground: 2,
    delivery_air: 2,
    additional_test1: {
      max: 108,
      multiplier: 2,
    },
  },
  // {
  //     permissionRequired:true,
  //     name: "Media Mail",
  //     rate_type: "MM-PRSB",
  //     max_length:0,
  //     max_width:0,
  //     max_height:0,
  //     max_weight:70,
  //     delivery_override:'2-8',
  //     delivery_min: 2,
  //     delivery_ground: 2,
  //     delivery_air: 8,
  //     additionalTest: {
  //         max: 108,
  //         multiplier: 2,
  //     },
  // },
  {
    permissionRequired: true,
    name: "US Media Mail",
    rate_type: "MM-PRSS",
    max_length: 0,
    max_width: 0,
    max_height: 0,
    max_weight: 70,
    delivery_override: "2-8",
    delivery_min: 2,
    delivery_ground: 2,
    delivery_air: 8,
    additionalTest: {
      max: 108,
      multiplier: 2,
    },
  },
];

const additionalTest1 = (input, service) => {
  return "additional_test1" in service
    ? parseInt(parseFloat(input.package.dimensions[0].length)) +
        parseInt(
          service.additional_test1.multiplier *
            parseFloat(input.package.dimensions[0].width)
        ) +
        parseInt(
          service.additional_test1.multiplier *
            parseFloat(input.package.dimensions[0].height)
        ) <=
        service.additional_test1.max
    : true;
};

const tubeTest = (input, service) => {
  return "tube_test" in service
    ? parseFloat(input.package.dimensions[0].height) <=
        service.tube_test.max_height
    : true;
};

const cubicTest = (input, service, debug) => {
  return "cubic_test" in service
    ? (((parseFloat(input.package.dimensions[0].length) *
        parseFloat(input.package.dimensions[0].height) *
        parseFloat(input.package.dimensions[0].width)) /
        1728) *
        10) /
        10 <=
        service.cubic_test.max
    : true;
};

const presortTest = (input, service) => {
  const ndc_array = [
    { zip3: "242", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "298", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "300", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "301", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "302", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "303", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "304", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "305", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "306", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "307", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "308", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "309", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "310", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "311", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "312", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "318", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "319", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "350", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "351", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "352", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "354", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "355", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "356", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "357", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "358", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "359", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "360", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "361", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "362", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "363", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "364", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "365", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "366", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "367", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "368", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "373", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "374", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "376", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "377", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "378", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "379", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "395", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "399", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "407", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "408", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "409", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "417", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "418", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "425", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "426", validPDX: true, bucket: "01", ndc_postal: "31195" },
    { zip3: "463", validPDX: true, bucket: "02", ndc_postal: "60808" },
    { zip3: "464", validPDX: true, bucket: "02", ndc_postal: "60808" },
    { zip3: "530", validPDX: true, bucket: "02", ndc_postal: "60808" },
    { zip3: "531", validPDX: true, bucket: "02", ndc_postal: "60808" },
    { zip3: "532", validPDX: true, bucket: "02", ndc_postal: "60808" },
    { zip3: "534", validPDX: true, bucket: "02", ndc_postal: "60808" },
    { zip3: "535", validPDX: true, bucket: "02", ndc_postal: "60808" },
    { zip3: "537", validPDX: true, bucket: "02", ndc_postal: "60808" },
    { zip3: "538", validPDX: true, bucket: "02", ndc_postal: "60808" },
    { zip3: "539", validPDX: true, bucket: "02", ndc_postal: "60808" },
    { zip3: "549", validPDX: true, bucket: "02", ndc_postal: "60808" },
    { zip3: "600", validPDX: true, bucket: "02", ndc_postal: "60808" },
    { zip3: "601", validPDX: true, bucket: "02", ndc_postal: "60808" },
    { zip3: "602", validPDX: true, bucket: "02", ndc_postal: "60808" },
    { zip3: "603", validPDX: true, bucket: "02", ndc_postal: "60808" },
    { zip3: "604", validPDX: true, bucket: "02", ndc_postal: "60808" },
    { zip3: "605", validPDX: true, bucket: "02", ndc_postal: "60808" },
    { zip3: "606", validPDX: true, bucket: "02", ndc_postal: "60808" },
    { zip3: "607", validPDX: true, bucket: "02", ndc_postal: "60808" },
    { zip3: "608", validPDX: true, bucket: "02", ndc_postal: "60808" },
    { zip3: "610", validPDX: true, bucket: "02", ndc_postal: "60808" },
    { zip3: "611", validPDX: true, bucket: "02", ndc_postal: "60808" },
    { zip3: "246", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "247", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "248", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "249", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "250", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "251", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "252", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "253", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "255", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "256", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "257", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "258", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "259", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "261", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "262", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "263", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "264", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "266", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "400", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "401", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "402", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "403", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "404", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "405", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "406", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "410", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "411", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "412", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "413", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "414", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "415", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "416", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "427", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "430", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "431", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "432", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "433", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "437", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "438", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "450", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "451", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "452", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "453", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "454", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "455", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "456", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "457", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "458", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "459", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "460", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "461", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "462", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "469", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "470", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "471", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "472", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "473", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "474", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "478", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "479", validPDX: true, bucket: "03", ndc_postal: "45900" },
    { zip3: "679", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "710", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "711", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "712", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "713", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "714", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "718", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "730", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "731", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "733", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "734", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "735", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "736", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "737", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "738", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "739", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "740", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "741", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "743", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "744", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "745", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "746", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "747", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "748", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "749", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "750", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "751", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "752", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "753", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "754", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "755", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "756", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "757", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "758", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "759", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "760", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "761", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "762", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "763", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "764", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "765", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "766", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "767", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "768", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "769", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "770", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "772", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "773", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "774", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "775", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "776", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "777", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "778", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "779", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "780", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "781", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "782", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "783", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "784", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "785", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "786", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "787", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "788", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "789", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "790", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "791", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "792", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "793", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "794", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "795", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "796", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "797", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "798", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "799", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "880", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "881", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "882", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "883", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "885", validPDX: true, bucket: "04", ndc_postal: "75199" },
    { zip3: "590", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "591", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "592", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "593", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "594", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "595", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "596", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "597", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "598", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "599", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "677", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "690", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "691", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "692", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "693", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "800", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "801", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "802", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "803", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "804", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "805", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "806", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "807", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "808", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "809", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "810", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "811", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "812", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "813", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "814", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "815", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "816", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "820", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "821", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "822", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "823", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "824", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "825", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "826", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "827", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "828", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "829", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "830", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "831", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "832", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "833", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "834", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "836", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "837", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "840", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "841", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "842", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "843", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "844", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "845", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "846", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "847", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "850", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "851", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "852", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "853", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "855", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "856", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "857", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "859", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "860", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "863", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "865", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "870", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "871", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "873", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "874", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "875", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "876", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "877", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "878", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "879", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "884", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "898", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "979", validPDX: true, bucket: "05", ndc_postal: "80088" },
    { zip3: "500", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "501", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "502", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "503", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "504", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "505", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "506", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "507", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "508", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "509", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "510", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "511", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "512", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "513", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "514", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "515", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "516", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "520", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "521", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "522", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "523", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "524", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "525", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "526", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "527", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "528", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "570", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "571", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "572", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "573", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "574", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "575", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "577", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "612", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "680", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "681", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "683", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "684", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "685", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "686", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "687", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "688", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "689", validPDX: true, bucket: "06", ndc_postal: "50999" },
    { zip3: "434", validPDX: true, bucket: "07", ndc_postal: "48399" },
    { zip3: "435", validPDX: true, bucket: "07", ndc_postal: "48399" },
    { zip3: "436", validPDX: true, bucket: "07", ndc_postal: "48399" },
    { zip3: "465", validPDX: true, bucket: "07", ndc_postal: "48399" },
    { zip3: "466", validPDX: true, bucket: "07", ndc_postal: "48399" },
    { zip3: "467", validPDX: true, bucket: "07", ndc_postal: "48399" },
    { zip3: "468", validPDX: true, bucket: "07", ndc_postal: "48399" },
    { zip3: "480", validPDX: true, bucket: "07", ndc_postal: "48399" },
    { zip3: "481", validPDX: true, bucket: "07", ndc_postal: "48399" },
    { zip3: "482", validPDX: true, bucket: "07", ndc_postal: "48399" },
    { zip3: "483", validPDX: true, bucket: "07", ndc_postal: "48399" },
    { zip3: "484", validPDX: true, bucket: "07", ndc_postal: "48399" },
    { zip3: "485", validPDX: true, bucket: "07", ndc_postal: "48399" },
    { zip3: "486", validPDX: true, bucket: "07", ndc_postal: "48399" },
    { zip3: "487", validPDX: true, bucket: "07", ndc_postal: "48399" },
    { zip3: "488", validPDX: true, bucket: "07", ndc_postal: "48399" },
    { zip3: "489", validPDX: true, bucket: "07", ndc_postal: "48399" },
    { zip3: "490", validPDX: true, bucket: "07", ndc_postal: "48399" },
    { zip3: "491", validPDX: true, bucket: "07", ndc_postal: "48399" },
    { zip3: "492", validPDX: true, bucket: "07", ndc_postal: "48399" },
    { zip3: "493", validPDX: true, bucket: "07", ndc_postal: "48399" },
    { zip3: "494", validPDX: true, bucket: "07", ndc_postal: "48399" },
    { zip3: "495", validPDX: true, bucket: "07", ndc_postal: "48399" },
    { zip3: "496", validPDX: true, bucket: "07", ndc_postal: "48399" },
    { zip3: "497", validPDX: true, bucket: "07", ndc_postal: "48399" },
    { zip3: "240", validPDX: true, bucket: "08", ndc_postal: "27075" },
    { zip3: "241", validPDX: true, bucket: "08", ndc_postal: "27075" },
    { zip3: "243", validPDX: true, bucket: "08", ndc_postal: "27075" },
    { zip3: "245", validPDX: true, bucket: "08", ndc_postal: "27075" },
    { zip3: "270", validPDX: true, bucket: "08", ndc_postal: "27075" },
    { zip3: "271", validPDX: true, bucket: "08", ndc_postal: "27075" },
    { zip3: "272", validPDX: true, bucket: "08", ndc_postal: "27075" },
    { zip3: "273", validPDX: true, bucket: "08", ndc_postal: "27075" },
    { zip3: "274", validPDX: true, bucket: "08", ndc_postal: "27075" },
    { zip3: "275", validPDX: true, bucket: "08", ndc_postal: "27075" },
    { zip3: "276", validPDX: true, bucket: "08", ndc_postal: "27075" },
    { zip3: "277", validPDX: true, bucket: "08", ndc_postal: "27075" },
    { zip3: "278", validPDX: true, bucket: "08", ndc_postal: "27075" },
    { zip3: "279", validPDX: true, bucket: "08", ndc_postal: "27075" },
    { zip3: "280", validPDX: true, bucket: "08", ndc_postal: "27075" },
    { zip3: "281", validPDX: true, bucket: "08", ndc_postal: "27075" },
    { zip3: "282", validPDX: true, bucket: "08", ndc_postal: "27075" },
    { zip3: "283", validPDX: true, bucket: "08", ndc_postal: "27075" },
    { zip3: "284", validPDX: true, bucket: "08", ndc_postal: "27075" },
    { zip3: "285", validPDX: true, bucket: "08", ndc_postal: "27075" },
    { zip3: "286", validPDX: true, bucket: "08", ndc_postal: "27075" },
    { zip3: "287", validPDX: true, bucket: "08", ndc_postal: "27075" },
    { zip3: "288", validPDX: true, bucket: "08", ndc_postal: "27075" },
    { zip3: "289", validPDX: true, bucket: "08", ndc_postal: "27075" },
    { zip3: "290", validPDX: true, bucket: "08", ndc_postal: "27075" },
    { zip3: "291", validPDX: true, bucket: "08", ndc_postal: "27075" },
    { zip3: "292", validPDX: true, bucket: "08", ndc_postal: "27075" },
    { zip3: "293", validPDX: true, bucket: "08", ndc_postal: "27075" },
    { zip3: "294", validPDX: true, bucket: "08", ndc_postal: "27075" },
    { zip3: "295", validPDX: true, bucket: "08", ndc_postal: "27075" },
    { zip3: "296", validPDX: true, bucket: "08", ndc_postal: "27075" },
    { zip3: "297", validPDX: true, bucket: "08", ndc_postal: "27075" },
    { zip3: "299", validPDX: true, bucket: "08", ndc_postal: "27075" },
    { zip3: "006", validPDX: false, bucket: "09", ndc_postal: "32099" },
    { zip3: "007", validPDX: false, bucket: "09", ndc_postal: "32099" },
    { zip3: "008", validPDX: false, bucket: "09", ndc_postal: "32099" },
    { zip3: "009", validPDX: false, bucket: "09", ndc_postal: "32099" },
    { zip3: "313", validPDX: true, bucket: "09", ndc_postal: "32099" },
    { zip3: "314", validPDX: true, bucket: "09", ndc_postal: "32099" },
    { zip3: "315", validPDX: true, bucket: "09", ndc_postal: "32099" },
    { zip3: "316", validPDX: true, bucket: "09", ndc_postal: "32099" },
    { zip3: "317", validPDX: true, bucket: "09", ndc_postal: "32099" },
    { zip3: "320", validPDX: true, bucket: "09", ndc_postal: "32099" },
    { zip3: "321", validPDX: true, bucket: "09", ndc_postal: "32099" },
    { zip3: "322", validPDX: true, bucket: "09", ndc_postal: "32099" },
    { zip3: "323", validPDX: true, bucket: "09", ndc_postal: "32099" },
    { zip3: "324", validPDX: true, bucket: "09", ndc_postal: "32099" },
    { zip3: "325", validPDX: true, bucket: "09", ndc_postal: "32099" },
    { zip3: "326", validPDX: true, bucket: "09", ndc_postal: "32099" },
    { zip3: "327", validPDX: true, bucket: "09", ndc_postal: "32099" },
    { zip3: "328", validPDX: true, bucket: "09", ndc_postal: "32099" },
    { zip3: "329", validPDX: true, bucket: "09", ndc_postal: "32099" },
    { zip3: "330", validPDX: true, bucket: "09", ndc_postal: "32099" },
    { zip3: "331", validPDX: true, bucket: "09", ndc_postal: "32099" },
    { zip3: "332", validPDX: true, bucket: "09", ndc_postal: "32099" },
    { zip3: "333", validPDX: true, bucket: "09", ndc_postal: "32099" },
    { zip3: "334", validPDX: true, bucket: "09", ndc_postal: "32099" },
    { zip3: "335", validPDX: true, bucket: "09", ndc_postal: "32099" },
    { zip3: "336", validPDX: true, bucket: "09", ndc_postal: "32099" },
    { zip3: "337", validPDX: true, bucket: "09", ndc_postal: "32099" },
    { zip3: "338", validPDX: true, bucket: "09", ndc_postal: "32099" },
    { zip3: "339", validPDX: true, bucket: "09", ndc_postal: "32099" },
    { zip3: "340", validPDX: true, bucket: "09", ndc_postal: "32099" },
    { zip3: "341", validPDX: true, bucket: "09", ndc_postal: "32099" },
    { zip3: "342", validPDX: true, bucket: "09", ndc_postal: "32099" },
    { zip3: "344", validPDX: true, bucket: "09", ndc_postal: "32099" },
    { zip3: "346", validPDX: true, bucket: "09", ndc_postal: "32099" },
    { zip3: "347", validPDX: true, bucket: "09", ndc_postal: "32099" },
    { zip3: "349", validPDX: true, bucket: "09", ndc_postal: "32099" },
    { zip3: "398", validPDX: true, bucket: "09", ndc_postal: "32099" },
    { zip3: "635", validPDX: true, bucket: "10", ndc_postal: "66107" },
    { zip3: "640", validPDX: true, bucket: "10", ndc_postal: "66107" },
    { zip3: "641", validPDX: true, bucket: "10", ndc_postal: "66107" },
    { zip3: "644", validPDX: true, bucket: "10", ndc_postal: "66107" },
    { zip3: "645", validPDX: true, bucket: "10", ndc_postal: "66107" },
    { zip3: "646", validPDX: true, bucket: "10", ndc_postal: "66107" },
    { zip3: "647", validPDX: true, bucket: "10", ndc_postal: "66107" },
    { zip3: "648", validPDX: true, bucket: "10", ndc_postal: "66107" },
    { zip3: "649", validPDX: true, bucket: "10", ndc_postal: "66107" },
    { zip3: "650", validPDX: true, bucket: "10", ndc_postal: "66107" },
    { zip3: "651", validPDX: true, bucket: "10", ndc_postal: "66107" },
    { zip3: "652", validPDX: true, bucket: "10", ndc_postal: "66107" },
    { zip3: "653", validPDX: true, bucket: "10", ndc_postal: "66107" },
    { zip3: "654", validPDX: true, bucket: "10", ndc_postal: "66107" },
    { zip3: "655", validPDX: true, bucket: "10", ndc_postal: "66107" },
    { zip3: "656", validPDX: true, bucket: "10", ndc_postal: "66107" },
    { zip3: "657", validPDX: true, bucket: "10", ndc_postal: "66107" },
    { zip3: "658", validPDX: true, bucket: "10", ndc_postal: "66107" },
    { zip3: "660", validPDX: true, bucket: "10", ndc_postal: "66107" },
    { zip3: "661", validPDX: true, bucket: "10", ndc_postal: "66107" },
    { zip3: "662", validPDX: true, bucket: "10", ndc_postal: "66107" },
    { zip3: "664", validPDX: true, bucket: "10", ndc_postal: "66107" },
    { zip3: "665", validPDX: true, bucket: "10", ndc_postal: "66107" },
    { zip3: "666", validPDX: true, bucket: "10", ndc_postal: "66107" },
    { zip3: "667", validPDX: true, bucket: "10", ndc_postal: "66107" },
    { zip3: "668", validPDX: true, bucket: "10", ndc_postal: "66107" },
    { zip3: "669", validPDX: true, bucket: "10", ndc_postal: "66107" },
    { zip3: "670", validPDX: true, bucket: "10", ndc_postal: "66107" },
    { zip3: "671", validPDX: true, bucket: "10", ndc_postal: "66107" },
    { zip3: "672", validPDX: true, bucket: "10", ndc_postal: "66107" },
    { zip3: "673", validPDX: true, bucket: "10", ndc_postal: "66107" },
    { zip3: "674", validPDX: true, bucket: "10", ndc_postal: "66107" },
    { zip3: "675", validPDX: true, bucket: "10", ndc_postal: "66107" },
    { zip3: "676", validPDX: true, bucket: "10", ndc_postal: "66107" },
    { zip3: "678", validPDX: true, bucket: "10", ndc_postal: "66107" },
    { zip3: "864", validPDX: true, bucket: "11", ndc_postal: "90299" },
    { zip3: "889", validPDX: true, bucket: "11", ndc_postal: "90299" },
    { zip3: "890", validPDX: true, bucket: "11", ndc_postal: "90299" },
    { zip3: "891", validPDX: true, bucket: "11", ndc_postal: "90299" },
    { zip3: "893", validPDX: true, bucket: "11", ndc_postal: "90299" },
    { zip3: "900", validPDX: true, bucket: "11", ndc_postal: "90299" },
    { zip3: "901", validPDX: true, bucket: "11", ndc_postal: "90299" },
    { zip3: "902", validPDX: true, bucket: "11", ndc_postal: "90299" },
    { zip3: "903", validPDX: true, bucket: "11", ndc_postal: "90299" },
    { zip3: "904", validPDX: true, bucket: "11", ndc_postal: "90299" },
    { zip3: "905", validPDX: true, bucket: "11", ndc_postal: "90299" },
    { zip3: "906", validPDX: true, bucket: "11", ndc_postal: "90299" },
    { zip3: "907", validPDX: true, bucket: "11", ndc_postal: "90299" },
    { zip3: "908", validPDX: true, bucket: "11", ndc_postal: "90299" },
    { zip3: "910", validPDX: true, bucket: "11", ndc_postal: "90299" },
    { zip3: "911", validPDX: true, bucket: "11", ndc_postal: "90299" },
    { zip3: "912", validPDX: true, bucket: "11", ndc_postal: "90299" },
    { zip3: "913", validPDX: true, bucket: "11", ndc_postal: "90299" },
    { zip3: "914", validPDX: true, bucket: "11", ndc_postal: "90299" },
    { zip3: "915", validPDX: true, bucket: "11", ndc_postal: "90299" },
    { zip3: "916", validPDX: true, bucket: "11", ndc_postal: "90299" },
    { zip3: "917", validPDX: true, bucket: "11", ndc_postal: "90299" },
    { zip3: "918", validPDX: true, bucket: "11", ndc_postal: "90299" },
    { zip3: "919", validPDX: true, bucket: "11", ndc_postal: "90299" },
    { zip3: "920", validPDX: true, bucket: "11", ndc_postal: "90299" },
    { zip3: "921", validPDX: true, bucket: "11", ndc_postal: "90299" },
    { zip3: "922", validPDX: true, bucket: "11", ndc_postal: "90299" },
    { zip3: "923", validPDX: true, bucket: "11", ndc_postal: "90299" },
    { zip3: "924", validPDX: true, bucket: "11", ndc_postal: "90299" },
    { zip3: "925", validPDX: true, bucket: "11", ndc_postal: "90299" },
    { zip3: "926", validPDX: true, bucket: "11", ndc_postal: "90299" },
    { zip3: "927", validPDX: true, bucket: "11", ndc_postal: "90299" },
    { zip3: "928", validPDX: true, bucket: "11", ndc_postal: "90299" },
    { zip3: "930", validPDX: true, bucket: "11", ndc_postal: "90299" },
    { zip3: "931", validPDX: true, bucket: "11", ndc_postal: "90299" },
    { zip3: "932", validPDX: true, bucket: "11", ndc_postal: "90299" },
    { zip3: "933", validPDX: true, bucket: "11", ndc_postal: "90299" },
    { zip3: "934", validPDX: true, bucket: "11", ndc_postal: "90299" },
    { zip3: "935", validPDX: true, bucket: "11", ndc_postal: "90299" },
    { zip3: "369", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "370", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "371", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "372", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "375", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "380", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "381", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "382", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "383", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "384", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "385", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "386", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "387", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "388", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "389", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "390", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "391", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "392", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "393", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "394", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "396", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "397", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "421", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "422", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "700", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "701", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "703", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "704", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "705", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "706", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "707", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "708", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "716", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "717", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "719", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "720", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "721", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "722", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "723", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "724", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "725", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "726", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "727", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "728", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "729", validPDX: true, bucket: "12", ndc_postal: "38999" },
    { zip3: "498", validPDX: true, bucket: "13", ndc_postal: "55502" },
    { zip3: "499", validPDX: true, bucket: "13", ndc_postal: "55502" },
    { zip3: "540", validPDX: true, bucket: "13", ndc_postal: "55502" },
    { zip3: "541", validPDX: true, bucket: "13", ndc_postal: "55502" },
    { zip3: "542", validPDX: true, bucket: "13", ndc_postal: "55502" },
    { zip3: "543", validPDX: true, bucket: "13", ndc_postal: "55502" },
    { zip3: "544", validPDX: true, bucket: "13", ndc_postal: "55502" },
    { zip3: "545", validPDX: true, bucket: "13", ndc_postal: "55502" },
    { zip3: "546", validPDX: true, bucket: "13", ndc_postal: "55502" },
    { zip3: "547", validPDX: true, bucket: "13", ndc_postal: "55502" },
    { zip3: "548", validPDX: true, bucket: "13", ndc_postal: "55502" },
    { zip3: "550", validPDX: true, bucket: "13", ndc_postal: "55502" },
    { zip3: "551", validPDX: true, bucket: "13", ndc_postal: "55502" },
    { zip3: "553", validPDX: true, bucket: "13", ndc_postal: "55502" },
    { zip3: "554", validPDX: true, bucket: "13", ndc_postal: "55502" },
    { zip3: "555", validPDX: true, bucket: "13", ndc_postal: "55502" },
    { zip3: "556", validPDX: true, bucket: "13", ndc_postal: "55502" },
    { zip3: "557", validPDX: true, bucket: "13", ndc_postal: "55502" },
    { zip3: "558", validPDX: true, bucket: "13", ndc_postal: "55502" },
    { zip3: "559", validPDX: true, bucket: "13", ndc_postal: "55502" },
    { zip3: "560", validPDX: true, bucket: "13", ndc_postal: "55502" },
    { zip3: "561", validPDX: true, bucket: "13", ndc_postal: "55502" },
    { zip3: "562", validPDX: true, bucket: "13", ndc_postal: "55502" },
    { zip3: "563", validPDX: true, bucket: "13", ndc_postal: "55502" },
    { zip3: "564", validPDX: true, bucket: "13", ndc_postal: "55502" },
    { zip3: "565", validPDX: true, bucket: "13", ndc_postal: "55502" },
    { zip3: "566", validPDX: true, bucket: "13", ndc_postal: "55502" },
    { zip3: "567", validPDX: true, bucket: "13", ndc_postal: "55502" },
    { zip3: "576", validPDX: true, bucket: "13", ndc_postal: "55502" },
    { zip3: "580", validPDX: true, bucket: "13", ndc_postal: "55502" },
    { zip3: "581", validPDX: true, bucket: "13", ndc_postal: "55502" },
    { zip3: "582", validPDX: true, bucket: "13", ndc_postal: "55502" },
    { zip3: "583", validPDX: true, bucket: "13", ndc_postal: "55502" },
    { zip3: "584", validPDX: true, bucket: "13", ndc_postal: "55502" },
    { zip3: "585", validPDX: true, bucket: "13", ndc_postal: "55502" },
    { zip3: "586", validPDX: true, bucket: "13", ndc_postal: "55502" },
    { zip3: "587", validPDX: true, bucket: "13", ndc_postal: "55502" },
    { zip3: "588", validPDX: true, bucket: "13", ndc_postal: "55502" },
    { zip3: "005", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "066", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "068", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "069", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "070", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "071", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "072", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "073", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "074", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "075", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "076", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "077", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "078", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "079", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "085", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "086", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "087", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "088", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "089", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "090", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "091", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "092", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "093", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "094", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "095", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "096", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "097", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "098", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "099", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "100", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "101", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "102", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "103", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "104", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "105", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "106", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "107", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "108", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "109", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "110", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "111", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "112", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "113", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "114", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "115", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "116", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "117", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "118", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "119", validPDX: true, bucket: "14", ndc_postal: "07097" },
    { zip3: "080", validPDX: true, bucket: "15", ndc_postal: "19205" },
    { zip3: "081", validPDX: true, bucket: "15", ndc_postal: "19205" },
    { zip3: "082", validPDX: true, bucket: "15", ndc_postal: "19205" },
    { zip3: "083", validPDX: true, bucket: "15", ndc_postal: "19205" },
    { zip3: "084", validPDX: true, bucket: "15", ndc_postal: "19205" },
    { zip3: "169", validPDX: true, bucket: "15", ndc_postal: "19205" },
    { zip3: "170", validPDX: true, bucket: "15", ndc_postal: "19205" },
    { zip3: "171", validPDX: true, bucket: "15", ndc_postal: "19205" },
    { zip3: "172", validPDX: true, bucket: "15", ndc_postal: "19205" },
    { zip3: "173", validPDX: true, bucket: "15", ndc_postal: "19205" },
    { zip3: "174", validPDX: true, bucket: "15", ndc_postal: "19205" },
    { zip3: "175", validPDX: true, bucket: "15", ndc_postal: "19205" },
    { zip3: "176", validPDX: true, bucket: "15", ndc_postal: "19205" },
    { zip3: "177", validPDX: true, bucket: "15", ndc_postal: "19205" },
    { zip3: "178", validPDX: true, bucket: "15", ndc_postal: "19205" },
    { zip3: "179", validPDX: true, bucket: "15", ndc_postal: "19205" },
    { zip3: "180", validPDX: true, bucket: "15", ndc_postal: "19205" },
    { zip3: "181", validPDX: true, bucket: "15", ndc_postal: "19205" },
    { zip3: "182", validPDX: true, bucket: "15", ndc_postal: "19205" },
    { zip3: "183", validPDX: true, bucket: "15", ndc_postal: "19205" },
    { zip3: "184", validPDX: true, bucket: "15", ndc_postal: "19205" },
    { zip3: "185", validPDX: true, bucket: "15", ndc_postal: "19205" },
    { zip3: "186", validPDX: true, bucket: "15", ndc_postal: "19205" },
    { zip3: "187", validPDX: true, bucket: "15", ndc_postal: "19205" },
    { zip3: "188", validPDX: true, bucket: "15", ndc_postal: "19205" },
    { zip3: "189", validPDX: true, bucket: "15", ndc_postal: "19205" },
    { zip3: "190", validPDX: true, bucket: "15", ndc_postal: "19205" },
    { zip3: "191", validPDX: true, bucket: "15", ndc_postal: "19205" },
    { zip3: "192", validPDX: true, bucket: "15", ndc_postal: "19205" },
    { zip3: "193", validPDX: true, bucket: "15", ndc_postal: "19205" },
    { zip3: "194", validPDX: true, bucket: "15", ndc_postal: "19205" },
    { zip3: "195", validPDX: true, bucket: "15", ndc_postal: "19205" },
    { zip3: "196", validPDX: true, bucket: "15", ndc_postal: "19205" },
    { zip3: "197", validPDX: true, bucket: "15", ndc_postal: "19205" },
    { zip3: "198", validPDX: true, bucket: "15", ndc_postal: "19205" },
    { zip3: "199", validPDX: true, bucket: "15", ndc_postal: "19205" },
    { zip3: "254", validPDX: true, bucket: "15", ndc_postal: "19205" },
    { zip3: "130", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "131", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "132", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "133", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "134", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "135", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "136", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "137", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "138", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "139", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "140", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "141", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "142", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "143", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "144", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "145", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "146", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "147", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "148", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "149", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "150", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "151", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "152", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "153", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "154", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "155", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "156", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "157", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "158", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "159", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "160", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "161", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "162", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "163", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "164", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "165", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "166", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "167", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "168", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "260", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "265", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "439", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "440", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "441", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "442", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "443", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "444", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "445", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "446", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "447", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "448", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "449", validPDX: true, bucket: "16", ndc_postal: "15195" },
    { zip3: "894", validPDX: true, bucket: "17", ndc_postal: "94850" },
    { zip3: "895", validPDX: true, bucket: "17", ndc_postal: "94850" },
    { zip3: "897", validPDX: true, bucket: "17", ndc_postal: "94850" },
    { zip3: "936", validPDX: true, bucket: "17", ndc_postal: "94850" },
    { zip3: "937", validPDX: true, bucket: "17", ndc_postal: "94850" },
    { zip3: "938", validPDX: true, bucket: "17", ndc_postal: "94850" },
    { zip3: "939", validPDX: true, bucket: "17", ndc_postal: "94850" },
    { zip3: "940", validPDX: true, bucket: "17", ndc_postal: "94850" },
    { zip3: "941", validPDX: true, bucket: "17", ndc_postal: "94850" },
    { zip3: "942", validPDX: true, bucket: "17", ndc_postal: "94850" },
    { zip3: "943", validPDX: true, bucket: "17", ndc_postal: "94850" },
    { zip3: "944", validPDX: true, bucket: "17", ndc_postal: "94850" },
    { zip3: "945", validPDX: true, bucket: "17", ndc_postal: "94850" },
    { zip3: "946", validPDX: true, bucket: "17", ndc_postal: "94850" },
    { zip3: "947", validPDX: true, bucket: "17", ndc_postal: "94850" },
    { zip3: "948", validPDX: true, bucket: "17", ndc_postal: "94850" },
    { zip3: "949", validPDX: true, bucket: "17", ndc_postal: "94850" },
    { zip3: "950", validPDX: true, bucket: "17", ndc_postal: "94850" },
    { zip3: "951", validPDX: true, bucket: "17", ndc_postal: "94850" },
    { zip3: "952", validPDX: true, bucket: "17", ndc_postal: "94850" },
    { zip3: "953", validPDX: true, bucket: "17", ndc_postal: "94850" },
    { zip3: "954", validPDX: true, bucket: "17", ndc_postal: "94850" },
    { zip3: "955", validPDX: true, bucket: "17", ndc_postal: "94850" },
    { zip3: "956", validPDX: true, bucket: "17", ndc_postal: "94850" },
    { zip3: "957", validPDX: true, bucket: "17", ndc_postal: "94850" },
    { zip3: "958", validPDX: true, bucket: "17", ndc_postal: "94850" },
    { zip3: "959", validPDX: true, bucket: "17", ndc_postal: "94850" },
    { zip3: "960", validPDX: true, bucket: "17", ndc_postal: "94850" },
    { zip3: "961", validPDX: true, bucket: "17", ndc_postal: "94850" },
    { zip3: "962", validPDX: true, bucket: "17", ndc_postal: "94850" },
    { zip3: "963", validPDX: true, bucket: "17", ndc_postal: "94850" },
    { zip3: "964", validPDX: true, bucket: "17", ndc_postal: "94850" },
    { zip3: "965", validPDX: true, bucket: "17", ndc_postal: "94850" },
    { zip3: "966", validPDX: true, bucket: "17", ndc_postal: "94850" },
    { zip3: "967", validPDX: false, bucket: "17", ndc_postal: "94850" },
    { zip3: "968", validPDX: false, bucket: "17", ndc_postal: "94850" },
    { zip3: "969", validPDX: false, bucket: "17", ndc_postal: "94850" },
    { zip3: "835", validPDX: true, bucket: "18", ndc_postal: "98000" },
    { zip3: "838", validPDX: true, bucket: "18", ndc_postal: "98000" },
    { zip3: "970", validPDX: true, bucket: "18", ndc_postal: "98000" },
    { zip3: "971", validPDX: true, bucket: "18", ndc_postal: "98000" },
    { zip3: "972", validPDX: true, bucket: "18", ndc_postal: "98000" },
    { zip3: "973", validPDX: true, bucket: "18", ndc_postal: "98000" },
    { zip3: "974", validPDX: true, bucket: "18", ndc_postal: "98000" },
    { zip3: "975", validPDX: true, bucket: "18", ndc_postal: "98000" },
    { zip3: "976", validPDX: true, bucket: "18", ndc_postal: "98000" },
    { zip3: "977", validPDX: true, bucket: "18", ndc_postal: "98000" },
    { zip3: "978", validPDX: true, bucket: "18", ndc_postal: "98000" },
    { zip3: "980", validPDX: true, bucket: "18", ndc_postal: "98000" },
    { zip3: "981", validPDX: true, bucket: "18", ndc_postal: "98000" },
    { zip3: "982", validPDX: true, bucket: "18", ndc_postal: "98000" },
    { zip3: "983", validPDX: true, bucket: "18", ndc_postal: "98000" },
    { zip3: "984", validPDX: true, bucket: "18", ndc_postal: "98000" },
    { zip3: "985", validPDX: true, bucket: "18", ndc_postal: "98000" },
    { zip3: "986", validPDX: true, bucket: "18", ndc_postal: "98000" },
    { zip3: "988", validPDX: true, bucket: "18", ndc_postal: "98000" },
    { zip3: "989", validPDX: true, bucket: "18", ndc_postal: "98000" },
    { zip3: "990", validPDX: true, bucket: "18", ndc_postal: "98000" },
    { zip3: "991", validPDX: true, bucket: "18", ndc_postal: "98000" },
    { zip3: "992", validPDX: true, bucket: "18", ndc_postal: "98000" },
    { zip3: "993", validPDX: true, bucket: "18", ndc_postal: "98000" },
    { zip3: "994", validPDX: true, bucket: "18", ndc_postal: "98000" },
    { zip3: "995", validPDX: false, bucket: "18", ndc_postal: "98000" },
    { zip3: "996", validPDX: false, bucket: "18", ndc_postal: "98000" },
    { zip3: "997", validPDX: false, bucket: "18", ndc_postal: "98000" },
    { zip3: "998", validPDX: false, bucket: "18", ndc_postal: "98000" },
    { zip3: "999", validPDX: false, bucket: "18", ndc_postal: "98000" },
    { zip3: "010", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "011", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "012", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "013", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "014", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "015", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "016", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "017", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "018", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "019", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "020", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "021", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "022", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "023", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "024", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "025", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "026", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "027", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "028", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "029", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "030", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "031", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "032", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "033", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "034", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "035", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "036", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "037", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "038", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "039", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "040", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "041", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "042", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "043", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "044", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "045", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "046", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "047", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "048", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "049", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "050", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "051", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "052", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "053", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "054", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "055", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "056", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "057", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "058", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "059", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "060", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "061", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "062", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "063", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "064", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "065", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "067", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "120", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "121", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "122", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "123", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "124", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "125", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "126", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "127", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "128", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "129", validPDX: true, bucket: "19", ndc_postal: "01152" },
    { zip3: "420", validPDX: true, bucket: "20", ndc_postal: "63001" },
    { zip3: "423", validPDX: true, bucket: "20", ndc_postal: "63001" },
    { zip3: "424", validPDX: true, bucket: "20", ndc_postal: "63001" },
    { zip3: "475", validPDX: true, bucket: "20", ndc_postal: "63001" },
    { zip3: "476", validPDX: true, bucket: "20", ndc_postal: "63001" },
    { zip3: "477", validPDX: true, bucket: "20", ndc_postal: "63001" },
    { zip3: "609", validPDX: true, bucket: "20", ndc_postal: "63001" },
    { zip3: "613", validPDX: true, bucket: "20", ndc_postal: "63001" },
    { zip3: "614", validPDX: true, bucket: "20", ndc_postal: "63001" },
    { zip3: "615", validPDX: true, bucket: "20", ndc_postal: "63001" },
    { zip3: "616", validPDX: true, bucket: "20", ndc_postal: "63001" },
    { zip3: "617", validPDX: true, bucket: "20", ndc_postal: "63001" },
    { zip3: "618", validPDX: true, bucket: "20", ndc_postal: "63001" },
    { zip3: "619", validPDX: true, bucket: "20", ndc_postal: "63001" },
    { zip3: "620", validPDX: true, bucket: "20", ndc_postal: "63001" },
    { zip3: "622", validPDX: true, bucket: "20", ndc_postal: "63001" },
    { zip3: "623", validPDX: true, bucket: "20", ndc_postal: "63001" },
    { zip3: "624", validPDX: true, bucket: "20", ndc_postal: "63001" },
    { zip3: "625", validPDX: true, bucket: "20", ndc_postal: "63001" },
    { zip3: "626", validPDX: true, bucket: "20", ndc_postal: "63001" },
    { zip3: "627", validPDX: true, bucket: "20", ndc_postal: "63001" },
    { zip3: "628", validPDX: true, bucket: "20", ndc_postal: "63001" },
    { zip3: "629", validPDX: true, bucket: "20", ndc_postal: "63001" },
    { zip3: "630", validPDX: true, bucket: "20", ndc_postal: "63001" },
    { zip3: "631", validPDX: true, bucket: "20", ndc_postal: "63001" },
    { zip3: "633", validPDX: true, bucket: "20", ndc_postal: "63001" },
    { zip3: "634", validPDX: true, bucket: "20", ndc_postal: "63001" },
    { zip3: "636", validPDX: true, bucket: "20", ndc_postal: "63001" },
    { zip3: "637", validPDX: true, bucket: "20", ndc_postal: "63001" },
    { zip3: "638", validPDX: true, bucket: "20", ndc_postal: "63001" },
    { zip3: "639", validPDX: true, bucket: "20", ndc_postal: "63001" },
    { zip3: "200", validPDX: true, bucket: "21", ndc_postal: "20799" },
    { zip3: "201", validPDX: true, bucket: "21", ndc_postal: "20799" },
    { zip3: "202", validPDX: true, bucket: "21", ndc_postal: "20799" },
    { zip3: "203", validPDX: true, bucket: "21", ndc_postal: "20799" },
    { zip3: "204", validPDX: true, bucket: "21", ndc_postal: "20799" },
    { zip3: "205", validPDX: true, bucket: "21", ndc_postal: "20799" },
    { zip3: "206", validPDX: true, bucket: "21", ndc_postal: "20799" },
    { zip3: "207", validPDX: true, bucket: "21", ndc_postal: "20799" },
    { zip3: "208", validPDX: true, bucket: "21", ndc_postal: "20799" },
    { zip3: "209", validPDX: true, bucket: "21", ndc_postal: "20799" },
    { zip3: "210", validPDX: true, bucket: "21", ndc_postal: "20799" },
    { zip3: "211", validPDX: true, bucket: "21", ndc_postal: "20799" },
    { zip3: "212", validPDX: true, bucket: "21", ndc_postal: "20799" },
    { zip3: "214", validPDX: true, bucket: "21", ndc_postal: "20799" },
    { zip3: "215", validPDX: true, bucket: "21", ndc_postal: "20799" },
    { zip3: "216", validPDX: true, bucket: "21", ndc_postal: "20799" },
    { zip3: "217", validPDX: true, bucket: "21", ndc_postal: "20799" },
    { zip3: "218", validPDX: true, bucket: "21", ndc_postal: "20799" },
    { zip3: "219", validPDX: true, bucket: "21", ndc_postal: "20799" },
    { zip3: "220", validPDX: true, bucket: "21", ndc_postal: "20799" },
    { zip3: "221", validPDX: true, bucket: "21", ndc_postal: "20799" },
    { zip3: "222", validPDX: true, bucket: "21", ndc_postal: "20799" },
    { zip3: "223", validPDX: true, bucket: "21", ndc_postal: "20799" },
    { zip3: "224", validPDX: true, bucket: "21", ndc_postal: "20799" },
    { zip3: "225", validPDX: true, bucket: "21", ndc_postal: "20799" },
    { zip3: "226", validPDX: true, bucket: "21", ndc_postal: "20799" },
    { zip3: "227", validPDX: true, bucket: "21", ndc_postal: "20799" },
    { zip3: "228", validPDX: true, bucket: "21", ndc_postal: "20799" },
    { zip3: "229", validPDX: true, bucket: "21", ndc_postal: "20799" },
    { zip3: "230", validPDX: true, bucket: "21", ndc_postal: "20799" },
    { zip3: "231", validPDX: true, bucket: "21", ndc_postal: "20799" },
    { zip3: "232", validPDX: true, bucket: "21", ndc_postal: "20799" },
    { zip3: "233", validPDX: true, bucket: "21", ndc_postal: "20799" },
    { zip3: "234", validPDX: true, bucket: "21", ndc_postal: "20799" },
    { zip3: "235", validPDX: true, bucket: "21", ndc_postal: "20799" },
    { zip3: "236", validPDX: true, bucket: "21", ndc_postal: "20799" },
    { zip3: "237", validPDX: true, bucket: "21", ndc_postal: "20799" },
    { zip3: "238", validPDX: true, bucket: "21", ndc_postal: "20799" },
    { zip3: "239", validPDX: true, bucket: "21", ndc_postal: "20799" },
    { zip3: "244", validPDX: true, bucket: "21", ndc_postal: "20799" },
    { zip3: "267", validPDX: true, bucket: "21", ndc_postal: "20799" },
    { zip3: "268", validPDX: true, bucket: "21", ndc_postal: "20799" },
  ];
  // alert(input.receiver.zip.slice(0,3));
  // alert(ndc_array.filter(e=>e.zip3==input.receiver.zip.slice(0,3))[0].validPDX)
  return "presort_test" in service
    ? ndc_array.filter((e) => e.zip3 == input.receiver.zip.slice(0, 3))[0]
        .validPDX
    : true;
};

const machinableTest = (input, service) => {
  let measurements = [
    parseFloat(input.package.dimensions[0]["length"]),
    parseFloat(input.package.dimensions[0]["height"]),
    parseFloat(input.package.dimensions[0]["width"]),
  ];
  console.log(measurements);

  measurements = measurements.sort((a, b) => (Number(a) > Number(b) ? 1 : -1));

  // console.log(measurements);
  // console.log((parseFloat(measurements[2]) >= 6)
  // && (parseFloat(measurements[1]) >= 3)
  // && (parseFloat(measurements[0]) >= .25))
  return "machinable_test" in service
    ? parseFloat(measurements[2]) >= 6 &&
        parseFloat(measurements[1]) >= 3 &&
        parseFloat(measurements[0]) >= 0.25
    : true;
};

const weightTest = (input, service) => {
  return service.max_weight
    ? parseFloat(input.package.dimensions[0].weight) <= service.max_weight
    : true;
};

const dimensionTest = (input, service) => {
  let measurements = [
    parseFloat(input.package.dimensions[0]["length"]),
    parseFloat(input.package.dimensions[0]["height"]),
    parseFloat(input.package.dimensions[0]["width"]),
  ];
  measurements = measurements.sort((a, b) => (Number(a) > Number(b) ? 1 : -1));

  return (
    (service.max_length
      ? parseFloat(measurements[2]) <= service.max_length
      : true) &&
    (service.max_width
      ? parseFloat(measurements[1]) <= service.max_width
      : true) &&
    (service.max_height
      ? parseFloat(measurements[0]) <= service.max_height
      : true)
  );
};

const resolver = (input, mediamailFlag) => {
  // console.log(input)
  //tests if package is a tube, sets height to prevent checking functions from breaking
  if (input.package.type == "tube") {
    input.package.dimensions[0].length = input.package.dimensions[0].width;
  }

  let permissions =
    input.account && input.account.user && input.account.user.usps_rates
      ? input.account.user.usps_rates
      : [];
  // console.log(permissions)
  return serviceConstaints
    .filter(
      (service) =>
        (!service.permissionRequired &&
          (service.package_type
            ? service.package_type.toUpperCase() ==
              input.package.type.toUpperCase()
            : true)) ||
        (permissions[service.rate_type] &&
          (service.package_type
            ? service.package_type.toUpperCase() ==
              input.package.type.toUpperCase()
            : true))
    )
    .filter((service) =>
      service.rate_type == "MM-PRSS"
        ? service.rate_type == "MM-PRSS" && mediamailFlag == true
        : true
    )
    .map((service) => {
      // console.log(service)
      return {
        service: service,
        qualify:
          dimensionTest(input, service) &&
          (input.package.type === "tube" ? tubeTest(input, service) : true) &&
          weightTest(input, service) &&
          additionalTest1(input, service) &&
          cubicTest(input, service) &&
          machinableTest(input, service) &&
          presortTest(input, service),
        dimensionTest: dimensionTest(input, service),
        weightTest: weightTest(input, service),
        additionalTest1: additionalTest1(input, service),
        cubicTest: cubicTest(input, service),
        machinableTest: machinableTest(input, service),
        tubeTest:
          input.package.type === "tube" ? tubeTest(input, service) : true,
        presort_test: presortTest(input, service),
      };
    });
};

export default resolver;
