import {
  FormControl,
  InputLabel,
  NativeSelect,
  Select,
} from "@material-ui/core";
import { NativeSelectProps } from "@material-ui/core/NativeSelect";

type StateProvinceSelectProps = {
  country?: string;
} & NativeSelectProps;

export const StateProvinceSelect = ({
  country,
  ...props
}: StateProvinceSelectProps) => {
  if (country === "CA") {
    return <SelectWithProvinces {...props} />;
  }

  if (country === "US") {
    return <SelectWithStates {...props} />;
  }

  return (
    <FormControl fullWidth={props.fullWidth}>
      <InputLabel error={props.error} required={props.required}>
        Province
      </InputLabel>
      <Select {...props} displayEmpty></Select>
    </FormControl>
  );
};

const SelectWithProvinces = ({
  country,
  ...props
}: StateProvinceSelectProps) => {
  return (
    <FormControl fullWidth={props.fullWidth}>
      <InputLabel error={props.error} required={props.required}>
        Province
      </InputLabel>
      <NativeSelect {...props}>
        <option value=""></option>
        <option value="AB">Alberta</option>
        <option value="BC">British Columbia</option>
        <option value="MB">Manitoba</option>
        <option value="NB">New Brunswick</option>
        <option value="NL">Newfoundland and Labrador</option>
        <option value="NT">Northwest Territories</option>
        <option value="NS">Nova Scotia</option>
        <option value="NU">Nunavut</option>
        <option value="ON">Ontario</option>
        <option value="PE">Prince Edward Island</option>
        <option value="QC">Quebec</option>
        <option value="SK">Saskatchewan</option>
        <option value="YT">Yukon</option>
      </NativeSelect>
    </FormControl>
  );
};

const SelectWithStates = ({ country, ...props }: StateProvinceSelectProps) => {
  return (
    <FormControl fullWidth={props.fullWidth}>
      <InputLabel error={props.error} required={props.required}>
        State
      </InputLabel>
      <NativeSelect {...props}>
        <option value=""></option>
        <option value="AL">Alabama</option>
        <option value="AK">Alaska</option>
        <option value="AZ">Arizona</option>
        <option value="AR">Arkansas</option>
        <option value="CA">California</option>
        <option value="CO">Colorado</option>
        <option value="CT">Connecticut</option>
        <option value="DE">Delaware</option>
        <option value="DC">District of Columbia</option>
        <option value="FL">Florida</option>
        <option value="GA">Georgia</option>
        <option value="HI">Hawaii</option>
        <option value="ID">Idaho</option>
        <option value="IL">Illinois</option>
        <option value="IN">Indiana</option>
        <option value="IA">Iowa</option>
        <option value="KS">Kansas</option>
        <option value="KY">Kentucky</option>
        <option value="LA">Louisiana</option>
        <option value="ME">Maine</option>
        <option value="MD">Maryland</option>
        <option value="MA">Massachusetts</option>
        <option value="MI">Michigan</option>
        <option value="MN">Minnesota</option>
        <option value="MS">Mississippi</option>
        <option value="MO">Missouri</option>
        <option value="MT">Montana</option>
        <option value="NE">Nebraska</option>
        <option value="NV">Nevada</option>
        <option value="NH">New Hampshire</option>
        <option value="NJ">New Jersey</option>
        <option value="NM">New Mexico</option>
        <option value="NY">New York</option>
        <option value="NC">North Carolina</option>
        <option value="ND">North Dakota</option>
        <option value="OH">Ohio</option>
        <option value="OK">Oklahoma</option>
        <option value="OR">Oregon</option>
        <option value="PA">Pennsylvania</option>
        <option value="PR">Puerto Rico</option>
        <option value="RI">Rhode Island</option>
        <option value="SC">South Carolina</option>
        <option value="SD">South Dakota</option>
        <option value="TN">Tennessee</option>
        <option value="TX">Texas</option>
        <option value="UT">Utah</option>
        <option value="VT">Vermont</option>
        <option value="VA">Virginia</option>
        <option value="WA">Washington</option>
        <option value="WV">West Virginia</option>
        <option value="WI">Wisconsin</option>
        <option value="WY">Wyoming</option>
      </NativeSelect>
    </FormControl>
  );
};
