import {
  Button,
  FilledInput,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
// import LushOpenClaim from './LushOpenClaim';
import { fade } from "@material-ui/core/styles/colorManipulator";
import Typography from "@material-ui/core/Typography";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { Auth } from "aws-amplify";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import listShipments2 from "../api/listInboundShipments";
import InboundShipment from "./InboundShipment";
import ShipmentRow from "./InboundShipmentRow";

// import MobileFixedSearch from './MobileFixedSearch';

const styles = (theme) => ({
  text: {
    paddingTop: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  button: {
    margin: theme.spacing.unit * 1.5,
  },
  paper: {
    // paddingBottom: 50,
  },
  list: {
    // marginBottom: theme.spacing.unit * 2,
    // maxHeight: '500px',
    // overflow: 'scroll',
    paddingTop: 0,
  },
  subHeader: {
    // backgroundColor: theme.palette.background.paper,
  },
  grey: {
    height: "20px",
    width: "20px",
    backgroundColor: "#FFC300",
  },
  yellow: {
    height: "20px",
    width: "20px",
    backgroundColor: "#FFC300",
  },
  red: {
    height: "20px",
    width: "20px",
    backgroundColor: "#C70039",
  },
  green: {
    height: "20px",
    width: "20px",
    backgroundColor: "#52BE80",
  },
  avatar: {
    background: "transparent",
    color: "grey",
    height: "20px",
    width: "20px",
    [theme.breakpoints.down("sm")]: {
      alignSelf: "flex-start",
      marginTop: "5px",
    },
    [theme.breakpoints.up("sm")]: {
      alignSelf: "center",
    },
  },
  activeAvatar: {
    background: "green",
    transition: ".1s all ease",
    [theme.breakpoints.down("sm")]: {
      alignSelf: "flex-start",
      marginTop: "5px",
    },
    [theme.breakpoints.up("sm")]: {
      alignSelf: "center",
    },
  },
  appBar: {
    top: "auto",
    bottom: 0,
  },
  toolbar: {
    alignItems: "center",
    justifyContent: "space-between",
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto",
  },
  activeButton: {
    background: "#eafaea",
    transition: ".3s all ease",
  },
  button: {
    transition: ".3s all ease",
  },
  greybutton: {
    transition: ".3s all ease",
    background: "#f7f7f7",
  },
  marginbutton: {
    marginTop: "-8px",
    marginBottom: "8px",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  fixedBar: {
    position: "fixed",
    bottom: "5px",
    left: "50%",
    transform: "translateX(-50%)",
  },
  search: {
    position: "absolute",
    right: "20px",
    top: "50%",
    transform: "translateY(-50%)",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    // width: '100%',
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit,
      width: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 120,
      "&:focus": {
        width: 200,
      },
    },
  },
  formControl: {
    float: "right",
    margin: theme.spacing.unit,
    minWidth: 140,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  pageToggles: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "-5px",
    },
  },
});

class InboundShipments extends React.Component {
  state = {
    search: "",
    selected: "",
    modalOpen: false,
    claimOpen: false,
    filtered: [],
    page: 0,
    display: 10,
    sort: '{"status":["none"]}',
    sortPlatform: "none",
    itemsCount: 0,
    selectedStatus: "",
    sortCountry: "none",
    isLoading: true,
    shipments: [],
    disabled: false,
    status: "",
    hasBeenCorrected: false,
    isSearching: false,
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  UNSAFE_componentWillMount = async () => {
    let shipments = await listShipments2(
      this.props.childData.cognitoUsername,
      this.props.childData.email.toUpperCase(),
      this.state.page,
      this.state.display,
      "none",
      JSON.parse(this.state.sort).status,
      "none",
      this.state.search,
      this.props.account &&
        this.props.account.user &&
        this.props.account.user.isParent
        ? true
        : false,
      await Auth.user.username,
      this.state.sortPlatform
    );
    console.log(shipments);
    await this.setState({ shipments: shipments.shipments });
    // alert(this.state.display)
    this.setState({ filtered: this.state.shipments });
    this.itemsCount = this.state.shipments.length;
    this.setState({ isLoading: false });
  };

  handleClose = () => {
    this.setState({ modalOpen: false });
  };

  handleSelectChange = (name) => async (event) => {
    await this.setState({ [name]: event.target.value });
    await this.renderShipments("0", false);
    this.setState({ page: 0 });
    return true;
  };

  clearSearchAndRender = async () => {
    this.setState({ isLoading: true });
    let sample = await listShipments2(
      this.props.childData.cognitoUsername,
      this.props.childData.email.toUpperCase(),
      this.state.page,
      this.state.display,
      this.state.sortCountry,
      JSON.parse(this.state.sort).status,
      "none",
      this.props.account &&
        this.props.account.user &&
        this.props.account.user.isParent
        ? true
        : false,
      await Auth.user.username,
      this.state.sortPlatform
    ).catch((e) => {
      window.location.reload();
    });
    this.setState({ isLoading: false, isSearching: false });
    // console.log(sample);
    if (sample.shipments.length > 0) {
      this.setState({ shipments: sample.shipments });
      this.setState({ disabled: false });
      return true;
    } else {
      this.setState({ shipments: [] });
      this.setState({ disabled: true });
      return false;
    }
  };

  renderShipments = async (page, isNext) => {
    this.setState({ isLoading: true });

    let sample = await listShipments2(
      this.props.childData.cognitoUsername,
      this.props.childData.email.toUpperCase(),
      page,
      this.state.display,
      this.state.sortCountry,
      JSON.parse(this.state.sort).status,
      "none",
      this.state.search,
      this.props.account &&
        this.props.account.user &&
        this.props.account.user.isParent
        ? true
        : false,
      await Auth.user.username,
      this.state.sortPlatform
    ).catch((e) => {
      window.location.reload();
    });
    this.setState({ isLoading: false, isSearching: false });
    // console.log(sample);
    if (sample.shipments.length > 0) {
      this.setState({ shipments: sample.shipments });
      this.setState({ disabled: false });
      return true;
    } else {
      if (!isNext) {
        this.setState({ shipments: [] });
      }
      this.setState({ disabled: true });
      return false;
    }
  };

  updateVisibleShipments = (obj) => {
    var { shipments } = this.state;
    shipments = shipments.map((e) => {
      if (obj.row_ids.indexOf(e.id) > -1) {
        let temp = e;
        let index = obj.row_ids.indexOf(e.id);
        temp.tracking = obj.tracking[index];
        temp.image_url = obj.label_url[index];
        return temp;
      } else {
        return e;
      }
    });
    this.setState({ shipments: shipments });
  };

  render() {
    const { classes } = this.props;

    return (
      <div class="Home">
        <Fragment>
          {/* <MobileFixedSearch className={classes.fixedBar} search={this.state.search} applyFilter={this.handleChange} clearFilter={this.clearFilter} /> */}
          <Typography variant="h1">Inbound Shipments</Typography>
          <div
            style={{ float: "right", marginRight: "20px" }}
            className={classes.pageToggles}
          >
            <IconButton
              disabled={this.state.isLoading || this.state.page === 0}
              className={classes.button}
              style={{ marginRight: "5px" }}
              aria-label="Go Left"
              onClick={async () => {
                this.setState({ isLoading: true });
                await this.renderShipments(this.state.page - 1, true);
                this.setState({ page: this.state.page - 1 });
                this.setState({ disabled: false });
                this.setState({ isLoading: false });
              }}
            >
              <ChevronLeft />
            </IconButton>
            <span
              style={{
                width: "75px",
                fontSize: ".6em",
                verticalAlign: "middle",
              }}
            >
              {this.state.page + 1}
            </span>
            <IconButton
              disabled={this.state.isLoading || this.state.disabled}
              className={classes.button}
              style={{ marginLeft: "5px" }}
              aria-label="Go Right"
              onClick={async () => {
                this.setState({ isLoading: true });
                let temp = await this.renderShipments(
                  this.state.page + 1,
                  true
                );
                if (temp) {
                  this.setState({ page: this.state.page + 1 });
                } else {
                  this.setState({ disabled: true });
                }
                this.setState({ isLoading: false });
              }}
            >
              <ChevronRight />
            </IconButton>
          </div>

          {this.state.selected !== "" && (
            <InboundShipment
              addressCorrected={this.state.hasBeenCorrected}
              status={this.state.status}
              open={this.state.modalOpen}
              handleClose={this.handleClose}
              shipment={this.state.selected}
              image_url={
                this.state.selected.dropoff_reprint_url
                  ? this.state.selected.dropoff_reprint_url
                  : false
              }
              account={this.props.account}
            />
          )}
          {/* {this.state.selected != "" && <LushOpenClaim refreshClaims={this.props.refreshClaims} open={this.state.claimOpen} handleClaimClose={this.handleClaimClose} shipment={this.state.selected} /> } */}

          <Paper square className={classes.paper}>
            <Typography
              className={classes.text}
              variant="h5"
              style={{
                position: "relative",
                background: "var(--brand-cyan)",
                color: "#fff",
              }}
            >
              Inbound to My Location
            </Typography>

            <List className={classes.list}>
              {this.state.isLoading && <LinearProgress color="secondary" />}
              {!this.state.isLoading && this.state.shipments.length === 0 && (
                <ListItem>
                  <p>
                    No shipments found.&nbsp;&nbsp;
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={async () => {
                        this.setState({ sort: '{"status":["none"]}' });
                        this.setState({ sortCountry: "none" });
                        this.setState({ display: 10 });
                        this.setState({ page: 0 });
                        let shipments = await listShipments2(
                          this.props.childData.cognitoUsername,
                          this.props.childData.email.toUpperCase(),
                          0,
                          10,
                          "none",
                          ["none"],
                          "none",
                          this.state.search,
                          this.props.account &&
                            this.props.account.user &&
                            this.props.account.user.isParent
                            ? true
                            : false,
                          await Auth.user.username,
                          this.state.sortPlatform
                        );
                        this.setState({ shipments: shipments.shipments });
                        this.setState({ isLoading: false });
                      }}
                    >
                      Reset Filters
                    </Button>
                  </p>
                </ListItem>
              )}
              {this.state.shipments.map((shipment, count) => (
                <ShipmentRow
                  settings={this.props.settings}
                  completedQueue={this.props.completedQueue}
                  updateVisibleShipments={this.updateVisibleShipments}
                  processQueue={this.props.processQueue}
                  pushProcessQueue={this.props.pushProcessQueue}
                  popProcessQueue={this.props.popProcessQueue}
                  count={count}
                  shipment={shipment}
                  shipmentStatus={(e) => this.setState({ status: e })}
                  openShipment={() => this.setState({ modalOpen: true })}
                  setSelected={(e) => this.setState({ selected: e })}
                  toggleModal={(e) =>
                    this.setState({ modalOpen: !this.state.modalOpen })
                  }
                  toggleLoading={(e) =>
                    this.setState({ isLoading: !this.state.isLoading })
                  }
                />
              ))}
            </List>
          </Paper>
          <FormControl variant="filled" className={classes.formControl}>
            <InputLabel
              ref={(ref) => {
                this.InputLabelRef = ref;
              }}
              htmlFor="outlined-age-native-simple"
            >
              Items per page
            </InputLabel>
            <Select
              native
              value={this.state.display}
              onChange={this.handleSelectChange("display")}
              input={
                <FilledInput
                  name="age"
                  labelWidth={this.state.labelWidth}
                  id="outlined-age-native-simple"
                />
              }
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </Select>
          </FormControl>
        </Fragment>
      </div>
    );
  }
}

InboundShipments.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InboundShipments);
