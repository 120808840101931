import { Button, Divider, Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { AddCircleOutline } from "@material-ui/icons";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import BalanceButton from "../components/BalanceButton";
import Invoices from "../components/Invoices";
import PaymentCard from "../components/PaymentCard";
import PaymentCreate from "../components/PaymentCreate";
// import AccountTopUp from '../components/AccountTopUp';
import Transactions from "../components/Transactions";
import "../styles.css";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  paper: {
    padding: "20px",
  },
});

class Payments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      payment: this.props.payment,
    };
  }

  handleModalOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleModalClose = () => {
    this.setState({ modalOpen: false });
  };

  UNSAFE_componentWillMount = () => {
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });
  };

  language = {
    BillingCentre: {
      FR: "Centre de facturation",
      EN: "Billing Centre",
    },
    NewPaymentMethod: {
      FR: "Nouveau mode de paiement",
      EN: "New Payment Method",
    },
    Balance: {
      FR: "Balance",
      EN: "Balance",
    },
    PaymentMethods: {
      FR: "Modes de paiement",
      EN: "Payment Methods",
    },
    RecentTransactions: {
      FR: "Transactions récentes",
      EN: "Recent Transactions",
    },
    Invoices: {
      FR: "Les factures",
      EN: "Invoices",
    },
  };

  render() {
    return (
      <div className="Home">
        <PaymentCreate
          open={this.state.modalOpen}
          handleClose={this.handleModalClose}
          account={this.state.account}
          {...this.props}
        />
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Typography variant="h1">
              {this.language.BillingCentre[this.state.language]}
            </Typography>
            <Button color="secondary" onClick={this.handleModalOpen}>
              <AddCircleOutline style={{ marginRight: "5px" }} />{" "}
              {this.language.NewPaymentMethod[this.state.language]}
            </Button>
            <Divider />
          </Grid>
          {this.props.account &&
          this.props.account.user &&
          this.props.account.user.noWallet ? (
            ""
          ) : (
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                {this.language.Balance[this.state.language]}
              </Typography>
              <br />
              <BalanceButton
                account={this.props.account}
                createSnackBar={this.props.createSnackBar}
                paymentmethods={this.props.paymentmethods}
                balance={this.props.balance}
                user={this.props.user}
                addPayment={this.props.addPayment}
                handleUserData={this.props.handleUserData}
              />
              <br />
              <br />
              <Divider />
            </Grid>
          )}
          <Grid item xs={12}>
            <div>
              <br />
              <br />
              <Typography variant="h5" gutterBottom>
                {this.language.PaymentMethods[this.state.language]}
              </Typography>
              <br />
              {this.props.payment.map((e, i) => (
                <PaymentCard
                  data={e}
                  key={i}
                  number={i}
                  {...this.props}
                  style={{ display: "inline" }}
                />
              ))}
            </div>
          </Grid>
          <Grid item xs={12}>
            <br />
            <br />
            <Divider />
            <br />
            <br />

            {this.props.account &&
            this.props.account.user &&
            this.props.account.user.noWallet ? (
              ""
            ) : (
              <Fragment>
                <Typography variant="h5" gutterBottom>
                  {this.language.RecentTransactions[this.state.language]}
                </Typography>
                <Transactions
                  language={this.state.language}
                  billing={[]}
                  history={[]}
                  vault={[]}
                />
                <br />
                <br />
                <Divider />
                <br />
                <br />
              </Fragment>
            )}
            <Typography variant="h5" gutterBottom>
              {this.language.Invoices[this.state.language]}
            </Typography>
            <Invoices
              language={this.state.language}
              billing={[]}
              history={[]}
              vault={[]}
              account={this.props.account}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

Payments.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Payments);
