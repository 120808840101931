import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Pay0Stepper from "./pay0pickupstepper";
import Dropoff from "../Dropoff";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import Typography from "@material-ui/core/Typography";
import { Hidden, Tooltip, Chip } from "@material-ui/core";
import { CalendarToday } from "@material-ui/icons";
import DailyPickup from "../DailyPickup";

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
    marginBottom: "50px",
  },
});

class ScrollableTabsButtonForce extends React.Component {
  handleChange = (event, value) => {
    this.props.setData("tab", value);
  };

  render() {
    const { classes, ...childProps } = this.props;
    const language = {
      Howwouldyouliketosendyourpackages: {
        FR: (
          <span>
            Comment souhaitez-vous{" "}
            <Hidden mdUp>
              <br />
            </Hidden>
            envoyer vos colis
          </span>
        ),
        EN: (
          <span>
            How would you like to{" "}
            <Hidden mdUp>
              <br />
            </Hidden>
            send your packages?
          </span>
        ),
      },
      Findalocaldropoffcentre: {
        FR: (
          <span>
            Trouver un lieu{" "}
            <Hidden mdUp>
              <br />
            </Hidden>
            de dépôt local
          </span>
        ),
        EN: (
          <span>
            Find a local{" "}
            <Hidden mdUp>
              <br />
            </Hidden>
            dropoff centre
          </span>
        ),
      },
      Pickupfromyourlocation: {
        FR: (
          <span>
            Ramassage à partir
            <Hidden mdUp>
              <br />
            </Hidden>
            de votre emplacement
          </span>
        ),
        EN: (
          <span>
            Pickup from{" "}
            <Hidden mdUp>
              <br />
            </Hidden>
            your location <br />
          </span>
        ),
      },
      Usedailypickup: {
        FR: (
          <span>
            Utiliser le{" "}
            <Hidden mdUp>
              <br />
            </Hidden>
            ramassage quotidien
          </span>
        ),
        EN: (
          <span>
            Use daily{" "}
            <Hidden mdUp>
              <br />
            </Hidden>
            pickup <br />
          </span>
        ),
      },
    };
    return (
      <Grow in={true}>
        <Paper
          className={classes.root}
          elevation={1}
          style={{ background: "#fff" }}
        >
          {/* <div className={classes.root}> */}
          <AppBar position="static" color="primary">
            <h3 style={{ textAlign: "center" }}>
              <br />
              {language.Howwouldyouliketosendyourpackages[this.props.language]}
            </h3>
            <Tabs value={this.props.tab} onChange={this.handleChange} centered>
              {this.props.account &&
              this.props.account.user &&
              this.props.account.user.cannotDropoff ? (
                ""
              ) : (
                <Tab
                  label={language.Findalocaldropoffcentre[this.props.language]}
                  value={1}
                  icon={
                    <img
                      style={{ height: "20px" }}
                      alt={"Swiftpost"}
                      src="https://s3.amazonaws.com/swiftpost-img/icon-swiftpost.png"
                    />
                  }
                />
              )}
              <Tab
                disabled={this.props.account.user.hasDailyPickup}
                label={language.Pickupfromyourlocation[this.props.language]}
                value={0}
                icon={<PersonPinIcon />}
              />
              {this.props.account
                ? this.props.account.user
                  ? this.props.account.user.hasDailyPickup && (
                      <Tab
                        label={language.Usedailypickup[this.props.language]}
                        value={2}
                        icon={<CalendarToday />}
                      />
                    )
                  : ""
                : ""}
            </Tabs>
          </AppBar>
          {this.props.tab === 0 && (
            <TabContainer>
              <Pay0Stepper
                {...childProps}
                senderLocationID={this.props.senderLocationID}
                account={this.props.account}
                handleLocationChange={this.props.handleLocationChange}
              />
            </TabContainer>
          )}
          {this.props.tab === 1 && (
            <TabContainer>
              <Dropoff {...childProps} account={this.props.account} />
            </TabContainer>
          )}
          {this.props.tab === 2 && (
            <TabContainer>
              <DailyPickup {...childProps} account={this.props.account} />
            </TabContainer>
          )}
          {/* </div> */}
        </Paper>
      </Grow>
    );
  }
}

ScrollableTabsButtonForce.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ScrollableTabsButtonForce);
