import { API } from "aws-amplify";

var try_count = 0;

const canpar_getBundleRate = (input, callback) => {
  try_count = try_count + 1;

  API.post("sqs", "/bundle/rate", {
    body: input,
  })
    .then((e) => {
      callback(e);
    })
    .catch((e) => {
      // console.log(e)
      //RETRY POLICY,
      // if(try_count > 3){
      //     // console.log("tried 3 times and failed")
      //     return;
      // } else{
      //     canpar_getBundleRate(input, callback)
      // }
    });
};

export default canpar_getBundleRate;
