import { API, graphqlOperation } from "aws-amplify";

export const onDeleteLocation = (user: string) =>
  API.graphql(
    graphqlOperation(`
      subscription {
        onDeleteV1_Location(cognitoUsername:"${user}"){
          id
          cognitoUsername
        }
      }
    `)
  );
