import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { round } from "../../helpers/TaskRunners";
import LoadingIcon from "../loading/LoadingIcon";

const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  menu: {
    width: 200,
  },
  formControl: {
    margin: theme.spacing.unit,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  formControl: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2,
    display: "block",
  },
  resetContainer: {
    padding: theme.spacing.unit * 2,
  },
});

class TextFields extends React.Component {
  state = {};

  UNSAFE_componentWillMount = async () => {
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });
  };

  language = {
    inches: {
      FR: "pouces",
      EN: "inches",
    },
    pounds: {
      FR: "livre",
      EN: "pounds",
    },
    Receiver: {
      FR: "Le Destinataire",
      EN: "Receiver",
    },
    Sender: {
      FR: "L'Expéditeur",
      EN: "Sender",
    },
    Package: {
      FR: "Paquet",
      EN: "Package",
    },
    Packages: {
      FR: "Paquets",
      EN: "Packages",
    },
    Qty: {
      FR: "Qté",
      EN: "Qty",
    },
    value: {
      FR: "valeur",
      EN: "value",
    },
    Service: {
      FR: "Niveau de Service",
      EN: "Service",
    },
    Edit: {
      FR: "Éditer",
      EN: "Edit",
    },
    Back: {
      FR: "Arrière",
      EN: "Back",
    },
    Submit: {
      FR: "Soumettre",
      EN: "Submit",
    },
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  renderPackages(arr) {
    if (arr.length > 1) {
      return [{}].concat(arr).map((note, i) => {
        if (i !== 0) {
          return (
            <Fragment>
              {note.length} {this.language.inches[this.props.language]} x{" "}
              {note.width} {this.language.inches[this.props.language]} x{" "}
              {note.height} {this.language.inches[this.props.language]},{" "}
              {note.weight} {this.language.pounds[this.props.language]}
              <br />
            </Fragment>
          );
        }
      });
    } else {
      return (
        <Fragment>
          {arr[0]["length"]} {this.language.inches[this.props.language]} x{" "}
          {arr[0].width} {this.language.inches[this.props.language]} x{" "}
          {arr[0].height} {this.language.inches[this.props.language]},{" "}
          {arr[0].weight} {this.language.pounds[this.props.language]}
          <br />
        </Fragment>
      );
    }
  }

  putShipment = () => {
    // let location = await API.graphql(graphqlOperation(`mutation {
    //     createV1_Shipment(input:{
    //         title:"${payment.alias}"
    //         card:{
    //           token:"${e.token}"
    //           pan:"${(payment.pan).replace(/.(?=.{4,}$)/g, '*')}"
    //           exp:"${payment.exp}"
    //           year:"${payment.year}"
    //           month:"${payment.month}"
    //           type:"${payment.type}"
    //         }
    //         cardholder: {
    //             address: {
    //                 street_name: "${cardholder.location.address.street_name}",
    //                 street_number: "${cardholder.location.address.street_number}",
    //                 ${cardholder.location.street_direction ? `street_direction: "${cardholder.location.street_direction}",` : ``}
    //                 postal: "${cardholder.location.address.postal}",
    //                 city: "${cardholder.location.address.city}",
    //                 ${cardholder.location.unit ? `unit: "${cardholder.location.unit}",` : ``}
    //                 state: "${cardholder.location.address.state}",
    //                 country: "${cardholder.location.address.country}",
    //                 coords_lat: "${cardholder.location.address.coords_lat}",
    //                 coords_long: "${cardholder.location.address.coords_long}"
    //             }
    //             contact: {
    //                 fname: "${cardholder.location.contact.fname}",
    //                 lname: "${cardholder.location.contact.lname}",
    //                 ${cardholder.location.contact.email ? `email: "${cardholder.location.contact.email}",` : ``}
    //                 ${cardholder.location.contact.phone ? `phone: "${cardholder.location.contact.phone}",` : ``}
    //             }
    //         }
    //       }){
    //         id
    //         cognitoUsername
    //       }
    // }`));
  };

  render() {
    const { classes } = this.props;
    // console.log(this.props)
    var rate;
    this.props.editRec && this.props.rates.length == 0
      ? (rate = this.props.editRate.amount)
      : (rate = this.props.rates[this.props.selectedService].rate);

    rate = parseFloat(rate).toFixed(2);

    let sender = this.props.locations.filter(
      (e) => e.id === this.props.senderLocationID
    );
    sender = sender[0];

    return (
      <div>
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography variant="subheading">
            <div style={{ maxWidth: "250px", lineHeight: "36px" }}>
              <strong>{this.language.Receiver[this.props.language]}</strong>
              <Button
                color="secondary"
                style={{ verticalAlign: "middle", float: "right" }}
                onClick={() => this.props.handleGoToStep(0)}
              >
                {this.language.Edit[this.props.language]}
              </Button>
            </div>
            {this.props.receiver.business && (
              <span>
                {this.props.receiver.business}
                <br />
              </span>
            )}
            Attn: {this.props.receiver.fname} {this.props.receiver.lname}
            <br />
            {this.props.receiver.unit && (
              <span>#{this.props.receiver.unit}&nbsp;-&nbsp;</span>
            )}
            {this.props.receiver.address}
            <br />
            {this.props.receiver.address2 ? (
              <span>
                {this.props.receiver.address2}
                <br />
              </span>
            ) : (
              ""
            )}
            {this.props.receiver.city}, {this.props.receiver.state}{" "}
            {this.props.receiver.zip}
          </Typography>
        </Paper>
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography variant="subheading">
            <div style={{ maxWidth: "250px", lineHeight: "36px" }}>
              <strong>{this.language.Sender[this.props.language]}</strong>{" "}
              <Button
                color="secondary"
                style={{ verticalAlign: "middle", float: "right" }}
                onClick={() => this.props.handleGoToStep(1)}
              >
                {this.language.Edit[this.props.language]}
              </Button>
            </div>
            {sender.title}
            <br />
            {sender.address.unit ? (
              <span>#{sender.address.unit}&nbsp;-&nbsp;</span>
            ) : (
              ``
            )}
            {sender.address.street_number} {sender.address.street_name}
            <br />
            {sender.address.city}, {sender.address.state}{" "}
            {sender.address.postal}
          </Typography>
        </Paper>
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography variant="subheading">
            <div style={{ maxWidth: "250px", lineHeight: "36px" }}>
              <strong>
                {this.props.package.dimensions.length == 1
                  ? this.language.Package[this.props.language]
                  : this.language.Packages[this.props.language]}
              </strong>{" "}
              <Button
                color="secondary"
                style={{ verticalAlign: "middle", float: "right" }}
                onClick={() => this.props.handleGoToStep(2)}
              >
                {this.language.Edit[this.props.language]}
              </Button>
            </div>
            {this.renderPackages(this.props.package.dimensions)}
            {this.props.package.insured == "true" && (
              <Fragment>
                {this.props.package.description},{" "}
                {this.language.Qty[this.props.language]}{" "}
                {this.props.package.quantity}
                <br />${this.props.package.value}{" "}
                {this.language.value[this.props.language]}
              </Fragment>
            )}
          </Typography>
        </Paper>
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography variant="subheading">
            <div style={{ maxWidth: "250px", lineHeight: "36px" }}>
              <strong>{this.language.Service[this.props.language]}</strong>{" "}
              <Button
                color="secondary"
                style={{ verticalAlign: "middle", float: "right" }}
                onClick={() => this.props.handleGoToStep(3)}
              >
                {this.language.Edit[this.props.language]}
              </Button>
            </div>
            {this.props.editRec && this.props.rates.length == 0
              ? this.props.editRate.service.name
              : this.props.rates[this.props.selectedService].service.service
                  .name}
            <br />${round(rate)}
            <br />
            {/* Approximately {this.props.rates[this.props.selectedService].service.service.delivery_ground} days */}
          </Typography>
        </Paper>
        <Paper square elevation={0} className={classes.resetContainer}>
          <Button
            disabled={this.props.isLoading}
            onClick={() => this.props.handleGoToStep(2)}
            className={classes.button}
          >
            {this.language.Back[this.props.language]}
          </Button>
          <Button
            disabled={this.props.isLoading}
            onClick={() => this.props.createShipment()}
            className={classes.button}
            variant="contained"
            color="primary"
          >
            {!this.props.isLoading ? (
              this.language.Submit[this.props.language]
            ) : (
              <LoadingIcon size={20} />
            )}
          </Button>
        </Paper>
      </div>
    );
  }
}

TextFields.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TextFields);
