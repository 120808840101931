/* eslint-disable react/no-multi-comp */

import { Divider, IconButton, TextField, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import blue from "@material-ui/core/colors/blue";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Edit } from "@material-ui/icons";
import React from "react";
import uuid from "uuid";
import {
  isAlphaNumericWithApostrophe,
  isAlphaNumericWithSpace,
  isValidEmail,
  isValidPhoneNumber,
  isValidPostal,
} from "../../../helpers/Validation";

const styles = {
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
};

class EditDialog extends React.Component {
  state = {
    id: "",
    open: false,
    email: "",
    fname: "",
    lname: "",
    name: "",
    unit: "",
    street_name: "",
    street_number: "",
    postal: "",
    state: "",
    country: "CA",
    status: 0,
    child_sub: "",
    location_id: "",
    phone: "",
    account_id: "",
    needsEmail: false,
    isLoading: false,
  };

  handleListItemClick = (value) => {
    this.props.onClose(value);
  };

  handleChange = (name) => (event) => {
    let tempString = event.target.value;
    this.setState({ [name]: tempString.toUpperCase() });
  };

  checkState = async () => {
    this.setState({
      emailErrorText:
        this.props.data.id === ""
          ? this.props.isEmailAvailable(this.state.email)
            ? ""
            : "Email exists"
          : "",
      emailError:
        this.state.email === "" ||
        (this.props.data.id === "" &&
          !this.props.isEmailAvailable(this.state.email)) ||
        !isValidEmail(this.state.email) ||
        (await this.props.checkEmails(this.state.email)),
      fnameError:
        this.state.fname === "" || !isAlphaNumericWithSpace(this.state.fname),
      lnameError:
        this.state.lname === "" || !isAlphaNumericWithSpace(this.state.lname),
      businessError:
        this.state.business === "" ||
        !isAlphaNumericWithApostrophe(this.state.business),
      street_nameError:
        this.state.street_name === "" ||
        !isAlphaNumericWithSpace(this.state.street_name),
      street_numberError:
        this.state.street_number === "" ||
        !isAlphaNumericWithSpace(this.state.street_number),
      postalError:
        this.state.postal === "" || !isValidPostal(this.state.postal),
      stateError:
        this.state.state === "" || !isAlphaNumericWithSpace(this.state.state),
      cityError:
        this.state.city === "" || !isAlphaNumericWithSpace(this.state.city),
      country: "CA",
      phoneError:
        this.state.phone === "" || !isValidPhoneNumber(this.state.phone),
    });

    let response =
      (this.props.data.id === ""
        ? this.props.isEmailAvailable(this.state.email) &&
          !(await this.props.checkEmails(this.state.email))
        : true) &&
      this.state.email !== "" &&
      isValidEmail(this.state.email) &&
      this.state.fname !== "" &&
      isAlphaNumericWithSpace(this.state.fname) &&
      this.state.lname !== "" &&
      isAlphaNumericWithSpace(this.state.lname) &&
      this.state.business !== "" &&
      isAlphaNumericWithApostrophe(this.state.fname) &&
      this.state.street_name !== "" &&
      isAlphaNumericWithSpace(this.state.street_name) &&
      this.state.street_number !== "" &&
      isAlphaNumericWithSpace(this.state.street_number) &&
      this.state.postal !== "" &&
      isValidPostal(this.state.postal) &&
      this.state.state !== "" &&
      isAlphaNumericWithSpace(this.state.state) &&
      this.state.city !== "" &&
      isAlphaNumericWithSpace(this.state.city) &&
      this.state.phone !== "" &&
      isValidPhoneNumber(this.state.phone);

    if (!response) {
      this.setState({ isLoading: false });
    }
    return response;
  };

  componentDidMount = () => {
    this.setState({
      needsEmail: this.props.data.needsEmail
        ? this.props.data.needsEmail
        : this.props.data.status == "-1",
      account_id: this.props.data.account_id ? this.props.data.account_id : "",
      location_id: this.props.data.location_id
        ? this.props.data.location_id
        : "",
      email: this.props.data.email ? this.props.data.email : "",
      business: this.props.data.business ? this.props.data.business : "",
      fname: this.props.data.fname ? this.props.data.fname : "",
      lname: this.props.data.lname ? this.props.data.lname : "",
      unit: this.props.data.unit ? this.props.data.unit : "",
      street_name: this.props.data.street_name
        ? this.props.data.street_name
        : "",
      street_number: this.props.data.street_number
        ? this.props.data.street_number
        : "",
      postal: this.props.data.postal ? this.props.data.postal : "",
      state: this.props.data.state ? this.props.data.state : "",
      city: this.props.data.city ? this.props.data.city : "",
      country: "CA",
      status: this.props.data.status !== "-1" ? this.props.data.status : 0,
      child_sub: this.props.data.child_sub ? this.props.data.child_sub : "",
      phone: this.props.data.phone ? this.props.data.phone : "",
      id: this.props.data.id ? this.props.data.id : "",
    });
  };

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <Typography variant="h6">
              {this.props.data.id !== "" ? "Update" : "Create"} Child Account
            </Typography>
            <br />
            {/* <DialogContentText id="alert-dialog-description">
                Search by Postal Code
            </DialogContentText> */}
            <TextField
              error={this.state.businessError}
              required
              id={new uuid.v4()}
              label="Lo&zwnj;ca&zwnj;tion Na&zwnj;me"
              margin="normal"
              onChange={this.handleChange("business")}
              fullWidth
              // disabled={this.props.data.business !== ""}
              variant="outlined"
              style={{ marginTop: 10, marginBottom: 10 }}
              value={this.state.business}
            />

            <TextField
              error={this.state.emailError}
              required
              helperText={this.state.emailErrorText}
              id={new uuid.v4()}
              label="Ema&zwnj;il Addr&zwnj;ess"
              margin="normal"
              onChange={this.handleChange("email")}
              fullWidth
              disabled={this.props.data.status}
              variant="outlined"
              value={this.state.email}
              style={{ marginTop: 10, marginBottom: 10 }}
              type="email"
            />
            <TextField
              error={this.state.phoneError}
              required
              id={new uuid.v4()}
              label="P&zwnj;ho&zwnj;ne Nu&zwnj;mb&zwnj;er"
              margin="normal"
              onChange={this.handleChange("phone")}
              fullWidth
              variant="outlined"
              value={this.state.phone}
              style={{ marginTop: 10, marginBottom: 10 }}
            />
            <TextField
              error={this.state.fnameError}
              required
              id={new uuid.v4()}
              label="Fir&zwnj;st Na&zwnj;me"
              margin="normal"
              variant="outlined"
              value={this.state.fname}
              onChange={this.handleChange("fname")}
              style={{
                marginTop: 10,
                width: "45%",
                marginBottom: 10,
                marginRight: "5%",
              }}
            />
            <TextField
              error={this.state.lnameError}
              required
              id={new uuid.v4()}
              label="La&zwnj;st Na&zwnj;me"
              margin="normal"
              variant="outlined"
              value={this.state.lname}
              onChange={this.handleChange("lname")}
              style={{ marginTop: 10, width: "50%", marginBottom: 10 }}
            />
            <TextField
              error={this.state.street_numberError}
              required
              id={new uuid.v4()}
              label="S&zwnj;tr&zwnj;eet N&zwnj;um&zwnj;ber"
              margin="normal"
              variant="outlined"
              value={this.state.street_number}
              onChange={this.handleChange("street_number")}
              style={{
                marginTop: 10,
                width: "45%",
                marginBottom: 10,
                marginRight: "5%",
              }}
            />
            <TextField
              id={uuid.v4()}
              required
              variant="outlined"
              error={this.state.street_nameError}
              label="St&zwnj;re&zwnj;et Na&zwnj;me"
              value={this.state.street_name}
              disabled={this.props.isLoading}
              onChange={this.handleChange("street_name")}
              InputLabelProps={{ shrink: this.state.street_name !== "" }}
              inputProps={{
                list: "streets",
                type: "text",
                autoComplete: "off",
                autoCorrect: "off",
                autoCapitalize: "none",
                spellCheck: "false",
              }}
              margin="normal"
              style={{ marginTop: 10, width: "50%", marginBottom: 10 }}
            />
            <datalist id="streets">
              {this.props.streetArr.map((e) => (
                <option value={e} />
              ))}
            </datalist>
            <TextField
              error={this.state.unitError}
              id={new uuid.v4()}
              label="U&zwnj;ni&zwnj;t"
              fullWidth
              margin="normal"
              variant="outlined"
              value={this.state.unit}
              onChange={this.handleChange("unit")}
              style={{ marginTop: 10, marginBottom: 10 }}
            />
            <br />
            <br />
            <Divider />
            <br />
            <TextField
              required
              disabled={true}
              id={new uuid.v4()}
              label="P&zwnj;os&zwnj;tal C&zwnj;o&zwnj;de"
              margin="normal"
              fullWidth
              variant="outlined"
              value={this.state.postal}
              style={{
                marginTop: 10,
                width: "45%",
                marginBottom: 10,
                marginRight: "5%",
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={() => this.props.editPostal(this.state)}
                    >
                      <Edit />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              required
              error={this.state.cityError}
              id={new uuid.v4()}
              label="C&zwnj;it&zwnj;y"
              margin="normal"
              fullWidth
              disabled={true}
              variant="outlined"
              value={this.state.city}
              style={{ marginTop: 10, width: "50%", marginBottom: 10 }}
            />
            <TextField
              error={this.state.stateError}
              required
              disabled={true}
              id={new uuid.v4()}
              label="Pr&zwnj;ovi&zwnj;nce"
              margin="normal"
              fullWidth
              value={this.state.state}
              variant="outlined"
              style={{
                marginTop: 10,
                width: "45%",
                marginBottom: 10,
                marginRight: "5%",
              }}
            />
            <TextField
              required
              disabled={true}
              id={new uuid.v4()}
              label="Cou&zwnj;ntr&zwnj;y"
              margin="normal"
              fullWidth
              value={this.state.country}
              variant="outlined"
              style={{ marginTop: 10, width: "50%", marginBottom: 10 }}
            />
            <br />
            <br />
            <Divider />
            <br />
            <Button
              style={{ marginTop: 20, marginBottom: 30 }}
              onClick={async () => {
                this.setState({ isLoading: true });
                if (this.props.data.id !== "") {
                  if (await this.checkState()) {
                    await this.props.updateAccount(this.state);
                  }
                } else {
                  if (await this.checkState()) {
                    await this.props.createAccount([this.state]);
                  }
                }
              }}
              disabled={this.state.isLoading}
              fullWidth
              variant="contained"
              color="primary"
            >
              {this.props.data.id !== "" ? "Save account" : "Create account"}
            </Button>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default EditDialog;
