import { Button, Grid, Theme, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import "../../styles.css";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import { createSelfDeliveryAccount, querySelfDeliveryAccount } from "api/selfDelivery";
import PagePaper from "pages/PagePaper";
import config from "../../config";

//defaulting self delivery aos radius to 2km
const DEFAULT_RADIUS_KM = 2, MINIMUM_RADIUS_KM = .5;

const styles = (theme: Theme) => ({
  topActions: {
    display: "flex",
    justifyContent: "space-between",
    margin: "1em 0 2em",
  },
});

export function CreateSelfDeliveryAccount(props: { classes: any; account:any; locations:any, history:any }) {
    const [radius, setRadius] = React.useState<number>(DEFAULT_RADIUS_KM * 1000);
    const [error, setError] = React.useState<string>();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [saving, setSaving] = React.useState<boolean>(false);
    const [step, setStep] = React.useState<number>(0);
    const [center, setCenter] = React.useState<{lat: number, lng: number}>({
      lat: Number(props?.account?.location?.address?.coords_lat),
      lng: Number(props?.account?.location?.address?.coords_long),
    });
    let map: google.maps.Map;
    let circle: google.maps.Circle;
    let autocomplete: google.maps.places.Autocomplete;

    function initMap(): void {
      map =  new window.google.maps.Map(document.getElementById("map2")!, {
          streetViewControl: false,
          mapTypeControl: false,
          center: {
            lat: Number(props?.account?.location?.address?.coords_lat),
            lng: Number(props?.account?.location?.address?.coords_long),
          },
          zoom: 11,
          mapTypeId: "roadmap",
          styles: [
            {
              featureType: "administrative.land_parcel",
              elementType: "labels",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "poi",
              elementType: "labels.text",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "poi.business",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "poi.medical",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "poi.park",
              stylers: [
                {
                  visibility: "simplified",
                },
              ],
            },
            {
              featureType: "poi.place_of_worship",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "poi.sports_complex",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "labels.icon",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "road.local",
              elementType: "labels",
              stylers: [
                {
                  visibility: "on",
                },
              ],
            },
            {
              featureType: "road.local",
              elementType: "labels.icon",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "transit",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
          ],
        });
   }

   function initAutocomplete(): void {
      autocomplete = new google.maps.places.Autocomplete(document.getElementById("autocomplete")! as HTMLInputElement, {
        componentRestrictions: { country: "ca" },
        types: ['address'],
      });
      autocomplete.addListener('place_changed', function () {
        const place = autocomplete.getPlace();
        const latlng = {lat:place?.geometry?.location?.lat() ?? 0, lng:place?.geometry?.location?.lng() ?? 0};
        circle.setCenter(latlng);
        setCenter(latlng);
        map.panTo(latlng);
      })
   }

   function initCircle(): void {
        circle = new google.maps.Circle({
          editable: true,
          strokeColor: '#FF0000',
          strokeOpacity: 0.5,
          strokeWeight: 2,
          fillColor: '#FF0000',
          fillOpacity: 0.35,
          map: map,
          center: {
              lat: Number(props.account.location.address.coords_lat),
              lng: Number(props.account.location.address.coords_long),
          },
          radius: DEFAULT_RADIUS_KM * 1000, //meters
        });
        circle.addListener('radius_changed', function() {
          setRadius(roundTenth(circle.getRadius()));
          if (circle.getRadius() < MINIMUM_RADIUS_KM * 1000) {
              circle.setRadius(roundTenth(MINIMUM_RADIUS_KM * 1000));
          }
        });
        circle.addListener('center_changed', function() {
          const new_center = circle.getCenter();
          setCenter({lat: new_center?.lat() ?? 0, lng: new_center?.lng() ?? 0 });
        });
    };

    const roundTenth = (num: number) => Math.round(num * 10) / 10;

    const processData = async (existingAccount:boolean) => {
      setSaving(true);
      const timeZone = await getTimeZone();
      const state = uuidv4();
      await createSelfDeliveryAccount({
        state,
        origin_center: center,
        radius_km: roundTenth(radius / 1000),
        contact_email: props.account.location.contact.email,
        contact_phone: props.account.location.contact.phone,
      });
      window.location.href = existingAccount ? 
        `https://${config.routes.API_URL}/?client_id=${config.routes.APP_CLIENT_ID}&response_type=code&redirect_uri=https://${process.env.REACT_APP_STAGE === "prod" ? `8fe3g0x2nf` : `bqtdk07jgb`}.execute-api.us-east-1.amazonaws.com/${process.env.REACT_APP_STAGE === "prod" ? `prod` : `dev`}/self-delivery/callback&state=${state}&email=${encodeURIComponent(props.account.location.contact.email)}&firstName=${encodeURIComponent(props.account.location.contact.fname)}&lastName=${encodeURIComponent(props.account.location.contact.lname)}&organizationName=${encodeURIComponent(props.account.location.title)}&location[name]=${encodeURIComponent(props.account.location.title)}&location[address]=${encodeURIComponent(`${props.account.location.address.street_number} ${props.account.location.address.street_name}`)}&location[locality]=${encodeURIComponent(props.account.location.address.city)}&location[region]=${encodeURIComponent(props.account.location.address.state)}&location[postalcode]=${encodeURIComponent(props.account.location.address.postal)}&location[country]=CA&location[timeZone]=${timeZone}&location[lat]=${center.lat}&location[lon]=${center.lng}`
        :
        `https://${config.routes.API_URL}/sign_up?client_id=${config.routes.APP_CLIENT_ID}&response_type=code&redirect_uri=https://${process.env.REACT_APP_STAGE === "prod" ? `8fe3g0x2nf` : `bqtdk07jgb`}.execute-api.us-east-1.amazonaws.com/${process.env.REACT_APP_STAGE === "prod" ? `prod` : `dev`}/self-delivery/callback&state=${state}&email=${encodeURIComponent(props.account.location.contact.email)}&firstName=${encodeURIComponent(props.account.location.contact.fname)}&lastName=${encodeURIComponent(props.account.location.contact.lname)}&organizationName=${encodeURIComponent(props.account.location.title)}&location[name]=${encodeURIComponent(props.account.location.title)}&location[address]=${encodeURIComponent(`${props.account.location.address.street_number} ${props.account.location.address.street_name}`)}&location[locality]=${encodeURIComponent(props.account.location.address.city)}&location[region]=${encodeURIComponent(props.account.location.address.state)}&location[postalcode]=${encodeURIComponent(props.account.location.address.postal)}&location[country]=CA&location[timeZone]=${timeZone}&location[lat]=${center.lat}&location[lon]=${center.lng}`;
    };

    async function getRoutesConnection() {
      setLoading(true);
      setError(``);
      const response = await querySelfDeliveryAccount();
      if(response.integration_id){
        console.log(response);
        props.history.push("/self-delivery");
      } else{
        setError("Error: Unable to find self-delivery connection");
        setLoading(false);
      }
    }

    const getTimeZone = async () => {
      const response = await axios.get(`https://maps.googleapis.com/maps/api/timezone/json?location=${center.lat}%2C${center.lng}&timestamp=1331161200&key=AIzaSyBNMRsO80tsOGxVUIJbrDKw0DuUSvFrIhc`);
      return response?.data?.timeZoneId ?? "America/Toronto";
    };

    React.useEffect(()=>{
      if(step === 1){
        initMap();
        initAutocomplete();
        initCircle();
      }
    }, [step]);


  return (
    <div className="Home">
    <Typography variant="h1">Self-Delivery</Typography>
    <Grid container>
      <Grid item xs={12} md={8} lg={6} xl={4}>
      <PagePaper>
          {
              step === 0 ? <>
              <h2>Use your own delivery assets to reduce costs and delight your customers.</h2>
              <p>Self-Delivery is a new delivery model that enables businesses to deliver more profitably – with efficient, decision-free experiences for both customers and employees.</p>
              <p>Using Routes™️ Self-Delivery technologies, Swiftpost customers are able to create shipments, print labels, and deploy drivers in just minutes.</p>
              <br/>
              <p><Button color="primary" onClick={()=>setStep(1)}>Connect to Routes</Button></p>
              {/* <br/> */}
              {/* <small>Already have an account? <a target="_blank" href={`https://app${process.env.REACT_APP_STAGE === "prod" ? `` : `-staging`}.routes.io/?client_id=227to12k97robnie7d81tnja3l&response_type=code&redirect_uri=http://localhost:3008/callback&state=${new Date().getTime()}`}>Sign in</a></small> */}
              </>
              :
              <>
              <h2>Set a location for your self-delivery area.</h2>
        <div style={{position:'relative'}}>
          {/* {step == 2 && <div style={{
            zIndex:9999,
            position: 'absolute',
            top:0,
            left:0,
            width:'100%',
            padding:30,
            height:'100%',
            background: "rgba(255,255,255,.6)",
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
          }}>
            <div>
            <div style={{background:"#fff", padding:30, borderRadius: 10, boxShadow: "0px 12px 24px 4px rgba(0, 0, 0, 0.08)", }}>
              <p>A window should automatically open to complete your registration for Routes self-delivery.<br/><br/>
              If the window does not open, you may have to enable popups for app.swiftpost.com in your browser.<br/><br/>
              <strong>Press next once you have successfully created and verified your Routes self-delivery account</strong>.</p>
              <br/>
              <Button disabled={loading} color="primary" onClick={()=>{ getRoutesConnection(); }} target="_blank">Next</Button>
              {error && <p><small style={{color:"#ff0000"}}>{error}</small></p>}

            </div>
            <p style={{opacity:.9}}><small><a href="#" onClick={()=>processData()}>Open registration window again</a></small></p>
          </div>
          </div>} */}
          <div id="map2" style={{ height: 500, width: '100%', borderRadius:10 }} />
          <input id="autocomplete" onKeyDown={(e)=>{
            if (e.keyCode === 13) {
              e.preventDefault();
            }
          }}
          defaultValue={`${props.account.location.address.street_number} ${props.account.location.address.street_name}, ${props.account.location.address.city}, ${props.account.location.address.state}, Canada`}
          className="form-control places-autocomplete"
          type="text"
          placeholder="Enter an address"
          autoFocus
          style={{
            zIndex: 1,
            top:10,
            left: 10,
            position:'absolute',
            padding: 10,
            border: "3px solid var(--deep-blue)",
            borderRadius: 10,
            paddingLeft: "1em",
            width: '50%',
            boxShadow: "0px 12px 24px 4px rgba(0, 0, 0, 0.08)",
          }} />
          <div style={{
            background: "#fff",
            borderRadius:10,
            padding:10,
            position:'absolute',
            bottom:10,
            left:10,
            zIndex:1,
            boxShadow: "0px 12px 24px 4px rgba(0, 0, 0, 0.08)",

          }}><strong>Delivery Radius: <span style={{color:"var(--deep-blue)"}}>{(roundTenth(radius / 1000))} KM</span></strong></div>
        </div>
        <br/>
        <Button onClick={()=>setStep(0)} target="_blank">Back</Button>
        <Button style={{marginLeft: 10}} disabled={saving || step != 1} color="primary" onClick={()=>{ processData(false); }} target="_blank">Create Routes account</Button>
        <Button style={{marginLeft: 10}} disabled={saving || step != 1} color="primary" onClick={()=>{ processData(true); }} target="_blank">Link existing Routes account</Button>
      </>
          }
      </PagePaper>
      </Grid>
    </Grid>
  </div>
  );
}

CreateSelfDeliveryAccount.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CreateSelfDeliveryAccount);
