import { getCookie } from "./getCookie";

export type Language = "EN" | "FR";

export function getLanguage(): Language {
  const cookieValue = getCookie("language");
  if (cookieValue) {
    return cookieValue as Language;
  } else {
    return "EN";
  }
}
