import { Grid, Paper, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import React from "react";
import Stepper from "../components/pharma_shipment/Stepper";
import SnackBar from "../components/Snackbar";
// import LoadingIcon from '../components/loading/LoadingIcon';
import {
  isAlphaNumeric,
  isAlphaNumericWithSpace,
  isNumericOnly,
  isValidPhoneNumber,
  isValidPostal,
} from "../helpers/Validation";

const styles = (theme) => ({
  textField: {
    width: "100%",
    boxSizing: "border-box",
    fontSize: "1.5em",
  },
  menu: {
    width: 200,
  },
  formControl: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2,
  },
  resetContainer: {
    padding: theme.spacing.unit * 3,
  },
});

class CreateShipmentPharma extends React.Component {
  state = {
    isLoading: false,
    openSnack: false,
    location: {
      company: "",
    },
    contact: {
      fname: "",
      lname: "",
      phone: "",
      email: "",
    },
    address: {
      street_name: "",
      street_number: "",
      street_direction: "",
      postal: "",
      city: "",
      state: "",
    },
    errors: {
      business: false,
      fname: false,
      lname: false,
      phone: false,
      email: false,
      address: false,
      city: false,
      postal: false,
    },
  };

  backup = {};

  getCoords = (postal) => {
    return new Promise((res, rej) => {
      var request = new XMLHttpRequest();
      request.onreadystatechange = function () {
        if (request.readyState == 4 && request.status == 200) {
          if (JSON.parse(request.responseText).results.length > 0) {
            let coords = JSON.parse(request.responseText).results[0].geometry
              .viewport.northeast;
            // console.log(coords)
            // console.log({lng: JSON.parse(request.responseText).results[0].geometry.viewport.northeast.lat, lat: request.responseText.results[0].geometry.viewport.northeast.lat})
            res({ lng: coords.lng, lat: coords.lat });
          } else {
            res(false);
            // rej(true)
          }
        }
      };
      request.open(
        "GET",
        `https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyBNMRsO80tsOGxVUIJbrDKw0DuUSvFrIhc&address=${
          this.state.address.street_number
        }+${this.state.address.street_name},+${this.state.address.city},+${
          this.state.address.state
        },+${this.state.address.postal.split(" ").join("")}`
      );
      request.send();
    });
  };

  language = {
    CAShipment: {
      FR: "Expédition aux Canada",
      EN: "Canadian Shipment",
    },
  };

  UNSAFE_componentWillMount = async () => {
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });
    // ithis.setState({this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length - 1])
    // let { id } = useParams();
    // alert(id)
    // if(this.props.edit == 'true'){
    let edit_id =
      this.props.location.pathname.indexOf("edit") > -1 &&
      this.props.location.pathname.split("/")[
        this.props.location.pathname.split("/").length - 1
      ];
    // alert(edit_id)
    if (edit_id) {
      this.setState({ edit: edit_id });
      // console.log(this.props.shipments.filter(e=>e.id===edit_id)[0])
      let shipment = this.props.shipments.filter((e) => e.id === edit_id)[0];

      this.setState({ currentStep: Number(shipment.rate.amount) == 0 ? 2 : 4 });

      this.setState({
        editReceiver: `{
          "verified": "false",
          ${
            shipment.receiver.title != null
              ? `"business": "${shipment.receiver.title}",`
              : '"business":"",'
          }
          "fname": "${
            shipment.receiver.contact.fname != " "
              ? shipment.receiver.contact.fname
              : ""
          }",
          "lname": "${
            shipment.receiver.contact.lname != " "
              ? shipment.receiver.contact.lname
              : ""
          }",
          "attention": "${
            shipment.receiver.contact.fname != " "
              ? shipment.receiver.contact.fname
              : ""
          }${
          shipment.receiver.contact.lname != " "
            ? ` ${shipment.receiver.contact.lname}`
            : ""
        }",
          "address": "${
            shipment.receiver.address.street_number &&
            shipment.receiver.address.street_number != null &&
            shipment.receiver.address.street_number != " "
              ? `${shipment.receiver.address.street_number} `
              : ""
          }${
          shipment.receiver.address.street_name != " "
            ? shipment.receiver.address.street_name
            : ""
        }",
          "unit": "${
            shipment.receiver.address.unit != null
              ? shipment.receiver.address.unit
              : ""
          }",
          "address2": "${
            shipment.receiver.address.address2 != null
              ? shipment.receiver.address.address2
              : ""
          }",
          "city": "${
            shipment.receiver.address.city != " "
              ? shipment.receiver.address.city
              : ""
          }",
          "state": "${
            shipment.receiver.address.state != " "
              ? shipment.receiver.address.state
              : ""
          }",
          "zip": "${
            shipment.receiver.address.postal != " "
              ? shipment.receiver.address.postal
              : ""
          }",
          "zip5": "${
            shipment.receiver.address.postal != " "
              ? shipment.receiver.address.postal
              : ""
          }",
          "country": "${
            shipment.receiver.address.country != " "
              ? shipment.receiver.address.country
              : ""
          }",
          ${
            shipment.receiver.contact.phone != null
              ? `"phone": "${shipment.receiver.contact.phone}",`
              : '"phone":"",'
          }
          ${
            shipment.receiver.contact.email != null
              ? `"email": "${shipment.receiver.contact.email}",`
              : '"email":"",'
          }
          "street_name": "${
            shipment.receiver.address.street_name &&
            shipment.receiver.address.street_name != null &&
            shipment.receiver.address.street_name != " "
              ? shipment.receiver.address.street_name
              : " "
          }",
          "street_number": "${
            shipment.receiver.address.street_number &&
            shipment.receiver.address.street_number != null &&
            shipment.receiver.address.street_number != " "
              ? shipment.receiver.address.street_number
              : " "
          }",
          "is_residential": "false"
        }`,
      });

      this.setState({
        editSender: `{
        "address": {
          "city": "${shipment.sender.address.city}",
          "coords_lat": "0",
          "coords_long": "0",
          "country": "${shipment.sender.address.country}",
          "postal": "${shipment.sender.address.postal}",
          "state": "${shipment.sender.address.state}",
          "street_name": "${
            shipment.sender.address.street_number != null &&
            shipment.sender.address.street_number != " "
              ? shipment.sender.address.street_number
              : ""
          }${
          shipment.sender.address.street_name != " "
            ? shipment.sender.address.street_name
            : ""
        }",
          "street_number": "${
            shipment.sender.address.street_number != null
              ? shipment.sender.address.street_number
              : ""
          }"
        },
        "contact": {
          "fname": "${shipment.sender.contact.fname}",
          "lname": "${shipment.sender.contact.lname}"
        },
        "title": "${shipment.sender.title}"
      }`,
      });

      this.setState({
        editPackage: `{
            ${
              shipment.contents != null && shipment.contents.origin != null
                ? `"origin": "${shipment.contents.origin}",`
                : `"origin":"CA",`
            }
            "ref": "${shipment.reference}",
            ${
              shipment.package != null
                ? `"count": "${shipment.package.entered_pld.length}",`
                : '"count":"1",'
            }
            ${
              shipment.contents != null && shipment.contents.title != null
                ? `"description": "${shipment.contents.title}",`
                : '"description": "",'
            }
            ${
              shipment.contents != null &&
              shipment.contents.item_quantity != null
                ? `"quantity": "${shipment.contents.item_quantity}",`
                : '"quantity": "",'
            }
            ${
              shipment.contents != null && shipment.contents.item_value != null
                ? `"value": "${shipment.contents.item_value}",`
                : '"value": "",'
            }
            "insured": "false",
            "type": "box",
            ${
              shipment.package != null
                ? `
            "dimensions": [${shipment.package.entered_pld
              .map((e) => {
                return `{
                "weight": "${e.weight}",
                "height": "${e.height}",
                "length": "${e["length"]}",
                "width": "${e.width}"
              }`;
              })
              .join(",")}]`
                : `"dimensions": [
              {
                "weight": "",
                "height": "",
                "length": "",
                "width": ""
              }
            ]`
            }
          }`,
      });

      this.setState({
        editRate: Number(shipment.rate.amount) > 0 ? shipment.rate : false,
      });
      this.setState({
        sender_id: shipment.sender_id
          ? shipment.sender_id
          : this.props.locations[0].id,
      });

      //     console.log({editPackage: `{
      //       ${shipment.contents != null ? `"origin": "${shipment.contents.origin}",`:''}
      //       "ref": "${shipment.reference}",
      //       ${shipment.package != null ? `"count": "${shipment.package.entered_pld.length}",` : '"count":"",'}
      //       ${shipment.contents != null ? `"description": "${shipment.contents.title}",`:'"description": "",'}
      //       ${shipment.contents != null ? `"quantity": "${shipment.contents.item_quantity}",`:'"quantity": "",'}
      //       ${shipment.contents != null ? `"value": "${shipment.contents.item_value}",`:'"value": "",'}
      //       "insured": "false",
      //       "type": "box",
      //       ${shipment.package != null ? `
      //       "dimensions": [${shipment.package.entered_pld.map(e=>{
      //         return `{
      //           "weight": "${e.weight}",
      //           "height": "${e.height}",
      //           "length": "${e['length']}",
      //           "width": "${e.width}"
      //         }`
      //       }).join(',')}]` : `"dimensions": [{
      //         "weight":0,
      //         "height":0,
      //         "length":0,
      //         "width":0}
      //       ]`}
      //     }`
      // })
    }
    // {
    //   insured: false,
    //   insured_value: 0,
    //   rateError: false,
    //   phoneError: false,
    //   emailError: false,
    //   modalOpen: false,
    //   snackOpen: false,
    //   poBoxWarning:false,
    //   id: "",
    //   streetArr: [],
    //   activeStep: 0,
    //   oneStep: 0,
    //   twoStep: 0,
    //   receiver: {
    // verified: false,
    // business: "",
    // fname: "",
    // lname: "",
    // attention: "",
    // address: "",
    // address2: "",
    // city: "",
    // state: "",
    // zip: "",
    // zip5: "",
    // country: "CA",
    // phone: "",
    // email: "",
    // street_name: "",
    // street_number: "",
    // is_residential: false
    //   },
    //   package: {
    //     origin: "CA",
    //     ref: "",
    //     count: "1",
    //     description: "",
    //     quantity: "",
    //     value: "",
    //     insured: false,
    //     type: "box",
    //     dimensions: [
    //       {
    //         weight: "",
    //         height: "",
    //         length: "",
    //         width: ""
    //       }
    //     ]
    //   },
    //   senderLocationID: this.props.locations.length
    //     ? this.props.locations[0].id
    //     : 0,
    //   senderLocation: this.props.locations.length ? this.props.locations[0] : "",
    //   selectedService: 0,
    //   rates: []
    // };
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleObjChange = (name, objName) => (event) => {
    let temp = this.state[objName];
    temp[name] = event.target.value;
    this.setState({ [objName]: temp });
  };

  setObjectValue = (name, objName, value) => {
    // console.log(name, objName, value)
    let temp = this.state[objName];
    temp[name] = value;
    // console.log(temp)
    this.setState({ [objName]: temp });
  };

  handleIsAlphaNumeric = (name, obj) => (event) =>
    isAlphaNumeric(event.target.value) || event.target.value === ""
      ? this.setObjectValue(name, obj, event.target.value)
      : "";
  handleIsAlphaNumericWithSpace = (name, obj) => (event) =>
    isAlphaNumericWithSpace(event.target.value) || event.target.value === ""
      ? this.setObjectValue(name, obj, event.target.value)
      : "";
  handleIsValidPhoneNumber = (name, obj) => (event) =>
    isValidPhoneNumber(event.target.value) || event.target.value === ""
      ? this.setObjectValue(name, obj, event.target.value)
      : "";
  handleIsValidPostal = (name, obj) => (event) =>
    isValidPostal(event.target.value) || event.target.value === ""
      ? this.setObjectValue(name, obj, event.target.value)
      : "";
  handleIsNumericOnly = (name, obj) => (event) =>
    isNumericOnly(event.target.value) || event.target.value === ""
      ? this.setObjectValue(name, obj, event.target.value)
      : "";

  reset = () => {
    // alert("RESETTING")
    // console.log(this.backup)
    this.setState({ contact: this.backup.location.contact });
    this.setState({ address: this.backup.location.address });
    this.setState({ location: this.backup.location });
    // console.log(this.state.location)
    this.clearErrors();
  };

  clearErrors = () => {
    var temp = {
      businessError: false,
      fnameError: false,
      lnameError: false,
      phoneError: false,
      emailError: false,
      addressError: false,
      cityError: false,
      postalError: false,
    };
    this.setState({ errors: temp });
  };

  // validateForm = async () => {
  //   this.setState({isLoading: true});
  //   await this.clearErrors();
  //   var temp = this.state.errors;
  //   var hasErrors = false;
  //   if(this.state.location.title === ""){ temp.business=true; hasErrors=true; }
  //   if(this.state.contact.fname === ""){ temp.fname=true; hasErrors=true; }
  //   if(this.state.contact.lname === ""){ temp.lname=true; hasErrors=true; }
  //   if(this.state.contact.phone === "" || !isValidPhoneNumber(this.state.contact.phone) ){ temp.phone=true; hasErrors=true; }
  //   if(this.state.address.street_name === ""){ temp.address=true; hasErrors=true; }
  //   if(this.state.address.street_number === ""){ temp.address=true; hasErrors=true; }
  //   if(this.state.address.city === ""){ temp.city=true; hasErrors=true; }
  //   if(this.state.address.postal === "" || !isValidPostal(this.state.address.postal)){ temp.zip=true; hasErrors=true; }
  //   await this.setState({errors: temp});
  //   if(
  //     this.state.location.title != "" &&
  //     this.state.contact.fname != "" &&
  //     this.state.contact.lname != "" &&
  //     (this.state.contact.phone != "" && isValidPhoneNumber(this.state.contact.phone)) &&
  //     this.state.address.street_name != "" &&
  //     this.state.address.street_number != "" &&
  //     this.state.address.city != "" &&
  //     (this.state.address.postal != "" && isValidPostal(this.state.address.postal))
  //   ) {
  //   if(!hasErrors){
  //     console.log(hasErrors)
  //     let coords = { lat: 0, lng: 0 };
  //     coords = await this.getCoords(this.state.address.postal);
  //     if(coords === false){
  //       this.setState({isLoading: false});
  //       let errors = {zip:true, address:true, city: true, state: true}
  //       this.setState({ errors});
  //       return;
  //     }
  //     this.setState({coords});

  //     if(this.props.account){
  //       let account = await API.graphql(graphqlOperation(`mutation {
  //         updateV1_Account(input:{
  //           id:"${this.props.account.id}"
  //           location:{
  //             title:"${this.state.location.title}"
  //             address:{
  //               street_name:"${this.state.address.street_name}"
  //               street_number:"${this.state.address.street_number}"
  //               postal:"${this.state.address.postal}"
  //               state:"${this.state.address.state}"
  //               country:"CA"
  //               ${this.state.address.unit ? `unit:"${this.state.address.unit}"` : ``}
  //               city:"${this.state.address.city}"
  //               coords_lat:"${this.state.coords.lat}"
  //               coords_long:"${this.state.coords.lng}"
  //             }
  //             contact: {
  //               fname:"${this.state.contact.fname}"
  //               lname:"${this.state.contact.lname}"
  //               email:"${this.state.contact.email}"
  //               phone:"${this.state.contact.phone}"
  //             }
  //           }
  //         }){
  //           id
  //           cognitoUsername
  //         }
  //       }`));
  //     } else{
  //       let account = await API.graphql(graphqlOperation(`mutation {
  //         createV1_Account(input:{
  //           location:{
  //             title:"${this.state.location.title}"
  //             address:{
  //               street_name:"${this.state.address.street_name}"
  //               street_number:"${this.state.address.street_number}"
  //               postal:"${this.state.address.postal}"
  //               state:"${this.state.address.state}"
  //               country:"CA"
  //               ${this.state.address.unit ? `unit:"${this.state.address.unit}"` : ``}
  //               city:"${this.state.address.city}"
  //               coords_lat:"${this.state.coords.lat}"
  //               coords_long:"${this.state.coords.lng}"
  //             }
  //             contact: {
  //               fname:"${this.state.contact.fname}"
  //               lname:"${this.state.contact.lname}"
  //               email:"${this.state.contact.email}"
  //               phone:"${this.state.contact.phone}"
  //             }
  //           }
  //         }){
  //           id
  //           cognitoUsername
  //         }
  //       }`));
  //     }

  //     this.setState({openSnack: true});
  //   }
  //   }
  //   this.setState({isLoading: false});
  // }

  render() {
    const { classes } = this.props;

    return (
      <div className="Home">
        <SnackBar
          style={{ zIndex: 999999, position: "fixed" }}
          variant={"success"}
          open={this.state.openSnack}
          message={"Successfully updated account."}
          handleClose={() => {
            this.setState({ openSnack: false });
          }}
        />
        <div className="notes">
          <Typography variant="h1">Pharmaceutical Delivery</Typography>
          <Paper square className={classes.root} elevation={1}>
            <Grid container xs={12} lg={12}>
              <Stepper
                editID={this.state.edit}
                sender_id={this.state.sender_id}
                editRate={this.state.editRate}
                currentStep={this.state.currentStep}
                editSender={this.state.editSender}
                editPkg={this.state.editPackage}
                editRec={this.state.editReceiver}
                account={this.props.account}
                goToOrders={() => {}}
                locations={this.props.locations}
                getRates={this.props.getRate}
              />
            </Grid>
            {/* <Grid container xs={12} lg={6}>
              <ShipmentStepperMobile />
            </Grid> */}
          </Paper>
        </div>
      </div>
    );
  }
}

CreateShipmentPharma.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CreateShipmentPharma);
