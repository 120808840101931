/* eslint-disable react/no-multi-comp */
// import { API } from "aws-amplify";

import Button from "@material-ui/core/Button";
import blue from "@material-ui/core/colors/blue";
import Dialog from "@material-ui/core/Dialog";
import MobileStepper from "@material-ui/core/MobileStepper";
import { withStyles } from "@material-ui/core/styles";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { getCookie } from "helpers/getCookie";
// import { Auth } from "aws-amplify";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import updateAccount from "../api/app/updateAccount";
import {
  isAlphaNumeric,
  isAlphaNumericWithSpace,
  isNumericOnly,
  isValidPhoneNumber,
  isValidPostal,
} from "../helpers/Validation";
import Form1 from "./NewUserForm1";
// import Form2 from "./NewUserForm2";
import Form3 from "./NewUserForm3";

const styles = (theme) => ({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 50,
    paddingLeft: theme.spacing.unit * 4,
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 255,
    maxWidth: 400,
    overflow: "hidden",
    display: "block",
    width: "100%",
  },
});

class SimpleDialog extends React.Component {
  state = {
    activeStep: 0,
    fname: "",
    lname: "",
    shipmentCount: "",
    business: "",
    errorObj: {
      zip: "",
      phone: "",
      address: "",
      street_name: "",
      street_number: "",
      fname: "",
      lname: "",
    },
    shipToCA: false,
    shipToUSA: false,
    shipToINT: false,
    phone: "",
    street_number: "",
    street_name: "",
    zip: "",
    city: "",
    state: "",
  };

  UNSAFE_componentWillMount = () => {
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });
  };

  language = {
    WelcometoSwiftpost: {
      FR: "Bienvenue sur Swiftpost",
      EN: "Welcome to Swiftpost",
    },
    Finish: {
      FR: "Terminer",
      EN: "Finish",
    },
    Next: {
      FR: "Suivant",
      EN: "Next",
    },
    Back: {
      FR: "Arrière",
      EN: "Back",
    },
  };

  componentDidMount = async () => {
    let email = await Auth.currentSession();
    this.setState({ email: email.idToken.payload.email });
  };

  updateState = (name) => (event) => {
    if (event.target.value.length < 40) {
      if (event.target.value === "") {
        this.setState({ [name]: event.target.value });
      }
      if (name === "phone" || name === "shipmentCount") {
        if (!isNumericOnly(event.target.value)) {
          return;
        }
      } else if (
        name === "street_name" ||
        name === "street_number" ||
        name === "fname" ||
        name === "lname" ||
        name === "business"
      ) {
        if (!isAlphaNumericWithSpace(event.target.value)) {
          return;
        }
      } else {
        if (!isAlphaNumeric(event.target.value)) {
          return;
        }
      }
      // console.log(this.state.name)
      // console.log(name, event.target.value)
      this.setState({ [name]: event.target.value });
    }
  };

  handleChange = (name) => (event) => {
    // console.log(name)
    // console.log(event)
    this.setState({ [name]: event.target.checked });
  };

  searchPostal() {
    return new Promise(async (res, rej) => {
      await this.setState({ isLoading: true });
      API.post("sqs", "/address/verify", {
        body: {
          postal: this.state.zip,
          country: "CA",
        },
      })
        .then(async (e) => {
          await this.setState({ city: e.city });
          await this.setState({ state: e.state });
          res(true);
        })
        .catch(async (e) => {
          await this.setState({ isLoading: false });
          let temp = this.state.errorObj;
          temp.address = true;
          temp.zip = true;
          this.setState({ errorObj: temp });
          res(false);
        });
    });
  }

  getCoords = () => {
    return new Promise((res, rej) => {
      var request = new XMLHttpRequest();
      request.onreadystatechange = function () {
        if (request.readyState === 4 && request.status === 200) {
          if (JSON.parse(request.responseText).results.length > 0) {
            console.log(JSON.parse(request.responseText).results[0])
            let coords = JSON.parse(request.responseText).results[0].geometry.location;
            console.log(coords);
            // console.log({lng: JSON.parse(request.responseText).results[0].geometry.viewport.northeast.lat, lat: request.responseText.results[0].geometry.viewport.northeast.lat})
            res({ lng: coords.lng, lat: coords.lat });
          } else {
            res(false);
          }
        }
      };
      request.open(
        "GET",
        `https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyBNMRsO80tsOGxVUIJbrDKw0DuUSvFrIhc&address=${this.state.street_number}%20${this.state.street_name},+${this.state.city},+${this.state.state},+${this.state.zip}+CANADA`
      );
      request.send();
    });
  };

  handleNext = async () => {
    if (this.state.activeStep === 2) {
      if (
        this.state.fname != "" &&
        this.state.lname != "" &&
        isValidPhoneNumber(this.state.phone) &&
        isValidPostal(this.state.zip)
      ) {
        if (await this.searchPostal()) {
          let coords = await this.getCoords();
          if (coords === false) {
            let temp = this.state.errorObj;
            temp.address = true;
            temp.zip = true;
            this.setState({ errorObj: { address: true, zip: true } });
            await this.setState({ isLoading: false });

            return;
          }
          // let names = {
          //   fname: this.state.name,
          // }
          // if(this.state.name.indexOf(" ") > -1){
          //   names = {
          //     fname: this.state.name.split(" ")[0],
          //     lname: this.state.name.split(" ")[1]
          //   }
          // }
          let business = "";
          if (this.state.business != "") {
            business = this.state.business;
          }
          await updateAccount(
            {
              name: this.state.name,
              business,
              avg_shipments: this.state.shipmentCount,
              can: this.state.shipToCA,
              usa: this.state.shipToUSA,
              intl: this.state.shipToINT,
              phone: this.state.phone,
              state: this.state.state,
              city: this.state.city,
              coords: coords,
              address: this.state.address,
              zip: this.state.zip,
              fname: this.state.fname,
              lname: this.state.lname,
              balanceID: this.props.balanceID,
            },
            async (e) => {
              let account = await API.graphql(
                graphqlOperation(`mutation {
                  updateV1_Account(input:{
                    id:"${this.props.account.id}"
                    location:{
                      title:"${
                        this.state.business
                          ? this.state.business
                          : `${this.state.fname} ${this.state.lname}`
                      }"
                      address:{
                        street_name:"${this.state.street_name}"
                        street_number:"${this.state.street_number}"
                        postal:"${this.state.zip}"
                        state:"${this.state.state}"
                        country:"CA"
                        city:"${this.state.city}"
                        coords_lat:"${coords.lat}"
                        coords_long:"${coords.lng}"
                      }
                      contact: {
                        fname:"${this.state.fname}"
                        lname:"${this.state.lname}"
                        email:"${this.state.email}"
                        phone:"${this.state.phone}"
                      } 
                    }
                  }){
                    id
                    cognitoUsername
                  }
                }`)
              );
              await this.props.handleUserData();
              //create location
              let location = await API.graphql(
                graphqlOperation(`mutation {
                  createV1_Location(input:{
                    title:"${
                      this.state.business
                        ? this.state.business
                        : `${this.state.fname} ${this.state.lname}`
                    }"
                    address:{
                      street_name:"${this.state.street_name}"
                      street_number:"${this.state.street_number}"
                      postal:"${this.state.zip}"
                      state:"${this.state.state}"
                      country:"CA"
                      city:"${this.state.city}"
                      coords_lat:"${coords.lat}"
                      coords_long:"${coords.lng}"
                    }
                    contact: {
                      fname:"${this.state.fname}"
                      lname:"${this.state.lname}"
                      email:"${this.state.email}"
                      phone:"${this.state.phone}"
                    }
                  }){
                    id
                    cognitoUsername
                  }
                }`)
              );
              this.handleClose();
              //push user to self-delivery page
              this.props.history.push("/self-delivery")
            }
          );
          // alert("TRYING TO UPADTE")
          // console.log(this.state);
        }
      } else {
        var temp = this.state.errorObj;
        !isValidPostal(this.state.zip) || this.state.zip.length != 6
          ? (temp.zip = true)
          : (temp.zip = false);
        !isValidPhoneNumber(this.state.phone)
          ? (temp.phone = true)
          : (temp.phone = false);

        this.setState({ errorObj: temp });
      }
    } else {
      this.setState((prevState) => ({
        activeStep: prevState.activeStep + 1,
      }));
    }
  };

  handleBack = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep - 1,
    }));
  };

  handleClose = () => {
    this.props.onClose(this.props.selectedValue);
  };

  handleListItemClick = (value) => {
    this.props.onClose(value);
  };

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const tutorialSteps = [
      <Fragment>
        <div style={{ background: "var(--brand-cyan)", padding: "50px 50px" }}>
          <h2 style={{ color: "white" }}>
            {this.language.WelcometoSwiftpost[this.state.language]}!
          </h2>
          <br />
        </div>
      </Fragment>,
      <Form1 {...this.state} updateState={this.updateState} />,
      // <Form2
      //   {...this.state}
      //   updateState={this.updateState}
      //   handleChange={this.handleChange}
      // />,
      <Form3
        {...this.state}
        updateState={this.updateState}
        handleChange={this.handleChange}
      />,
    ];
    const { classes, theme } = this.props;
    const { activeStep } = this.state;
    const maxSteps = tutorialSteps.length;
    return (
      <Dialog
        open={this.props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.root}>
          {tutorialSteps[activeStep]}
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            className={classes.mobileStepper}
            nextButton={
              this.state.activeStep === 2 ? (
                <Button
                  size="small"
                  onClick={() => this.handleNext()}
                  disabled={
                    this.state.isLoading ||
                    (this.state.activeStep === 2
                      ? this.state.phone === "" ||
                        this.state.zip === "" ||
                        this.state.street_name === "" ||
                        this.state.street_number === ""
                      : false)
                  }
                >
                  {this.language.Finish[this.state.language]}
                  {<KeyboardArrowRight />}
                </Button>
              ) : (
                <Button
                  size="small"
                  onClick={() => this.handleNext()}
                  disabled={
                    (this.state.activeStep === 1
                      ? this.state.fname === "" || this.state.lname === ""
                      : false) ||
                    (this.state.activeStep === 2
                      ? this.state.shipmentCount === ""
                      : false)
                  }
                >
                  {this.language.Next[this.state.language]}
                  {<KeyboardArrowRight />}
                </Button>
              )
            }
            backButton={
              this.state.activeStep === 0 ? (
                <Button></Button>
              ) : (
                // <Button size="small" onClick={this.props.handleLogout}>
                //   Logout
                // </Button>
                <Button
                  size="small"
                  onClick={this.handleBack}
                  disabled={activeStep === 0}
                >
                  {<KeyboardArrowLeft />}
                  {this.language.Back[this.state.language]}
                </Button>
              )
            }
          />
        </div>
      </Dialog>
    );
  }
}

SimpleDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  selectedValue: PropTypes.string,
};

const SimpleDialogWrapped = withStyles(styles)(SimpleDialog);

class NewUser extends React.Component {
  state = {
    open: true,
  };

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = (value) => {
    this.setState({ selectedValue: value, open: false });
  };

  render() {
    return (
      <div>
        <SimpleDialogWrapped
          handleUserData={this.props.handleUserData}
          selectedValue={this.state.selectedValue}
          open={this.state.open}
          onClose={this.handleClose}
          {...this.props}
        />
      </div>
    );
  }
}

export default NewUser;
