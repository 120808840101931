import { Hidden } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Step from "@material-ui/core/Step";
import StepContent from "@material-ui/core/StepContent";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import getBundleRate from "../../api/canpar/getBundleRate";
import getCutoff from "../../api/canpar/getCutoff";
import LocationPicker from "../../components/LocationPicker";
import PackageMeasurements from "../../components/PackageMeasurements";
import { isNotEmpty } from "../../helpers/Validation";
import BoxImage from "../../img/box.png";

const styles = (theme) => ({
  root: {
    width: "90%",
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2,
  },
  resetContainer: {
    padding: theme.spacing.unit * 3,
  },
  progress: {
    margin: theme.spacing.unit * 2,
    marginLeft: "20px",
  },
});

class VerticalLinearStepper extends React.Component {
  state = {
    activeStep: 0,
    measurementError: false,
    cutoff: false,
    bundleRate: (
      <CircularProgress className={styles.progress} size={20} thickness={5} />
    ),
    loading: false,
    senderLocationID: this.props.senderLocationID
      ? this.props.senderLocationID
      : this.props.locations[0].id,
  };

  language = {
    BundleInstructions: {
      FR: (
        <span>
          Veuillez prendre tous vos colis internationaux{" "}
          <u> (la destination est à l'extérieur du Canada) </u> et les placer
          dans une grande boîte. Saisissez les nouvelles dimensions et le poids
          de la grande boîte ci-dessous.
        </span>
      ),
      EN: (
        <span>
          Please take all of your International packages{" "}
          <u>(destination is outside of Canada)</u> and place them into a large
          box. Input the new dimensions and weight of the large box below.
        </span>
      ),
    },
    CutoffTime: {
      FR: <>Votre heure limite est</>,
      EN: <>Your cutoff time is</>,
    },
    CutoffDisclaimer: {
      FR: (
        <>
          <small>
            Votre heure limite est la dernière date à laquelle un ramassage peut
            être soumis pour cette journée.
            <br />
            Si ce délai est déjà écoulé, un service de messagerie sera prévu le
            jour ouvrable suivant. <br />
            <strong>
              Veuillez noter que les ramassages n'ont pas lieu le week-end ou
              les jours fériés au Canada.
            </strong>
          </small>
        </>
      ),
      EN: (
        <>
          <small>
            Your cutoff time is the latest a pickup can be submitted for that
            day.
            <br />
            If this time has already passed, a courier will be scheduled for the
            following business day. <br />
            <strong>
              Please note that pickups do not occur on weekends or Holidays in
              Canada.
            </strong>
          </small>
        </>
      ),
    },
    CuttoffBundleRate: {
      FR: this.props.hasUS ? "Coût d'expédition:" : "",
      EN: this.props.hasUS ? "Cost of shipment:" : "",
    },
    ConsolidateInternationalShipments: {
      FR: "Consolider les expéditions internationales",
      EN: "Consolidate International Shipments",
    },
    SelectPickupLocation: {
      FR: "Sélectionnez le lieu de ramassage",
      EN: "Select Pickup Location",
    },
    Pickup: {
      FR: "Ramasser",
      EN: "Pickup",
    },
    BundleFeeDisclosure: {
      FR: "UN FRAIS SUPPLÉMENTAIRE EST REQUIS POUR LES ENVOIS INTERNATIONAUX À RAMASSER",
      EN: (
        <>
          AN ADDITIONAL FEE IS REQUIRED FOR INTERNATIONAL SHIPMENTS TO BE PICKED
          UP
          <br />
          <strong>
            DON'T WANT TO PAY EXTRA? THERE IS NO CHARGE WHEN YOU USE OUR{" "}
            <a
              href="#"
              style={{ color: "#e65100" }}
              onClick={() => this.props.setData("tab", 1)}
            >
              DROPOFF LOCATIONS
            </a>
          </strong>
        </>
      ),
    },
    Next: {
      FR: "Suivant",
      EN: "Next",
    },
    Back: {
      FR: "Arrière",
      EN: "Back",
    },
    ConfirmandContinue: {
      FR: "Confirmer et continuer",
      EN: "Confirm and Continue",
    },
  };

  UNSAFE_componentWillMount = () => {
    // alert(this.props.locations[0].id)
    if (this.props.selectedShipments) {
      if (this.props.selectedShipments.length > 0) {
        if (
          this.props.shipment
            .filter((e) => this.props.selectedShipments.indexOf(e.id) > -1)
            .filter((e) => e.receiver.address.country === "US").length > 0
        ) {
          this.props.setData("hasUS", true);
        }
      } else {
        this.props.history.push("/");
      }
    } else {
      this.props.history.push("/");
    }
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });
  };

  handleNext = async () => {
    if (this.props.hasUS && this.props.pickupStep === 0) {
      this.checkDimensions().then((e) =>
        e ? this.props.setData("pickupStep", this.props.pickupStep + 1) : false
      );
    } else if (this.props.pickupStep === this.getSteps().length - 1) {
      this.props.setData("activeStep", this.props.activeStep + 1);
    } else if (this.props.pickupStep === this.getSteps().length - 2) {
      if (this.props.hasUS) {
        this.setState({ loading: true });
        // alert("GETTING BUNDLE RATE LINE 70 OF pay0pickupstepper")

        await getBundleRate(
          { sender: this.props.senderLocation, package: this.props.package },
          (e) => {
            // console.log(e)
            if (e.status) {
              this.setState({ loading: false });

              this.props.setData("fullBundleRate", e.response[0]);
              // console.log("FULL BUNDLE RATE",this.props.fullBundleRate)
              // console.log(e.response[0])
              this.props.setData("bundleRate", e.response[0].rate);
            }
          }
        );
      }
      let cutoffTime = await getCutoff(
        this.props.senderLocation.address.postal
      );
      if (cutoffTime.message.substr(0, 2) > 12) {
        this.props.setData(
          "cutoff",
          `${cutoffTime.message.substr(0, 2) - 12}:${cutoffTime.message.substr(
            2,
            4
          )} PM`
        );
      } else if (cutoffTime.message.substr(0, 2) === "12") {
        this.props.setData(
          "cutoff",
          `${cutoffTime.message.substr(0, 2)}:${cutoffTime.message.substr(
            2,
            4
          )} PM`
        );
      } else {
        this.props.setData(
          "cutoff",
          `${cutoffTime.message.substr(0, 2)}:${cutoffTime.message.substr(
            2,
            4
          )} AM`
        );
      }
      this.props.setData("pickupStep", this.props.pickupStep + 1);
      this.setState({ loading: false });

      return;
    } else {
      // alert('default')
      this.props.setData("pickupStep", this.props.pickupStep + 1);
    }
  };

  checkDimensions = async () => {
    let response = await this.props.package.dimensions.map((e, i) => {
      if (i + 1 <= this.props.package.count) {
        return (
          isNotEmpty(e.height) &&
          isNotEmpty(e.length) &&
          isNotEmpty(e.width) &&
          isNotEmpty(e.weight)
        );
      } else {
        return true;
      }
    });

    !response.filter((e) => e === false).length
      ? this.setState({ measurementError: false })
      : this.setState({ measurementError: true });
    // console.log(!response.filter(e => e === false).length)
    return !response.filter((e) => e === false).length;
  };

  handleBack = () => {
    if (this.props.pickupStep === this.getSteps().length - 1) {
      this.props.setData("cutoff", false);
      this.props.setData("bundleRate", false);
    }

    this.props.setData("pickupStep", this.props.pickupStep - 1);
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  getStepContent = (step) => {
    if (this.props.hasUS) {
      switch (step) {
        case 0:
          return (
            <Grid container spacing={24}>
              <Grid item xs={6}>
                <img
                  src={BoxImage}
                  style={{
                    height: "50px",
                    float: "left",
                    marginRight: "20px",
                    paddingTop: "20px",
                  }}
                />
                <h3
                  style={{
                    paddingLeft: "70px",
                    width: "100%",
                  }}
                >
                  {this.language.BundleInstructions[this.state.language]}
                </h3>
              </Grid>
              <Grid item xs={12}>
                <PackageMeasurements
                  country={"CA"}
                  maxQuantity={25}
                  {...this.props}
                  error={this.state.measurementError}
                />
              </Grid>
            </Grid>
          );
        case 1:
          return (
            <LocationPicker
              account={this.props.account}
              senderLocationID={this.props.senderLocationID}
              handleLocationChange={this.props.handleLocationChange}
              locations={this.props.locations}
              showButtons={false}
            />
          );
        case 2:
          return (
            <Fragment>
              <h3>See email for your scheduled pick-up day.</h3>
            </Fragment>
          );
        default:
          return "Unknown step";
      }
    } else {
      switch (step) {
        case 0:
          return this.selectPickupLocation();
        case 1:
          return (
            <Fragment>
              <h3>See email for your scheduled pick-up day.</h3>
            </Fragment>
          );
        default:
          return "Unknown step";
      }
    }
  };

  selectPickupLocation = () => {
    return (
      <LocationPicker
        account={this.props.account}
        senderLocationID={this.props.senderLocationID}
        handleLocationChange={this.props.handleLocationChange}
        locations={this.props.locations}
        showButtons={false}
      />
    );
  };

  getSteps = () => {
    if (this.props.hasUS) {
      return [
        this.language.ConsolidateInternationalShipments[this.state.language],
        this.language.SelectPickupLocation[this.state.language],
        this.language.ConfirmandContinue[this.state.language],
      ];
    } else {
      return [
        this.language.SelectPickupLocation[this.state.language],
        this.language.ConfirmandContinue[this.state.language],
      ];
    }
  };

  render() {
    const { classes } = this.props;
    const steps = this.getSteps();
    const activeStep = this.props.pickupStep;

    return (
      <div className={classes.root}>
        <Typography variant="h1">
          <Hidden mdUp>
            <br />
            <br />
          </Hidden>
          {this.language.Pickup[this.state.language]}
        </Typography>
        {this.props.hasUS && (
          <small>
            <br />
            {this.language.BundleFeeDisclosure[this.state.language]}
          </small>
        )}
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                <StepContent>
                  <Typography>{this.getStepContent(index)}</Typography>
                  <div className={classes.actionsContainer}>
                    <div>
                      <Button
                        disabled={activeStep === 0}
                        onClick={this.handleBack}
                        className={classes.button}
                      >
                        {this.language.Back[this.state.language]}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleNext}
                        className={classes.button}
                        disabled={this.state.loading}
                      >
                        {activeStep === steps.length - 1
                          ? this.language.ConfirmandContinue[
                              this.state.language
                            ]
                          : this.language.Next[this.state.language]}
                      </Button>
                    </div>
                  </div>
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
      </div>
    );
  }
}

VerticalLinearStepper.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(VerticalLinearStepper);
