import Button from "@material-ui/core/Button";
import DayjsUtils from "@date-io/dayjs";
import dayjs from "dayjs";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import Step from "@material-ui/core/Step";
import StepContent from "@material-ui/core/StepContent";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import uuid from "uuid";
import { PO_BOX_REGEX } from "../../helpers/regex";
import {
  isNotEmpty,
  isValidEmail,
  isValidPhoneNumber,
  isValidPostal,
} from "../../helpers/Validation";
import AddressBookPickerDialog from "../AddressBookPickerDialog";
import LoadingIcon from "../loading/LoadingIcon";
import { MuiPickersUtilsProvider, TimePicker } from "material-ui-pickers";

const styles = (theme) => ({
  textField: {
    width: "100%",
    boxSizing: "border-box",
    fontSize: "1.5em",
  },
  menu: {
    width: 200,
  },
  formControl: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  root: {
    paddingRight: 0,
    paddingLeft: 0,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2,
  },
  resetContainer: {
    padding: theme.spacing.unit * 3,
  },
});

class VerticalLinearStepper extends React.Component {
  state = {
    activeStep: 0,
    zipError: false,
    addressError: false,
    attentionError: false,
    cityError: false,
    textmask: "",
    addressBookPickerOpen: false,
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  UNSAFE_componentWillMount = async () => {
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });
  };

  language = {
    Enterzipcode: {
      FR: "Entrer le code postal",
      EN: "Enter Postal Code",
    },
    Enteraddress: {
      FR: "Entrer l'adresse",
      EN: "Enter Address",
    },
    Confirmdetails: {
      FR: "Confirmer les détails",
      EN: "Confirm Details",
    },
    ZIPCode: {
      FR: "Code postal",
      EN: "ZIP Code",
    },
    CustomersEnteredAddress: {
      FR: "Adresse saisie par le client",
      EN: "Customer's Entered Address",
    },
    Address: {
      FR: "Adresse",
      EN: "Address",
    },
    Address2: {
      FR: "Adresse 2",
      EN: "Address 2",
    },
    StreetName: {
      FR: "Nom de rue",
      EN: "Street Name",
    },
    StreetNumber: {
      FR: "Numéro de rue",
      EN: "Street Number",
    },
    Province: {
      FR: "Province",
      EN: "Province",
    },
    Postal: {
      FR: "Code Postale",
      EN: "Postal code",
    },
    BusinessName: {
      FR: "Nom du Commerce",
      EN: "Business Name",
    },
    FirstName: {
      FR: "Prénom",
      EN: "First Name",
    },
    LastName: {
      FR: "Nom de famille",
      EN: "Last Name",
    },
    Phone: {
      FR: "Numéro de téléphone",
      EN: "Phone",
    },
    Email: {
      FR: "Adresse électronique",
      EN: "Email",
    },
    ReferencePO: {
      FR: "Référence ou bon de commande",
      EN: "Reference/P.O. #",
    },
    UnitApt: {
      FR: "Unité/Appartemant",
      EN: "Unit/Apt #",
    },
    City: {
      FR: "Ville",
      EN: "City",
    },
    State: {
      FR: "Etat",
      EN: "State",
    },
    Back: {
      FR: "Arrière",
      EN: "Back",
    },
    Next: {
      FR: "Suivant",
      EN: "Next",
    },
    ContinueAnyway: {
      FR: "Continuez quand même",
      EN: "Continue anyway",
    },
    Receiver: {
      FR: "Le Destinataire",
      EN: "Receiver",
    },
    Edit: {
      FR: "Éditer",
      EN: "Edit",
    },
    ConfirmAndContinue: {
      FR: "Confirmer et continuer",
      EN: "Confirm and Continue",
    },
  };

  getSteps = () => {
    return [
      this.language.Enterzipcode[this.state.language],
      this.language.Enteraddress[this.state.language],
      this.language.Confirmdetails[this.state.language],
    ];
  };

  getStepContent = (step) => {
    const { classes } = this.props;
    switch (step) {
      case 0:
        return (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.setState({ addressBookPickerOpen: true })}
            >
              Address Book
            </Button>
            <p style={{ marginTop: "2em", marginBottom: 0 }}>
              or enter postal code to begin
            </p>
            <div style={{ width: "7em" }}>
              <TextField
                error={this.props.zipError}
                required
                id={uuid.v4()}
                label={this.language.Postal[this.state.language]}
                className={classes.textField}
                value={this.props.receiver.zip}
                onChange={this.props.handleZipChange("zip")}
                margin="normal"
                inputProps={{
                  name: "zip",
                  type: "text",
                  autoComplete: "off",
                  autoCorrect: "off",
                  autoCapitalize: "none",
                  spellCheck: "false",
                  maxLength: 6,
                }}
              />
            </div>
          </>
        );
      case 1:
        return (
          <Fragment>
            {this.props.previousData && (
              <div
                style={{
                  background: "#ffffe0",
                  padding: "20px",
                  borderRadius: "10px",
                }}
              >
                <strong>
                  {this.language.CustomersEnteredAddress[this.state.language]}:
                </strong>
                <br />
                {this.props.previousData.address}
                <br />
                {this.props.previousData.unit}
                <br />
                {this.props.previousData.city}, {this.props.previousData.state}{" "}
                {this.props.previousData.zip}
              </div>
            )}

            <TextField
              error={this.props.addressError}
              id={uuid.v4()}
              label={this.language.StreetNumber[this.state.language]}
              className={classes.textField}
              value={this.props.receiver.street_number}
              onChange={this.props.handleObjChange("street_number", "receiver")}
              margin="normal"
              required
              inputProps={{
                name: "street_number",
                type: "text",
                autoComplete: "off",
                autoCorrect: "off",
                autoCapitalize: "none",
                spellCheck: "false",
              }}
            />
            <TextField
              id={uuid.v4()}
              required
              error={this.props.addressError}
              label={this.language.StreetName[this.state.language]}
              className={classes.textField}
              value={this.props.receiver.street_name}
              onChange={this.props.handleObjChange("street_name", "receiver")}
              InputLabelProps={{
                shrink: this.props.receiver.street_name !== "",
              }}
              inputProps={{
                name: "street_name",
                list: "streets",
                type: "text",
                autoComplete: "off",
                autoCorrect: "off",
                autoCapitalize: "none",
                spellCheck: "false",
              }}
              margin="normal"
            />
            <datalist id="streets">
              {this.props.streetArr.map((e) => (
                <option value={e} />
              ))}
            </datalist>

            <TextField
              id={uuid.v4()}
              label={this.language.UnitApt[this.state.language]}
              className={classes.textField}
              value={this.props.receiver.unit}
              onChange={this.props.handleObjChange("unit", "receiver")}
              margin="normal"
              inputProps={{
                name: "unit",
                type: "text",
                autoComplete: "off",
                autoCorrect: "off",
                autoCapitalize: "none",
                spellCheck: "false",
              }}
            />
          </Fragment>
        );
      case 2:
        return (
          <Fragment>
            <TextField
              style={{ width: "45%" }}
              id={uuid.v4()}
              error={this.props.refError}
              label={this.language.ReferencePO[this.state.language]}
              className={classes.textField}
              value={this.props.package.ref}
              onChange={this.props.handleObjChange("ref", "package")}
              margin="normal"
              inputProps={{
                type: "text",
                autoComplete: "off",
                autoCorrect: "off",
                autoCapitalize: "none",
                spellCheck: "false",
                maxLength: 35,
              }}
            />

            <TextField
              style={{ width: "20%", marginLeft: "5%" }}
              inputProps={{
                autoComplete: "off",
                autoCorrect: "off",
                autoCapitalize: "none",
                spellCheck: "false",
                maxLength: 35,
                step: 300,
              }}
              type="time"
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue="09:00"
              id={uuid.v4()}
              error={this.state.opentimeError}
              label={"Open time"}
              className={classes.textField}
              value={this.props.receiver.open_time}
              helperText={"Earliest time 08:00AM"}
              margin="normal"
              onChange={(event) => {
                if (
                  this.props.receiver.close_time &&
                  Number(this.props.receiver.close_time.replace(":", "")) -
                    Number(event.target.value.replace(":", "")) >=
                    200 &&
                  Number(event.target.value.replace(":", "")) >= 800
                ) {
                  this.setState({ opentimeError: false });
                  this.props.setObjData(
                    "open_time",
                    "receiver",
                    event.target.value
                  );
                } else {
                  this.setState({ opentimeError: true });
                }
              }}
            />
            <TextField
              style={{ width: "25%", marginLeft: "5%" }}
              inputProps={{
                autoComplete: "off",
                autoCorrect: "off",
                autoCapitalize: "none",
                spellCheck: "false",
                maxLength: 35,
                step: 300,
                max: "23:00",
              }}
              type="time"
              InputLabelProps={{
                shrink: true,
              }}
              id={uuid.v4()}
              error={this.state.closetimeError}
              helperText={
                this.state.closetimeError
                  ? "Minimum window of 2 hours"
                  : "Lastest time 07:00PM"
              }
              label={"Close time"}
              className={classes.textField}
              value={this.props.receiver.close_time}
              onChange={(event) => {
                if (
                  this.props.receiver.open_time &&
                  Number(event.target.value.replace(":", "")) -
                    Number(this.props.receiver.open_time.replace(":", "")) >=
                    200 &&
                  Number(event.target.value.replace(":", "")) <= 1900
                ) {
                  this.setState({ closetimeError: false });
                  this.props.setObjData(
                    "close_time",
                    "receiver",
                    event.target.value
                  );
                } else {
                  this.setState({ closetimeError: true });
                }
              }}
              margin="normal"
            />
            <TextField
              fullWidth
              inputProps={{
                type: "text",
                autoComplete: "off",
                autoCorrect: "off",
                autoCapitalize: "none",
                spellCheck: "false",
                maxLength: 35,
              }}
              id={uuid.v4()}
              error={this.props.businessError}
              label={this.language.BusinessName[this.state.language]}
              className={classes.textField}
              value={this.props.receiver.business}
              onChange={this.props.handleObjChange("business", "receiver")}
              margin="normal"
            />
            <TextField
              style={{ width: "45%" }}
              error={this.props.attentionError}
              required
              inputProps={{
                name: "fname",
                type: "text",
                autoComplete: "off",
                autoCorrect: "off",
                autoCapitalize: "none",
                spellCheck: "false",
                maxLength: 20,
              }}
              id={uuid.v4()}
              label={this.language.FirstName[this.state.language]}
              className={classes.textField}
              value={this.props.receiver.fname}
              onChange={this.props.handleObjChange("fname", "receiver")}
              margin="normal"
            />
            <TextField
              style={{ width: "50%", marginLeft: "5%" }}
              error={this.props.attentionError}
              required
              id={uuid.v4()}
              inputProps={{
                name: "lname",
                maxLength: 20,
              }}
              label={this.language.LastName[this.state.language]}
              className={classes.textField}
              value={this.props.receiver.lname}
              onChange={this.props.handleObjChange("lname", "receiver")}
              margin="normal"
            />
            <TextField
              style={{ width: "45%" }}
              id={uuid.v4()}
              label={this.language.Phone[this.state.language]}
              inputProps={{
                type: "text",
                autoComplete: "off",
                autoCorrect: "off",
                autoCapitalize: "none",
                spellCheck: "false",
                maxLength: 35,
              }}
              // required
              type="phone"
              className={classes.textField}
              error={this.props.phoneError}
              value={this.props.receiver.phone}
              onChange={this.props.handleObjChange("phone", "receiver")}
              margin="normal"
            />
            <TextField
              style={{ width: "50%", marginLeft: "5%" }}
              error={this.props.emailError}
              id={uuid.v4()}
              label={this.language.Email[this.state.language]}
              type="email"
              inputProps={{
                maxLength: 50,
              }}
              // required
              className={classes.textField}
              value={this.props.receiver.email}
              onChange={this.props.handleObjChange("email", "receiver")}
              margin="normal"
            />
            <TextField
              fullWidth
              error={this.props.addressError}
              required
              id={uuid.v4()}
              inputProps={{
                type: "text",
                autoComplete: "off",
                autoCorrect: "off",
                autoCapitalize: "none",
                spellCheck: "false",
                maxLength: 35,
              }}
              label={this.language.Address[this.state.language]}
              className={classes.textField}
              value={this.props.receiver.address}
              onChange={this.props.handleObjChange("address", "receiver")}
              margin="normal"
            />
            <TextField
              fullWidth
              id={uuid.v4()}
              inputProps={{
                maxLength: 35,
              }}
              label={this.language.UnitApt[this.state.language]}
              className={classes.textField}
              value={this.props.receiver.unit}
              onChange={this.props.handleObjChange("unit", "receiver")}
              margin="normal"
            />
            <TextField
              fullWidth
              error={this.props.cityError}
              id={uuid.v4()}
              inputProps={{
                type: "text",
                autoComplete: "off",
                autoCorrect: "off",
                autoCapitalize: "none",
                spellCheck: "false",
                maxLength: 35,
              }}
              label={this.language.City[this.state.language]}
              className={classes.textField}
              value={this.props.receiver.city}
              onChange={this.props.handleObjChange("city", "receiver")}
              margin="normal"
            />
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="age-native-simple">
                {this.language.Province[this.state.language]}
              </InputLabel>
              <Select
                native
                value={this.props.receiver.state}
                onChange={this.props.handleObjChange("state", "receiver")}
                inputProps={{
                  name: "state",
                  id: "state-native-simple",
                }}
              >
                <option value={"AB"}>Alberta</option>
                <option value={"BC"}>British Columbia</option>
                <option value={"MB"}>Manitoba</option>
                <option value={"NB"}>New Brunswick</option>
                <option value={"NS"}>Nova Scotia</option>
                <option value={"NL"}>Newfoundland and Labrador</option>
                <option value={"ON"}>Ontario</option>
                <option value={"PE"}>Prince Edward Island</option>
                <option value={"SK"}>Saskatchewan</option>
                <option value={"QC"}>Quebec</option>
              </Select>
            </FormControl>
            <TextField
              fullWidth
              error={this.props.zipError}
              required
              id={uuid.v4()}
              label={this.language.Postal[this.state.language]}
              className={classes.textField}
              value={this.props.receiver.zip}
              onChange={this.props.handleObjChange("zip", "receiver")}
              margin="normal"
              inputProps={{
                type: "text",
                autoComplete: "off",
                autoCorrect: "off",
                autoCapitalize: "none",
                spellCheck: "false",
                maxLength: 35,
              }}
            />
          </Fragment>
        );
      default:
        return "Unknown step";
    }
  };

  handleFormSubmit = () => {
    isNotEmpty(this.props.receiver.fname) &&
    isNotEmpty(this.props.receiver.lname)
      ? this.props.setData("attentionError", false)
      : this.props.setData("attentionError", true);
    // isNotEmpty(this.props.receiver.business) ? this.props.setData('businessError',false) : this.props.setData('businessError',true);
    isNotEmpty(this.props.receiver.address)
      ? this.props.setData("addressError", false)
      : this.props.setData("addressError", true);
    isNotEmpty(this.props.receiver.city)
      ? this.props.setData("cityError", false)
      : this.props.setData("cityError", true);
    isNotEmpty(this.props.receiver.zip)
      ? this.props.setData("zipError", false)
      : this.props.setData("zipError", true);
    // isNotEmpty(this.props.package.ref) ? this.props.setData('refError',false) : this.props.setData('refError',true);
    (isNotEmpty(this.props.receiver.email) &&
      isValidEmail(this.props.receiver.email)) ||
    !isNotEmpty(this.props.receiver.email)
      ? this.props.setData("emailError", false)
      : this.props.setData("emailError", true);
    (isNotEmpty(this.props.receiver.phone) &&
      isValidPhoneNumber(this.props.receiver.phone)) ||
    !isNotEmpty(this.props.receiver.phone)
      ? this.props.setData("phoneError", false)
      : this.props.setData("phoneError", true);
    return (
      isNotEmpty(this.props.receiver.fname) &&
      isNotEmpty(this.props.receiver.lname) &&
      isNotEmpty(this.props.receiver.address) &&
      isNotEmpty(this.props.receiver.city) &&
      isNotEmpty(this.props.receiver.zip) &&
      ((isNotEmpty(this.props.receiver.phone) &&
        isValidPhoneNumber(this.props.receiver.phone)) ||
        !isNotEmpty(this.props.receiver.phone)) &&
      ((isNotEmpty(this.props.receiver.email) &&
        isValidEmail(this.props.receiver.email)) ||
        !isNotEmpty(this.props.receiver.email))
    );
  };

  handleBack = () => {
    this.setState((state) => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  handleNext = () => {
    if (this.props.activeStep == 2) {
      if (this.handleFormSubmit()) {
        this.props.handleStep1Next();
      }
    } else {
      this.props.handleStep1Next();
    }
  };

  render() {
    const { classes } = this.props;
    const steps = this.getSteps();
    // const activeStep = this.state.activeStep
    const activeStep = this.props.oneStep;

    return (
      <Fragment>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                {/* <AddressBook open={this.state.addressBookOpen} pushSelection={ } /> */}
                <StepLabel>{label}</StepLabel>
                <StepContent>
                  {this.getStepContent(index)}
                  <div className={classes.actionsContainer}>
                    {activeStep > 0 && (
                      <Button
                        disabled={this.props.isLoading}
                        onClick={() => this.props.handleBack("oneStep")}
                        className={classes.button}
                      >
                        {this.language.Back[this.state.language]}
                      </Button>
                    )}
                    <Button
                      disabled={
                        !isValidPostal(this.props.receiver.zip) ||
                        this.props.isLoading ||
                        this.props.zipError
                      }
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        this.handleNext();
                      }}
                      className={classes.button}
                    >
                      {!this.props.isLoading ? (
                        this.language.Next[this.state.language]
                      ) : (
                        <LoadingIcon size={20} />
                      )}
                    </Button>
                    {this.props.addressError &&
                      this.props.activeStep === 1 &&
                      !PO_BOX_REGEX.test(
                        `${this.props.receiver.street_number} ${this.props.receiver.street_name}`
                      ) && (
                        <Button
                          disabled={activeStep === 0}
                          onClick={() => {
                            this.props.handleNext("oneStep");
                          }}
                          className={classes.button}
                        >
                          {this.language.ContinueAnyway[this.state.language]}
                        </Button>
                      )}
                  </div>
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
        {activeStep == steps.length && (
          <Paper square elevation={0} className={classes.resetContainer}>
            <Typography variant="subheading">
              <strong>{this.language.Receiver[this.state.language]}:</strong>
              <br />
              {this.props.receiver.business ? (
                <>
                  {this.props.receiver.business}
                  <br />
                </>
              ) : (
                <></>
              )}
              Attn: {this.props.receiver.fname} {this.props.receiver.lname}
              <br />
              {this.props.receiver.phone ? (
                <>
                  {this.props.receiver.phone}
                  <br />
                </>
              ) : (
                <></>
              )}
              {this.props.receiver.email ? (
                <>
                  {this.props.receiver.email}
                  <br />
                </>
              ) : (
                <></>
              )}
              {this.props.receiver.unit && (
                <span>#{this.props.receiver.unit}&nbsp;-&nbsp;</span>
              )}
              {this.props.receiver.address}
              <br />
              {this.props.receiver.city}, {this.props.receiver.state}{" "}
              {this.props.receiver.zip}
              <br />
              {this.props.receiver.country}
              <br />
              <span style={{ color: "#757575" }}>
                Operating hours:{" "}
                {this.props.receiver.open_time.substr(0, 2) >= 12
                  ? `${(this.props.receiver.open_time.substr(0, 2) - 12)
                      .toString()
                      .padStart(2, "0")}:${this.props.receiver.open_time.substr(
                      3,
                      2
                    )}`
                  : this.props.receiver.open_time}{" "}
                {this.props.receiver.open_time.substr(0, 2) >= 12 ? "PM" : "AM"}
                –{" "}
                {this.props.receiver.close_time.substr(0, 2) >= 12
                  ? `${(this.props.receiver.close_time.substr(0, 2) - 12)
                      .toString()
                      .padStart(
                        2,
                        "0"
                      )}:${this.props.receiver.close_time.substr(3, 2)}`
                  : this.props.receiver.close_time}{" "}
                {this.props.receiver.close_time.slice(0, 2) >= 12 ? "PM" : "AM"}{" "}
                -{" "}
                <span
                  style={{
                    cursor: "pointer",
                    color: "#051E51",
                  }}
                  onClick={() => this.props.handleBack("oneStep")}
                >
                  Edit
                </span>
              </span>
              <br />
              <br />
            </Typography>
            <Button
              onClick={() => this.props.handleBack("oneStep")}
              className={classes.button}
            >
              {this.language.Edit[this.state.language]}
            </Button>
            <Button
              onClick={() => this.handleNext()}
              className={classes.button}
              variant="contained"
              color="primary"
            >
              {this.language.ConfirmAndContinue[this.state.language]}
            </Button>
          </Paper>
        )}
        {this.state.addressBookPickerOpen && (
          <AddressBookPickerDialog
            country="CA"
            open={this.state.addressBookPickerOpen}
            onClose={() => this.setState({ addressBookPickerOpen: false })}
            onSelect={(address) => {
              this.props.handleAddressbook(address);
              this.setState({ addressBookPickerOpen: false });
            }}
            handleLogout={this.props.handleLogout}
          />
        )}
      </Fragment>
    );
  }
}

VerticalLinearStepper.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(VerticalLinearStepper);
