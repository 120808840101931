import { Grid, Grow, Tooltip } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import red from "@material-ui/core/colors/red";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import { Cancel } from "@material-ui/icons";
import { API, graphqlOperation } from "aws-amplify";
import axios from "axios";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import React from "react";
import WooCommLogoSmall from "../img/woocommerce_logo_small.png";
import LoadingIcon from "./loading/LoadingIcon";

const styles = (theme) => ({
  card: {
    position: "relative",
    maxWidth: 400,
    margin: "10px 10px 10px 0px",
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
    },
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  actions: {
    display: "flex",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
});

class RecipeReviewCard extends React.Component {
  state = { expanded: false, isLoading: false };

  handleExpandClick = () => {
    this.setState((state) => ({ expanded: !state.expanded }));
  };

  UNSAFE_componentWillMount = () => {
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });
  };

  language = {
    ConnectedOn: {
      FR: "CONNECTÉ",
      EN: "CONNECTED ON",
    },
    Delete: {
      FR: "Retirer",
      EN: "Remove E-commerce Connection",
    },
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid item xs={12} md={3} lg={3}>
        <Grow in={true}>
          <Card className={classes.card}>
            <div
              style={{
                backgroundColor: `${
                  this.props.data.platform == "ETSY"
                    ? `#F1641E`
                    : this.props.data.platform == "SHOPIFY"
                    ? `#7fc140`
                    : `#9a5c8f`
                }`,
                background: `linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.9)),
            ${
              this.props.data.image_url
                ? `url('${this.props.data.image_url}')`
                : `${
                    this.props.data.platform == "ETSY"
                      ? `#F1641E`
                      : this.props.data.platform == "SHOPIFY"
                      ? `#7fc140`
                      : `#9a5c8f`
                  }`
            }`,
                // 'backgroundImage':`url('${this.props.data.image_url}')`,

                backgroundSize: "cover",
                backgroundPosition: "center center",
                minHeight: "150px",
                textAlign: "bottom",
                textShadow: "0px 0px 3px rgba(0,0,0,.3)",
                color: "#fff",
                fontWeight: "700",
                padding: "10px",
                position: "relative",
              }}
            >
              <Tooltip title={this.language.Delete[this.state.language]}>
                <IconButton
                  size="small"
                  style={{
                    position: "absolute",
                    top: "0px",
                    right: "0px",
                    opacity: ".7",
                    color: "#fff",
                    fill: "#fff",
                  }}
                  aria-label="Remove E-commerce Connection"
                >
                  <Cancel
                    onClick={async () => {
                      this.setState({ isLoading: true });
                      if (this.props.data.platform == "SHOPIFY") {
                        // console.log({
                        //   'method': 'DELETE',
                        //   'url': `https://${this.props.data.shop_id}/admin/api_permissions/current.json`,
                        //   'headers': {
                        //     'Content-Type': 'application/json',
                        //     'X-Shopify-Access-Token': this.props.data.token,
                        //   }
                        // });
                        var data = "";

                        var config = {
                          method: "delete",
                          url: `https://${this.props.data.shop_id}/admin/api_permissions/current.json`,
                          headers: {
                            "Content-Type": "application/json",
                            "X-Shopify-Access-Token": this.props.data.token,
                          },
                          data: data,
                        };

                        axios(config)
                          .then(function (response) {
                            console.log(JSON.stringify(response.data));
                          })
                          .catch(function (error) {
                            console.log(error);
                          });
                        // await request({
                        //   'method': 'DELETE',
                        //   'url': `https://${this.props.data.shop_id}/admin/api_permissions/current.json`,
                        //   'headers': {
                        //     'Content-Type': 'application/json',
                        //     'X-Shopify-Access-Token': this.props.data.token,
                        //   }
                        // },(err,data)=>{
                        //   console.log(err,data);
                        // });
                        // console.log('removed location from shopify account.');
                      }
                      let location = await API.graphql(
                        graphqlOperation(`mutation deleteV1_Location {
                  deleteV1_Location(input: { id:"${this.props.data.sender_id}" }){
                      id
                      cognitoUsername
                    }
                  }`)
                      );
                      let connections = await API.graphql(
                        graphqlOperation(`mutation deleteEcommerceIntegration {
                    deleteEcommerceIntegration(input: { id:"${this.props.data.id}" }){
                        id
                        cognitoUsername
                      }
                }`)
                      );
                      setTimeout(
                        () => this.setState({ isLoading: false }),
                        100
                      );
                    }}
                  />
                </IconButton>
              </Tooltip>
              <p
                style={{
                  position: "absolute",
                  left: "0px",
                  bottom: "14px",
                  paddingLeft: "10px",
                  color: "#FFF",
                  fontSize: "1.15em",
                  paddingRight: "10px",
                  width: "100%",
                  boxSizing: "border-box",
                }}
              >
                <Tooltip title={`${this.props.data.platform} ACCOUNT`}>
                  <img
                    src={
                      this.props.data.platform == "ETSY"
                        ? "https://images.swiftpost.com/ecommerce-integrations/etsy_logo.png"
                        : this.props.data.platform == "SHOPIFY"
                        ? "https://images.swiftpost.com/ecommerce-integrations/shopify_logo.png"
                        : WooCommLogoSmall
                    }
                    height="16px"
                    width="16px"
                    style={{
                      borderRadius: "50%",
                      boxShadow: "0px 0px 3px rgba(0,0,0,.3)",
                    }}
                  />
                </Tooltip>
                &nbsp;{this.props.data.shop_name}
              </p>
              {/* <p style={{'position':'absolute','bottom':'12px','left':'15px','color':'#FFF','fontSize':'.6em'}}>0 SHIPMENTS SENT</p> */}
              <p
                style={{
                  position: "absolute",
                  bottom: "2px",
                  left: "15px",
                  color: "#FFF",
                  fontSize: ".6em",
                }}
              >
                {this.language.ConnectedOn[this.state.language]}{" "}
                {this.props.data.date_created}
              </p>
            </div>
            {/* <CardActions className={classes.actions} disableActionSpacing>
          <Tooltip title={'Refresh shop'}><IconButton aria-label="Add to favorites">
            <RefreshOutlined />
          </IconButton></Tooltip>
        </CardActions> */}
            {this.state.isLoading && (
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  background: "rgba(255,255,255,.75)",
                }}
              >
                <center>
                  <LoadingIcon style={{ marginTop: "50px" }} />
                </center>
              </div>
            )}
          </Card>
        </Grow>
      </Grid>
    );
  }
}

RecipeReviewCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RecipeReviewCard);
