//react
import AppBar from "@material-ui/core/AppBar";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
//components
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import {
  MoveToInboxOutlined,
  Settings,
  SupervisedUserCircle,
} from "@material-ui/icons";
import AccountIcon from "@material-ui/icons/AccountCircleOutlined";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import AddressBookIcon from "@material-ui/icons/Contacts";
// import TruckIcon from "@material-ui/icons/";
import PaymentIcon from "@material-ui/icons/CreditCardOutlined";
import ShipmentIcon from "@material-ui/icons/DashboardOutlined";
import LocationIcon from "@material-ui/icons/LocationOnOutlined";
import MenuIcon from "@material-ui/icons/MenuOutlined";
// import '../styles/animations.css';
//icons
import InboxIcon from "@material-ui/icons/ReceiptOutlined";
import ConnectionIcon from "@material-ui/icons/Store";
import classNames from "classnames";
import { getCookie } from "helpers/getCookie";
//styles
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import BalanceButton from "./components/BalanceButton";
import EcommerceInterstitial from "./components/EcommerceInterstitial";
import LoadingIcon from "./components/loading/LoadingIcon";
//dialogs
import Logout from "./dialogs/Logout";
import NewShipment from "./dialogs/NewShipment";
//images
import Logo from "./img/logo.svg";
import RoutesLogo from "./img/routes_icon.svg";

// import AddressBook from "./components/AddressBook";
import { AddressBookContext } from "./providers/AddressBook";

//---------------------------------------------

const drawerWidth = 240;

function AddressbookConsumer({ children }) {
  return (
    <AddressBookContext.Consumer>
      {(context) => {
        if (context === undefined) {
          throw new Error("CountConsumer must be used within a CountProvider");
        }
        return children(context);
      }}
    </AddressBookContext.Consumer>
  );
}

class AddressBookButton extends React.Component {
  render() {
    return (
      <AddressbookConsumer>
        {({ toggleDrawer }) => (
          <Tooltip title={"Address Book"} placement="right">
            <Link to="/addressbook">
              <ListItem button>
                <ListItemIcon className="navicon">
                  <AddressBookIcon />
                </ListItemIcon>
                <ListItemText primary={"Address Book"} />
              </ListItem>
            </Link>
          </Tooltip>
        )}
      </AddressbookConsumer>
    );
  }
}

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: 430,
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    [theme.breakpoints.up("md")]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  menuButton: {
    marginLeft: 12,
    [theme.breakpoints.down("md")]: {
      marginRight: 16,
    },
    [theme.breakpoints.up("md")]: {
      marginRight: 36,
    },
  },
  banner: {
    display: "block",
    padding: 4,
    textAlign: "center",
    backgroundColor: "#0a6fc2",
    color: "white",
  },
  hide: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    minHeight: "100vh",
    height: "100%",
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing.unit * 9,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
  },
  badge: {
    top: -1,
    right: -3,
    minWidth: 15,
    minHeight: 15,
    height: 15,
    width: 15,
    padding: 3,
    fontSize: 9,
    color: "white",
  },
  badge2: {
    top: 3,
    right: -5,
    width: 4,
    height: 4,
    padding: 3,
    fontSize: 8,
    color: "red",
    background: "#fff",
  },
});

class SwiftNav extends React.Component {
  state = {
    open: false,
    openTopUpStepper: false,
    interstitialOpen: false,
  };

  UNSAFE_componentWillMount = () => {
    // document.cookie = "language=FR";

    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });
  };

  language = {
    NewShipment: {
      FR: "Nouvel Envoi",
      EN: "New Shipment",
    },
    MyOrders: {
      FR: "Mes Commandes",
      EN: "My Orders",
    },
    MyShipments: {
      FR: "Mes Envois",
      EN: "My Shipments",
    },
    InboundShipments: {
      FR: "Expéditions entrantes",
      EN: "Inbound Shipments",
    },
    ChildAccounts: {
      FR: "Comptes enfants",
      EN: "Child Accounts",
    },
    MyLocations: {
      FR: "Mes Emplacements",
      EN: "My Locations",
    },
    BillingCentre: {
      FR: "Centre de Facturation",
      EN: "Billing Centre",
    },
    EcommerceConnections: {
      FR: "Les Connections eCommerce",
      EN: "Connect Store",
    },
    SelfDelivery: {
      FR: "Auto-livraison",
      EN: "Self-Delivery",
    },
    Account: {
      FR: "Mon Compte",
      EN: "Account",
    },
    Settings: {
      FR: "Paramètres",
      EN: "Settings",
    },
    ImportOrder: {
      FR: "COMMANDES D'IMPORTATION",
      EN: "IMPORT ORDERS",
    },
  };

  changeLanguage = () => {
    document.cookie = "language=FR";
    window.location.reload();
  };

  componentWillUnmount = () => {
    this.handleDrawerOpen = null;
    this.handleDrawerClose = null;
    this.handleMenu = null;
    this.handleClose = null;
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    // console.log("NAV PROPS=>",this.props)
    const { classes, theme } = this.props;
    const { auth, anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <Fragment>
        {/* <AddressBook open={true} /> */}
        <EcommerceInterstitial
          open={this.state.interstitialOpen}
          handleClose={() => this.setState({ interstitialOpen: false })}
          refresh={() => {
            this.setState({ interstitialOpen: false });
            this.props.ecommerceRefresh();
          }}
        />
        <Hidden mdUp>
          <AppBar
            position="fixed"
            className={classNames(
              classes.appBar,
              this.state.open && classes.appBarShift
            )}
          >
            <Toolbar disableGutters={!this.state.open}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
                className={classNames(
                  classes.menuButton,
                  this.state.open && classes.hide
                )}
              >
                <MenuIcon />
              </IconButton>
              <img
                src={Logo}
                style={{ maxHeight: "23px" }}
                alt="Swiftpost Logo"
              />

              {/* <div>
                                <IconButton
                                aria-owns={open ? 'menu-appbar' : undefined}
                                aria-haspopup="true"
                                onClick={this.handleMenu}
                                color="inherit"
                                style={{right:'20px',position:'absolute'}}
                                >
                                    <AccountIcon />
                                </IconButton>
                                <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={open}
                                onClose={this.handleClose}
                                >
                                <MenuItem onClick={this.handleClose}>
                                <div style={{
                                    '-webkit-user-select':'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    '-o-user-select': 'none',
                                    'user-select': 'none',
                                    height:'100px',
                                    padding:'30px',
                                    fontSize:'1.5em',
                                    lineHeight:'100px'
                                }}><center>$200 CAD</center></div></MenuItem>
                                <Link to={'/account'}><MenuItem onClick={this.handleClose}>My account</MenuItem></Link>
                                </Menu>
                            </div> */}

              <div style={{ position: "absolute", right: "20px" }}>
                {/* <IconButton aria-label="Delete" className={classes.margin}>
                            {this.props.connections.filter(e=>e.platform == "ETSY").length > 0 ? <img src={"https://res-1.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_256,w_256,f_auto,q_auto:eco/v1475508264/bjljj1do9xbdoo1vxury.png"} height="25px" width="25px" style={{borderRadius:'50%',boxShadow:'0px 0px 3px rgba(0,0,0,.3)'}}/> : ''}
                        </IconButton> */}

                {/* {this.props.connections.filter((e) => e.platform == "ETSY")
                  .length > 0 ? (
                  this.props.etsyLoading ? (
                    <IconButton
                      className={classes.margin}
                      style={{ opacity: 0.3 }}
                      onClick={() => {}}
                    >
                      <img
                        src={
                          "https://res-1.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_256,w_256,f_auto,q_auto:eco/v1475508264/bjljj1do9xbdoo1vxury.png"
                        }
                        height="25px"
                        width="25px"
                        style={{
                          borderRadius: "50%",
                          boxShadow: "0px 0px 3px rgba(0,0,0,.3)",
                        }}
                      />
                    </IconButton>
                  ) : (
                    <IconButton
                      className={classes.margin}
                      onClick={() => {
                        if (window.location.pathname.indexOf("/new") > -1) {
                          this.setState({ interstitialOpen: true });
                        } else {
                          this.props.ecommerceRefresh("ETSY");
                        }
                      }}
                    >
                      <img
                        src={
                          "https://res-1.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_256,w_256,f_auto,q_auto:eco/v1475508264/bjljj1do9xbdoo1vxury.png"
                        }
                        height="25px"
                        width="25px"
                        style={{
                          borderRadius: "50%",
                          boxShadow: "0px 0px 3px rgba(0,0,0,.3)",
                        }}
                      />
                    </IconButton>
                  )
                ) : (
                  ""
                )} */}

                {/* {this.props.connections.filter(
                  (e) => e.platform == "WOOCOMMERCE"
                ).length > 0 ? (
                  this.props.woocommerceLoading ? (
                    <IconButton
                      className={classes.margin}
                      style={{ opacity: 0.3 }}
                      onClick={() => {}}
                    >
                      <img
                        src={
                          "https://images.swiftpost.com/ecommerce-integrations/woocommerce_logo.png"
                        }
                        height="25px"
                        width="25px"
                        style={{
                          borderRadius: "50%",
                          boxShadow: "0px 0px 3px rgba(0,0,0,.3)",
                        }}
                      />
                    </IconButton>
                  ) : (
                    <IconButton
                      className={classes.margin}
                      onClick={() => {
                        if (window.location.pathname.indexOf("/new") > -1) {
                          this.setState({ interstitialOpen: true });
                        } else {
                          this.props.ecommerceRefresh("WOOCOMMERCE");
                        }
                      }}
                    >
                      <img
                        src={
                          "https://images.swiftpost.com/ecommerce-integrations/woocommerce_logo.png"
                        }
                        height="25px"
                        width="25px"
                        style={{
                          borderRadius: "50%",
                          boxShadow: "0px 0px 3px rgba(0,0,0,.3)",
                        }}
                      />
                    </IconButton>
                  )
                ) : (
                  ""
                )}

                {this.props.connections.filter((e) => e.platform == "SHOPIFY")
                  .length > 0 ? (
                  this.props.shopifyLoading ? (
                    <IconButton
                      className={classes.margin}
                      style={{ opacity: 0.3 }}
                      onClick={() => {}}
                    >
                      <img
                        src={
                          "https://images.swiftpost.com/ecommerce-integrations/shopify_logo.png"
                        }
                        height="25px"
                        width="25px"
                        style={{
                          borderRadius: "50%",
                          boxShadow: "0px 0px 3px rgba(0,0,0,.3)",
                        }}
                      />
                    </IconButton>
                  ) : (
                    <IconButton
                      disabled={this.props.shopifyLoading}
                      className={classes.margin}
                      onClick={() => {
                        if (window.location.pathname.indexOf("/new") > -1) {
                          this.setState({ interstitialOpen: true });
                        } else {
                          this.props.ecommerceRefresh("SHOPIFY");
                        }
                      }}
                    >
                      <img
                        src={
                          "https://images.swiftpost.com/ecommerce-integrations/shopify_logo.png"
                        }
                        height="25px"
                        width="25px"
                        style={{
                          borderRadius: "50%",
                          boxShadow: "0px 0px 3px rgba(0,0,0,.3)",
                        }}
                      />
                    </IconButton>
                  )
                ) : (
                  ""
                )} */}

                {/* {(this.props.account &&
                  this.props.account.user &&
                  this.props.account.user.hideBilling) ||
                (this.props.account &&
                  this.props.account.user &&
                  this.props.account.user.noWallet) ? (
                  ""
                ) : (
                  <BalanceButton
                    createSnackBar={this.props.createSnackBar}
                    account={this.props.account}
                    onAppBar={1}
                    paymentmethods={this.props.paymentmethods}
                    balance={this.props.balance}
                    addPayment={this.props.addPayment}
                    open={this.state.openTopUpStepper}
                    user={this.props.user}
                    handleUserData={this.props.handleUserData}
                  />
                )} */}
                {/* <IconButton
                            aria-owns={open ? 'menu-appbar' : null}
                            aria-haspopup="true"
                            onClick={this.handleMenu}
                            color="inherit"
                            >
                            {((!('account' in this.props.user) ? true : !('fname' in this.props.user.account[0])) || this.props.user.payment.length === 0 || this.props.user.location.length === 0) && <Badge classes={{ badge: classes.badge2 }} badgeContent={<WarningIcon />}><AlertIcon /></Badge> }
                            {(this.props.user.payment.length != 0 && this.props.user.location.length != 0) && <AlertIcon style={{opacity:.3}} /> }
                            </IconButton>
                            <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            onClose={this.handleClose}
                            >
                            {this.props.user.payment.length === 0 && <MenuItem className={classes.menuItem} onClick={()=> { this.handleClose();this.props.history.push("/payments");}}>
                            <ListItemIcon className={classes.icon}>
                                <PaymentIcon />
                            </ListItemIcon>
                            <ListItemText classes={{ primary: classes.primary }} inset primary="Add a payment method" />
                            </MenuItem>}
                            {this.props.user.location.length === 0 && <MenuItem className={classes.menuItem} onClick={()=> { this.handleClose();this.props.history.push("/locations");}}>
                            <ListItemIcon className={classes.icon}>
                                <LocationIcon />
                            </ListItemIcon>
                            <ListItemText classes={{ primary: classes.primary }} inset primary="Add a pickup location" />
                            </MenuItem>}
                            {(!('account' in this.props.user) ? true : !('fname' in this.props.user.account[0])) && <MenuItem className={classes.menuItem} onClick={()=> { this.handleClose();this.props.history.push("/account");}}>
                            <ListItemIcon className={classes.icon}>
                                <AccountIcon />
                            </ListItemIcon>
                            <ListItemText classes={{ primary: classes.primary }} inset primary="Complete account profile" />
                            </MenuItem>}
                            {(this.props.user.payment.length != 0 && this.props.user.location.length != 0) && <MenuItem className={classes.menuItem} onClick={() => { this.handleClose(); }}>
                            <ListItemIcon className={classes.icon}>
                                <Done />
                            </ListItemIcon>
                            <ListItemText classes={{ primary: classes.primary }} inset primary="There are no alerts at this time" />
                            </MenuItem>}
                            </Menu> */}
              </div>
            </Toolbar>
          </AppBar>
        </Hidden>
        <Hidden smDown>
          <AppBar
            position="absolute"
            className={classNames(
              classes.appBar,
              this.state.open && classes.appBarShift
            )}
          >
            {/* <Typography className={classes.banner} variant="body2" noWrap>
                  Something test
                </Typography> */}
            <Toolbar disableGutters={!this.state.open}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
                className={classNames(
                  classes.menuButton,
                  this.state.open && classes.hide
                )}
              >
                <MenuIcon />
              </IconButton>
              <img
                src={Logo}
                style={{ maxHeight: "23px" }}
                alt="Swiftpost Logo"
              />

              {/* <div>
                                <IconButton
                                aria-owns={open ? 'menu-appbar' : undefined}
                                aria-haspopup="true"
                                onClick={this.handleMenu}
                                color="inherit"
                                style={{right:'20px',position:'absolute'}}
                                >
                                    <AccountIcon />
                                </IconButton>
                                <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={open}
                                onClose={this.handleClose}
                                >
                                <MenuItem onClick={this.handleClose}>
                                <div style={{
                                    '-webkit-user-select':'none',
                                    '-moz-user-select': 'none',
                                    '-ms-user-select': 'none',
                                    '-o-user-select': 'none',
                                    'user-select': 'none',
                                    height:'100px',
                                    padding:'30px',
                                    fontSize:'1.5em',
                                    lineHeight:'100px'
                                }}><center>$200 CAD</center></div></MenuItem>
                                <Link to={'/account'}><MenuItem onClick={this.handleClose}>My account</MenuItem></Link>
                                </Menu>
                            </div> */}

              <div style={{ position: "absolute", right: "40px" }}>
                {/* {this.props.connections.length > 0 &&
                this.props.connections.filter((e) => e.platform == "ETSY")
                  .length > 0 ? (
                  <Button
                    color="success"
                    variant="contained"
                    style={{ marginRight: "10px" }}
                    onClick={() => {
                      if (window.location.pathname.indexOf("/new") > -1) {
                        this.setState({ interstitialOpen: true });
                      } else {
                        this.props.ecommerceRefresh("ETSY");
                      }
                    }}
                    className={classes.margin}
                  >
                    {this.props.etsyLoading == true && (
                      <LoadingIcon
                        style={{
                          position: "absolute",
                          top: "15%",
                          left: "9.5%",
                          fill: "#fff",
                        }}
                        color="white"
                        size={25}
                      />
                    )}
                    <img
                      src={
                        "https://images.swiftpost.com/ecommerce-integrations/etsy_logo.png"
                      }
                      height="25px"
                      width="25px"
                      style={{
                        borderRadius: "50%",
                        boxShadow: "0px 0px 3px rgba(0,0,0,.3)",
                      }}
                    />
                    &nbsp;&nbsp;{this.language.ImportOrder[this.state.language]}
                  </Button>
                ) : (
                  ""
                )}
                {this.props.connections.length > 0 &&
                this.props.connections.filter(
                  (e) => e.platform == "WOOCOMMERCE"
                ).length > 0 ? (
                  <Button
                    color="success"
                    variant="contained"
                    style={{ marginRight: "10px" }}
                    onClick={() => {
                      if (window.location.pathname.indexOf("/new") > -1) {
                        this.setState({ interstitialOpen: true });
                      } else {
                        this.props.ecommerceRefresh("WOOCOMMERCE");
                      }
                    }}
                    className={classes.margin}
                  >
                    {this.props.woocommerceLoading == true && (
                      <LoadingIcon
                        style={{
                          position: "absolute",
                          top: "15%",
                          left: "9.5%",
                          fill: "#fff",
                        }}
                        color="white"
                        size={25}
                      />
                    )}
                    <img
                      src={
                        "https://images.swiftpost.com/ecommerce-integrations/woocommerce_logo.png"
                      }
                      height="25px"
                      width="25px"
                      style={{
                        borderRadius: "50%",
                        boxShadow: "0px 0px 3px rgba(0,0,0,.3)",
                      }}
                    />
                    &nbsp;&nbsp;{this.language.ImportOrder[this.state.language]}
                  </Button>
                ) : (
                  ""
                )}
                {this.props.connections.length > 0 &&
                this.props.connections.filter((e) => e.platform == "SHOPIFY")
                  .length > 0 ? (
                  <Button
                    color="success"
                    variant="contained"
                    style={{ marginRight: "10px" }}
                    onClick={() => {
                      if (window.location.pathname.indexOf("/new") > -1) {
                        this.setState({ interstitialOpen: true });
                      } else {
                        this.props.ecommerceRefresh("SHOPIFY");
                      }
                    }}
                    className={classes.margin}
                  >
                    {this.props.shopifyLoading == true && (
                      <LoadingIcon
                        style={{
                          position: "absolute",
                          top: "15%",
                          left: "9.5%",
                          fill: "#fff",
                        }}
                        color="white"
                        size={25}
                      />
                    )}
                    <img
                      src={
                        "https://images.swiftpost.com/ecommerce-integrations/shopify_logo.png"
                      }
                      height="25px"
                      width="25px"
                      style={{
                        borderRadius: "50%",
                        boxShadow: "0px 0px 3px rgba(0,0,0,.3)",
                      }}
                    />
                    &nbsp;&nbsp;{this.language.ImportOrder[this.state.language]}
                  </Button>
                ) : (
                  ""
                )} */}

                {/* {(this.props.account &&
                  this.props.account.user &&
                  this.props.account.user.hideBilling) ||
                (this.props.account &&
                  this.props.account.user &&
                  this.props.account.user.noWallet) ? (
                  ""
                ) : (
                  <BalanceButton
                    createSnackBar={this.props.createSnackBar}
                    account={this.props.account}
                    onAppBar={1}
                    paymentmethods={this.props.paymentmethods}
                    balance={this.props.balance}
                    addPayment={this.props.addPayment}
                    open={this.state.openTopUpStepper}
                    user={this.props.user}
                    handleUserData={this.props.handleUserData}
                  />
                )} */}
                {/* <IconButton
                            aria-owns={open ? 'menu-appbar' : null}
                            aria-haspopup="true"
                            onClick={this.handleMenu}
                            color="inherit"
                            >
                            {((!('account' in this.props.user) ? true : !('fname' in this.props.user.account[0])) || this.props.user.payment.length === 0 || this.props.user.location.length === 0) && <Badge classes={{ badge: classes.badge2 }} badgeContent={<WarningIcon />}><AlertIcon /></Badge> }
                            {(this.props.user.payment.length != 0 && this.props.user.location.length != 0) && <AlertIcon style={{opacity:.3}} /> }
                            </IconButton>
                            <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            onClose={this.handleClose}
                            >
                            {this.props.user.payment.length === 0 && <MenuItem className={classes.menuItem} onClick={()=> { this.handleClose();this.props.history.push("/payments");}}>
                            <ListItemIcon className={classes.icon}>
                                <PaymentIcon />
                            </ListItemIcon>
                            <ListItemText classes={{ primary: classes.primary }} inset primary="Add a payment method" />
                            </MenuItem>}
                            {this.props.user.location.length === 0 && <MenuItem className={classes.menuItem} onClick={()=> { this.handleClose();this.props.history.push("/locations");}}>
                            <ListItemIcon className={classes.icon}>
                                <LocationIcon />
                            </ListItemIcon>
                            <ListItemText classes={{ primary: classes.primary }} inset primary="Add a pickup location" />
                            </MenuItem>}
                            {(!('account' in this.props.user) ? true : !('fname' in this.props.user.account[0])) && <MenuItem className={classes.menuItem} onClick={()=> { this.handleClose();this.props.history.push("/account");}}>
                            <ListItemIcon className={classes.icon}>
                                <AccountIcon />
                            </ListItemIcon>
                            <ListItemText classes={{ primary: classes.primary }} inset primary="Complete account profile" />
                            </MenuItem>}
                            {(this.props.user.payment.length != 0 && this.props.user.location.length != 0) && <MenuItem className={classes.menuItem} onClick={() => { this.handleClose(); }}>
                            <ListItemIcon className={classes.icon}>
                                <Done />
                            </ListItemIcon>
                            <ListItemText classes={{ primary: classes.primary }} inset primary="There are no alerts at this time" />
                            </MenuItem>}
                            </Menu> */}
              </div>
            </Toolbar>
          </AppBar>
        </Hidden>
        <Hidden smDown>
          <Drawer
            variant="permanent"
            className={classNames(!this.state.open && "test")}
            classes={{
              paper: classNames(
                classes.drawerPaper,
                !this.state.open && classes.drawerPaperClose
              ),
            }}
            open={this.state.open}
          >
            <div className={classes.toolbar}>
              <IconButton onClick={this.handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </div>
            <Divider />
            <List>
              <div>
                <Tooltip
                  title={this.language.NewShipment[this.state.language]}
                  placement="right"
                >
                  <NewShipment account={this.props.account} />
                </Tooltip>
              </div>
            </List>
            <Divider />
            <List>
              <div>
                <Tooltip
                  title={this.language.MyOrders[this.state.language]}
                  placement="right"
                >
                  <Link to="/">
                    <ListItem button>
                      {this.props.shipments.length ? (
                        <ListItemIcon className="navicon">
                          <Badge
                            classes={{ badge: classes.badge }}
                            color="secondary"
                            badgeContent={this.props.shipments.length}
                          >
                            <InboxIcon />
                          </Badge>
                        </ListItemIcon>
                      ) : (
                        <ListItemIcon className="navicon">
                          <InboxIcon />
                        </ListItemIcon>
                      )}
                      <ListItemText
                        primary={this.language.MyOrders[this.state.language]}
                      />
                    </ListItem>
                  </Link>
                </Tooltip>
                <Tooltip
                  title={this.language.MyShipments[this.state.language]}
                  placement="right"
                >
                  <Link to="/shipments">
                    <ListItem button>
                      <ListItemIcon className="navicon">
                        <ShipmentIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={this.language.MyShipments[this.state.language]}
                      />
                    </ListItem>
                  </Link>
                </Tooltip>
                {/* {this.props.account &&
                  this.props.account.user &&
                  (this.props.account.user.isChild ||
                    this.props.account.user.isParent) && (
                    <Tooltip
                      title={
                        this.language.InboundShipments[this.state.language]
                      }
                      placement="right"
                    >
                      <Link to="/inbound">
                        <ListItem button>
                          <ListItemIcon className="navicon">
                            <MoveToInboxOutlined />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              this.language.InboundShipments[
                                this.state.language
                              ]
                            }
                          />
                        </ListItem>
                      </Link>
                    </Tooltip>
                  )} */}
              </div>
            </List>
            <Divider />
            <List>
              <div>
                {this.props.account &&
                  this.props.account.user &&
                  this.props.account.user.isParent && (
                    <Tooltip
                      title={
                        this.props.account.user.childType
                          ? `${this.props.account.user.childType} Accounts`
                          : this.language.ChildAccounts[this.state.language]
                      }
                      placement="right"
                    >
                      <Link to="/child-accounts">
                        <ListItem button>
                          <ListItemIcon className="navicon">
                            <SupervisedUserCircle />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              this.props.account.user.childType
                                ? `${this.props.account.user.childType} Accounts`
                                : this.language.ChildAccounts[
                                    this.state.language
                                  ]
                            }
                          />
                        </ListItem>
                      </Link>
                    </Tooltip>
                  )}
                {/* {this.props.account && this.props.account.user && this.props.account.user.returnsOnly ?  '' : <Tooltip title="Locations" placement="right"> */}
                <Tooltip
                  title={this.language.MyLocations[this.state.language]}
                  placement="right"
                >
                  <Link to="/locations">
                    <ListItem button>
                      <ListItemIcon className="navicon">
                        <LocationIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={this.language.MyLocations[this.state.language]}
                      />
                    </ListItem>
                  </Link>
                </Tooltip>
                {/* } */}

                {this.props.account &&
                this.props.account.user &&
                (this.props.account.user.isParent ||
                  this.props.account.user.isChild) ? (
                  ""
                ) : (
                  <Tooltip title={`Address Book`} placement="right">
                    <AddressBookButton />
                  </Tooltip>
                )}
                {/* {this.props.account &&
                this.props.account.user &&
                (this.props.account.user.isParent ||
                  this.props.account.user.isChild) ? (
                  ""
                ) : (
                  <Tooltip
                    title={
                      this.language.EcommerceConnections[this.state.language]
                    }
                    placement="right"
                  >
                    <Link to="/connections">
                      <ListItem button>
                        <ListItemIcon className="navicon">
                          <ConnectionIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            this.language.EcommerceConnections[
                              this.state.language
                            ]
                          }
                        />
                      </ListItem>
                    </Link>
                  </Tooltip>
                )} */}
                <Tooltip
                    title={
                      this.language.SelfDelivery[this.state.language]
                    }
                    placement="right"
                  >

                    <Link to="/self-delivery">
                      <ListItem button>
                        <ListItemIcon className="navicon">
                        <img
                          src={RoutesLogo}
                          style={{ maxHeight: "23px" }}
                          alt="Swiftpost Logo"
                        />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            this.language.SelfDelivery[
                              this.state.language
                            ]
                          }
                        />
                      </ListItem>
                    </Link>
                  </Tooltip>
              </div>
            </List>
            <Divider />
            <List>
              <div>
                {/* {this.props.account &&
                this.props.account.user &&
                this.props.account.user.hideBilling ? (
                  ""
                ) : (
                  <Tooltip
                    title={this.language.BillingCentre[this.state.language]}
                    placement="right"
                  >
                    <Link to="/payments">
                      <ListItem button>
                        <ListItemIcon className="navicon">
                          <PaymentIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            this.language.BillingCentre[this.state.language]
                          }
                        />
                      </ListItem>
                    </Link>
                  </Tooltip>
                )} */}
                {this.props.account &&
                this.props.account.user &&
                this.props.account.user.hideBilling ? (
                  ""
                ) : (
                  <Tooltip
                    title={this.language.Account[this.state.language]}
                    placement="right"
                  >
                    <Link to="/account">
                      <ListItem button>
                        <ListItemIcon className="navicon">
                          <AccountIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={this.language.Account[this.state.language]}
                        />
                      </ListItem>
                    </Link>
                  </Tooltip>
                )}
                <Tooltip
                  title={this.language.Settings[this.state.language]}
                  placement="right"
                >
                  <Link to="/settings">
                    <ListItem button>
                      <ListItemIcon className="navicon">
                        <Settings />
                      </ListItemIcon>
                      <ListItemText
                        primary={this.language.Settings[this.state.language]}
                      />
                    </ListItem>
                  </Link>
                </Tooltip>
              </div>
            </List>
            <Divider />
            <List>
              <div>
                <Logout
                  {...this.props}
                  handleLogout={this.props.handleLogout}
                  history={this.props.history}
                />
              </div>
            </List>
          </Drawer>
        </Hidden>
        <Hidden mdUp>
          <SwipeableDrawer
            open={this.state.open}
            onClose={this.handleDrawerClose}
            onOpen={this.handleDrawerOpen}
          >
            <div className={classes.toolbar}>
              <IconButton onClick={this.handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </div>
            <Divider />
            <List>
              <div>
                <Tooltip
                  title={this.language.NewShipment[this.state.language]}
                  placement="right"
                >
                  <NewShipment
                    handleMobile={this.handleDrawerClose}
                    account={this.props.account}
                  />
                </Tooltip>
              </div>
            </List>
            <Divider />
            <List>
              <div onClick={this.handleDrawerClose}>
                <Tooltip
                  title={this.language.MyOrders[this.state.language]}
                  placement="right"
                >
                  <Link to="/">
                    <ListItem button>
                      {this.props.shipments.length > 0 ? (
                        <ListItemIcon className="navicon">
                          <Badge
                            classes={{ badge: classes.badge }}
                            color="secondary"
                            badgeContent={this.props.shipments.length}
                          >
                            <InboxIcon />
                          </Badge>
                        </ListItemIcon>
                      ) : (
                        <ListItemIcon className="navicon">
                          <InboxIcon />
                        </ListItemIcon>
                      )}
                      <ListItemText
                        primary={this.language.MyOrders[this.state.language]}
                      />
                    </ListItem>
                  </Link>
                </Tooltip>
                <Tooltip
                  title={this.language.MyShipments[this.state.language]}
                  placement="right"
                >
                  <Link to="/shipments">
                    <ListItem button>
                      <ListItemIcon className="navicon">
                        <ShipmentIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={this.language.MyShipments[this.state.language]}
                      />
                    </ListItem>
                  </Link>
                </Tooltip>
                {/* {this.props.account &&
                  this.props.account.user &&
                  (this.props.account.user.isChild ||
                    this.props.account.user.isParent) && (
                    <Tooltip
                      title={
                        this.language.InboundShipments[this.state.language]
                      }
                      placement="right"
                    >
                      <Link to="/inbound">
                        <ListItem button>
                          <ListItemIcon className="navicon">
                            <MoveToInboxOutlined />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              this.language.InboundShipments[
                                this.state.language
                              ]
                            }
                          />
                        </ListItem>
                      </Link>
                    </Tooltip>
                  )} */}
              </div>
            </List>
            <Divider />
            <List>
              <div onClick={this.handleDrawerClose}>
                {this.props.account &&
                  this.props.account.user &&
                  this.props.account.user.isParent && (
                    <Tooltip
                      title={
                        this.props.account.user.childType
                          ? `${this.props.account.user.childType} Accounts`
                          : this.language.ChildAccounts[this.state.language]
                      }
                      placement="right"
                    >
                      <Link to="/child-accounts">
                        <ListItem button>
                          <ListItemIcon className="navicon">
                            <SupervisedUserCircle />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              this.props.account.user.childType
                                ? `${this.props.account.user.childType} Accounts`
                                : this.language.ChildAccounts[
                                    this.state.language
                                  ]
                            }
                          />
                        </ListItem>
                      </Link>
                    </Tooltip>
                  )}
                <Tooltip
                  title={this.language.MyLocations[this.state.language]}
                  placement="right"
                >
                  <Link to="/locations">
                    <ListItem button>
                      <ListItemIcon className="navicon">
                        <LocationIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={this.language.MyLocations[this.state.language]}
                      />
                    </ListItem>
                  </Link>
                </Tooltip>
                {this.props.account &&
                this.props.account.user &&
                (this.props.account.user.isParent ||
                  this.props.account.user.isChild) ? (
                  ""
                ) : (
                  <Tooltip title={`Address Book`} placement="right">
                    <AddressBookButton />
                  </Tooltip>
                )}
                {/* {this.props.account &&
                this.props.account.user &&
                this.props.account.user.hideBilling ? (
                  ""
                ) : (
                  <Tooltip
                    title={this.language.BillingCentre[this.state.language]}
                    placement="right"
                  >
                    <Link to="/payments">
                      <ListItem button>
                        <ListItemIcon className="navicon">
                          <PaymentIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            this.language.BillingCentre[this.state.language]
                          }
                        />
                      </ListItem>
                    </Link>
                  </Tooltip>
                )} */}
                {/* {this.props.account &&
                this.props.account.user &&
                (this.props.account.user.isParent ||
                  this.props.account.user.isChild) ? (
                  ""
                ) : (
                  <Tooltip
                    title={
                      this.language.EcommerceConnections[this.state.language]
                    }
                    placement="right"
                  >
                    <Link to="/connections">
                      <ListItem button>
                        <ListItemIcon className="navicon">
                          <ConnectionIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            this.language.EcommerceConnections[
                              this.state.language
                            ]
                          }
                        />
                      </ListItem>
                    </Link>
                  </Tooltip>
                )} */}
                <Tooltip
                    title={
                      this.language.SelfDelivery[this.state.language]
                    }
                    placement="right"
                  >

                    <Link to="/self-delivery">
                      <ListItem button>
                        <ListItemIcon className="navicon">
                        <img
                          src={RoutesLogo}
                          style={{ maxHeight: "23px" }}
                          alt="Swiftpost Logo"
                        />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            this.language.SelfDelivery[
                              this.state.language
                            ]
                          }
                        />
                      </ListItem>
                    </Link>
                  </Tooltip>
                  <Divider/>
                {this.props.account &&
                this.props.account.user &&
                this.props.account.user.hideBilling ? (
                  ""
                ) : (
                  <Tooltip
                    title={this.language.Account[this.state.language]}
                    placement="right"
                  >
                    <Link to="/account">
                      <ListItem button>
                        <ListItemIcon className="navicon">
                          <AccountIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={this.language.Account[this.state.language]}
                        />
                      </ListItem>
                    </Link>
                  </Tooltip>
                )}
                <Tooltip
                  title={this.language.Settings[this.state.language]}
                  placement="right"
                >
                  <Link to="/settings">
                    <ListItem button>
                      <ListItemIcon className="navicon">
                        <Settings />
                      </ListItemIcon>
                      <ListItemText
                        primary={this.language.Settings[this.state.language]}
                      />
                    </ListItem>
                  </Link>
                </Tooltip>
              </div>
            </List>
            <Divider />
            <List>
              <div>
                <Logout
                  {...this.props}
                  handleLogout={this.props.handleLogout}
                  history={this.props.history}
                />
              </div>
            </List>
          </SwipeableDrawer>
        </Hidden>
      </Fragment>
    );
  }
}

SwiftNav.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(SwiftNav);
