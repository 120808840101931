import { API, graphqlOperation } from "aws-amplify";

export const onDeleteEcommerceIntegration = (user: string) =>
  API.graphql(
    graphqlOperation(`
      subscription {
        onDeleteEcommerceIntegration(cognitoUsername:"${user}") {
          id
          cognitoUsername
        }
      }
    `)
  );
