// Allow an update to clear values by setting them to null if they're empty
export function emptyStringsToNulls<T extends Record<string, unknown>>(
  obj: T
): T {
  const toNulls = { ...obj };

  for (const property in toNulls) {
    if (toNulls[property] === "") {
      (toNulls as any)[property] = null;
    }
  }
  return toNulls;
}

// Values come out of Formik with *blank strings* for each property
// we were required to set in initialValues this strips them out
export function withoutEmptyStrings<T extends Record<string, unknown>>(
  obj: T
): T {
  const withoutEmpty = { ...obj };

  for (const property in withoutEmpty) {
    if (withoutEmpty[property] === "") {
      delete withoutEmpty[property];
    }
  }
  return withoutEmpty;
}
