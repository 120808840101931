import { Button, Grid, Hidden, Theme, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Add, UnarchiveOutlined } from "@material-ui/icons";
import { AddressBookRecord } from "@shared";
import { querySelfDeliveryAccount, updateSelfDeliveryAccount } from "api/selfDelivery";
import LoadingIcon from "components/loading/LoadingIcon";
import PagePaper from "pages/PagePaper";
import PropTypes from "prop-types";
import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import "../../styles.css";
import CreateSelfDeliveryAccount from "./CreateSelfDeliveryAccount";

//defaulting self delivery aos radius to 2km
const DEFAULT_RADIUS_KM = 2, MINIMUM_RADIUS_KM = .5;

const styles = (theme: Theme) => ({
  topActions: {
    display: "flex",
    justifyContent: "space-between",
    margin: "1em 0 2em",
  },
});

export function SelfDelivery(props: { classes: any; account:any; locations:any, history:any }) {
  const [fetchingAccount, setFetchingAccount] = React.useState<boolean>(true);
  const [saving, setSaving] = React.useState<boolean>(false);
  const [account, setAccount] = React.useState<any>();
  const [email, setEmail] = React.useState<any>();
  const [phone, setPhone] = React.useState<any>();
  const [radius, setRadius] = React.useState<number>(500);
  const [center, setCenter] = React.useState<{lat: number, lng: number}>();

  let map: google.maps.Map;
  let circle: google.maps.Circle;

  async function checkForAccount(){
    const account = await querySelfDeliveryAccount();
    if(!account?.integration_id){
      props.history.push("/self-delivery/create")
    } else{
      setAccount(account);
      setPhone(account.contact_phone);
      setEmail(account.contact_email);
      setFetchingAccount(false);
      console.log(account.radius_km);
      console.log(account.radius_km * 1000);
      setRadius(account.radius_km * 1000);
      setCenter(account.origin_center);
      initMap();
      initCircle(account.origin_center, account.radius_km);
    }
  }

  React.useEffect(()=>{
    checkForAccount();
  },[]);

  const roundTenth = (num: number) => Math.round(num * 10) / 10;

  function initCircle(origin_center:{lat: number, lng: number}, radius_km:number): void {
    circle = new google.maps.Circle({
      editable: true,
      strokeColor: '#FF0000',
      strokeOpacity: 0.5,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
      map: map,
      center: origin_center,
      radius: radius_km * 1000, //meters
    });
    circle.addListener('radius_changed', function() {
      setRadius(roundTenth(circle.getRadius()));
      if (circle.getRadius() < MINIMUM_RADIUS_KM * 1000) {
          circle.setRadius(roundTenth(MINIMUM_RADIUS_KM * 1000));
      }
    });
    circle.addListener('center_changed', function() {
      const new_center = circle.getCenter();
      setCenter({lat: new_center?.lat() ?? 0, lng: new_center?.lng() ?? 0 });
    });
    map.panTo(origin_center);
};

  function initMap(): void {
    map =  new window.google.maps.Map(document.getElementById("map")!, {
        streetViewControl: false,
        mapTypeControl: false,
        center: {
          lat: Number(props?.account?.location?.address?.coords_lat),
          lng: Number(props?.account?.location?.address?.coords_long),
        },
        zoom: 11,
        mapTypeId: "roadmap",
        styles: [
          {
            featureType: "administrative.land_parcel",
            elementType: "labels",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "labels.text",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.business",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.medical",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.park",
            stylers: [
              {
                visibility: "simplified",
              },
            ],
          },
          {
            featureType: "poi.place_of_worship",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.sports_complex",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road.local",
            elementType: "labels",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "road.local",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "transit",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
        ],
      });
 }

  const updateAccount = async ({origin_center, radius_km, contact_email, contact_phone, enabled} :any) => {
    setSaving(true);
    // console.log(enabled.toString(), account.enabled.toString());
    console.log({origin_center, radius_km, contact_email, contact_phone, enabled});
    await updateSelfDeliveryAccount({origin_center, radius_km, contact_email, contact_phone, enabled})
    await checkForAccount();
    setSaving(false);
  }

  return (
    <div className="Home">
      <Typography variant="h1">Self-Delivery</Typography>
      <Grid container>
        <Grid item xs={12} md={8} lg={6} xl={4}>
        {fetchingAccount ?
        <LoadingIcon /> :
        <PagePaper>
          <p><strong style={{color:"var(--deep-blue)"}}><small>ENABLE SELF-DELIVERY</small></strong></p>
          <p>
            <label className="switch" onClick={()=>{updateAccount({enabled: !account.enabled})}}>
              <input type="checkbox" checked={account.enabled} />
              <span className="slider round"></span>
            </label>
          </p>
          <hr/>
          <p><strong style={{color:"var(--deep-blue)"}}><small>SUPPORT CONTACT INFORMATION</small></strong></p>
          <p><input onKeyDown={(e)=>{
            if (e.keyCode === 13) {
              e.preventDefault();
            }
          }}
          defaultValue={account?.contact_phone}
          className="form-control places-autocomplete"
          type="text"
          placeholder="Contact Phone Number"
          onChange={e => setPhone(e.target.value)}
          value={phone}
          style={{
            zIndex: 1,
            top:10,
            left: 10,
            padding: 10,
            border: "1px solid var(--deep-blue)",
            borderRadius: 20,
            paddingLeft: "1em",
            width: '50%',
          }} /></p>
          <p><input onKeyDown={(e)=>{
            if (e.keyCode === 13) {
              e.preventDefault();
            }
          }}
          defaultValue={account?.contact_email}
          className="form-control places-autocomplete"
          type="text"
          placeholder="Contact Email"
          onChange={e => setEmail(e.target.value)}
          value={email}
          style={{
            zIndex: 1,
            top:10,
            left: 10,
            padding: 10,
            border: "1px solid var(--deep-blue)",
            borderRadius: 20,
            paddingLeft: "1em",
            width: '50%',
          }} /></p>
          <p>
            <Button onClick={()=>updateAccount({contact_phone:phone, contact_email:email})} disabled={saving || (account.contact_phone == phone && account.contact_email == email)} color="primary">Save</Button>
          </p>
          <hr/>
          <p><strong style={{color:"var(--deep-blue)"}}><small>SELF-DELIVERY AREA OF SERVICE</small></strong></p>
          <p>
          <div style={{position:'relative'}}>
          <div id="map" style={{ height: 500, width: '100%', borderRadius:10 }} />
          <div style={{
            background: "#fff",
            borderRadius:10,
            padding:10,
            position:'absolute',
            bottom:10,
            left:10,
            zIndex:1,
            boxShadow: "0px 12px 24px 4px rgba(0, 0, 0, 0.08)",

          }}><strong>Delivery Radius: <span style={{color:"var(--deep-blue)"}}>{(roundTenth(radius / 1000))} KM</span></strong></div>
          </div>
          </p>
          <p>
            <Button onClick={()=>updateAccount({origin_center: center, radius_km: roundTenth(radius/1000)})} disabled={saving || (account.origin_center == center && account.radius_km == roundTenth(radius/1000))} color="primary">Save</Button>
          </p>
        </PagePaper>
        }
        </Grid>
      </Grid>
    </div>
  );
}

SelfDelivery.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SelfDelivery);
