import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import React from "react";

class Checkout extends React.Component {
  state = {
    open: true,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  UNSAFE_componentWillMount = () => {
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });
  };

  language = {
    Processingyourorder: {
      FR: "Traitement de votre commande",
      EN: "Processing your order",
    },
    Therewasanerrorretreivinglabels: {
      FR: "Une erreur s'est produite lors de la récupération des étiquettes",
      EN: "There was an error retreiving labels",
    },
    ClickRetrybelow: {
      FR: "Cliquez sur Réessayer ci-dessous",
      EN: "Click Retry below",
    },
    Therewasanerrorcreatingshipments: {
      FR: "Une erreur s'est produite lors de la création des envois",
      EN: "There was an error creating shipments",
    },
    Retry: {
      FR: "Recommencez",
      EN: "Retry",
    },
    Finished: {
      FR: "Terminé",
      EN: "Finished",
    },
  };

  render() {
    const { fullScreen } = this.props;

    return (
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={this.props.open}
          onClose={() => {}}
          maxWidth={false}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {this.language.Processingyourorder[this.state.language]}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {!this.props.failedImages.length &&
                !this.props.failedShipments.length && <LinearProgress />}
              <h3>{this.props.progress}</h3>
              <h5>
                {this.props.failedImages.length ? (
                  <span>
                    {
                      this.language.Therewasanerrorretreivinglabels[
                        this.state.language
                      ]
                    }
                    <br />
                    {this.language.ClickRetrybelow[this.state.language]}
                  </span>
                ) : (
                  ``
                )}
                {this.props.failedShipments.length ? (
                  <span>
                    {
                      this.language.Therewasanerrorcreatingshipments[
                        this.state.language
                      ]
                    }
                    <br />
                    {this.language.ClickRetrybelow[this.state.language]}
                  </span>
                ) : (
                  ``
                )}
              </h5>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {this.props.failedImages.length && (
              <Button onClick={this.props.labelsAction} color="primary">
                {this.language.Retry[this.state.language]}
              </Button>
            )}
            {this.props.failedShipments.length && (
              <Button onClick={this.props.shipmentsAction} color="primary">
                {this.language.Retry[this.state.language]}
              </Button>
            )}
            {this.props.progress === "COMPLETE" && (
              <Button
                onClick={this.props.handleClose}
                color="primary"
                autoFocus
              >
                {this.language.Finished[this.state.language]}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

Checkout.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

export default withMobileDialog()(Checkout);
