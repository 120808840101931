import amber from "@material-ui/core/colors/amber";
import green from "@material-ui/core/colors/green";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { withStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles1 = (theme) => ({
  none: {
    backgroundColor: "#051F52",
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
});

function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          {variant === "none" ? (
            ""
          ) : (
            <Icon className={classNames(classes.icon, classes.iconVariant)} />
          )}
          {message}
        </span>
      }
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired,
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

const styles2 = (theme) => ({
  margin: {
    margin: theme.spacing.unit,
  },
});

class CustomizedSnackbars extends React.Component {
  render() {
    return (
      <div>
        <Snackbar
          anchorOrigin={
            this.props.anchorOrigin
              ? this.props.anchorOrigin
              : {
                  vertical: "bottom",
                  horizontal: "center",
                }
          }
          onClick={() =>
            this.props.closeSnackbar ? this.props.closeSnackbar() : {}
          }
          style={{ marginTop: this.props.anchorOrigin ? 50 : 0 }}
          open={this.props.open}
          autoHideDuration={parseInt(this.props.duration ?? 3000)}
          onClose={this.props.handleClose}
        >
          <MySnackbarContentWrapper
            variant={this.props.variant ? this.props.variant : "error"}
            className={this.props.margin}
            message={this.props.message}
            action={"action" in this.props ? this.props.action : ""}
          />
        </Snackbar>
      </div>
    );
  }
}

// CustomizedSnackbars.propTypes = {
//   classes: PropTypes.object.isRequired,
// };

export default withStyles(styles2)(CustomizedSnackbars);
