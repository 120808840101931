import { API, graphqlOperation } from "aws-amplify";

export const createAccount = (email: string) =>
  API.graphql(
    graphqlOperation(`
      mutation {
        createV1_Account(input: {
          location: {
            contact: {
              email:"${email}"
            } 
          }
        }) {
          id
          cognitoUsername
        }
      }
    `)
  );
