import { API, graphqlOperation } from "aws-amplify";

export const listBalances = () =>
  API.graphql(
    graphqlOperation(`
      query listBalances {
        listV1_Balances {
          items {
            id
            balance
            cognitoUsername
          }
        }
      }
    `)
  );
