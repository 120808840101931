import { API, graphqlOperation } from "aws-amplify";

export const listAccounts = () =>
  API.graphql(
    graphqlOperation(`
      query listAccounts {
        listV1_Accounts {
          items {
            signedXCForm
            id
            location {
              title
              address {
                street_name
                street_number
                street_direction
                postal
                city
                unit
                state
                country
                coords_lat
                coords_long
              }
              contact {
                fname
                lname
                email
                phone
              }
            }
            cognitoUsername
          }
        }
      }
    `)
  );
