import { TableCell, TableRow } from "@material-ui/core";
import { StyleRules, withStyles } from "@material-ui/core/styles";
import { FavoriteBorder } from "@material-ui/icons";
import React from "react";
import { AddressBookRecord } from "../../../../shared/types/address";
import { displayedFields } from "../../providers/AddressBook";
import "../../styles.css";
import { highlightTokens } from "./AddressBookTable";

export const FAVORITE_PADDING = "0 0 0 16px";

const styles: StyleRules = {
  row: {
    cursor: "pointer",
    "&:nth-child(2n + 1)": {
      backgroundColor: "var(--beige-50)",
    },
    "&:hover": {
      backgroundColor: "var(--brand-cyan-25)",
    },
  },
  favoriteCell: {
    padding: FAVORITE_PADDING,
  },
};

const AddressTableRow = ({
  address,
  tokens,
  onRowClicked,
  classes,
  isSmall,
}: {
  address: AddressBookRecord;
  tokens: string[];
  onRowClicked: (address: AddressBookRecord) => void;
  classes: any;
  isSmall: boolean;
}) => {
  return (
    <TableRow
      key={address.id}
      onClick={() => onRowClicked(address)}
      className={classes.row}
    >
      {!isSmall && (
        <TableCell className={classes.favoriteCell} headers="favorite">
          {address.favorite && <FavoriteBorder />}
        </TableCell>
      )}
      {displayedFields(isSmall).map((field, idx) => {
        return (
          <TableCell key={idx} className="resultRow">
            {highlightTokens(address[field]! as string, tokens)}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default withStyles(styles)(AddressTableRow);
