import DayjsUtils from "@date-io/dayjs";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  NativeSelect,
  Switch,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { SnackbarProps } from "@material-ui/core/Snackbar";
import { StyleRulesCallback, withStyles } from "@material-ui/core/styles";
import { TextFieldProps } from "@material-ui/core/TextField";
import { ArrowBackIos } from "@material-ui/icons";
import {
  Address,
  AddressBookRecord,
  CreateAddressRequest,
  UpdateAddressRequest,
} from "@shared";
import { verifyAddress } from "api/addressBook";
import dayjs from "dayjs";
import { Formik, useFormikContext } from "formik";
import { emptyStringsToNulls, withoutEmptyStrings } from "helpers/forms";
import { MuiPickersUtilsProvider, TimePicker } from "material-ui-pickers";
import { useEffect, useMemo, useState } from "react";
import SnackBar from "../../components/Snackbar";
import { useAddressBook } from "../../providers/AddressBook";
import {
  AddressFormSchema,
  MAX_CLOSE,
  MIN_OPEN,
  to24hr,
} from "../address_book/AddressFormSchema";
import DeleteAddressConfirmationDialog from "../address_book/DeleteAddressConfirmationDialog";
import { addressesMatch, fullFormattedAddress } from "../address_book/helpers";
import { StateProvinceSelect } from "../address_book/StateProvinceSelect";

const styles: StyleRulesCallback = (theme: Theme) => ({
  form: {
    padding: "0 16px",
  },
  formButtons: {
    paddingTop: 16,
    marginTop: 16,
    borderTop: "1px solid rgba(0, 0, 0, 0.09)",
    textAlign: "right",
  },
});

// material-ui-pickers v2 documentation: https://material-ui-pickers-v2.dmtr-kovalenko.vercel.app/getting-started/installation

// Forms don't allow null or undefined values
type NonNullableValues<Type> = {
  [Key in keyof Type]-?: NonNullable<Type[Key]>;
};

type MultipartFormState = {
  form:
    | NonNullableValues<CreateAddressRequest>
    | NonNullableValues<UpdateAddressRequest>;
  confirmation?: {
    originalAddress: Address;
    verifiedAddress: Address;
  };
};

const ReceiverForm = (props: {
  onSuccess: (address?: AddressBookRecord) => void;
  address?: AddressBookRecord;
  classes: any;
}) => {
  const { classes, onSuccess, address } = props;
  const [multipartFormState, setMultipartFormState] =
    useState<MultipartFormState>();
  const { createEntry, updateEntry } = useAddressBook();
  const [submitting, setSubmitting] = useState(false);
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);
  const [deletingAddress, setDeletingAddress] = useState<AddressBookRecord>();
  const [generalFormError, setGeneralFormError] = useState<string>();
  const [autocompleteFields, setAutocompleteFields] = useState<{
    address1:string,
    city:string,
    state:string,
    postal:string,
    country:string
  }>();

  const isUpdating = !!address?.id;

  let autocomplete: google.maps.places.Autocomplete = new google.maps.places.Autocomplete(document.getElementById("autocomplete")! as HTMLInputElement, {
      componentRestrictions: { country: "ca" },
  });

  const AutocompletePropagation = () => {
    // Grab values and submitForm from context
    const { setFieldValue } = useFormikContext();
    useEffect(() => {
      if(autocompleteFields?.address1){
        setFieldValue("address1", autocompleteFields?.address1);
        setFieldValue("city", autocompleteFields?.city);
        setFieldValue("state", autocompleteFields?.state);
        setFieldValue("postal", autocompleteFields?.postal);
        setFieldValue("country", autocompleteFields?.country);
      }
    }, [setFieldValue, autocompleteFields]);
    return null;
  };

  autocomplete.addListener('place_changed', function () {
      const place = autocomplete.getPlace();
      var components_by_type:any = {};
      // @ts-ignore
      for (var i = 0; i < place.address_components.length; i++) {
          // @ts-ignore
          var c = place.address_components[i];
          components_by_type[c.types[0]] = c;
      }
      setAutocompleteFields({
        address1: `${components_by_type.street_number.long_name} ${components_by_type.route.long_name}`,
        city: components_by_type.locality.long_name,
        state: components_by_type.administrative_area_level_1.short_name,
        postal: components_by_type.postal_code.long_name.split(" ").join(""),
        country: components_by_type.country.short_name,
      });
  });

  async function save(values: CreateAddressRequest | UpdateAddressRequest) {
    onSuccess?.(values);
    return address;
  }
  
  async function handleSubmit(values: typeof initialFormValues) {
    if(values.createAddressBookEntry){
      const {createAddressBookEntry, ...adressBookEntry} = values;
      await createEntry(withoutEmptyStrings(adressBookEntry));
    }
    await save({ ...values });
  }
  
  const existingFormData = multipartFormState?.form ?? address;
  const initialFormValues:
    | NonNullableValues<CreateAddressRequest>
    | NonNullableValues<UpdateAddressRequest> = {
    business_name: existingFormData?.business_name ?? "",
    first_name: existingFormData?.first_name ?? "",
    last_name: existingFormData?.last_name ?? "",
    email: existingFormData?.email ?? "",
    phone: existingFormData?.phone ?? "",
    address1: existingFormData?.address1 ?? "",
    address2: existingFormData?.address2 ?? "",
    city: existingFormData?.city ?? "",
    state: existingFormData?.state ?? "",
    postal: existingFormData?.postal ?? "",
    country: existingFormData?.country ?? "",
    open_time: existingFormData?.open_time ?? "",
    close_time: existingFormData?.close_time ?? "",
    location_note: existingFormData?.location_note ?? "",
    createAddressBookEntry: existingFormData?.createAddressBookEntry ?? false,
  };

  function handleAddressConfirmationChoice(selectedAddress: Address) {
  }



  // There's an address confirmation that needs dealt with
  if (multipartFormState?.confirmation) {
    return (
      <AddressConfirmation
        originalAddress={multipartFormState.confirmation.originalAddress}
        verifiedAddress={multipartFormState.confirmation.verifiedAddress}
        onSelectAddress={(selectedAddress) =>
          handleAddressConfirmationChoice(selectedAddress)
        }
        onReturnToEditing={() =>
          setMultipartFormState((prevState) => {
            if (prevState) {
              return {
                ...prevState,
                confirmation: undefined,
              };
            }
          })
        }
      />
    );
  }

  if (deletingAddress) {
    return (
      <DeleteAddressConfirmationDialog
        address={deletingAddress}
        open={true}
        onClose={() => {
          setDeletingAddress(undefined);
          onSuccess();
        }}
      />
    );
  }

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={AddressFormSchema}
      validateOnChange={hasAttemptedSubmit}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ handleSubmit, values, errors, handleChange, setFieldValue }) => (
        <form className={classes.form}>
          <AutocompletePropagation />
          <ErrorSnackBar
            open={!!generalFormError}
            message={generalFormError}
            onClose={() => setGeneralFormError(undefined)}
          />
          <Grid container spacing={8}>
            <Grid item xs={12} md={6}>
              <AddressBookField
                required
                name="address1"
                label="Address"
                id="autocomplete"
                
                value={values.address1}
                error={!!errors.address1}
                onChange={handleChange("address1")}
              />
              {errors.address1 && (
                <FormHelperText>{errors.address1}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <AddressBookField
                name="address2"
                label="Unit/Apt #"
                value={values.address2}
                error={!!errors.address2}
                onChange={handleChange("address2")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel error={!!errors.country} required>
                  Country
                </InputLabel>
                <NativeSelect
                  value={values.country}
                  onChange={(ev) => {
                    handleChange("country")(ev);
                    setFieldValue("state", ""); // Reset state when changing country
                  }}
                  input={<Input name="country" id="country-selector" />}
                  error={!!errors.country}
                >
                  <option value=""></option>
                  <option value="CA">Canada</option>
                  <option value="US">US</option>
                </NativeSelect>
              </FormControl>
              {errors.country && (
                <FormHelperText>{errors.country}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <StateProvinceSelect
                name="state"
                value={values.state}
                onChange={handleChange("state")}
                country={values.country}
                input={<Input name="state" id="state-selector" />}
                fullWidth
                error={!!errors.state}
                required
              />
              {errors.state && <FormHelperText>{errors.state}</FormHelperText>}
            </Grid>
            <Grid item xs={12} md={6}>
              <AddressBookField
                required
                name="city"
                label="City"
                value={values.city}
                error={!!errors.city}
                onChange={handleChange("city")}
              />
              {errors.city && <FormHelperText>{errors.city}</FormHelperText>}
            </Grid>
            <Grid item xs={12} md={6}>
              <AddressBookField
                required
                name="postal"
                label={values.country === "US" ? "ZIP code" : "Postal code"}
                value={values.postal}
                error={!!errors.postal}
                onBlur={() =>
                  setFieldValue("postal", values.postal.replaceAll(/\W/g, ""))
                }
                onChange={handleChange("postal")}
              />
              {errors.postal && (
                <FormHelperText>{errors.postal}</FormHelperText>
              )}
            </Grid>
            <GridDivider />
            <Grid item xs={12}>
              <AddressBookField
                name="business_name"
                label="Business Name"
                value={values.business_name}
                error={!!errors.business_name}
                onChange={handleChange("business_name")}
              />
              {errors.business_name && (
                <FormHelperText>{errors.business_name}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <AddressBookField
                required
                name="first_name"
                label="First Name"
                value={values.first_name}
                error={!!errors.first_name}
                onChange={handleChange("first_name")}
              />
              {errors.first_name && (
                <FormHelperText>{errors.first_name}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <AddressBookField
                required
                name="last_name"
                label="Last Name"
                value={values.last_name}
                error={!!errors.last_name}
                onChange={handleChange("last_name")}
              />
              {errors.last_name && (
                <FormHelperText>{errors.last_name}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <AddressBookField
                name="email"
                label="Email"
                value={values.email}
                error={!!errors.email}
                onChange={handleChange("email")}
              />
              {errors.email && <FormHelperText>{errors.email}</FormHelperText>}
            </Grid>
            <Grid item xs={12} md={6}>
              <AddressBookField
                name="phone"
                label="Phone Number"
                value={values.phone}
                error={!!errors.phone}
                onChange={handleChange("phone")}
              />
              {errors.phone && <FormHelperText>{errors.phone}</FormHelperText>}
            </Grid>
            <GridDivider />
            <Grid item xs={12}>
              <AddressBookField
                name="location_note"
                label="Location Note"
                value={values.location_note}
                error={!!errors.location_note}
                multiline
                onChange={handleChange("location_note")}
              />
              {errors.location_note && (
                <FormHelperText>{errors.location_note}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <MuiPickersUtilsProvider utils={DayjsUtils}>
                <TimePicker
                  fullWidth
                  label="Open Time"
                  value={
                    values.open_time === ""
                      ? null
                      : dayjs(values.open_time, "HH:mm")
                  }
                  initialFocusedDate={dayjs(to24hr(MIN_OPEN), "HH:mm")}
                  clearable
                  onChange={(time) => {
                    setFieldValue("open_time", time?.format("HH:mm") ?? "");
                  }}
                  error={!!errors.open_time}
                />
              </MuiPickersUtilsProvider>
              {errors.open_time && (
                <FormHelperText>{errors.open_time}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <MuiPickersUtilsProvider utils={DayjsUtils}>
                <TimePicker
                  fullWidth
                  label="Close Time"
                  value={
                    values.close_time === ""
                      ? null
                      : dayjs(values.close_time, "HH:mm")
                  }
                  initialFocusedDate={dayjs(to24hr(MAX_CLOSE), "HH:mm")}
                  clearable
                  onChange={(time) => {
                    setFieldValue("close_time", time?.format("HH:mm") ?? "");
                  }}
                  error={!!errors.close_time}
                />
              </MuiPickersUtilsProvider>
              {errors.close_time && (
                <FormHelperText>{errors.close_time}</FormHelperText>
              )}
            </Grid>
            {!props?.address?.id &&
              <>
                <GridDivider />
                <Grid item xs={12} md={12} style={{ textAlign: "right" }}>
                  <FormControlLabel
                    label="Save to Address Book"
                    control={
                      <Switch
                        checked={values.createAddressBookEntry}
                        onChange={(ev) =>
                          setFieldValue("createAddressBookEntry", ev.target.checked)
                        }
                      />
                    }
                  />
                </Grid>
              </>
            }
            <Grid item xs={12}>
              <div className={classes.formButtons}>
                <Button
                  disabled={submitting}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    // This allows the initial validation to occur when the form is submitted and
                    // _then_ start validation onChange as the form is corrected from that point.
                    setHasAttemptedSubmit(true);
                    handleSubmit();
                  }}
                >
                  {submitting && (
                    <CircularProgress
                      size={16}
                      style={{ marginRight: 12 }}
                      color="inherit"
                    />
                  )}
                  Save
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

const AddressConfirmation = (props: {
  originalAddress: Address;
  verifiedAddress: Address;
  onSelectAddress: (selectedAddress: Address) => void;
  onReturnToEditing?: () => void;
}) => {
  const [loading, setLoading] = useState(false);

  function handleOnSelectAddress(selectedAddress: Address) {
    setLoading(true);
    props.onSelectAddress(selectedAddress);
  }

  return (
    <div>
      <Typography style={{ fontSize: 16, marginBottom: 16 }}>
        The address provided was adjusted upon verification.
        <br />
        Please choose an address to proceed or return to edit the address.
      </Typography>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 60,
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <AddressCard
            address={props.verifiedAddress}
            type="verified"
            onSelectAddress={handleOnSelectAddress}
          />
          <AddressCard
            address={props.originalAddress}
            type="original"
            onSelectAddress={handleOnSelectAddress}
          />
          <Button
            onClick={props.onReturnToEditing}
            style={{
              backgroundColor: "transparent",
              color: "black",
              margin: "16px 0 0 0",
              padding: 0,
            }}
          >
            <ArrowBackIos /> Return to editing address
          </Button>
        </>
      )}
    </div>
  );
};

const AddressCard = ({
  address,
  type,
  onSelectAddress,
}: {
  address: Address;
  type: "original" | "verified";
  onSelectAddress?: (selectedAddress: Address) => void;
}) => {
  const styles = useMemo(() => {
    const base = {
      marginBottom: 12,
      minWidth: 320,
    };

    if (type === "verified") {
      return { ...base, borderTop: "5px solid green" };
    } else {
      return base;
    }
  }, [type]);

  const title = useMemo(
    () => (type === "verified" ? "Suggested" : "Original"),
    [type]
  );

  return (
    <Card style={styles}>
      <CardContent>
        <Typography variant="h3" style={{ fontSize: 18, marginBottom: 18 }}>
          {title}
        </Typography>
        <Typography>{fullFormattedAddress(address)}</Typography>
      </CardContent>
      <CardActions
        style={{
          justifyContent: "center",
          padding: 10,
          borderTop: "1px solid lightGrey",
        }}
      >
        <Button
          size="small"
          color="primary"
          onClick={() => onSelectAddress?.(address)}
        >
          Use this address
        </Button>
      </CardActions>
    </Card>
  );
};

const AddressBookField = (props: TextFieldProps) => {
  return (
    <FormControl fullWidth>
      <TextField
        name={props.name}
        id={`id_${props.name}`}
        margin="dense"
        label={props.label}
        type="text"
        fullWidth
        multiline={props.multiline}
        value={props.value}
        error={!!props.error}
        onChange={props.onChange}
        onBlur={props.onBlur}
        {...props}
      />
    </FormControl>
  );
};

function ErrorSnackBar(props: SnackbarProps) {
  return (
    <SnackBar
      variant="error"
      duration="5000"
      open={!!props.message}
      message={props.message}
      handleClose={props.onClose}
    />
  );
}

const GridDivider = () => (
  <Grid item xs={12}>
    <Divider />
  </Grid>
);

export default withStyles(styles)(ReceiverForm);
