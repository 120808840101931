/* eslint-disable react/no-multi-comp */

import { Divider } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import blue from "@material-ui/core/colors/blue";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import $ from "jquery";
import React from "react";

const styles = {
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
};

class ChildDialog extends React.Component {
  state = {
    open: false,
    postal: "",
  };

  handleListItemClick = (value) => {
    this.props.onClose(value);
  };

  handleChange = (name) => (event) => {
    let tempString = event.target.value;
    this.setState({ [name]: tempString.toUpperCase() });
  };

  getFileData = (e) => {
    if (e.target.files && e.target.files[0]) {
      var FR = new FileReader();

      this.setState({ isLoading: true });

      FR.addEventListener("load", async (e) => {
        var temp = e.target.result.replace(/^data*.*base64,/gm, "");
        temp = atob(temp);
        const csv = require("csvtojson");
        csv()
          .fromString(temp)
          .then((csvRow) => {
            this.props.startUpload(csvRow);
            // this.setState({data: csvRow}) // => [["1","2","3"], ["4","5","6"], ["7","8","9"]]
          });
      });
      FR.readAsDataURL(e.target.files[0]);
    }
  };

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={() => {
            this.setState({ postal: "" });
            this.props.handleClose();
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent style={{ padding: "20px 50px" }}>
            <br />
            <small>
              <strong>Create one account</strong>
            </small>

            {/* <TextField
                id={new uuid.v4()}
                label="Pos&zwnj;tal Co&zwnj;de"
                margin="dense"
                error={this.props.zipError}
                fullWidth
                autoFocus
                onChange={this.handleChange('postal')}
                variant="outlined"
                value={this.state.postal}
                style={{marginTop:15,marginBottom:10}}
                helperText={this.props.zipError ? 'Postal code not found.' : ''}
                /> */}
            <br />
            <br />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={this.props.singleAccount}
            >
              Create one account
            </Button>
            <br />
            <br />
            <br />
            <Divider />

            <br />
            <br />
            <small>
              <strong>Create multiple accounts</strong>
            </small>
            <br />
            <input
              style={{ zIndex: "-2", opacity: "0" }}
              accept=".csv"
              id="contained-button-file"
              type="file"
              onChange={(e) => {
                this.getFileData(e);
              }}
            />
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={() => $("input[type=file]").trigger("click")}
            >
              Import multiple accounts
            </Button>
            <br />
            <br />
            <small>
              <strong>
                <center>
                  <a
                    href="https://images.swiftpost.com/csv/Swiftpost+Child+Account+Template.csv"
                    target="_blank"
                  >
                    Download CSV Template
                  </a>
                </center>
              </strong>
            </small>
            <br />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default ChildDialog;
