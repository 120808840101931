import { Chip } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import Grow from "@material-ui/core/Grow";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import Slide from "@material-ui/core/Slide";
import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { ArrowDropDown, QuestionAnswerRounded } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { GrDocumentPdf } from "react-icons/gr";
import ShipmentMenu from "../components/ShipmentMenu";

const styles = {
  appBar: {
    position: "relative",
    background: "var(--brand-cyan)",
  },
  flex: {
    flex: 1,
  },
};

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class FullScreenDialog extends React.Component {
  state = {
    open: false,
    shipment: {},
    dropdown: false,
  };

  UNSAFE_componentWillMount = async () => {
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  language = {
    OrderID: {
      FR: "Numéro de commande",
      EN: "Order ID",
    },
    Status: {
      FR: "Statut",
      EN: "Status",
    },
    Business: {
      FR: "Nom de l'entreprise",
      EN: "Business",
    },
    ReceiverName: {
      FR: "Nom du destinataire",
      EN: "Receiver Name",
    },
    EmailAddress: {
      FR: "Adresse électronique",
      EN: "Email Address",
    },
    PhoneNumber: {
      FR: "Numéro de téléphone",
      EN: "Phone Number",
    },
    ReceiverAddress: {
      FR: "Adresse du destinataire",
      EN: "Receiver Address",
    },
    SenderName: {
      FR: "Nom de l'expéditeur",
      EN: "Sender Name",
    },
    SenderAddress: {
      FR: "Adresse de l'expéditeur",
      EN: "Sender Address",
    },
    Tracking: {
      FR: "Numéro de suivi",
      EN: "Tracking",
    },
    Label: {
      FR: "Étiquette",
      EN: "Label",
    },
    Weight: {
      FR: "Poids",
      EN: "Weight",
    },
    lb: {
      FR: "livre",
      EN: "lb",
    },
    Length: {
      FR: "Longueur",
      EN: "Length",
    },
    in: {
      FR: "pouce",
      EN: "in",
    },
    Width: {
      FR: "Largeur",
      EN: "Width",
    },
    Height: {
      FR: "Taille",
      EN: "Height",
    },
    MeasuredPackageDetails: {
      FR: "Détails de l'emballage mesuré",
      EN: "Measured Package Details",
    },
    EnteredPackageDetails: {
      FR: "Détails du package saisi",
      EN: "Entered Package Details",
    },
  };

  openChat = async (shipment) => {
    window.openChat();
  };


  getStatusColor = (code) => {
    switch (code) {
      case "NR":
        return "#e65100";
      case "PU":
        return "#4FC3F7";
      case "IN":
        return "#4FC3F7";
      case "SH":
        return "#4FC3F7";
      case "SP":
        return "#4FC3F7";
      case "TR":
        return "#4FC3F7";
      case "EX":
        return "#C70039";
      case "DEL":
        return "#52BE80";
      case "HFPU":
        return "#4FC3F7";
      case "DP":
        return "#4FC3F7";
      case "RTS":
        return "#C70039";
      default:
        return "#4FC3F7";
    }
  };

  handleToggle = () => {
    this.setState({ dropdown: !this.state.dropdown });
  };

  render() {
    const { classes } = this.props;
    console.log(this.props.shipment);
    return (
      this.props.shipment != undefined && (
        <div>
          <Dialog
            fullScreen
            open={this.props.open}
            onClose={this.props.handleClose}
            TransitionComponent={Transition}
          >
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton
                  color="inherit"
                  onClick={this.props.handleClose}
                  aria-label="Close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography
                  variant="h6"
                  color="inherit"
                  className={classes.flex}
                >
                  {this.language.OrderID[this.state.language]}:{" "}
                  {this.props.shipment.order_id}
                </Typography>
                {this.props.shipment.dropoff_print_requested === "true" ? (
                  <ShipmentMenu
                    account={this.props.account}
                    rate_id={this.props.shipment.rate_id}
                    image_url={this.props.shipment.dropoff_reprint_url}
                  />
                ) : (
                  ""
                )}
                <div>
                  <Button
                    buttonRef={(node) => {
                      this.anchorEl = node;
                    }}
                    aria-owns={
                      this.state.dropdown ? "menu-list-grow" : undefined
                    }
                    aria-haspopup="true"
                    onClick={this.handleToggle}
                    variant="contained"
                    size="small"
                  >
                    Actions <ArrowDropDown />
                  </Button>
                  <Popper
                    open={this.state.dropdown}
                    anchorEl={this.anchorEl}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        id="menu-list-grow"
                        style={{
                          transformOrigin:
                            placement === "bottom"
                              ? "center top"
                              : "center bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={this.handleToggle}>
                            <MenuList>
                              <MenuItem
                                className={classes.menuItem}
                                onClick={() => {
                                  this.openChat(this.props.shipment);
                                  this.handleToggle();
                                }}
                              >
                                <ListItemIcon className={classes.icon}>
                                  <QuestionAnswerRounded
                                    style={{ color: "#000" }}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  classes={{ primary: classes.primary }}
                                  inset
                                  primary="Open Chat"
                                />
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </div>
              </Toolbar>
            </AppBar>
            <List>
              <ListItem>
                <ListItemText
                  primary={this.language.Status[this.state.language]}
                  secondary={
                    <Fragment>
                      <strong>{this.props.status}</strong>
                  
                    {this.props.events?.length ?
                      <Fragment>
                      {this.props.events.map(event => <div style={{display:'flex',alignItems:'center',marginBottom:10, marginTop:20}}><div style={{width:8,height:8,borderRadius:'50%',background:this.getStatusColor(event.code),marginRight:10}}></div><div>{event.description}<br/><div style={{fontSize:".7em", fontWeight:900, lineHeight:1, opacity:.4}}>{event.date}</div></div></div>)}
                    </Fragment>: <></>}
                    </Fragment>
                  }
                />
              </ListItem>
              <Divider />
              {this.props.shipment.requirements.length ? (
                <>
                  <ListItem>
                    <ListItemText
                      primary={`Proof of Delivery`}
                      secondary={
                        <Fragment>
                          <strong>
                            {this.props.shipment.requirements.map(
                              (requirement) => (
                                <img
                                  style={{ borderRadius: 5, maxHeight: 150 }}
                                  src={requirement.value}
                                />
                              )
                            )}
                          </strong>
                        </Fragment>
                      }
                    />
                  </ListItem>
                  <Divider />
                </>
              ) : (
                <></>
              )}
              {this.props.shipment.tracking ? (
                <a
                  target="_blank"
                  href={`https://www.swiftpost.com/tracking?t=${this.props.shipment.tracking}`}
                  rel="noreferrer"
                >
                  <ListItem button>
                    <ListItemText
                      primary={this.language.Tracking[this.state.language]}
                      secondary={
                        <Fragment>
                          <img
                            src={`https://s3.amazonaws.com/swiftpost-img/logo-${this.props.shipment.service_courier.toUpperCase()}.png`}
                            style={{ height: "10px", marginRight: "10px" }}
                          />
                          {this.props.shipment.tracking}
                        </Fragment>
                      }
                    />
                  </ListItem>
                </a>
              ) : (
                ""
              )}
              {this.props.shipment.image_url ? (
                <Fragment>
                  <a
                    target="_blank"
                    href={this.props.shipment.image_url}
                    rel="noreferrer"
                  >
                    <ListItem button>
                      <ListItemText
                        primary={this.language.Label[this.state.language]}
                        secondary={
                          <Fragment>
                            <img
                              src={this.props.shipment.image_url}
                              style={{ height: "15px", marginRight: "10px" }}
                            />
                            Click to view
                          </Fragment>
                        }
                      />
                    </ListItem>
                  </a>
                  <Divider />
                </Fragment>
              ) : (
                ""
              )}
              {this.props.shipment.bol_url ? (
                <Fragment>
                  <a
                    target="_blank"
                    href={this.props.shipment.bol_url}
                    rel="noreferrer"
                  >
                    <ListItem button>
                      <ListItemText
                        primary={`Bill of Lading`}
                        secondary={
                          <Fragment>
                            <GrDocumentPdf />
                            &nbsp;&nbsp; Click to view
                          </Fragment>
                        }
                      />
                    </ListItem>
                  </a>
                  <Divider />
                </Fragment>
              ) : (
                ""
              )}
              {this.props.shipment.receiver_business ? (
                <ListItem>
                  <ListItemText
                    primary={this.language.Business[this.state.language]}
                    secondary={this.props.shipment.receiver_business}
                  />
                </ListItem>
              ) : (
                ""
              )}
              {this.props.shipment.receiver_fname &&
              this.props.shipment.receiver_lname ? (
                <ListItem>
                  <ListItemText
                    primary={this.language.ReceiverName[this.state.language]}
                    secondary={
                      <Fragment>
                        {this.props.shipment.receiver_fname}{" "}
                        {this.props.shipment.receiver_lname}
                      </Fragment>
                    }
                  />
                </ListItem>
              ) : (
                ""
              )}
              {this.props.shipment.receiver_email ? (
                <ListItem
                  button
                  onClick={() =>
                    this.copyToClipboard(this.props.shipment.receiver_email)
                  }
                >
                  <ListItemText
                    primary={this.language.EmailAddress[this.state.language]}
                    secondary={this.props.shipment.receiver_email}
                  />
                </ListItem>
              ) : (
                ""
              )}
              {this.props.shipment.receiver_phone ? (
                <ListItem>
                  <ListItemText
                    primary={this.language.PhoneNumber[this.state.language]}
                    secondary={this.props.shipment.receiver_phone}
                  />
                </ListItem>
              ) : (
                ""
              )}
              {this.props.shipment.receiver_address ? (
                <ListItem>
                  <ListItemText
                    primary={this.language.ReceiverAddress[this.state.language]}
                    secondary={
                      <Fragment>
                        {this.props.shipment.receiver_unit
                          ? `${this.props.shipment.receiver_unit} - `
                          : ""}
                        {this.props.shipment.receiver_address},{" "}
                        {this.props.shipment.receiver_city},{" "}
                        {this.props.shipment.receiver_state}{" "}
                        {this.props.shipment.receiver_postal}{" "}
                        {this.props.shipment.receiver_country}
                      </Fragment>
                    }
                  />
                </ListItem>
              ) : (
                ""
              )}
              <Divider />
              {this.props.shipment.sender_business ? (
                <ListItem>
                  <ListItemText
                    primary={this.language.Business[this.state.language]}
                    secondary={this.props.shipment.sender_business}
                  />
                </ListItem>
              ) : (
                ""
              )}
              {this.props.shipment.sender_fname &&
              this.props.shipment.sender_lname ? (
                <ListItem>
                  <ListItemText
                    primary={this.language.SenderName[this.state.language]}
                    secondary={
                      <Fragment>
                        {this.props.shipment.sender_fname}{" "}
                        {this.props.shipment.sender_lname}
                      </Fragment>
                    }
                  />
                </ListItem>
              ) : (
                ""
              )}
              {this.props.shipment.sender_email ? (
                <ListItem
                  button
                  onClick={() =>
                    this.copyToClipboard(this.props.shipment.sender_email)
                  }
                >
                  <ListItemText
                    primary={this.language.EmailAddress[this.state.language]}
                    secondary={this.props.shipment.sender_email}
                  />
                </ListItem>
              ) : (
                ""
              )}
              {this.props.shipment.sender_phone ? (
                <ListItem>
                  <ListItemText
                    primary={this.language.PhoneNumber[this.state.language]}
                    secondary={this.props.shipment.sender_phone}
                  />
                </ListItem>
              ) : (
                ""
              )}
              {this.props.shipment.sender_address ? (
                <ListItem>
                  <ListItemText
                    primary={this.language.SenderAddress[this.state.language]}
                    secondary={
                      <Fragment>
                        {this.props.shipment.sender_unit
                          ? `${this.props.shipment.sender_unit} - `
                          : ""}
                        {this.props.shipment.sender_address},{" "}
                        {this.props.shipment.sender_city},{" "}
                        {this.props.shipment.sender_state}{" "}
                        {this.props.shipment.sender_postal}{" "}
                        {this.props.shipment.sender_country}
                      </Fragment>
                    }
                  />
                </ListItem>
              ) : (
                ""
              )}
              <Divider />
              <ListItem>
                <ListItemText
                  primary={
                    this.language.EnteredPackageDetails[this.state.language]
                  }
                  secondary={
                    <Fragment>
                      {this.language.Weight[this.state.language]}:{" "}
                      {Number(this.props.shipment.package_weight).toFixed(2)}{" "}
                      {this.language.lb[this.state.language]}
                      <br />
                      {this.language.Length[this.state.language]}:{" "}
                      {Number(this.props.shipment.package_length).toFixed(2)}{" "}
                      {this.language.in[this.state.language]}
                      <br />
                      {this.language.Width[this.state.language]}:{" "}
                      {Number(this.props.shipment.package_width).toFixed(2)}{" "}
                      {this.language.in[this.state.language]}
                      <br />
                      {this.language.Height[this.state.language]}:{" "}
                      {Number(this.props.shipment.package_height).toFixed(2)}{" "}
                      {this.language.in[this.state.language]}
                      <br />
                    </Fragment>
                  }
                />
              </ListItem>
              {this.props.shipment.package_weight_measured ? (
                <ListItem>
                  <ListItemText
                    primary={
                      this.language.MeasuredPackageDetails[this.state.language]
                    }
                    secondary={
                      <Fragment>
                        {this.language.Weight[this.state.language]}:{" "}
                        {Number(
                          this.props.shipment.package_weight_measured
                        ).toFixed(2)}{" "}
                        {this.language.lb[this.state.language]}
                        <br />
                        {this.language.Length[this.state.language]}:{" "}
                        {Number(
                          this.props.shipment.package_length_measured
                        ).toFixed(2)}{" "}
                        {this.language.in[this.state.language]}
                        <br />
                        {this.language.Width[this.state.language]}:{" "}
                        {Number(
                          this.props.shipment.package_width_measured
                        ).toFixed(2)}{" "}
                        {this.language.in[this.state.language]}
                        <br />
                        {this.language.Height[this.state.language]}:{" "}
                        {Number(
                          this.props.shipment.package_height_measured
                        ).toFixed(2)}{" "}
                        {this.language.in[this.state.language]}
                        <br />
                      </Fragment>
                    }
                  />
                </ListItem>
              ) : (
                ""
              )}
            </List>
          </Dialog>
        </div>
      )
    );
  }
}

FullScreenDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FullScreenDialog);
