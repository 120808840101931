import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import StarIcon from "@material-ui/icons/Star";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { FiPackage } from "react-icons/fi";
import { round } from "../helpers/TaskRunners";

const styles = (theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  chip: {
    backgroundColor: "#e65100",
    color: "#fff",
  },
  avatar: {
    backgroundColor: "rgb(161, 56, 0)",
  },
  button: {
    transition: ".3s all ease",
    boxShadow: "0 3px 5px 2px rgba(255, 255, 255, 0)",
    borderRadius: 3,
    border: 0,
    minHeight: 100,
    maxWidth: "500px",
    padding: "0 30px",
    borderColor: "rgba(0,0,0,.1)",
    border: "1px solid",
    margin: "0 0 10px 0",
  },
  activeButton: {
    paddingLeft: "55px",
    transition: ".3s all ease",
    background: "linear-gradient(45deg, var(--brand-cyan) 30%, #21CBF3 90%)",
    boxShadow: "0 3px 5px 2px rgba(33, 203, 243, .3)",
    borderRadius: 3,
    border: 0,
    color: "white",
    minHeight: 100,
    padding: "0 30px",
    maxWidth: "550px",
    borderColor: "rgba(33,150,243,1)",
    border: "1px solid",
    margin: "0 0 10px 0",
  },
  rate: {
    fontSize: "1.4em",
  },
  activeRate: {
    fontSize: "1.4em",
    color: "white",
  },
  whiteText: {
    color: "white",
  },
});

class RateCard extends React.Component {
  state = {
    expanded: false,
  };

  UNSAFE_componentWillMount = async () => {
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });
  };

  language = {
    EstDelivery: {
      FR: "Livraison",
      EN: "Est Delivery",
    },
    Continue: {
      FR: "Continuer",
      EN: "Continue",
    },
    day: {
      FR: "journée",
      EN: "day",
    },
    days: {
      FR: "journées",
      EN: "days",
    },
    Recommended: {
      FR: "Conseillé",
      EN: "Recommended",
    },
  };

  render() {
    const { classes } = this.props;
    let rate = this.props.data.rate;
    rate = parseFloat(rate).toFixed(2);
    return (
      <Fragment>
        <ListItem
          onClick={() => {
            this.props.setData("selectedService", this.props.number);
          }}
          button
          className={
            this.props.selectedService === this.props.number
              ? classes.activeButton
              : classes.button
          }
          style={{ marginTop: 0 }}
        >
          <div
            className={
              this.props.selectedService === this.props.number
                ? classes.activeRate
                : classes.rate
            }
          >
            ${round(rate)}
          </div>
          <ListItemText
            primary={
              <span
                className={
                  this.props.selectedService === this.props.number
                    ? classes.whiteText
                    : ""
                }
              >
                {this.props.data.service.service.name}
                {this.props.data.package_type ? (
                  <>
                    <br />
                    <small
                      style={{
                        fontSize: ".86em",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                      }}
                    >
                      <FiPackage />
                      &nbsp;
                      {this.props.data.package_type
                        ? `${this.props.data.package_type}`
                        : ""}
                    </small>
                  </>
                ) : (
                  ""
                )}
              </span>
            }
            secondary={
              <span
                className={
                  this.props.selectedService === this.props.number
                    ? classes.whiteText
                    : ""
                }
              >
                {this.language.EstDelivery[this.state.language]}:{" "}
                {this.props.data.service.service.delivery_override
                  ? `${this.props.data.service.service.delivery_override} ${
                      this.language.day[this.state.language]
                    }${
                      this.props.data.service.service.delivery_override > 1
                        ? "s"
                        : ""
                    }`
                  : `${
                      this.props.data.service.service.delivery_min
                        ? `${this.props.data.service.service.delivery_min}-`
                        : ``
                    }${
                      Number(this.props.data.service.service.delivery_ground) +
                      1
                    } ${this.language.days[this.state.language]}`}{" "}
                {this.props.number === 0 && !this.props.alone && (
                  <Chip
                    className={classes.chip}
                    color="secondary"
                    style={{
                      height: 20,
                      verticalAlign: "top",
                      marginLeft: 5,
                    }}
                    avatar={
                      <Avatar
                        className={classes.avatar}
                        style={{ height: 20, width: 20 }}
                      >
                        <StarIcon style={{ height: 13, color: "orange" }} />
                      </Avatar>
                    }
                    label={
                      <span
                        style={{
                          fontSize: "10px",
                          textTransform: "uppercase",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        {this.language.Recommended[this.state.language]}
                      </span>
                    }
                  />
                )}
              </span>
            }
          />
        </ListItem>
      </Fragment>
    );
  }
}

RateCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RateCard);
