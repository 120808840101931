import { API, Auth } from "aws-amplify";

const createToken = (payment, account) =>
  new Promise(async (resolve, reject) => {
    // console.log(payment)
    let body = {
      email: payment.email,
      pan: payment.pan,
      exp: payment.exp,
      cust_id: payment.cust_id,
      street_num: payment.street_num,
      stret_name: payment.street_name,
      zipcode: payment.postal,
    };

    // let body = {
    //   email: 'test@test.com',
    //   pan: '5555555555554444',
    //   exp: '2007',
    //   cust_id: 'test-123-567',
    //   note: 'This is a test',
    //   street_num: '12',
    //   stret_name: 'Test Street',
    //   zipcode: '14240',
    // }
    // console.log("PAYMENT CHECK => ",body)
    API.post("sqs", "/moneris/token", {
      body,
    })
      .then((e) => {
        // console.log("PASS=>",e)
        //   this.setState({token: e.token})
        resolve(e);
      })
      .catch((e) => {
        // console.log("FAIL=>",e);
        reject(false);
      });
  });

export default createToken;
