/* eslint-disable react/no-multi-comp */

import Avatar from "@material-ui/core/Avatar";
import blue from "@material-ui/core/colors/blue";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { ContactMailOutlined, PeopleOutline, Reply } from "@material-ui/icons";
import NewShipmentIcon from "@material-ui/icons/AddCircle";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import CANFlag from "../img/canflag.jpg";
import USAFlag from "../img/usaflag.jpg";
import FREIGHT_TRUCK from "../img/freight_truck.png";
import PARCEL_BOX from "../img/parcel_box.png";

const styles = {
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
};


class NewShipment extends React.Component {
  state = {
    open: false,
  };

  UNSAFE_componentWillMount = () => {
    this.props.handleMobile
      ? this.setState({ mobile: true })
      : this.setState({ mobile: false });
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });
  };

  language = {
    NewShipment: {
      FR: "Nouvel Envoi",
      EN: "New Shipment",
    },
  };

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = (value) => {
    this.setState({ open: false });
    if (this.state.mobile) {
      this.props.handleMobile();
    }
  };

  render() {
    return (
      <div>
        <Tooltip
          title={this.language.NewShipment[this.state.language]}
          placement="right"
        >
          <Link to="/new/ca">
          <ListItem button onClick={this.handleClickOpen}>
            <ListItemIcon className="navicon">
              <NewShipmentIcon />
            </ListItemIcon>
            <ListItemText
              primary={this.language.NewShipment[this.state.language]}
            />
          </ListItem>
          </Link>
        </Tooltip>
      </div>
    );
  }
}

export default NewShipment;
