import { Button, Hidden, Theme, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Add, UnarchiveOutlined } from "@material-ui/icons";
import { AddressBookRecord } from "@shared";
import PagePaper from "pages/PagePaper";
import PropTypes from "prop-types";
import { useState } from "react";
import { Link } from "react-router-dom";
import "../../styles.css";
import AddressBookTable from "./AddressBookTable";
import CreateAddressDialog from "./CreateAddressDialog";
import EditAddressDialog from "./EditAddressDialog";
import { SearchAddressesInput } from "./SearchAddresssesInput";

const styles = (theme: Theme) => ({
  topActions: {
    display: "flex",
    justifyContent: "space-between",
    margin: "1em 0 2em",
  },
});

export function AddressBook(props: { classes: any; handleLogout: () => void }) {
  const { classes, handleLogout } = props;
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [editingAddress, setEditingAddress] = useState<AddressBookRecord>();

  return (
    <div className="Home">
      <Typography variant="h1">Address Book</Typography>
      <PagePaper>
        <Hidden mdUp>
          <CreateAddressButton
            onClick={() => setCreateDialogOpen(true)}
            style={{ margin: "0 0 1em", width: "100%" }}
          />
          <SearchAddressesInput
            onChange={setSearchTerm}
            style={{ margin: "1em 0 2em", width: "100%" }}
          />
        </Hidden>
        <Hidden smDown>
          <div className={classes.topActions}>
            <div>
              <CreateAddressButton
                onClick={() => setCreateDialogOpen(true)}
                style={{ marginRight: 16 }}
              />
              <ImportCSVButton />
            </div>
            <SearchAddressesInput onChange={setSearchTerm} />
          </div>
        </Hidden>
        <AddressBookTable
          searchTerm={searchTerm}
          onRowClicked={setEditingAddress}
          handleLogout={handleLogout}
        />
      </PagePaper>
      <CreateAddressDialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
      />
      {editingAddress && (
        <EditAddressDialog
          address={editingAddress}
          open={true}
          onClose={() => setEditingAddress(undefined)}
        />
      )}
    </div>
  );
}

const ImportCSVButton = (props: any) => (
  <Link to="/addressbook/import">
    <Button variant="contained" color="primary" {...props}>
      <UnarchiveOutlined style={{ fontSize: "1.2em", marginRight: 6 }} />
      Import CSV
    </Button>
  </Link>
);

const CreateAddressButton = (props: any) => (
  <Button variant="contained" color="primary" {...props}>
    <Add style={{ fontSize: "1.2em", marginRight: 6 }} />
    Create Address
  </Button>
);

AddressBook.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddressBook);
