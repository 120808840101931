import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { PictureAsPdfOutlined } from "@material-ui/icons";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import PropTypes from "prop-types";
import React from "react";
import listInvoices from "../api/moneris/listInvoices";
import { round } from "../helpers/TaskRunners";

const actionsStyles = (theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
  },
  button: {
    margin: theme.spacing.unit,
  },
});

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = (event) => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = (event) => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, {
  withTheme: true,
})(TablePaginationActions);

let counter = 0;
function createData(date_created, amount, invoice_id, url) {
  counter += 1;
  return { id: counter, date_created, amount, invoice_id, url };
}

const styles = (theme) => ({
  root: {
    width: "100%",
    maxWidth: "500px",
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    // minWidth: 300,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  margin: {
    fontSize: "1.5em",
    padding: "5px",
  },
});

class CustomPaginationActionsTable extends React.Component {
  state = {
    rows: [],
    page: 0,
    rowsPerPage: 5,
    loading: [],
  };

  UNSAFE_componentWillMount = async () => {
    const invoices = await listInvoices(this.state.page, 500);
    // console.log(invoices)
    this.setState({
      rows: invoices.response.map((e) =>
        createData(
          e.date_created,
          e.amount,
          e.invoice_id.toUpperCase(),
          e.invoice_url
        )
      ),
    });
  };

  handleChangePage = async (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = async (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { classes } = this.props;
    const { rowsPerPage, page } = this.state;
    const rows = this.state.rows;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
      <Paper
        className={classes.root}
        style={{
          overflow: "auto",
          // 'float':'left',
          width: "calc(100vw - 45px)",
        }}
      >
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <TableBody>
              <TableRow key={-1}>
                <TableCell
                  align="left"
                  style={{ padding: "4px 10px 4px 20px" }}
                >
                  {this.props.language == "FR" ? "Date" : "Date"}
                </TableCell>
                <TableCell
                  align="left"
                  style={{ padding: "4px 10px 4px 20px" }}
                >
                  {this.props.language == "FR"
                    ? "Numéro de facture"
                    : "Invoice ID"}
                </TableCell>
                <TableCell scope="row" style={{ padding: "4px 10px 4px 20px" }}>
                  {this.props.language == "FR" ? "Total" : "Total"}
                </TableCell>
                <TableCell scope="row" style={{ padding: "4px 10px 4px 20px" }}>
                  {this.props.language == "FR" ? "Voir" : "View"}
                </TableCell>
              </TableRow>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow key={row.id}>
                      <TableCell
                        align="left"
                        style={{ padding: "4px 10px 4px 20px" }}
                      >
                        {new Date(row.date_created).toLocaleDateString()}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ padding: "4px 10px 4px 20px" }}
                      >
                        <span style={{ whiteSpace: "nowrap" }}>
                          <small>{row.invoice_id}</small>
                        </span>
                      </TableCell>
                      <TableCell
                        scope="row"
                        style={{ padding: "4px 10px 4px 20px" }}
                      >
                        ${round(row.amount)}CAD
                      </TableCell>
                      <TableCell
                        scope="row"
                        style={{ padding: "4px 10px 4px 20px" }}
                      >
                        {/* { this.state.loading.indexOf(row.invoice_id) > -1 ?
                      <LoadingIcon size={15} />
                      :
                        row.url !== "" ? */}
                        <IconButton
                          onClick={() => window.open(`${row.url}`)}
                          aria-label="delete"
                          className={classes.margin}
                          size="small"
                        >
                          <PictureAsPdfOutlined fontSize="inherit" />
                        </IconButton>
                        {/* :
                        <IconButton onClick={()=>{
                          var temp = this.state.loading
                          temp.push(row.invoice_id)
                          this.setState({loading:temp});
                          API.post("sqs","/invoice/pdf",{
                            body: {
                              amount: row.amount,
                              invoice_date:new Date(row.date_created).toLocaleDateString(),
                              invoice_id:row.invoice_id,
                              account:this.props.account
                            }
                          }).then(e=>{
                            var temp = this.state.loading
                            temp = temp.filter(f=>f!=row.invoice_id)
                            this.setState({loading:temp});
                            window.open(e.url)
                        })}} aria-label="delete" className={classes.margin} size="small">
                          <PictureAsPdfOutlined fontSize="inherit" />
                        </IconButton>
                      } */}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  colSpan={2}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    native: true,
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActionsWrapped}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </Paper>
    );
  }
}

CustomPaginationActionsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomPaginationActionsTable);
