import { API, Auth } from "aws-amplify";

const db_updateAccount = async (data, callback) => {
  let email = await Auth.currentSession();
  email = email.idToken.payload.email;

  let body = data;
  body.email = email;

  // console.log(body)

  API.post("sqs", "/account/create", {
    body,
  })
    .then((e) => {
      // console.log("CHECK THIS", e)
      callback(e);
    })
    .catch((e) => {}); // console.log(e))
};

export default db_updateAccount;
