import React, { Component, Fragment } from "react";

// Externals
import classNames from "classnames";
import PropTypes from "prop-types";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material components
import { Typography } from "@material-ui/core";

// Component styles
// import styles from './styles';
const styles = (theme) => ({
  root: {
    padding: theme.spacing.unit * 3,
  },
  content: {
    alignItems: "center",
    display: "flex",
  },
  title: {
    color: theme.palette.text.secondary,
    fontWeight: 700,
  },
  value: {
    marginTop: theme.spacing.unit,
  },
  iconWrapper: {
    alignItems: "center",
    borderRadius: "50%",
    display: "inline-flex",
    height: "4rem",
    justifyContent: "center",
    marginLeft: "auto",
    width: "4rem",
  },
  icon: {
    color: "white",
    fontSize: "2rem",
    height: "2rem",
    width: "2rem",
  },
  footer: {
    marginTop: theme.spacing.unit * 2,
    display: "flex",
    alignItems: "center",
  },
  difference: {
    alignItems: "center",
    color: "red",
    display: "inline-flex",
    fontWeight: 700,
  },
  caption: {
    marginLeft: theme.spacing.unit,
  },
});
class Budget extends Component {
  render() {
    const { classes, className, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <div
        className={rootClassName}
        style={{ paddingLeft: "0", paddingTop: "0" }}
      >
        <div
          className={classes.content}
          style={{
            color: "#fff",
            padding: "10px 25px",
            background: this.props.color,
            "border-radius": "5px",
          }}
        >
          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant="body2"
              style={{ color: "rgba(255,255,255,.8)" }}
            >
              {this.props.pretitle}
            </Typography>
            <Typography
              className={classes.value}
              variant="h3"
              style={{ color: "#fff" }}
            >
              {this.props.title}
            </Typography>
          </div>
          <div
            className={classes.iconWrapper}
            style={{ backgroundColor: "#fff" }}
          >
            {this.props.icon}
          </div>
        </div>
      </div>
    );
  }
}

Budget.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Budget);
