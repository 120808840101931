import {
  Address,
  AddressBookRecord,
  CreateAddressRequest,
  CreateAddressResponse,
  ListAddressesResponse,
  UpdateAddressRequest,
  UUID,
  VerifyAddressResponse,
} from "@shared";
import { API } from "aws-amplify";

export const createAddressBookEntry = async (data: CreateAddressRequest) => {
  console.log(data);
  return (await API.post("addressbook", "/addresses", {
    body: data,
  })) as CreateAddressResponse;
};

export const deleteAddressBookEntry = (id: UUID) => {
  return API.del("addressbook", `/addresses/${id}`, {});
};

export const updateAddressBookEntry = (
  id: UUID,
  data: UpdateAddressRequest
) => {
  return API.put("addressbook", `/addresses/${id}`, { body: data });
};

export const fetchAddressBook = async () => {
  const addresses = (await API.get(
    "addressbook",
    "/addresses",
    undefined
  )) as ListAddressesResponse;
  return sortAddresses(addresses);
};

export const verifyAddress = async (address: Address): Promise<Address> => {
  const { address1, city, state, postal, country } = address;

  // -- Mock call
  // const verifiedAddress = {
  //   ...address,
  //   address1: address1 && address1.toUpperCase().replace("TILLICUM", "TUXEDO"),
  //   address2: address2 && address2.toUpperCase(),
  //   city: city && city.toUpperCase(),
  //   state: state && state.toUpperCase(),
  //   postal: postal && postal.toUpperCase(),
  //   country: country && country.toUpperCase(),
  // };

  const verifiedAddress = (await API.get("addressbook", "/verifyAddress", {
    queryStringParameters: {
      address1,
      city,
      state,
      postal,
      country,
    },
  })) as VerifyAddressResponse;

  return { ...address, ...verifiedAddress };
};

const sortAddresses = (addresses: AddressBookRecord[]) => {
  return addresses.sort((a, b) => {
    // Have to force boolean in case of comparing `false` to `undefined` or `null`
    if (!!a.favorite !== !!b.favorite) {
      return a.favorite ? -1 : +1;
    }

    const nameA = a.business_name ?? a.first_name;
    const nameB = b.business_name ?? b.first_name;

    return nameA.localeCompare(nameB);
  });
};
