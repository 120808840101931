import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { AddressBookRecord } from "@shared";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { sortImportRecords } from "../helpers";
import { RecordRow } from "./CSVImportTableRow";
import { ImportSummary } from "./ImportSummary";
import { useAddressCSVImporter } from "./useAddressCSVImporter";

export type ImportRecordState =
  | "processing"
  | "verified"
  | "duplicate"
  | "mismatch"
  | "error"
  | "imported";

export type ImportRecord = {
  refId: string;
  importState: ImportRecordState;
  importedAddress: AddressBookRecord;
  verifiedAddress?: AddressBookRecord;
  errors: Array<{ message: string }>;
};

const styles = (theme: Theme) => ({
  tableUtilityHeader: {
    display: "flex",
    padding: 16,
    background: "var(--brand-cyan)",
    justifyContent: "space-between",
  },
  tableUtilityHeaderTitle: {
    position: "relative" as const,
    color: "#fff",
  },
  tableHeaderCell: {
    padding: "4px 16px",
  },
});

export function CSVImportTable(props: { classes: any; csv: any }) {
  const { classes } = props;
  const { importRecords, processAndImportCSV } = useAddressCSVImporter();

  useEffect(() => {
    processAndImportCSV(props.csv.data);
    // wants to depend on `processAndImportCSV` but it invalidates on me in ways I can't track
    // down, leading to a second import attempt
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.csv]);

  return (
    <div>
      <ImportSummary records={importRecords} />
      <Paper
        square
        className={`${classes.paper} ${classes.tableUtilityHeader}`}
      >
        <Typography
          className={`${classes.text} ${classes.tableUtilityHeaderTitle}`}
          variant="h5"
        >
          Importing Addresses
        </Typography>
      </Paper>
      <Paper>
        <Table width="100%">
          <TableHead>
            <TableRow>
              <TableCell width="5%" style={{ padding: "0 10px" }}></TableCell>
              <TableCell width="10%" className={classes.tableHeaderCell}>
                Business Name
              </TableCell>
              <TableCell width="5%" className={classes.tableHeaderCell}>
                First Name
              </TableCell>
              <TableCell width="5%" className={classes.tableHeaderCell}>
                Last Name
              </TableCell>
              <TableCell width="15%" className={classes.tableHeaderCell}>
                Phone
              </TableCell>
              <TableCell width="15%" className={classes.tableHeaderCell}>
                Email
              </TableCell>
              <TableCell width="15%" className={classes.tableHeaderCell}>
                Address
              </TableCell>
              <TableCell width="5%" className={classes.tableHeaderCell}>
                City
              </TableCell>
              <TableCell width="5%" className={classes.tableHeaderCell}>
                State
              </TableCell>
              <TableCell width="10%" className={classes.tableHeaderCell}>
                Postal
              </TableCell>
              <TableCell width="10%" className={classes.tableHeaderCell}>
                --
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortImportRecords(importRecords).map((record) => (
              <RecordRow
                key={record.refId}
                record={record}
                className={classes.tableHeaderCell}
              />
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}

CSVImportTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CSVImportTable);
