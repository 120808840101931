/* eslint-disable react/no-multi-comp */

import React, { Fragment } from "react";
import { Auth } from "aws-amplify";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import blue from "@material-ui/core/colors/blue";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Tooltip from "@material-ui/core/Tooltip";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import MinusIcon from "@material-ui/icons/Remove";
import DialogContentText from "@material-ui/core/DialogContentText";
import Stepper from "./Stepper";
import {
  TextField,
  Typography,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import uuid from "uuid";
import CSVReader from "react-csv-reader";
import $ from "jquery";
import CSV from "./../img/spreadsheet.png";
import LocationPicker from "../components/LocationPicker";
// import { API } from "aws-amplify";
import { API, graphqlOperation } from "aws-amplify";

const styles = {
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
};

class PackingSlipModal extends React.Component {
  state = {};

  render() {
    //   console.log(this.props.shipment)
    //   console.log(this.props.flashEditData);
    return (
      <div>
        <Dialog
          open={true}
          onClose={() => {
            this.props.handleClose();
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Fragment>
            <DialogContent style={{ padding: "20px 30px" }}>
              {/* <Grid continer xs={12}> */}
              <p>
                <strong>{this.props.shipment.reference}</strong>
              </p>
              <table style={{ fontSize: ".7em" }} cellSpacing={0}>
                <tr>
                  <td
                    style={{
                      minWidth: "150px",
                      paddingRight: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    ITEM NAME
                  </td>
                  <td style={{ paddingRight: "20px", fontWeight: "bold" }}>
                    QTY
                  </td>
                  <td style={{ fontWeight: "bold" }}>PRICE</td>
                </tr>
                {this.props.shipment.packing_slip.map((e, f) => (
                  <>
                    {f % 2 == 0 ? (
                      <tr style={{ padding: "2px 4px", background: "#eee" }}>
                        <td style={{ paddingRight: "20px" }}>{e.title}</td>
                        <td style={{ paddingRight: "20px" }}>
                          {e.item_quantity}
                        </td>
                        <td>
                          $
                          {(
                            Number(e.item_value) * Number(e.item_quantity)
                          ).toFixed(2)}
                        </td>
                      </tr>
                    ) : (
                      <tr style={{ padding: "2px 4px" }}>
                        <td style={{ paddingRight: "20px" }}>{e.title}</td>
                        <td style={{ paddingRight: "20px" }}>
                          {e.item_quantity}
                        </td>
                        <td>
                          $
                          {(
                            Number(e.item_value) * Number(e.item_quantity)
                          ).toFixed(2)}
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </table>
              <br />
              {this.props.shipment.customer_note &&
              this.props.shipment.customer_note != "null" ? (
                <span style={{ fontSize: ".7em" }}>
                  Note: {this.props.shipment.customer_note}
                </span>
              ) : (
                ""
              )}
              {/* </Grid> */}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.props.handleClose();
                }}
                color="default"
              >
                Close
              </Button>
            </DialogActions>
          </Fragment>
        </Dialog>
      </div>
    );
  }
}

export default PackingSlipModal;
