import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { API, graphqlOperation } from "aws-amplify";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { isNotInPast } from "../helpers/Validation";
import amexLogo from "../img/amex-logo.png";
import discoverLogo from "../img/discover-logo.png";
import mastercardLogo from "../img/mastercard-logo.png";
import visaLogo from "../img/visa-logo.png";

const styles = (theme) => ({
  // card: {
  //   [theme.breakpoints.up('lg')]: {
  //     width: '28%',
  //   },
  //   [theme.breakpoints.down('md')]: {
  //     width: '45%',
  //   },
  //   [theme.breakpoints.down('sm')]: {
  //     width: '100%',
  //   },
  //   marginRight: 20,
  //   marginBottom: 20,
  //   float:'left',
  // },
  card: {
    position: "relative",
    fontFamily: "Abel",
    textShadow: "0 0 2px rgba(0,0,0,.8)",
    width: "239px",
    height: "150px",
    borderRadius: "7px",
    backgroundColor: "var(--brand-cyan)",
    display: "inline-block",
    marginRight: "10px",
    color: "#FFF",
    boxShadow: "3px 3px 3px #eee",
    backgroundSize: "1px 200%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    display: "inline-block",
    width: 28,
    height: 15,
    backgroundSize: "auto 100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  VISA: {
    backgroundImage: `url(${visaLogo})`,
  },
  MASTERCARD: {
    backgroundImage: `url(${mastercardLogo})`,
  },
  AMEX: {
    backgroundImage: `url(${amexLogo})`,
  },
  DISCOVER: {
    backgroundImage: `url(${discoverLogo})`,
  },
  actions: {
    display: "flex",
    float: "right",
  },
});

class RecipeReviewCard extends React.Component {
  state = {
    anchorEl: null,
    buttons: this.props.buttons === false ? false : true,
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleRemove = async (id) => {
    let payment = await API.graphql(
      graphqlOperation(`mutation {
      deleteV1_PaymentMethod(input: { id:"${id}" }){
        id
        cognitoUsername
      }
    }`)
    );
    this.handleClose();
  };

  UNSAFE_componentWillMount = () => {
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });
  };

  language = {
    Remove: {
      FR: "Retirer",
      EN: "Remove",
    },
    Expired: {
      FR: "EXPIRÉ",
      EN: "EXPIRED",
    },
  };

  render() {
    const { anchorEl } = this.state;
    const { classes } = this.props;

    return (
      <div style={{ display: "inline" }}>
        {"noPaper" in this.props ? (
          <CardHeader
            style={{ padding: 0, margin: 0 }}
            title={
              <div style={{ margin: "5px 0 10px 0", fontSize: "1.2rem" }}>
                {this.props.data.title}
              </div>
            }
            subheader={
              <div>
                {this.props.data.card.pan}{" "}
                <div
                  className={`${classes[this.props.data.card.type]} ${
                    classes.cover
                  }`}
                />
                <br />
                EXP {this.props.data.card.month}/{this.props.data.card.year}{" "}
                {!isNotInPast(
                  this.props.data.card.month,
                  this.props.data.card.year
                ) && (
                  <strong style={{ marginLeft: "10px", color: "red" }}>
                    {this.language.Expired[this.state.language]}
                  </strong>
                )}
              </div>
            }
          />
        ) : (
          <Card square className={classes.card}>
            <CardHeader
              className={classes.header}
              action={
                this.state.buttons && (
                  <Fragment>
                    <IconButton
                      aria-label="More"
                      aria-owns={anchorEl ? "long-menu" : null}
                      aria-haspopup="true"
                      onClick={this.handleClick}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={this.handleClose}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <MenuItem
                        onClick={() => this.handleRemove(this.props.data.id)}
                      >
                        {this.language.Remove[this.state.language]}
                      </MenuItem>
                    </Menu>
                  </Fragment>
                )
              }
              title={<></>}
              subheader={
                <span style={{ color: "#fff", fontSize: "14px" }}>
                  <p
                    style={{
                      width: "165px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <strong>{this.props.data.title}</strong>
                  </p>
                  <br />
                  <span style={{ color: "#FFF" }}>
                    {this.props.data.card.pan}&nbsp;&nbsp;&nbsp;
                    <div
                      className={`${classes[this.props.data.card.type]} ${
                        classes.cover
                      }`}
                      style={{ borderRadius: "3px", backgroundColor: "#FFF" }}
                    />
                    <br />
                    EXP {this.props.data.card.month}/{this.props.data.card.year}{" "}
                    {!isNotInPast(
                      this.props.data.card.month,
                      this.props.data.card.year
                    ) && (
                      <strong style={{ marginLeft: "10px", color: "red" }}>
                        {this.language.Expired[this.state.language]}
                      </strong>
                    )}
                  </span>
                </span>
              }
            />
          </Card>
        )}
      </div>
    );
  }
}

RecipeReviewCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RecipeReviewCard);
