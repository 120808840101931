import { API, graphqlOperation } from "aws-amplify";

export const listPaymentMethods = () =>
  API.graphql(
    graphqlOperation(`
      query listPaymentMethods {
        listV1_PaymentMethods {
          items {
            id
            title
            card {
              exp
              pan
              year
              month
              type
              token
            }
            cognitoUsername
          }
        }
      }
    `)
  );
