import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import React from "react";
import SnackBar from "../components/Snackbar";
import PagePaper from "./PagePaper";

// import LoadingIcon from '../components/loading/LoadingIcon';
import {
  isAlphaNumeric,
  isAlphaNumericWithSpace,
  isNumericOnly,
  isValidPhoneNumber,
  isValidPostal,
} from "../helpers/Validation";

const styles = (theme) => ({
  textField: {
    width: "100%",
    boxSizing: "border-box",
    fontSize: "1.5em",
  },
  menu: {
    width: 200,
  },
  formControl: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2,
  },
  resetContainer: {
    padding: theme.spacing.unit * 3,
  },
});

class Account extends React.Component {
  state = {
    isLoading: false,
    openSnack: false,
    location: {
      company: "",
    },
    contact: {
      fname: "",
      lname: "",
      phone: "",
      email: "",
    },
    address: {
      street_name: "",
      street_number: "",
      street_direction: "",
      postal: "",
      city: "",
      state: "",
    },
    errors: {
      business: false,
      fname: false,
      lname: false,
      phone: false,
      email: false,
      address: false,
      city: false,
      postal: false,
    },
  };

  language = {
    Account: {
      FR: "Votre Compte",
      EN: "Your Account",
    },
    BusinessName: {
      FR: "Nom du Commerce",
      EN: "Business Name",
    },
    FirstName: {
      FR: "Prénom",
      EN: "First Name",
    },
    LastName: {
      FR: "Nom de famille",
      EN: "Last Name",
    },
    Phone: {
      FR: "Numéro de téléphone",
      EN: "Phone",
    },
    Email: {
      FR: "Adresse électronique",
      EN: "Email",
    },
    StreetNumber: {
      FR: "Numéro de rue",
      EN: "Street Number",
    },
    StreetName: {
      FR: "Nom de rue",
      EN: "Street Name",
    },
    UnitApt: {
      FR: "Unité/Appartemant",
      EN: "Unit/Apt #",
    },
    City: {
      FR: "Ville",
      EN: "City",
    },
    Province: {
      FR: "Province",
      EN: "Province",
    },
    Postal: {
      FR: "Code Postale",
      EN: "Postal",
    },
    Save: {
      FR: "Enregistrer",
      EN: "Save",
    },
    Loading: {
      FR: "Attendre",
      EN: "LOADING",
    },
    SuccessfullyUpdate: {
      FR: "Compte mis à jour avec succès.",
      EN: "Successfully updated account.",
    },
  };

  backup = {};

  getCoords = (postal) => {
    return new Promise((res, rej) => {
      var request = new XMLHttpRequest();
      request.onreadystatechange = function () {
        if (request.readyState == 4 && request.status == 200) {
          if (JSON.parse(request.responseText).results.length > 0) {
            let coords = JSON.parse(request.responseText).results[0].geometry
              .viewport.northeast;
            // console.log(coords)
            // console.log({lng: JSON.parse(request.responseText).results[0].geometry.viewport.northeast.lat, lat: request.responseText.results[0].geometry.viewport.northeast.lat})
            res({ lng: coords.lng, lat: coords.lat });
          } else {
            res(false);
            // rej(true)
          }
        }
      };
      request.open(
        "GET",
        `https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyBNMRsO80tsOGxVUIJbrDKw0DuUSvFrIhc&address=${
          this.state.address.street_number
        }+${this.state.address.street_name},+${this.state.address.city},+${
          this.state.address.state
        },+${this.state.address.postal.split(" ").join("")}`
      );
      request.send();
    });
  };

  UNSAFE_componentWillMount = async () => {
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });

    let session = await Auth.currentSession();
    // console.log(await session.getIdToken().decodePayload()["cognito:groups"])
    if (this.props.account && this.props.account.location) {
      this.setState({ location: this.props.account.location });
      if (this.props.account && this.props.account.location.contact) {
        this.setState({ contact: this.props.account.location.contact });
      }
      if (this.props.account && this.props.account.location.address) {
        this.setState({ address: this.props.account.location.address });
      }
      this.backup = this.props.account;
    } else {
      let contact = {
        fname: "",
        lname: "",
        phone: "",
        email: await Auth.user.attributes.email,
      };
      this.setState({ contact });
    }
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleObjChange = (name, objName) => (event) => {
    let temp = this.state[objName];
    temp[name] = event.target.value;
    this.setState({ [objName]: temp });
  };

  setObjectValue = (name, objName, value) => {
    // console.log(name, objName, value)
    let temp = this.state[objName];
    temp[name] = value;
    // console.log(temp)
    this.setState({ [objName]: temp });
  };

  handleIsAlphaNumeric = (name, obj) => (event) =>
    isAlphaNumeric(event.target.value) || event.target.value === ""
      ? this.setObjectValue(name, obj, event.target.value)
      : "";
  handleIsAlphaNumericWithSpace = (name, obj) => (event) =>
    isAlphaNumericWithSpace(event.target.value) || event.target.value === ""
      ? this.setObjectValue(name, obj, event.target.value)
      : "";
  handleIsValidPhoneNumber = (name, obj) => (event) =>
    isValidPhoneNumber(event.target.value) || event.target.value === ""
      ? this.setObjectValue(name, obj, event.target.value)
      : "";
  handleIsValidPostal = (name, obj) => (event) =>
    isValidPostal(event.target.value) || event.target.value === ""
      ? this.setObjectValue(name, obj, event.target.value)
      : "";
  handleIsNumericOnly = (name, obj) => (event) =>
    isNumericOnly(event.target.value) || event.target.value === ""
      ? this.setObjectValue(name, obj, event.target.value)
      : "";

  reset = () => {
    // alert("RESETTING")
    // console.log(this.backup)
    this.setState({ contact: this.backup.location.contact });
    this.setState({ address: this.backup.location.address });
    this.setState({ location: this.backup.location });
    // console.log(this.state.location)
    this.clearErrors();
  };

  clearErrors = () => {
    var temp = {
      businessError: false,
      fnameError: false,
      lnameError: false,
      phoneError: false,
      emailError: false,
      addressError: false,
      cityError: false,
      postalError: false,
    };
    this.setState({ errors: temp });
  };

  validateForm = async () => {
    this.setState({ isLoading: true });
    await this.clearErrors();
    var temp = this.state.errors;
    var hasErrors = false;
    if (this.state.location.title === "") {
      temp.business = true;
      hasErrors = true;
    }
    if (this.state.contact.fname === "") {
      temp.fname = true;
      hasErrors = true;
    }
    if (this.state.contact.lname === "") {
      temp.lname = true;
      hasErrors = true;
    }
    if (
      this.state.contact.phone === "" ||
      !isValidPhoneNumber(this.state.contact.phone)
    ) {
      temp.phone = true;
      hasErrors = true;
    }
    if (this.state.address.street_name === "") {
      temp.address = true;
      hasErrors = true;
    }
    if (this.state.address.street_number === "") {
      temp.address = true;
      hasErrors = true;
    }
    if (this.state.address.city === "") {
      temp.city = true;
      hasErrors = true;
    }
    if (
      this.state.address.postal === "" ||
      !isValidPostal(this.state.address.postal)
    ) {
      temp.zip = true;
      hasErrors = true;
    }
    await this.setState({ errors: temp });
    if (
      this.state.location.title != "" &&
      this.state.contact.fname != "" &&
      this.state.contact.lname != "" &&
      this.state.contact.phone != "" &&
      isValidPhoneNumber(this.state.contact.phone) &&
      this.state.address.street_name != "" &&
      this.state.address.street_number != "" &&
      this.state.address.city != "" &&
      this.state.address.postal != "" &&
      isValidPostal(this.state.address.postal)
    ) {
      if (!hasErrors) {
        // console.log(hasErrors)
        let coords = { lat: 0, lng: 0 };
        coords = await this.getCoords(this.state.address.postal);
        // console.log(coords);
        if (coords === false) {
          this.setState({ isLoading: false });
          let errors = { zip: true, address: true, city: true, state: true };
          this.setState({ errors });
          return;
        }
        this.setState({ coords });

        if (this.props.account && this.props.account.location) {
          let account = await API.graphql(
            graphqlOperation(`mutation {
          updateV1_Account(input:{
            id:"${this.props.account.id}"
            location:{
              title:"${this.state.location.title}"
              address:{
                street_name:"${this.state.address.street_name}"
                street_number:"${this.state.address.street_number}"
                postal:"${this.state.address.postal}"
                state:"${this.state.address.state}"
                country:"CA"
                ${
                  this.state.address.unit
                    ? `unit:"${this.state.address.unit}"`
                    : ``
                }
                city:"${this.state.address.city}"
                coords_lat:"${this.state.coords.lat}"
                coords_long:"${this.state.coords.lng}"
              }
              contact: {
                fname:"${this.state.contact.fname}"
                lname:"${this.state.contact.lname}"
                email:"${this.state.contact.email}"
                phone:"${this.state.contact.phone}"
              } 
            }
          }){
            id
            cognitoUsername
          }
        }`)
          );
        } else {
          let account = await API.graphql(
            graphqlOperation(`mutation {
          createV1_Account(input:{
            location:{
              title:"${this.state.location.title}"
              address:{
                street_name:"${this.state.address.street_name}"
                street_number:"${this.state.address.street_number}"
                postal:"${this.state.address.postal}"
                state:"${this.state.address.state}"
                country:"CA"
                ${
                  this.state.address.unit
                    ? `unit:"${this.state.address.unit}"`
                    : ``
                }
                city:"${this.state.address.city}"
                coords_lat:"${this.state.coords.lat}"
                coords_long:"${this.state.coords.lng}"
              }
              contact: {
                fname:"${this.state.contact.fname}"
                lname:"${this.state.contact.lname}"
                email:"${this.state.contact.email}"
                phone:"${this.state.contact.phone}"
              } 
            }
          }){
            id
            cognitoUsername
          }
        }`)
          );
        }

        this.setState({ openSnack: true });
      }
    }
    this.setState({ isLoading: false });
  };

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes, theme } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div className="Home">
        <SnackBar
          style={{ zIndex: 999999, position: "fixed" }}
          variant={"success"}
          open={this.state.openSnack}
          message={this.language.SuccessfullyUpdate[this.state.language]}
          handleClose={() => {
            this.setState({ openSnack: false });
          }}
        />
        <div className="notes">
          <Typography variant="h1">
            {this.language.Account[this.state.language]}
          </Typography>
          {/* <Paper square className={classes.root} elevation={1}> */}
          <Grid container>
            <Grid item xs={12} md={8} lg={5} xl={4}>
            <PagePaper>
            <Grid container xs={12}>
              <TextField
                InputLabelProps={{ shrink: true }}
                disabled={
                  this.state.isLoading ||
                  (this.props.account &&
                    this.props.account.user &&
                    (this.props.account.user.isParent ||
                      this.props.account.user.isChild))
                }
                fullWidth
                required
                id="business"
                error={this.state.errors.business}
                label={this.language.BusinessName[this.state.language]}
                className={classes.textField}
                value={
                  this.state.location && this.state.location.title
                    ? this.state.location.title
                    : ""
                }
                onChange={this.handleIsAlphaNumericWithSpace(
                  "title",
                  "location"
                )}
                margin="normal"
              />
              <TextField
                InputLabelProps={{ shrink: true }}
                disabled={
                  this.state.isLoading ||
                  (this.props.account &&
                    this.props.account.user &&
                    this.props.account.user.isParent)
                }
                style={{ width: "45%" }}
                error={this.state.errors.fname}
                required
                id="fname"
                label={this.language.FirstName[this.state.language]}
                className={classes.textField}
                value={
                  this.state.contact && this.state.contact.fname
                    ? this.state.contact.fname
                    : ""
                }
                onChange={this.handleIsAlphaNumeric("fname", "contact")}
                margin="normal"
              />
              <TextField
                InputLabelProps={{ shrink: true }}
                style={{ width: "50%", marginLeft: "5%" }}
                disabled={
                  this.state.isLoading ||
                  (this.props.account &&
                    this.props.account.user &&
                    this.props.account.user.isParent)
                }
                error={this.state.errors.lname}
                required
                id="lname"
                label={this.language.LastName[this.state.language]}
                className={classes.textField}
                value={
                  this.state.contact && this.state.contact.lname
                    ? this.state.contact.lname
                    : ""
                }
                onChange={this.handleIsAlphaNumeric("lname", "contact")}
                margin="normal"
              />
              <TextField
                InputLabelProps={{ shrink: true }}
                disabled={
                  this.state.isLoading ||
                  (this.props.account &&
                    this.props.account.user &&
                    this.props.account.user.isParent)
                }
                required
                maxLength="10"
                style={{ width: "45%" }}
                id="phone"
                error={this.state.errors.phone}
                label={this.language.Phone[this.state.language]}
                className={classes.textField}
                value={
                  this.state.contact && this.state.contact.phone
                    ? this.state.contact.phone
                    : ""
                }
                onChange={this.handleIsNumericOnly("phone", "contact")}
                margin="normal"
              />
              <TextField
                InputLabelProps={{ shrink: true }}
                disabled={true}
                required
                style={{ width: "50%", marginLeft: "5%" }}
                id="email"
                label={this.language.Email[this.state.language]}
                className={classes.textField}
                value={
                  this.state.contact && this.state.contact.email
                    ? this.state.contact.email
                    : ""
                }
                margin="normal"
              />
              <TextField
                InputLabelProps={{ shrink: true }}
                disabled={
                  this.state.isLoading ||
                  (this.props.account &&
                    this.props.account.user &&
                    this.props.account.user.isParent)
                }
                maxLength="10"
                required
                style={{ width: "45%" }}
                id="street_number"
                error={this.state.errors.address}
                label={this.language.StreetNumber[this.state.language]}
                className={classes.textField}
                value={
                  this.state.address && this.state.address.street_number
                    ? this.state.address.street_number
                    : ""
                }
                onChange={this.handleIsAlphaNumericWithSpace(
                  "street_number",
                  "address"
                )}
                margin="normal"
              />
              <TextField
                InputLabelProps={{ shrink: true }}
                disabled={
                  this.state.isLoading ||
                  (this.props.account &&
                    this.props.account.user &&
                    this.props.account.user.isParent)
                }
                required
                style={{ width: "50%", marginLeft: "5%" }}
                id="street_name"
                label={this.language.StreetName[this.state.language]}
                error={this.state.errors.address}
                className={classes.textField}
                value={
                  this.state.address && this.state.address.street_name
                    ? this.state.address.street_name
                    : ""
                }
                onChange={this.handleIsAlphaNumericWithSpace(
                  "street_name",
                  "address"
                )}
                margin="normal"
              />
              {/* <TextField
          InputLabelProps={{ shrink: true }}
            fullWidth
            error={this.state.errors.address}
            required
            id="address"
            label="Address"
            className={classes.textField}
            value={`${this.state.address.street_number} ${this.state.address.street_name}`} 
            onChange={this.handleIsAlphaNumericWithSpace('street_number','address')}
            margin="normal"
          /> */}
              <TextField
                InputLabelProps={{ shrink: true }}
                disabled={
                  this.state.isLoading ||
                  (this.props.account &&
                    this.props.account.user &&
                    this.props.account.user.isParent)
                }
                fullWidth
                id="unit"
                label={this.language.UnitApt[this.state.language]}
                className={classes.textField}
                value={
                  this.state.address && this.state.address.unit
                    ? this.state.address.unit
                    : ""
                }
                onChange={this.handleIsAlphaNumeric("unit", "address")}
                margin="normal"
              />
              <TextField
                InputLabelProps={{ shrink: true }}
                disabled={
                  this.state.isLoading ||
                  (this.props.account &&
                    this.props.account.user &&
                    this.props.account.user.isParent)
                }
                fullWidth
                required
                error={this.state.errors.city}
                id="city"
                label={this.language.City[this.state.language]}
                className={classes.textField}
                value={
                  this.state.address && this.state.address.city
                    ? this.state.address.city
                    : ""
                }
                onChange={this.handleIsAlphaNumeric("city", "address")}
                margin="normal"
              />
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="age-native-simple" shrink required>
                  {this.language.Province[this.state.language]}
                </InputLabel>
                <Select
                  required
                  native
                  disabled={
                    this.state.isLoading ||
                    (this.props.account &&
                      this.props.account.user &&
                      this.props.account.user.isParent)
                  }
                  value={
                    this.state.address && this.state.address.state
                      ? this.state.address.state
                      : ""
                  }
                  onChange={this.handleObjChange("state", "address")}
                  inputProps={{
                    name: "state",
                    id: "state-native-simple",
                  }}
                >
                  <option value={"AB"}>Alberta</option>
                  <option value={"BC"}>British Columbia</option>
                  <option value={"MB"}>Manitoba</option>
                  <option value={"NB"}>New Brunswick</option>
                  <option value={"NS"}>Nova Scotia</option>
                  <option value={"NL"}>Newfoundland and Labrador</option>
                  <option value={"ON"}>Ontario</option>
                  <option value={"PE"}>Prince Edward Island</option>
                  <option value={"SK"}>Saskatchewan</option>
                  <option value={"QC"}>Quebec</option>
                </Select>
              </FormControl>
              <TextField
                maxLength="6"
                disabled={
                  this.state.isLoading ||
                  (this.props.account &&
                    this.props.account.user &&
                    this.props.account.user.isParent)
                }
                InputLabelProps={{ shrink: true }}
                fullWidth
                error={this.state.errors.zip}
                required
                id="zip"
                label={this.language.Postal[this.state.language]}
                className={classes.textField}
                value={
                  this.state.address && this.state.address.postal
                    ? this.state.address.postal
                    : ""
                }
                onChange={this.handleIsAlphaNumeric("postal", "address")}
                margin="normal"
              />
              <div>
                <br />
                {/* <Button
                        disabled={this.state.isLoading}
                        onClick={this.reset}
                        className={classes.button}
                      >
                        Reset
                      </Button> */}
                <Button
                  disabled={
                    this.state.isLoading ||
                    (this.props.account &&
                      this.props.account.user &&
                      this.props.account.user.isParent)
                  }
                  variant="contained"
                  color="primary"
                  onClick={this.validateForm}
                  className={classes.button}
                >
                  {!this.state.isLoading
                    ? this.language.Save[this.state.language]
                    : this.language.Loading[this.state.language]}
                </Button>
              </div>
            </Grid>
            </PagePaper>
            </Grid>
            </Grid>
          {/* </Paper> */}
        </div>
      </div>
    );
  }
}

Account.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Account);
