import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import Step from "@material-ui/core/Step";
import StepContent from "@material-ui/core/StepContent";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import uuid from "uuid";
import {
  isNotEmpty,
  isValidEmail,
  isValidPhoneNumber,
  isValidZipCode,
} from "../../helpers/Validation";
import AddressBookPickerDialog from "../AddressBookPickerDialog";
import LoadingIcon from "../loading/LoadingIcon";

const styles = (theme) => ({
  textField: {
    width: "100%",
    boxSizing: "border-box",
    fontSize: "1.5em",
  },
  menu: {
    width: 200,
  },
  formControl: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    padding: "10px",
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2,
  },
  resetContainer: {
    padding: theme.spacing.unit * 3,
  },
});

class VerticalLinearStepper extends React.Component {
  state = {
    activeStep: 0,
    zipError: false,
    addressError: false,
    attentionError: false,
    cityError: false,
    textmask: "",
    addressBookPickerOpen: false,
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  getSteps = () => {
    return [
      this.language.Enterzipcode[this.state.language],
      this.language.Enteraddress[this.state.language],
      this.language.Confirmdetails[this.state.language],
    ];
  };

  onFocus = (event) => {
    if (event.target.autocomplete) {
      event.target.autocomplete = "whatever";
    }
  };

  UNSAFE_componentWillMount = async () => {
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });
  };

  language = {
    Enterzipcode: {
      FR: "Entrer le code postal",
      EN: "Enter ZIP Code",
    },
    Enteraddress: {
      FR: "Entrer l'adresse",
      EN: "Enter Address",
    },
    Confirmdetails: {
      FR: "Confirmer les détails",
      EN: "Confirm Details",
    },
    ZIPCode: {
      FR: "Code postal",
      EN: "ZIP Code",
    },
    CustomersEnteredAddress: {
      FR: "Adresse saisie par le client",
      EN: "Customer's Entered Address",
    },
    Address: {
      FR: "Adresse",
      EN: "Address",
    },
    Address2: {
      FR: "Adresse 2",
      EN: "Address 2",
    },
    BusinessName: {
      FR: "Nom du Commerce",
      EN: "Business Name",
    },
    FirstName: {
      FR: "Prénom",
      EN: "First Name",
    },
    LastName: {
      FR: "Nom de famille",
      EN: "Last Name",
    },
    Phone: {
      FR: "Numéro de téléphone",
      EN: "Phone",
    },
    Email: {
      FR: "Adresse électronique",
      EN: "Email",
    },
    ReferencePO: {
      FR: "Référence ou bon de commande",
      EN: "Reference/P.O. #",
    },
    UnitApt: {
      FR: "Unité/Appartemant",
      EN: "Unit/Apt #",
    },
    City: {
      FR: "Ville",
      EN: "City",
    },
    State: {
      FR: "Etat",
      EN: "State",
    },
    Back: {
      FR: "Arrière",
      EN: "Back",
    },
    Next: {
      FR: "Suivant",
      EN: "Next",
    },
    ContinueAnyway: {
      FR: "Continuez quand même",
      EN: "Continue anyway",
    },
    Receiver: {
      FR: "Le Destinataire",
      EN: "Receiver",
    },
    Edit: {
      FR: "Éditer",
      EN: "Edit",
    },
    ConfirmAndContinue: {
      FR: "Confirmer et continuer",
      EN: "Confirm and Continue",
    },
  };

  getStepContent = (step) => {
    const { classes } = this.props;
    switch (step) {
      case 0:
        return (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.setState({ addressBookPickerOpen: true })}
            >
              Address Book
            </Button>
            <p style={{ marginTop: "2em", marginBottom: 0 }}>
              or enter ZIP code to begin
            </p>
            <div style={{ width: "7em" }}>
              <TextField
                error={this.props.zipError}
                required
                id={uuid.v4()}
                label={this.language.ZIPCode[this.state.language]}
                className={classes.textField}
                value={this.props.receiver.zip}
                inputProps={{
                  name: "zip",
                  type: "text",
                  autoComplete: "off",
                  autoCorrect: "off",
                  autoCapitalize: "none",
                  spellCheck: "false",
                }}
                onChange={this.props.handleZipChange("zip")}
                margin="normal"
              />
            </div>
          </>
        );
      case 1:
        return (
          <Fragment>
            {this.props.previousData && (
              <div
                style={{
                  background: "#ffffe0",
                  padding: "20px",
                  borderRadius: "10px",
                }}
              >
                <strong>
                  {this.language.CustomersEnteredAddress[this.state.language]}:
                </strong>
                <br />
                {this.props.previousData.address}
                <br />
                {this.props.previousData.address2}
                <br />
                {this.props.previousData.unit}
                <br />
                {this.props.previousData.city}, {this.props.previousData.state}{" "}
                {this.props.previousData.zip}
              </div>
            )}
            <TextField
              error={this.props.addressError}
              required
              id={uuid.v4()}
              label={this.language.Address[this.state.language]}
              className={classes.textField}
              value={this.props.receiver.address}
              inputProps={{
                name: "address",
                type: "text",
                autoComplete: "off",
                autoCorrect: "off",
                autoCapitalize: "none",
                spellCheck: "false",
              }}
              onChange={this.props.handleObjChange("address", "receiver")}
              margin="normal"
            />
            <input type="hidden" value="something" />
            <TextField
              inputProps={{
                type: "text",
                autoComplete: "off",
                autoCorrect: "off",
                autoCapitalize: "none",
                spellCheck: "false",
              }}
              fullWidth
              error={this.props.addressError}
              id={uuid.v4()}
              label={this.language.Address2[this.state.language]}
              className={classes.textField}
              value={this.props.receiver.address2}
              onChange={this.props.handleObjChange("address2", "receiver")}
              margin="normal"
            />
            <TextField
              inputProps={{
                type: "text",
                autoComplete: "off",
                autoCorrect: "off",
                autoCapitalize: "none",
                spellCheck: "false",
              }}
              id={uuid.v4()}
              label={this.language.UnitApt[this.state.language]}
              className={classes.textField}
              value={this.props.receiver.unit}
              onChange={this.props.handleObjChange("unit", "receiver")}
              margin="normal"
            />
          </Fragment>
        );
      case 2:
        return (
          <Fragment>
            <TextField
              inputProps={{
                type: "text",
                autoComplete: "off",
                autoCorrect: "off",
                autoCapitalize: "none",
                spellCheck: "false",
                maxLength: 35,
              }}
              error={this.props.referenceError}
              fullWidth
              id={uuid.v4()}
              label={this.language.ReferencePO[this.state.language]}
              className={classes.textField}
              value={this.props.package.ref}
              onChange={this.props.handleObjChange("ref", "package")}
              margin="normal"
            />
            <TextField
              inputProps={{
                type: "text",
                autoComplete: "off",
                autoCorrect: "off",
                autoCapitalize: "none",
                spellCheck: "false",
                maxLength: 35,
                pattern: /^[A-Za-z0-9-']+$/i,
              }}
              fullWidth
              id={uuid.v4()}
              error={this.props.businessError}
              label={this.language.BusinessName[this.state.language]}
              className={classes.textField}
              value={this.props.receiver.business}
              onChange={this.props.handleObjChange("business", "receiver")}
              margin="normal"
            />
            <TextField
              inputProps={{
                name: "fname",
                type: "text",
                autoComplete: "off",
                autoCorrect: "off",
                autoCapitalize: "none",
                spellCheck: "false",
                maxLength: 20,
              }}
              style={{ width: "45%" }}
              error={this.props.attentionError}
              required
              id={uuid.v4()}
              label={this.language.FirstName[this.state.language]}
              className={classes.textField}
              value={this.props.receiver.fname}
              onChange={this.props.handleObjChange("fname", "receiver")}
              margin="normal"
            />
            <TextField
              inputProps={{
                name: "lname",
                type: "text",
                autoComplete: "off",
                autoCorrect: "off",
                autoCapitalize: "none",
                spellCheck: "false",
                maxLength: 20,
              }}
              style={{ width: "50%", marginLeft: "5%" }}
              error={this.props.attentionError}
              required
              id={uuid.v4()}
              label={this.language.LastName[this.state.language]}
              className={classes.textField}
              value={this.props.receiver.lname}
              onChange={this.props.handleObjChange("lname", "receiver")}
              margin="normal"
            />
            <TextField
              inputProps={{
                type: "phone",
                autoComplete: "off",
                autoCorrect: "off",
                autoCapitalize: "none",
                spellCheck: "false",
                maxLength: 10,
              }}
              style={{ width: "45%" }}
              id={uuid.v4()}
              label={this.language.Phone[this.state.language]}
              className={classes.textField}
              error={this.props.phoneError}
              value={this.props.receiver.phone}
              onChange={this.props.handleObjChange("phone", "receiver")}
              margin="normal"
            />
            <TextField
              inputProps={{
                type: "text",
                autoComplete: "off",
                autoCorrect: "off",
                autoCapitalize: "none",
                spellCheck: "false",
              }}
              style={{ width: "50%", marginLeft: "5%" }}
              error={this.props.emailError}
              id={uuid.v4()}
              type="email"
              label={this.language.Email[this.state.language]}
              // required
              className={classes.textField}
              value={this.props.receiver.email}
              onChange={this.props.handleObjChange("email", "receiver")}
              margin="normal"
            />
            <TextField
              inputProps={{
                type: "text",
                autoComplete: "off",
                autoCorrect: "off",
                autoCapitalize: "none",
                spellCheck: "false",
              }}
              fullWidth
              error={this.props.addressError}
              required
              id={uuid.v4()}
              label={this.language.Address[this.state.language]}
              className={classes.textField}
              value={this.props.receiver.address}
              onChange={this.props.handleObjChange("address", "receiver")}
              margin="normal"
            />
            <TextField
              inputProps={{
                type: "text",
                autoComplete: "off",
                autoCorrect: "off",
                autoCapitalize: "none",
                spellCheck: "false",
              }}
              fullWidth
              error={this.props.addressError}
              id={uuid.v4()}
              label={this.language.Address2[this.state.language]}
              className={classes.textField}
              value={this.props.receiver.address2}
              onChange={this.props.handleObjChange("address2", "receiver")}
              margin="normal"
            />
            <TextField
              autoComplete="new-password"
              onFocus={this.onFocus}
              fullWidth
              id={uuid.v4()}
              label={this.language.UnitApt[this.state.language]}
              className={classes.textField}
              value={this.props.receiver.unit}
              onChange={this.props.handleObjChange("unit", "receiver")}
              margin="normal"
            />
            <TextField
              autoComplete="new-password"
              onFocus={this.onFocus}
              fullWidth
              error={this.props.cityError}
              id={uuid.v4()}
              label={this.language.City[this.state.language]}
              className={classes.textField}
              value={this.props.receiver.city}
              onChange={this.props.handleObjChange("city", "receiver")}
              margin="normal"
            />
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="age-native-simple">
                {this.language.State[this.state.language]}
              </InputLabel>
              <Select
                native
                value={this.props.receiver.state}
                onChange={this.props.handleObjChange("state", "receiver")}
                inputProps={{
                  name: "state",
                  id: "state-native-simple",
                }}
              >
                <option value={"AL"}>Alabama</option>
                <option value={"AK"}>Alaska</option>
                <option value={"AZ"}>Arizona</option>
                <option value={"AR"}>Arkansas</option>
                <option value={"CA"}>California</option>
                <option value={"CO"}>Colorado</option>
                <option value={"CT"}>Connecticut</option>
                <option value={"DE"}>Delaware</option>
                <option value={"DC"}>District of Columbia</option>
                <option value={"FL"}>Florida</option>
                <option value={"GA"}>Georgia</option>
                <option value={"HI"}>Hawaii</option>
                <option value={"ID"}>Idaho</option>
                <option value={"IL"}>Illinois</option>
                <option value={"IN"}>Indiana</option>
                <option value={"IA"}>Iowa</option>
                <option value={"KS"}>Kansas</option>
                <option value={"KY"}>Kentucky</option>
                <option value={"LA"}>Louisiana</option>
                <option value={"ME"}>Maine</option>
                <option value={"MT"}>Montana</option>
                <option value={"NE"}>Nebraska</option>
                <option value={"NV"}>Nevada</option>
                <option value={"NH"}>New Hampshire</option>
                <option value={"NJ"}>New Jersey</option>
                <option value={"NM"}>New Mexico</option>
                <option value={"NY"}>New York</option>
                <option value={"NC"}>North Carolina</option>
                <option value={"ND"}>North Dakota</option>
                <option value={"OH"}>Ohio</option>
                <option value={"OK"}>Oklahoma</option>
                <option value={"OR"}>Oregon</option>
                <option value={"MD"}>Maryland</option>
                <option value={"MA"}>Massachusetts</option>
                <option value={"MI"}>Michigan</option>
                <option value={"MN"}>Minnesota</option>
                <option value={"MS"}>Mississippi</option>
                <option value={"MO"}>Missouri</option>
                <option value={"PA"}>Pennsylvania</option>
                <option value={"PR"}>Puerto Rico</option>
                <option value={"RI"}>Rhode Island</option>
                <option value={"SC"}>South Carolina</option>
                <option value={"SD"}>South Dakota</option>
                <option value={"TN"}>Tennessee</option>
                <option value={"TX"}>Texas</option>
                <option value={"UT"}>Utah</option>
                <option value={"VT"}>Vermont</option>
                <option value={"VA"}>Virginia</option>
                <option value={"WA"}>Washington</option>
                <option value={"WV"}>West Virginia</option>
                <option value={"WI"}>Wisconsin</option>
                <option value={"WY"}>Wyoming</option>
              </Select>
            </FormControl>
            <TextField
              autoComplete="new-password"
              onFocus={this.onFocus}
              fullWidth
              error={this.props.zipError}
              required
              id={uuid.v4()}
              label={this.language.ZIPCode[this.state.language]}
              className={classes.textField}
              value={this.props.receiver.zip}
              onChange={this.props.handleObjChange("zip", "receiver")}
              margin="normal"
            />
          </Fragment>
        );
      default:
        return "Unknown step";
    }
  };

  handleFormSubmit = () => {
    isNotEmpty(this.props.receiver.fname) &&
    isNotEmpty(this.props.receiver.lname)
      ? this.props.setData("attentionError", false)
      : this.props.setData("attentionError", true);
    // isNotEmpty(this.props.package.ref) ? this.props.setData('referenceError',false) : this.props.setData('referenceError',true);
    // isNotEmpty(this.props.receiver.business) ? this.props.setData('businessError',false) : this.props.setData('businessError',true);
    isNotEmpty(this.props.receiver.address)
      ? this.props.setData("addressError", false)
      : this.props.setData("addressError", true);
    isNotEmpty(this.props.receiver.city)
      ? this.props.setData("cityError", false)
      : this.props.setData("cityError", true);
    isNotEmpty(this.props.receiver.zip)
      ? this.props.setData("zipError", false)
      : this.props.setData("zipError", true);
    (isNotEmpty(this.props.receiver.email) &&
      isValidEmail(this.props.receiver.email)) ||
    !isNotEmpty(this.props.receiver.email)
      ? this.props.setData("emailError", false)
      : this.props.setData("emailError", true);
    (isNotEmpty(this.props.receiver.phone) &&
      isValidPhoneNumber(this.props.receiver.phone)) ||
    !isNotEmpty(this.props.receiver.phone)
      ? this.props.setData("phoneError", false)
      : this.props.setData("phoneError", true);
    return (
      isNotEmpty(this.props.receiver.fname) &&
      isNotEmpty(this.props.receiver.lname) &&
      isNotEmpty(this.props.receiver.address) &&
      isNotEmpty(this.props.receiver.city) &&
      isNotEmpty(this.props.receiver.zip) &&
      ((isNotEmpty(this.props.receiver.phone) &&
        isValidPhoneNumber(this.props.receiver.phone)) ||
        !isNotEmpty(this.props.receiver.phone)) &&
      ((isNotEmpty(this.props.receiver.email) &&
        isValidEmail(this.props.receiver.email)) ||
        !isNotEmpty(this.props.receiver.email))
    );
  };

  handleBack = () => {
    this.setState((state) => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  handleNext = () => {
    if (this.props.activeStep == 2) {
      if (this.handleFormSubmit()) {
        this.props.handleStep1Next();
      }
    } else {
      this.props.handleStep1Next(this.state.bypass);
    }
  };

  render() {
    const { classes } = this.props;
    const steps = this.getSteps();
    // const activeStep = this.state.activeStep
    const activeStep = this.props.oneStep;

    return (
      <div>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                <StepContent>
                  {this.getStepContent(index)}
                  <div className={classes.actionsContainer}>
                    <div>
                      {activeStep > 0 && (
                        <Button
                          disabled={this.props.isLoading}
                          onClick={() => this.props.handleBack("oneStep")}
                          className={classes.button}
                        >
                          {this.language.Back[this.state.language]}
                        </Button>
                      )}
                      <Button
                        disabled={
                          (!isValidZipCode(this.props.receiver.zip) &&
                            !this.state.bypass) ||
                          this.props.isLoading
                        }
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          this.handleNext();
                        }}
                        className={classes.button}
                      >
                        {!this.props.isLoading ? (
                          this.language.Next[this.state.language]
                        ) : (
                          <LoadingIcon size={20} />
                        )}
                      </Button>
                      {this.props.addressError && this.props.activeStep === 1 && (
                        <Button
                          disabled={activeStep === 0}
                          onClick={() => {
                            this.props.handleNext("oneStep");
                            this.setState({ bypass: true });
                          }}
                          className={classes.button}
                        >
                          {this.language.ContinueAnyway[this.state.language]}
                        </Button>
                      )}
                    </div>
                  </div>
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
        {activeStep == steps.length && (
          <Paper square elevation={0} className={classes.resetContainer}>
            <Typography variant="subheading">
              <strong>{this.language.Receiver[this.state.language]}:</strong>
              <br />
              {this.props.receiver.business}
              <br />
              Attn: {this.props.receiver.fname} {this.props.receiver.lname}
              <br />
              {this.props.receiver.unit && (
                <span>#{this.props.receiver.unit}&nbsp;-&nbsp;</span>
              )}
              {this.props.receiver.address}
              <br />
              {this.props.receiver.address2 != "" ? (
                <span>
                  {this.props.receiver.address2}
                  <br />
                </span>
              ) : (
                ``
              )}
              {this.props.receiver.city}, {this.props.receiver.state}{" "}
              {this.props.receiver.zip}
              <br />
              {this.props.receiver.country}
              <br />
              <br />
            </Typography>
            <Button
              onClick={() => this.props.handleBack("oneStep")}
              className={classes.button}
            >
              {this.language.Edit[this.state.language]}
            </Button>
            <Button
              onClick={() => this.handleNext()}
              className={classes.button}
              variant="contained"
              color="primary"
            >
              {this.language.ConfirmAndContinue[this.state.language]}
            </Button>
          </Paper>
        )}
        {this.state.addressBookPickerOpen && (
          <AddressBookPickerDialog
            country="US"
            open={this.state.addressBookPickerOpen}
            onClose={() => this.setState({ addressBookPickerOpen: false })}
            onSelect={(address) => {
              this.props.handleAddressbook(address);
              this.setState({ addressBookPickerOpen: false });
            }}
            handleLogout={this.props.handleLogout}
          />
        )}
      </div>
    );
  }
}

VerticalLinearStepper.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(VerticalLinearStepper);
