import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { TextField } from "@material-ui/core";
import classNames from "classnames";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const styles = (theme) => ({
  root: {
    maxWidth: 400,
    flexGrow: 1,
    padding: "20px 30px",
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 50,
    paddingLeft: theme.spacing.unit * 4,
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 255,
    display: "block",
    maxWidth: 400,
    overflow: "hidden",
    width: "100%",
  },
  textField: {
    marginTop: 0,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
});

class SwipeableTextMobileStepper extends React.Component {
  state = {
    activeStep: 0,
  };

  handleNext = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep - 1,
    }));
  };

  handleStepChange = (activeStep) => {
    this.setState({ activeStep });
  };

  render() {
    const { classes, theme } = this.props;
    const { activeStep } = this.state;
    const maxSteps = 3;

    return (
      <div className={classes.root}>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={() => {}}
          enableMouseEvents
        >
          <div key={1}>
            <TextField
              id="outlined-dense"
              label="Postal Code"
              className={classNames(classes.textField, classes.dense)}
              margin="dense"
              variant="outlined"
              style={{ margin: 0 }}
            />
            <Button color="primary" variant="contained">
              Search
            </Button>
          </div>
          <div key={2}>
            <TextField
              id="outlined-dense"
              label="Postal Code"
              disabled={true}
              className={classNames(classes.textField, classes.dense)}
              margin="dense"
              variant="outlined"
              style={{ margin: 0 }}
            />
            <TextField
              id="outlined-dense"
              label="City"
              disabled={true}
              className={classNames(classes.textField, classes.dense)}
              margin="dense"
              variant="outlined"
              style={{ margin: 0 }}
            />
            <TextField
              id="outlined-dense"
              label="Province"
              disabled={true}
              className={classNames(classes.textField, classes.dense)}
              margin="dense"
              variant="outlined"
              style={{ margin: 0 }}
            />
          </div>
          <div key={3}>
            <TextField
              id="outlined-dense"
              label="Postal Code"
              className={classNames(classes.textField, classes.dense)}
              margin="dense"
              variant="outlined"
              style={{ margin: 0 }}
            />
          </div>
        </SwipeableViews>
        {/* <MobileStepper
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          className={classes.mobileStepper}
          nextButton={<span></span>
            
          }
          backButton={
            <span></span>
          }
        /> */}
      </div>
    );
  }
}

SwipeableTextMobileStepper.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(
  SwipeableTextMobileStepper
);
