import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import React from "react";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});

class SimpleSelect extends React.Component {
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  UNSAFE_componentWillMount = async () => {
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });
  };

  language = {
    Packagetype: {
      FR: "Type d'emballage",
      EN: "Package Type",
    },
    Box: {
      FR: "Boîte",
      EN: "Box",
    },
    Envelope: {
      FR: "Enveloppe",
      EN: "Envelope",
    },
    FRBox: {
      FR: "Flat Rate Boîte",
      EN: "Flat Rate Box",
    },
    FREnvelope: {
      FR: "Flat Rate Enveloppe",
      EN: "Flat Rate Envelope",
    },
  };

  render() {
    // const hasFlatRateBox = service => [
    //   'PM-FRL',
    //   'PM-FRX',
    //   'PM-FRS'
    // ].filter(item=>service[item.toUpperCase()] ? true : false).length > 0
    // const hasFlatRateEnv = service => [
    //   'PM-FRE',
    //   'PM-PFE',
    //   'PM-LFE'
    // ].filter(item=>service[item.toUpperCase()] ? true : false).length > 0
    const { classes } = this.props;

    return (
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="package_type">
          {this.language.Packagetype[this.state.language]}
        </InputLabel>
        <Select
          value={this.props.package.type}
          onChange={this.props.handleObjChange("type", "package")}
          inputProps={{
            name: "package_type",
            id: "package_type",
          }}
        >
          {/* {hasFlatRateBox(this.props.services) ? <MenuItem value={'fr_box'}>{this.language.FRBox[this.state.language]}</MenuItem> : ''} */}
          {/* {hasFlatRateEnv(this.props.services) ? <MenuItem value={'fr_env'}>{this.language.FREnv[this.state.language]}</MenuItem> : ''} */}
          <MenuItem value={"box"}>
            {this.language.Box[this.state.language]}
          </MenuItem>
          {/* <MenuItem value={'tube'}>Tube</MenuItem> */}
          <MenuItem value={"envelope"}>
            {this.language.Envelope[this.state.language]}
          </MenuItem>
        </Select>
      </FormControl>
    );
  }
}

SimpleSelect.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleSelect);
