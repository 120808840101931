import { Typography } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import CopyIcon from "@material-ui/icons/FileCopyRounded";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import React, { Fragment } from "react";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: 15,
    marginRight: theme.spacing.unit,
    width: "100px",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 80,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  avatar: {
    fontSize: "1em",
    margin: 10,
    display: "inline-flex",
    // marginTop: "40px",
  },
  orangeAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: "blue",
  },
  row: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    width: 80,
    margin: theme.spacing.unit,
  },
});

class SimpleSelect extends React.Component {
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  UNSAFE_componentWillMount = async () => {
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });
  };

  language = {
    Add: {
      FR: "Ajouter le",
      EN: "Add",
    },
    package: {
      FR: "paquet",
      EN: "package",
    },
    inches: {
      FR: "",
      EN: "inches",
    },
    pounds: {
      FR: "livre",
      EN: "pounds",
    },
    Package: {
      FR: "Paquet",
      EN: "Pallet",
    },
    MaximumReached: {
      FR: "Maximum atteint",
      EN: "Maximum Reached",
    },
    Remove: {
      FR: "Retirer",
      EN: "Remove",
    },
    Weight: {
      FR: "Poids",
      EN: "Weight",
    },
    lb: {
      FR: "livre",
      EN: "lb",
    },
    Length: {
      FR: "Longueur",
      EN: "Length",
    },
    in: {
      FR: "pouce",
      EN: "in",
    },
    Width: {
      FR: "Largeur",
      EN: "Width",
    },
    Height: {
      FR: "Taille",
      EN: "Height",
    },
  };

  renderFixedPLD = (pkg) => (
    <Typography>
      {pkg.dimensions.length} {this.language.package[this.state.language]}
      {pkg.dimensions.length > 1 ? `s` : ``}
    </Typography>
  );

  renderPackages = (pkg) => {
    return pkg.dimensions.map((dims, i) => (
      <form key={i} className={styles.container} noValidate autoComplete="off">
        <Grid justifyContent="center" alignItems="center">
          <Avatar
            className={styles.avatar}
            style={{ display: "inline-flex", marginTop: "40px" }}
          >
            {i + 1}
          </Avatar>
          <TextField
            required
            disabled={this.props.isFreight}
            error={this.props.error}
            id="pkg_length"
            key={`dimsinput_length_${i + 1}`}
            label={this.language.Length[this.state.language]}
            type="number"
            style={{
              marginLeft: 20,
              width: "100px",
            }}
            className={styles.textField}
            value={dims.length}
            helperText={`${this.language.inches[this.state.language]} (${
              this.props.isFreight
                ? `48`
                : this.props.sends_oversized == true
                ? "108"
                : this.props.country === "US"
                ? "25"
                : "45"
            } max)`}
            onChange={this.props.handleDimensionChange(i, "length")}
            margin="normal"
            inputProps={{
              name: "pkg_length",
              step: ".01",
              min: "0.01",
              max: this.props.isFreight
                ? `48`
                : this.props.sends_oversized == true
                ? "108.00"
                : this.props.country === "US"
                ? "25.00"
                : "45.00",
            }}
          />
          <TextField
            required
            type="number"
            key={`dimsinput_width_${i + 1}`}
            error={this.props.error}
            style={{
              marginLeft: 20,
              width: "100px",
            }}
            disabled={this.props.isFreight}
            className={styles.textField}
            id="pkg_width"
            label={this.language.Width[this.state.language]}
            value={dims.width}
            helperText={`${this.language.inches[this.state.language]} (${
              this.props.isFreight
                ? `48`
                : this.props.sends_oversized == true
                ? "108"
                : this.props.country === "US"
                ? "20"
                : "30"
            } max)`}
            onChange={this.props.handleDimensionChange(i, "width")}
            margin="normal"
            inputProps={{
              name: "pkg_width",
              step: ".01",
              min: "0.01",
              max: this.props.isFreight
                ? `48`
                : this.props.sends_oversized == true
                ? "108"
                : this.props.country === "US"
                ? "20"
                : "30",
            }}
          />
          {pkg.type !== "tube" && (
            <TextField
              style={{
                marginLeft: 20,
                width: "100px",
              }}
              required
              type="number"
              key={`dimsinput_height_${i + 1}`}
              error={this.props.error}
              className={styles.textField}
              disabled={pkg.type === "tube"}
              id="pkg_height"
              label={this.language.Height[this.state.language]}
              value={dims.height}
              helperText={`${this.language.inches[this.state.language]} (${
                this.props.isFreight
                  ? `72`
                  : this.props.sends_oversized == true
                  ? "108"
                  : this.props.country === "US"
                  ? "20"
                  : "30"
              } max)`}
              onChange={this.props.handleDimensionChange(i, "height")}
              margin="normal"
              inputProps={{
                name: "pkg_height",
                step: ".01",
                min: "0.01",
                max: this.props.isFreight
                  ? `72`
                  : this.props.sends_oversized == true
                  ? "108"
                  : this.props.country === "US"
                  ? "20"
                  : "30",
              }}
            />
          )}
          <TextField
            style={{
              marginLeft: 20,
              width: "100px",
            }}
            required
            type="number"
            error={this.props.error}
            className={styles.textField}
            id="pkg_weight"
            key={`dimsinput_weight_${i + 1}`}
            label={this.language.Weight[this.state.language]}
            value={dims.weight}
            helperText={`${this.language.pounds[this.state.language]} (${
              this.props.country === "US"
                ? "70"
                : this.props.isFreight
                ? `52000 total`
                : this.props.sends_oversized == true
                ? "150"
                : "66"
            } max)`}
            onChange={this.props.handleDimensionChange(i, "weight")}
            margin="normal"
            inputProps={{
              name: "pkg_weight",
              step: ".01",
              min: 0.01,
              max:
                this.props.country === "US"
                  ? 70
                  : this.props.isFreight
                  ? 52000
                  : this.props.sends_oversized == true
                  ? 150
                  : 66,
            }}
          />
          {/* <div style={{ marginLeft: 60 }}> */}
          <TextField
            style={{
              width: "200px",
              marginLeft: 20,
            }}
            required
            type="text"
            error={this.props.error}
            className={styles.textField}
            id="item_description"
            key={`dimsinput_quantity_${i + 1}`}
            label={"Description of contents"}
            value={dims.item_description}
            helperText={`descripton of items on pallet`}
            onChange={this.props.handleDimensionChange(i, "item_description")}
            margin="normal"
            inputProps={{
              name: "item_description",
            }}
          />
          <TextField
            style={{
              width: "100px",
              marginLeft: 20,
            }}
            required
            type="number"
            error={this.props.error}
            className={styles.textField}
            id="item_quantity"
            key={`dimsinput_quantity_${i + 1}`}
            label={"Quantity"}
            value={dims.item_quantity}
            helperText={`quantity of items`}
            onChange={this.props.handleDimensionChange(i, "item_quantity")}
            margin="normal"
            inputProps={{
              name: "item_quantity",
              min: 1,
            }}
          />
          <TextField
            style={{
              width: "100px",
              marginLeft: 20,
            }}
            required
            type="number"
            error={this.props.error}
            className={styles.textField}
            id="pkg_weight"
            key={`dimsinput_weight_${i + 1}`}
            label={"Value"}
            value={dims.item_value}
            helperText={`total value of contents (CAD)`}
            onChange={this.props.handleDimensionChange(i, "item_value")}
            margin="normal"
            inputProps={{
              name: "item_value",
              min: 1,
            }}
          />
          {this.props.duplicate &&
            this.props.maxQuantity !== 1 &&
            pkg.dimensions.length < this.props.maxQuantity && (
              <IconButton
                variant="extended"
                size="small"
                color="primary"
                disabled={
                  !dims.length || !dims.width || !dims.height || !dims.weight
                }
                aria-label="Add"
                style={{ marginLeft: 10 }}
                onClick={() => this.props.duplicate(dims)}
              >
                <CopyIcon fontSize="small" />
              </IconButton>
            )}
          {/* </div> */}
        </Grid>
      </form>
    ));
  };

  ending = (num) =>
    this.state.language == "FR"
      ? ["e", "er", "e", "e", "e", "e", "e", "e", "e", "e"][num % 10]
      : num > 10 && num < 14
      ? "th"
      : ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"][num % 10];

  render() {
    const { classes } = this.props;

    // console.log("PKG MEASUREMENTS =>>>>> ", this.props);

    return (
      <FormControl className={classes.formControl}>
        {this.props.fixedPLD
          ? this.renderFixedPLD(this.props.package)
          : this.renderPackages(this.props.package)}
        <br />
        <div>
          {this.props.maxQuantity !== 1 && (
            <Fragment>
              <Button
                disabled={
                  this.props.package.count >= this.props.maxQuantity ||
                  this.props.disabled
                }
                style={{ width: "auto", float: "left", marginRight: 15 }}
                variant="outlined"
                color="primary"
                className={styles.button}
                onClick={() => {
                  this.props.adjustCount(1);
                }}
              >
                {this.props.package.dimensions.length < this.props.maxQuantity
                  ? `${this.language.Add[this.state.language]} ${
                      Number(this.props.package.dimensions.length) + 1
                    }${this.ending(
                      Number(this.props.package.dimensions.length) + 1
                    )} ${this.language.Package[this.state.language]}`
                  : `${this.language.MaximumReached[this.state.language]}`}
              </Button>
              <Button
                style={{ width: 150, float: "left" }}
                disabled={this.props.package.count == 1 || this.props.disabled}
                color="secondary"
                className={styles.button}
                onClick={() => {
                  this.props.adjustCount(-1);
                }}
              >
                {this.language.Remove[this.state.language]}
              </Button>
            </Fragment>
          )}
        </div>
        <br />
        <br />
      </FormControl>
    );
  }
}

SimpleSelect.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleSelect);
