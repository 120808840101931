/*
email regex matches pattern A@A.AA
*/
export const isValidEmail = (email) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

/*
password regex matches cognito requirements
1 uppercase letter
1 lowercase letter
1 special character
minimum 8 chars
*/
export const isValidPassword = (password) => {
  const re =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,}$/;
  return re.test(password);
};

/*
return whether the input is not empty
*/
export const isNotEmpty = (input) => {
  return input.length > 0;
};

/*
return whether the input is a valid Canadian postal code
*/
export const isValidPostal = (postal) => {
  if (!postal) return;
  postal = postal.replace(" ", "");
  const re = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
  return re.test(postal);
};

/*
return whether the input is a valid US zipcode (5 digit + 9 digit)
*/
export const isValidZipCode = (zip) => {
  const re = /^\d{5}(-\d{4})?$/;
  // console.log("TEST=>",re.test(zip))
  return re.test(zip);
};

export const isValidPhoneNumber = (phone) => {
  const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
  return re.test(phone.split(" ")[0]);
};

/*
return whether the input is a valid Credit Card number
*/
export const isValidCreditCard = (cardnumber) => {
  if (isAmex(cardnumber)) {
    return "AMEX";
  }
  if (isVisa(cardnumber)) {
    return "VISA";
  }
  if (isMastercard(cardnumber)) {
    return "MASTERCARD";
  }
  if (isDiscover(cardnumber)) {
    return "DISCOVER";
  }
  return "";
};

export const isAmex = (cardnumber) => {
  const amex = /^3[47][0-9]{13}$/;
  return amex.test(cardnumber);
};

export const isMastercard = (cardnumber) => {
  const mastercard =
    /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/;
  return mastercard.test(cardnumber);
};

export const isDiscover = (cardnumber) => {
  const discover = /^6(?:011|5[0-9]{2})[0-9]{12}$/;
  return discover.test(cardnumber);
};

export const isVisa = (cardnumber) => {
  const visa = /^4[0-9]{12}(?:[0-9]{3})?$/;
  return visa.test(cardnumber);
};

/*
return whether the input is a valid Credit Card number
*/
export const isNotInPast = (month, year) => {
  year = parseInt(year);
  month = parseInt(month);
  if (month == 12) {
    month = 1;
    year = year + 1;
  }
  // console.log(month, year)
  if (
    month + 1 > new Date().getMonth() + 1 &&
    year >= new Date().getFullYear().toString().slice(-2)
  ) {
    return true;
  } else if (
    month + 1 <= new Date().getMonth() + 1 &&
    year > new Date().getFullYear().toString().slice(-2)
  ) {
    return true;
  } else {
    return false;
  }
};

/*
return whether the input is a numeric value
*/
export const isNumericOnly = (input) => {
  return Array.prototype.filter.call(input, (e, i) => {
    if ("0123456789".indexOf(input[i]) === -1) {
      return e;
    }
  }).length === 0
    ? true
    : false;
};

/*
return whether the input is a numeric value
*/
export const isAlphaWithSpace = (input) => {
  const alphaNumericWithSpace = /^[A-Za-z- ]+$/i;
  return alphaNumericWithSpace.test(input);
};

/*
return whether the input is an alpha numeric value
*/
export const isAlphaNumeric = (input) => {
  const alphaNumeric = /^[a-z0-9]+$/i;
  return alphaNumeric.test(input);
};

/*
return whether the input is an alpha numeric value, allowing spaces
*/
export const isAlphaNumericWithSpace = (input) => {
  const alphaNumericWithSpace = /^[A-Za-z0-9- ]+$/i;
  return alphaNumericWithSpace.test(input);
};

/*
return whether the input is an alpha numeric value, allowing spaces and apostrophes
*/
export const isAlphaNumericWithApostrophe = (input) => {
  const alphaNumericWithSpace = /^[A-Za-z0-9-' ]+$/i;
  return alphaNumericWithSpace.test(input);
};

/*
return whether the input is an alpha numeric value, allowing hypen
*/
export const isNumericWithHyphen = (input) => {
  const numericWithHyphen = /^[0-9-\-]+$/i;
  return numericWithHyphen.test(input);
};

/*
return whether the input is an alpha numeric value, allowing spaces
*/
export const isAlphaNumericEmail = (input) => {
  const alphaNumericWithSpace = /^[^@]+@[a-zA-Z0-9._-]+\\.+[a-z._-]+$/i;
  return alphaNumericWithSpace.test(input);
};

/*
return whether the input is an alpha numeric value, allowing spaces
*/
export const isMeasurement = (input) => {
  const isMeasurement = /^(\d*\.)?(\d+)?$/g;
  return isMeasurement.test(input);
};

/*
return whether the input is an alpha numeric value, allowing spaces
*/
export const isWeight = (input) => {
  const isWeight = /^([\d]+)?([.])?([\d]+)?$/g;
  return isWeight.test(input);
};

/*
return whether the input is an alpha numeric value, allowing spaces
*/
export const isDollarValue = (input) => {
  const isDollarValue = /^\$?\d+(,\d{3})*(\.\d*)?$/g;
  return isDollarValue.test(input);
};

// const isDollarValue = /^([\d]+)?([.])?([\d]+)?$/g;
