import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { isNumericOnly } from "../helpers/Validation";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    padding: "25px 30px 50px 30px",
  },
  textField: {
    width: "100%",
    margin: "10px 0px",
  },
});

const ranges = [
  {
    value: "0-20",
    label: "0 to 20",
  },
  {
    value: "21-50",
    label: "21 to 50",
  },
  {
    value: "51-100",
    label: "51 to 100",
  },
];

class OutlinedInputAdornments extends React.Component {
  state = {
    name: "",
    shipmentCount: "",
  };

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
    this.props.updateState(name, event.target.value);
  };

  render() {
    const language = {
      Justafewquestionsbeforeyougetstarted: {
        FR: "Quelques questions avant de commencer",
        EN: "Just a few questions before you get started",
      },
      FirstName: {
        FR: "Prénom",
        EN: "First Name",
      },
      LastName: {
        FR: "Nom de famille",
        EN: "Last Name",
      },
      BusinessName: {
        FR: "Emplacement ou Nom du Commerce",
        EN: "Business Name",
      },
    };
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <h3 style={{ marginBottom: "25px" }}>
          {language.Justafewquestionsbeforeyougetstarted[this.props.language]}
        </h3>
        <TextField
          fullWidth
          required
          id="outlined-simple-start-adornment"
          className={classNames(classes.textField)}
          variant="outlined"
          value={this.props.fname}
          onChange={this.props.updateState("fname")}
          label={language.FirstName[this.props.language]}
        />
        <TextField
          fullWidth
          required
          id="outlined-simple-start-adornment"
          className={classNames(classes.textField)}
          variant="outlined"
          value={this.props.lname}
          onChange={this.props.updateState("lname")}
          label={language.LastName[this.props.language]}
        />
        <TextField
          fullWidth
          // required
          id="outlined-simple-start-adornment"
          className={classNames(classes.textField)}
          variant="outlined"
          value={this.props.business}
          onChange={this.props.updateState("business")}
          label={language.BusinessName[this.props.language]}
        />
      </div>
    );
  }
}

OutlinedInputAdornments.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OutlinedInputAdornments);
