import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import MobileStepper from "@material-ui/core/MobileStepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { withStyles } from "@material-ui/core/styles";
import { API, graphqlOperation } from "aws-amplify";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import confirmAddress from "../../api/confirmAddressUS";
import searchZip from "../../api/searchZip";
import UnconfirmedAddress from "../../dialogs/UnconfirmedAddress";
import {
  isAlphaNumeric,
  isAlphaNumericWithApostrophe,
  isAlphaNumericWithSpace,
  isAlphaWithSpace,
  isDollarValue,
  isMeasurement,
  isNumericOnly,
  isValidZipCode,
} from "../../helpers/Validation";
import SnackBar from "../Snackbar";
import USStep1 from "./Step1";
import USStep2 from "./Step2";
import USStep3 from "./Step3";
import USStep4 from "./Step4";
import USStep5 from "./Step5";

const styles = (theme) => ({
  root: {
    background: "transparent",
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  dots: {
    margin: "auto",
  },
});

class HorizontalLabelPositionBelowStepper extends React.Component {
  getSteps = () => {
    return [
      this.language.Receiverinformation[this.state.language],
      this.language.Senderinformation[this.state.language],
      this.language.Packagedetails[this.state.language],
      this.language.ShippingService[this.state.language],
      this.language.ConfirmandSubmit[this.state.language],
    ];
  };

  UNSAFE_componentWillMount = async () => {
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });

    if (this.props.editPkg) {
      this.setState({ oldReceiver: JSON.parse(this.props.editRec) });
      // alert('true')
      // console.log(JSON.parse(this.props.editPkg))
      this.setState({ package: JSON.parse(this.props.editPkg) });
      this.setState({ receiver: JSON.parse(this.props.editRec) });
      this.setState({ activeStep: JSON.parse(this.props.currentStep) });
      // console.log(this.props.editRec)

      if (!isValidZipCode(JSON.parse(this.props.editRec).zip)) {
        this.setState({ zipError: true });
        this.setState({ activeStep: 0 });
        this.setState({ snackOpen: true });
        return;
      }

      await confirmAddress({ receiver: JSON.parse(this.props.editRec) }).then(
        (e) => {
          // console.log(e);
          if (e.status) {
            this.setState({ oneStep: 3 });
            this.setObjData("verified", "receiver", true);
            this.setObjData("address", "receiver", e.result.address);
            this.setObjData(
              "zip",
              "receiver",
              `${e.result.zip}${
                e.result.zip4 !== "" ? `-${e.result.zip4}` : ""
              }`
            );
            this.setObjData("zip5", "receiver", `${e.result.zip}`);

            this.setState({ addressError: false });
            this.setState({ zipError: false });

            this.setState({ activeStep: 2 });
          } else {
            this.setState({ zipError: true });
            this.setObjData("verified", "receiver", false);
            this.setState({ snackOpen: true });
            this.setState({ addressError: true });
            this.setState({ activeStep: 0 });
          }
        }
      );
      if (this.props.currentStep == 4) {
        this.setState({ twoStep: 0 });
      }
      this.setState({
        senderLocation:
          this.props.locations.filter((e) => e.id === this.props.sender_id)
            .length > 0
            ? this.props.locations.filter(
                (e) => e.id === this.props.sender_id
              )[0]
            : this.props.locations.length
            ? this.props.locations[0]
            : 0,
      });
      this.setState({
        senderLocationID:
          this.props.locations.filter((e) => e.id === this.props.sender_id)
            .length > 0
            ? this.props.sender_id
            : this.props.locations.length
            ? this.props.locations[0].id
            : 0,
      });
    }
  };

  language = {
    Receiverinformation: {
      FR: "Informations sur le récepteur",
      EN: "Receiver",
    },
    Senderinformation: {
      FR: "Informations sur l'expéditeur",
      EN: "Sender",
    },
    Packagedetails: {
      FR: "Détails du paquet",
      EN: "Package",
    },
    ShippingService: {
      FR: "Service de livraison",
      EN: "Service",
    },
    ConfirmandSubmit: {
      FR: "Confirmer et soumettre",
      EN: "Confirmation",
    },
    Back: {
      FR: "Arrière",
      EN: "Back",
    },
    Finish: {
      FR: "Terminer",
      EN: "Finish",
    },
    Next: {
      FR: "Suivant",
      EN: "Next",
    },
  };

  state = {
    contents_type: 0,
    modalOpen: false,
    snackOpen: false,
    phoneError: false,
    emailError: false,
    id: "",
    activeStep: 0,
    oneStep: 0,
    twoStep: 0,
    receiver: {
      verified: false,
      business: "",
      fname: "",
      lname: "",
      attention: "",
      address: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      zip5: "",
      country: "US",
      phone: "",
      email: "",
      street_name: "",
      street_number: "",
    },
    package: {
      origin: "CA",
      ref: "",
      count: "1",
      description: "",
      quantity: "",
      value: "",
      hs_code: "",
      insured: false,
      type: "box",
      dimensions: [
        {
          weight: "",
          height: "",
          length: "",
          width: "",
        },
      ],
    },
    senderLocationID: this.props.locations.length
      ? this.props.locations[0].id
      : 0,
    senderLocation: this.props.locations.length ? this.props.locations[0] : "",
    selectedService: 0,
    rates: [],
  };

  rates = [];

  handleChange = (name) => (event) => {
    this.setObjData(name, "receiver", event.value);
  };

  handleAddressbook = (addressbook_item) => {
    this.setState({
      receiver: JSON.parse(`{
      "verified": "false",
      ${
        addressbook_item.business_name != null
          ? `"business": "${addressbook_item.business_name}",`
          : '"business":"",'
      }
      "fname": "${
        addressbook_item.first_name !== " " ? addressbook_item.first_name : ""
      }",
      "lname": "${
        addressbook_item.last_name !== " " ? addressbook_item.last_name : ""
      }",
      "attention": "${
        addressbook_item.first_name !== " " ? addressbook_item.first_name : ""
      }${
        addressbook_item.last_name !== " "
          ? ` ${addressbook_item.last_name}`
          : ""
      }",
      "address": "${addressbook_item.address1}",
      "unit": "${addressbook_item.address2 ? addressbook_item.address2 : ""}",
      "address2": "${
        addressbook_item.address2 ? addressbook_item.address2 : ""
      }",
      "city": "${addressbook_item.city}",
      "state": "${addressbook_item.state}",
      "zip": "${addressbook_item.postal}",
      "zip5": "${addressbook_item.postal}",
      "country": "${addressbook_item.country}",
      ${
        addressbook_item.phone != null
          ? `"phone": "${addressbook_item.phone}",`
          : '"phone":"",'
      }
      ${
        addressbook_item.email != null
          ? `"email": "${addressbook_item.email}",`
          : '"email":"",'
      }
      "street_name": "${addressbook_item.address1.substring(
        addressbook_item.address1.indexOf(" ") + 1
      )}",
      "street_number": "${
        addressbook_item.address1.split(" ").length > 1
          ? addressbook_item.address1.split(" ")[0]
          : " "
      }",
      "is_residential": "false"
    }`),
    });
    this.handleStep1Next();
  };

  handleObjChange = (name, objName) => (event) => {
    if (
      event.target.value.length < 50 &&
      (name === "hs_code" || event.target.value === "")
    ) {
      let temp = this.state[objName];
      temp[name] = event.target.value;
      this.setState({ [objName]: temp });
      return;
    }
    if (
      name === "email" ||
      (event.target.value === "" && event.target.value.length < 50)
    ) {
      let temp = this.state[objName];
      temp[name] = event.target.value;
      this.setState({ [objName]: temp });
    }
    if (event.target.value.length < 30) {
      if (name === "fname" || name === "lname") {
        if (isAlphaWithSpace(event.target.value)) {
          let temp = this.state[objName];
          temp[name] = event.target.value;
          this.setState({ [objName]: temp });
        }
      } else if (name === "business") {
        if (isAlphaNumericWithApostrophe(event.target.value)) {
          let temp = this.state[objName];
          temp[name] = event.target.value;
          this.setState({ [objName]: temp });
        }
      } else if (name === "quantity") {
        if (isNumericOnly(event.target.value)) {
          let temp = this.state[objName];
          temp[name] = event.target.value;
          this.setState({ [objName]: temp });
          return;
        }
      } else if (name === "value") {
        if (isDollarValue(event.target.value)) {
          let temp = this.state[objName];
          temp[name] = event.target.value;
          this.setState({ [objName]: temp });
          return;
        }
      } else if (isAlphaNumericWithSpace(event.target.value)) {
        if (event.target.value === "") {
          let temp = this.state[objName];
          temp[name] = event.target.value;
          this.setState({ [objName]: temp });
        }

        if (name === "zip" && !isAlphaNumeric(event.target.value)) {
          return;
        }
        let temp = this.state[objName];
        temp[name] = event.target.value;
        this.setState({ [objName]: temp });
      }
    }
  };

  adjustCount = async (val) => {
    let temp = this.state.package;
    temp.count = parseInt(temp.count) + parseInt(val);
    this.setState({ package: temp });
    if (val > 0) {
      temp.dimensions.push({
        length: "",
        width: "",
        height: "",
        weight: "",
      });
    } else {
      temp.dimensions.pop();
    }
    this.setState({ package: temp });
  };

  duplicate = async (dims) => {
    let temp = Object.assign({}, this.state.package);

    // let temp = this.state.package;
    temp.count = parseInt(temp.count) + parseInt(1);
    // this.setState({ package: temp });
    temp.dimensions.push({
      length: dims["length"],
      width: dims.width,
      height: dims.height,
      weight: dims.weight,
    });
    // console.log(temp.dimensions);
    this.setState({ package: temp });
  };

  handleDimensionChange = (i, name) => (event) => {
    if (name === "weight") {
      if (event.target.value > 70) {
        this.setState({ weightError: true });
        return;
      }
    }
    if (this.props.account.user.sends_oversized) {
      let copy = this.state.package;
      const arr = [
        Math.ceil(
          name == "length" ? event.target.value : copy.dimensions[i]["length"]
        ),
        Math.ceil(
          name == "width" ? event.target.value : copy.dimensions[i]["width"]
        ),
        Math.ceil(
          name == "height" ? event.target.value : copy.dimensions[i]["height"]
        ),
      ].sort((a, b) => {
        return a > b ? 1 : -1;
      });
      if (Number(arr[2]) + Number(2 * arr[1]) + Number(2 * arr[0]) > 108) {
        return;
      }
      if (event.target.value > 108) {
        return;
      }
    } else {
      switch (name) {
        case "weight":
          if (Number(event.target.value) > 70) {
            return;
          } else {
            break;
          }
        case "length":
          if (Number(event.target.value) > 25) {
            return;
          } else {
            break;
          }
        case "width":
          if (Number(event.target.value) > 20) {
            return;
          } else {
            break;
          }
        case "height":
          if (Number(event.target.value) > 20) {
            return;
          } else {
            break;
          }
      }
    }
    if (event.target.value === "" || isMeasurement(event.target.value)) {
      let copy = this.state.package;
      copy.dimensions[i][name] = event.target.value;
      this.setState({ package: copy });
    }
  };

  setDimensionChange = (name, i, val) => {
    if (isMeasurement(val)) {
      let copy = this.state.package;
      copy.dimensions[i][name] = val;
      this.setState({ package: copy });
    }
  };

  setData = (name, data) => {
    this.setState({
      [name]: data,
    });
  };

  setObjData = (name, objName, data) => {
    let temp = this.state[objName];
    temp[name] = data;
    this.setState({ [objName]: temp });
  };

  setRates = (name, value) => {
    // console.log(name, value);

    this.setState({
      [name]: value,
    });

    this.rates = value;
  };

  toggleCheck = (name, objName) => (event) => {
    let temp = this.state[objName];
    temp[name] = event.target.checked;
    this.setState({ [objName]: temp });
  };

  createShipment = async () => {
    this.setState({ isLoading: true });

    await API.graphql(
      graphqlOperation(`mutation createShipment{
        ${
          this.props.editPkg ? `updateV1_Shipment` : `createV1_Shipment`
        } (input:{
          ${
            this.props.editPkg
              ? ""
              : `date_created:"${new Date().toLocaleString()}"`
          }
          ${
            this.props.editPkg
              ? `date_updated:"${new Date().toLocaleString()}"`
              : ""
          }
          ${this.props.editPkg ? "" : `platform:"SWIFTPOST"`}
          sender_id:"${this.state.senderLocationID}"
          ${this.props.editPkg ? `id:"${this.props.editID}"` : ``}
        rate:{
          amount:"${this.rates[this.state.selectedService].rate}"
          subtotal:"${this.rates[this.state.selectedService].rate}"
          rate_id:"${this.rates[this.state.selectedService].rate_id}"
          service:{
            name: "${
              this.rates[this.state.selectedService].service.service.name
            }"
            courier_code: "${
              this.rates[this.state.selectedService].service.service.rate_type
            }"
            courier: "USPS"
          }
        }
        receiver:{
          ${
            this.state.receiver.business
              ? `title:"${this.state.receiver.business}"`
              : ``
          }
          address:{
            ${
              this.state.receiver.address2
                ? `address2:"${this.state.receiver.address2}"`
                : ""
            }
            street_name:"${this.state.receiver.address}"
            postal:"${this.state.receiver.zip}"
            state:"${this.state.receiver.state}"
            country:"US"
            ${
              this.state.receiver.unit &&
              this.state.receiver.unit !== null &&
              this.state.receiver.unit !== "null" &&
              this.state.receiver.unit !== undefined &&
              this.state.receiver.unit !== "undefined"
                ? `unit:"${this.state.receiver.unit}"`
                : ``
            }
            city:"${this.state.receiver.city}"
          }
          contact: {
            fname:"${this.state.receiver.fname}"
            lname:"${this.state.receiver.lname}"
            ${
              this.state.receiver.email
                ? `email:"${this.state.receiver.email}"`
                : ``
            }
            ${
              this.state.receiver.phone
                ? `phone:"${this.state.receiver.phone}"`
                : ``
            }
          }
        }
        receiver_invalid:"false"
        sender:{
          title:"${this.state.senderLocation.title}"
          address:{
            street_name:"${this.state.senderLocation.address.street_name}"
            street_number:"${this.state.senderLocation.address.street_number}"
            postal:"${this.state.senderLocation.address.postal}"
            state:"${this.state.senderLocation.address.state}"
            country:"CA"
            ${
              this.state.senderLocation.address.unit &&
              this.state.senderLocation.address.unit !== null &&
              this.state.senderLocation.address.unit !== "null" &&
              this.state.senderLocation.address.unit !== undefined &&
              this.state.senderLocation.address.unit !== "undefined"
                ? `unit:"${this.state.senderLocation.address.unit}"`
                : ``
            }
            city:"${this.state.senderLocation.address.city}"
          }
          contact: {
            fname:"${this.state.senderLocation.contact.fname}"
            lname:"${this.state.senderLocation.contact.lname}"
            ${
              this.state.senderLocation.contact.email
                ? `email:"${this.state.senderLocation.contact.email}"`
                : ``
            }
            ${
              this.state.senderLocation.contact.phone
                ? `phone:"${this.state.senderLocation.contact.phone}"`
                : ``
            }
          }
        }
        contents_type: ${this.state.contents_type}
        ${
          this.state.package.description
            ? `contents:{
            title: "${this.state.package.description}"
            item_quantity: "${this.state.package.quantity}"
            item_value: "${this.state.package.value}"
            origin: "${this.state.package.origin}"
            ${
              this.state.package.hs_code
                ? `hs_code:"${this.state.package.hs_code}"`
                : ``
            }
        }`
            : ``
        }
        package: {
          entered_pld:[{
            width:"${this.state.package.dimensions[0].width}"
            height:"${this.state.package.dimensions[0].height}"
            length:"${this.state.package.dimensions[0].length}"
            weight:"${this.state.package.dimensions[0].weight}"
          }]
        }
        ${
          this.state.package.ref
            ? `reference:"${this.state.package.ref}"`
            : `reference:"${new Date().getTime()}"`
        }
      }){
        id
        cognitoUsername
      }
    }`)
    ).catch((e) => {
      // console.log(e);
      this.setState({ isLoading: false });
    });
    this.props.goToOrders();
  };

  handleZipChange = (name) => (event) => {
    if (isNumericOnly(event.target.value) || event.target.value === "") {
      this.setObjData(name, "receiver", event.target.value.toUpperCase());
      if (event.target.value == "") {
        this.setObjData("state", "receiver", "");
        this.setObjData("city", "receiver", "");
      }
      if (isValidZipCode(event.target.value)) {
        this.setState({ isLoading: true });
        searchZip(event.target.value)
          .then((e) => {
            // console.log(e);
            this.setState({ isLoading: false });
            if (e.status) {
              this.setState({ zipError: false });
              this.setObjData("state", "receiver", e.state);
              this.setObjData("city", "receiver", e.city);
              this.setObjData("zip", "receiver", e.zip);
              this.handleStep1Next();
            } else {
              this.setState({ zipError: true });
              this.setObjData("state", "receiver", "");
              this.setObjData("city", "receiver", "");
            }
          })
          .catch((e) => {
            this.setState({ isLoading: false });
            this.setState({ zipError: true });
          });
      } else {
        this.setState({ isLoading: false });
        this.setState({ zipError: true });
      }
    }
  };

  handleStep1Next = (bypass) => {
    if (this.state.oneStep === 1) {
      confirmAddress(this.state).then((e) => {
        // console.log(e.result);
        if (e.status) {
          this.setObjData("verified", "receiver", true);
          this.setObjData("address", "receiver", e.result.address);
          this.setObjData(
            "zip",
            "receiver",
            `${e.result.zip}${e.result.zip4 !== "" ? `-${e.result.zip4}` : ""}`
          );
          this.setObjData("zip5", "receiver", `${e.result.zip}`);

          this.setState({ addressError: false });
          this.setState({ zipError: false });

          this.setState((state) => ({
            oneStep: state.oneStep + 1,
          }));
        } else {
          this.setObjData("verified", "receiver", false);
          this.setState({ snackOpen: true });
          this.setState({ addressError: true });
        }
      });
    } else if (this.state.oneStep === 2) {
      confirmAddress(this.state).then((e) => {
        if (e.status) {
          this.setObjData("verified", "receiver", true);
          this.setState((state) => ({
            oneStep: state.oneStep + 1,
          }));
        } else {
          this.setObjData("verified", "receiver", false);
          this.setState({ modalOpen: true });
        }
      });
    } else if (this.state.oneStep === 3) {
      this.handleNext("activeStep");
    } else {
      this.setState((state) => ({
        oneStep: state.oneStep + 1,
      }));
    }
  };

  handleLocationChange = (e) => {
    this.setState({ senderLocation: e.location });
    this.setState({ senderLocationID: e.id });
  };

  getStepContent = (stepIndex) => {
    const { classes, ...childProps } = this.props;

    switch (stepIndex) {
      case 0:
        return (
          <USStep1
            {...this.state}
            language={this.state.language}
            previousData={this.state.oldReceiver}
            handleNext={() => this.handleNext("oneStep")}
            handleBack={(e) => this.handleBack(e)}
            activeStep={this.state.oneStep}
            advance={() => this.handleNext("activeStep")}
            handleStep1Next={this.handleStep1Next}
            handleZipChange={(zip) => this.handleZipChange(zip)}
            handleChange={(name) => this.handleChange(name)}
            setData={(name, data) => this.setData(name, data)}
            setObjData={(name, objName, data) =>
              this.setObjData(name, objName, data)
            }
            handleObjChange={(name, objName) =>
              this.handleObjChange(name, objName)
            }
            handleAddressbook={this.handleAddressbook}
            handleLogout={this.props.handleLogout}
          />
        );
      case 1:
        return (
          <USStep2
            language={this.state.language}
            account={this.props.account}
            handleLocationChange={(e) => this.handleLocationChange(e)}
            handleBack={(e) => {
              this.handleBack(e);
            }}
            handleNext={(e) => {
              this.handleNext(e);
            }}
            locations={this.props.locations}
            {...this.state}
            {...childProps}
          />
        );
      case 2:
        return (
          <USStep3
            language={this.state.language}
            account={this.props.account}
            activeStep={this.state.twoStep}
            handleChange={(name) => this.handleChange(name)}
            handleBack={(e) => {
              this.handleBack(e);
            }}
            handleNext={(e) => {
              this.handleNext(e);
            }}
            setRates={(e, i) => {
              this.setRates(e, i);
            }}
            setObjData={(name, objName, data) =>
              this.setObjData(name, objName, data)
            }
            handleObjChange={(name, objName) =>
              this.handleObjChange(name, objName)
            }
            sender={this.state.senderLocation}
            toggleCheck={(name, objName) => {
              this.toggleCheck(name, objName);
            }}
            handleDimensionChange={(i, name) =>
              this.handleDimensionChange(i, name)
            }
            setDimensionChange={(name, i, val) =>
              this.setDimensionChange(name, i, val)
            }
            adjustCount={(val) => {
              this.adjustCount(val);
            }}
            duplicate={(dims) => {
              this.duplicate(dims);
            }}
            {...this.state}
            {...childProps}
          />
        );
      case 3:
        return (
          <USStep4
            language={this.state.language}
            setData={(name, data) => this.setData(name, data)}
            setObjData={(name, objName, data) =>
              this.setObjData(name, objName, data)
            }
            handleObjChange={(name, objName) =>
              this.handleObjChange(name, objName)
            }
            handleBack={(e) => {
              this.handleBack(e);
            }}
            handleNext={(e) => {
              this.handleNext(e);
            }}
            {...this.state}
            {...childProps}
          />
        );
      case 4:
        return (
          <USStep5
            language={this.state.language}
            {...this.state}
            {...childProps}
            editRate={this.props.editRate}
            locations={this.props.locations}
            handleGoToStep={(e) => this.handleGoToStep(e)}
            handleBack={(e) => {
              this.handleBack(e);
            }}
            handleNext={(e) => {
              this.handleNext(e);
            }}
            createShipment={() => {
              this.createShipment();
            }}
          />
        );
      default:
        return "Uknown stepIndex";
    }
  };

  handleNext = (stepper) => {
    const activeStep = this.state[stepper];
    this.setState({
      [stepper]: activeStep + 1,
    });
  };

  handleBack = (stepper) => {
    const activeStep = this.state[stepper];
    this.setState({
      [stepper]: activeStep - 1,
    });
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  handleGoToStep = async (step) => {
    if (step === 3 && this.state.rates.length === 0) {
      // if(this.state.rates.length === 0){
      this.setState({
        activeStep: 2,
        twoStep: 0,
      });
      // } else{

      // }
      // let sample = this.props.locations.filter(
      //   e => e.id === this.state.senderLocationID
      // );
      // sample = sample[0];
      // let test = await resolver(this.state);
      // await getRates(
      //   {
      //     ...this.state,
      //     sender: sample
      //   },
      //   test,
      //   async e => {
      //     this.setState({ isLoading: false });
      //     this.setRates(
      //       "rates",
      //       e.response.filter(f => {
      //         return f.rate != 0;
      //       })
      //     );
      //     this.setState({
      //       activeStep: step
      //     });
      //   }
      // );
    } else {
      this.setState({
        activeStep: step,
      });
    }
  };

  render() {
    const { classes } = this.props;
    const steps = this.getSteps();
    const { activeStep } = this.state;

    return (
      <Fragment>
        <UnconfirmedAddress
          open={this.state.modalOpen}
          onClose={() => this.setState({ modalOpen: false })}
          modalContinue={() => {
            this.setState((state) => ({
              oneStep: state.oneStep + 1,
            }));
            this.setState({ modalOpen: false });
          }}
        />
        <SnackBar
          variant="error"
          action={[
            <Button
              style={{ color: "#ffffff" }}
              size="small"
              onClick={() => this.setState({ snackOpen: false })}
            >
              I understand
            </Button>,
          ]}
          duration="60000"
          open={this.state.snackOpen}
          message={
            "We were unable to find that address. Please confirm that the address is correct. Address corrections can incur additional fees as laid out in our Terms and Services."
          }
          handleClose={() => {
            this.setState({ snackOpen: false });
          }}
        />

        <Hidden smDown={false} mdUp={true}>
          <Typography className={"stepper"} variant={"strong"}>
            {steps[activeStep]}
          </Typography>
        </Hidden>

        <Hidden smDown={false} mdUp={true}>
          <MobileStepper
            variant="dots"
            steps={steps.length}
            position="static"
            activeStep={this.state.activeStep}
            classes={{
              root: classes.root,
              dots: classes.dots,
            }}
            style={{ width: "100%", margin: "auto" }}
          />
        </Hidden>

        <Hidden smDown={true}>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            style={{ background: "transparent", width: "100%" }}
          >
            {steps.map((label) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Hidden>

        <div style={{ maxWidth: "900px", width: "100%" }}>
          {this.getStepContent(activeStep)}
          {this.state.activeStep == 100 && (
            <div>
              <br />
              <br />
              <Button
                disabled={activeStep === 0}
                onClick={() => this.handleBack("activeStep")}
                className={classes.backButton}
              >
                {this.language.Back[this.state.language]}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.handleNext("activeStep")}
              >
                {activeStep === steps.length - 1
                  ? this.language.Finish[this.state.language]
                  : this.language.Next[this.state.language]}
              </Button>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

HorizontalLabelPositionBelowStepper.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(HorizontalLabelPositionBelowStepper);
