import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import MobileStepper from "@material-ui/core/MobileStepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { withStyles } from "@material-ui/core/styles";
import { API, graphqlOperation } from "aws-amplify";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import UnconfirmedAddress from "../../dialogs/UnconfirmedAddress";
import {
  isAlphaNumeric,
  isAlphaNumericWithApostrophe,
  isAlphaNumericWithSpace,
  isAlphaWithSpace,
  isDollarValue,
  isMeasurement,
  isNumericOnly,
  isValidPostal,
} from "../../helpers/Validation";
import SnackBar from "../Snackbar";
import CANStep1 from "./Step1";
import CANStep2 from "./Step2";
import CANStep3 from "./Step3";
import CANStep4 from "./Step4";
import CANStep5 from "./Step5";

const styles = (theme) => ({
  root: {
    background: "transparent",
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  dots: {
    margin: "auto",
  },
});

class HorizontalLabelPositionBelowStepper extends React.Component {
  getSteps = () => {
    return [
      this.language.Receiverinformation[this.state.language],
      // this.language.Senderinformation[this.state.language],
      this.language.Packagedetails[this.state.language],
      ,
      this.language.ShippingService[this.state.language],
      ,
      this.language.ConfirmandSubmit[this.state.language],
    ];
  };

  language = {
    Receiverinformation: {
      FR: "Informations sur le récepteur",
      EN: "Receiver information",
    },
    Senderinformation: {
      FR: "Informations sur l'expéditeur",
      EN: "Sender information",
    },
    Packagedetails: {
      FR: "Détails du paquet",
      EN: "Package details",
    },
    ShippingService: {
      FR: "Service de livraison",
      EN: "Shipping service",
    },
    ConfirmandSubmit: {
      FR: "Confirmer et soumettre",
      EN: "Confirm and Submit",
    },
    Back: {
      FR: "Arrière",
      EN: "Back",
    },
    Finish: {
      FR: "Terminer",
      EN: "Finish",
    },
    Next: {
      FR: "Suivant",
      EN: "Next",
    },
  };

  UNSAFE_componentWillMount = async () => {
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });

    if (this.props.editPkg) {
      // alert('true')
      this.setState({ oldReceiver: JSON.parse(this.props.editRec) });

      // console.log(JSON.parse(this.props.editPkg))
      this.setState({ package: JSON.parse(this.props.editPkg) });
      this.setState({ receiver: JSON.parse(this.props.editRec) });
      this.setState({ activeStep: JSON.parse(this.props.currentStep) });
      this.setState({
        senderLocation:
          this.props.locations.filter((e) => e.id === this.props.sender_id)
            .length > 0
            ? this.props.locations.filter(
                (e) => e.id === this.props.sender_id
              )[0]
            : this.props.locations.length
            ? this.props.locations[0]
            : 0,
      });
      this.setState({
        senderLocationID:
          this.props.locations.filter((e) => e.id === this.props.sender_id)
            .length > 0
            ? this.props.sender_id
            : this.props.locations.length
            ? this.props.locations[0].id
            : 0,
      });

      if (
        !isValidPostal(JSON.parse(this.props.editRec).zip) ||
        !JSON.parse(this.props.editRec).address ||
        JSON.parse(this.props.editRec).address.trim() === ""
      ) {
        this.setState({ zipError: true });
        this.setState({ activeStep: 0 });
        this.setState({ snackOpen: true });
        return;
      }

      await API.post("sqs", "/address/verify", {
        body: {
          postal: JSON.parse(this.props.editRec).zip,
          country: "CA",
        },
      })
        .then((e) => {
          // alert(e.is_residential)
          // console.log(e);
          this.setObjData("is_residential", "receiver", e.is_residential);
          this.setState({ zipError: false });
          this.setState({ isLoading: false });
          if (
            e.state.toUpperCase() != this.state.receiver.state.toUpperCase() ||
            e.city.toUpperCase() != this.state.receiver.city.toUpperCase()
          ) {
            this.setState({
              response: Array.from(new Set(e.street_name)).map((f) => {
                return { value: f, label: f };
              }),
              streetArr: Array.from(new Set(e.street_name)),
            });
            this.setObjData("state", "receiver", e.state);
            this.setObjData("city", "receiver", e.city);
            this.setObjData("zip", "receiver", e.postal);
            // this.setObjData("street_name", "receiver", "");
            // this.setObjData("street_number", "receiver", "");
            this.setState({ isLoading: false });
            this.setObjData("verified", "receiver", false);
            this.setState({ snackOpen: true });
            this.setState({ addressError: true });
            this.setState({ activeStep: 0 });
            // this.setState({ zipError: true });
            this.setState({ oneStep: 1 });
            return;
          } else {
            this.setObjData("state", "receiver", e.state);
            this.setObjData("city", "receiver", e.city);
            this.setObjData("zip", "receiver", e.postal);
            this.setState({ oneStep: 3 });
          }
          // this.setState({
          //   response: Array.from(new Set(e.street_name)).map(f => {
          //     return { value: f, label: f };
          //   }),
          //   streetArr: Array.from(new Set(e.street_name))
          // });

          // this.setObjData(
          //   "street_name",
          //   "receiver",
          //   Array.from(new Set(e.street_name))[0]
          // );
          this.setState({ searchDone: true });
          this.setState({ activeStep: 2 });
        })
        .catch((e) => {
          // console.log(e);
          this.setState({ isLoading: false });
          this.setObjData("verified", "receiver", false);
          this.setState({ snackOpen: true });
          this.setState({ addressError: true });
          this.setState({ activeStep: 0 });
          this.setState({ zipError: true });
        });
      if (this.props.currentStep == 4) {
        this.setState({ twoStep: 2 });
      }
    }
  };

  state = {
    insured: false,
    insured_value: 0,
    rateError: false,
    phoneError: false,
    emailError: false,
    modalOpen: false,
    snackOpen: false,
    poBoxWarning: false,
    id: "",
    streetArr: [],
    activeStep: 0,
    oneStep: 0,
    twoStep: 0,
    receiver: {
      verified: false,
      business: "",
      fname: "",
      lname: "",
      attention: "",
      address: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      zip5: "",
      country: "CA",
      phone: "",
      email: "",
      street_name: "",
      street_number: "",
      is_residential: false,
    },
    package: {
      origin: "CA",
      ref: "",
      count: "1",
      description: "",
      quantity: "",
      value: "",
      insured: false,
      type:
        this.props.account &&
        this.props.account.user &&
        this.props.account.user.fixedPLD &&
        this.props.account.user.fixedPLD.type
          ? this.props.account.user.fixedPLD.type
          : "box",
      typeFixed:
        this.props.account &&
        this.props.account.user &&
        this.props.account.user.fixedPLD &&
        this.props.account.user.fixedPLD.type,
      dimensions: [
        {
          weight:
            this.props.account &&
            this.props.account.user &&
            this.props.account.user.fixedPLD
              ? this.props.account.user.fixedPLD.weight
              : "",
          height:
            this.props.account &&
            this.props.account.user &&
            this.props.account.user.fixedPLD
              ? this.props.account.user.fixedPLD.height
              : "",
          length:
            this.props.account &&
            this.props.account.user &&
            this.props.account.user.fixedPLD
              ? this.props.account.user.fixedPLD["length"]
              : "",
          width:
            this.props.account &&
            this.props.account.user &&
            this.props.account.user.fixedPLD
              ? this.props.account.user.fixedPLD.width
              : "",
        },
      ],
    },
    senderLocationID: this.props.locations.length
      ? this.props.locations[0].id
      : 0,
    senderLocation: this.props.locations.length ? this.props.locations[0] : "",
    selectedService: 0,
    rates: [],
  };

  rates = [];

  handleChange = (name) => (event) => {
    this.setObjData(name, "receiver", event.value);
  };

  handleObjChange = (name, objName) => (event) => {
    if (event.target.value.length < 30) {
      if (name === "email" || event.target.value === "") {
        let temp = this.state[objName];
        temp[name] = event.target.value;
        this.setState({ [objName]: temp });
      } else if (name === "fname" || name === "lname") {
        if (isAlphaWithSpace(event.target.value)) {
          let temp = this.state[objName];
          temp[name] = event.target.value;
          this.setState({ [objName]: temp });
        }
      } else if (name === "business") {
        if (isAlphaNumericWithApostrophe(event.target.value)) {
          let temp = this.state[objName];
          temp[name] = event.target.value;
          this.setState({ [objName]: temp });
        }
      } else if (name === "quantity") {
        if (isNumericOnly(event.target.value)) {
          let temp = this.state[objName];
          temp[name] = event.target.value;
          this.setState({ [objName]: temp });
          return;
        }
      } else if (name === "value") {
        if (isDollarValue(event.target.value)) {
          let temp = this.state[objName];
          temp[name] = event.target.value;
          this.setState({ [objName]: temp });
          return;
        }
      } else if (name === "street_name") {
        if (isAlphaNumericWithApostrophe(event.target.value)) {
          let temp = this.state[objName];
          temp[name] = event.target.value;
          this.setState({ [objName]: temp });
          return;
        }
      } else if (isAlphaNumericWithSpace(event.target.value)) {
        if (event.target.value === "") {
          let temp = this.state[objName];
          temp[name] = event.target.value;
          this.setState({ [objName]: temp });
        }
        if (name === "phone" && !isNumericOnly(event.target.value)) {
          return;
        }
        if (name === "zip" && !isAlphaNumeric(event.target.value)) {
          return;
        }
        let temp = this.state[objName];
        temp[name] = event.target.value;
        this.setState({ [objName]: temp });
      }
    }
  };

  // adjustCount = async (val) => {
  //   let temp = this.state.package;
  //   temp.count = parseInt(temp.count) + parseInt(val);
  //   this.setState({ package: temp });
  //   if (val > 0) {
  //     temp.dimensions.push({
  //       length: "",
  //       width: "",
  //       height: "",
  //       weight: "",
  //     });
  //   } else {
  //     temp.dimensions.pop();
  //   }
  //   this.setState({ package: temp });
  // };

  adjustCount = async (val) => {
    let temp = this.state.package;
    temp.count = parseInt(temp.count) + parseInt(val);
    this.setState({ package: temp });
    if (val > 0) {
      temp.dimensions.push({
        weight:
          this.props.account &&
          this.props.account.user &&
          this.props.account.user.fixedPLD
            ? this.props.account.user.fixedPLD.weight
            : "",
        height:
          this.props.account &&
          this.props.account.user &&
          this.props.account.user.fixedPLD
            ? this.props.account.user.fixedPLD.height
            : "",
        length:
          this.props.account &&
          this.props.account.user &&
          this.props.account.user.fixedPLD
            ? this.props.account.user.fixedPLD["length"]
            : "",
        width:
          this.props.account &&
          this.props.account.user &&
          this.props.account.user.fixedPLD
            ? this.props.account.user.fixedPLD.width
            : "",
      });
    } else {
      temp.dimensions.pop();
    }
    this.setState({ package: temp });
  };

  handleDimensionChange = (i, name) => (event) => {
    if (name === "weight") {
      if (
        this.state.package.dimensions.reduce(
          (a, b) => Number(b.weight) + a,
          0
        ) +
          Number(event.target.value) >
        500
      ) {
        this.setState({ weightError: true });
        return;
      }
    }
    if (this.props.account.user.sends_oversized) {
      let copy = this.state.package;
      const arr = [
        Math.ceil(
          name == "length" ? event.target.value : copy.dimensions[i]["length"]
        ),
        Math.ceil(
          name == "width" ? event.target.value : copy.dimensions[i]["width"]
        ),
        Math.ceil(
          name == "height" ? event.target.value : copy.dimensions[i]["height"]
        ),
      ].sort((a, b) => {
        return a > b ? 1 : -1;
      });
      // console.log(arr);
      // console.log((Number(arr[2]) + Number(2 * arr[1]) + Number(2 * arr[0])))
      if (Number(arr[2]) + Number(2 * arr[1]) + Number(2 * arr[0]) > 165) {
        return;
      }
      if (event.target.value > 150) {
        return;
      }
    } else {
      switch (name) {
        case "weight":
          if (Number(event.target.value) > 66) {
            return;
          } else {
            break;
          }
        case "length":
          if (Number(event.target.value) > 45) {
            return;
          } else {
            break;
          }
        case "width":
          if (Number(event.target.value) > 30) {
            return;
          } else {
            break;
          }
        case "height":
          if (Number(event.target.value) > 30) {
            return;
          } else {
            break;
          }
      }
    }
    if (event.target.value === "" || isMeasurement(event.target.value)) {
      let copy = this.state.package;
      copy.dimensions[i][name] = event.target.value;
      this.setState({ package: copy });
    }
  };

  setDimensionChange = (name, i, val) => {
    if (isMeasurement(val)) {
      let copy = this.state.package;
      copy.dimensions[i][name] = val;
      this.setState({ package: copy });
    }
  };

  setData = (name, data) => {
    this.setState({
      [name]: data,
    });
  };

  setObjData = (name, objName, data) => {
    let temp = this.state[objName];
    temp[name] = data;
    this.setState({ [objName]: temp });
  };

  setRates = (name, value) => {
    this.setState({
      [name]: value,
    });
    this.rates = value;
  };

  toggleCheck = (name, objName) => (event) => {
    let temp = this.state[objName];
    temp[name] = event.target.checked;
    this.setState({ [objName]: temp });
  };

  createShipment = async () => {
    this.setState({ isLoading: true });
    // console.log(`mutation createShipment{
    //   ${this.props.editPkg ? `updateV1_Shipment` : `createV1_Shipment`} (input:{
    //     ${this.props.editPkg ? "" : `date_created:"${new Date().toLocaleString()}"`}
    //     ${this.props.editPkg ? `date_updated:"${new Date().toLocaleString()}"` : ""}
    //     ${this.props.editPkg ? "" : `platform:"SWIFTPOST"`}
    //     sender_id:"${this.state.senderLocationID}"
    //     ${this.props.editPkg ? `id:"${this.props.editID}"`:``}
    //     rate:{
    //       ${this.rates[this.state.selectedService].accessorials && (this.rates[this.state.selectedService].accessorials.length > 0) ?
    //         `accessorial_1_name:"${this.rates[this.state.selectedService].accessorials[0].name}"
    //         accessorial_1_charge:"${this.rates[this.state.selectedService].accessorials[0].charge}"` : ""}
    //       ${this.rates[this.state.selectedService].accessorials && (this.rates[this.state.selectedService].accessorials.length > 1) ?
    //         `accessorial_1_name:"${this.rates[this.state.selectedService].accessorials[1].name}"
    //         accessorial_1_charge:"${this.rates[this.state.selectedService].accessorials[1].charge}"` : ""}
    //       ${this.rates[this.state.selectedService].accessorials && (this.rates[this.state.selectedService].accessorials.length > 2) ?
    //         `accessorial_1_name:"${this.rates[this.state.selectedService].accessorials[2].name}"
    //         accessorial_1_charge:"${this.rates[this.state.selectedService].accessorials[2].charge}"` : ""}
    //       ${this.rates[this.state.selectedService].accessorials && (this.rates[this.state.selectedService].accessorials.length > 3) ?
    //         `accessorial_1_name:"${this.rates[this.state.selectedService].accessorials[3].name}"
    //         accessorial_1_charge:"${this.rates[this.state.selectedService].accessorials[3].charge}"` : ""}
    //       amount:"${this.rates[this.state.selectedService].rate}"
    //       subtotal:"${this.rates[this.state.selectedService].subtotal}"
    //       rate_id:"${this.rates[this.state.selectedService].rate_id}"
    //       tax1_code:"${this.rates[this.state.selectedService].tax1_code}"
    //       ${
    //         this.rates[this.state.selectedService].tax2_code
    //           ? `tax2_code:"${
    //               this.rates[this.state.selectedService].tax2_code
    //             }"`
    //           : ``
    //       }
    //       tax1_rate:"${this.rates[this.state.selectedService].tax1_rate}"
    //       ${
    //         this.rates[this.state.selectedService].tax2_rate
    //           ? `tax2_rate:"${
    //               this.rates[this.state.selectedService].tax2_rate
    //             }"`
    //           : ``
    //       }
    //       tax1_amount:"${this.rates[this.state.selectedService].tax1_amount}"
    //       ${
    //         this.rates[this.state.selectedService].tax2_amount
    //           ? `tax2_amount:"${
    //               this.rates[this.state.selectedService].tax2_amount
    //             }"`
    //           : ``
    //       }
    //       ea_charge:"${
    //         this.rates[this.state.selectedService].ea_charge
    //       }"
    //       residential_charge:"${
    //         this.rates[this.state.selectedService].residential_charge
    //       }"
    //       fuel_surcharge:"${
    //         this.rates[this.state.selectedService].fuel_surcharge
    //       }"
    //       rural_charge:"${
    //         this.rates[this.state.selectedService].rural_charge
    //       }"
    //       xc_charge:"${this.rates[this.state.selectedService].xc_charge}"
    //       service:{
    //         name: "${
    //           this.rates[this.state.selectedService].service.service.name
    //         }"
    //         courier_code: "${
    //           this.rates[this.state.selectedService].service.service.rate_type
    //         }"
    //         courier: "${
    //           this.rates[this.state.selectedService].courier
    //         }"
    //       }
    //     }
    //     receiver:{
    //       ${
    //         this.state.receiver.business
    //           ? `title:"${this.state.receiver.business}"`
    //           : ``
    //       }
    //       address:{
    //         street_name:"${this.state.receiver.street_name ? this.state.receiver.street_name : " "}"
    //         street_number:"${this.state.receiver.street_number ? this.state.receiver.street_number : " "}"
    //         postal:"${this.state.receiver.zip}"
    //         state:"${this.state.receiver.state}"
    //         country:"CA"
    //         ${
    //           this.state.receiver.unit &&
    //           this.state.receiver.unit !== null &&
    //           this.state.receiver.unit !== "null" &&
    //           this.state.receiver.unit !== undefined &&
    //           this.state.receiver.unit !== "undefined"
    //             ? `unit:"${this.state.receiver.unit}"`
    //             : ``
    //         }
    //         city:"${this.state.receiver.city}"
    //       }
    //       contact: {
    //         fname:"${this.state.receiver.fname}"
    //         lname:"${this.state.receiver.lname}"
    //         ${
    //           this.state.receiver.email
    //             ? `email:"${this.state.receiver.email}"`
    //             : ``
    //         }
    //         ${
    //           this.state.receiver.phone
    //             ? `phone:"${this.state.receiver.phone}"`
    //             : ``
    //         }
    //       }
    //     }
    //     ${
    //       this.state.package.description
    //         ? `contents:{
    //       title: "${this.state.package.description}"
    //       item_quantity: "${this.state.package.quantity}"
    //       item_value: "${this.state.package.value}"
    //       origin: "${this.state.package.origin}"
    //     }`
    //         : ``
    //     }
    //     sender:{
    //       title:"${this.state.senderLocation.title}"
    //       address:{
    //         street_name:"${this.state.senderLocation.address.street_name}"
    //         street_number:"${this.state.senderLocation.address.street_number}"
    //         postal:"${this.state.senderLocation.address.postal}"
    //         state:"${this.state.senderLocation.address.state}"
    //         country:"${this.state.senderLocation.address.country}"
    //         ${
    //           this.state.senderLocation.address.unit &&
    //           this.state.senderLocation.address.unit !== null &&
    //           this.state.senderLocation.address.unit !== "null" &&
    //           this.state.senderLocation.address.unit !== undefined &&
    //           this.state.senderLocation.address.unit !== "undefined"
    //             ? `unit:"${this.state.senderLocation.address.unit}"`
    //             : ``
    //         }
    //         city:"${this.state.senderLocation.address.city}"
    //       }
    //       contact: {
    //         fname:"${this.state.senderLocation.contact.fname}"
    //         lname:"${this.state.senderLocation.contact.lname}"
    //         ${
    //           this.state.senderLocation.address.email ||
    //           this.state.senderLocation.address.email === null ||
    //           this.state.senderLocation.address.email === "null"
    //             ? `email:"${this.state.senderLocation.contact.email}"`
    //             : ``
    //         }
    //         ${
    //           this.state.senderLocation.contact.phone
    //             ? `phone:"${this.state.senderLocation.contact.phone}"`
    //             : ``
    //         }
    //       }
    //     }
    //     receiver_invalid:"false"
    //     residential: "${this.state.receiver.is_residential}"
    //     package: {
    //       entered_pld:[${this.state.package.dimensions
    //         .map(e => {
    //           return `{
    //         width:"${e.width}"
    //         height:"${e.height}"
    //         length:"${e.length}"
    //         weight:"${e.weight}"
    //       }`;
    //         })
    //         .join(",")}]
    //     }
    //     ${this.state.package.ref ? `reference:"${this.state.package.ref}"` : `reference:"${new Date().getTime()}"`}
    //   }){
    //     id
    //     cognitoUsername
    //   }
    // }`)
    await API.graphql(
      graphqlOperation(`mutation createShipment{
        ${
          this.props.editPkg ? `updateV1_Shipment` : `createV1_Shipment`
        } (input:{
          ${
            this.props.editPkg
              ? ""
              : `date_created:"${new Date().toLocaleString()}"`
          }
          ${
            this.props.editPkg
              ? `date_updated:"${new Date().toLocaleString()}"`
              : ""
          }
          ${this.props.editPkg ? "" : `platform:"SWIFTPOST"`}
          sender_id:"${this.state.senderLocationID}"
          ${this.props.editPkg ? `id:"${this.props.editID}"` : ``}
          rate:{
            ${
              this.rates[this.state.selectedService].accessorials &&
              this.rates[this.state.selectedService].accessorials.length > 0
                ? `accessorial_1_name:"${
                    this.rates[this.state.selectedService].accessorials[0].name
                  }"
              accessorial_1_charge:"${
                this.rates[this.state.selectedService].accessorials[0].charge
              }"`
                : ""
            }
            ${
              this.rates[this.state.selectedService].accessorials &&
              this.rates[this.state.selectedService].accessorials.length > 1
                ? `accessorial_1_name:"${
                    this.rates[this.state.selectedService].accessorials[1].name
                  }"
              accessorial_1_charge:"${
                this.rates[this.state.selectedService].accessorials[1].charge
              }"`
                : ""
            }
            ${
              this.rates[this.state.selectedService].accessorials &&
              this.rates[this.state.selectedService].accessorials.length > 2
                ? `accessorial_1_name:"${
                    this.rates[this.state.selectedService].accessorials[2].name
                  }"
              accessorial_1_charge:"${
                this.rates[this.state.selectedService].accessorials[2].charge
              }"`
                : ""
            }
            ${
              this.rates[this.state.selectedService].accessorials &&
              this.rates[this.state.selectedService].accessorials.length > 3
                ? `accessorial_1_name:"${
                    this.rates[this.state.selectedService].accessorials[3].name
                  }"
              accessorial_1_charge:"${
                this.rates[this.state.selectedService].accessorials[3].charge
              }"`
                : ""
            }
            amount:"${this.rates[this.state.selectedService].rate}"
            subtotal:"${this.rates[this.state.selectedService].subtotal}"
            rate_id:"${this.rates[this.state.selectedService].rate_id}"
            tax1_code:"${this.rates[this.state.selectedService].tax1_code}"
            ${
              this.rates[this.state.selectedService].tax2_code
                ? `tax2_code:"${
                    this.rates[this.state.selectedService].tax2_code
                  }"`
                : ``
            }
            tax1_rate:"${this.rates[this.state.selectedService].tax1_rate}"
            ${
              this.rates[this.state.selectedService].tax2_rate
                ? `tax2_rate:"${
                    this.rates[this.state.selectedService].tax2_rate
                  }"`
                : ``
            }
            tax1_amount:"${this.rates[this.state.selectedService].tax1_amount}"
            ${
              this.rates[this.state.selectedService].tax2_amount
                ? `tax2_amount:"${
                    this.rates[this.state.selectedService].tax2_amount
                  }"`
                : ``
            }
            ea_charge:"${this.rates[this.state.selectedService].ea_charge}"
            residential_charge:"${
              this.rates[this.state.selectedService].residential_charge
            }"
            fuel_surcharge:"${
              this.rates[this.state.selectedService].fuel_surcharge
            }"
            rural_charge:"${
              this.rates[this.state.selectedService].rural_charge
            }"
            xc_charge:"${this.rates[this.state.selectedService].xc_charge}"
            service:{
              name: "${
                this.rates[this.state.selectedService].service.service.name
              }"
              courier_code: "${
                this.rates[this.state.selectedService].service.service.rate_type
              }"
              courier: "${this.rates[this.state.selectedService].courier}"
            }
          }
          receiver:{
            ${
              this.state.receiver.business
                ? `title:"${this.state.receiver.business}"`
                : ``
            }
            address:{
              street_name:"${
                this.state.receiver.street_name
                  ? this.state.receiver.street_name
                  : " "
              }"
              street_number:"${
                this.state.receiver.street_number
                  ? this.state.receiver.street_number
                  : " "
              }"
              postal:"${this.state.receiver.zip}"
              state:"${this.state.receiver.state}"
              country:"CA"
              ${
                this.state.receiver.unit &&
                this.state.receiver.unit !== null &&
                this.state.receiver.unit !== "null" &&
                this.state.receiver.unit !== undefined &&
                this.state.receiver.unit !== "undefined"
                  ? `unit:"${this.state.receiver.unit}"`
                  : ``
              }
              city:"${this.state.receiver.city}"
            }
            contact: {
              fname:"${this.state.receiver.fname}"
              lname:"${this.state.receiver.lname}"
              ${
                this.state.receiver.email
                  ? `email:"${this.state.receiver.email}"`
                  : ``
              }
              ${
                this.state.receiver.phone
                  ? `phone:"${this.state.receiver.phone}"`
                  : ``
              }
            }
          }
          ${
            this.state.package.description
              ? `contents:{
            title: "${this.state.package.description}"
            item_quantity: "${this.state.package.quantity}"
            item_value: "${this.state.package.value}"
            origin: "${this.state.package.origin}"
          }`
              : ``
          }
          sender:{
            title:"${this.state.senderLocation.title}"
            address:{
              street_name:"${this.state.senderLocation.address.street_name}"
              street_number:"${this.state.senderLocation.address.street_number}"
              postal:"${this.state.senderLocation.address.postal}"
              state:"${this.state.senderLocation.address.state}"
              country:"${this.state.senderLocation.address.country}"
              ${
                this.state.senderLocation.address.unit &&
                this.state.senderLocation.address.unit !== null &&
                this.state.senderLocation.address.unit !== "null" &&
                this.state.senderLocation.address.unit !== undefined &&
                this.state.senderLocation.address.unit !== "undefined"
                  ? `unit:"${this.state.senderLocation.address.unit}"`
                  : ``
              }
              city:"${this.state.senderLocation.address.city}"
            }
            contact: {
              fname:"${this.state.senderLocation.contact.fname}"
              lname:"${this.state.senderLocation.contact.lname}"
              ${
                this.state.senderLocation.address.email ||
                this.state.senderLocation.address.email === null ||
                this.state.senderLocation.address.email === "null"
                  ? `email:"${this.state.senderLocation.contact.email}"`
                  : ``
              }
              ${
                this.state.senderLocation.contact.phone
                  ? `phone:"${this.state.senderLocation.contact.phone}"`
                  : ``
              }
            }
          }
          receiver_invalid:"false"
          residential: "${this.state.receiver.is_residential}"
          package: {
            entered_pld:[${this.state.package.dimensions
              .map((e) => {
                return `{
              width:"${e.width}"
              height:"${e.height}"
              length:"${e.length}"
              weight:"${e.weight}"
            }`;
              })
              .join(",")}]
          }
          ${
            this.state.package.ref
              ? `reference:"${this.state.package.ref}"`
              : `reference:"${new Date().getTime()}"`
          }
        }){
          id
          cognitoUsername
        }
      }`)
    ).catch((e) => {
      // console.log(e)
      this.setState({ isLoading: false });
    });
  };

  handleZipChange = (name) => (event) => {
    if (isAlphaNumeric(event.target.value) || event.target.value === "") {
      this.setObjData(name, "receiver", event.target.value.toUpperCase());
      if (event.target.value === "") {
        this.setObjData("state", "receiver", "");
        this.setObjData("city", "receiver", "");
      }
      if (isValidPostal(event.target.value)) {
        this.setState({ isLoading: true });
        this.setObjData("street_name", "receiver", "");
        this.setObjData("street_number", "receiver", "");

        // console.log({
        //   body: {
        //     postal: this.state.receiver.zip,
        //     country: "CA"
        //   }
        // })

        API.post("sqs", "/address/verify", {
          body: {
            postal: this.state.receiver.zip,
            country: "CA",
          },
        })
          .then((e) => {
            // alert(e.is_residential)
            // console.log(e);
            this.setObjData("is_residential", "receiver", e.is_residential);
            this.setState({ zipError: false });
            this.setState({ isLoading: false });
            this.setObjData("state", "receiver", e.state);
            this.setObjData("city", "receiver", e.city);
            this.setObjData("zip", "receiver", e.postal);
            this.setState({
              response: Array.from(new Set(e.street_name)).map((f) => {
                return { value: f, label: f };
              }),
              streetArr: Array.from(new Set(e.street_name)),
            });
            // this.setObjData(
            //   "street_name",
            //   "receiver",
            //   Array.from(new Set(e.street_name))[0]
            // );
            this.setState({ searchDone: true });
            this.handleStep1Next();
          })
          .catch((e) => {
            // console.log(e);
            this.setState({ isLoading: false });
            this.setState({ zipError: true });
          });
      } else {
        this.setState({ isLoading: false });
        this.setState({ zipError: true });
      }
    }
  };

  handleStep1Next = () => {
    if (this.state.oneStep === 1) {
      // if(PO_BOX_REGEX.test(`${this.state.receiver.street_number} ${
      //   this.state.receiver.street_name
      // }`)){
      //   this.setState({poBoxWarning:true});
      //   this.setState({ addressError: true });
      //   return;
      // }
      if (
        this.state.receiver.street_name === "" ||
        this.state.receiver.street_number === ""
      ) {
        this.setState({ addressError: true });
        return;
      }
      this.setObjData(
        "address",
        "receiver",
        `${this.state.receiver.street_number} ${this.state.receiver.street_name}`
      );

      this.setState({ addressError: false });
      this.setState({ zipError: false });

      this.setState((state) => ({
        oneStep: state.oneStep + 1,
      }));
    } else if (this.state.oneStep === 2) {
      if (!isAlphaNumericWithSpace(this.state.receiver.address)) {
        this.setState({ addressError: true });
        return;
      }
      // this.setObjData("verified", "receiver", true);
      this.setState((state) => ({
        oneStep: state.oneStep + 1,
      }));
    } else if (this.state.oneStep === 3) {
      this.handleNext("activeStep");
    } else {
      this.setState((state) => ({
        oneStep: state.oneStep + 1,
      }));
    }
  };

  handleLocationChange = (e) => {
    // console.log(e)
    this.setState({ senderLocation: e.location });
    this.setState({ senderLocationID: e.id });
  };

  getStepContent = (stepIndex) => {
    const { classes, ...childProps } = this.props;

    // console.log(this.props);

    switch (stepIndex) {
      case 0:
        return (
          <CANStep1
            {...this.state}
            previousData={this.state.oldReceiver}
            streetArr={this.state.streetArr}
            handleNext={() => this.handleNext("oneStep")}
            handleBack={(e) => this.handleBack(e)}
            activeStep={this.state.oneStep}
            advance={() => this.handleNext("activeStep")}
            handleStep1Next={this.handleStep1Next}
            handleZipChange={(zip) => this.handleZipChange(zip)}
            handleChange={(name) => this.handleChange(name)}
            setData={(name, data) => this.setData(name, data)}
            setObjData={(name, objName, data) =>
              this.setObjData(name, objName, data)
            }
            handleObjChange={(name, objName) =>
              this.handleObjChange(name, objName)
            }
          />
        );
      case 1:
        return (
          <CANStep2
            account={this.props.account}
            handleLocationChange={(e) => this.handleLocationChange(e)}
            handleBack={(e) => {
              this.handleBack(e);
            }}
            handleNext={(e) => {
              this.handleNext(e);
            }}
            locations={this.props.locations}
            {...this.state}
            {...childProps}
          />
        );
      case 2:
        return (
          <CANStep3
            account={this.props.account}
            setData={(name, data) => this.setData(name, data)}
            activeStep={this.state.twoStep}
            handleChange={(name) => this.handleChange(name)}
            handleBack={(e) => {
              this.handleBack(e);
            }}
            handleNext={(e) => {
              this.handleNext(e);
            }}
            setRates={(e, i) => {
              this.setRates(e, i);
            }}
            setObjData={(name, objName, data) =>
              this.setObjData(name, objName, data)
            }
            handleObjChange={(name, objName) =>
              this.handleObjChange(name, objName)
            }
            sender={this.state.senderLocation}
            toggleCheck={(name, objName) => {
              this.toggleCheck(name, objName);
            }}
            handleDimensionChange={(i, name) =>
              this.handleDimensionChange(i, name)
            }
            setDimensionChange={(name, i, val) =>
              this.setDimensionChange(name, i, val)
            }
            adjustCount={(val) => {
              this.adjustCount(val);
            }}
            getRates={this.props.getRates}
            {...this.state}
            {...childProps}
          />
        );
      case 3:
        return (
          <CANStep4
            setData={(name, data) => this.setData(name, data)}
            setObjData={(name, objName, data) =>
              this.setObjData(name, objName, data)
            }
            handleObjChange={(name, objName) =>
              this.handleObjChange(name, objName)
            }
            handleBack={(e) => {
              this.handleBack(e);
            }}
            handleNext={(e) => {
              this.handleNext(e);
            }}
            {...this.state}
            {...childProps}
          />
        );
      case 4:
        return (
          <CANStep5
            {...this.state}
            {...childProps}
            handleGoToStep={(e) => this.handleGoToStep(e)}
            handleBack={(e) => {
              this.handleBack(e);
            }}
            handleNext={(e) => {
              this.handleNext(e);
            }}
            createShipment={() => {
              this.createShipment();
            }}
          />
        );
      default:
        return "Uknown stepIndex";
    }
  };

  handleNext = (stepper) => {
    const activeStep = this.state[stepper];
    this.setState({
      [stepper]: activeStep + 1,
    });
  };

  handleBack = (stepper) => {
    const activeStep = this.state[stepper];
    // console.log(activeStep)
    this.setState({
      [stepper]: activeStep - 1,
    });
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  handleGoToStep = async (step) => {
    if (step === 3 && this.state.rates.length === 0) {
      // if(this.state.rates.length === 0){
      this.setState({
        activeStep: 2,
        twoStep: 2,
      });
      // } else{

      // }
      // let sample = this.props.locations.filter(
      //   e => e.id === this.state.senderLocationID
      // );
      // sample = sample[0];
      // let test = await resolver(this.state);
      // await getRates(
      //   {
      //     ...this.state,
      //     sender: sample
      //   },
      //   test,
      //   async e => {
      //     this.setState({ isLoading: false });
      //     this.setRates(
      //       "rates",
      //       e.response.filter(f => {
      //         return f.rate != 0;
      //       })
      //     );
      //     this.setState({
      //       activeStep: step
      //     });
      //   }
      // );
    } else {
      this.setState({
        activeStep: step,
      });
    }
  };

  render() {
    const { classes } = this.props;
    const steps = this.getSteps();
    const { activeStep } = this.state;

    return (
      <Fragment>
        <UnconfirmedAddress
          open={this.state.modalOpen}
          onClose={() => this.setState({ modalOpen: false })}
          modalContinue={() => {
            this.setState((state) => ({
              oneStep: state.oneStep + 1,
            }));
            this.setState({ modalOpen: false });
          }}
        />
        <SnackBar
          variant="error"
          action={[
            <Button
              style={{ color: "#ffffff" }}
              size="small"
              onClick={() => this.setState({ snackOpen: false })}
            >
              I understand
            </Button>,
          ]}
          duration="60000"
          open={this.state.snackOpen}
          message={
            "We were unable to find that address. Please confirm that the address is correct. Address corrections can incur additional fees as laid out in our Terms and Services."
          }
          handleClose={() => {
            this.setState({ snackOpen: false });
          }}
        />

        <SnackBar
          variant="error"
          duration="5000"
          open={this.state.rateError}
          message={
            "There was an issue getting rates for the provided shipment. Please confirm the pickup and destination address are valid."
          }
          handleClose={() => {
            this.setState({ rateError: false });
          }}
        />

        <SnackBar
          variant="error"
          duration="5000"
          open={this.state.weightError}
          message={"Total weight of all shipments cannot exceed 500lb."}
          handleClose={() => {
            this.setState({ weightError: false });
          }}
        />

        <SnackBar
          variant="error"
          duration="5000"
          open={this.state.poBoxWarning}
          message={"Your account is unable to ship to PO boxes in Canada."}
          handleClose={() => {
            this.setState({ poBoxWarning: false });
          }}
        />

        <Hidden smDown={false} mdUp={true}>
          <Typography className={"stepper"} variant={"strong"}>
            {steps[activeStep]}
          </Typography>
        </Hidden>

        <Hidden smDown={false} mdUp={true}>
          <MobileStepper
            variant="dots"
            steps={steps.length}
            position="static"
            activeStep={this.state.activeStep}
            classes={{
              root: classes.root, // class name, e.g. `classes-nesting-root-x`
              dots: classes.dots, // class name, e.g. `classes-nesting-label-x`
            }}
            // className={classes.dots}
            style={{ width: "100%", margin: "auto" }}
          />
        </Hidden>

        <Hidden smDown={true}>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            style={{ background: "transparent", width: "100%" }}
          >
            {steps.map((label) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Hidden>

        <div style={{ maxWidth: "900px", width: "100%" }}>
          {this.getStepContent(activeStep)}
          {this.state.activeStep === 100 && (
            <div>
              <br />
              <br />
              <Button
                disabled={activeStep === 0}
                onClick={() => this.handleBack("activeStep")}
                className={classes.backButton}
              >
                {this.language.Back[this.state.language]}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.handleNext("activeStep")}
              >
                {activeStep === steps.length - 1
                  ? this.language.Finish[this.state.language]
                  : this.language.Next[this.state.language]}
              </Button>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

HorizontalLabelPositionBelowStepper.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(HorizontalLabelPositionBelowStepper);
