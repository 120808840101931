import { API } from "aws-amplify";

var try_count = 0;

const childReport = (start_date, end_date, cognitoSub) =>
  new Promise((resolve, reject) => {
    let body = {
      start_date,
      end_date,
      cognitoSub,
    };

    console.log(body);

    API.post("child", "/children/report", {
      body,
    })
      .then((e) => {
        console.log(e);
        resolve(e);
      })
      .catch((e) => {
        reject(false);
      });
  });

export default childReport;
