import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { API, graphqlOperation } from "aws-amplify";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import React from "react";
// import { removeDuplicatesFromArray } from '../helpers/TaskRunners';
import uuid from "uuid";
import {
  isAlphaNumericWithApostrophe,
  isAlphaNumericWithSpace,
  isNotEmpty,
  isNumericOnly,
  isValidPostal,
} from "../helpers/Validation";
//snackbars
import SnackBar from "./Snackbar";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    padding: 0,
  },
  formControl: {
    // marginRight: '5%',
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    // width: '45%',
    // maxWidth: '50%',
  },
  input: {
    display: "none",
  },
});

class FormDialog extends React.Component {
  state = {
    isLoading: false,
    open: false,
    alias: "",
    postal: "",
    searchDone: false,
    address: "",
    street: "",
    province: "",
    unit: "",
    fname: "",
    lname: "",
    logo: "",
    url: "",
    verified: false,
    response: [],
    snackMessage: "",
    snackOpen: false,
    snackVariant: "error",
    formError: false,
    coords: {},
    errors: {},
    language: "EN",
  };

  handleChange = (name) => (event) => {
    // alert(name)
    // alert(event.target.value)
    this.setState({
      [name]: event.target.value,
    });
  };

  UNSAFE_componentWillMount = () => {
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });
  };

  language = {
    Warning: {
      FR: (
        <span>
          En appuyant sur <em>Continuer malgré tout</em>, vous reconnaissez et
          acceptez tous les frais supplémentaires pour les changements
          d'adresse, comme indiqué dans notre{" "}
          <a href="https://www.swiftpost.com/terms-of-use/" target="_new">
            Termes et conditions
          </a>
          .<br />
          <br />
        </span>
      ),
      EN: (
        <span>
          By pressing <em>Continue Anyway</em> you acknowledge and accept any
          additional charge for address changes, as laid out in our{" "}
          <a href="https://www.swiftpost.com/terms-of-use/" target="_new">
            Terms and Conditions
          </a>
          .<br />
          <br />
        </span>
      ),
    },
    InvalidPostalCode: {
      FR: "Code postal invalide",
      EN: "Invalid Postal Code",
    },
    PostalCodeCouldNotFound: {
      FR: "Le code postal est introuvable",
      EN: "Postal Code could not be found",
    },
    AddressCouldNotBeValidated: {
      FR: "L'adresse n'a pas pu être validée",
      EN: "Address could not be validated",
    },
    AddressSuccessfullyAdded: {
      FR: "Adresse ajoutée avec succès",
      EN: "Address successfully added",
    },
    LocationName: {
      FR: "Nom de famille",
      EN: "LocationName",
    },
    StreetNumber: {
      FR: "Numéro de rue",
      EN: "Street Number",
    },
    StreetName: {
      FR: "Nom de rue",
      EN: "Street Name",
    },
    UnitApt: {
      FR: "Unité/Appartemant",
      EN: "Unit/Apt #",
    },
    City: {
      FR: "Ville",
      EN: "City",
    },
    PostalCode: {
      FR: "Code Postale",
      EN: "Postal Code",
    },
    Province: {
      FR: "Province",
      EN: "Province",
    },
    Cancel: {
      FR: "Annuler",
      EN: "Cancel",
    },
    AddLocation: {
      FR: "Ajouter l'emplacement",
      EN: "Add Location",
    },
    Loading: {
      FR: "Attendre",
      EN: "Loading",
    },
    Results: {
      FR: "Résultats",
      EN: "Results",
    },
    WeWereUnableToLocateTheProvidedAddressBelow: {
      FR: "Impossible de trouver l'adresse ci-dessous",
      EN: "We were unable to locate the provided address below",
    },
    GoBack: {
      FR: "Derrière",
      EN: "GoBack",
    },
    ContinueAnyway: {
      FR: "Continuer",
      EN: "Continue Anyway",
    },
    FindAddress: {
      FR: "Trouvez l'addresse",
      EN: "Find Address",
    },
    EditAddress: {
      FR: "Trouvez l'addresse",
      EN: "Edit Address",
    },
    StartByEntering: {
      FR: "Commencez par entrer le code postal, nous essaierons de trouver l'adresse pour vous.",
      EN: "Start by entering the postal code, we will try to find the address for you.",
    },
    UnableToProcessImageMayBeTooLarge: {
      FR: "Image de vérification d'erreur",
      EN: "Unable to process, image may be too large.",
    },
    UnableToProcessSelectedImage: {
      FR: "Image de vérification d'erreur",
      EN: "Unable to process selected image.",
    },
    RequiredFieldsAreMissing: {
      FR: "Éléments obligatoires manquants",
      EN: "Required fields are missing",
    },
    Search: {
      FR: "Rechercher",
      EN: "Search",
    },
    ResultsFromSearch: {
      FR: "Résultats de la recherche",
      EN: "Results from search",
    },
    LocationOrBusinessName: {
      FR: "Emplacement ou Nom du Commerce",
      EN: "Location or Business Name",
    },
    UploadLogo: {
      FR: "Télécharger le logo",
      EN: "Upload Logo",
    },
    FirstName: {
      FR: "Prénom",
      EN: "First Name",
    },
    LastName: {
      FR: "Nom de famille",
      EN: "Last Name",
    },
    Processing: {
      FR: "En traitement...",
      EN: "Processing...",
    },
    RemoveLogo: {
      FR: "Supprimer",
      EN: "Remove logo",
    },
    ProvideALogo: {
      FR: "Fournir un logo",
      EN: "Provide a Logo",
    },
  };

  handlePostalInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value.toUpperCase(),
    });
  };

  handleSelectChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleStreetSelect = (name) => (value) => {
    this.setState({
      [name]: value.value,
    });
  };

  handleClose = async () => {
    this.setState({ alias: "" });
    this.setState({ postal: "" });
    this.setState({ address: "" });
    this.setState({ street: "" });
    this.setState({ unit: "" });
    this.setState({ fname: "" });
    this.setState({ lname: "" });
    this.setState({ url: "" });
    await this.props.handleClose();
    this.setState({ searchDone: false });
  };

  validateForm = () => {
    return isValidPostal(this.state.postal) && isNotEmpty(this.state.postal);
  };

  handleSubmit = async () => {
    if (!this.validateForm()) {
      this.handleClose();
      this.setState({ snackVariant: "error" });
      this.setState({
        snackMessage: this.language.InvalidPostalCode[this.state.language],
      });
      this.setState({ snackOpen: true });
      return;
    }
    await this.setState({ postal: this.state.postal.replace(" ", "") });
    await this.searchPostal(this.state.postal);
  };

  searchPostal() {
    // alert('trying new search')
    return new Promise(async (res, rej) => {
      await this.setState({ isLoading: true });
      API.post("sqs", "/address/verify", {
        body: {
          postal: this.state.postal,
          country: "CA",
        },
      })
        .then(async (e) => {
          // console.log(e)
          // alert("success")
          await this.setState({ isLoading: false });
          await this.setState({ postal: e.postal });
          await this.setState({ city: e.city });
          await this.setState({ province: e.state });
          this.setState({
            response: Array.from(new Set(e.street_name)).map((f) => {
              return { value: f, label: f };
            }),
            streetArr: Array.from(new Set(e.street_name)),
          });
          // if(e.street_name.length === 1){
          // console.log({ street: Array.from(new Set(e.street_name))[0] })
          // this.setState({ street: Array.from(new Set(e.street_name))[0] })
          // this.setState({ street: Array.from(new Set(e.street_name))[0] })

          this.setState({ searchDone: true });
          res(true);
        })
        .catch(async (e) => {
          // console.log(e)
          // alert("failure")
          this.setState({ isLoading: false });
          this.handleClose();
          this.setState({ snackVariant: "error" });
          this.setState({
            snackMessage:
              this.language.PostalCodeCouldNotFound[this.state.language],
          });
          this.setState({ snackOpen: true });
          rej(false);
        });
    });
  }

  getCoords = (postal) => {
    return new Promise((res, rej) => {
      var request = new XMLHttpRequest();
      request.onreadystatechange = function () {
        if (request.readyState == 4 && request.status == 200) {
          if (JSON.parse(request.responseText).results.length > 0) {
            let coords = JSON.parse(request.responseText).results[0].geometry
              .viewport.northeast;
            // console.log(coords)
            // console.log({lng: JSON.parse(request.responseText).results[0].geometry.viewport.northeast.lat, lat: request.responseText.results[0].geometry.viewport.northeast.lat})
            res({ lng: coords.lng, lat: coords.lat });
          } else {
            res(false);
            // rej(true)
          }
        }
      };
      request.open(
        "GET",
        `https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyBNMRsO80tsOGxVUIJbrDKw0DuUSvFrIhc&address=${
          this.state.street
        }+${this.state.address},+${this.state.city},+${
          this.state.province
        },+${this.state.postal.split(" ").join("")}`
      );
      request.send();
    });
  };

  formCheck = () => {
    this.setState({
      aliasError: !isNotEmpty(this.state.alias),
      fnameError: !isNotEmpty(this.state.fname),
      lnameError: !isNotEmpty(this.state.lname),
      addressError: !isNotEmpty(this.state.address),
      streetError: !isNotEmpty(this.state.street),
    });
    return (
      isNotEmpty(this.state.alias) &&
      isNotEmpty(this.state.address) &&
      isNotEmpty(this.state.street) &&
      isNotEmpty(this.state.city) &&
      isNotEmpty(this.state.province) &&
      isNotEmpty(this.state.postal) &&
      isNotEmpty(this.state.fname) &&
      isNotEmpty(this.state.lname)
    );
  };

  addressPreConfirm = () => {
    //testing has shown that canadapost/canpar does not verify properly if the street number is over 9 characters or contains a letter
    return this.state.address.length <= 9 && isNumericOnly(this.state.address);
  };

  confirmAddress = async () => {
    this.setState({ isLoading: true });
    // console.log(this.state);

    if (!this.formCheck()) {
      this.setState({ isLoading: false });
      this.setState({ snackVariant: "error" });
      this.setState({
        snackMessage:
          this.language.RequiredFieldsAreMissing[this.state.language],
      });
      this.setState({ snackOpen: true });
      return;
    }

    //this is used to catch known bad addresses without posting to the endpoint
    if (!this.addressPreConfirm()) {
      // console.log('failed preflight');
      this.setState({ isLoading: false });
      this.setState({ formError: true });
      this.setState({ snackVariant: "error" });
      this.setState({
        snackMessage:
          this.language.AddressCouldNotBeValidated[this.state.language],
      });
      let errors = { address: true, street: true };
      this.setState({ errors });
      this.setState({ snackOpen: true });
      return;
    }

    // console.log({body: {
    //   postal: this.state.postal,
    //   province: this.state.province,
    //   address: `${this.state.address} ${this.state.street}`,
    //   city: this.state.city,
    // }})

    API.post("sqs", "/address/verify", {
      body: {
        country: "CA",
        postal: this.state.postal.trim(),
        province: this.state.province,
        address: `${this.state.address.trim()} ${this.state.street.trim()}`,
        city: this.state.city.trim(),
      },
    })
      .then(async (e) => {
        this.setState({ verified: true });
        // await this.props.finalAction(this.state);
        this.createLocation();
        this.setState({ isLoading: false });
        this.handleClose();
        this.setState({ snackVariant: "success" });
        this.setState({
          snackMessage:
            this.language.AddressSuccessfullyAdded[this.state.language],
        });
        this.setState({ snackOpen: true });
      })
      .catch((e) => {
        // console.log(e)
        this.setState({ isLoading: false });
        this.setState({ formError: true });
        this.setState({ snackVariant: "error" });
        this.setState({
          snackMessage:
            this.language.AddressCouldNotBeValidated[this.state.language],
        });
        this.setState({ snackOpen: true });
      });

    let coords = { lat: 0, lng: 0 };
    coords = await this.getCoords(this.state.address.postal);
    if (coords === false) {
      this.setState({ isLoading: false });
      let errors = { zip: true, address: true, city: true, state: true };
      this.setState({ errors });
      return;
    }
    this.setState({ coords });
  };

  handleAlphaNumeric = (event) => {
    if (
      isAlphaNumericWithSpace(event.target.value) ||
      event.target.value === ""
    ) {
      this.setState({
        [event.target.id]: event.target.value,
      });
    }
  };

  handleAlphaNumericApostrophe = (name) => (event) => {
    if (
      isAlphaNumericWithApostrophe(event.target.value) ||
      event.target.value === ""
    ) {
      this.setState({
        [name]: event.target.value,
      });
    }
  };

  createLocation = async () => {
    let location = await API.graphql(
      graphqlOperation(`mutation {
      createV1_Location(input:{
        title:"${this.state.alias}"
        address:{
          street_name:"${this.state.street.trim()}"
          street_number:"${this.state.address.trim()}"
          postal:"${this.state.postal.trim()}"
          state:"${this.state.province.trim()}"
          country:"CA"
          ${this.state.unit != "" ? `unit:"${this.state.unit}"` : ``}
          city:"${this.state.city}"
          coords_lat:"${this.state.coords.lat}"
          coords_long:"${this.state.coords.lng}"
        }
        contact: {
          fname:"${this.state.fname.trim()}"
          lname:"${this.state.lname.trim()}"
        } 
        ${this.state.url != "" ? `url:"${this.state.url}"` : ``}
      }){
        id
        cognitoUsername
      }
    }`)
    );
    this.handleClose();
  };

  renderProvinces() {
    let provinces = [
      "AB",
      "BC",
      "MB",
      "NB",
      "NS",
      "NL",
      "SK",
      "ON",
      "PE",
      "QC",
    ];
    provinces.sort();

    return [{}].concat(provinces).map((item, i) => {
      if (Object.keys(item).length === 0 && item.constructor === Object) return;
      return <MenuItem value={item}>{item}</MenuItem>;
    });
  }

  getBase64 = (e) => {
    if (e.target.files && e.target.files[0]) {
      var FR = new FileReader();

      this.setState({ isLoading: true });

      FR.addEventListener("load", async (e) => {
        // console.log(e)
        // this.setState({logo: e.target.result.replace(/^data*.*base64,/gm,'')});

        API.post("sqs", "/logo", {
          body: {
            logo: e.target.result.replace(/^data*.*base64,/gm, ""),
          },
        })
          .then((e) => {
            if (e != false) {
              this.setState({ url: e.url });
              this.setState({ isLoading: false });
              this.setState({ error: "" });
            } else {
              this.setState({ url: "" });
              this.setState({
                error:
                  this.language.UnableToProcessSelectedImage[
                    this.state.language
                  ],
              });
              this.setState({ isLoading: false });
            }
          })
          .catch((e) => {
            this.setState({ url: "" });
            this.setState({
              error:
                this.language.UnableToProcessImageMayBeTooLarge[
                  this.state.language
                ],
            });
            this.setState({ isLoading: false });
          });
        // resizeLogo(e.target.result.replace(/^data*.*base64,/gm,''), e => {
        //   if(e != false){
        //     this.setState({logo: e.logo})
        //     this.setState({url: e.url})
        //     this.setState({isLoading: false});
        //   } else{
        //     this.setState({error: 'Unable to process selected image'})
        //     this.setState({isLoading: false});

        //   }
        // })
      });

      FR.readAsDataURL(e.target.files[0]);
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        {!this.state.searchDone && !this.state.formError && (
          <Dialog open={this.props.open} aria-labelledby="form-dialog-title">
            <div>
              <DialogTitle id="form-dialog-title">
                {this.language.FindAddress[this.state.language]}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {this.language.StartByEntering[this.state.language]}
                </DialogContentText>
                <FormControl className={classes.formControl}>
                  <TextField
                    inputProps={{
                      type: "text",
                      autoComplete: "off",
                      autoCorrect: "off",
                      autoCapitalize: "none",
                      spellCheck: "false",
                    }}
                    disabled={this.state.isLoading}
                    autoFocus
                    margin="dense"
                    id={uuid.v4()}
                    name="postal"
                    label={this.language.PostalCode[this.state.language]}
                    type="text"
                    fullWidth
                    key={0}
                    value={this.state.postal}
                    onChange={this.handlePostalInput}
                  />
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button
                  disabled={this.state.isLoading}
                  onClick={this.handleClose}
                  color="primary"
                >
                  {this.language.Cancel[this.state.language]}
                </Button>
                <Button
                  disabled={this.state.isLoading}
                  onClick={this.handleSubmit}
                  color="primary"
                >
                  {!this.state.isLoading
                    ? this.language.Search[this.state.language]
                    : this.language.Loading[this.state.language]}
                </Button>
              </DialogActions>
            </div>
          </Dialog>
        )}
        {this.state.searchDone && !this.state.formError && (
          <Dialog open={this.props.open} aria-labelledby="form-dialog-title">
            <div>
              <SnackBar
                style={{ zIndex: 999999, position: "fixed" }}
                variant={this.state.snackVariant}
                open={this.state.snackOpen}
                message={this.state.snackMessage}
                handleClose={() => {
                  this.setState({ snackOpen: false });
                }}
              />
              <DialogTitle id="form-dialog-title">
                {this.language.ResultsFromSearch[this.state.language]}
              </DialogTitle>
              <DialogContent>
                <DialogContentText></DialogContentText>
                <FormControl fullWidth className={classes.formControl}>
                  <TextField
                    required
                    inputProps={{
                      type: "text",
                      autoComplete: "off",
                      autoCorrect: "off",
                      autoCapitalize: "none",
                      spellCheck: "false",
                      maxLength: 35,
                    }}
                    error={this.state.aliasError}
                    disabled={this.state.isLoading}
                    autoFocus
                    margin="dense"
                    id={uuid.v4()}
                    label={
                      this.language.LocationOrBusinessName[this.state.language]
                    }
                    type="text"
                    fullWidth
                    key={1}
                    value={this.state.alias}
                    onChange={this.handleAlphaNumericApostrophe("alias")}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  style={{ width: "95%", maxWidth: "95%", marginRight: 0 }}
                  className={classes.formControl}
                >
                  {!this.state.url ? (
                    <label htmlFor="contained-button-file">
                      <input
                        accept="image/*"
                        className={classes.input}
                        id="contained-button-file"
                        type="file"
                        onChange={(e) => {
                          this.getBase64(e);
                        }}
                      />
                      <p style={{ fontSize: "11px" }}>
                        {this.language.ProvideALogo[this.state.language]}
                        <br />
                      </p>
                      <Button
                        variant="contained"
                        disabled={this.state.isLoading}
                        component="span"
                        className={classes.button}
                      >
                        {this.state.isLoading
                          ? this.language.Processing[this.state.language]
                          : this.language.UploadLogo[this.state.language]}
                      </Button>
                      <p>
                        <small style={{ color: "red" }}>
                          {this.state.error}
                        </small>
                      </p>
                    </label>
                  ) : (
                    <p>
                      <Button
                        id="test"
                        variant="contained"
                        component="span"
                        className={classes.button}
                        onClick={() => {
                          this.setState({ url: "" });
                        }}
                      >
                        {this.language.RemoveLogo[this.state.language]}
                      </Button>
                      <img
                        src={`${this.state.url}`}
                        style={{ maxHeight: 50, float: "right" }}
                      />
                    </p>
                  )}
                </FormControl>
                <FormControl fullWidth className={classes.formControl}>
                  <TextField
                    required
                    error={this.state.fnameError}
                    disabled={this.state.isLoading}
                    margin="dense"
                    id={uuid.v4()}
                    // name="fname"
                    label={this.language.FirstName[this.state.language]}
                    type="text"
                    key={1}
                    value={this.state.fname}
                    onChange={this.handleChange("fname")}
                    inputProps={{
                      type: "text",
                      autoComplete: "off",
                      autoCorrect: "off",
                      autoCapitalize: "none",
                      spellCheck: "false",
                      maxLength: 35,
                    }}
                  />
                </FormControl>
                <FormControl fullWidth className={classes.formControl}>
                  <TextField
                    required
                    error={this.state.lnameError}
                    disabled={this.state.isLoading}
                    margin="dense"
                    id={uuid.v4()}
                    // name="lname"
                    label={this.language.LastName[this.state.language]}
                    type="text"
                    key={1}
                    value={this.state.lname}
                    inputProps={{
                      type: "text",
                      autoComplete: "off",
                      autoCorrect: "off",
                      autoCapitalize: "none",
                      spellCheck: "false",
                      maxLength: 35,
                    }}
                    onChange={this.handleChange("lname")}
                  />
                </FormControl>
                <FormControl fullWidth className={classes.formControl}>
                  <TextField
                    required
                    disabled={this.state.isLoading}
                    margin="dense"
                    error={this.state.addressError}
                    id={uuid.v4()}
                    // name="address"
                    label={this.language.StreetNumber[this.state.language]}
                    type="text"
                    fullWidth
                    key={2}
                    inputProps={{
                      type: "text",
                      autoComplete: "off",
                      autoCorrect: "off",
                      autoCapitalize: "none",
                      spellCheck: "false",
                      maxLength: 35,
                    }}
                    value={this.state.address}
                    onChange={this.handleChange("address")}
                  />
                </FormControl>
                {/* <FormControl fullWidth className={classes.formControl} >
              { this.state.searchDone &&
                <StreetAutocomplete
                  id='street'
                  name='street'
                  disabled={this.state.isLoading}
                  data={this.state.response}
                  onChange={this.handleStreetSelect}
                  value={this.state.street} />
              }
            </FormControl> */}
                <TextField
                  id={uuid.v4()}
                  required
                  error={this.state.streetError}
                  label={this.language.StreetName[this.state.language]}
                  className={classes.textField}
                  value={this.state.street}
                  // name="street"
                  disabled={this.state.isLoading}
                  onChange={this.handleChange("street")}
                  InputLabelProps={{ shrink: this.state.street !== "" }}
                  inputProps={{
                    list: "streets",
                    type: "text",
                    autoComplete: "off",
                    autoCorrect: "off",
                    autoCapitalize: "none",
                    spellCheck: "false",
                  }}
                  fullWidth
                  margin="normal"
                />
                <datalist id="streets">
                  {this.state.streetArr.map((e) => (
                    <option value={e} />
                  ))}
                </datalist>
                <FormControl fullWidth className={classes.formControl}>
                  <TextField
                    disabled={this.state.isLoading}
                    margin="dense"
                    id={uuid.v4()}
                    // name="unit"
                    label={this.language.UnitApt[this.state.language]}
                    type="text"
                    fullWidth
                    key={4}
                    inputProps={{
                      type: "text",
                      autoComplete: "off",
                      autoCorrect: "off",
                      autoCapitalize: "none",
                      spellCheck: "false",
                      maxLength: 35,
                    }}
                    value={this.state.unit}
                    onChange={this.handleChange("unit")}
                  />
                </FormControl>
                <FormControl fullWidth className={classes.formControl}>
                  <TextField
                    margin="dense"
                    id={uuid.v4()}
                    // name="city"
                    label={this.language.City[this.state.language]}
                    type="text"
                    fullWidth
                    key={4}
                    inputProps={{
                      type: "text",
                      autoComplete: "off",
                      autoCorrect: "off",
                      autoCapitalize: "none",
                      spellCheck: "false",
                      maxLength: 35,
                    }}
                    disabled={true}
                    value={this.state.city}
                    onChange={this.handleChange("city")}
                  />
                </FormControl>
                <FormControl fullWidth className={classes.formControl}>
                  <TextField
                    required
                    disabled={true}
                    margin="dense"
                    id={uuid.v4()}
                    // name="postal"
                    label={this.language.PostalCode[this.state.language]}
                    type="text"
                    inputProps={{
                      type: "text",
                      autoComplete: "off",
                      autoCorrect: "off",
                      autoCapitalize: "none",
                      spellCheck: "false",
                      maxLength: 35,
                    }}
                    fullWidth
                    key={3}
                    value={this.state.postal}
                    onChange={this.handleChange("postal")}
                  />
                </FormControl>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="province">
                    {this.language.Province[this.state.language]}
                  </InputLabel>
                  <Select
                    required
                    disabled={true}
                    fullWidth
                    value={this.state.province}
                    onChange={this.handleSelectChange}
                    inputProps={{
                      name: "province",
                      id: "province",
                    }}
                    key={5}
                  >
                    {this.renderProvinces()}
                  </Select>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button
                  disabled={this.state.isLoading}
                  onClick={this.handleClose}
                  color="primary"
                >
                  {this.language.Cancel[this.state.language]}
                </Button>
                <Button
                  disabled={this.state.isLoading}
                  onClick={() => {
                    this.confirmAddress();
                  }}
                  color="primary"
                >
                  {!this.state.isLoading
                    ? this.language.AddLocation[this.state.language]
                    : this.language.Loading[this.state.language]}
                </Button>
              </DialogActions>
            </div>
          </Dialog>
        )}
        {this.state.searchDone && this.state.formError && (
          <Dialog open={this.props.open} aria-labelledby="form-dialog-title">
            <div>
              <DialogTitle id="form-dialog-title">
                {this.language.Results[this.state.language]}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {
                    this.language.WeWereUnableToLocateTheProvidedAddressBelow[
                      this.state.language
                    ]
                  }
                  :<br />
                  <br />
                  <strong>
                    {this.state.address} {this.state.street}
                    <br />
                    {this.state.city}, {this.state.province} {this.state.postal}
                  </strong>
                  <br />
                  <br />
                  {this.language.Warning[this.state.language]}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    this.setState({ formError: false });
                  }}
                  color="primary"
                >
                  {this.language.EditAddress[this.state.language]}
                </Button>
                <Button
                  onClick={() => {
                    this.createLocation();
                  }}
                  color="primary"
                >
                  {/* this.props.finalAction(this.state); this.handleClose(); this.setState({formError:false}); */}
                  {this.language.ContinueAnyway[this.state.language]}
                </Button>
              </DialogActions>
            </div>
          </Dialog>
        )}
      </div>
    );
  }
}

FormDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FormDialog);
