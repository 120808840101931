// react
// import LoadingIcon from "../components/loading/LoadingIcon";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
// components
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
// icons
import Typography from "@material-ui/core/Typography";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import LockOutlined from "@material-ui/icons/LockOutlined";
import Replay from "@material-ui/icons/Replay";
import { Auth } from "aws-amplify";
import { getCookie } from "helpers/getCookie";
import React from "react";
import { Link } from "react-router-dom";
// snackbars
import SnackBar from "../../components/Snackbar";
import { getParameterByName } from "../../helpers/TaskRunners";
// helpers
import { isNotEmpty, isValidPassword } from "../../helpers/Validation";
// images
import Logo from "../../img/logo.svg";
// styles
import "../../styles/Login.css";

//---------------------------------------------

export default class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      password: "",
      confirmPassword: "",
      snackMessage: "",
      snackOpen: false,
      snackVariant: "error",
      snackAction: "",
      formComplete: false,
    };
  }

  UNSAFE_componentWillMount() {
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });

    if (
      getParameterByName("code") &&
      getParameterByName("user") &&
      getParameterByName("id")
    ) {
      this.setState({ code: getParameterByName("code") });
    } else {
      this.props.history.push("/login");
    }
  }

  language = {
    PasswordMismatch: {
      FR: "Les mots de passe saisis ne sont pas valides ou ne correspondent pas. Veuillez confirmer que vous avez au moins 8 caractères avec 1 symbole, 1 chiffre, 1 lettre majuscule et 1 lettre minuscule.",
      EN: "The passwords entered are not valid or do not match. Please confirm you have at least 8 characters with 1 symbol, 1 number, 1 uppercase letter and 1 lowercase letter.",
    },
    ExceededResets: {
      FR: "Vous avez dépassé le nombre de réinitialisations, veuillez patienter quelques minutes avant de réessayer.",
      EN: "You have exceeded the number of resets, please wait a few minutes before trying again.",
    },
    LinkExpired: {
      FR: "Le lien utilisé a expiré. Vous devez demander un autre e-mail de réinitialisation de mot de passe pour continuer.",
      EN: "The link used has expired. You must request another password reset email to proceed.",
    },
    ErrorResetting: {
      FR: "Erreur lors de la réinitialisation du mot de passe. Veuillez contacter le support: support@swiftpost.ca",
      EN: "Error resetting password. Please contact support: support@swiftpost.ca",
    },
    Resetpassword: {
      FR: "Réinitialiser le mot de passe",
      EN: "Reset password",
    },
    EmailAddress: {
      FR: "Adresse électronique",
      EN: "Email Address",
    },
    PasswordConstraints: {
      FR: "Minimum 8 caractères, doit contenir 1 symbole, 1 chiffre, 1 lettre majuscule et 1 lettre minuscule",
      EN: "Minimum 8 characters, must contain 1 symbol, 1 number, 1 uppercase letter and 1 lowercase letter",
    },
    Password: {
      FR: "Mot de passe",
      EN: "Password",
    },
    ConfirmPassword: {
      FR: "Confirmez le mot de passe",
      EN: "Confirm Password",
    },
    NewPassword: {
      FR: "Nouveau mot de passe",
      EN: "",
    },
    ConfirmNewPassword: {
      FR: "Confirmer le nouveau mot de passe",
      EN: "Confirm New Password",
    },
    ChangePassword: {
      FR: "Changer le mot de passe",
      EN: "Change Password",
    },
    Loading: {
      FR: "Attendre",
      EN: "Loading",
    },
    YouHaveSuccessullyChangeYourPassword: {
      FR: "Vous avez réussi à changer votre mot de passe",
      EN: "You have successully change your password",
    },
    RememberYourOldPassword: {
      FR: "Rappelez-vous votre ancien mot de passe?",
      EN: "Remember Your Old Password?",
    },
    Login: {
      FR: "S'identifier",
      EN: "Login",
    },
    BacktoLogin: {
      FR: "Retour connexion",
      EN: "Back to Login",
    },
  };

  validateForm() {
    return (
      isNotEmpty(this.state.password) &&
      isValidPassword(this.state.password) &&
      this.state.password === this.state.confirmPassword
    );
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    // check form
    if (!this.validateForm()) {
      this.setState({
        snackMessage: this.language.PasswordMismatch[this.state.language],
      });
      this.setState({ snackOpen: true });
      return;
    }

    await Auth.forgotPasswordSubmit(
      getParameterByName("user"),
      getParameterByName("code"),
      this.state.password
    )
      .then(() => {
        this.setState({ isLoading: false });
        this.setState({ formComplete: true });
      })
      .catch((e) => {
        // console.log(e)
        switch (e.code) {
          case "LimitExceededException":
            this.setState({
              snackMessage: this.language.ExceededResets[this.state.language],
            });
            this.setState({ snackVariant: "error" });
            this.setState({ snackOpen: true });
            break;
          case "ExpiredCodeException":
            this.setState({
              snackMessage: this.language.LinkExpired[this.state.language],
            });
            this.setState({ snackVariant: "error" });
            this.setState({ snackOpen: true });
            break;
          default:
            this.setState({
              snackMessage: this.language.ErrorResetting[this.state.language],
            });
            this.setState({ snackVariant: "error" });
            this.setState({ snackOpen: true });
        }
      });
  };

  render() {
    return (
      <div className="Login">
        <SnackBar
          variant={this.state.snackVariant}
          open={this.state.snackOpen}
          message={this.state.snackMessage}
          handleClose={() => {
            this.setState({ snackOpen: false });
          }}
        />
        <Grow in={true} timeout={500}>
          <Grid item xs={12} sm={7} md={5} style={{ margin: "auto" }}>
            <img src={Logo} className="Paper" alt="Swiftpost Logo" />
            <Paper
              elevation={6}
              style={{ padding: "30px", maxWidth: "400px" }}
              className="Paper"
            >
              <Typography variant="headline" component="h3">
                {!this.state.formComplete
                  ? this.language.Resetpassword[this.state.language]
                  : ""}
              </Typography>

              {!this.state.formComplete ? (
                <form onSubmit={this.handleSubmit}>
                  <Grid
                    className="Input"
                    style={{
                      width: "100%",
                    }}
                    container
                    spacing={8}
                    alignItems="flex-end"
                  >
                    <Grid item xs={1}>
                      <LockOutlined style={{ marginBottom: "30px" }} />
                    </Grid>
                    <Grid item xs={11}>
                      <TextField
                        helperText={
                          this.language.PasswordConstraints[this.state.language]
                        }
                        style={{ width: "100%" }}
                        id="password"
                        onChange={this.handleChange}
                        value={this.state.password}
                        type="password"
                        label={this.language.NewPassword[this.state.language]}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    className="Input no-margin"
                    style={{
                      width: "100%",
                    }}
                    container
                    spacing={8}
                    alignItems="flex-end"
                  >
                    <Grid item xs={1}>
                      <Replay />
                    </Grid>
                    <Grid item xs={11}>
                      <TextField
                        style={{ width: "100%" }}
                        id="confirmPassword"
                        onChange={this.handleChange}
                        value={this.state.confirmPassword}
                        type="Password"
                        label={
                          this.language.ConfirmNewPassword[this.state.language]
                        }
                      />
                    </Grid>
                  </Grid>

                  <br />

                  <Button
                    className="loginSubmit"
                    variant="contained"
                    color="primary"
                    style={{
                      width: "100%",
                    }}
                    onClick={this.handleSubmit}
                    disabled={this.state.isLoading}
                  >
                    {!this.state.isLoading &&
                      this.language.ChangePassword[this.state.language]}
                    {this.state.isLoading &&
                      this.language.Loading[this.state.language]}
                  </Button>
                </form>
              ) : (
                <Grow timeout={1000} in={this.state.formComplete}>
                  <center>
                    <CheckIcon style={{ color: "green", fontSize: "80px" }} />
                    <h4>
                      {
                        this.language.YouHaveSuccessullyChangeYourPassword[
                          this.state.language
                        ]
                      }
                    </h4>
                  </center>
                </Grow>
              )}
            </Paper>
            <Grid
              item
              style={{ margin: "auto", padding: "0px", maxWidth: "400px" }}
            >
              <Typography className="callout">
                {!this.state.formComplete
                  ? this.language.RememberYourOldPassword[this.state.language]
                  : ""}
              </Typography>
              <Link to="/login">
                <Button
                  variant="contained"
                  color="secondary"
                  style={{
                    width: "100%",
                  }}
                >
                  {!this.state.formComplete
                    ? this.language.Login[this.state.language]
                    : this.language.BacktoLogin[this.state.language]}
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grow>
      </div>
    );
  }
}
