import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { round } from "../../helpers/TaskRunners";
import LoadingIcon from "../loading/LoadingIcon";
import Grid from "@material-ui/core/Grid";
import { accessorials } from "./Step3";

const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  menu: {
    width: 200,
  },
  formControl: {
    margin: theme.spacing.unit,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  formControl: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2,
    display: "block",
  },
  resetContainer: {
    padding: theme.spacing.unit * 2,
  },
});

class TextFields extends React.Component {
  state = {};

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  renderPackages(arr) {
    if (arr.length > 1) {
      return [{}].concat(arr).map((note, i) => {
        if (i !== 0) {
          return (
            <Fragment>
              <strong>Pallet {i}:</strong> {note.length}" x {note.width}" x{" "}
              {note.height}", {note.weight}lbs
              <br />
            </Fragment>
          );
        }
      });
    } else {
      return (
        <Fragment>
          <strong>Pallet 1:</strong> {arr[0]["length"]}" x {arr[0].width}" x{" "}
          {arr[0].height}", {arr[0].weight}lbs
        </Fragment>
      );
    }
  }

  UNSAFE_componentWillMount = async () => {
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });
  };

  language = {
    inches: {
      FR: "pouces",
      EN: "inches",
    },
    pounds: {
      FR: "livre",
      EN: "pounds",
    },
    Receiver: {
      FR: "Le Destinataire",
      EN: "Receiver",
    },
    Sender: {
      FR: "L'Expéditeur",
      EN: "Sender",
    },
    Package: {
      FR: "Paquet",
      EN: "Package",
    },
    Packages: {
      FR: "Paquets",
      EN: "Packages",
    },
    Qty: {
      FR: "Qté",
      EN: "Qty",
    },
    value: {
      FR: "valeur",
      EN: "value",
    },
    Service: {
      FR: "Niveau de Service",
      EN: "Service",
    },
    Edit: {
      FR: "Éditer",
      EN: "Edit",
    },
    Back: {
      FR: "Arrière",
      EN: "Back",
    },
    Submit: {
      FR: "Soumettre",
      EN: "Submit",
    },
    FuelSurcharge: {
      FR: "Surcharge d'essence",
      EN: "Fuel Surcharge",
    },
    ExtraCare: {
      FR: "Soin supplémentaire",
      EN: "Extra Care",
    },
    ExtendedArea: {
      FR: "Zone étendue",
      EN: "Extended Area",
    },
    RuralArea: {
      FR: "Zone rurale",
      EN: "Rural Area",
    },
    ResidentialCharge: {
      FR: "Frais de résidence",
      EN: "Residential Charge",
    },
    Base: {
      FR: "Frais de base",
      EN: "Base",
    },
  };

  render() {
    const { classes } = this.props;
    // console.log(this.props)
    var rate, rateObj;
    if (this.props.editRec && this.props.rates.length == 0) {
      rate = this.props.editRate.amount;
      rateObj = this.props.editRate;
    } else {
      rate = this.props.rates[this.props.selectedService].rate;
      rateObj = this.props.rates[this.props.selectedService];
    }

    rate = parseFloat(rate).toFixed(2);

    console.log(rateObj);

    let sender = this.props.locations.filter(
      (e) => e.id === this.props.senderLocationID
    );
    sender = sender[0];

    return (
      <div>
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography variant="subheading">
            <div style={{ maxWidth: "450px", lineHeight: "36px" }}>
              <strong>{this.language.Receiver[this.state.language]}</strong>
              <Button
                color="secondary"
                style={{ verticalAlign: "middle", float: "right" }}
                onClick={() => this.props.handleGoToStep(0)}
              >
                {this.language.Edit[this.state.language]}
              </Button>
            </div>
            {this.props.receiver.business && (
              <span>
                {this.props.receiver.business}
                <br />
              </span>
            )}
            Attn: {this.props.receiver.fname} {this.props.receiver.lname}
            <br />
            {this.props.receiver.unit && (
              <span>#{this.props.receiver.unit}&nbsp;-&nbsp;</span>
            )}
            {this.props.receiver.address}
            <br />
            {this.props.receiver.city}, {this.props.receiver.state}{" "}
            {this.props.receiver.zip}
          </Typography>
        </Paper>
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography variant="subheading">
            <div style={{ maxWidth: "450px", lineHeight: "36px" }}>
              <strong>{this.language.Sender[this.state.language]}</strong>{" "}
              <Button
                color="secondary"
                style={{ verticalAlign: "middle", float: "right" }}
                onClick={() => this.props.handleGoToStep(1)}
              >
                {this.language.Edit[this.state.language]}
              </Button>
            </div>
            {sender.title}
            <br />
            {sender.address.unit ? (
              <span>#{sender.address.unit}&nbsp;-&nbsp;</span>
            ) : (
              ``
            )}
            {sender.address.street_number} {sender.address.street_name}
            <br />
            {sender.address.city}, {sender.address.state}{" "}
            {sender.address.postal}
          </Typography>
        </Paper>
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography variant="subheading">
            <div style={{ maxWidth: "450px", lineHeight: "36px" }}>
              <strong>Pallets</strong>{" "}
              <Button
                color="secondary"
                style={{ verticalAlign: "middle", float: "right" }}
                onClick={() => this.props.handleGoToStep(2)}
              >
                {this.language.Edit[this.state.language]}
              </Button>
            </div>
            {this.renderPackages(this.props.package.dimensions)}
            <br />
            <br />
            <Grid container spacing={24}>
              {this.props.sender_accessorials?.length ? (
                <Grid item xs={4}>
                  <strong>Pickup considerations:</strong>
                  <br />
                  {this.props.sender_accessorials.map((f) => (
                    <>
                      {accessorials.filter((g) => g.code === f)[0].readable}
                      <br />
                    </>
                  ))}
                </Grid>
              ) : (
                <></>
              )}
              {this.props.receiver_accessorials?.length ? (
                <Grid item xs={6}>
                  <strong>Dropoff considerations:</strong>
                  <br />
                  {this.props.receiver_accessorials.map((f) => (
                    <>
                      {accessorials.filter((g) => g.code === f)[0].readable}
                      <br />
                    </>
                  ))}
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
            {this.props.package.insured == "true" && (
              <Fragment>
                {this.props.package.description},{" "}
                {this.language.Qty[this.state.language]}{" "}
                {this.props.package.quantity}
                <br />${this.props.package.value}{" "}
                {this.language.value[this.state.language]}
              </Fragment>
            )}
          </Typography>
        </Paper>
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography variant="subheading">
            <div style={{ maxWidth: "450px", lineHeight: "36px" }}>
              <strong>Service</strong>{" "}
              <Button
                color="secondary"
                style={{ verticalAlign: "middle", float: "right" }}
                onClick={() => this.props.handleGoToStep(3)}
              >
                {this.language.Edit[this.state.language]}
              </Button>
            </div>
            {this.props.editRec && this.props.rates.length == 0
              ? this.props.editRate.service.name
              : this.props.rates[this.props.selectedService].service.service
                  .name}
            <br />${round(rate)}
            <br />
            <p style={{ color: "grey" }}>
              <small>
                {this.language.Base[this.state.language]}: $
                {round(Number(rateObj.subtotal))} CAD
                <br />
                {/* {rateObj.return_charge > 0 ? <Fragment>Return Charge: ${round(rateObj.return_charge)} CAD<br/></Fragment>:``} */}
                {rateObj.accessorials && rateObj.accessorials.length > 0
                  ? rateObj.accessorials.map((e) => (
                      <Fragment>
                        {e.name}: ${round(e.charge)} CAD
                        <br />
                      </Fragment>
                    ))
                  : ""}
                {rateObj.fuel_surcharge > 0 ? (
                  <Fragment>
                    {this.language.FuelSurcharge[this.state.language]}: $
                    {round(rateObj.fuel_surcharge)} CAD
                    <br />
                  </Fragment>
                ) : (
                  ``
                )}
                {rateObj.xc_charge > 0 ? (
                  <Fragment>
                    {this.language.ExtraCare[this.state.language]}: $
                    {round(rateObj.xc_charge)} CAD
                    <br />
                  </Fragment>
                ) : (
                  ``
                )}
                {rateObj.ea_charge > 0 ? (
                  <Fragment>
                    {this.language.ExtendedArea[this.state.language]}: $
                    {round(rateObj.ea_charge)} CAD
                    <br />
                  </Fragment>
                ) : (
                  ``
                )}
                {rateObj.rural_charge > 0 ? (
                  <Fragment>
                    {this.language.RuralArea[this.state.language]}: $
                    {round(rateObj.rural_charge)} CAD
                    <br />
                  </Fragment>
                ) : (
                  ``
                )}
                {rateObj.residential_charge > 0 ? (
                  <Fragment>
                    {this.language.ResidentialCharge[this.state.language]}: $
                    {round(rateObj.residential_charge)} CAD
                    <br />
                  </Fragment>
                ) : (
                  ``
                )}
                {rateObj.tax1_code ? (
                  <Fragment>
                    {rateObj.tax1_code} (
                    {(Number(rateObj.tax1_rate) * 100).toFixed(2)}%): $
                    {round(rateObj.tax1_amount)} CAD
                    <br />
                  </Fragment>
                ) : (
                  ``
                )}
                {rateObj.tax2_code ? (
                  <Fragment>
                    {rateObj.tax2_code} (
                    {(Number(rateObj.tax2_rate) * 100).toFixed(2)}%): $
                    {round(rateObj.tax2_amount)} CAD
                    <br />
                  </Fragment>
                ) : (
                  ``
                )}
                {/* {rateObj.tax2_code ? <Fragment>{rateObj.tax2_code} ({Number(rateObj.tax2_rate) * 100}%): ${round(rateObj.tax2_amount} CAD<br/></Fragment>:``} */}
              </small>
              {/* {rateObj.service.service.delivery_ground} days */}
            </p>
          </Typography>
        </Paper>
        <Paper square elevation={0} className={classes.resetContainer}>
          <Button
            disabled={this.props.isLoading}
            onClick={() => this.props.handleGoToStep(3)}
            className={classes.button}
          >
            {this.language.Back[this.state.language]}
          </Button>
          <Button
            disabled={this.props.isLoading}
            onClick={() => this.props.createShipment()}
            className={classes.button}
            variant="contained"
            color="primary"
          >
            {!this.props.isLoading ? (
              this.language.Submit[this.state.language]
            ) : (
              <LoadingIcon size={20} />
            )}
          </Button>
        </Paper>
      </div>
    );
  }
}

TextFields.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TextFields);
