import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { isNumericOnly } from "../helpers/Validation";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Switch from "@material-ui/core/Switch";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    padding: "25px 30px 50px 30px",
  },
  textField: {
    width: "100%",
    margin: "10px 0px",
  },
});

class OutlinedInputAdornments extends React.Component {
  state = {
    name: "",
    shipmentCount: "",
  };

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
    this.props.updateState(name, event.target.value);
  };

  render() {
    const { classes } = this.props;

    const language = {
      Selecttheregionsyoushipto: {
        FR: "Sélectionnez les régions dans lesquelles vous expédiez",
        EN: "Select the regions you ship to",
      },
      Averageshipmentsperweek: {
        FR: "Expéditions moyennes par semaine",
        EN: "Average shipments per week",
      },
      International: {
        FR: "Internationale",
        EN: "International",
      },
    };

    return (
      <div className={classes.root}>
        <h3 style={{ marginBottom: "25px" }}>
          {language.Selecttheregionsyoushipto[this.props.language]}
        </h3>
        <FormControlLabel
          control={
            <Switch
              checked={this.props.shipToCA}
              onChange={this.props.handleChange("shipToCA")}
              value="shipToCA"
            />
          }
          label="Canada"
        />
        <FormControlLabel
          control={
            <Switch
              checked={this.props.shipToUSA}
              onChange={this.props.handleChange("shipToUSA")}
              value="shipToUSA"
            />
          }
          label="USA"
        />
        <FormControlLabel
          control={
            <Switch
              checked={this.props.shipToINT}
              onChange={this.props.handleChange("shipToINT")}
              value="shipToINT"
            />
          }
          label={language.International[this.props.language]}
        />
        <TextField
          fullWidth
          required
          id="outlined-simple-start-adornment"
          className={classNames(classes.textField)}
          variant="outlined"
          value={this.props.shipmentCount}
          onChange={this.props.updateState("shipmentCount")}
          label={language.Averageshipmentsperweek[this.props.language]}
        />
      </div>
    );
  }
}

OutlinedInputAdornments.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OutlinedInputAdornments);
