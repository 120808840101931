import { Address, AddressBookRecord } from "@shared";
import { ImportRecord } from "./importer/CSVImportTable";

export function fullName(record: AddressBookRecord) {
  return [record.first_name, record.last_name].join(" ").trim();
}

export function fullAddress(address: Address) {
  return [
    address.address1,
    address.address2,
    address.city,
    address.country,
    address.postal,
  ]
    .join(" ")
    .trim();
}

export function fullFormattedAddress(record: Address) {
  return [
    record.address1,
    record.address2,
    record.city,
    record.state,
    record.country,
    record.postal,
  ].map((val, i) => (
    <span key={i} style={{ margin: 0, display: "block" }}>
      {val}
    </span>
  ));
}

export function recordToString(record: AddressBookRecord) {
  return [fullName(record), fullAddress(record)].join(" ").trim();
}

export function rowValue(value?: string | null) {
  // purposefully using "||" so empty string counts
  return value || "--";
}

export function sortImportRecords(records: ImportRecord[]) {
  return records.sort((a: ImportRecord, b: ImportRecord) => {
    if (a.importState === "error") {
      return -1;
    }

    if (a.importState === "mismatch" && b.importState !== "error") {
      return -1;
    }

    if (
      a.importState === "duplicate" &&
      b.importState !== "error" &&
      b.importState !== "mismatch"
    ) {
      return -1;
    }

    return 1;
  });
}

export function addressesMatch(address1: Address, address2: Address) {
  const normalize = (addr: string) => addr.replace(/\s/g, "").toUpperCase();
  const normalized1 = normalize(fullAddress(address1));
  const normalized2 = normalize(fullAddress(address2));
  return normalized1 === normalized2;
}

export function hasVerifiedAddressMismatch(record: ImportRecord) {
  if (!record.verifiedAddress) {
    return true;
  }

  return !addressesMatch(record.importedAddress, record.verifiedAddress);
}
