import { InputAdornment } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { getCookie } from "helpers/getCookie";
import React from "react";

export default class FormDialog extends React.Component {
  state = {
    open: false,
    shop: "",
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  UNSAFE_componentWillMount = () => {
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });
  };

  language = {
    ShopifyConnectTitle: {
      FR: "Connectez votre Boutique",
      EN: "Connect Your Shop",
    },
    StoreName: {
      FR: "Boutique Shopify",
      EN: "Shopify Store Name",
    },
    Cancel: {
      FR: "Annuler",
      EN: "Cancel",
    },
    Connect: {
      FR: "Connecter",
      EN: "Connect",
    },
  };

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={() => {
            this.setState({ shop: "" });
            this.props.handleClose();
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {this.language.ShopifyConnectTitle[this.state.language]}
          </DialogTitle>
          <DialogContent>
            {/* <DialogContentText>
              To subscribe to this website, please enter your email address here. We will send
              updates occasionally.
            </DialogContentText> */}
            <TextField
              autoFocus
              variant="outlined"
              margin="dense"
              id="shop"
              name="shop"
              label={this.language.StoreName[this.state.language]}
              type="text"
              fullWidth
              onChange={this.handleChange("shop")}
              value={this.state.shop}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">http://</InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">.myshopify.com</InputAdornment>
                ),
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({ shop: "" });
                this.props.handleClose();
              }}
              color="primary"
            >
              {this.language.Cancel[this.state.language]}
            </Button>
            <Button
              onClick={() => this.props.openShopify(this.state.shop)}
              color="primary"
            >
              {this.language.Connect[this.state.language]}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
