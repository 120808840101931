/* eslint-disable react/no-multi-comp */

import Button from "@material-ui/core/Button";
import blue from "@material-ui/core/colors/blue";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import MinusIcon from "@material-ui/icons/Remove";
// import { API } from "aws-amplify";
import { API, graphqlOperation } from "aws-amplify";
import React, { Fragment } from "react";
import { Auth } from "aws-amplify";

const styles = {
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
};

class FlashEdit extends React.Component {
  state = {
    open: false,
    postal: "",
    error: false,
    activeStep: 0,
    connection_id: "",
    message: "",
    packageCount: this.props.flashEditData.package.entered_pld.length,
  };

  savePackageChanges = () =>
    new Promise(async (resolve, reject) => {
      // alert('starting savepackage');
      this.setState({ isLoading: true });
      this.setState({ message: "GETTING NEW RATE..." });
      // alert('stop');
      await this.rateChanges().catch((e) => {
        this.setState({
          error: true,
          message: "Unable to get rate for shipment.",
        });
      });
      // await this.saveToAppSync().catch(e=>{this.setState({error:true,message:'Unable to save changes.'})});
      // this.props.handleClose();
      resolve(true);
    });

  componentDidMount = async () => {
    // alert('socket')
    const socket2 = new WebSocket(
      "wss://keuhz93lxj.execute-api.us-east-1.amazonaws.com/dev"
    );

    socket2.addEventListener("open", function (event) {
      socket2.send(JSON.stringify({ action: "sendMessage" }));
      // alert('connected')
    });

    socket2.addEventListener("message", (event) => {
      //   alert('socket msg');
      // console.log(JSON.parse(event.data));
      let data = JSON.parse(event.data);
      if (data.connectionId) {
        // alert(data.connectionId);
        this.setState({ connection_id: data.connectionId });
        // console.log("CONNECTED, ",data.connectionId)
      }
      if (data.rate) {
        //   alert('rated');
        console.log(data.rate);
        this.setState({ rate: data.rate[0] });
        this.saveToAppSync().catch((e) => {
          this.setState({ error: true, message: "Unable to save changes." });
        });
        // this.state.setState(
        //   "rates",
        //   data.rate.filter(f => {
        //     return f.rate != 0;
        //   })
        // );
        // this.props.handleNext("activeStep");
      }
    });
  };

  rateChanges = () =>
    new Promise(async (resolve, reject) => {
      let email = await Auth.currentSession();
      email = email.idToken.payload.email;
      let body = {
        sender: this.props.flashEditData.sender,
        package: {
          origin: "CA",
          ref: "",
          count: this.state.packageCount,
          description: "",
          quantity: "",
          value: "",
          insured: false,
          type: "box",
          dimensions: new Array(Number(this.state.packageCount))
            .fill(0)
            .map((e) => {
              return {
                weight: (
                  Number(
                    this.props.flashEditData.package.entered_pld.reduce(
                      (a, b) => Number(a) + Number(b.weight),
                      0
                    )
                  ) / Number(this.state.packageCount)
                ).toFixed(2),
                height: "11",
                length: "13",
                width: "11",
              };
            }),
        },
        receiver: {
          verified: false,
          business: "",
          fname: "",
          lname: "",
          attention: "",
          address: "",
          address2: "",
          city: this.props.flashEditData.receiver.address.city,
          state: this.props.flashEditData.receiver.address.state,
          zip: this.props.flashEditData.receiver.address.postal,
          zip5: "",
          country: "CA",
          phone: "",
          email: "",
          street_name: "",
          street_number: "",
          is_residential: false,
        },
        services: [
          {
            service: {
              name: "Ground",
              rate_type: "1",
              max_length: 108,
              max_width: 108,
              max_height: 108,
              max_weight: 150,
              delivery_min: 1,
              delivery_ground: 3,
              delivery_air: 5,
              additional_test1: {
                max: 165,
                multiplier: 2,
              },
            },
          },
        ],
        connection_id: this.state.connection_id,
        cognitoUsername: this.props.account.user.userId,
        email,
      };
      console.log(body);
      if (this.props.account.user.ics_api) {
        body.ics_api = this.props.account.user.ics_api;
      }
      if (this.props.account.user.ics_rates) {
        body.ics_rates = this.props.account.user.ics_rates;
      }
      if (this.props.account.user.canpar_api) {
        body.canpar_api = this.props.account.user.canpar_api;
      }
      if (this.props.account.user.canpar_rates) {
        body.canpar_rates = this.props.account.user.canpar_rates;
      }
      console.log(body);
      API.post("sqs", "/sqs-rate", {
        body,
      });
      resolve(true);
    });

  saveToAppSync = () =>
    new Promise(async (resolve, reject) => {
      // alert('trying to save');
      this.setState({ message: "UPDATING SHIPMENT..." });

      await API.graphql(
        graphqlOperation(`mutation createShipment{
          updateV1_Shipment (input:{
            date_updated:"${new Date().toLocaleString()}"
            id:"${this.props.flashEditData.id}"
            rate:{
              ${
                this.state.rate.accessorials &&
                this.state.rate.accessorials.length > 0
                  ? `accessorial_1_name:"${this.state.rate.accessorials[0].name}"
                accessorial_1_charge:"${this.state.rate.accessorials[0].charge}"`
                  : ""
              }
              ${
                this.state.rate.accessorials &&
                this.state.rate.accessorials.length > 1
                  ? `accessorial_1_name:"${this.state.rate.accessorials[1].name}"
                accessorial_1_charge:"${this.state.rate.accessorials[1].charge}"`
                  : ""
              }
              ${
                this.state.rate.accessorials &&
                this.state.rate.accessorials.length > 2
                  ? `accessorial_1_name:"${this.state.rate.accessorials[2].name}"
                accessorial_1_charge:"${this.state.rate.accessorials[2].charge}"`
                  : ""
              }
              ${
                this.state.rate.accessorials &&
                this.state.rate.accessorials.length > 3
                  ? `accessorial_1_name:"${this.state.rate.accessorials[3].name}"
                accessorial_1_charge:"${this.state.rate.accessorials[3].charge}"`
                  : ""
              }
              amount:"${this.state.rate.rate}"
              subtotal:"${this.state.rate.subtotal}"
              rate_id:"${this.state.rate.rate_id}"
              tax1_code:"${this.state.rate.tax1_code}"
              ${
                this.state.rate.tax2_code
                  ? `tax2_code:"${this.state.rate.tax2_code}"`
                  : ``
              }
              tax1_rate:"${this.state.rate.tax1_rate}"
              ${
                this.state.rate.tax2_rate
                  ? `tax2_rate:"${this.state.rate.tax2_rate}"`
                  : ``
              }
              tax1_amount:"${this.state.rate.tax1_amount}"
              ${
                this.state.rate.tax2_amount
                  ? `tax2_amount:"${this.state.rate.tax2_amount}"`
                  : ``
              }
              ea_charge:"${this.state.rate.ea_charge}"
              residential_charge:"${this.state.rate.residential_charge}"
              fuel_surcharge:"${this.state.rate.fuel_surcharge}"
              rural_charge:"${this.state.rate.rural_charge}"
              xc_charge:"${this.state.rate.xc_charge}"
              service:{
                name: "${this.state.rate.service.service.name}"
                courier_code: "${this.state.rate.service.service.rate_type}"
                courier: "${this.state.rate.courier}"
              }
            }
            package: {
              entered_pld:[${new Array(Number(this.state.packageCount))
                .fill(0)
                .map((e) => {
                  return `{
                width:"11"
                height:"11"
                length:"13"
                weight:"${(
                  Number(
                    this.props.flashEditData.package.entered_pld.reduce(
                      (a, b) => Number(a) + Number(b.weight),
                      0
                    )
                  ) / Number(this.state.packageCount)
                ).toFixed(2)}"
              }`;
                })
                .join(",")}]
            }
          }){
            id
            cognitoUsername
          }
        }`)
      ).catch((e) => {
        // console.log(e)
        this.setState({ isLoading: false });
      });
      //   setTimeout(async()=>{await this.props.updateOrders();this.props.handleClose()},1000);

      resolve(true);
    });

  render() {
    //   console.log(this.props.flashEditData);
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={() => {
            if (!this.state.isLoading) {
              this.props.handleClose();
            }
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Fragment>
            <DialogContent style={{ padding: "20px 30px", minWidth: "250px" }}>
              <strong>
                {this.props.flashEditData.reference}
                <br />
                <small style={{ color: "rgba(0,0,0,.5)" }}>
                  {this.props.flashEditData.package.entered_pld
                    .reduce((a, b) => Number(a) + Number(b.weight), 0)
                    .toFixed(2)}{" "}
                  LB
                </small>
              </strong>

              <br />
              <br />
              <br />
              <small>
                <span
                  style={{
                    color: "var(--brand-cyan)",
                    textTransform: "uppercase",
                  }}
                >
                  <strong>PACKAGE QUANTITY</strong>
                </span>
              </small>
              <br />
              <br />
              <IconButton
                disabled={this.state.packageCount === 25}
                onClick={() =>
                  this.setState({ packageCount: this.state.packageCount + 1 })
                }
                style={{ float: "right" }}
              >
                <AddIcon />
              </IconButton>
              <IconButton
                disabled={this.state.packageCount === 1}
                onClick={() =>
                  this.setState({ packageCount: this.state.packageCount - 1 })
                }
                style={{ float: "left" }}
              >
                <MinusIcon />
              </IconButton>
              <center>
                <span
                  style={{
                    fontSize: "2.5em",
                    fontFamily: "sans-serif",
                    color: "rgba(0,0,0,.9)",
                  }}
                >
                  {this.state.packageCount}
                </span>
              </center>
              <p></p>
              <p>
                <center>
                  <small style={{ color: "rgba(0,0,0,.4)" }}>
                    {this.state.message}
                  </small>
                </center>
              </p>
            </DialogContent>
            <DialogActions>
              <Button
                disabled={
                  this.state.connection_id == "" || this.state.isLoading
                }
                onClick={async () => this.savePackageChanges()}
                color="primary"
              >
                {this.state.isLoading ? "Loading" : "Save"}
              </Button>
              <Button
                onClick={() => {
                  this.props.handleClose();
                }}
                color="default"
              >
                Cancel
              </Button>
            </DialogActions>
          </Fragment>
        </Dialog>
      </div>
    );
  }
}

export default FlashEdit;
