import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { RefreshOutlined, SaveOutlined } from "@material-ui/icons";
import { API, graphqlOperation } from "aws-amplify";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
// import LoadingIcon from '../components/loading/LoadingIcon';
import LoadingIcon from "../components/loading/LoadingIcon";
import SnackBar from "../components/Snackbar";
import QZStepper from "../dialogs/QZStepper";
import PagePaper from "./PagePaper";

const styles = (theme) => ({
  textField: {
    width: "100%",
    boxSizing: "border-box",
    fontSize: "1.5em",
  },
  menu: {
    width: 200,
  },
  formControl: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2,
  },
  resetContainer: {
    padding: theme.spacing.unit * 3,
  },
});

// var qz = require("qz-tray");

class Settings extends React.Component {
  state = {
    isLoading: true,
    activeStep: 0,
    activePrinters: [],
    osName: "NA",
    qzActive: false,
    selectedPrinter: "",
    autoPrint: false,
    snackMsg: "",
    openSnack: false,
  };

  UNSAFE_componentWillMount = () => {
    if (window.navigator.appVersion.indexOf("Win") !== -1)
      this.setState({ osName: "Windows" });
    if (window.navigator.appVersion.indexOf("Mac") !== -1)
      this.setState({ osName: "MacOS" });
    if (window.navigator.appVersion.indexOf("X11") !== -1)
      this.setState({ osName: "UNIX" });
    if (window.navigator.appVersion.indexOf("Linux") !== -1)
      this.setState({ osName: "Linux" });
  };

  componentDidMount = async () => {
    // console.log(this.props.settings)
    if (this.props.settings.printer) {
      // alert(this.props.settings[0].printer)
      this.setState({ selectedPrinter: this.props.settings.printer });
      this.setState({
        autoPrint: this.props.settings.autoPrint === "false" ? false : true,
      });

      // this.setState({activeStep:1});
      // this.setState({isLoading:false});
      // alert(this.props.settings.printer)
    }
    // qz.websocket.connect().then(function() {
    //     return qz.printers.find();
    // }).then(function(printers) {
    //     console.log(printers);
    // }).then(function() {
    //     return qz.websocket.disconnect();
    // }).then(function() {
    //     process.exit(0);
    // }).catch(function(err) {
    //     console.error(err);
    //     process.exit(1);
    // });
    setTimeout(async () => {
      if (!window.qz.websocket.isActive()) {
        this.setState({ activeStep: 0, isLoading: false });
      } else {
        await this.getPrinters();
      }
    }, 3000);
  };

  handleCheck = async (event) => {
    // alert(event.target.checked)
    this.setState({ [event.target.name]: event.target.checked });

    await API.graphql(
      graphqlOperation(`mutation {
          updateSettings(input:{
            id:"${this.props.settings.id}"
            printer:"${this.state.selectedPrinter}"
            autoPrint:"${event.target.checked}"
          }){
            id
            cognitoUsername
          }
    }`)
    );
  };

  handleChange = (event) => {
    // alert(event.target.value)
    this.setState({ [event.target.name]: event.target.value });
  };

  connect = async () => {
    let test = await window.startConnection({ retries: 0, delay: 1 });
    // alert(`TEST RESPONSE => ${test}`)
    if (!test) {
      // alert("Advise user to download or turn on software.")
    } else {
      this.getPrinters();
    }
  };
  // alert("TRYING")
  // this.setState({isLoading:true});

  //   window.qz.websocket.connect({ retries: 5, delay: 1 }).then(function () {
  //     console.log('connected.')
  //     window.cfg = window.qz.configs.create(null);
  //     window.qz.printers.find().then(function (printer) {
  //       window.cfg.setPrinter(printer);
  //       console.log("SETTING DEFAULT TO ", printer)
  //     }).catch(e=>{
  //       window.qz.printers.getDefault().then(function (printer) {
  //         window.cfg.setPrinter(printer);
  //       });
  //     });
  //   }).catch(e => console.log(e));

  //   window.qz.websocket.connect({}).then(async e=>{
  //       console.log('connected')
  //       await this.getPrinters();
  //       this.setState({qzActive:true});
  //       this.setState({activeStep:1});
  //       this.setState({isLoading:false});
  //   }).catch(e=>{
  //       //assume that qz is either not installed or not turned on
  //       this.setState({qzActive:false});
  //       this.setState({isLoading:false});
  //   });
  // }

  getPrinters = () => {
    this.setState({ isLoading: true });
    window.qz.printers
      .find()
      .then((e) => {
        this.setState({
          activePrinters: e,
          isLoading: false,
          activeStep: 1,
          qzActive: true,
        });
      })
      .catch((e) => {
        window.qz.printers
          .find()
          .then((e) => {
            this.setState({
              activePrinters: e,
              isLoading: false,
              activeStep: 1,
              qzActive: false,
            });
          })
          .catch((e) => {});
      });
  };

  render() {
    return (
      <div className="Home">
        <QZStepper open={this.state.qzOpen} />
        <SnackBar
          style={{ zIndex: 999999, position: "fixed" }}
          variant={"success"}
          open={this.state.openSnack}
          message={this.state.snackMsg}
          handleClose={() => {
            this.setState({ openSnack: false });
          }}
        />
          <Typography variant="h1">Settings</Typography>
          <Grid item xs={12} md={8} lg={5} xl={4}>
          <PagePaper>
          <Grid item xs={12}>
            <h2>
              Print Service
            </h2>
            <br />
            <p>
              Enable One-Click Printing by installing the{" "}
              <strong>Swiftpost Print Service</strong> below.
            </p>

            {this.state.isLoading === true ? (
              <span>
                <LoadingIcon />
              </span>
            ) : (
              <Fragment>
                {this.state.osName !== "NA" &&
                  !this.state.qzActive &&
                  !this.state.isLoading && (
                    <Button
                      color="primary"
                      variant="contained"
                      href={
                        this.state.osName === "Windows"
                          ? `https://swiftpost-downloads.s3.amazonaws.com/Swiftpost-2.1.2.exe`
                          : this.state.osName === "MacOS"
                          ? `https://swiftpost-downloads.s3.amazonaws.com/Swiftpost-2.1.2.pkg`
                          : `https://swiftpost-downloads.s3.amazonaws.com/Swiftpost-2.1.2.run`
                      }
                    >
                      Print Service for {this.state.osName}
                    </Button>
                  )}
                {this.state.activeStep === 0 && (
                  <Fragment>
                    <br />
                    <br />
                    <br />
                    <small>
                      Already have the software installed and started?
                    </small>
                    <br /><br />
                    <Button color="secondary" variant="contained" onClick={() => this.connect()}>
                      Check Connection
                    </Button>
                  </Fragment>
                )}
                {this.state.activeStep === 1 && (
                  <FormControl>
                    <InputLabel shrink htmlFor="selectedPrinter">
                      Select Printer
                    </InputLabel>
                    <Select
                      value={this.state.selectedPrinter}
                      onChange={this.handleChange}
                      inputProps={{
                        name: "selectedPrinter",
                        id: "selectedPrinter",
                      }}
                      style={{
                        minWidth: "200px",
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <Tooltip title="Refresh Printer List">
                            <IconButton
                              aria-label="Refresh Printers"
                              onClick={this.getPrinters}
                            >
                              <RefreshOutlined />
                            </IconButton>
                          </Tooltip>
                          {this.state.selectedPrinter !==
                            this.props.settings.printer && (
                            <Tooltip title="Save Settings">
                              <IconButton
                                onClick={async () => {
                                  if (this.props.settings.printer) {
                                    await API.graphql(
                                      graphqlOperation(`mutation {
                                updateSettings(input:{
                                  id:"${this.props.settings.id}"
                                  printer:"${this.state.selectedPrinter}"
                                  autoPrint:"${this.state.autoPrint}"
                                }){
                                  id
                                  cognitoUsername
                                }
                          }`)
                                    );
                                    // console.log(settings)
                                  } else {
                                    await API.graphql(
                                      graphqlOperation(`mutation createSettings{
                                createSettings(input:{
                                  printer:${JSON.stringify(
                                    this.state.selectedPrinter
                                  )}
                                  autoPrint:"${this.state.autoPrint}"
                                }){
                                  id
                                  cognitoUsername
                                }
                              }`)
                                    );
                                  }
                                  this.setState({
                                    openSnack: true,
                                    snackMsg: "Successfully saved settings.",
                                  });
                                }}
                              >
                                <SaveOutlined color="primary" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </InputAdornment>
                      }
                    >
                      {this.state.activePrinters.map((e) => (
                        <MenuItem value={e}>{e}</MenuItem>
                      ))}
                    </Select>
                    <br />
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          checked={this.state.autoPrint}
                          name="autoPrint"
                          id="autoPrint"
                          color="primary"
                          onChange={this.handleCheck}
                        />
                      }
                      label="Auto Print"
                    />
                    {/* <Button color="secondary" onClick={async ()=>{
                      if(this.props.settings.printer){
                      let settings = await API.graphql(graphqlOperation(`mutation {
                        updateSettings(input:{
                          id:"${this.props.settings.id}"
                          printer:"${this.state.selectedPrinter}"
                        }){
                          id
                          cognitoUsername
                        }
                  }`));
                      // console.log(settings)
                    }else{
                      let settings2 = await API.graphql(graphqlOperation(`mutation createSettings{
                        createSettings(input:{
                          printer:${JSON.stringify(this.state.selectedPrinter)}
                        }){
                          id
                          cognitoUsername
                        }
                      }`));
                    }
                    }}>Save Settings</Button> */}
                  </FormControl>
                )}
              </Fragment>
            )}
          </Grid>
          <Grid item xs={12}>
            {/* <div>
                    <br/><br/>
                    <Divider />
                    <br/><br/>
                    <Typography variant="h5" gutterBottom>E-commerce Integrations</Typography>
                    <br/>
                </div> */}
          </Grid>
          </PagePaper>
          </Grid>
      </div>
    );
  }
}

Settings.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Settings);
