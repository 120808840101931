import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import React from "react";
import LoadingLogo from "../../img/logo-vertical.svg";
import "../../styles/Loading.css";

export default class Loading extends React.Component {
  render() {
    return (
      <div className="Loading" style={{height:'100vh',backgroundColor: "#051e51"}}>
        <img src={LoadingLogo} alt="Swiftpost Logo" className="loadingIcon" />
        <LinearProgress style={{ height: "5px" }} />
        <Typography className="loadingText">{this.props.text}</Typography>
      </div>
    );
  }
}
