// /* eslint-disable react/no-multi-comp */

// import Avatar from "@material-ui/core/Avatar";
// import Button from "@material-ui/core/Button";
// import blue from "@material-ui/core/colors/blue";
// import Dialog from "@material-ui/core/Dialog";
// import DialogTitle from "@material-ui/core/DialogTitle";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemAvatar from "@material-ui/core/ListItemAvatar";
// import ListItemText from "@material-ui/core/ListItemText";
// import { withStyles } from "@material-ui/core/styles";
// import Typography from "@material-ui/core/Typography";
// import AddIcon from "@material-ui/icons/Add";
// import PersonIcon from "@material-ui/icons/LocationOn";
// import { getCookie } from "helpers/getCookie";
// import PropTypes from "prop-types";
// import React from "react";
// import FindAddress from "./FindAddress";

// const styles = (theme) => ({
//   avatar: {
//     backgroundColor: blue[100],
//     color: blue[600],
//   },
//   actionsContainer: {
//     marginBottom: theme.spacing.unit * 2,
//   },
// });

// class SimpleDialog extends React.Component {
//   state = {};

//   UNSAFE_componentWillMount = async () => {
//     let language = getCookie("language");
//     this.setState({ language: language != "" ? language : "EN" });
//   };

//   language = {
//     Selectalocation: {
//       FR: "Sélectionner un lieu",
//       EN: "Select a location",
//     },
//     Addalocation: {
//       FR: "Ajouter l'emplacement",
//       EN: "Add a location",
//     },
//   };

//   render() {
//     const { classes, onClose, selectedValue, ...other } = this.props;
//     return (
//       <Dialog
//         onClose={() => this.props.onClose(-1)}
//         aria-labelledby="simple-dialog-title"
//         {...other}
//       >
//         <DialogTitle id="simple-dialog-title">
//           {this.language.Selectalocation[this.state.language]}
//         </DialogTitle>
//         <div>
//           <List>
//             {this.props.locations.map((location, i) => (
//               <ListItem
//                 button
//                 onClick={() =>
//                   this.props.onClose({ id: location.id, location: location })
//                 }
//                 key={i}
//               >
//                 <ListItemAvatar>
//                   <Avatar className={classes.avatar}>
//                     <PersonIcon />
//                   </Avatar>
//                 </ListItemAvatar>
//                 <ListItemText
//                   primary={location.title}
//                   secondary={`${location.address.street_number} ${location.address.street_name}. ${location.address.city}, ${location.address.state} ${location.address.postal}`}
//                 />
//               </ListItem>
//             ))}
//               <ListItem
//                 button
//                 onClick={() => {
//                   this.props.handleModalOpen();
//                 }}
//               >
//                 <ListItemAvatar>
//                   <Avatar>
//                     <AddIcon />
//                   </Avatar>
//                 </ListItemAvatar>
//                 <ListItemText
//                   primary={this.language.Addalocation[this.state.language]}
//                 />
//               </ListItem>
//           </List>
//         </div>
//       </Dialog>
//     );
//   }
// }

// SimpleDialog.propTypes = {
//   classes: PropTypes.object.isRequired,
//   onClose: PropTypes.func,
//   selectedValue: PropTypes.string,
// };

// const SimpleDialogWrapped = withStyles(styles)(SimpleDialog);

// class LocationPicker extends React.Component {
//   state = {
//     open: false,
//     selectedValue: "",
//     modalOpen: false,
//   };

//   UNSAFE_componentWillMount = async () => {
//     let language = getCookie("language");
//     this.setState({ language: language != "" ? language : "EN" });
//   };

//   language = {
//     LocationSelected: {
//       FR: "Emplacement sélectionné",
//       EN: "Location Selected",
//     },
//     NoLocations: {
//       FR: "Aucun emplacement ajouté, créez-en un en cliquant sur le bouton ci-dessus.",
//       EN: "No locations added, create one by clicking the button above.",
//     },
//     Back: {
//       FR: "Arrière",
//       EN: "Back",
//     },
//     Next: {
//       FR: "Suivant",
//       EN: "Next",
//     },
//     SelectLocation: {
//       FR: "SÉLECTIONNEZ L'EMPLACEMENT",
//       EN: "SELECT LOCATION",
//     },
//   };

//   handleClickOpen = () => {
//     this.setState({
//       open: true,
//     });
//   };

//   handleClose = (value) => {
//     console.log(value);
//     if (value != -1) {
//       this.props.handleLocationChange(value);
//     } else if(this.props.open){ this.props.onClose()}
//   };

//   handleModalClose = () => {
//     this.setState({ modalOpen: false });
//   };

//   handleModalOpen = () => {
//     console.log("FIRED OPEN")
//     this.setState({ modalOpen: true });
//   };

//   render() {
//     // alert(this.props.senderLocationID)
//     const { classes } = this.props;
//     var locationID = "";
//     if (this.props.locations && this.props.locations.length > 0) {
//       locationID = this.props.locations[0].id;
//     } else {
//       locationID = this.props.senderLocationID
//         ? this.props.senderLocationID
//         : false;
//     }
//     // console.log(locationID)
//     let sample = this.props.locations.map((e, i) =>
//       e.id === locationID ? i : false
//     );
//     sample = sample.filter((e) => e !== false);
//     sample = sample[0];

//     return (
//       <div>
//         {this.props.hidden ? <><FindAddress
//           finalAction={(state) => {
//             this.props.addLocation(state);
//           }}
//           open={this.state.modalOpen}
//           handleClose={()=>this.handleModalClose()}
//           // {...this.props}
//         />
//         <SimpleDialogWrapped
//           selectedValue={this.props.senderLocation}
//           open={this.props.open ?? this.state.open}
//           onClose={(e) => {console.log(e);this.handleClose(e)}}
//           handleModalOpen={() => this.handleModalOpen()}
//           locations={this.props.locations}
//           // {...this.props}
//         /></>:
//           <>
//           <Button
//           onClick={this.handleClickOpen}
//           variant={this.props.locations.length > 0 ? "outlined" : "contained"}
//           color="primary"
//         >
//           {this.props.locations.length > 0
//             ? this.language.SelectLocation[this.state.language]
//             : this.language.SelectLocation[this.state.language]}{" "}
//         </Button>
//         <br />
//         <br />
//         <FindAddress
//           finalAction={(state) => {
//             this.props.addLocation(state);
//           }}
//           open={this.state.modalOpen}
//           handleClose={this.handleModalClose}
//           {...this.props}
//         />
//         {this.props.locations.length > 0 ? (
//           <Typography variant="subheading">
//             <strong>
//               {this.language.LocationSelected[this.state.language]}:
//             </strong>
//             <br />
//             {this.props.locations[sample].title}
//             <br />
//             {this.props.locations[sample].contact.fname}{" "}
//             {this.props.locations[sample].contact.lname}
//             <br />
//             {this.props.locations[sample].address.unit && (
//               <span>
//                 #{this.props.locations[sample].address.unit}&nbsp;-&nbsp;
//               </span>
//             )}
//             {this.props.locations[sample].address.street_number}{" "}
//             {this.props.locations[sample].address.street_name}
//             <br />
//             {this.props.locations[sample].address.city},{" "}
//             {this.props.locations[sample].address.province}{" "}
//             {this.props.locations[sample].address.postal}
//             <br />
//           </Typography>
//         ) : (
//           <span>
//             {this.language.NoLocations[this.state.language]}
//             <br />
//           </span>
//         )}
//         <br />
//         <SimpleDialogWrapped
//           selectedValue={this.props.senderLocation}
//           open={this.state.open}
//           onClose={(e) => this.handleClose(e)}
//           handleModalOpen={() => this.handleModalOpen()}
//           {...this.props}
//         />
//         <br />
//         <br />
//         {this.props.showButtons && (
//           <div className={classes.actionsContainer}>
//             <div>
//               <Button
//                 onClick={() => this.props.handleBack("activeStep")}
//                 className={classes.button}
//               >
//                 {this.language.Back[this.state.language]}
//               </Button>
//               <Button
//                 disabled={this.props.locations.length == 0}
//                 variant="contained"
//                 color="primary"
//                 onClick={() => {
//                   this.props.handleNext("activeStep");
//                 }}
//                 className={classes.button}
//               >
//                 {this.language.Next[this.state.language]}
//               </Button>
//             </div>
//           </div>
//         )}
//         </>
//       }
//       </div>
//     );
//   }
// }

// export default LocationPicker;


/* eslint-disable react/no-multi-comp */

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import blue from "@material-ui/core/colors/blue";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import PersonIcon from "@material-ui/icons/LocationOn";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import React from "react";
import FindAddress from "./FindAddress";

const styles = (theme) => ({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2,
  },
});

class SimpleDialog extends React.Component {
  state = {};

  UNSAFE_componentWillMount = async () => {
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });
  };

  language = {
    Selectalocation: {
      FR: "SÃ©lectionner un lieu",
      EN: "Select a location",
    },
    Addalocation: {
      FR: "Ajouter l'emplacement",
      EN: "Add a location",
    },
  };

  render() {
    const { classes, onClose, selectedValue, ...other } = this.props;
    return (
      <Dialog
        onClose={() => this.props.onClose(-1)}
        aria-labelledby="simple-dialog-title"
        {...other}
      >
        <DialogTitle id="simple-dialog-title">
          {this.language.Selectalocation[this.state.language]}
        </DialogTitle>
        <div>
          <List>
            {this.props.locations.map((location, i) => (
              <ListItem
                button
                onClick={() =>
                  this.props.onClose({ id: location.id, location: location })
                }
                key={i}
              >
                <ListItemAvatar>
                  <Avatar className={classes.avatar}>
                    <PersonIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={location.title}
                  secondary={`${location.address.street_number} ${location.address.street_name}. ${location.address.city}, ${location.address.state} ${location.address.postal}`}
                />
              </ListItem>
            ))}
            {this.props.account &&
            this.props.account.user &&
            this.props.account.user.cannotShipCollect ? (
              ""
            ) : (
              <ListItem
                button
                onClick={() => {
                  this.props.handleModalOpen();
                }}
              >
                <ListItemAvatar>
                  <Avatar>
                    <AddIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={this.language.Addalocation[this.state.language]}
                />
              </ListItem>
            )}
          </List>
        </div>
      </Dialog>
    );
  }
}

SimpleDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  selectedValue: PropTypes.string,
};

const SimpleDialogWrapped = withStyles(styles)(SimpleDialog);

class LocationPicker extends React.Component {
  state = {
    open: false,
    selectedValue: "",
    modalOpen: false,
  };

  UNSAFE_componentWillMount = async () => {
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });
  };

  language = {
    LocationSelected: {
      FR: "Emplacement sÃ©lectionnÃ©",
      EN: "Location Selected",
    },
    NoLocations: {
      FR: "Aucun emplacement ajoutÃ©, crÃ©ez-en un en cliquant sur le bouton ci-dessus.",
      EN: "No locations added, create one by clicking the button above.",
    },
    Back: {
      FR: "ArriÃ¨re",
      EN: "Back",
    },
    Next: {
      FR: "Suivant",
      EN: "Next",
    },
    SelectLocation: {
      FR: "SÃ‰LECTIONNEZ L'EMPLACEMENT",
      EN: "SELECT LOCATION",
    },
  };

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = (value) => {
    // console.log(value)
    if (value != -1) {
      this.props.handleLocationChange(value);
    }
    this.setState({ open: false });
    this.props.onClose();
  };

  handleModalClose = () => {
    this.setState({ modalOpen: false });
  };

  handleModalOpen = () => {
    this.setState({ modalOpen: true });
  };

  render() {
    // alert(this.props.senderLocationID)
    const { classes } = this.props;
    var locationID = "";
    if (this.props.locations && this.props.locations.length === 1) {
      locationID = this.props.locations[0].id;
    } else {
      locationID = this.props.senderLocationID
        ? this.props.senderLocationID
        : false;
    }
    // console.log(locationID)
    let sample = this.props.locations.map((e, i) =>
      e.id === locationID ? i : false
    );
    sample = sample.filter((e) => e !== false);
    sample = sample[0];

    return (
      this.props.hidden ? <><FindAddress
        finalAction={(state) => {
          this.props.addLocation(state);
        }}
        open={this.state.modalOpen}
        handleClose={()=>this.handleModalClose()}
        // {...this.props}
      />
      <SimpleDialogWrapped
        selectedValue={this.props.senderLocation}
        open={this.props.open ?? this.state.open}
        onClose={(e) => {console.log(e);this.handleClose(e)}}
        handleModalOpen={() => this.handleModalOpen()}
        locations={this.props.locations}
        // {...this.props}
      /></>:
      <div>
        <Button
          onClick={this.handleClickOpen}
          variant={this.props.locations.length > 0 ? "outlined" : "contained"}
          color="primary"
        >
          {this.props.locations.length > 0
            ? this.language.SelectLocation[this.state.language]
            : this.language.SelectLocation[this.state.language]}{" "}
        </Button>
        <br />
        <br />
        <FindAddress
          finalAction={(state) => {
            this.props.addLocation(state);
          }}
          open={this.state.modalOpen}
          handleClose={this.handleModalClose}
          {...this.props}
        />
        {this.props.locations.length > 0 ? (
          <Typography variant="subheading">
            <strong>
              {this.language.LocationSelected[this.state.language]}:
            </strong>
            <br />
            {this.props.locations[sample].title}
            <br />
            {this.props.locations[sample].contact.fname}{" "}
            {this.props.locations[sample].contact.lname}
            <br />
            {this.props.locations[sample].address.unit && (
              <span>
                #{this.props.locations[sample].address.unit}&nbsp;-&nbsp;
              </span>
            )}
            {this.props.locations[sample].address.street_number}{" "}
            {this.props.locations[sample].address.street_name}
            <br />
            {this.props.locations[sample].address.city},{" "}
            {this.props.locations[sample].address.province}{" "}
            {this.props.locations[sample].address.postal}
            <br />
          </Typography>
        ) : (
          <span>
            {this.language.NoLocations[this.state.language]}
            <br />
          </span>
        )}
        <br />
        <SimpleDialogWrapped
          selectedValue={this.props.senderLocation}
          open={this.state.open}
          onClose={(e) => this.handleClose(e)}
          handleModalOpen={() => this.handleModalOpen()}
          {...this.props}
        />
        <br />
        <br />
        {this.props.showButtons && (
          <div className={classes.actionsContainer}>
            <div>
              <Button
                onClick={() => this.props.handleBack("activeStep")}
                className={classes.button}
              >
                {this.language.Back[this.state.language]}
              </Button>
              <Button
                disabled={this.props.locations.length == 0}
                variant="contained"
                color="primary"
                onClick={() => {
                  this.props.handleNext("activeStep");
                }}
                className={classes.button}
              >
                {this.language.Next[this.state.language]}
              </Button>
            </div>
          </div>
        )}
      </div>
      
    );
  }
}

export default LocationPicker;