import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    padding: "25px 30px 50px 30px",
  },
  textField: {
    width: "100%",
    margin: "10px 0px",
  },
});

class OutlinedInputAdornments extends React.Component {
  state = {
    name: "",
    shipmentCount: "",
  };

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
    this.props.updateState(name, event.target.value);
  };

  render() {
    const language = {
      Pleaseenteryourbillingaddress: {
        FR: "Veuillez saisir votre adresse de facturation",
        EN: "Please enter your shipping address",
      },
      StreetNumber: {
        FR: "Numéro de rue",
        EN: "Street Number",
      },
      StreetName: {
        FR: "Nom de rue",
        EN: "Street Name",
      },
      PostalCode: {
        FR: "Code postal",
        EN: "Postal Code",
      },
      PhoneNumber: {
        FR: "Numéro de téléphone",
        EN: "Phone Number",
      },
    };
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <h3 style={{ marginBottom: "25px" }}>
          {language.Pleaseenteryourbillingaddress[this.props.language]}
        </h3>
        <TextField
          required
          disabled={this.props.isLoading}
          id="outlined-simple-start-adornment"
          className={classNames(classes.textField)}
          variant="outlined"
          value={this.props.street_number}
          onChange={this.props.updateState("street_number")}
          label={language.StreetNumber[this.props.language]}
          error={this.props.errorObj.street_number}
        />
        <TextField
          required
          disabled={this.props.isLoading}
          id="outlined-simple-start-adornment"
          className={classNames(classes.textField)}
          variant="outlined"
          value={this.props.street_name}
          onChange={this.props.updateState("street_name")}
          label={language.StreetName[this.props.language]}
          error={this.props.errorObj.street_name}
        />
        <TextField
          fullWidth
          required
          disabled={this.props.isLoading}
          id="outlined-simple-start-adornment"
          className={classNames(classes.textField)}
          variant="outlined"
          value={this.props.zip}
          error={this.props.errorObj.zip}
          onChange={this.props.updateState("zip")}
          label={language.PostalCode[this.props.language]}
        />
        <TextField
          fullWidth
          required
          disabled={this.props.isLoading}
          id="outlined-simple-start-adornment"
          className={classNames(classes.textField)}
          variant="outlined"
          error={this.props.errorObj.phone}
          value={this.props.phone}
          onChange={this.props.updateState("phone")}
          label={language.PhoneNumber[this.props.language]}
        />
      </div>
    );
  }
}

OutlinedInputAdornments.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OutlinedInputAdornments);