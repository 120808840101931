/* eslint-disable react/no-multi-comp */

import {
  CircularProgress,
  Collapse,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import blue from "@material-ui/core/colors/blue";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { WarningOutlined } from "@material-ui/icons";
import { API, Auth } from "aws-amplify";
import $ from "jquery";
import React, { Fragment } from "react";
import LocationPicker from "../components/LocationPicker";
import CSV from "./../img/spreadsheet.png";

const styles = {
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
};

class UploadOrdersDialog extends React.Component {
  state = {
    error: false,
    open: false,
    postal: "",
    error: false,
    activeStep: 0,
    rows: [],
    inputID: new Date().getTime(),
    senderLocationID:
      this.props.locations.length > 0 ? this.props.locations[0].id : "",
    senderLocation:
      this.props.locations.length > 0 ? this.props.locations[0] : "",
  };

  UNSAFE_componentWillMount = () => {
    // const socket2 = new WebSocket('wss://keuhz93lxj.execute-api.us-east-1.amazonaws.com/dev');
    // socket2.addEventListener('open', function (event) {
    //     socket2.send(JSON.stringify({'action':'sendMessage'}));
    //     // alert('connected')
    //   });
    // socket2.addEventListener('message', event => {
    //     // alert('socket msg');
    //     // console.log(JSON.parse(event.data));
    //     let data = JSON.parse(event.data);
    //     if(data.connectionId){
    //         // alert(data.connectionId)
    //         this.setState({connection_id: data.connectionId})
    //         // console.log("CONNECTED, ",data.connectionId)
    //     }
    //     // if(data.failed_row){
    //     //   this.
    //     // }
    //     // if(data.success_row){
    //     // }
    // })
  };

  componentWillUnmount = () => {
    this.setState({ isLoading: false });
  };

  handleListItemClick = (value) => {
    this.props.onClose(value);
  };

  handleChange = (name) => (event) => {
    let tempString = event.target.value;
    this.setState({ [name]: tempString.toUpperCase() });
  };

  handleLocationChange = (e) => {
    // alert('trying')
    this.setState({ senderLocation: e.location });
    this.setState({ senderLocationID: e.id });
  };

  getFileData = (e) => {
    this.setState({ rows: [] });

    if (e.target.files && e.target.files[0]) {
      this.setState({ errors: [] });

      var FR = new FileReader();

      // this.setState({isLoading: true});

      FR.addEventListener("load", async (e) => {
        this.setState({ error: false });

        var temp = e.target.result.replace(/^data*.*base64,/gm, "");
        temp = atob(temp);
        const csv = require("csvtojson");
        csv()
          .fromString(temp)
          .then((csvRow) => {
            // console.log(csvRow)
            let errors = [];

            if (csvRow.length > 501) {
              errors.push("File cannot exceed 500 data rows");
            }

            console.log(csvRow);

            if (
              !csvRow[0].reference ||
              !csvRow[0].receiver_fname ||
              !csvRow[0].receiver_lname ||
              !csvRow[0].receiver_address1 ||
              !csvRow[0].receiver_city ||
              !csvRow[0].receiver_state ||
              !csvRow[0].receiver_postal ||
              !csvRow[0].receiver_country
            ) {
              errors.push("Header row is invalid on line 0");
            }

            try {
              if (errors.length == 0) {
                let cleanRows = csvRow.map((f) => {
                  let item = {
                    fulfillment_type: f.fulfillment_type,
                    pickup_services: f.pickup_services,
                    delivery_services: f.delivery_services,
                    sender_open_time: f.sender_open_time
                      ? f.sender_open_time.trim().substr(0, 50).toUpperCase()
                      : "",
                    sender_close_time: f.sender_close_time
                      ? f.sender_close_time.trim().substr(0, 50).toUpperCase()
                      : "",
                    receiver_open_time: f.receiver_open_time
                      ? f.receiver_open_time.trim().substr(0, 50).toUpperCase()
                      : "",
                    receiver_close_time: f.receiver_close_time
                      ? f.receiver_close_time.trim().substr(0, 50).toUpperCase()
                      : "",
                    open_time: f.open_time
                      ? f.open_time.trim().substr(0, 50).toUpperCase()
                      : "",
                    close_time: f.close_time
                      ? f.close_time.trim().substr(0, 50).toUpperCase()
                      : "",
                    location_note: f.location_note
                      ? f.location_note.trim().substr(0, 50).toUpperCase()
                      : "",
                    receiver_email: f.receiver_email
                      ? f.receiver_email.trim().substr(0, 50).toUpperCase()
                      : "",
                    receiver_phone: f.receiver_phone
                      ? f.receiver_phone.trim().substr(0, 50).toUpperCase()
                      : "",
                    reference: f.reference
                      .replace(/[^\w\s]/g, "")
                      .replace(/\s+/g, " ")
                      .trim()
                      .substr(0, 25)
                      .toUpperCase(),
                    package_height_in:f.package_height_in ? Number(
                      f.package_height_in.replace(/[^0-9.]/g, "")
                    )
                      .toFixed(3)
                      .substr(0, 10)
                      .toUpperCase() : "0",
                    package_length_in: f.package_length_in ? Number(
                      f.package_length_in.replace(/[^0-9.]/g, "")
                    )
                      .toFixed(3)
                      .substr(0, 10)
                      .toUpperCase() : "0",
                    package_width_in: f.package_width_in ? Number(
                      f.package_width_in.replace(/[^0-9.]/g, "")
                    )
                      .toFixed(3)
                      .substr(0, 10)
                      .toUpperCase() : "0",
                    package_weight_lb: f.package_weight_lb ? Number(
                      f.package_weight_lb.replace(/[^0-9.]/g, "")
                    )
                      .toFixed(3)
                      .substr(0, 10)
                      .toUpperCase() : "0",
                    receiver_business: f.receiver_business
                      ? f.receiver_business
                          .replace(/[^\w\s]/g, "")
                          .replace(/\s+/g, " ")
                          .trim()
                          .substr(0, 20)
                          .toUpperCase()
                      : "",
                    receiver_fname: f.receiver_fname
                      .replace(/[^\w\s]/g, "")
                      .replace(/\s+/g, " ")
                      .trim()
                      .substr(0, 20)
                      .toUpperCase(),
                    receiver_lname: f.receiver_lname
                      .replace(/[^\w\s]/g, "")
                      .replace(/\s+/g, " ")
                      .trim()
                      .substr(0, 20)
                      .toUpperCase(),
                    receiver_address1: f.receiver_address1
                      .replace(/[^A-Za-z0-9# -]/g, "")
                      .replace(/\s+/g, " ")
                      .trim()
                      .substr(0, 30)
                      .toUpperCase(),
                    receiver_address2: f.receiver_address2
                      .replace(/[^A-Za-z0-9# -]/g, "")
                      .replace(/\s+/g, " ")
                      .trim()
                      .substr(0, 30)
                      .toUpperCase(),
                    receiver_city: f.receiver_city
                      .replace(/[^A-Za-z0-9 -]/g, "")
                      .replace(/\s+/g, " ")
                      .trim()
                      .substr(0, 20)
                      .toUpperCase(),
                    receiver_state: f.receiver_state
                      .replace(/[^\w]/g, "")
                      .trim()
                      .substr(0, 2)
                      .toUpperCase(),
                    receiver_postal:
                      f.receiver_country.toUpperCase() == "CA"
                        ? f.receiver_postal
                            .replace(/[^\w]/g, "")
                            .trim()
                            .substr(0, 6)
                        : f.receiver_postal
                            .replace(/[^\w]/g, "")
                            .trim()
                            .substr(0, 5)
                            .padStart(5, "0"),
                    receiver_country: f.receiver_country
                      .replace(/[^\w]/g, "")
                      .trim()
                      .substr(0, 2)
                      .toUpperCase(),
                    package_contents_title: f.package_contents_title
                      ? f.package_contents_title
                          .replace(/[^\w\s]/g, "")
                          .replace(/\s+/g, " ")
                          .trim()
                          .substr(0, 30)
                          .toUpperCase()
                      : "",
                    package_contents_total_value_usd:
                      f.package_contents_total_value_usd
                        ? Number(
                            f.package_contents_total_value_usd.replace(
                              /[^0-9.]/g,
                              ""
                            )
                          )
                            .toFixed(3)
                            .substr(0, 10)
                            .toUpperCase()
                        : "",
                    package_contents_total_value: f.package_contents_total_value
                      ? Number(
                          f.package_contents_total_value.replace(/[^0-9.]/g, "")
                        )
                          .toFixed(3)
                          .substr(0, 10)
                          .toUpperCase()
                      : "",
                    package_contents_item_quantity:
                      f.package_contents_item_quantity
                        ? f.package_contents_item_quantity
                            .replace(/[^0-9]/g, "")
                            .trim()
                        : "",
                    package_contents_origin_country:
                      f.package_contents_origin_country
                        ? f.package_contents_origin_country
                            .replace(/[^\w]/g, "")
                            .trim()
                            .substr(0, 2)
                            .toUpperCase()
                        : "",
                    service_level: f.service_level ? f.service_level
                      .replace(/[^\w]/g, "")
                      .trim()
                      .toUpperCase() : "",
                  };
                  if (f.receiver_email) {
                    item.receiver_email = f.receiver_email;
                  }
                  return item;
                });
                console.log(cleanRows);
                this.setState({
                  rows: cleanRows,
                  inputID: new Date().getTime(),
                });
                this.props.increaseUploadStep();
              } else {
                this.setState({ rows: [] });
                this.setState({
                  errors: errors,
                  inputID: new Date().getTime(),
                });
                this.setState({ error: true });
              }
            } catch (e) {
              console.log(JSON.stringify(e));
              this.setState({ errors: errors, inputID: new Date().getTime() });
              this.setState({ error: true });
            }
          });
      });
      FR.readAsDataURL(e.target.files[0]);
    }
  };

  importOrders = async (rows) => {
    // alert('Uploading...');
    //COMEBACK
    this.setState({ isLoading: true });
    // this.props.toggleUploading(true);
    // console.log({
    //     body: {
    //       shipments: rows,
    //       cogsub:this.props.account.cognitoUsername,
    //       senderLocation: this.state.senderLocation,
    //       senderLocationID: this.state.senderLocationID,
    //       connection_id:this.props.connection_id
    //     }
    // })
    let email = await Auth.currentSession();
    // console.log(email.idToken.payload.email);

    console.log({
      body: {
        email: email.idToken.payload.email,
        shipments: rows,
        cognito_identity: this.props.account.user.userId,
        cogsub: this.props.account.cognitoUsername,
        senderLocation: this.state.senderLocation,
        senderLocationID: this.state.senderLocationID,
        connection_id: this.props.connection_id,
      },
    });
    API.post("uploadorders", "/upload-chunks", {
      body: {
        email: email.idToken.payload.email,
        cognito_identity: this.props.account.user.userId,
        shipments: rows,
        cogsub: this.props.account.cognitoUsername,
        senderLocation: this.state.senderLocation,
        senderLocationID: this.state.senderLocationID,
        connection_id: this.props.connection_id,
      },
    })
      .then((e) => {
        console.log(e);
        // alert("success")
        this.props.set_upload_chunk_count(e.chunk_count);

        //   console.log(e);
      })
      .catch((e) => {
        this.setState({ isLoading: false });
        // this.props.toggleUploading(false);
        this.setState({ error: true });
        // alert("There was an error uploading your file. Please try again later.")
        // console.log(e);
      });
  };

  handleBack = () => {
    // alert('trying to bak')
  };

  render() {
    return (
      <div>
        <input
          style={{
            zIndex: "-2",
            opacity: "0",
            position: "fixed",
            top: -300,
            left: -500,
          }}
          accept=".csv"
          // id="contained-button-file"
          id="importOrders"
          key={this.state.inputID}
          type="file"
          onChange={(e) => {
            this.getFileData(e);
          }}
        />
        <Dialog
          open={this.props.open}
          // onClose={()=>{if(!(this.state.isLoading)){this.props.handleClose()}}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {this.state.isLoading ? (
            <Fragment>
              <DialogContent style={{ padding: "20px 50px" }}>
                <center>
                  {!this.props.upload_chunk_count && (
                    <CircularProgress width="20" />
                  )}
                  <Typography variant="h1">Importing Orders</Typography>
                  {!this.props.upload_chunk_count ? (
                    <small style={{ color: "rgba(0,0,0,.3)" }}>
                      PLEASE WAIT
                    </small>
                  ) : (
                    <>
                      <small style={{ color: "rgba(0,0,0,.3)" }}>
                        PROCESSING FILE
                      </small>
                      <br />
                      <br />
                      <LinearProgress
                        variant="determinate"
                        value={
                          (this.props.completed /
                            this.props.upload_chunk_count) *
                          100
                        }
                      />
                      <br />
                      <small>
                        <strong style={{ color: "var(--brand-cyan)" }}>
                          {Math.ceil(
                            (this.props.completed /
                              this.props.upload_chunk_count) *
                              100
                          )}
                          %
                        </strong>
                      </small>
                    </>
                  )}
                </center>
              </DialogContent>
            </Fragment>
          ) : this.props.uploadStep == 0 ? (
            <Fragment>
              <DialogContent style={{ padding: "20px 50px" }}>
                <img
                  src={CSV}
                  style={{
                    padding: "25px 30px 15px 0px",
                    marginTop: "15px",
                    marginBottom: "10px",
                    maxWidth: "25%",
                    float: "left",
                  }}
                />
                <small
                  style={{
                    color: "var(--brand-cyan)",
                    textTransform: "uppercase",
                  }}
                >
                  <strong>
                    <br />
                    Import Orders from a File
                  </strong>
                </small>
                <br />
                <br />
                <small>
                  You can import your order directly into Swiftpost by following{" "}
                  <a
                    style={{
                      color: "var(--brand-cyan)",
                      textDecoration: "underline",
                      fontWeight: "bold",
                    }}
                    href="https://images.swiftpost.com/csv/Swiftpost+Import+Orders+Template.csv"
                    target="_blank"
                    rel="noreferrer"
                  >
                    this CSV template
                  </a>
                  .<br />
                  <br />
                  For multipiece orders, use the same Order ID on the line below
                  (
                  <a
                    style={{
                      color: "var(--brand-cyan)",
                      textDecoration: "underline",
                      fontWeight: "bold",
                    }}
                    href="https://images.swiftpost.com/csv/Swiftpost+Import+Orders+Multipiece+Example.csv"
                    target="_blank"
                    rel="noreferrer"
                  >
                    see example&nbsp;file
                  </a>
                  ).
                </small>
                {/* <Button fullWidth variant="contained" color="secondary" onClick={()=>$('input[type=file]').trigger('click')}>Import multiple accounts</Button> */}
                <br />
                <br />
                {this.state.error ? (
                  <Collapse in={this.state.error}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        background: "rgba(255,0,0,.1)",
                        padding: "10px 25px",
                        borderRadius: "5px",
                        color: "red",
                        fontSize: ".9em",
                      }}
                    >
                      <WarningOutlined
                        style={{ float: "left", marginRight: "15px" }}
                      />{" "}
                      <div>
                        <strong>Unable to process file</strong>
                        <br />
                        {this.state.errors.map((e) => (
                          <li>{e}</li>
                        ))}
                      </div>
                    </div>
                  </Collapse>
                ) : (
                  ``
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  onClick={() =>
                    $("input[type=file]#importOrders").trigger("click")
                  }
                >
                  Import&nbsp;File
                </Button>
                <Button onClick={this.props.handleClose} color="default">
                  Cancel
                </Button>
              </DialogActions>
            </Fragment>
          ) : (
            <Fragment>
              <DialogContent style={{ padding: "20px 50px" }}>
                <small
                  style={{
                    color: "var(--brand-cyan)",
                    textTransform: "uppercase",
                  }}
                >
                  <strong>
                    <br />
                    Select Sender Location
                  </strong>
                </small>
                <br />
                <br />
                <br />
                <LocationPicker
                  senderLocationID={this.state.senderLocationID}
                  account={this.props.account}
                  showButtons={false}
                  locations={this.props.locations}
                  handleLocationChange={(e) => {
                    this.handleLocationChange(e);
                  }}
                  handleBack={(e) => this.handleBack(e)}
                />
                <Collapse in={this.state.error}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      background: "rgba(255,0,0,.1)",
                      padding: "10px 25px",
                      borderRadius: "5px",
                      color: "red",
                      fontSize: ".9em",
                    }}
                  >
                    <WarningOutlined
                      style={{ float: "left", marginRight: "15px" }}
                    />{" "}
                    <div>
                      <strong>Unable to process your file</strong>
                      <br />
                      <small>
                        Please confirm that your selected file is formatted
                        correctly according to our{" "}
                        <a href="#">CSV Import Template</a>
                      </small>
                    </div>
                  </div>
                </Collapse>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  onClick={() => this.importOrders(this.state.rows)}
                >
                  Import orders ({this.state.rows.length} rows)
                </Button>
                <Button
                  onClick={() => {
                    this.props.handleClose();
                  }}
                  color="default"
                >
                  Cancel
                </Button>
              </DialogActions>
            </Fragment>
          )}
        </Dialog>
      </div>
    );
  }
}

export default UploadOrdersDialog;
