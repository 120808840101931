import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import RateCard from "../RateCard";

const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  menu: {
    width: 200,
  },
  formControl: {
    margin: theme.spacing.unit,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  formControl: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2,
    display: "block",
  },
  resetContainer: {
    padding: theme.spacing.unit * 3,
  },
});

class TextFields extends React.Component {
  state = {};

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  render() {
    const { classes } = this.props;
    let rates = this.props.rates;
    rates = rates.sort((e, i) => {
      return e.rate - i.rate;
    });
    // console.log(rates)
    return (
      <div>
        <br />
        <List style={{ margin: "auto", display: "block" }}>
          {rates.map((e, i) => (
            <RateCard
              data={e}
              key={i}
              number={i}
              {...this.props}
              selectedService={this.props.selectedService}
              handleChange={(e) => this.props.handleChange(e)}
            />
          ))}
        </List>
        <div className={classes.actionsContainer}>
          <Paper square elevation={0} className={classes.resetContainer}>
            <Button
              onClick={() => this.props.handleBack("activeStep")}
              className={classes.button}
            >
              Back
            </Button>
            <Button
              onClick={() => this.props.handleNext("activeStep")}
              className={classes.button}
              variant="contained"
              color="primary"
            >
              Continue
            </Button>
          </Paper>
        </div>
      </div>
    );
  }
}

TextFields.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TextFields);
