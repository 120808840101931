/* eslint-disable react/no-multi-comp */
// import { API } from "aws-amplify";

import React, { Fragment } from "react";
// import { Auth } from "aws-amplify";
import ReactDOM from "react-dom";

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import blue from "@material-ui/core/colors/blue";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Tooltip from "@material-ui/core/Tooltip";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import MobileStepper from "@material-ui/core/MobileStepper";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { Auth, API, graphqlOperation } from "aws-amplify";

import Form1 from "./NewUserForm1";
import Form2 from "./NewUserForm2";
import Form3 from "./NewUserForm3";

import updateAccount from "../api/app/updateAccount";
import { isAlphaNumeric } from "../helpers/Validation";

import {
  isValidPostal,
  isDollarValue,
  isAlphaNumericWithSpace,
  isWeight,
  isAlphaNumericEmail,
  isValidPhoneNumber,
  isNumericOnly,
  isValidEmail,
  isMeasurement,
} from "../helpers/Validation";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

const styles = (theme) => ({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 50,
    paddingLeft: theme.spacing.unit * 4,
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 255,
    maxWidth: 400,
    overflow: "hidden",
    display: "block",
    width: "100%",
  },
});

class SimpleDialog extends React.Component {
  state = {
    activeStep: 0,
    activePrinters: [],
    qzActive: false,
  };

  componentDidMount = async () => {
    if (!window.qz.websocket.isActive()) {
      //qz is not active
      this.connect();
    } else {
      this.setState({ qzActive: true });
      //qz is installed and already connected, get printers
      this.getPrinters();
    }
  };

  connect = () => {
    this.setState({ isLoading: true });
    window.qz.websocket
      .connect({})
      .then((e) => {
        this.getPrinters();
        this.setState({ qzActive: true });
        this.setState({ activeStep: 1 });
        this.setState({ isLoading: false });
      })
      .catch((e) => {
        //assume that qz is either not installed or not turned on
        this.setState({ qzActive: false });
        this.setState({ isLoading: false });
      });
  };

  getPrinters = () => {
    window.qz.printers
      .find()
      .then((e) => {
        this.setState({
          activePrinters: e,
          isLoading: false,
        });
      })
      .catch((e) => {
        window.qz.printers
          .find()
          .then((e) => {
            this.setState({
              activePrinters: e,
              isLoading: false,
            });
          })
          .catch((e) => {
            alert("cannot get printers.");
          });
      });
  };

  handleNext = async () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep - 1,
    }));
  };

  handleClose = () => {
    this.props.onClose();
  };

  render() {
    const tutorialSteps = [
      <Paper className={styles.root2}>
        <div>
          <a href="/">Download Now</a>
          <br />
          <br />
          <Button onClick={this.connect}>Check connection</Button>
        </div>
      </Paper>,
      <div>
        <FormControl>
          <InputLabel htmlFor="age-simple">Select Printer</InputLabel>
          <Select
            value={this.state.age}
            onChange={this.handleChange}
            inputProps={{
              name: "age",
              id: "age-simple",
            }}
            style={{
              minWidth: "200px",
            }}
          >
            {this.state.activePrinters.map((e) => (
              <MenuItem value={e}>{e}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>,
      <div>All done!</div>,
    ];
    const { classes, theme } = this.props;
    const { activeStep } = this.state;
    const maxSteps = tutorialSteps.length;
    return (
      <Dialog
        open={this.props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.root}>
          {tutorialSteps[activeStep]}
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            className={classes.mobileStepper}
            nextButton={
              this.state.activeStep === 3 ? (
                <Button
                  size="small"
                  onClick={() => this.handleNext()}
                  disabled={
                    this.state.isLoading ||
                    (this.state.activeStep === 3
                      ? this.state.phone === "" ||
                        this.state.zip === "" ||
                        this.state.street_name === "" ||
                        this.state.street_number === ""
                      : false)
                  }
                >
                  Finish
                  {<KeyboardArrowRight />}
                </Button>
              ) : (
                <Button
                  size="small"
                  onClick={() => this.handleNext()}
                  disabled={
                    (this.state.activeStep === 1
                      ? this.state.fname === "" || this.state.lname === ""
                      : false) ||
                    (this.state.activeStep === 2
                      ? this.state.shipmentCount === ""
                      : false)
                  }
                >
                  Next
                  {<KeyboardArrowRight />}
                </Button>
              )
            }
            backButton={
              this.state.activeStep === 0 ? (
                <Button></Button>
              ) : (
                // <Button size="small" onClick={this.props.handleLogout}>
                //   Logout
                // </Button>
                <Button
                  size="small"
                  onClick={this.handleBack}
                  disabled={activeStep === 0}
                >
                  {<KeyboardArrowLeft />}
                  Back
                </Button>
              )
            }
          />
        </div>
      </Dialog>
    );
  }
}

SimpleDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  selectedValue: PropTypes.string,
};

const SimpleDialogWrapped = withStyles(styles)(SimpleDialog);

class QZStepper extends React.Component {
  state = {
    open: true,
  };

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = (value) => {
    this.setState({ selectedValue: value, open: false });
  };

  render() {
    return (
      <div>
        <SimpleDialogWrapped
          handleUserData={this.props.handleUserData}
          selectedValue={this.state.selectedValue}
          open={this.props.open}
          onClose={this.handleClose}
          {...this.props}
        />
      </div>
    );
  }
}

export default QZStepper;
