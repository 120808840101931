import { Button, CircularProgress, Theme, Typography } from "@material-ui/core";
import { ButtonProps } from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { ArrowBackIos, UnarchiveOutlined } from "@material-ui/icons";
import { AddressBookRecord } from "@shared";
import * as Papa from "papaparse";
import { ChangeEvent, useState } from "react";
import { useHistory } from "react-router-dom";
import CSV from "../../../img/spreadsheet.png";

const styles = (theme: Theme) => ({
  // https://v3.mui.com/demos/dialogs/#customized-dialog
  closeButton: {
    position: "absolute" as const,
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
});

function ImportCSVPanel(props: {
  classes: any;
  open: boolean;
  onClose: () => void;
  onParsed: (result: Papa.ParseResult<AddressBookRecord>) => void;
}) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const handleFile = (e: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);

    if (e.target.files) {
      Papa.parse<AddressBookRecord>(e.target.files[0], {
        header: true,
        skipEmptyLines: true,
        complete: async (result) => {
          setLoading(false);
          props.onParsed && props.onParsed(result);
        },
        error: (error) => {
          alert("The CSV is invalid. Please fix it and try again.");
        },
      });
    }
  };

  return (
    <div>
      <div style={{ display: "flex", padding: "12px 0" }}>
        <img
          src={CSV}
          alt="CSV Icon"
          style={{
            marginRight: 20,
            maxWidth: "132px",
          }}
        />
        <div>
          <Typography variant="h5">Import Addresses via CSV</Typography>
          <p>
            You can import addresses directly into Swiftpost by following{" "}
            <a href="https://images.swiftpost.com/csv/Swiftpost+Import+Addresses+Template.csv">
              this CSV template
            </a>
            .
          </p>
          {loading ? (
            <CircularProgress />
          ) : (
            <div style={{}}>
              <UploadCSVButton onChange={handleFile} />
            </div>
          )}
        </div>
      </div>
      <ReturnButton
        onClick={() => {
          history.push("/addressbook");
        }}
        style={{ marginTop: 16 }}
      />
    </div>
  );
}

const UploadCSVButton = (props: {
  onChange: (file: ChangeEvent<HTMLInputElement>) => void;
}) => (
  <>
    <input
      type="file"
      id="csv-upload"
      onChange={props.onChange}
      style={{ display: "none" }}
    />

    <label htmlFor="csv-upload">
      <Button variant="contained" color="primary" component="span" {...props}>
        <UnarchiveOutlined style={{ fontSize: "1.2em", marginRight: 6 }} />
        Upload CSV
      </Button>
    </label>
  </>
);

const ReturnButton = (props: ButtonProps) => (
  <Button component="span" {...props}>
    <ArrowBackIos style={{ fontSize: "1.2em", marginRight: 6 }} />
    Back to Address Book
  </Button>
);

export default withStyles(styles)(ImportCSVPanel);
