// import TextField from "@material-ui/core/TextField";

// import React, { Fragment } from "react";
// import PropTypes from "prop-types";
// import { withStyles } from "@material-ui/core/styles";
// import Stepper from "@material-ui/core/Stepper";
// import Step from "@material-ui/core/Step";
// import StepLabel from "@material-ui/core/StepLabel";
// import StepContent from "@material-ui/core/StepContent";
// import Button from "@material-ui/core/Button";
// import Paper from "@material-ui/core/Paper";
// import Grow from "@material-ui/core/Grow";
// import Typography from "@material-ui/core/Typography";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Switch from "@material-ui/core/Switch";
// import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
// import Select from "@material-ui/core/Select";
// import LoadingIcon from "../../components/loading/LoadingIcon";
// import Collapse from "@material-ui/core/Collapse";

// import { isNotEmpty } from "../../helpers/Validation";
// import resolver from "../../helpers/resolverCA";
// import getRates from "../../api/getRatesCanpar";

// import PackageType from "../../components/PackageType";
// import PackageMeasurements from "../../components/PackageMeasurements";

// import Amplify, { API, graphqlOperation, Auth } from "aws-amplify";

// const styles = theme => ({
//   textField: {
//     width: "100%",
//     boxSizing: "border-box",
//     fontSize: "1.5em"
//   },
//   menu: {
//     width: 200
//   },
//   formControl: {
//     marginTop: theme.spacing.unit * 2,
//     marginBottom: theme.spacing.unit,
//     width: "100%"
//   },
//   selectEmpty: {
//     marginTop: theme.spacing.unit * 2
//   },
//   root: {
//     ...theme.mixins.gutters(),
//     paddingTop: theme.spacing.unit * 2,
//     paddingBottom: theme.spacing.unit * 2
//   },
//   button: {
//     marginTop: theme.spacing.unit,
//     marginRight: theme.spacing.unit
//   },
//   actionsContainer: {
//     marginBottom: theme.spacing.unit * 2
//   },
//   resetContainer: {
//     padding: theme.spacing.unit * 3
//   }
// });

// class VerticalLinearStepper extends React.Component {
//   state = {
//     activeStep: 0,
//     isLoading: false,
//     connection_id: ""
//   };

// //   getSteps = () => {
// //     return ["Package Type", "Package measurements"]; // 'Package contents'];
// //   };

// getSteps = () => {
//     return [
//       this.language.PackageType[this.state.language],
//       this.language.PackageMeasurements[this.state.language],
//     //   this.language.PackageContents[this.state.language]
//     ];
//   };

//   componentDidMount = async () => {
//     // alert('socket')
//     const socket2 = new WebSocket(
//       "wss://keuhz93lxj.execute-api.us-east-1.amazonaws.com/dev"
//     );

//     socket2.addEventListener("open", function(event) {
//       socket2.send(JSON.stringify({ action: "sendMessage" }));
//       // alert('connected')
//     });

//     socket2.addEventListener("message", event => {
//       // alert('socket msg');
//       // console.log(JSON.parse(event.data));
//       let data = JSON.parse(event.data);
//       if (data.connectionId) {
//         // alert(data.connectionId)
//         this.setState({ connection_id: data.connectionId });
//         // console.log("CONNECTED, ",data.connectionId)
//       }
//       if (data.rate) {
//         if(data.rate.length){
//           this.props.setRates(
//             "rates",
//             data.rate.filter(f => {
//               return f.rate != 0;
//             })
//           );
//           this.props.handleNext("activeStep");
//         }else{
//           this.setState({rateError:true});
//           this.setState({isLoading:false});
//         }
//       }
//     });
//   };

//   UNSAFE_componentWillMount = async () => {
//     let language = getCookie("language");
//     this.setState({ language: language != "" ? language : "EN" });
//   };

//   getCookie(cname) {
//     var name = cname + "=";
//     var decodedCookie = decodeURIComponent(document.cookie);
//     var ca = decodedCookie.split(";");
//     for (var i = 0; i < ca.length; i++) {
//       var c = ca[i];
//       while (c.charAt(0) == " ") {
//         c = c.substring(1);
//       }
//       if (c.indexOf(name) == 0) {
//         return c.substring(name.length, c.length);
//       }
//     }
//     return "";
//   }

//   language = {
//     PackageType: {
//       FR: "Type d'emballage",
//       EN: "Package Type"
//     },
//     PackageMeasurements: {
//       FR: "Mesures de l'emballage",
//       EN: "Package Measurements"
//     },
//     PackageContents: {
//       FR: "Contenu du colis",
//       EN: "Package Contents"
//     },
//     CountryofOrigin: {
//       FR: "Pays d'origine",
//       EN: "Country of Origin"
//     },
//     Totalitemquantity: {
//       FR: "Quantité totale d'articles",
//       EN: "Total item quantity"
//     },
//     TotalValueOfShipmentUSD: {
//       FR: "Valeur totale de l'expédition (USD)",
//       EN: "Total value of shipment (USD)"
//     },
//     MaximumOf800USDPerShipment: {
//       FR: "Maximum de 800 USD par envoi",
//       EN: "Maximum of $800 USD per shipment"
//     },
//     Optional: {
//       FR: "En option",
//       EN: "Optional"
//     },
//     inches: {
//       FR: "pouces",
//       EN: "inches"
//     },
//     pounds: {
//       FR: "livre",
//       EN: "pounds"
//     },
//     Package: {
//       FR: "Paquet",
//       EN: "Package"
//     },
//     Packages: {
//       FR: "Paquets",
//       EN: "Packages"
//     },
//     Qty: {
//       FR: "Qté",
//       EN: "Qty"
//     },
//     value: {
//       FR: "valeur",
//       EN: "value"
//     },
//     Next: {
//       FR: "Suivant",
//       EN: "Next"
//     },
//     Back: {
//       FR: "Arrière",
//       EN: "Back"
//     },
//     ConfirmAndContinue: {
//       FR: "Confirmer et continuer",
//       EN: "Confirm and Continue"
//     },
//     HarmonizedCodes: {
//       FR: "Code harmonisé d'article",
//       EN: "Item(s) Harmonized Code(s)"
//     },
//     ItemsDescription: {
//       FR: "Description de l'article",
//       EN: "Item(s) Description"
//     },
//     Edit: {
//       FR: "ÉDITER",
//       EN: "EDIT"
//     }
//   };

//   getStepContent = step => {
//     const { classes } = this.props;
//     switch (step) {
//       case 0:
//         return (
//           <Fragment>
//             <PackageType {...this.props} />
//           </Fragment>
//         );
//       case 1:
//         return (
//           <Fragment>
//             <PackageMeasurements
//               sends_oversized={this.props.account.user.sends_oversized ? true : false}
//               country={"CA"}
//               maxQuantity={25}
//               error={this.state.measurementError}
//               {...this.props}
//             />
//           </Fragment>
//         );
//       case 2:
//         return (
//           <Fragment>
//             <FormControlLabel
//               control={
//                 <Switch
//                   name="insured"
//                   checked={this.props.insured}
//                   onChange={() =>
//                     this.props.setData("insured", !this.props.insured)
//                   }
//                   value="insured"
//                   color="primary"
//                 />
//               }
//               label="Insure package ($.30CAD/$100USD coverage, up to $1,000USD)"
//             />
//             <TextField
//               disabled={!this.props.insured}
//               error={this.state.descriptionError}
//               id="insured_value"
//               vairant="outlined"
//               name="insured_value"
//               label="Declared value to cover"
//               className={classes.textField}
//               value={this.props.insured_value}
//               onChange={this.props.handleChange}
//               margin="normal"
//             />
//             {/* <FormControl className={classes.formControl}>
//             <InputLabel htmlFor="country-native-simple" shrink>Country of Origin</InputLabel>
//             <Select
//             disabled={!this.props.package.insured}
//             native
//             required
//             value={this.props.package.origin}
//             onChange={this.props.handleObjChange('origin','package')}
//             inputProps={{
//                 name: 'origin',
//                 id: 'country-native-simple',
//             }}
//             >
//             <option value={"AF"}>Afghanistan</option>
//             <option value={"AX"}>Aland Islands</option>
//             <option value={"AL"}>Albania</option>
//             <option value={"DZ"}>Algeria</option>
//             <option value={"AS"}>American Samoa</option>
//             <option value={"AD"}>Andorra</option>
//             <option value={"AO"}>Angola</option>
//             <option value={"AI"}>Anguilla</option>
//             <option value={"AQ"}>Antarctica</option>
//             <option value={"AG"}>Antigua and Barbuda</option>
//             <option value={"AR"}>Argentina</option>
//             <option value={"AM"}>Armenia</option>
//             <option value={"AW"}>Aruba</option>
//             <option value={"AU"}>Australia</option>
//             <option value={"AT"}>Austria</option>
//             <option value={"AZ"}>Azerbaijan</option>
//             <option value={"BS"}>Bahamas</option>
//             <option value={"BH"}>Bahrain</option>
//             <option value={"BD"}>Bangladesh</option>
//             <option value={"BB"}>Barbados</option>
//             <option value={"BY"}>Belarus</option>
//             <option value={"BE"}>Belgium</option>
//             <option value={"BZ"}>Belize</option>
//             <option value={"BJ"}>Benin</option>
//             <option value={"BM"}>Bermuda</option>
//             <option value={"BT"}>Bhutan</option>
//             <option value={"BO"}>Bolivia (Plurinational State of)</option>
//             <option value={"BQ"}>Bonaire (Sint Eustatius and Saba)</option>
//             <option value={"BA"}>Bosnia and Herzegovina</option>
//             <option value={"BW"}>Botswana</option>
//             <option value={"BV"}>Bouvet Island</option>
//             <option value={"BR"}>Brazil</option>
//             <option value={"IO"}>British Indian Ocean Territory</option>
//             <option value={"BN"}>Brunei Darussalam</option>
//             <option value={"BG"}>Bulgaria</option>
//             <option value={"BF"}>Burkina Faso</option>
//             <option value={"BI"}>Burundi</option>
//             <option value={"CV"}>Cabo Verde</option>
//             <option value={"KH"}>Cambodia</option>
//             <option value={"CM"}>Cameroon</option>
//             <option value={"CA"}>Canada</option>
//             <option value={"KY"}>Cayman Islands</option>
//             <option value={"CF"}>Central African Republic</option>
//             <option value={"TD"}>Chad</option>
//             <option value={"CL"}>Chile</option>
//             <option value={"CN"}>China</option>
//             <option value={"CX"}>Christmas Island</option>
//             <option value={"CC"}>Cocos (Keeling) Islands</option>
//             <option value={"CO"}>Colombia</option>
//             <option value={"KM"}>Comoros</option>
//             <option value={"CG"}>Congo</option>
//             <option value={"CD"}>Congo (Democratic Republic of the)</option>
//             <option value={"CK"}>Cook Islands</option>
//             <option value={"CR"}>Costa Rica</option>
//             <option value={"CI"}>Cote d'Ivoire</option>
//             <option value={"HR"}>Croatia</option>
//             <option value={"CU"}>Cuba</option>
//             <option value={"CW"}>Curacao</option>
//             <option value={"CY"}>Cyprus</option>
//             <option value={"CZ"}>Czechia</option>
//             <option value={"DK"}>Denmark</option>
//             <option value={"DJ"}>Djibouti</option>
//             <option value={"DM"}>Dominica</option>
//             <option value={"DO"}>Dominican Republic</option>
//             <option value={"EC"}>Ecuador</option>
//             <option value={"EG"}>Egypt</option>
//             <option value={"SV"}>El Salvador</option>
//             <option value={"GQ"}>Equatorial Guinea</option>
//             <option value={"ER"}>Eritrea</option>
//             <option value={"EE"}>Estonia</option>
//             <option value={"SZ"}>Eswatini</option>
//             <option value={"ET"}>Ethiopia</option>
//             <option value={"FK"}>Falkland Islands (Malvinas)</option>
//             <option value={"FO"}>Faroe Islands</option>
//             <option value={"FJ"}>Fiji</option>
//             <option value={"FI"}>Finland</option>
//             <option value={"FR"}>France</option>
//             <option value={"GF"}>French Guiana</option>
//             <option value={"PF"}>French Polynesia</option>
//             <option value={"TF"}>French Southern Territories</option>
//             <option value={"GA"}>Gabon</option>
//             <option value={"GM"}>Gambia</option>
//             <option value={"GE"}>Georgia</option>
//             <option value={"DE"}>Germany</option>
//             <option value={"GH"}>Ghana</option>
//             <option value={"GI"}>Gibraltar</option>
//             <option value={"GR"}>Greece</option>
//             <option value={"GL"}>Greenland</option>
//             <option value={"GD"}>Grenada</option>
//             <option value={"GP"}>Guadeloupe</option>
//             <option value={"GU"}>Guam</option>
//             <option value={"GT"}>Guatemala</option>
//             <option value={"GG"}>Guernsey</option>
//             <option value={"GN"}>Guinea</option>
//             <option value={"GW"}>Guinea-Bissau</option>
//             <option value={"GY"}>Guyana</option>
//             <option value={"HT"}>Haiti</option>
//             <option value={"HM"}>Heard Island and McDonald Islands</option>
//             <option value={"VA"}>Holy See</option>
//             <option value={"HN"}>Honduras</option>
//             <option value={"HK"}>Hong Kong</option>
//             <option value={"HU"}>Hungary</option>
//             <option value={"IS"}>Iceland</option>
//             <option value={"IN"}>India</option>
//             <option value={"ID"}>Indonesia</option>
//             <option value={"IR"}>Iran (Islamic Republic of)</option>
//             <option value={"IQ"}>Iraq</option>
//             <option value={"IE"}>Ireland</option>
//             <option value={"IM"}>Isle of Man</option>
//             <option value={"IL"}>Israel</option>
//             <option value={"IT"}>Italy</option>
//             <option value={"JM"}>Jamaica</option>
//             <option value={"JP"}>Japan</option>
//             <option value={"JE"}>Jersey</option>
//             <option value={"JO"}>Jordan</option>
//             <option value={"KZ"}>Kazakhstan</option>
//             <option value={"KE"}>Kenya</option>
//             <option value={"KI"}>Kiribati</option>
//             <option value={"KP"}>Korea (Democratic People's Republic of)</option>
//             <option value={"KR"}>Korea (Republic of)</option>
//             <option value={"KW"}>Kuwait</option>
//             <option value={"KG"}>Kyrgyzstan</option>
//             <option value={"LA"}>Lao People's Democratic Republic</option>
//             <option value={"LV"}>Latvia</option>
//             <option value={"LB"}>Lebanon</option>
//             <option value={"LS"}>Lesotho</option>
//             <option value={"LR"}>Liberia</option>
//             <option value={"LY"}>Libya</option>
//             <option value={"LI"}>Liechtenstein</option>
//             <option value={"LT"}>Lithuania</option>
//             <option value={"LU"}>Luxembourg</option>
//             <option value={"MO"}>Macao</option>
//             <option value={"MK"}>Macedonia (the former Yugoslav Republic of)</option>
//             <option value={"MG"}>Madagascar</option>
//             <option value={"MW"}>Malawi</option>
//             <option value={"MY"}>Malaysia</option>
//             <option value={"MV"}>Maldives</option>
//             <option value={"ML"}>Mali</option>
//             <option value={"MT"}>Malta</option>
//             <option value={"MH"}>Marshall Islands</option>
//             <option value={"MQ"}>Martinique</option>
//             <option value={"MR"}>Mauritania</option>
//             <option value={"MU"}>Mauritius</option>
//             <option value={"YT"}>Mayotte</option>
//             <option value={"MX"}>Mexico</option>
//             <option value={"FM"}>Micronesia (Federated States of)</option>
//             <option value={"MD"}>Moldova (Republic of)</option>
//             <option value={"MC"}>Monaco</option>
//             <option value={"MN"}>Mongolia</option>
//             <option value={"ME"}>Montenegro</option>
//             <option value={"MS"}>Montserrat</option>
//             <option value={"MA"}>Morocco</option>
//             <option value={"MZ"}>Mozambique</option>
//             <option value={"MM"}>Myanmar</option>
//             <option value={"NA"}>Namibia</option>
//             <option value={"NR"}>Nauru</option>
//             <option value={"NP"}>Nepal</option>
//             <option value={"NL"}>Netherlands</option>
//             <option value={"NC"}>New Caledonia</option>
//             <option value={"NZ"}>New Zealand</option>
//             <option value={"NI"}>Nicaragua</option>
//             <option value={"NE"}>Niger</option>
//             <option value={"NG"}>Nigeria</option>
//             <option value={"NU"}>Niue</option>
//             <option value={"NF"}>Norfolk Island</option>
//             <option value={"MP"}>Northern Mariana Islands</option>
//             <option value={"NO"}>Norway</option>
//             <option value={"OM"}>Oman</option>
//             <option value={"PK"}>Pakistan</option>
//             <option value={"PW"}>Palau</option>
//             <option value={"PS"}>Palestine (State of)</option>
//             <option value={"PA"}>Panama</option>
//             <option value={"PG"}>Papua New Guinea</option>
//             <option value={"PY"}>Paraguay</option>
//             <option value={"PE"}>Peru</option>
//             <option value={"PH"}>Philippines</option>
//             <option value={"PN"}>Pitcairn</option>
//             <option value={"PL"}>Poland</option>
//             <option value={"PT"}>Portugal</option>
//             <option value={"PR"}>Puerto Rico</option>
//             <option value={"QA"}>Qatar</option>
//             <option value={"RE"}>Reunion</option>
//             <option value={"RO"}>Romania</option>
//             <option value={"RU"}>Russian Federation</option>
//             <option value={"RW"}>Rwanda</option>
//             <option value={"BL"}>Saint Barthelemy</option>
//             <option value={"SH"}>Saint Helena (Ascension and Tristan da Cunha)</option>
//             <option value={"KN"}>Saint Kitts and Nevis</option>
//             <option value={"LC"}>Saint Lucia</option>
//             <option value={"MF"}>Saint Martin (French part)</option>
//             <option value={"PM"}>Saint Pierre and Miquelon</option>
//             <option value={"VC"}>Saint Vincent and the Grenadines</option>
//             <option value={"WS"}>Samoa</option>
//             <option value={"SM"}>San Marino</option>
//             <option value={"ST"}>Sao Tome and Principe</option>
//             <option value={"SA"}>Saudi Arabia</option>
//             <option value={"SN"}>Senegal</option>
//             <option value={"RS"}>Serbia</option>
//             <option value={"SC"}>Seychelles</option>
//             <option value={"SL"}>Sierra Leone</option>
//             <option value={"SG"}>Singapore</option>
//             <option value={"SX"}>Sint Maarten (Dutch part)</option>
//             <option value={"SK"}>Slovakia</option>
//             <option value={"SI"}>Slovenia</option>
//             <option value={"SB"}>Solomon Islands</option>
//             <option value={"SO"}>Somalia</option>
//             <option value={"ZA"}>South Africa</option>
//             <option value={"GS"}>South Georgia and the South Sandwich Islands</option>
//             <option value={"SS"}>South Sudan</option>
//             <option value={"ES"}>Spain</option>
//             <option value={"LK"}>Sri Lanka</option>
//             <option value={"SD"}>Sudan</option>
//             <option value={"SR"}>Suriname</option>
//             <option value={"SJ"}>Svalbard and Jan Mayen</option>
//             <option value={"SE"}>Sweden</option>
//             <option value={"CH"}>Switzerland</option>
//             <option value={"SY"}>Syrian Arab Republic</option>
//             <option value={"TW"}>Taiwan (Province of China)</option>
//             <option value={"TJ"}>Tajikistan</option>
//             <option value={"TZ"}>Tanzania (United Republic of)</option>
//             <option value={"TH"}>Thailand</option>
//             <option value={"TL"}>Timor-Leste</option>
//             <option value={"TG"}>Togo</option>
//             <option value={"TK"}>Tokelau</option>
//             <option value={"TO"}>Tonga</option>
//             <option value={"TT"}>Trinidad and Tobago</option>
//             <option value={"TN"}>Tunisia</option>
//             <option value={"TR"}>Turkey</option>
//             <option value={"TM"}>Turkmenistan</option>
//             <option value={"TC"}>Turks and Caicos Islands</option>
//             <option value={"TV"}>Tuvalu</option>
//             <option value={"UG"}>Uganda</option>
//             <option value={"UA"}>Ukraine</option>
//             <option value={"AE"}>United Arab Emirates</option>
//             <option value={"GB"}>United Kingdom of Great Britain and Northern Ireland</option>
//             <option value={"UM"}>United States Minor Outlying Islands</option>
//             <option value={"UY"}>Uruguay</option>
//             <option value={"UZ"}>Uzbekistan</option>
//             <option value={"VU"}>Vanuatu</option>
//             <option value={"VE"}>Venezuela (Bolivarian Republic of)</option>
//             <option value={"VN"}>Viet Nam</option>
//             <option value={"VG"}>Virgin Islands (British)</option>
//             <option value={"VI"}>Virgin Islands (U.S.)</option>
//             <option value={"WF"}>Wallis and Futuna</option>
//             <option value={"EH"}>Western Sahara</option>
//             <option value={"YE"}>Yemen</option>
//             <option value={"ZM"}>Zambia</option>
//             <option value={"ZW"}>Zimbabwe</option>
//             </Select>
//         </FormControl>
//                 <TextField
//                 disabled={!this.props.package.insured}
//                 error={this.state.quantityError}
//                 id="pkg_quantity"
//                 label="Total item quantity"
//                 className={classes.textField}
//                 value={this.props.package.quantity}
//                 onChange={this.props.handleObjChange('quantity','package')}
//                 margin="normal"
//               /><TextField
//               disabled={!this.props.package.insured}
//               error={this.props.package.value > 800 || this.state.valueError}
//               id="pkg_value"
//               label="Total value of shipment (USD)"
//               className={classes.textField}
//               helperText='Maximum of $800USD per shipment.'
//               value={this.props.package.value}
//               onChange={this.props.handleObjChange('value','package')}
//               margin="normal"
//             /> */}
//           </Fragment>
//         );
//       default:
//         return "Unknown step";
//     }
//   };

//   checkDimensions = async () => {
//     if (this.props.package.type === "tube") {
//       this.props.setObjData("height", "package", this.props.package.width);
//     }

//     let response = await this.props.package.dimensions.map((e, i) => {
//       if (i + 1 <= this.props.package.count) {
//         if (parseFloat(e.height) < 0.001) {
//           this.props.setDimensionChange("height", i, "0.001");
//         } else {
//           this.props.setDimensionChange(
//             "height",
//             i,
//             parseFloat(e.height)
//               .toFixed(3)
//               .toString()
//           );
//         }
//         if (parseFloat(e.length) < 0.001) {
//           this.props.setDimensionChange("length", i, "0.001");
//         } else {
//           this.props.setDimensionChange(
//             "length",
//             i,
//             parseFloat(e.length)
//               .toFixed(3)
//               .toString()
//           );
//         }
//         if (parseFloat(e.width) < 0.001) {
//           this.props.setDimensionChange("width", i, "0.001");
//         } else {
//           this.props.setDimensionChange(
//             "width",
//             i,
//             parseFloat(e.width)
//               .toFixed(3)
//               .toString()
//           );
//         }
//         if (parseFloat(e.weight) < 0.001) {
//           this.props.setDimensionChange("weight", i, "0.1");
//         } else {
//           this.props.setDimensionChange(
//             "weight",
//             i,
//             parseFloat(e.weight)
//               .toFixed(3)
//               .toString()
//           );
//         }
//         return (
//           (this.props.package.type !== "tube" ? isNotEmpty(e.height) : true) &&
//           isNotEmpty(e["length"]) &&
//           isNotEmpty(e.width) &&
//           isNotEmpty(e.weight)
//         );
//       } else {
//         return true;
//       }
//     });

//     !response.filter(e => e === false).length
//       ? this.setState({ measurementError: false })
//       : this.setState({ measurementError: true });

//     return !response.filter(e => e === false).length;

//     // if(this.props.package.type === "tube"){ this.props.setObjData('height','package',this.props.package.width) }

//     // isNotEmpty(this.props.package.height) ? this.setState({heightError: false}) : this.setState({heightError: true})
//     // isNotEmpty(this.props.package.width) ? this.setState({widthError: false}) : this.setState({widthError: true})
//     // isNotEmpty(this.props.package.length) ? this.setState({lengthError: false}) : this.setState({lengthError: true})
//     // isNotEmpty(this.props.package.weight) ? this.setState({weightError: false}) : this.setState({weightError: true})

//     // return isNotEmpty(this.props.package.height) &&
//     // isNotEmpty(this.props.package.length) &&
//     // isNotEmpty(this.props.package.width) &&
//     // isNotEmpty(this.props.package.weight)
//   };

//   checkDescription = () => {
//     if (this.props.package.insured) {
//       isNotEmpty(this.props.package.description)
//         ? this.setState({ descriptionError: false })
//         : this.setState({ descriptionError: true });
//       isNotEmpty(this.props.package.quantity) && this.props.package.quantity > 1
//         ? this.setState({ quantityError: false })
//         : this.setState({ quantityError: true });
//       isNotEmpty(this.props.package.value) && this.props.package.value < 800
//         ? this.setState({ valueError: false })
//         : this.setState({ valueError: true });

//       return (
//         isNotEmpty(this.props.package.description) &&
//         isNotEmpty(this.props.package.quantity) &&
//         isNotEmpty(this.props.package.value) &&
//         this.props.package.value < 800
//       );
//     }
//     return true;
//   };

//   handleNext = async () => {
//     if (this.props.twoStep == 1) {
//       this.checkDimensions().then(e =>
//         e ? this.props.handleNext("twoStep") : false
//       );
//     } else if (this.props.twoStep == 2) {
//       this.setState({ isLoading: true });

//       let sample = this.props.locations.map((e, i) =>
//         e.id === this.props.senderLocationID ? i : false
//       );
//       sample = sample.filter(e => e !== false);
//       sample = sample[0];
//       let test = await resolver(this.props);

//       var services_reduced = test.filter(service => {
//         return service.qualify;
//       });
//       let body = {
//         sender: this.props.locations[sample],
//         package: this.props.package,
//         receiver: this.props.receiver,
//         services: services_reduced,
//         connection_id: this.state.connection_id,
//         cognitoUsername: this.props.account.user.userId
//       };
//       if (this.props.account.user.ics_api) {
//         body.ics_api = this.props.account.user.ics_api;
//       }
//       if (this.props.account.user.ics_rates) {
//         body.ics_rates = this.props.account.user.ics_rates;
//       }
//       if (this.props.account.user.canpar_api) {
//         body.canpar_api = this.props.account.user.canpar_api;
//       }
//       if (this.props.account.user.canpar_rates) {
//         body.canpar_rates = this.props.account.user.canpar_rates;
//       }
//       this.setState({rateError:false});
//       // console.log(body)
//       API.post("sqs", "/sqs-rate", {
//         body
//       });
//     } else {
//       this.props.handleNext("twoStep");
//     }
//   };

//   renderPackages(arr) {
//     if (arr.length > 1) {
//       return [{}].concat(arr).map((note, i) => {
//         if (i !== 0) {
//           return (
//             <Fragment>
//               {note.length} {this.language.inches[this.state.language]} x {note.width} {this.language.inches[this.state.language]} x {note.height} {this.language.inches[this.state.language]}
//               <br />
//             </Fragment>
//           );
//         }
//       });
//     } else {
//       return (
//         <Fragment>
//           {arr[0]["length"]} {this.language.inches[this.state.language]} x {arr[0].width} {this.language.inches[this.state.language]} x {arr[0].height}{" "}
//           {this.language.inches[this.state.language]}
//           <br />
//           {arr[0].weight} {this.language.pounds[this.state.language]}
//         </Fragment>
//       );
//     }
//   }

//   render() {
//     const { classes } = this.props;
//     const steps = this.getSteps();
//     const activeStep = this.props.twoStep;

//     // console.log("PKG MEASUREMENTS =>>>>> ", this.props)

//     return (
//       <div>
//         <Stepper activeStep={activeStep} orientation="vertical">
//           {steps.map((label, index) => {
//             return (
//               <Step key={label}>
//                 <StepLabel>{label}</StepLabel>
//                 <StepContent>
//                   <Typography>{this.getStepContent(index)}</Typography>
//                   <div className={classes.actionsContainer}>
//                     <div>
//                       {activeStep === 0 ? (
//                         <Button
//                           onClick={() => this.props.handleBack("activeStep")}
//                           className={classes.button}
//                         >
//                           {this.language.Back[this.state.language]}
//                         </Button>
//                       ) : (
//                         <Button
//                           onClick={() => this.props.handleBack("twoStep")}
//                           className={classes.button}
//                         >
//                           {this.language.Back[this.state.language]}
//                         </Button>
//                       )}
//                       <Button
//                         variant="contained"
//                         color="primary"
//                         onClick={() => {
//                           this.handleNext();
//                         }}
//                         className={classes.button}
//                       >
//                         {this.language.Next[this.state.language]}
//                       </Button>
//                       <Typography variant="subheading">
//                         {this.state.errorMsg}
//                       </Typography>
//                     </div>
//                   </div>
//                 </StepContent>
//               </Step>
//             );
//           })}
//         </Stepper>
//         {activeStep == steps.length && (
//           <Paper square elevation={0} className={classes.resetContainer}>
//             <Typography variant="subheading">
//               <strong>
//                 {this.props.package.dimensions.length === 1
//                   ? `${this.language.Package[this.state.language]}:`
//                   : `${this.language.Packages[this.state.language]}:`}
//               </strong>
//               <br />
//               {this.renderPackages(this.props.package.dimensions)}
//               {this.props.package.insured === "true" && (
//                 <Fragment>
//                   {this.props.package.description}, {this.language.Qty[this.state.language]}{" "}
//                   {this.props.package.quantity}
//                   <br />${this.props.package.value}{" "}
//                   {this.language.Value[this.state.language]}
//                 </Fragment>
//               )}
//             </Typography>
// <Collapse in={this.state.rateError} style={{margin:'1rem 0',padding: this.state.rateError ? '.75rem 1.25rem' : '0',color:'#721c24',borderRadius:'.25rem',background:'#f8d7da'}}>There are no services available for shipping from <strong>{this.props.locations.filter(location=>location.id===this.props.senderLocationID)[0].address.postal}</strong> to <strong>{this.props.receiver.zip}</strong></Collapse>
// <Button
//   disabled={this.state.isLoading}
//   onClick={() => {this.setState({rateError:false});this.props.handleBack("twoStep")}}
//   className={classes.button}
// >
//   {this.language.Edit[this.state.language]}
// </Button>
//             <Button
//               disabled={this.state.isLoading || this.state.connection_id === ""}
//               onClick={() => this.handleNext()}
//               className={classes.button}
//               variant="contained"
//               color="primary"
//             >
//               {!this.state.isLoading && this.state.connection_id !== "" ? (
//                 this.language.ConfirmAndContinue[this.state.language]
//               ) : (
//                 <LoadingIcon size={20} />
//               )}
//             </Button>
//           </Paper>
//         )}
//       </div>
//     );
//   }
// }

// VerticalLinearStepper.propTypes = {
//   classes: PropTypes.object
// };

// export default withStyles(styles)(VerticalLinearStepper);
import { Auth } from "aws-amplify";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Step from "@material-ui/core/Step";
import StepContent from "@material-ui/core/StepContent";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { API } from "aws-amplify";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import resolver from "../../helpers/resolverCA";
import { isNotEmpty } from "../../helpers/Validation";
import LoadingIcon from "../loading/LoadingIcon";
import PackageMeasurements from "../PackageMeasurements";
import PackageType from "../PackageType";

// import { borderTopStyle } from 'html2canvas/dist/types/css/property-descriptors/border-style';

const styles = (theme) => ({
  textField: {
    width: "100%",
    boxSizing: "border-box",
    fontSize: "1.5em",
  },
  menu: {
    width: 200,
  },
  formControl: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2,
  },
  resetContainer: {
    padding: theme.spacing.unit * 3,
  },
});

class VerticalLinearStepper extends React.Component {
  state = {
    activeStep: 0,
    isLoading: false,
    connection_id: "",
  };

  getSteps = () => {
    if (
      this.props.account &&
      this.props.account.user &&
      this.props.account.user.fixedPLD
    ) {
      return ["Package Quantity", "Package Reference"]; // 'Package contents'];
    } else {
      return ["Package Type", "Package measurements"]; // 'Package contents'];
    }
  };

  componentDidMount = async () => {
    // alert('socket')
    const socket2 = new WebSocket(
      "wss://keuhz93lxj.execute-api.us-east-1.amazonaws.com/dev"
    );

    socket2.addEventListener("open", function (event) {
      socket2.send(JSON.stringify({ action: "sendMessage" }));
      // alert('connected')
    });

    socket2.addEventListener("message", (event) => {
      // alert('socket msg');
      // console.log(JSON.parse(event.data));
      let data = JSON.parse(event.data);
      if (data.connectionId) {
        // alert(data.connectionId)
        this.setState({ connection_id: data.connectionId });
        // console.log("CONNECTED TO RATE SOCKET, ",data.connectionId)
      }
      if (data.rate) {
        // console.log(data.rate);
        // this.props.setRates(
        //   "rates",
        //   data.rate.filter((f) => {
        //     return f.rate != 0;
        //   })
        // );
        if (data.rate.length) {
          this.props.setRates(
            "rates",
            data.rate.filter((f) => {
              return f.rate != 0;
            })
          );
          this.props.handleNext("activeStep");
        } else {
          this.setState({
            errorMsg:
              "There are no services available on your account for shipping to the destination provided.",
          });
          this.setState({ isLoading: false });
        }
        this.props.handleNext("activeStep");
      }
    });
  };

  getStepContent = (step) => {
    const { classes } = this.props;

    // if (this.props.account && this.props.account.user && this.props.account.user.fixedPLD) {
    //     return <PackageMeasurements disabled={this.state.isLoading} fixedPLD={true} country={"CA"} maxQuantity={25} error={this.state.measurementError} {...this.props} />
    // }

    if (
      this.props.account &&
      this.props.account.user &&
      this.props.account.user.fixedPLD
    ) {
      switch (step) {
        case 0:
          return (
            <>
              <PackageMeasurements
                disabled={this.state.isLoading}
                fixedPLD={true}
                country={"CA"}
                maxQuantity={1}
                error={this.state.measurementError}
                {...this.props}
              />
            </>
          );
        case 1:
          return (
            <>
              <TextField
                fullWidth
                disabled={this.state.isLoading}
                helperText="(Optional)"
                label={"Reference/P.O. #"}
                className={classes.textField}
                value={this.props.package.ref}
                onChange={this.props.handleObjChange("ref", "package")}
                margin="normal"
                inputProps={{
                  type: "text",
                  autoComplete: "off",
                  autoCorrect: "off",
                  autoCapitalize: "none",
                  spellCheck: "false",
                  maxLength: 35,
                }}
              />
            </>
          );
      }
    }

    switch (step) {
      case 0:
        return (
          <Fragment>
            <PackageType {...this.props} />
          </Fragment>
        );
      case 1:
        return (
          <Fragment>
            <PackageMeasurements
              country={"CA"}
              maxQuantity={25}
              error={this.state.measurementError}
              {...this.props}
            />
          </Fragment>
        );
      case 2:
        return (
          <Fragment>
            <FormControlLabel
              control={
                <Switch
                  name="insured"
                  checked={this.props.insured}
                  onChange={() =>
                    this.props.setData("insured", !this.props.insured)
                  }
                  value="insured"
                  color="primary"
                />
              }
              label="Insure package ($.30CAD/$100USD coverage, up to $1,000USD)"
            />
            <TextField
              disabled={!this.props.insured}
              error={this.state.descriptionError}
              id="insured_value"
              vairant="outlined"
              name="insured_value"
              label="Declared value to cover"
              className={classes.textField}
              value={this.props.insured_value}
              onChange={this.props.handleChange}
              margin="normal"
            />
            {/* <FormControl className={classes.formControl}>
            <InputLabel htmlFor="country-native-simple" shrink>Country of Origin</InputLabel>
            <Select
            disabled={!this.props.package.insured}
            native
            required
            value={this.props.package.origin}
            onChange={this.props.handleObjChange('origin','package')}
            inputProps={{
                name: 'origin',
                id: 'country-native-simple',
            }}
            >
            <option value={"AF"}>Afghanistan</option>
            <option value={"AX"}>Aland Islands</option>
            <option value={"AL"}>Albania</option>
            <option value={"DZ"}>Algeria</option>
            <option value={"AS"}>American Samoa</option>
            <option value={"AD"}>Andorra</option>
            <option value={"AO"}>Angola</option>
            <option value={"AI"}>Anguilla</option>
            <option value={"AQ"}>Antarctica</option>
            <option value={"AG"}>Antigua and Barbuda</option>
            <option value={"AR"}>Argentina</option>
            <option value={"AM"}>Armenia</option>
            <option value={"AW"}>Aruba</option>
            <option value={"AU"}>Australia</option>
            <option value={"AT"}>Austria</option>
            <option value={"AZ"}>Azerbaijan</option>
            <option value={"BS"}>Bahamas</option>
            <option value={"BH"}>Bahrain</option>
            <option value={"BD"}>Bangladesh</option>
            <option value={"BB"}>Barbados</option>
            <option value={"BY"}>Belarus</option>
            <option value={"BE"}>Belgium</option>
            <option value={"BZ"}>Belize</option>
            <option value={"BJ"}>Benin</option>
            <option value={"BM"}>Bermuda</option>
            <option value={"BT"}>Bhutan</option>
            <option value={"BO"}>Bolivia (Plurinational State of)</option>
            <option value={"BQ"}>Bonaire (Sint Eustatius and Saba)</option>
            <option value={"BA"}>Bosnia and Herzegovina</option>
            <option value={"BW"}>Botswana</option>
            <option value={"BV"}>Bouvet Island</option>
            <option value={"BR"}>Brazil</option>
            <option value={"IO"}>British Indian Ocean Territory</option>
            <option value={"BN"}>Brunei Darussalam</option>
            <option value={"BG"}>Bulgaria</option>
            <option value={"BF"}>Burkina Faso</option>
            <option value={"BI"}>Burundi</option>
            <option value={"CV"}>Cabo Verde</option>
            <option value={"KH"}>Cambodia</option>
            <option value={"CM"}>Cameroon</option>
            <option value={"CA"}>Canada</option>
            <option value={"KY"}>Cayman Islands</option>
            <option value={"CF"}>Central African Republic</option>
            <option value={"TD"}>Chad</option>
            <option value={"CL"}>Chile</option>
            <option value={"CN"}>China</option>
            <option value={"CX"}>Christmas Island</option>
            <option value={"CC"}>Cocos (Keeling) Islands</option>
            <option value={"CO"}>Colombia</option>
            <option value={"KM"}>Comoros</option>
            <option value={"CG"}>Congo</option>
            <option value={"CD"}>Congo (Democratic Republic of the)</option>
            <option value={"CK"}>Cook Islands</option>
            <option value={"CR"}>Costa Rica</option>
            <option value={"CI"}>Cote d'Ivoire</option>
            <option value={"HR"}>Croatia</option>
            <option value={"CU"}>Cuba</option>
            <option value={"CW"}>Curacao</option>
            <option value={"CY"}>Cyprus</option>
            <option value={"CZ"}>Czechia</option>
            <option value={"DK"}>Denmark</option>
            <option value={"DJ"}>Djibouti</option>
            <option value={"DM"}>Dominica</option>
            <option value={"DO"}>Dominican Republic</option>
            <option value={"EC"}>Ecuador</option>
            <option value={"EG"}>Egypt</option>
            <option value={"SV"}>El Salvador</option>
            <option value={"GQ"}>Equatorial Guinea</option>
            <option value={"ER"}>Eritrea</option>
            <option value={"EE"}>Estonia</option>
            <option value={"SZ"}>Eswatini</option>
            <option value={"ET"}>Ethiopia</option>
            <option value={"FK"}>Falkland Islands (Malvinas)</option>
            <option value={"FO"}>Faroe Islands</option>
            <option value={"FJ"}>Fiji</option>
            <option value={"FI"}>Finland</option>
            <option value={"FR"}>France</option>
            <option value={"GF"}>French Guiana</option>
            <option value={"PF"}>French Polynesia</option>
            <option value={"TF"}>French Southern Territories</option>
            <option value={"GA"}>Gabon</option>
            <option value={"GM"}>Gambia</option>
            <option value={"GE"}>Georgia</option>
            <option value={"DE"}>Germany</option>
            <option value={"GH"}>Ghana</option>
            <option value={"GI"}>Gibraltar</option>
            <option value={"GR"}>Greece</option>
            <option value={"GL"}>Greenland</option>
            <option value={"GD"}>Grenada</option>
            <option value={"GP"}>Guadeloupe</option>
            <option value={"GU"}>Guam</option>
            <option value={"GT"}>Guatemala</option>
            <option value={"GG"}>Guernsey</option>
            <option value={"GN"}>Guinea</option>
            <option value={"GW"}>Guinea-Bissau</option>
            <option value={"GY"}>Guyana</option>
            <option value={"HT"}>Haiti</option>
            <option value={"HM"}>Heard Island and McDonald Islands</option>
            <option value={"VA"}>Holy See</option>
            <option value={"HN"}>Honduras</option>
            <option value={"HK"}>Hong Kong</option>
            <option value={"HU"}>Hungary</option>
            <option value={"IS"}>Iceland</option>
            <option value={"IN"}>India</option>
            <option value={"ID"}>Indonesia</option>
            <option value={"IR"}>Iran (Islamic Republic of)</option>
            <option value={"IQ"}>Iraq</option>
            <option value={"IE"}>Ireland</option>
            <option value={"IM"}>Isle of Man</option>
            <option value={"IL"}>Israel</option>
            <option value={"IT"}>Italy</option>
            <option value={"JM"}>Jamaica</option>
            <option value={"JP"}>Japan</option>
            <option value={"JE"}>Jersey</option>
            <option value={"JO"}>Jordan</option>
            <option value={"KZ"}>Kazakhstan</option>
            <option value={"KE"}>Kenya</option>
            <option value={"KI"}>Kiribati</option>
            <option value={"KP"}>Korea (Democratic People's Republic of)</option>
            <option value={"KR"}>Korea (Republic of)</option>
            <option value={"KW"}>Kuwait</option>
            <option value={"KG"}>Kyrgyzstan</option>
            <option value={"LA"}>Lao People's Democratic Republic</option>
            <option value={"LV"}>Latvia</option>
            <option value={"LB"}>Lebanon</option>
            <option value={"LS"}>Lesotho</option>
            <option value={"LR"}>Liberia</option>
            <option value={"LY"}>Libya</option>
            <option value={"LI"}>Liechtenstein</option>
            <option value={"LT"}>Lithuania</option>
            <option value={"LU"}>Luxembourg</option>
            <option value={"MO"}>Macao</option>
            <option value={"MK"}>Macedonia (the former Yugoslav Republic of)</option>
            <option value={"MG"}>Madagascar</option>
            <option value={"MW"}>Malawi</option>
            <option value={"MY"}>Malaysia</option>
            <option value={"MV"}>Maldives</option>
            <option value={"ML"}>Mali</option>
            <option value={"MT"}>Malta</option>
            <option value={"MH"}>Marshall Islands</option>
            <option value={"MQ"}>Martinique</option>
            <option value={"MR"}>Mauritania</option>
            <option value={"MU"}>Mauritius</option>
            <option value={"YT"}>Mayotte</option>
            <option value={"MX"}>Mexico</option>
            <option value={"FM"}>Micronesia (Federated States of)</option>
            <option value={"MD"}>Moldova (Republic of)</option>
            <option value={"MC"}>Monaco</option>
            <option value={"MN"}>Mongolia</option>
            <option value={"ME"}>Montenegro</option>
            <option value={"MS"}>Montserrat</option>
            <option value={"MA"}>Morocco</option>
            <option value={"MZ"}>Mozambique</option>
            <option value={"MM"}>Myanmar</option>
            <option value={"NA"}>Namibia</option>
            <option value={"NR"}>Nauru</option>
            <option value={"NP"}>Nepal</option>
            <option value={"NL"}>Netherlands</option>
            <option value={"NC"}>New Caledonia</option>
            <option value={"NZ"}>New Zealand</option>
            <option value={"NI"}>Nicaragua</option>
            <option value={"NE"}>Niger</option>
            <option value={"NG"}>Nigeria</option>
            <option value={"NU"}>Niue</option>
            <option value={"NF"}>Norfolk Island</option>
            <option value={"MP"}>Northern Mariana Islands</option>
            <option value={"NO"}>Norway</option>
            <option value={"OM"}>Oman</option>
            <option value={"PK"}>Pakistan</option>
            <option value={"PW"}>Palau</option>
            <option value={"PS"}>Palestine (State of)</option>
            <option value={"PA"}>Panama</option>
            <option value={"PG"}>Papua New Guinea</option>
            <option value={"PY"}>Paraguay</option>
            <option value={"PE"}>Peru</option>
            <option value={"PH"}>Philippines</option>
            <option value={"PN"}>Pitcairn</option>
            <option value={"PL"}>Poland</option>
            <option value={"PT"}>Portugal</option>
            <option value={"PR"}>Puerto Rico</option>
            <option value={"QA"}>Qatar</option>
            <option value={"RE"}>Reunion</option>
            <option value={"RO"}>Romania</option>
            <option value={"RU"}>Russian Federation</option>
            <option value={"RW"}>Rwanda</option>
            <option value={"BL"}>Saint Barthelemy</option>
            <option value={"SH"}>Saint Helena (Ascension and Tristan da Cunha)</option>
            <option value={"KN"}>Saint Kitts and Nevis</option>
            <option value={"LC"}>Saint Lucia</option>
            <option value={"MF"}>Saint Martin (French part)</option>
            <option value={"PM"}>Saint Pierre and Miquelon</option>
            <option value={"VC"}>Saint Vincent and the Grenadines</option>
            <option value={"WS"}>Samoa</option>
            <option value={"SM"}>San Marino</option>
            <option value={"ST"}>Sao Tome and Principe</option>
            <option value={"SA"}>Saudi Arabia</option>
            <option value={"SN"}>Senegal</option>
            <option value={"RS"}>Serbia</option>
            <option value={"SC"}>Seychelles</option>
            <option value={"SL"}>Sierra Leone</option>
            <option value={"SG"}>Singapore</option>
            <option value={"SX"}>Sint Maarten (Dutch part)</option>
            <option value={"SK"}>Slovakia</option>
            <option value={"SI"}>Slovenia</option>
            <option value={"SB"}>Solomon Islands</option>
            <option value={"SO"}>Somalia</option>
            <option value={"ZA"}>South Africa</option>
            <option value={"GS"}>South Georgia and the South Sandwich Islands</option>
            <option value={"SS"}>South Sudan</option>
            <option value={"ES"}>Spain</option>
            <option value={"LK"}>Sri Lanka</option>
            <option value={"SD"}>Sudan</option>
            <option value={"SR"}>Suriname</option>
            <option value={"SJ"}>Svalbard and Jan Mayen</option>
            <option value={"SE"}>Sweden</option>
            <option value={"CH"}>Switzerland</option>
            <option value={"SY"}>Syrian Arab Republic</option>
            <option value={"TW"}>Taiwan (Province of China)</option>
            <option value={"TJ"}>Tajikistan</option>
            <option value={"TZ"}>Tanzania (United Republic of)</option>
            <option value={"TH"}>Thailand</option>
            <option value={"TL"}>Timor-Leste</option>
            <option value={"TG"}>Togo</option>
            <option value={"TK"}>Tokelau</option>
            <option value={"TO"}>Tonga</option>
            <option value={"TT"}>Trinidad and Tobago</option>
            <option value={"TN"}>Tunisia</option>
            <option value={"TR"}>Turkey</option>
            <option value={"TM"}>Turkmenistan</option>
            <option value={"TC"}>Turks and Caicos Islands</option>
            <option value={"TV"}>Tuvalu</option>
            <option value={"UG"}>Uganda</option>
            <option value={"UA"}>Ukraine</option>
            <option value={"AE"}>United Arab Emirates</option>
            <option value={"GB"}>United Kingdom of Great Britain and Northern Ireland</option>
            <option value={"UM"}>United States Minor Outlying Islands</option>
            <option value={"UY"}>Uruguay</option>
            <option value={"UZ"}>Uzbekistan</option>
            <option value={"VU"}>Vanuatu</option>
            <option value={"VE"}>Venezuela (Bolivarian Republic of)</option>
            <option value={"VN"}>Viet Nam</option>
            <option value={"VG"}>Virgin Islands (British)</option>
            <option value={"VI"}>Virgin Islands (U.S.)</option>
            <option value={"WF"}>Wallis and Futuna</option>
            <option value={"EH"}>Western Sahara</option>
            <option value={"YE"}>Yemen</option>
            <option value={"ZM"}>Zambia</option>
            <option value={"ZW"}>Zimbabwe</option>
            </Select>
        </FormControl>
                <TextField
                disabled={!this.props.package.insured}
                error={this.state.quantityError}
                id="pkg_quantity"
                label="Total item quantity"
                className={classes.textField}
                value={this.props.package.quantity}
                onChange={this.props.handleObjChange('quantity','package')}
                margin="normal"
              /><TextField
              disabled={!this.props.package.insured}
              error={this.props.package.value > 800 || this.state.valueError}
              id="pkg_value"
              label="Total value of shipment (USD)"
              className={classes.textField}
              helperText='Maximum of $800USD per shipment.'
              value={this.props.package.value}
              onChange={this.props.handleObjChange('value','package')}
              margin="normal"
            /> */}
          </Fragment>
        );
      default:
        return "Unknown step";
    }
  };

  checkDimensions = async () => {
    if (this.props.package.type === "tube") {
      this.props.setObjData("height", "package", this.props.package.width);
    }

    let response = await this.props.package.dimensions.map((e, i) => {
      if (i + 1 <= this.props.package.count) {
        if (parseFloat(e.height) < 0.001) {
          this.props.setDimensionChange("height", i, "0.001");
        } else {
          this.props.setDimensionChange(
            "height",
            i,
            parseFloat(e.height).toFixed(3).toString()
          );
        }
        if (parseFloat(e.length) < 0.001) {
          this.props.setDimensionChange("length", i, "0.001");
        } else {
          this.props.setDimensionChange(
            "length",
            i,
            parseFloat(e.length).toFixed(3).toString()
          );
        }
        if (parseFloat(e.width) < 0.001) {
          this.props.setDimensionChange("width", i, "0.001");
        } else {
          this.props.setDimensionChange(
            "width",
            i,
            parseFloat(e.width).toFixed(3).toString()
          );
        }
        if (parseFloat(e.weight) < 0.001) {
          this.props.setDimensionChange("weight", i, "0.1");
        } else {
          this.props.setDimensionChange(
            "weight",
            i,
            parseFloat(e.weight).toFixed(3).toString()
          );
        }
        return (
          (this.props.package.type !== "tube" ? isNotEmpty(e.height) : true) &&
          isNotEmpty(e["length"]) &&
          isNotEmpty(e.width) &&
          isNotEmpty(e.weight)
        );
      } else {
        return true;
      }
    });

    !response.filter((e) => e === false).length
      ? this.setState({ measurementError: false })
      : this.setState({ measurementError: true });

    return !response.filter((e) => e === false).length;

    // if(this.props.package.type === "tube"){ this.props.setObjData('height','package',this.props.package.width) }

    // isNotEmpty(this.props.package.height) ? this.setState({heightError: false}) : this.setState({heightError: true})
    // isNotEmpty(this.props.package.width) ? this.setState({widthError: false}) : this.setState({widthError: true})
    // isNotEmpty(this.props.package.length) ? this.setState({lengthError: false}) : this.setState({lengthError: true})
    // isNotEmpty(this.props.package.weight) ? this.setState({weightError: false}) : this.setState({weightError: true})

    // return isNotEmpty(this.props.package.height) &&
    // isNotEmpty(this.props.package.length) &&
    // isNotEmpty(this.props.package.width) &&
    // isNotEmpty(this.props.package.weight)
  };

  checkDescription = () => {
    if (this.props.package.insured) {
      isNotEmpty(this.props.package.description)
        ? this.setState({ descriptionError: false })
        : this.setState({ descriptionError: true });
      isNotEmpty(this.props.package.quantity) && this.props.package.quantity > 1
        ? this.setState({ quantityError: false })
        : this.setState({ quantityError: true });
      isNotEmpty(this.props.package.value) && this.props.package.value < 800
        ? this.setState({ valueError: false })
        : this.setState({ valueError: true });

      return (
        isNotEmpty(this.props.package.description) &&
        isNotEmpty(this.props.package.quantity) &&
        isNotEmpty(this.props.package.value) &&
        this.props.package.value < 800
      );
    }
    return true;
  };

  handleNext = async () => {
    if (
      this.props.account &&
      this.props.account.user &&
      this.props.account.user.fixedPLD &&
      this.props.twoStep == 1
    ) {
      // this.props.handleNext('twoStep')
      this.setState({ isLoading: true });

      let sample = this.props.locations.map((e, i) =>
        e.id === this.props.senderLocationID ? i : false
      );
      sample = sample.filter((e) => e !== false);
      sample = sample[0];
      let test = await resolver(this.props);

      // var services_reduced = test.filter((service) => {
      //   return service.qualify;
      // });

      var services_reduced = [];

      let email = await Auth.currentSession();
      email = email.idToken.payload.email;

      let body = {
        sender: this.props.locations[sample],
        package: this.props.package,
        receiver: this.props.receiver,
        services: services_reduced,
        connection_id: this.state.connection_id,
        cognitoUsername: this.props.account.user.userId,
        email,
      };

      if (this.props.return === true) {
        body.isReturn = true;
        body.sender = {
          title: this.props.receiver.business
            ? this.props.receiver.business
            : this.props.receiver.fname + " " + this.props.receiver.lname,
          locked: null,
          address: {
            street_name: this.props.receiver.address,
            street_number: null,
            street_direction: null,
            postal: this.props.receiver.zip,
            city: this.props.receiver.city,
            unit: this.props.receiver.address2
              ? this.props.receiver.address2
              : null,
            state: this.props.receiver.state,
            country: this.props.receiver.country,
          },
          contact: {
            fname: this.props.receiver.fname,
            lname: this.props.receiver.lname,
            email: null,
            phone: null,
          },
          url: null,
        };
        body.receiver = {
          verified: false,
          business: this.props.locations[sample].title,
          fname: this.props.locations[sample].contact.fname,
          lname: this.props.locations[sample].contact.lname,
          attention:
            this.props.locations[sample].contact.fname +
            " " +
            this.props.locations[sample].contact.lname,
          address: `${
            this.props.locations[sample].address.street_number
              ? this.props.locations[sample].address.street_number + " "
              : ""
          }${this.props.locations[sample].address.street_name}`,
          address2: this.props.locations[sample].address.unit
            ? this.props.locations[sample].address.unit
            : null,
          city: this.props.locations[sample].address.city,
          state: this.props.locations[sample].address.state,
          zip: this.props.locations[sample].address.postal,
          zip5: "",
          country: this.props.locations[sample].address.country,
          phone: "",
          email: this.props.locations[sample].contact.email,
          street_name: this.props.locations[sample].address.street_name,
          street_number: this.props.locations[sample].address.street_number,
        };
        //     "receiver": {
        //       "verified": false,
        //       "business": "NEW TEST LOCATION",
        //       "fname": "B OB",
        //       "lname": "DOLE",
        //       "attention": "B OB DOLE",
        //       "address": "178 BEVERLEY ST",
        //       "address2": null,
        //       "city": "TORONTO",
        //       "state": "ON",
        //       "zip": "M5T1Y8",
        //       "zip5": "",
        //       "country": "CA",
        //       "phone": "6549873214",
        //       "email": "VITOJ96371@ONMAIL.TOP",
        //       "street_name": "BEVERLEY ST",
        //       "street_number": "178",
        //       "is_residential": false
        //     },
      }
      if (this.props.account.user.ics_api) {
        body.ics_api = this.props.account.user.ics_api;
      }
      if (this.props.account.user.ics_rates) {
        body.ics_rates = this.props.account.user.ics_rates;
      }
      if (this.props.account.user.canpar_api) {
        body.canpar_api = this.props.account.user.canpar_api;
      }
      if (this.props.account.user.canpar_rates) {
        body.canpar_rates = this.props.account.user.canpar_rates;
      }
      console.log(body);
      API.post("sqs", "/sqs-rate", {
        body,
      });
      return;
    }
    // if (this.props.twoStep == 1) {
    //     this.checkDimensions().then(e => e ? this.props.handleNext('twoStep') : false)
    // } else if (this.props.twoStep == 2) {
    //     this.setState({ isLoading: true })

    //     let sample = this.props.locations.map((e, i) => e.id === this.props.senderLocationID ? i : false)
    //     sample = sample.filter(e => e !== false)
    //     sample = sample[0]
    //     let test = await resolver(this.props)

    //     var services_reduced = test.filter(service => {
    //         return service.qualify
    //     })
    //     // alert(this.props.return)
    //     let body = `{
    //         ${this.props.return == true ? `isReturn:true,` : ``}
    //         sender: ${this.props.return === true ? this.props.receiver : this.props.locations[sample]},
    //         package: ${this.props.package},
    //         receiver: ${this.props.return === true ? this.props.locations[sample] : this.props.receiver},
    //         services: ${services_reduced},
    //         connection_id: ${this.state.connection_id},
    //         cognitoUsername: ${this.props.account.user.userId}
    //     }`;
    //     // console.log(JSON.parse(body))
    //     if (this.props.account.user.ics_api) {
    //         body.ics_api = this.props.account.user.ics_api;
    //     }
    //     if (this.props.account.user.ics_rates) {
    //         body.ics_rates = this.props.account.user.ics_rates;
    //     }
    //     if (this.props.account.user.canpar_api) {
    //         body.canpar_api = this.props.account.user.canpar_api;
    //     }
    //     if (this.props.account.user.canpar_rates) {
    //         body.canpar_rates = this.props.account.user.canpar_rates;
    //     }
    //     // console.log(body)
    //     API.post('sqs', '/sqs-rate', {
    //         body: JSON.parse(body)
    //     })
    else {
      this.props.handleNext("twoStep");
    }
  };

  renderPackages(arr) {
    if (arr.length > 1) {
      return [{}].concat(arr).map((note, i) => {
        if (i !== 0) {
          return (
            <Fragment>
              {note.length} inches x {note.width} inches x {note.height} inches
              <br />
            </Fragment>
          );
        }
      });
    } else {
      return (
        <Fragment>
          {arr[0]["length"]} inches x {arr[0].width} inches x {arr[0].height}{" "}
          inches
          <br />
          {arr[0].weight} pounds
        </Fragment>
      );
    }
  }

  render() {
    const { classes } = this.props;
    const steps = this.getSteps();
    const activeStep = this.props.twoStep;

    // console.log("PKG MEASUREMENTS =>>>>> ", this.props)

    return (
      <div>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                <StepContent>
                  <Typography>{this.getStepContent(index)}</Typography>
                  <div className={classes.actionsContainer}>
                    <div>
                      {activeStep === 0 ? (
                        <Button
                          onClick={() => this.props.handleBack("activeStep")}
                          className={classes.button}
                        >
                          Back
                        </Button>
                      ) : (
                        <Button
                          onClick={() => this.props.handleBack("twoStep")}
                          className={classes.button}
                        >
                          Back
                        </Button>
                      )}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          this.handleNext();
                        }}
                        className={classes.button}
                        disabled={
                          this.state.isLoading ||
                          this.state.connection_id === ""
                        }
                      >
                        {!this.state.isLoading &&
                        this.state.connection_id !== "" ? (
                          "Confirm and continue"
                        ) : (
                          <LoadingIcon size={20} />
                        )}
                      </Button>
                      <Typography variant="subheading">
                        {this.state.errorMsg}
                      </Typography>
                    </div>
                  </div>
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
        {/* {activeStep == steps.length && (
                        <Paper square elevation={0} className={classes.resetContainer}>
                        <Typography variant="subheading">
                            <strong>{this.props.package.dimensions.length} {this.props.package.dimensions.length === 1 ? `Package:` : `Packages:`}</strong><br/>
                            {this.props.account && this.props.account.user && this.props.account.user.fixedPLD ? '' : `${this.renderPackages(this.props.package.dimensions)}
                            ${this.props.package.insured && <Fragment>{this.props.package.description}, Qty {this.props.package.quantity}<br/>
                            {this.props.package.value} value</Fragment> }`}
                        </Typography>
                        <Button
                            disabled={this.state.isLoading}
                            onClick={() => this.props.handleBack('twoStep')}
                            className={classes.button}>
                            Edit
                        </Button>
                        <Button
                            disabled={this.state.isLoading}
                            onClick={() => this.handleNext() }
                            className={classes.button}
                            variant="contained"
                            color="primary">
                            { !this.state.isLoading ? "Confirm and continue" : <LoadingIcon size={20} /> }
                        </Button>
                        
                        </Paper>
            )} */}
      </div>
    );
  }
}

VerticalLinearStepper.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(VerticalLinearStepper);
