import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

function CircularIndeterminate(props) {
  return (
    <div>
      <CircularProgress {...props} />
    </div>
  );
}

export default CircularIndeterminate;
