import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CheckBox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import Slide from "@material-ui/core/Slide";
import Step from "@material-ui/core/Step";
import StepContent from "@material-ui/core/StepContent";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { API } from "aws-amplify";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import resolver from "../../helpers/resolverUS";
import { isNotEmpty } from "../../helpers/Validation";
import LoadingIcon from "../loading/LoadingIcon";
import PackageMeasurements from "../PackageMeasurements";
import { Auth } from "aws-amplify";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = (theme) => ({
  textField: {
    width: "100%",
    boxSizing: "border-box",
    fontSize: "1.5em",
  },
  menu: {
    width: 200,
  },
  formControl: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2,
  },
  resetContainer: {
    padding: theme.spacing.unit * 3,
  },
});

class VerticalLinearStepper extends React.Component {
  state = {
    activeStep: 0,
    isLoading: false,
    mediamail: false,
    open: false,
  };

  getSteps = () => {
    return [
      // this.language.PackageType[this.state.language],
      this.language.PackageMeasurements[this.state.language],
      this.language.PackageContents[this.state.language],
    ];
  };

  UNSAFE_componentWillMount = async () => {
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });
  };

  language = {
    PackageType: {
      FR: "Type d'emballage",
      EN: "Package Type",
    },
    PackageMeasurements: {
      FR: "Mesures de l'emballage",
      EN: "Package Measurements",
    },
    PackageContents: {
      FR: "Contenu du colis",
      EN: "Package Contents",
    },
    CountryofOrigin: {
      FR: "Pays d'origine",
      EN: "Country of Origin",
    },
    Totalitemquantity: {
      FR: "Quantité totale d'articles",
      EN: "Total item quantity",
    },
    TotalValueOfShipmentUSD: {
      FR: "Valeur totale de l'expédition (USD)",
      EN: "Total value of shipment (USD)",
    },
    MaximumOf800USDPerShipment: {
      FR: "Maximum de 800 USD par envoi",
      EN: "Maximum of $800 USD per shipment",
    },
    Optional: {
      FR: "En option",
      EN: "Optional",
    },
    inches: {
      FR: "pouces",
      EN: "inches",
    },
    pounds: {
      FR: "livre",
      EN: "pounds",
    },
    Package: {
      FR: "Paquet",
      EN: "Package",
    },
    Packages: {
      FR: "Paquets",
      EN: "Packages",
    },
    Qty: {
      FR: "Qté",
      EN: "Qty",
    },
    value: {
      FR: "valeur",
      EN: "value",
    },
    Next: {
      FR: "Suivant",
      EN: "Next",
    },
    Back: {
      FR: "Arrière",
      EN: "Back",
    },
    ConfirmAndContinue: {
      FR: "Confirmer et continuer",
      EN: "Confirm and Continue",
    },
    HarmonizedCodes: {
      FR: "Code harmonisé d'article",
      EN: "Item(s) Harmonized Code(s)",
    },
    ItemsDescription: {
      FR: "Description de l'article",
      EN: "Item(s) Description",
    },
  };

  componentDidMount = async () => {
    // alert('socket')
    const socket2 = new WebSocket(
      "wss://keuhz93lxj.execute-api.us-east-1.amazonaws.com/dev"
    );

    socket2.addEventListener("open", function (event) {
      socket2.send(JSON.stringify({ action: "sendMessage" }));
      // alert('socket connected')
    });

    socket2.addEventListener("message", (event) => {
      // alert('socket msg');
      // console.log(JSON.parse(event.data));
      let data = JSON.parse(event.data);
      if (data.connectionId) {
        // alert(data.connectionId)
        this.setState({ connection_id: data.connectionId });
        // console.log("CONNECTED, ",data.connectionId)
      }
      if (data.rate) {
        this.props.setRates(
          "rates",
          data.rate.filter((f) => {
            return f.rate != 0;
          })
        );
        this.props.handleNext("activeStep");
      }
    });
  };

  getStepContent = (step) => {
    console.log(this.props.account.user.usps_rates);
    const { classes } = this.props;
    switch (step) {
      // case 0:
      //   return (
      //     <Fragment>
      //       <PackageType {...this.props} />
      //     </Fragment>
      //   );
      case 0:
        return (
          <Fragment>
            <PackageMeasurements
              sends_oversized={
                this.props.account.user.sends_oversized ? true : false
              }
              country="US"
              maxQuantity={1}
              error={this.state.measurementError}
              {...this.props}
            />
          </Fragment>
        );
      case 1:
        return (
          <Fragment>
            {/* <FormControlLabel
            control={
                <Switch
                    name="insured"
                    checked={this.props.package.insured}
                    onChange={() => this.props.setObjData('insured','package',!this.props.package.insured)}
                    value="insured"
                    color="primary"
                />
            }
            label="Insure package ($0.30CAD for every $100USD of coverage)"
            /> */}
            <TextField
              disabled={false}
              error={this.state.descriptionError}
              id="pkg_description"
              label={this.language.ItemsDescription[this.state.language]}
              className={classes.textField}
              value={this.props.package.description}
              onChange={this.props.handleObjChange("description", "package")}
              margin="normal"
            />
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="country-native-simple" shrink>
                {this.language.CountryofOrigin[this.state.language]}
              </InputLabel>
              <Select
                disabled={false}
                native
                required
                value={this.props.package.origin}
                onChange={this.props.handleObjChange("origin", "package")}
                inputProps={{
                  name: "origin",
                  id: "country-native-simple",
                }}
              >
                <option value={"AF"}>Afghanistan</option>
                <option value={"AX"}>Aland Islands</option>
                <option value={"AL"}>Albania</option>
                <option value={"DZ"}>Algeria</option>
                <option value={"AS"}>American Samoa</option>
                <option value={"AD"}>Andorra</option>
                <option value={"AO"}>Angola</option>
                <option value={"AI"}>Anguilla</option>
                <option value={"AQ"}>Antarctica</option>
                <option value={"AG"}>Antigua and Barbuda</option>
                <option value={"AR"}>Argentina</option>
                <option value={"AM"}>Armenia</option>
                <option value={"AW"}>Aruba</option>
                <option value={"AU"}>Australia</option>
                <option value={"AT"}>Austria</option>
                <option value={"AZ"}>Azerbaijan</option>
                <option value={"BS"}>Bahamas</option>
                <option value={"BH"}>Bahrain</option>
                <option value={"BD"}>Bangladesh</option>
                <option value={"BB"}>Barbados</option>
                <option value={"BY"}>Belarus</option>
                <option value={"BE"}>Belgium</option>
                <option value={"BZ"}>Belize</option>
                <option value={"BJ"}>Benin</option>
                <option value={"BM"}>Bermuda</option>
                <option value={"BT"}>Bhutan</option>
                <option value={"BO"}>Bolivia (Plurinational State of)</option>
                <option value={"BQ"}>Bonaire (Sint Eustatius and Saba)</option>
                <option value={"BA"}>Bosnia and Herzegovina</option>
                <option value={"BW"}>Botswana</option>
                <option value={"BV"}>Bouvet Island</option>
                <option value={"BR"}>Brazil</option>
                <option value={"IO"}>British Indian Ocean Territory</option>
                <option value={"BN"}>Brunei Darussalam</option>
                <option value={"BG"}>Bulgaria</option>
                <option value={"BF"}>Burkina Faso</option>
                <option value={"BI"}>Burundi</option>
                <option value={"CV"}>Cabo Verde</option>
                <option value={"KH"}>Cambodia</option>
                <option value={"CM"}>Cameroon</option>
                <option value={"CA"}>Canada</option>
                <option value={"KY"}>Cayman Islands</option>
                <option value={"CF"}>Central African Republic</option>
                <option value={"TD"}>Chad</option>
                <option value={"CL"}>Chile</option>
                <option value={"CN"}>China</option>
                <option value={"CX"}>Christmas Island</option>
                <option value={"CC"}>Cocos (Keeling) Islands</option>
                <option value={"CO"}>Colombia</option>
                <option value={"KM"}>Comoros</option>
                <option value={"CG"}>Congo</option>
                <option value={"CD"}>Congo (Democratic Republic of the)</option>
                <option value={"CK"}>Cook Islands</option>
                <option value={"CR"}>Costa Rica</option>
                <option value={"CI"}>Cote d'Ivoire</option>
                <option value={"HR"}>Croatia</option>
                <option value={"CU"}>Cuba</option>
                <option value={"CW"}>Curacao</option>
                <option value={"CY"}>Cyprus</option>
                <option value={"CZ"}>Czechia</option>
                <option value={"DK"}>Denmark</option>
                <option value={"DJ"}>Djibouti</option>
                <option value={"DM"}>Dominica</option>
                <option value={"DO"}>Dominican Republic</option>
                <option value={"EC"}>Ecuador</option>
                <option value={"EG"}>Egypt</option>
                <option value={"SV"}>El Salvador</option>
                <option value={"GQ"}>Equatorial Guinea</option>
                <option value={"ER"}>Eritrea</option>
                <option value={"EE"}>Estonia</option>
                <option value={"SZ"}>Eswatini</option>
                <option value={"ET"}>Ethiopia</option>
                <option value={"FK"}>Falkland Islands (Malvinas)</option>
                <option value={"FO"}>Faroe Islands</option>
                <option value={"FJ"}>Fiji</option>
                <option value={"FI"}>Finland</option>
                <option value={"FR"}>France</option>
                <option value={"GF"}>French Guiana</option>
                <option value={"PF"}>French Polynesia</option>
                <option value={"TF"}>French Southern Territories</option>
                <option value={"GA"}>Gabon</option>
                <option value={"GM"}>Gambia</option>
                <option value={"GE"}>Georgia</option>
                <option value={"DE"}>Germany</option>
                <option value={"GH"}>Ghana</option>
                <option value={"GI"}>Gibraltar</option>
                <option value={"GR"}>Greece</option>
                <option value={"GL"}>Greenland</option>
                <option value={"GD"}>Grenada</option>
                <option value={"GP"}>Guadeloupe</option>
                <option value={"GU"}>Guam</option>
                <option value={"GT"}>Guatemala</option>
                <option value={"GG"}>Guernsey</option>
                <option value={"GN"}>Guinea</option>
                <option value={"GW"}>Guinea-Bissau</option>
                <option value={"GY"}>Guyana</option>
                <option value={"HT"}>Haiti</option>
                <option value={"HM"}>Heard Island and McDonald Islands</option>
                <option value={"VA"}>Holy See</option>
                <option value={"HN"}>Honduras</option>
                <option value={"HK"}>Hong Kong</option>
                <option value={"HU"}>Hungary</option>
                <option value={"IS"}>Iceland</option>
                <option value={"IN"}>India</option>
                <option value={"ID"}>Indonesia</option>
                <option value={"IR"}>Iran (Islamic Republic of)</option>
                <option value={"IQ"}>Iraq</option>
                <option value={"IE"}>Ireland</option>
                <option value={"IM"}>Isle of Man</option>
                <option value={"IL"}>Israel</option>
                <option value={"IT"}>Italy</option>
                <option value={"JM"}>Jamaica</option>
                <option value={"JP"}>Japan</option>
                <option value={"JE"}>Jersey</option>
                <option value={"JO"}>Jordan</option>
                <option value={"KZ"}>Kazakhstan</option>
                <option value={"KE"}>Kenya</option>
                <option value={"KI"}>Kiribati</option>
                <option value={"KP"}>
                  Korea (Democratic People's Republic of)
                </option>
                <option value={"KR"}>Korea (Republic of)</option>
                <option value={"KW"}>Kuwait</option>
                <option value={"KG"}>Kyrgyzstan</option>
                <option value={"LA"}>Lao People's Democratic Republic</option>
                <option value={"LV"}>Latvia</option>
                <option value={"LB"}>Lebanon</option>
                <option value={"LS"}>Lesotho</option>
                <option value={"LR"}>Liberia</option>
                <option value={"LY"}>Libya</option>
                <option value={"LI"}>Liechtenstein</option>
                <option value={"LT"}>Lithuania</option>
                <option value={"LU"}>Luxembourg</option>
                <option value={"MO"}>Macao</option>
                <option value={"MK"}>
                  Macedonia (the former Yugoslav Republic of)
                </option>
                <option value={"MG"}>Madagascar</option>
                <option value={"MW"}>Malawi</option>
                <option value={"MY"}>Malaysia</option>
                <option value={"MV"}>Maldives</option>
                <option value={"ML"}>Mali</option>
                <option value={"MT"}>Malta</option>
                <option value={"MH"}>Marshall Islands</option>
                <option value={"MQ"}>Martinique</option>
                <option value={"MR"}>Mauritania</option>
                <option value={"MU"}>Mauritius</option>
                <option value={"YT"}>Mayotte</option>
                <option value={"MX"}>Mexico</option>
                <option value={"FM"}>Micronesia (Federated States of)</option>
                <option value={"MD"}>Moldova (Republic of)</option>
                <option value={"MC"}>Monaco</option>
                <option value={"MN"}>Mongolia</option>
                <option value={"ME"}>Montenegro</option>
                <option value={"MS"}>Montserrat</option>
                <option value={"MA"}>Morocco</option>
                <option value={"MZ"}>Mozambique</option>
                <option value={"MM"}>Myanmar</option>
                <option value={"NA"}>Namibia</option>
                <option value={"NR"}>Nauru</option>
                <option value={"NP"}>Nepal</option>
                <option value={"NL"}>Netherlands</option>
                <option value={"NC"}>New Caledonia</option>
                <option value={"NZ"}>New Zealand</option>
                <option value={"NI"}>Nicaragua</option>
                <option value={"NE"}>Niger</option>
                <option value={"NG"}>Nigeria</option>
                <option value={"NU"}>Niue</option>
                <option value={"NF"}>Norfolk Island</option>
                <option value={"MP"}>Northern Mariana Islands</option>
                <option value={"NO"}>Norway</option>
                <option value={"OM"}>Oman</option>
                <option value={"PK"}>Pakistan</option>
                <option value={"PW"}>Palau</option>
                <option value={"PS"}>Palestine (State of)</option>
                <option value={"PA"}>Panama</option>
                <option value={"PG"}>Papua New Guinea</option>
                <option value={"PY"}>Paraguay</option>
                <option value={"PE"}>Peru</option>
                <option value={"PH"}>Philippines</option>
                <option value={"PN"}>Pitcairn</option>
                <option value={"PL"}>Poland</option>
                <option value={"PT"}>Portugal</option>
                <option value={"PR"}>Puerto Rico</option>
                <option value={"QA"}>Qatar</option>
                <option value={"RE"}>Reunion</option>
                <option value={"RO"}>Romania</option>
                <option value={"RU"}>Russian Federation</option>
                <option value={"RW"}>Rwanda</option>
                <option value={"BL"}>Saint Barthelemy</option>
                <option value={"SH"}>
                  Saint Helena (Ascension and Tristan da Cunha)
                </option>
                <option value={"KN"}>Saint Kitts and Nevis</option>
                <option value={"LC"}>Saint Lucia</option>
                <option value={"MF"}>Saint Martin (French part)</option>
                <option value={"PM"}>Saint Pierre and Miquelon</option>
                <option value={"VC"}>Saint Vincent and the Grenadines</option>
                <option value={"WS"}>Samoa</option>
                <option value={"SM"}>San Marino</option>
                <option value={"ST"}>Sao Tome and Principe</option>
                <option value={"SA"}>Saudi Arabia</option>
                <option value={"SN"}>Senegal</option>
                <option value={"RS"}>Serbia</option>
                <option value={"SC"}>Seychelles</option>
                <option value={"SL"}>Sierra Leone</option>
                <option value={"SG"}>Singapore</option>
                <option value={"SX"}>Sint Maarten (Dutch part)</option>
                <option value={"SK"}>Slovakia</option>
                <option value={"SI"}>Slovenia</option>
                <option value={"SB"}>Solomon Islands</option>
                <option value={"SO"}>Somalia</option>
                <option value={"ZA"}>South Africa</option>
                <option value={"GS"}>
                  South Georgia and the South Sandwich Islands
                </option>
                <option value={"SS"}>South Sudan</option>
                <option value={"ES"}>Spain</option>
                <option value={"LK"}>Sri Lanka</option>
                <option value={"SD"}>Sudan</option>
                <option value={"SR"}>Suriname</option>
                <option value={"SJ"}>Svalbard and Jan Mayen</option>
                <option value={"SE"}>Sweden</option>
                <option value={"CH"}>Switzerland</option>
                <option value={"SY"}>Syrian Arab Republic</option>
                <option value={"TW"}>Taiwan (Province of China)</option>
                <option value={"TJ"}>Tajikistan</option>
                <option value={"TZ"}>Tanzania (United Republic of)</option>
                <option value={"TH"}>Thailand</option>
                <option value={"TL"}>Timor-Leste</option>
                <option value={"TG"}>Togo</option>
                <option value={"TK"}>Tokelau</option>
                <option value={"TO"}>Tonga</option>
                <option value={"TT"}>Trinidad and Tobago</option>
                <option value={"TN"}>Tunisia</option>
                <option value={"TR"}>Turkey</option>
                <option value={"TM"}>Turkmenistan</option>
                <option value={"TC"}>Turks and Caicos Islands</option>
                <option value={"TV"}>Tuvalu</option>
                <option value={"UG"}>Uganda</option>
                <option value={"UA"}>Ukraine</option>
                <option value={"AE"}>United Arab Emirates</option>
                <option value={"GB"}>
                  United Kingdom of Great Britain and Northern Ireland
                </option>
                <option value={"UM"}>
                  United States Minor Outlying Islands
                </option>
                <option value={"UY"}>Uruguay</option>
                <option value={"UZ"}>Uzbekistan</option>
                <option value={"VU"}>Vanuatu</option>
                <option value={"VE"}>Venezuela (Bolivarian Republic of)</option>
                <option value={"VN"}>Viet Nam</option>
                <option value={"VG"}>Virgin Islands (British)</option>
                <option value={"VI"}>Virgin Islands (U.S.)</option>
                <option value={"WF"}>Wallis and Futuna</option>
                <option value={"EH"}>Western Sahara</option>
                <option value={"YE"}>Yemen</option>
                <option value={"ZM"}>Zambia</option>
                <option value={"ZW"}>Zimbabwe</option>
              </Select>
            </FormControl>
            <TextField
              // disabled={!this.props.package.insured}
              error={this.state.quantityError}
              id="pkg_quantity"
              label={this.language.Totalitemquantity[this.state.language]}
              className={classes.textField}
              value={this.props.package.quantity}
              onChange={this.props.handleObjChange("quantity", "package")}
              margin="normal"
            />
            <TextField
              //   disabled={!this.props.package.insured}
              error={this.props.package.value > 800 || this.state.valueError}
              id="pkg_value"
              label={this.language.TotalValueOfShipmentUSD[this.state.language]}
              className={classes.textField}
              helperText={
                this.language.MaximumOf800USDPerShipment[this.state.language]
              }
              value={this.props.package.value}
              onChange={this.props.handleObjChange("value", "package")}
              margin="normal"
            />
            <TextField
              //   disabled={!this.props.package.insured}
              //   error={this.props.package.hs_code}
              id="hs_code"
              label={this.language.HarmonizedCodes[this.state.language]}
              className={classes.textField}
              helperText={`* ${this.language.Optional[this.state.language]}`}
              value={this.props.package.hs_code}
              onChange={this.props.handleObjChange("hs_code", "package")}
              margin="normal"
            />
            {this.props.account.user.usps_rates &&
              (this.props.account.user.usps_rates["MM-PRSS"] ||
                this.props.account.user.usps_rates["MM-PRSB"]) && (
                <>
                  <br />
                  <br />
                  <div
                    style={{
                      border: "5px solid rgba(0, 153, 206, .8)",
                      color: "rgba(0,0,0,.8)",
                      padding: "20px 30px",
                      borderRadius: "7px",
                    }}
                  >
                    <Grid container>
                      <Grid item>
                        <strong style={{ fontSize: "1.1em" }}>
                          Shipping Media Materials?
                        </strong>
                        <br />
                        <br />
                        USPS offers a cost effective way to ship educational
                        materials.
                        <br />
                        <a
                          style={{
                            fontWeight: "bold",
                            cursor: "pointer",
                            color: "var(--brand-cyan)",
                          }}
                          onClick={() => this.setState({ open: true })}
                        >
                          Restrictions apply, click here for more details
                        </a>
                        .
                        <br />
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item>
                        <FormControlLabel
                          control={
                            <CheckBox
                              style={{ fontWeight: "bold" }}
                              name="mediamail"
                              checked={this.state.mediamail}
                              onChange={() =>
                                this.setState({
                                  mediamail: !this.state.mediamail,
                                })
                              }
                              value="mediamail"
                              color="primary"
                            />
                          }
                          label="Yes, my shipment is eligible for Media Mail®"
                        />
                      </Grid>
                    </Grid>
                  </div>
                </>
              )}
          </Fragment>
        );
      default:
        return "Unknown step";
    }
  };

  checkDimensions = async () => {
    if (this.props.package.type === "tube") {
      this.props.setObjData("height", "package", this.props.package.width);
    }

    let response = await this.props.package.dimensions.map((e, i) => {
      if (i + 1 <= this.props.package.count) {
        if (parseFloat(e.height) < 0.001) {
          this.props.setDimensionChange("height", i, "0.001");
        } else {
          this.props.setDimensionChange(
            "height",
            i,
            parseFloat(e.height).toFixed(3).toString()
          );
        }
        if (parseFloat(e.length) < 0.001) {
          this.props.setDimensionChange("length", i, "0.001");
        } else {
          this.props.setDimensionChange(
            "length",
            i,
            parseFloat(e.length).toFixed(3).toString()
          );
        }
        if (parseFloat(e.width) < 0.001) {
          this.props.setDimensionChange("width", i, "0.001");
        } else {
          this.props.setDimensionChange(
            "width",
            i,
            parseFloat(e.width).toFixed(3).toString()
          );
        }
        if (parseFloat(e.weight) < 0.001) {
          this.props.setDimensionChange("weight", i, "0.1");
        } else {
          this.props.setDimensionChange(
            "weight",
            i,
            parseFloat(e.weight).toFixed(3).toString()
          );
        }
        return (
          (this.props.package.type !== "tube" ? isNotEmpty(e.height) : true) &&
          isNotEmpty(e["length"]) &&
          isNotEmpty(e.width) &&
          isNotEmpty(e.weight)
        );
      } else {
        return true;
      }
    });

    !response.filter((e) => e === false).length
      ? this.setState({ measurementError: false })
      : this.setState({ measurementError: true });

    return !response.filter((e) => e === false).length;

    // if(this.props.package.type === "tube"){ this.props.setObjData('height','package',this.props.package.width) }

    // isNotEmpty(this.props.package.height) ? this.setState({heightError: false}) : this.setState({heightError: true})
    // isNotEmpty(this.props.package.width) ? this.setState({widthError: false}) : this.setState({widthError: true})
    // isNotEmpty(this.props.package.length) ? this.setState({lengthError: false}) : this.setState({lengthError: true})
    // isNotEmpty(this.props.package.weight) ? this.setState({weightError: false}) : this.setState({weightError: true})

    // return isNotEmpty(this.props.package.height) &&
    // isNotEmpty(this.props.package.length) &&
    // isNotEmpty(this.props.package.width) &&
    // isNotEmpty(this.props.package.weight)
  };

  checkDescription = () => {
    // if( this.props.package.insured ) {
    isNotEmpty(this.props.package.description)
      ? this.setState({ descriptionError: false })
      : this.setState({ descriptionError: true });
    isNotEmpty(this.props.package.quantity) && this.props.package.quantity > 1
      ? this.setState({ quantityError: false })
      : this.setState({ quantityError: true });
    isNotEmpty(this.props.package.value) && this.props.package.value < 800
      ? this.setState({ valueError: false })
      : this.setState({ valueError: true });

    return (
      isNotEmpty(this.props.package.description) &&
      isNotEmpty(this.props.package.quantity) &&
      isNotEmpty(this.props.package.value) &&
      this.props.package.value < 800
    );
    // }
    // return true
  };

  handleNext = async () => {
    if (this.props.twoStep === 0) {
      this.checkDimensions().then((e) =>
        e ? this.props.handleNext("twoStep") : false
      );
    } else if (this.props.twoStep === 1) {
      if (this.checkDescription()) {
        this.props.handleNext("twoStep");
      }
    } else if (this.props.twoStep === 2) {
      this.setState({ isLoading: true });

      let sample = this.props.locations.map((e, i) =>
        e.id === this.props.senderLocationID ? i : false
      );
      sample = sample.filter((e) => e !== false);
      sample = sample[0];
      let test = await resolver(this.props, this.state.mediamail);
      // console.log(test)
      var services_reduced = test.filter((service) => {
        return service.qualify;
      });

      let email = await Auth.currentSession();
      email = email.idToken.payload.email;

      let body = {
        sender: this.props.locations[sample],
        package: this.props.package,
        receiver: this.props.receiver,
        services: services_reduced,
        connection_id: this.state.connection_id,
        cognitoUsername: this.props.account.user.userId,
        email,
      };
      // console.log(body)
      if (this.props.account.user.usps_api) {
        body.usps_api = this.props.account.user.usps_api;
      }
      if (this.props.account.user.usps_rates) {
        body.usps_rates = this.props.account.user.usps_rates;
      }
      // console.log(body);
      API.post("sqs", "/sqs-rate", {
        body,
      });

      // getRates({
      //     ...this.props,
      //     sender: this.props.locations[sample]
      // }, test, e => {
      //     // console.log(e)
      //     this.setState({isLoading:false})
      //     if(e.response != undefined){
      //         this.props.setRates('rates',e.response.filter( f => {
      //             return f.rate != 0
      //         }))
      //         this.props.handleNext('activeStep')
      //     } else{

      //     }
      // })
    } else {
      this.props.handleNext("twoStep");
    }
  };

  renderPackages(arr) {
    if (arr.length > 1) {
      return [{}].concat(arr).map((note, i) => {
        if (i !== 0) {
          return (
            <Fragment>
              {note["length"]} {this.language.inches[this.state.language]} x{" "}
              {note.width} {this.language.inches[this.state.language]} x{" "}
              {note.height} {this.language.inches[this.state.language]}
              <br />
              {note.weight} {this.language.pounds[this.state.language]}
              <br />
            </Fragment>
          );
        }
      });
    } else {
      return (
        <Fragment>
          {arr[0]["length"]} {this.language.inches[this.state.language]} x{" "}
          {arr[0].width} {this.language.inches[this.state.language]} x{" "}
          {arr[0].height} {this.language.inches[this.state.language]}
          <br />
          {arr[0].weight} {this.language.pounds[this.state.language]}
          <br />
        </Fragment>
      );
    }
  }

  render() {
    const { classes } = this.props;
    const steps = this.getSteps();
    const activeStep = this.props.twoStep;

    // console.log("PKG MEASUREMENTS =>>>>> ", this.props)

    return (
      <div>
        <div>
          <Dialog
            open={this.state.open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => this.setState({ open: false })}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">
              {"Media Mail Restrictions"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Qualifying items:
                <ul>
                  <li>
                    books (at least eight pages, containing no advertising other
                    than incidental announcements of other books)
                  </li>
                  <li>film (16 mm or narrower)</li>
                  <li>printed music</li>
                  <li>printed test materials</li>
                  <li>video and sound recordings</li>
                  <li>play scripts</li>
                  <li>printed educational charts</li>
                  <li>
                    loose-leaf pages and binders consisting of medical
                    information
                  </li>
                  <li>computer-readable media</li>
                </ul>
                <br />
                <br />
                <strong style={{ opacity: 0.6 }}>
                  <small>
                    Shipments containing items that are not on the approved item
                    list above will be subject to a $50CAD penalty as laid out
                    in our{" "}
                    <a
                      href="https://www.swiftpost.com/terms-of-use/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms and Services
                    </a>{" "}
                    agreement.
                  </small>
                </strong>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => this.setState({ open: false })}
                color="primary"
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                <StepContent>
                  <Typography>{this.getStepContent(index)}</Typography>
                  <div className={classes.actionsContainer}>
                    <div>
                      {activeStep === 0 ? (
                        <Button
                          onClick={() => this.props.handleBack("activeStep")}
                          className={classes.button}
                        >
                          {this.language.Back[this.state.language]}
                        </Button>
                      ) : (
                        <Button
                          onClick={() => this.props.handleBack("twoStep")}
                          className={classes.button}
                        >
                          {this.language.Back[this.state.language]}
                        </Button>
                      )}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          this.handleNext();
                        }}
                        className={classes.button}
                      >
                        {this.language.Next[this.state.language]}
                      </Button>
                      <Typography variant="subheading">
                        {this.state.errorMsg}
                      </Typography>
                    </div>
                  </div>
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
        {activeStep == steps.length && (
          <Paper square elevation={0} className={classes.resetContainer}>
            <Typography variant="subheading">
              <strong>
                {this.props.package.dimensions.length == 1
                  ? `${this.language.Package[this.state.language]}:`
                  : `${this.language.Package[this.state.language]}s:`}
              </strong>
              <br />
              {this.renderPackages(this.props.package.dimensions)}
              {this.props.package.insured == "true" && (
                <Fragment>
                  {this.props.package.description}, $
                  {this.language.Qty[this.state.language]}{" "}
                  {this.props.package.quantity}
                  <br />${this.props.package.value}{" "}
                  {this.language.value[this.state.language]}
                </Fragment>
              )}
            </Typography>
            <Button
              disabled={this.state.isLoading}
              onClick={() => this.props.handleBack("twoStep")}
              className={classes.button}
            >
              Edit
            </Button>
            <Button
              disabled={this.state.isLoading || this.state.connection_id === ""}
              onClick={() => this.handleNext()}
              className={classes.button}
              variant="contained"
              color="primary"
            >
              {!this.state.isLoading && this.state.connection_id !== "" ? (
                this.language.ConfirmAndContinue[this.state.language]
              ) : (
                <LoadingIcon size={20} />
              )}
            </Button>
          </Paper>
        )}
      </div>
    );
  }
}

VerticalLinearStepper.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(VerticalLinearStepper);
