import AddressBookDialog from "../address_book/AddressBookDialog";
import ReceiverForm from "./ReceiverForm";

export default function ReceiverModal(props: {
  open: boolean;
  onClose: any;
  address: any;
}) {
  const handleFormSuccess = (updated_address:any) => {
    props.onClose(updated_address);
  };

  return (
    <AddressBookDialog
      title="Receiver Address"
      open={props.open}
      onClose={props.onClose}
    >
      <ReceiverForm onSuccess={handleFormSuccess} address={props.address} />
    </AddressBookDialog>
  );
}