import { API } from "aws-amplify";

const listTransactions = (page, limit) =>
  new Promise(async (resolve, reject) => {
    // console.log({
    //   "page": page,
    //   "limit": limit
    // })
    let something = await API.post("sqs", "/transactions/get", {
      body: {
        page: page,
        limit: limit,
      },
    }).catch((e) => resolve(listTransactions(page, limit)));
    // console.log(something)
    resolve(something);
  });

export default listTransactions;
