import {
  Button,
  Collapse,
  Grid,
  IconButton,
  Theme,
  Tooltip,
} from "@material-ui/core";
import { StyleRulesCallback, withStyles } from "@material-ui/core/styles";
import AddressBookPickerDialog from "components/AddressBookPickerDialog";
import { useState, useEffect } from "react";
import axios from "axios";
import { querySelfDeliveryAccount } from "api/selfDelivery";
import LocationPicker from "components/LocationPicker";
import { Contacts, Edit, WarningOutlined } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import MinusIcon from "@material-ui/icons/Remove";
import { API, graphqlOperation } from "aws-amplify";
import ReceiverModal from "./ReceiverModal";
import { Link } from "react-router-dom";
import LoadingIcon from "components/loading/LoadingIcon";

const styles: StyleRulesCallback = (theme: Theme) => ({
  form: {
    padding: "0 16px",
  },
  formButtons: {
    paddingTop: 16,
    marginTop: 16,
    borderTop: "1px solid rgba(0, 0, 0, 0.09)",
    textAlign: "right",
  },
});

export type Receiver = {
    business_name?: string | null,
    first_name?: string,
    last_name?: string,
    phone?: string | null,
    email?: string | null,
    address1: string,
    address2?: string | null,
    city: string,
    state: string,
    postal: string,
    country: string,
    open_time?: string | null,
    close_time?: string | null,
    location_note?: string | null,
    favorite?: boolean,
};

const CreateShipment = (props: {
    classes: any;
    editID: string | null,
    locations: any,
    account: object,
    location: any,
    shipment: any,
}) => {
    const [fetchingAccount, setFetchingAccount] = useState<boolean>(true);
    const [loading, setLoading] = useState(false);
    const [receiverLocationPickerOpen, setReceiverLocationPickerOpen] = useState(false);
    const [senderLocationPickerOpen, setSenderLocationPickerOpen] = useState(false);
    const [addressBookPickerOpen, setAddressBookPickerOpen] = useState(false);
    const [sender, setSender] = useState<any>(props?.locations?.filter((f:any)=>f?.id == props?.shipment?.sender_id)?.[0] ?? props?.locations?.[0]);
    const [senderId, setSenderId] = useState<any>(props.shipment ? props.shipment.id : null);
    const [receiver, setReceiver] = useState<any>(props.shipment ? {
        business_name: props.shipment.receiver.title,
        email: props.shipment.receiver.contact.email,
        country: props.shipment.receiver.address.country,
        state: props.shipment.receiver.address.state,
        city: props.shipment.receiver.address.city,
        last_name: props.shipment.receiver.contact.lname,
        open_time: props.shipment.open_time,
        close_time: props.shipment.close_time,
        first_name: props.shipment.receiver.contact.fname,
        postal: props.shipment.receiver.address.postal,
        phone: props.shipment.receiver.contact.phone,
        address1: `${props.shipment.receiver.address.street_number} ${props.shipment.receiver.address.street_name}`,
        location_note: props.shipment.location_note,
        address2: props.shipment.receiver.address.unit
    } : null);

    const [account, setAccount] = useState<any>();
    const [packageCount, setPackageCount] = useState<number>(props.shipment ? props.shipment.package.entered_pld.length : 1);
    const [aosDistance, setAosDistance] = useState<number>(0);

    async function getCoordsFromAddress(address:any) {
        try {
            const response = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURI(`${address.address1}, ${address.city}, ${address.state}, CANADA`)}&key=AIzaSyBNMRsO80tsOGxVUIJbrDKw0DuUSvFrIhc`
            );
            destinationOutsideAOS(account, response?.data?.results?.[0]?.geometry?.location)
            return response?.data?.results?.[0]?.geometry?.location;
        } catch (err) {
            console.warn(err);
            return;
        }
    };

    function destinationOutsideAOS(selfDeliveryAccount:any, destinationCoords:any){
        const originCoords = selfDeliveryAccount.origin_center;
        const aosRadius_km = selfDeliveryAccount.radius_km;
        const distance_km = getDistanceBetweenTwoPoints(originCoords, destinationCoords);
        if(distance_km > aosRadius_km){
            setAosDistance(Math.ceil(distance_km - aosRadius_km));
            return true;
        } else {
            return false;
        }
    };
      
    function getDistanceBetweenTwoPoints(cord1:{lat:number, lng:number}, cord2:{lat:number, lng:number}) {
        if (cord1.lat == cord2.lat && cord1.lng == cord2.lng) {
            return 0;
        }
    
        const radlat1 = (Math.PI * cord1.lat) / 180;
        const radlat2 = (Math.PI * cord2.lat) / 180;
    
        const theta = cord1.lng - cord2.lng;
        const radtheta = (Math.PI * theta) / 180;
    
        let dist =
            Math.sin(radlat1) * Math.sin(radlat2) +
            Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    
        if (dist > 1) {
            dist = 1;
        }
    
        dist = Math.acos(dist);
        dist = (dist * 180) / Math.PI;
        dist = dist * 60 * 1.1515;
        dist = dist * 1.609344; //convert miles to km
    
        return dist;
    };

    async function createShipment() {
        setLoading(true);
        try{
            const postString = `mutation createShipment{
                ${props.editID ? `updateV1_Shipment` : `createV1_Shipment`} (input:{
                    ${
                        props.editID
                        ? ""
                        : `date_created:"${new Date().toLocaleString()}"`
                    }
                    ${
                        props.editID
                        ? ""
                        : `date_updated:"${new Date().toLocaleString()}"`
                    }
                    ${ props.editID ? `` : `platform:"SWIFTPOST"` }
                    location_note: "${receiver.location_note ?? ""}"
                    open_time: "${receiver.open_time ?? ""}"
                    close_time: "${receiver.close_time ?? ""}"
                    sender_id:"${senderId}"
                    ${props.editID ? `id:"${props.editID}"` : ``}
                    rate:{
                        amount:"0"
                        subtotal:"0"
                        rate_id:""
                        tax1_code:""
                        tax1_rate:""
                        tax1_amount:"0"
                        ea_charge:"0"
                        residential_charge:"0"
                        fuel_surcharge:"0"
                        rural_charge:"0"
                        xc_charge:"0"
                        service:{
                            name: "Self-Delivery"
                            courier_code: "SELF_DELIVERY"
                            courier: "SELF_DELIVERY"
                        }
                    }
                    receiver:{
                        title:"${receiver.business_name ?? ""}"
                        address:{
                            street_name:"${receiver.address1.substring(receiver.address1.indexOf(" ") + 1)}"
                            street_number:"${receiver.address1.split(" ").length > 1 ? receiver.address1.split(" ")[0] : " "}"
                            postal:"${receiver.postal}"
                            state:"${receiver.state}"
                            country:"CA"
                            unit:"${receiver.unit ?? ""}"
                            city:"${receiver.city}"
                        }
                        contact: {
                            fname:"${receiver.first_name}"
                            lname:"${receiver.last_name}"
                            email:"${receiver.email ?? ""}"
                            phone:"${receiver.phone ?? ""}"
                        }
                    }
                    sender:{
                        title:"${sender.title}"
                        address:{
                            street_name:"${sender.address.street_name}"
                            street_number:"${sender.address.street_number}"
                            postal:"${sender.address.postal}"
                            state:"${sender.address.state}"
                            country:"${sender.address.country}"
                            unit:"${sender.address.unit ?? ""}"
                            city:"${sender.address.city}"
                        }
                        contact: {
                            fname:"${sender.contact.fname}"
                            lname:"${sender.contact.lname}"
                            email:"${sender.contact.email ?? ""}"
                            phone:"${sender.contact.phone ?? ""}"
                        }
                    }
                    receiver_invalid:"false"
                    residential: "false"
                    package: {
                        entered_pld:[${Array(packageCount).fill(0).map((e) => {
                            return `{
                                width:"0"
                                height:"0"
                                length:"0"
                                weight:"0"
                            }`;
                        })
                        .join(",")}]
                    }
                    reference:"${new Date().getTime()}"
                }){
                    id
                    cognitoUsername
                }
            }`;
            await API.graphql(
                graphqlOperation(postString)
            );
        }
        catch(e){
            setLoading(false);
            alert("Unable to save record.")
        }
    };

    function incrementPackageCount(modifier:number) {
        if(packageCount + modifier > 0){
            setPackageCount(packageCount + modifier);
        }
    };

    async function checkForAccount(){
        const account = await querySelfDeliveryAccount();
        setFetchingAccount(false);
        setAccount(account);
        if(props.shipment) getCoordsFromAddress(receiver);
    };

    useEffect(()=>{
        checkForAccount();
    },[]);

    return (
        <>
        {addressBookPickerOpen && (
            <AddressBookPickerDialog
                country="CA"
                open={addressBookPickerOpen}
                onClose={() =>
                    setAddressBookPickerOpen(false)
                }
                onSelect={(address:any) => {
                    setReceiver(address);
                    getCoordsFromAddress(address);
                    setAddressBookPickerOpen(false);
                }}
                handleLogout={()=>{}}
            />
        )}
        {senderLocationPickerOpen && <LocationPicker
            open={senderLocationPickerOpen}
            hidden={true}
            account={props.account}
            locations={props.locations}
            handleLocationChange={(e:any) => {
                setSender(e.location);
                setSenderId(e.id);
                setSenderLocationPickerOpen(false);
            }}
            onClose={() => {
                setSenderLocationPickerOpen(false);
            }}
        />}
        {receiverLocationPickerOpen && <ReceiverModal
            open={receiverLocationPickerOpen}
            address={receiver}
            onClose={(updated_address:any) => {
                if(updated_address) {
                    setReceiver(updated_address)
                    getCoordsFromAddress(updated_address);
                }
                setReceiverLocationPickerOpen(false);
            }}
        />}
        <Grid container>
            <Grid item xs={12} md={10} lg={10} xl={10}>
                <br/>
                <div style={{
                    backgroundColor: '#f9f9f9',
                    padding: '10px 30px 10px 30px',
                    marginBottom: '10px',
                    position:'relative',
                    boxShadow: "0px 12px 24px 4px rgba(0, 0, 0, 0.08)",
                    borderRadius: '3px',
                    borderLeft: "3px solid var(--navy)"
                }}>
                    <Grid container alignItems="center">
                        <p style={{fontWeight:900, color:"#000"}}><strong>Receiver</strong></p>
                        <Tooltip title={"Open Address Book"} placement="top">
                            <IconButton
                                style={{padding:'5px',marginLeft:'10px'}}
                                onClick={() => {
                                    setAddressBookPickerOpen(true)
                                }}
                            >
                                <Contacts style={{color:"var(--deep-blue)"}} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    {receiver ? <p><small><strong>{receiver?.business_name ? <>{receiver?.business_name}<br/></> : <></>}{receiver?.first_name} {receiver?.last_name}<br/>{receiver?.address1}<br/>{receiver?.address2 ? <>{receiver?.address2}<br/></> : <></>}{receiver?.city}, {receiver?.state}<br/>{receiver?.postal}{receiver?.location_note ? <><br/><br/>{receiver?.location_note}</> : <></>}{receiver.open_time ? <><br/>{receiver.open_time} - {receiver.close_time}</> : <></>}</strong></small></p> : <></>}
                    <div style={{
                        position:'absolute',
                        top:'20px',
                        right:'15px',
                        fontSize:'.6em'
                    }}>
                        <Tooltip title={"Edit Receiver Details"} placement="top">
                            <IconButton
                                    style={{padding:'5px',marginLeft:'5px'}}     
                                    onClick={()=>{
                                        setReceiverLocationPickerOpen(true);
                                    }}
                                >
                                    <Edit style={{fontSize:'20px'}}/>
                            </IconButton>
                        </Tooltip>
                    </div>
                    {aosDistance > 0 ? <Collapse in={true}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        background: "rgba(255,0,0,.1)",
                        padding: "10px 25px 10px 10px",
                        borderRadius: "5px",
                        color: "red",
                        fontSize: ".9em",
                        width: "100%"
                      }}
                    >
                      <WarningOutlined
                        style={{ float: "left", marginRight: "10px" }}
                      />{" "}
                      <div>
                        {/* <div style={{marginBottom:3}}><strong>Destination outside of Area of Service</strong></div> */}
                        <div style={{marginBottom:5}}>The receiver you specified is approximately <strong className="nowrap">{aosDistance} KM outside</strong> of your area of service.</div>
                        You may proceed if you wish to deliver outside your area of service.
                      </div>
                    </div>
                  </Collapse> : <></>}
                </div>
                <div style={{
                    borderRadius: '3px',
                    borderLeft: "3px solid var(--navy)",
                    backgroundColor: '#f9f9f9',
                    padding: '10px 30px 10px 30px',
                    marginBottom: '10px',
                    position:'relative',
                    boxShadow: "0px 12px 24px 4px rgba(0, 0, 0, 0.08)",
                }}>
                    <p style={{fontWeight:900, color:"#000"}}><strong>Sender</strong></p>
                    {sender ? <p><small><strong>{sender?.title}<br/>{sender?.address?.street_number} {sender?.address?.street_name}<br/>{sender?.address?.city}, {sender?.address?.state}<br/>{sender?.address?.postal}</strong></small></p> : <></>}
                    <div style={{
                        position:'absolute',
                        top:'20px',
                        right:'15px',
                        fontSize:'.6em'
                    }}>
                        <Tooltip title={"Select Sender Location"} placement="top">
                            <IconButton
                                    style={{padding:'5px',marginLeft:'5px'}}     
                                    onClick={()=>{
                                        setSenderLocationPickerOpen(true);
                                    }}
                                >
                                    <Edit style={{fontSize:'20px'}}/>
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                <div style={{
                    borderRadius: '3px',
                    borderLeft: "3px solid var(--navy)",
                    backgroundColor: '#f9f9f9',
                    padding: '10px 30px 10px 30px',
                    marginBottom: '10px',
                    position:'relative',
                    boxShadow: "0px 12px 24px 4px rgba(0, 0, 0, 0.08)",
                }}>
                    <p style={{fontWeight:900, color:"#000"}}><strong>Package Count</strong></p>
                    <Grid container spacing={8} alignItems="center">
                        <Grid item>
                            <IconButton
                                style={{ padding:'5px' }}
                                disabled={packageCount <= 1}
                                onClick={() =>
                                    incrementPackageCount(-1)
                                }
                            >
                                <MinusIcon />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <p style={{margin:'0', fontSize:'1.5em', width: '25px', textAlign:'center',padding:'0'}}>{packageCount}</p>
                        </Grid>
                        <Grid item>
                        <IconButton
                            style={{ padding:'5px' }}
                            disabled={packageCount === 25}
                            onClick={() =>
                                incrementPackageCount(1)
                            }
                        >
                            <AddIcon />
                        </IconButton>
                        </Grid>
                    </Grid>
                </div>
                <div style={{
                    borderRadius: '3px',
                    borderLeft: "3px solid var(--navy)",
                    backgroundColor: '#f9f9f9',
                    padding: '10px 30px 10px 30px',
                    marginBottom: '10px',
                    position:'relative',
                    boxShadow: "0px 12px 24px 4px rgba(0, 0, 0, 0.08)",
                }}>
                    <p style={{fontWeight:900, color:"#000"}}><strong>Service</strong></p>
                    {fetchingAccount ? 
                        <LoadingIcon />
                    :
                    !account?.refresh_token ? <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      background: "rgba(255,0,0,.1)",
                      padding: "10px 25px 10px 10px",
                      borderRadius: "5px",
                      color: "red",
                      fontSize: ".9em",
                      width: "100%"
                    }}
                  >
                    <WarningOutlined
                      style={{ float: "left", marginRight: "10px" }}
                    />{" "}
                    <div>
                      <strong>No services found</strong>
                      <br />
                      You can deliver your own shipments by creating a <Link className="nowrap" to="/self-delivery">Self-Delivery account</Link>
                    </div>
                  </div> :  <p><small><strong><img
                        alt={"Courier Logo"}
                        src={`https://images.swiftpost.com/logo-SELF_DELIVERY.png`}
                        style={{ height: "14px", verticalAlign: "middle" }}
                    /> Self-Delivery</strong></small></p>}
                </div>
                <br/>
                <br/>
                <Button disabled={!account?.refresh_token || !receiver || !sender || loading} onClick={()=> createShipment()} color="primary" variant="contained" fullWidth>{props.shipment ? `Update` : `Create`} order</Button>
                <br/><br/>
            </Grid>
        </Grid>
        </>
    );
};

export default withStyles(styles)(CreateShipment);
