// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_appsync_graphqlEndpoint:
    "https://i3rxcothtjgczn367ykezcwpja.appsync-api.us-east-1.amazonaws.com/graphql",
  aws_appsync_region: "us-east-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_appsync_apiKey: "da2-v2tlbcujszggbjludntk326mdi",
};

export default awsmobile;
