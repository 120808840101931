import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

class AlertDialog extends React.Component {
  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={() => {}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Dear Valued Customer"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <>
                <p>
                  Due to the fragile contents in alcohol shipments, a new
                  sortation process has been implemented in the Canpar sorting
                  facilities to prevent damages. This requires alcohol shipments
                  to be sorted manually rather than automatically via conveyor
                  systems to prevent damage to parcels.
                </p>
                <p>
                  With this manual sort occurring, a $5.00 XC surcharge will now
                  apply to alcohol shipments effective immediately. This measure
                  will ensure that shipments continue delivering without delays
                  during this difficult time.
                </p>
                <p>
                  If you have any questions please reach out to your dedicated
                  sales resource, or you can reach us through our customer
                  service line at <a tel="1-888-655-6258">1-888-655-6258</a>.
                </p>
              </>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.onClose} color="primary" autoFocus>
              Accept
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default AlertDialog;
