import blue from "@material-ui/core/colors/blue";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import ChildPicker from "../ChildPicker";

const styles = (theme) => ({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  textField: {
    boxSizing: "border-box",
    fontSize: "1.5em",
  },
  menu: {
    width: 200,
  },
  formControl: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2,
  },
  resetContainer: {
    padding: theme.spacing.unit * 3,
  },
});

class VerticalLinearStepper extends React.Component {
  state = {};

  render() {
    // console.log(this.props.locations);
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.props.children && (
          <ChildPicker
            return={false}
            {...this.props}
            account={this.props.account}
            handleNext={(e) => this.props.handleNext(e)}
            childLocationID={this.props.childLocationID}
            childLocation={this.props.childLocation}
            showButtons={true}
            children={this.props.children}
            handleChildChange={(e) => {
              this.props.handleChildChange(e);
            }}
            handleBack={(e) => this.props.handleBack(e)}
          />
        )}
      </div>
    );
  }
}

VerticalLinearStepper.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(VerticalLinearStepper);
