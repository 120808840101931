import { API, graphqlOperation } from "aws-amplify";

export const onCreateEcommerceIntegration = (user: string) =>
  API.graphql(
    graphqlOperation(`
      subscription {
        onCreateEcommerceIntegration(cognitoUsername:"${user}"){
          id
          cognitoUsername
        }
      }
    `)
  );
