/* eslint-disable react/no-multi-comp */

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import blue from "@material-ui/core/colors/blue";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grow from "@material-ui/core/Grow";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { isNotInPast } from "../helpers/Validation";
import amexLogo from "../img/amex-logo.png";
import discoverLogo from "../img/discover-logo.png";
import mastercardLogo from "../img/mastercard-logo.png";
import visaLogo from "../img/visa-logo.png";
import PaymentCard from "./PaymentCard";
import CreatePayment from "./PaymentCreate";

const styles = (theme) => ({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2,
  },
  VISA: {
    backgroundImage: `url(${visaLogo})`,
  },
  MASTERCARD: {
    backgroundImage: `url(${mastercardLogo})`,
  },
  AMEX: {
    backgroundImage: `url(${amexLogo})`,
  },
  DISCOVER: {
    backgroundImage: `url(${discoverLogo})`,
  },
  cover: {
    display: "inline-block",
    width: 28,
    height: 15,
    backgroundSize: "auto 100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundColor: "transparent",
  },
  expired: {
    background: "rgba(255,0,0,.1)",
  },
});

class SimpleDialog extends React.Component {
  state = {};

  UNSAFE_componentWillMount = () => {
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });
  };

  language = {
    SelectAPaymentMethod: {
      FR: "Sélectionnez le mode de paiement",
      EN: "Select A Payment Method",
    },
    AddANewPaymentMethod: {
      FR: "Ajouter un nouveau mode de paiement",
      EN: "Add A New Payment Method",
    },
  };

  render() {
    // console.log(this.props);

    const { classes, onClose, selectedValue, ...other } = this.props;
    return (
      <Dialog
        onClose={() => this.props.onClose(-1)}
        aria-labelledby="simple-dialog-title"
        {...other}
      >
        <DialogTitle id="simple-dialog-title">
          {this.language.SelectAPaymentMethod[this.state.language]}
        </DialogTitle>
        <div>
          <List>
            {this.props.paymentmethods.map((payment, i) => (
              <ListItem
                className={
                  !isNotInPast(payment.card.month, payment.card.year) &&
                  classes.expired
                }
                button
                onClick={() => {
                  if (isNotInPast(payment.card.month, payment.card.year)) {
                    this.props.onClose({ id: payment.id, location: payment });
                  }
                }}
                key={i}
              >
                <ListItemAvatar>
                  <Avatar
                    className={`${classes[payment.card.type]} ${
                      classes.cover
                    } ${classes.avatar}`}
                  ></Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={payment.title}
                  secondary={
                    <Fragment>
                      {payment.card.pan} {payment.card.month}/
                      {payment.card.year}{" "}
                      {!isNotInPast(payment.card.month, payment.card.year) && (
                        <span style={{ color: "red" }}>EXPIRED</span>
                      )}
                    </Fragment>
                  }
                />
              </ListItem>
            ))}
            <ListItem
              button
              onClick={() => {
                this.props.handleModalOpen();
              }}
            >
              <ListItemAvatar>
                <Avatar>
                  <AddIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  this.language.AddANewPaymentMethod[this.state.language]
                }
              />
            </ListItem>
          </List>
        </div>
      </Dialog>
    );
  }
}

SimpleDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  selectedValue: PropTypes.string,
};

const SimpleDialogWrapped = withStyles(styles)(SimpleDialog);

class PaymentPicker extends React.Component {
  state = {
    open: false,
    selectedValue: "",
    modalOpen: false,
  };

  UNSAFE_componentWillMount = () => {
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });
  };

  language = {
    SelectPaymentMethod: {
      FR: "Sélectionnez le mode de paiement",
      EN: "Select Payment Method",
    },
    Selected: {
      FR: "Sélectionné",
      EN: "Selected",
    },
  };

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = (value) => {
    // console.log(value)
    if (value != -1) {
      this.props.handlePaymentChange(value);
    }
    this.setState({ open: false });
  };

  handleModalClose = () => {
    this.setState({ modalOpen: false });
  };

  handleModalOpen = () => {
    this.setState({ modalOpen: true });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Button
          onClick={this.handleClickOpen}
          variant={
            this.props.paymentmethods.length > 0 ? "outlined" : "contained"
          }
          color="primary"
        >
          {this.props.paymentmethods.length > 0
            ? this.language.SelectPaymentMethod[this.state.language]
            : this.language.SelectPaymentMethod[this.state.language]}{" "}
        </Button>
        <br />
        <br />
        <CreatePayment
          open={this.state.modalOpen}
          addPayment={(e) => {}}
          handleClose={this.handleModalClose}
          {...this.props}
        />
        {this.props.selectedPayment ? (
          <Typography variant="subheading">
            <strong>{this.language.Selected[this.state.language]}:</strong>
            <br />
            {this.props.paymentmethods
              .filter((e) => e.id === this.props.selectedPayment)
              .map((e, i) => (
                <Grow in={true}>
                  <PaymentCard
                    buttons={false}
                    noPaper={true}
                    data={e}
                    key={i}
                    number={i}
                    {...this.props}
                  />
                </Grow>
              ))}
          </Typography>
        ) : (
          <span></span>
        )}
        <SimpleDialogWrapped
          selectedValue={this.props.selectedPayment}
          open={this.state.open}
          onClose={(e) => this.handleClose(e)}
          handleModalOpen={() => this.handleModalOpen()}
          {...this.props}
        />
      </div>
    );
  }
}

export default PaymentPicker;
