import { API } from "aws-amplify";

var try_count = 0;

const canpar_getCutoff = (postal) =>
  new Promise(async (resolve, reject) => {
    try_count = try_count + 1;

    let body = {
      postal,
    };
    // console.log(body)
    API.post("check", "/canpar/cutoff", {
      body,
    })
      .then((e) => {
        // console.log(e)
        resolve(e);
      })
      .catch((e) => {
        // console.log(e)
        //RETRY POLICY,
        if (try_count > 3) {
          // console.log('failed after 3 attempts')
          return;
        } else {
          resolve(canpar_getCutoff(postal));
        }
      });
  });

export default canpar_getCutoff;
