import { Button, Grow, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import { API, graphqlOperation } from "aws-amplify";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import LoadingIcon from "../../components/loading/LoadingIcon";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 220,
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2,
  },
  resetContainer: {
    padding: theme.spacing.unit * 2,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});

class SimpleSelect extends React.Component {
  state = {
    isLoading: false,
  };
  UNSAFE_componentWillMount() {
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });
    this.renderRunningTotal();
    this.setState({
      finalBalance: this.roundTo(
        Number(this.props.balance.balance) - Number(this.runningTotal),
        2
      ),
    });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  roundTo = (n, digits) => {
    var negative = false;
    if (digits === undefined) {
      digits = 0;
    }
    if (n < 0) {
      negative = true;
      n = n * -1;
    }
    var multiplicator = Math.pow(10, digits);
    n = parseFloat((n * multiplicator).toFixed(11));
    n = (Math.round(n) / multiplicator).toFixed(2);
    if (negative) {
      n = (n * -1).toFixed(2);
    }
    return n;
  };

  renderRunningTotal = () => {
    let test = this.props.shipment
      .filter((e) => this.props.selectedShipments.indexOf(e.id) > -1)
      .map((e) => Number(e.rate.amount));
    let total = test.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );

    if (this.props.tab === 0 && this.props.hasUS) {
      if (this.props.printed) {
        total =
          Number(total) +
          this.props.shipment
            .filter((e) => this.props.selectedShipments.indexOf(e.id) > -1)
            .map((e) => Number(e.package.entered_pld.length) * 1.13)
            .reduce((a, b) => a + b, 0) +
          Number(this.props.bundleRate);
        this.props.setData("runningTotal", this.roundTo(total, 2));
        this.runningTotal = this.roundTo(total, 2);
        return;
      } else {
        this.props.setData(
          "runningTotal",
          this.roundTo(Number(total) + Number(this.props.bundleRate), 2)
        );
        this.runningTotal = this.roundTo(
          Number(total) + Number(this.props.bundleRate),
          2
        );
        return;
      }
    } else {
      if (this.props.printed) {
        total =
          Number(total) +
          this.props.shipment
            .filter((e) => this.props.selectedShipments.indexOf(e.id) > -1)
            .map((e) => Number(e.package.entered_pld.length) * 1.13)
            .reduce((a, b) => a + b, 0);
      }
      this.props.setData("runningTotal", this.roundTo(total, 2));
      this.runningTotal = this.roundTo(total, 2);
      return;
    }
  };

  checkQualify = async () => {
    const balance = await API.graphql(
      graphqlOperation(`query listBalances {
      listV1_Balances {
        items{
          id
          balance
          cognitoUsername
        }
      }
    }`)
    );
    if (
      Number(balance.data.listV1_Balances.items[0].balance) >=
      Number(this.runningTotal)
    ) {
      this.setState({ isLoading: false });
      this.setState({ qualify: true });
      this.qualify = true;
      return true;
    } else {
      this.setState({
        diff: this.roundTo(
          Number(this.runningTotal) -
            Number(balance.data.listV1_Balances.items[0].balance),
          2
        ),
      });
      this.setState({ isLoading: false });
      this.setState({ qualify: false });
      return false;
    }
  };

  processPayment = () =>
    new Promise(async (resolve, reject) => {
      if (this.props.runningTotal) {
        alert(
          "Your charge has been modified by an external source. Unable to process."
        );
        return;
      }
      const balance = await API.graphql(
        graphqlOperation(`query listBalances {
      listV1_Balances {
        items{
          id
          balance
          cognitoUsername
        }
      }
    }`)
      );
    });

  language = {
    PickupNotification: {
      FR: "Une demande d'enlèvement sera automatiquement créée une fois vos envois traités.",
      EN: "A pickup request will be automatically created after your shipments have been processed.",
    },
    PickupLocation: {
      FR: "Lieu de ramassage",
      EN: "Pickup Location",
    },
    DropoffLocation: {
      FR: "Point de chute",
      EN: "Dropoff Location",
    },
    TotalCost: {
      FR: "Coût total",
      EN: "Total Cost",
    },
    Balanceafterpayment: {
      FR: "Solde du compte après paiement",
      EN: "Balance after payment",
    },
    Back: {
      FR: "Arrière",
      EN: "Back",
    },
    ProcessShipments: {
      FR: "Traiter les envois",
      EN: "Process Shipments",
    },
    ConfirmandPay: {
      FR: "Confirmer et payer",
      EN: "Confirm and Print",
    },
  };

  render() {
    const { classes } = this.props;
    // console.log(this.state)
    // console.log(this.props)
    const balance = this.runningTotal;
    // console.log(balance)
    return (
      <Grow in={true}>
        <Paper
          className={classes.root}
          elevation={1}
          style={{ background: "#fff" }}
        >
          <form style={{ padding: "30px" }}>
            {(this.props.tab === 0 || this.props.tab === 2) && (
              <Paper square elevation={0} className={classes.resetContainer}>
                <Typography variant="subheading">
                  <br />
                  <strong>
                    {this.language.PickupLocation[this.state.language]}:
                  </strong>
                  <br />
                  {this.props.senderLocation.title}
                  <br />
                  {this.props.senderLocation.unit !== null &&
                  this.props.senderLocation.unit !== undefined
                    ? `${this.props.senderLocation.unit} - `
                    : ``}
                  {this.props.senderLocation.address.street_number
                    ? `${this.props.senderLocation.address.street_number} `
                    : ""}
                  {this.props.senderLocation.address.street_name
                    ? this.props.senderLocation.address.street_name
                    : ""}{" "}
                  {this.props.senderLocation.street}
                  <br />
                  {this.props.senderLocation.address.city},{" "}
                  {this.props.senderLocation.address.state}{" "}
                  {this.props.senderLocation.address.postal}
                  <br />
                  <br />
                  <small>
                    <em style={{ color: "#aaa" }}>
                      * {this.language.PickupNotification[this.state.language]}
                    </em>
                  </small>
                </Typography>
              </Paper>
            )}
            {this.props.tab === 1 && (
              <Paper square elevation={0} className={classes.resetContainer}>
                <Typography variant="subheading">
                  <br />
                  <strong>
                    {this.language.DropoffLocation[this.state.language]}:
                  </strong>
                  <br />
                  {this.props.dropoffObj.name}
                  <br />
                  {this.props.dropoffObj.address}
                  <br />
                  {this.props.dropoffObj.phone}
                </Typography>
              </Paper>
            )}
            {/* {this.props.account &&
            this.props.account.user &&
            this.props.account.user.hideBilling ? (
              ""
            ) : (
              <Fragment>
                <Paper square elevation={0} className={classes.resetContainer}>
                  <Typography variant="subheading">
                    <br />
                    <strong>
                      {this.language.TotalCost[this.state.language]}:
                    </strong>
                    <br />${this.roundTo(Number(balance), 2)} CAD
                  </Typography>
                </Paper>
                {this.props.account &&
                this.props.account.user &&
                this.props.account.user.noWallet ? (
                  ""
                ) : (
                  <Paper
                    square
                    elevation={0}
                    className={classes.resetContainer}
                  >
                    <Typography variant="subheading">
                      <strong>
                        {this.language.Balanceafterpayment[this.state.language]}
                        :
                      </strong>
                      <br />${this.roundTo(this.state.finalBalance, 2)} CAD
                    </Typography>
                  </Paper>
                )}
              </Fragment>
            )} */}
            <Paper square elevation={0} className={classes.resetContainer}>
              <Button
                disabled={this.props.isLoading}
                onClick={this.props.handleBack}
                className={classes.button}
              >
                {this.language.Back[this.state.language]}
              </Button>
              <Button
                disabled={this.props.isLoading}
                onClick={this.props.handleNext}
                className={classes.button}
                variant="contained"
                color="primary"
              >
                {this.props.account &&
                this.props.account.user &&
                this.props.account.user.isChild ? (
                  !this.props.isLoading ? (
                    this.language.ProcessShipments[this.state.language]
                  ) : (
                    <LoadingIcon size={20} />
                  )
                ) : !this.props.isLoading ? (
                  this.language.ConfirmandPay[this.state.language]
                ) : (
                  <LoadingIcon size={20} />
                )}
              </Button>
            </Paper>
          </form>
        </Paper>
      </Grow>
    );
  }
}

SimpleSelect.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleSelect);
