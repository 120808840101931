import { withStyles } from "@material-ui/core/styles";
import { API, graphqlOperation } from "aws-amplify";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import {
  isAlphaNumeric,
  isAlphaNumericWithApostrophe,
  isAlphaNumericWithSpace,
  isAlphaWithSpace,
  isDollarValue,
  isMeasurement,
  isNumericOnly,
  isValidPostal,
} from "../../helpers/Validation";
import CANStep1 from "../../pages/create_shipment/CreateShipment";

const styles = (theme) => ({
  root: {
    background: "transparent",
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  dots: {
    margin: "auto",
  },
});

class HorizontalLabelPositionBelowStepper extends React.Component {
  
  UNSAFE_componentWillMount = async () => {
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });

    if (this.props.editPkg) {
      this.setState({ oldReceiver: JSON.parse(this.props.editRec) });
      this.setState({ location_note: this.props.location_note });
      this.setState({ open_time: this.props.open_time });
      this.setState({ close_time: this.props.close_time });
      this.setState({ package: JSON.parse(this.props.editPkg) });
      this.setState({ receiver: JSON.parse(this.props.editRec) });
      this.setState({ activeStep: JSON.parse(this.props.currentStep) });
      this.setState({
        senderLocation:
          this.props.locations.filter((e) => e.id === this.props.sender_id)
            .length > 0
            ? this.props.locations.filter(
                (e) => e.id === this.props.sender_id
              )[0]
            : this.props.locations.length
            ? this.props.locations[0]
            : 0,
      });
      this.setState({
        senderLocationID:
          this.props.locations.filter((e) => e.id === this.props.sender_id)
            .length > 0
            ? this.props.sender_id
            : this.props.locations.length
            ? this.props.locations[0].id
            : 0,
      });

      if (
        !isValidPostal(JSON.parse(this.props.editRec).zip) ||
        !JSON.parse(this.props.editRec).address ||
        JSON.parse(this.props.editRec).address.trim() === ""
      ) {
        this.setState({ zipError: true });
        this.setState({ activeStep: 0 });
        this.setState({ snackOpen: true });
        return;
      }
      this.setObjData("is_residential", "receiver", "false");
      this.setState({ zipError: false });
      this.setState({ isLoading: false });
      // this.setObjData("state", "receiver", this.props.editRec.state);
      // this.setObjData("city", "receiver", e.city);
      // this.setObjData("zip", "receiver", e.postal);
      this.setState({ searchDone: true });
    }
  };

  state = {
    location_note: "",
    open_time: "",
    close_time: "",
    insured: false,
    insured_value: 0,
    rateError: false,
    phoneError: false,
    emailError: false,
    modalOpen: false,
    snackOpen: false,
    poBoxWarning: false,
    id: "",
    streetArr: [],
    activeStep: 0,
    oneStep: 0,
    twoStep: 0,
    receiver: {
      verified: false,
      business: "",
      fname: "",
      lname: "",
      attention: "",
      address: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      zip5: "",
      country: "CA",
      phone: "",
      email: "",
      street_name: "",
      street_number: "",
      is_residential: false,
    },
    package: {
      origin: "CA",
      ref: "",
      count: "1",
      description: "",
      quantity: "",
      value: "",
      insured: false,
      type: "box",
      dimensions: [
        {
          weight: "",
          height: "",
          length: "",
          width: "",
        },
      ],
    },
    contents_type: 0,
    senderLocationID: this.props.locations.length
      ? this.props.locations[0].id
      : 0,
    senderLocation: this.props.locations.length ? this.props.locations[0] : "",
    selectedService: 0,
    rates: [],
  };

  rates = [];

  handleChange = (name) => (event) => {
    this.setObjData(name, "receiver", event.value);
  };

  handleAddressbook = (addressbook_item) => {
    this.setState({
      receiver: JSON.parse(`{
      "verified": "false",
      ${
        addressbook_item.business_name != null
          ? `"business": "${addressbook_item.business_name}",`
          : '"business":"",'
      }
      "fname": "${
        addressbook_item.first_name !== " " ? addressbook_item.first_name : ""
      }",
      "lname": "${
        addressbook_item.last_name !== " " ? addressbook_item.last_name : ""
      }",
      "attention": "${
        addressbook_item.first_name !== " " ? addressbook_item.first_name : ""
      }${
        addressbook_item.last_name !== " "
          ? ` ${addressbook_item.last_name}`
          : ""
      }",
      "address": "${addressbook_item.address1}",
      "unit": "${addressbook_item.address2 ? addressbook_item.address2 : ""}",
      "address2": "${
        addressbook_item.address2 ? addressbook_item.address2 : ""
      }",
      "city": "${addressbook_item.city}",
      "state": "${addressbook_item.state}",
      "zip": "${addressbook_item.postal}",
      "zip5": "${addressbook_item.postal}",
      "country": "${addressbook_item.country}",
      ${
        addressbook_item.phone != null
          ? `"phone": "${addressbook_item.phone}",`
          : '"phone":"",'
      }
      ${
        addressbook_item.email != null
          ? `"email": "${addressbook_item.email}",`
          : '"email":"",'
      }
      "street_name": "${addressbook_item.address1.substring(
        addressbook_item.address1.indexOf(" ") + 1
      )}",
      "street_number": "${
        addressbook_item.address1.split(" ").length > 1
          ? addressbook_item.address1.split(" ")[0]
          : " "
      }",
      "is_residential": "false"
    }`),
      location_note: `${
        addressbook_item.location_note ? addressbook_item.location_note : ""
      }`,
      open_time: `${
        addressbook_item.open_time ? addressbook_item.open_time : ""
      }`,
      close_time: `${
        addressbook_item.close_time ? addressbook_item.close_time : ""
      }`,
    });
    this.handleStep1Next();
  };

  handleObjChange = (name, objName) => (event) => {
    if (
      name === "email" ||
      (event.target.value === "" && event.target.value.length < 50)
    ) {
      let temp = this.state[objName];
      temp[name] = event.target.value;
      this.setState({ [objName]: temp });
    }
    if (event.target.value.length < 30) {
      if (name === "fname" || name === "lname") {
        if (isAlphaWithSpace(event.target.value)) {
          let temp = this.state[objName];
          temp[name] = event.target.value;
          this.setState({ [objName]: temp });
        }
      } else if (name === "business") {
        if (isAlphaNumericWithApostrophe(event.target.value)) {
          let temp = this.state[objName];
          temp[name] = event.target.value;
          this.setState({ [objName]: temp });
        }
      } else if (name === "quantity") {
        if (isNumericOnly(event.target.value)) {
          let temp = this.state[objName];
          temp[name] = event.target.value;
          this.setState({ [objName]: temp });
          return;
        }
      } else if (name === "value") {
        if (isDollarValue(event.target.value)) {
          let temp = this.state[objName];
          temp[name] = event.target.value;
          this.setState({ [objName]: temp });
          return;
        }
      } else if (name === "street_name") {
        if (isAlphaNumericWithApostrophe(event.target.value)) {
          let temp = this.state[objName];
          temp[name] = event.target.value;
          this.setState({ [objName]: temp });
          return;
        }
      } else if (isAlphaNumericWithSpace(event.target.value)) {
        if (event.target.value === "") {
          let temp = this.state[objName];
          temp[name] = event.target.value;
          this.setState({ [objName]: temp });
        }
        if (name === "zip" && !isAlphaNumeric(event.target.value)) {
          return;
        }
        let temp = this.state[objName];
        temp[name] = event.target.value;
        this.setState({ [objName]: temp });
      }
    }
  };

  adjustCount = async (val) => {
    let temp = this.state.package;
    temp.count = parseInt(temp.count) + parseInt(val);
    this.setState({ package: temp });
    if (val > 0) {
      temp.dimensions.push({
        length: "",
        width: "",
        height: "",
        weight: "",
      });
    } else {
      temp.dimensions.pop();
    }
    this.setState({ package: temp });
  };

  duplicate = async (dims) => {
    let temp = Object.assign({}, this.state.package);

    // let temp = this.state.package;
    temp.count = parseInt(temp.count) + parseInt(1);
    // this.setState({ package: temp });
    temp.dimensions.push({
      length: dims["length"],
      width: dims.width,
      height: dims.height,
      weight: dims.weight,
    });
    // console.log(temp.dimensions);
    this.setState({ package: temp });
  };

  handleDimensionChange = (i, name) => (event) => {
    let override = null;
    if (name === "weight") {
      if (
        this.state.package.dimensions.reduce(
          (a, b) => Number(b.weight) + a,
          0
        ) +
          Number(event.target.value) >
        500
      ) {
        this.setState({ weightError: true });
        return;
      }
    } else if (this.props.account.user.sends_oversized) {
      let copy = this.state.package;
      const arr = [
        Math.ceil(
          name == "length" ? event.target.value : copy.dimensions[i]["length"]
        ),
        Math.ceil(
          name == "width" ? event.target.value : copy.dimensions[i]["width"]
        ),
        Math.ceil(
          name == "height" ? event.target.value : copy.dimensions[i]["height"]
        ),
      ].sort((a, b) => {
        return a > b ? 1 : -1;
      });
      // console.log(arr);
      // console.log((Number(arr[2]) + Number(2 * arr[1]) + Number(2 * arr[0])))
      if (Number(arr[2]) + Number(2 * arr[1]) + Number(2 * arr[0]) > 165) {
        return;
      }
      if (event.target.value > 150) {
        return;
      }
    } else {
      switch (name) {
        case "weight":
          if (Number(event.target.value) > 66) {
            return;
          } else {
            break;
          }
        case "length":
          if (Number(event.target.value) > 45) {
            return;
          } else {
            break;
          }
        case "width":
          if (Number(event.target.value) > 30) {
            return;
          } else {
            break;
          }
        case "height":
          if (Number(event.target.value) > 30) {
            return;
          } else {
            break;
          }
      }
    }
    if (event.target.value === "" || isMeasurement(event.target.value)) {
      let copy = this.state.package;
      console.log("in", copy);
      copy.dimensions[i][name] = override ? override : event.target.value;
      console.log("out", copy);
      this.setState({ package: copy });
    }
  };

  setDimensionChange = (name, i, val) => {
    if (isMeasurement(val)) {
      let copy = this.state.package;
      copy.dimensions[i][name] = val;
      this.setState({ package: copy });
    }
  };

  setData = (name, data) => {
    this.setState({
      [name]: data,
    });
  };

  setObjData = (name, objName, data) => {
    let temp = this.state[objName];
    temp[name] = data;
    this.setState({ [objName]: temp });
  };

  setRates = (name, value) => {
    this.setState({
      [name]: value,
    });
    this.rates = value;
  };

  toggleCheck = (name, objName) => (event) => {
    let temp = this.state[objName];
    temp[name] = event.target.checked;
    this.setState({ [objName]: temp });
  };

  createShipment = async () => {
    console.log(this.state);
    this.setState({ isLoading: true });
    console.log(`mutation createShipment{
      ${this.props.editPkg ? `updateV1_Shipment` : `createV1_Shipment`} (input:{
        ${
          this.props.editPkg
            ? ""
            : `date_created:"${new Date().toLocaleString()}"`
        }
        ${
          this.props.editPkg
            ? `date_updated:"${new Date().toLocaleString()}"`
            : ""
        }
        ${this.props.editPkg ? "" : `platform:"SWIFTPOST"`}
        location_note: "${this.state.location_note}"
        open_time: "${this.state.open_time}"
        close_time: "${this.state.close_time}"
        contents_type: ${this.state.contents_type}
        sender_id:"${this.state.senderLocationID}"
        ${this.props.editPkg ? `id:"${this.props.editID}"` : ``}
        rate:{
          ${
            this.rates[this.state.selectedService].accessorials &&
            this.rates[this.state.selectedService].accessorials.length > 0
              ? `accessorial_1_name:"${
                  this.rates[this.state.selectedService].accessorials[0].name
                }"
            accessorial_1_charge:"${
              this.rates[this.state.selectedService].accessorials[0].charge
            }"`
              : ""
          }
          ${
            this.rates[this.state.selectedService].accessorials &&
            this.rates[this.state.selectedService].accessorials.length > 1
              ? `accessorial_2_name:"${
                  this.rates[this.state.selectedService].accessorials[1].name
                }"
            accessorial_2_charge:"${
              this.rates[this.state.selectedService].accessorials[1].charge
            }"`
              : ""
          }
          ${
            this.rates[this.state.selectedService].accessorials &&
            this.rates[this.state.selectedService].accessorials.length > 2
              ? `accessorial_3_name:"${
                  this.rates[this.state.selectedService].accessorials[2].name
                }"
            accessorial_3_charge:"${
              this.rates[this.state.selectedService].accessorials[2].charge
            }"`
              : ""
          }
          ${
            this.rates[this.state.selectedService].accessorials &&
            this.rates[this.state.selectedService].accessorials.length > 3
              ? `accessorial_4_name:"${
                  this.rates[this.state.selectedService].accessorials[3].name
                }"
            accessorial_4_charge:"${
              this.rates[this.state.selectedService].accessorials[3].charge
            }"`
              : ""
          }
          amount:"${this.rates[this.state.selectedService].rate}"
          subtotal:"${this.rates[this.state.selectedService].subtotal}"
          rate_id:"${this.rates[this.state.selectedService].rate_id}"
          tax1_code:"${this.rates[this.state.selectedService].tax1_code}"
          ${
            this.rates[this.state.selectedService].tax2_code
              ? `tax2_code:"${
                  this.rates[this.state.selectedService].tax2_code
                }"`
              : ``
          }
          tax1_rate:"${this.rates[this.state.selectedService].tax1_rate}"
          ${
            this.rates[this.state.selectedService].tax2_rate
              ? `tax2_rate:"${
                  this.rates[this.state.selectedService].tax2_rate
                }"`
              : ``
          }
          tax1_amount:"${this.rates[this.state.selectedService].tax1_amount}"
          ${
            this.rates[this.state.selectedService].tax2_amount
              ? `tax2_amount:"${
                  this.rates[this.state.selectedService].tax2_amount
                }"`
              : ``
          }
          ea_charge:"${this.rates[this.state.selectedService].ea_charge}"
          residential_charge:"${
            this.rates[this.state.selectedService].residential_charge
          }"
          fuel_surcharge:"${
            this.rates[this.state.selectedService].fuel_surcharge
          }"
          rural_charge:"${this.rates[this.state.selectedService].rural_charge}"
          xc_charge:"${this.rates[this.state.selectedService].xc_charge}"
          service:{
            name: "${
              this.rates[this.state.selectedService].service.service.name
            }"
            courier_code: "${
              this.rates[this.state.selectedService].service.service.rate_type
            }"
            courier: "${this.rates[this.state.selectedService].courier}"
          }
        }
        receiver:{
          ${
            this.state.receiver.business
              ? `title:"${this.state.receiver.business}"`
              : ``
          }
          address:{
            street_name:"${
              this.state.receiver.street_name
                ? this.state.receiver.street_name
                : this.state.oldReceiver && this.state.oldReceiver.address
                ? this.state.oldReceiver.address.indexOf(" ") > -1
                  ? this.state.oldReceiver.address.split(" ")[1]
                  : this.state.oldReceiver.address
                : " "
            }"
            street_number:"${
              this.state.receiver.street_number
                ? this.state.receiver.street_number
                : this.state.oldReceiver && this.state.oldReceiver.address
                ? this.state.oldReceiver.address.indexOf(" ") > -1
                  ? this.state.oldReceiver.address.split(" ")[0]
                  : " "
                : " "
            }"
            postal:"${this.state.receiver.zip}"
            state:"${this.state.receiver.state}"
            country:"CA"
            ${
              this.state.receiver.unit &&
              this.state.receiver.unit !== null &&
              this.state.receiver.unit !== "null" &&
              this.state.receiver.unit !== undefined &&
              this.state.receiver.unit !== "undefined"
                ? `unit:"${this.state.receiver.unit}"`
                : ``
            }
            city:"${this.state.receiver.city}"
          }
          contact: {
            fname:"${this.state.receiver.fname}"
            lname:"${this.state.receiver.lname}"
            ${
              this.state.receiver.email
                ? `email:"${this.state.receiver.email}"`
                : ``
            }
            ${
              this.state.receiver.phone
                ? `phone:"${this.state.receiver.phone}"`
                : ``
            }
          }
        }
        ${
          this.state.package.description
            ? `contents:{
          title: "${this.state.package.description}"
          item_quantity: "${this.state.package.quantity}"
          item_value: "${this.state.package.value}"
          origin: "${this.state.package.origin}"
        }`
            : ``
        }
        sender:{
          title:"${this.state.senderLocation.title}"
          address:{
            street_name:"${this.state.senderLocation.address.street_name}"
            street_number:"${this.state.senderLocation.address.street_number}"
            postal:"${this.state.senderLocation.address.postal}"
            state:"${this.state.senderLocation.address.state}"
            country:"${this.state.senderLocation.address.country}"
            ${
              this.state.senderLocation.address.unit &&
              this.state.senderLocation.address.unit !== null &&
              this.state.senderLocation.address.unit !== "null" &&
              this.state.senderLocation.address.unit !== undefined &&
              this.state.senderLocation.address.unit !== "undefined"
                ? `unit:"${this.state.senderLocation.address.unit}"`
                : ``
            }
            city:"${this.state.senderLocation.address.city}"
          }
          contact: {
            fname:"${this.state.senderLocation.contact.fname}"
            lname:"${this.state.senderLocation.contact.lname}"
            ${
              this.state.senderLocation.address.email ||
              this.state.senderLocation.address.email === null ||
              this.state.senderLocation.address.email === "null"
                ? `email:"${this.state.senderLocation.contact.email}"`
                : ``
            }
            ${
              this.state.senderLocation.contact.phone
                ? `phone:"${this.state.senderLocation.contact.phone}"`
                : ``
            }
          }
        }
        receiver_invalid:"false"
        residential: "${this.state.receiver.is_residential}"
        package: {
          entered_pld:[${this.state.package.dimensions
            .map((e) => {
              return `{
            width:"${e.width}"
            height:"${e.height}"
            length:"${e.length}"
            weight:"${e.weight}"
          }`;
            })
            .join(",")}]
        }
        ${
          this.state.package.ref
            ? `reference:"${this.state.package.ref}"`
            : `reference:"${new Date().getTime()}"`
        }
      }){
        id
        cognitoUsername
      }
    }`);

    await API.graphql(
      graphqlOperation(`mutation createShipment{
        ${
          this.props.editPkg ? `updateV1_Shipment` : `createV1_Shipment`
        } (input:{
          ${
            this.props.editPkg
              ? ""
              : `date_created:"${new Date().toLocaleString()}"`
          }
          ${
            this.props.editPkg
              ? `date_updated:"${new Date().toLocaleString()}"`
              : ""
          }
          ${this.props.editPkg ? "" : `platform:"SWIFTPOST"`}
          open_time: "${this.state.open_time}"
          close_time: "${this.state.close_time}"
          location_note: "${this.state.location_note}"
          contents_type: ${this.state.contents_type}
          sender_id:"${this.state.senderLocationID}"
          ${this.props.editPkg ? `id:"${this.props.editID}"` : ``}
          rate:{
            ${
              this.rates[this.state.selectedService].accessorials &&
              this.rates[this.state.selectedService].accessorials.length > 0
                ? `accessorial_1_name:"${
                    this.rates[this.state.selectedService].accessorials[0].name
                  }"
              accessorial_1_charge:"${
                this.rates[this.state.selectedService].accessorials[0].charge
              }"`
                : ""
            }
            ${
              this.rates[this.state.selectedService].accessorials &&
              this.rates[this.state.selectedService].accessorials.length > 1
                ? `accessorial_2_name:"${
                    this.rates[this.state.selectedService].accessorials[1].name
                  }"
              accessorial_2_charge:"${
                this.rates[this.state.selectedService].accessorials[1].charge
              }"`
                : ""
            }
            ${
              this.rates[this.state.selectedService].accessorials &&
              this.rates[this.state.selectedService].accessorials.length > 2
                ? `accessorial_3_name:"${
                    this.rates[this.state.selectedService].accessorials[2].name
                  }"
              accessorial_3_charge:"${
                this.rates[this.state.selectedService].accessorials[2].charge
              }"`
                : ""
            }
            ${
              this.rates[this.state.selectedService].accessorials &&
              this.rates[this.state.selectedService].accessorials.length > 3
                ? `accessorial_4_name:"${
                    this.rates[this.state.selectedService].accessorials[3].name
                  }"
              accessorial_4_charge:"${
                this.rates[this.state.selectedService].accessorials[3].charge
              }"`
                : ""
            }
            amount:"${this.rates[this.state.selectedService].rate}"
            subtotal:"${this.rates[this.state.selectedService].subtotal}"
            rate_id:"${this.rates[this.state.selectedService].rate_id}"
            tax1_code:"${this.rates[this.state.selectedService].tax1_code}"
            ${
              this.rates[this.state.selectedService].tax2_code
                ? `tax2_code:"${
                    this.rates[this.state.selectedService].tax2_code
                  }"`
                : ``
            }
            tax1_rate:"${this.rates[this.state.selectedService].tax1_rate}"
            ${
              this.rates[this.state.selectedService].tax2_rate
                ? `tax2_rate:"${
                    this.rates[this.state.selectedService].tax2_rate
                  }"`
                : ``
            }
            tax1_amount:"${this.rates[this.state.selectedService].tax1_amount}"
            ${
              this.rates[this.state.selectedService].tax2_amount
                ? `tax2_amount:"${
                    this.rates[this.state.selectedService].tax2_amount
                  }"`
                : ``
            }
            ea_charge:"${this.rates[this.state.selectedService].ea_charge}"
            residential_charge:"${
              this.rates[this.state.selectedService].residential_charge
            }"
            fuel_surcharge:"${
              this.rates[this.state.selectedService].fuel_surcharge
            }"
            rural_charge:"${
              this.rates[this.state.selectedService].rural_charge
            }"
            xc_charge:"${this.rates[this.state.selectedService].xc_charge}"
            service:{
              name: "${
                this.rates[this.state.selectedService].service.service.name
              }"
              courier_code: "${
                this.rates[this.state.selectedService].service.service.rate_type
              }"
              courier: "${this.rates[this.state.selectedService].courier}"
            }
          }
          receiver:{
            ${
              this.state.receiver.business
                ? `title:"${this.state.receiver.business}"`
                : ``
            }
            address:{
              street_name:"${
                this.state.receiver.street_name
                  ? this.state.receiver.street_name
                  : this.state.oldReceiver && this.state.oldReceiver.address
                  ? this.state.oldReceiver.address.indexOf(" ") > -1
                    ? this.state.oldReceiver.address.split(" ")[1]
                    : this.state.oldReceiver.address
                  : " "
              }"
              street_number:"${
                this.state.receiver.street_number
                  ? this.state.receiver.street_number
                  : this.state.oldReceiver && this.state.oldReceiver.address
                  ? this.state.oldReceiver.address.indexOf(" ") > -1
                    ? this.state.oldReceiver.address.split(" ")[0]
                    : " "
                  : " "
              }"
              postal:"${this.state.receiver.zip}"
              state:"${this.state.receiver.state}"
              country:"CA"
              ${
                this.state.receiver.unit &&
                this.state.receiver.unit !== null &&
                this.state.receiver.unit !== "null" &&
                this.state.receiver.unit !== undefined &&
                this.state.receiver.unit !== "undefined"
                  ? `unit:"${this.state.receiver.unit}"`
                  : ``
              }
              city:"${this.state.receiver.city}"
            }
            contact: {
              fname:"${this.state.receiver.fname}"
              lname:"${this.state.receiver.lname}"
              ${
                this.state.receiver.email
                  ? `email:"${this.state.receiver.email}"`
                  : ``
              }
              ${
                this.state.receiver.phone
                  ? `phone:"${this.state.receiver.phone}"`
                  : ``
              }
            }
          }
          ${
            this.state.package.description
              ? `contents:{
            title: "${this.state.package.description}"
            item_quantity: "${this.state.package.quantity}"
            item_value: "${this.state.package.value}"
            origin: "${this.state.package.origin}"
          }`
              : ``
          }
          sender:{
            title:"${this.state.senderLocation.title}"
            address:{
              street_name:"${this.state.senderLocation.address.street_name}"
              street_number:"${this.state.senderLocation.address.street_number}"
              postal:"${this.state.senderLocation.address.postal}"
              state:"${this.state.senderLocation.address.state}"
              country:"${this.state.senderLocation.address.country}"
              ${
                this.state.senderLocation.address.unit &&
                this.state.senderLocation.address.unit !== null &&
                this.state.senderLocation.address.unit !== "null" &&
                this.state.senderLocation.address.unit !== undefined &&
                this.state.senderLocation.address.unit !== "undefined"
                  ? `unit:"${this.state.senderLocation.address.unit}"`
                  : ``
              }
              city:"${this.state.senderLocation.address.city}"
            }
            contact: {
              fname:"${this.state.senderLocation.contact.fname}"
              lname:"${this.state.senderLocation.contact.lname}"
              ${
                this.state.senderLocation.address.email ||
                this.state.senderLocation.address.email === null ||
                this.state.senderLocation.address.email === "null"
                  ? `email:"${this.state.senderLocation.contact.email}"`
                  : ``
              }
              ${
                this.state.senderLocation.contact.phone
                  ? `phone:"${this.state.senderLocation.contact.phone}"`
                  : ``
              }
            }
          }
          receiver_invalid:"false"
          residential: "false"
          package: {
            entered_pld:[${this.state.package.dimensions
              .map((e) => {
                return `{
              width:"${e.width}"
              height:"${e.height}"
              length:"${e.length}"
              weight:"${e.weight}"
            }`;
              })
              .join(",")}]
          }
          ${
            this.state.package.ref
              ? `reference:"${this.state.package.ref}"`
              : `reference:"${new Date().getTime()}"`
          }
        }){
          id
          cognitoUsername
        }
      }`)
    ).catch((e) => {
      // console.log(e)
      this.setState({ isLoading: false });
    });
  };

  handleStep1Next = () => {
    if (this.state.oneStep === 1) {
      if (
        this.state.receiver.street_name === "" ||
        this.state.receiver.street_number === ""
      ) {
        this.setState({ addressError: true });
        return;
      }
      this.setObjData(
        "address",
        "receiver",
        `${this.state.receiver.street_number} ${this.state.receiver.street_name}`
      );

      this.setState({ addressError: false });
      this.setState({ zipError: false });

      this.setState((state) => ({
        oneStep: state.oneStep + 1,
      }));
    } else if (this.state.oneStep === 2) {
      if (!isAlphaNumericWithSpace(this.state.receiver.address)) {
        this.setState({ addressError: true });
        return;
      }
      this.setState((state) => ({
        oneStep: state.oneStep + 1,
      }));
    } else if (this.state.oneStep === 3) {
      this.handleNext("activeStep");
    } else {
      this.setState((state) => ({
        oneStep: state.oneStep + 1,
      }));
    }
  };

  handleLocationChange = (e) => {
    // console.log(e)
    this.setState({ senderLocation: e.location });
    this.setState({ senderLocationID: e.id });
  };

  render() {
    return (
      <Fragment>
        <div style={{ maxWidth: "900px", width: "100%" }}>
          <CANStep1
            {...this.state}
            handleAddressbook={(item) => this.handleAddressbook(item)}
            previousData={this.state.oldReceiver}
            streetArr={this.state.streetArr}
            handleNext={() => this.handleNext("oneStep")}
            activeStep={this.state.oneStep}
            advance={() => this.handleNext("activeStep")}
            handleChange={(name) => this.handleChange(name)}
            setData={(name, data) => this.setData(name, data)}
            setObjData={(name, objName, data) =>
              this.setObjData(name, objName, data)
            }
            handleObjChange={(name, objName) =>
              this.handleObjChange(name, objName)
            }
            handleLogout={this.props.handleLogout}
          />
        </div>
      </Fragment>
    );
  }
}

HorizontalLabelPositionBelowStepper.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(HorizontalLabelPositionBelowStepper);
