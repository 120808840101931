import { Grid, Paper, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import Stepper from "../components/return_shipment/Stepper";
import SnackBar from "../components/Snackbar";
// import LoadingIcon from '../components/loading/LoadingIcon';
import {
  isAlphaNumeric,
  isAlphaNumericWithSpace,
  isNumericOnly,
  isValidPhoneNumber,
  isValidPostal,
} from "../helpers/Validation";

const styles = (theme) => ({
  textField: {
    width: "100%",
    boxSizing: "border-box",
    fontSize: "1.5em",
  },
  menu: {
    width: 200,
  },
  formControl: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2,
  },
  resetContainer: {
    padding: theme.spacing.unit * 3,
  },
});

class CreateShipmentReturn extends React.Component {
  state = {
    isLoading: false,
    openSnack: false,
    location: {
      company: "",
    },
    contact: {
      fname: "",
      lname: "",
      phone: "",
      email: "",
    },
    address: {
      street_name: "",
      street_number: "",
      street_direction: "",
      postal: "",
      city: "",
      state: "",
    },
    errors: {
      business: false,
      fname: false,
      lname: false,
      phone: false,
      email: false,
      address: false,
      city: false,
      postal: false,
    },
  };

  backup = {};

  getCoords = (postal) => {
    return new Promise((res, rej) => {
      var request = new XMLHttpRequest();
      request.onreadystatechange = function () {
        if (request.readyState == 4 && request.status == 200) {
          if (JSON.parse(request.responseText).results.length > 0) {
            let coords = JSON.parse(request.responseText).results[0].geometry
              .viewport.northeast;
            // console.log(coords)
            // console.log({lng: JSON.parse(request.responseText).results[0].geometry.viewport.northeast.lat, lat: request.responseText.results[0].geometry.viewport.northeast.lat})
            res({ lng: coords.lng, lat: coords.lat });
          } else {
            res(false);
            // rej(true)
          }
        }
      };
      request.open(
        "GET",
        `https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyBNMRsO80tsOGxVUIJbrDKw0DuUSvFrIhc&address=${
          this.state.address.street_number
        }+${this.state.address.street_name},+${this.state.address.city},+${
          this.state.address.state
        },+${this.state.address.postal.split(" ").join("")}`
      );
      request.send();
    });
  };

  UNSAFE_componentWillMount = async () => {
    // if(this.props.account){
    //   this.setState({contact: this.props.account.location.contact});
    //   this.setState({address: this.props.account.location.address});
    //   this.setState({location: this.props.account.location});
    //   this.backup = this.props.account;
    // } else{
    //   let contact = {
    //     fname: "",
    //     lname: "",
    //     phone: "",
    //     email: Auth.user.attributes.email,
    //   }
    //   this.setState({contact})
    // }
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleObjChange = (name, objName) => (event) => {
    let temp = this.state[objName];
    temp[name] = event.target.value;
    this.setState({ [objName]: temp });
  };

  setObjectValue = (name, objName, value) => {
    // console.log(name, objName, value)
    let temp = this.state[objName];
    temp[name] = value;
    // console.log(temp)
    this.setState({ [objName]: temp });
  };

  handleIsAlphaNumeric = (name, obj) => (event) =>
    isAlphaNumeric(event.target.value) || event.target.value === ""
      ? this.setObjectValue(name, obj, event.target.value)
      : "";
  handleIsAlphaNumericWithSpace = (name, obj) => (event) =>
    isAlphaNumericWithSpace(event.target.value) || event.target.value === ""
      ? this.setObjectValue(name, obj, event.target.value)
      : "";
  handleIsValidPhoneNumber = (name, obj) => (event) =>
    isValidPhoneNumber(event.target.value) || event.target.value === ""
      ? this.setObjectValue(name, obj, event.target.value)
      : "";
  handleIsValidPostal = (name, obj) => (event) =>
    isValidPostal(event.target.value) || event.target.value === ""
      ? this.setObjectValue(name, obj, event.target.value)
      : "";
  handleIsNumericOnly = (name, obj) => (event) =>
    isNumericOnly(event.target.value) || event.target.value === ""
      ? this.setObjectValue(name, obj, event.target.value)
      : "";

  reset = () => {
    // alert("RESETTING")
    // console.log(this.backup)
    this.setState({ contact: this.backup.location.contact });
    this.setState({ address: this.backup.location.address });
    this.setState({ location: this.backup.location });
    // console.log(this.state.location)
    this.clearErrors();
  };

  clearErrors = () => {
    var temp = {
      businessError: false,
      fnameError: false,
      lnameError: false,
      phoneError: false,
      emailError: false,
      addressError: false,
      cityError: false,
      postalError: false,
    };
    this.setState({ errors: temp });
  };

  // validateForm = async () => {
  //   this.setState({isLoading: true});
  //   await this.clearErrors();
  //   var temp = this.state.errors;
  //   var hasErrors = false;
  //   if(this.state.location.title === ""){ temp.business=true; hasErrors=true; }
  //   if(this.state.contact.fname === ""){ temp.fname=true; hasErrors=true; }
  //   if(this.state.contact.lname === ""){ temp.lname=true; hasErrors=true; }
  //   if(this.state.contact.phone === "" || !isValidPhoneNumber(this.state.contact.phone) ){ temp.phone=true; hasErrors=true; }
  //   if(this.state.address.street_name === ""){ temp.address=true; hasErrors=true; }
  //   if(this.state.address.street_number === ""){ temp.address=true; hasErrors=true; }
  //   if(this.state.address.city === ""){ temp.city=true; hasErrors=true; }
  //   if(this.state.address.postal === "" || !isValidPostal(this.state.address.postal)){ temp.zip=true; hasErrors=true; }
  //   await this.setState({errors: temp});
  //   if(
  //     this.state.location.title != "" &&
  //     this.state.contact.fname != "" &&
  //     this.state.contact.lname != "" &&
  //     (this.state.contact.phone != "" && isValidPhoneNumber(this.state.contact.phone)) &&
  //     this.state.address.street_name != "" &&
  //     this.state.address.street_number != "" &&
  //     this.state.address.city != "" &&
  //     (this.state.address.postal != "" && isValidPostal(this.state.address.postal))
  //   ) {
  //   if(!hasErrors){
  //     console.log(hasErrors)
  //     let coords = { lat: 0, lng: 0 };
  //     coords = await this.getCoords(this.state.address.postal);
  //     if(coords === false){
  //       this.setState({isLoading: false});
  //       let errors = {zip:true, address:true, city: true, state: true}
  //       this.setState({ errors});
  //       return;
  //     }
  //     this.setState({coords});

  //     if(this.props.account){
  //       let account = await API.graphql(graphqlOperation(`mutation {
  //         updateV1_Account(input:{
  //           id:"${this.props.account.id}"
  //           location:{
  //             title:"${this.state.location.title}"
  //             address:{
  //               street_name:"${this.state.address.street_name}"
  //               street_number:"${this.state.address.street_number}"
  //               postal:"${this.state.address.postal}"
  //               state:"${this.state.address.state}"
  //               country:"CA"
  //               ${this.state.address.unit ? `unit:"${this.state.address.unit}"` : ``}
  //               city:"${this.state.address.city}"
  //               coords_lat:"${this.state.coords.lat}"
  //               coords_long:"${this.state.coords.lng}"
  //             }
  //             contact: {
  //               fname:"${this.state.contact.fname}"
  //               lname:"${this.state.contact.lname}"
  //               email:"${this.state.contact.email}"
  //               phone:"${this.state.contact.phone}"
  //             }
  //           }
  //         }){
  //           id
  //           cognitoUsername
  //         }
  //       }`));
  //     } else{
  //       let account = await API.graphql(graphqlOperation(`mutation {
  //         createV1_Account(input:{
  //           location:{
  //             title:"${this.state.location.title}"
  //             address:{
  //               street_name:"${this.state.address.street_name}"
  //               street_number:"${this.state.address.street_number}"
  //               postal:"${this.state.address.postal}"
  //               state:"${this.state.address.state}"
  //               country:"CA"
  //               ${this.state.address.unit ? `unit:"${this.state.address.unit}"` : ``}
  //               city:"${this.state.address.city}"
  //               coords_lat:"${this.state.coords.lat}"
  //               coords_long:"${this.state.coords.lng}"
  //             }
  //             contact: {
  //               fname:"${this.state.contact.fname}"
  //               lname:"${this.state.contact.lname}"
  //               email:"${this.state.contact.email}"
  //               phone:"${this.state.contact.phone}"
  //             }
  //           }
  //         }){
  //           id
  //           cognitoUsername
  //         }
  //       }`));
  //     }

  //     this.setState({openSnack: true});
  //   }
  //   }
  //   this.setState({isLoading: false});
  // }

  render() {
    const { classes } = this.props;

    return (
      <div className="Home">
        <SnackBar
          style={{ zIndex: 999999, position: "fixed" }}
          variant={"success"}
          open={this.state.openSnack}
          message={"Successfully updated account."}
          handleClose={() => {
            this.setState({ openSnack: false });
          }}
        />
        <div className="notes">
          <Typography variant="h1">Return Shipment</Typography>
          <Paper square className={classes.root} elevation={1}>
            <Grid container xs={12} lg={12}>
              <Stepper
                account={this.props.account}
                goToOrders={() => {}}
                locations={this.props.locations}
                getRates={this.props.getRate}
              />
            </Grid>
            {/* <Grid container xs={12} lg={6}>
              <ShipmentStepperMobile />
            </Grid> */}
          </Paper>
        </div>
      </div>
    );
  }
}

CreateShipmentReturn.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CreateShipmentReturn);
