import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Fragment } from "react";

const styles = (theme) => ({
  root: {
    width: "100%",
    maxWidth: "100%",
    height: 300,
    overflow: "scroll",
    overflowX: "hidden",
    backgroundColor: "transparent",
  },
  active: {
    borderLeft: "3px solid #4286f4",
    color: "#fff",
    background: "#e5eeff",
  },
});

class MapPanel extends React.Component {
  render() {
    const dropoffLocations = this.props.data;
    const { classes } = this.props;
    return (
      <div id="dropoffLocations" className={classes.root}>
        <List component="nav">
          {dropoffLocations
            .slice(0)
            .reverse()
            .map((e, i) => {
              return (
                <ListItem
                  button
                  onClick={() => {
                    if (!this.props.disabled) {
                      this.props.setSelectedDropoff(e.id);
                      this.props.moveIt({ lat: e.lat, lng: e.lng }, e.id);
                    }
                  }}
                  className={this.props.active === e.id && classes.active}
                >
                  <ListItemText
                    primary={
                      <span>
                        {e.name}
                        <br />
                        <small>
                          <strong>{e.description}</strong>
                        </small>
                      </span>
                    }
                    secondary={`${e.address}, ${e.city}, ${e.province} ${e.postal}`}
                  />
                </ListItem>
              );
            })}
          {this.props.data.length < this.props.maxLength && (
            <Fragment>
              <ListItem
                button
                onClick={() => {
                  this.props.zoomOut();
                }}
                style={{
                  "border-left": "2px solid #EDB92E",
                  background: "rgba(237, 185, 46, .1)",
                }}
              >
                <ListItemText
                  primary={`More locations are available`}
                  secondary={`Click here to zoom out`}
                />
              </ListItem>
            </Fragment>
          )}
          {/* {this.props.data.length === 0 && `There are no dropoff locations in this area.`} */}
        </List>
      </div>
    );
  }
}

MapPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MapPanel);
