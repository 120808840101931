import { Button, Hidden, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import getActiveDropoff from "../api/app/getActiveDropoff";
import MapPanel from "../components/MapPanel";
import "./dev.css";

var iconBase = "https://s3.amazonaws.com/swiftpost-img/";
var icons = {
  parking: {
    icon: iconBase + "icon-ida.png",
  },
  library: {
    icon: iconBase + "icon-ida.png",
  },
  info: {
    icon: iconBase + "icon-guardian.png",
  },
  ida: {
    icon: iconBase + "icon-ida.png",
  },
  guardian: {
    icon: iconBase + "icon-guardian.png",
  },
  mckesson: {
    icon: iconBase + "icon-mckesson.png",
  },
  swiftpost: {
    icon: iconBase + "icon-swiftpost.png",
  },
  enhanced: {
    icon: iconBase + "icon-swiftpost.png",
  },
  remedy: {
    icon: iconBase + "icon-swiftpost.png",
  },
};

var features = [];

var map;
var markers = [];
var infowindow = "";

const styles = (theme) => ({
  root: {
    width: "90%",
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2,
  },
  resetContainer: {
    padding: theme.spacing.unit * 3,
  },
  progress: {
    margin: theme.spacing.unit * 2,
    marginLeft: "20px",
  },
});

class VerticalLinearStepper extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      zoom: 9,
      maptype: "roadmap",
      place_formatted: "",
      place_id: "",
      place_location: "",
      selected_marker: "",
      filteredFeatures: features,
      connector: [],
      center: {
        lat: 0,
        lng: 0,
      },
    };
  }

  language = {
    DropoffLocator: {
      FR: "Localisateur de lieu de dépôt",
      EN: "Dropoff Locator",
    },
    Enteralocation: {
      FR: "Entrez un emplacement",
      EN: "Enter a location",
    },
    Back: {
      FR: "Arrière",
      EN: "Back",
    },
    ConfirmandContinue: {
      FR: "Confirmer et continuer",
      EN: "Confirm and Continue",
    },
  };

  UNSAFE_componentWillMount = async () => {
    if (this.props.selectedShipments) {
      if (
        this.props.shipment
          .filter((e) => this.props.selectedShipments.indexOf(e.id) > -1)
          .filter((e) => e.receiver.address.country !== "CA").length > 0
      ) {
        this.props.setData("hasUS", true);
      }
    } else {
      // alert("NO SHIPMENTS SELECTED")
      this.props.history.push("/");
      return;
    }
    const locations = await getActiveDropoff();
    if (
      this.props.shipment
        .filter((e) => this.props.selectedShipments.indexOf(e.id) > -1)
        .filter((e) => e.receiver.address.country == "US").length > 0
    ) {
      features = locations.filter((e) => e.province == "ON");
    } else {
      features = locations;
    }
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });
    return;
  };

  async componentDidMount() {
    map = new window.google.maps.Map(document.getElementById("map"), {
      mapTypeControl: false,
      center:
        this.props.account &&
        this.props.account.location &&
        this.props.account.location.address
          ? {
              lat: Number(this.props.account.location.address.coords_lat),
              lng: Number(this.props.account.location.address.coords_long),
            }
          : this.props.user.length
          ? this.props.user[0].coords
          : {
              lat: 43.505479,
              lng: -79.6786107,
            },
      zoom: 12,
      mapTypeId: "roadmap",
      styles: [
        {
          featureType: "administrative.land_parcel",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels.text",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.business",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.medical",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.park",
          stylers: [
            {
              visibility: "simplified",
            },
          ],
        },
        {
          featureType: "poi.place_of_worship",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.sports_complex",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road.local",
          elementType: "labels",
          stylers: [
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "road.local",
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "transit",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
      ],
    });
    setTimeout(async () => {
      // console.log("CENTER",this.props.user.account[0].coords)

      map.addListener("zoom_changed", () => {
        // console.log('zoom changed')
        this.setState({
          zoom: map.getZoom(),
        });
        this.checkMarkers();
      });

      map.addListener("maptypeid_changed", () => {
        this.setState({
          maptype: map.getMapTypeId(),
        });
      });

      map.addListener("center_changed", () => {
        // console.log('center changed')
        this.checkMarkers();
      });

      // let marker = new window.google.maps.Marker({

      // initialize the autocomplete functionality using the #pac-input input box
      let inputNode = document.getElementById("pac-input");
      // map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(inputNode);
      let autoComplete = new window.google.maps.places.Autocomplete(inputNode);

      autoComplete.addListener("place_changed", () => {
        let place = autoComplete.getPlace();
        if ("place_id" in place) {
          let location = place.geometry.location;

          this.setState({
            place_formatted: place.formatted_address,
            place_id: place.place_id,
            place_location: location.toString(),
          });

          new window.google.maps.LatLng(location);

          // bring the selected place in view on the map
          map.fitBounds(place.geometry.viewport);
          map.setCenter(location);

          // marker.setPlace({
          //   placeId: place.place_id,
          //   location: location,
          // });

          map.setZoom(13);
          this.setState({ zoom: 13 });

          this.checkMarkers();
        }
      });

      let connector = [];

      // console.log("FEATURES >>> ", features)
      features = await getActiveDropoff();
      if (
        this.props.selectedShipments.filter(
          (e) => e.receiver.address.country == "US"
        ).length > 0
      ) {
        features = features.response.filter((e) => e.province == "ON");
      } else {
        features = features.response;
      }
      markers = features.map(
        (feature, i) =>
          new window.google.maps.Marker({
            position: new window.google.maps.LatLng(feature.lat, feature.lng),
            icon: feature.type
              ? icons[feature.type].icon
              : icons["swiftpost"].icon,
            map: map,
          })
      );

      // console.log("LIST",markers)

      this.setState({ connector: connector });

      // console.log('done')

      // console.log("MARKERS >>>>", markers)

      let wait = markers.map((e, i) =>
        window.google.maps.event.addListener(e, "click", () => {
          this.props.setSelectedDropoff(features[i].id);
          this.moveToLocation(
            { lat: features[i].lat, lng: features[i].lng },
            features[i].id
          );
        })
      );

      // console.log(wait)

      Promise.all(wait).then((e) => {
        this.setState({ isLoading: false });
        this.checkMarkers();
      });

      this.checkMarkers();

      // this.moveToLocation(this.props.user.account[0].coords, '')
    }, 2000);
  }

  zoomOut = () => {
    map.setZoom(this.state.zoom - 1);
    this.setState({ zoom: this.state.zoom - 1 });
  };

  moveToLocation = (position, id) => {
    // var center = new window.google.maps.LatLng(lat, lng);
    // using global variable:c
    // console.log("IM TRYING")
    // console.log(position, id)
    if (infowindow != "") {
      infowindow.close();
    }
    map.panTo(new window.google.maps.LatLng(position.lat, position.lng));
    map.setZoom(13);
    this.setState({ zoom: 13 });
    if (id != "") {
      this.setState({ selected_marker: id });
      // console.log('DOING MORE')
      this.props.setData("dropoff", id);
      this.props.setObjData(
        "name",
        "dropoffObj",
        features.filter((e) => e.id === id)[0].name
      );
      this.props.setObjData(
        "address",
        "dropoffObj",
        `${features.filter((e) => e.id === id)[0].address}, ${
          features.filter((e) => e.id === id)[0].city
        }, ${features.filter((e) => e.id === id)[0].province} ${
          features.filter((e) => e.id === id)[0].postal
        }`
      );
      this.props.setObjData(
        "phone",
        "dropoffObj",
        features.filter((e) => e.id === id)[0].phone
      );
      // console.log('ANIMATING')
      markers[features.map((e) => e.id === id).indexOf(true)].setAnimation(
        window.google.maps.Animation.BOUNCE
      );
      setTimeout(() => {
        markers[features.map((e) => e.id === id).indexOf(true)].setAnimation(
          null
        );
      }, 600);
      // console.log('TRIGGER WINDOW')
      infowindow = new window.google.maps.InfoWindow({
        content: `<strong>${
          features.filter((e) => e.id === id)[0].name
        }</strong><br/>${features.filter((e) => e.id === id)[0].address}<br/>${
          features.filter((e) => e.id === id)[0].city
        }, ${features.filter((e) => e.id === id)[0].province} ${
          features.filter((e) => e.id === id)[0].postal
        }<br/>${
          features.filter((e) => e.id === id)[0].description
            ? `<strong>${
                features.filter((e) => e.id === id)[0].description
              }</strong></br>`
            : ""
        }`, //${features[id].hours}`
      });

      infowindow.open(
        map,
        markers[features.map((e) => e.id === id).indexOf(true)]
      );
    }
  };

  moveToCenter = () => {
    map.panTo(new window.google.maps.LatLng(43.833458, -79.324175));
    map.setZoom(9);
    this.setState({ zoom: 9 });
  };

  checkMarkers = () => {
    // console.log('checking')
    var bounds = map.getBounds();
    var newTemp = features.filter((e, i) => {
      return bounds.contains(markers[i].getPosition());
    });
    // console.log(newTemp)

    this.setState({ filteredFeatures: newTemp });

    // console.log(this.state.filteredFeatures)
  };

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <Typography variant="h1">
          <Hidden mdUp>
            <br />
            <br />
          </Hidden>
          {this.language.DropoffLocator[this.state.language]}
        </Typography>
        <div id="state">
          <TextField
            style={{ width: "100%", height: "50px" }}
            id="pac-input"
            label={this.language.Enteralocation[this.state.language]}
            margin="normal"
          />
          <MapPanel
            disabled={this.state.isLoading}
            zoomOut={this.zoomOut}
            data={this.state.filteredFeatures}
            maxLength={features.length}
            moveIt={this.moveToLocation}
            setSelectedDropoff={this.props.setSelectedDropoff}
            active={this.state.selected_marker}
          />
        </div>
        <div id="map-container">
          <div id="map" style={{ height: 400 }} />
        </div>
        <div className={classes.actionsContainer}>
          <div>
            <Button
              onClick={() => this.moveToCenter()}
              className={classes.button}
            >
              {this.language.Back[this.state.language]}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={this.props.dropoff === ""}
              onClick={() => {
                this.props.handleNext();
              }}
              className={classes.button}
            >
              {this.language.ConfirmandContinue[this.state.language]}
            </Button>
          </div>
        </div>
      </Fragment>
    );
  }
}

VerticalLinearStepper.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(VerticalLinearStepper);
