import { Button, Hidden, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import "./dev.css";

const styles = (theme) => ({
  root: {
    width: "90%",
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2,
  },
  resetContainer: {
    padding: theme.spacing.unit * 3,
  },
  progress: {
    margin: theme.spacing.unit * 2,
    marginLeft: "20px",
  },
});

class VerticalLinearStepper extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      zoom: 9,
      maptype: "roadmap",
      place_formatted: "",
      place_id: "",
      place_location: "",
      selected_marker: "",
      connector: [],
      center: {
        lat: 0,
        lng: 0,
      },
    };
  }

  UNSAFE_componentWillMount = async () => {
    if (this.props.selectedShipments) {
      if (
        this.props.shipment
          .filter((e) => this.props.selectedShipments.indexOf(e.id) > -1)
          .filter((e) => e.receiver.address.country !== "CA").length > 0
      ) {
        this.props.setData("hasUS", true);
      }
    } else {
      // alert("NO SHIPMENTS SELECTED")
      this.props.history.push("/");
      return;
    }
    return;
  };

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <Fragment>
          <Typography variant="h1">
            <Hidden mdUp>
              <br />
              <br />
            </Hidden>
            Daily Pickup
          </Typography>
          <p>
            <strong>
              Add selected shipments to your existing daily pickup.
            </strong>
          </p>
        </Fragment>
        {this.props.shipment
          .filter((e) => this.props.selectedShipments.indexOf(e.id) > -1)
          .filter((e) => e.shipForChild == "true").length > 0 && (
          <Fragment>
            <br />
            <div
              style={{
                "max-width": "700px",
                background: "#f9ffa8",
                padding: "20px",
                "border-radius": "6px",
              }}
            >
              <h3>
                <strong>
                  Pickups for return shipments will be automatically scheduled
                  per location after&nbsp;checking&nbsp;out.
                </strong>
              </h3>
            </div>
            <br />
          </Fragment>
        )}
        <div className={classes.actionsContainer}>
          <div>
            <Button disabled={true} className={classes.button}>
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                this.props.handleNext();
              }}
              className={classes.button}
            >
              {"Continue"}
            </Button>
          </div>
        </div>
      </Fragment>
    );
  }
}

VerticalLinearStepper.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(VerticalLinearStepper);
