import { Button, CircularProgress, Paper, Typography } from "@material-ui/core";
import { useAddressBook } from "providers/AddressBook";
import { ReactNode } from "react";
import { useHistory } from "react-router-dom";
import { ImportRecord } from "./CSVImportTable";
import {
  DUPLICATE_ICON_COLOR,
  ERROR_ICON_COLOR,
  IMPORTED_ICON_COLOR,
  MISMATCH_ICON_COLOR,
  RowDuplicateIcon,
  RowErrorIcon,
  RowImportedIcon,
  RowMismatchIcon,
} from "./CSVImportTableRow";
import { useAddressCSVImporter } from "./useAddressCSVImporter";

export function ImportSummary({ records }: { records: ImportRecord[] }) {
  return (
    <Paper style={{ padding: "12px 24px", marginBottom: 16 }}>
      {records.length > 0 ? (
        <>
          <ImportedSummary records={records} />
          <ErrorSummary records={records} />
          <DuplicateSummary records={records} />
          <MismatchSummary records={records} />
          <ActionButtons />
        </>
      ) : (
        <ProcessingIndicator />
      )}
    </Paper>
  );
}

function ActionButtons() {
  const { setCsv } = useAddressCSVImporter();
  const { listAddressBook } = useAddressBook();
  const history = useHistory();

  function handleFinish() {
    // todo: refresh addresses
    history.push("/addressbook");
  }

  function handleStartAgain() {
    listAddressBook();
    setCsv(undefined);
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 12,
        borderTop: "1px solid lightGrey",
        marginTop: 16,
      }}
    >
      <Button onClick={handleStartAgain}>Start Again</Button>
      <Button color="primary" onClick={handleFinish}>
        Finish Import
      </Button>
    </div>
  );
}

function ProcessingIndicator() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 20,
      }}
    >
      <CircularProgress size={42} thickness={5} />
      <Typography style={{ marginTop: 20, fontSize: 18 }}>
        Processing CSV...
      </Typography>
    </div>
  );
}

function Legend({
  text,
  color,
  icon,
}: {
  text: string;
  color: string;
  icon: ReactNode;
}) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        paddingTop: 4,
        paddingBottom: 4,
      }}
    >
      {icon}
      <p style={{ margin: "0 0 0 8px", color }}>{text}</p>
    </div>
  );
}

function ImportedSummary({ records }: { records: ImportRecord[] }) {
  const importedRecords = records.filter((r) => r.importState === "imported");

  return (
    <Legend
      text={`${importedRecords.length} records were imported`}
      color={IMPORTED_ICON_COLOR}
      icon={<RowImportedIcon />}
    />
  );
}

function DuplicateSummary({ records }: { records: ImportRecord[] }) {
  const duplicateRecords = records.filter((r) => r.importState === "duplicate");

  if (duplicateRecords.length) {
    return (
      <Legend
        text={`${duplicateRecords.length} records were found to be duplicates of existing addresses and were not imported`}
        color={DUPLICATE_ICON_COLOR}
        icon={<RowDuplicateIcon />}
      />
    );
  } else {
    return <></>;
  }
}

function ErrorSummary({ records }: { records: ImportRecord[] }) {
  const failedRecords = records.filter((r) => r.importState === "error");

  if (failedRecords.length) {
    return (
      <Legend
        text={`${failedRecords.length} records failed to import and can be reviewed below`}
        color={ERROR_ICON_COLOR}
        icon={<RowErrorIcon />}
      />
    );
  } else {
    return <></>;
  }
}

function MismatchSummary({ records }: { records: ImportRecord[] }) {
  const mismatchedRecords = records.filter((r) => r.importState === "mismatch");

  if (mismatchedRecords.length) {
    return (
      <Legend
        text={`${mismatchedRecords.length} records have verified addresses that differ from imported file and should be reviewed`}
        color={MISMATCH_ICON_COLOR}
        icon={<RowMismatchIcon />}
      />
    );
  } else {
    return <></>;
  }
}
