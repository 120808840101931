import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { getCookie } from "helpers/getCookie";
import React from "react";

class AlertDialog extends React.Component {
  state = {
    open: this.props.open,
  };

  UNSAFE_componentWillMount = () => {
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });

    if (this.props.printed === true) {
      this.setState({ open: false });
    }
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = (e) => {
    // console.log(this.props.total, this.props.count)
    if (e) {
      this.props.setData(
        "runningTotal",
        (
          (Number(this.props.total * 100) + Number(this.props.count * 113)) /
          100
        ).toFixed(2)
      ); //TODO
      this.props.setData("printed", true); //TODO
    }
    this.setState({ open: false });
  };

  language = {
    DropoffPrintTitle: {
      FR: "Souhaitez-vous que vos étiquettes d'expédition soient imprimées à l'endroit de livraison sélectionné?",
      EN: "Would you like to have your shipping labels printed at the selected dropoff location?",
    },
    No: {
      FR: "Non",
      EN: "No",
    },
    Yes: {
      FR: "Oui",
      EN: "Yes",
    },
  };

  render() {
    return (
      <div>
        {/* <Button variant="outlined" color="primary" onClick={this.handleClickOpen}>
          Open alert dialog
        </Button> */}
        <Dialog
          open={this.props.tab === 1 && this.state.open}
          // onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {this.language.DropoffPrintTitle[this.state.language]}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleClose(false)} color="primary">
              {this.language.No[this.state.language]}
            </Button>
            <Button
              onClick={() => this.handleClose(true)}
              color="primary"
              autoFocus
            >
              {this.language.Yes[this.state.language]}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default AlertDialog;
