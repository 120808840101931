import { InputBase } from "@material-ui/core";
import { InputBaseProps } from "@material-ui/core/InputBase";
import { FC } from "react";

export const SearchAddressesInput: FC<
  Omit<InputBaseProps, "onChange"> & {
    onChange: (searchTerm: string) => void;
  }
> = ({ onChange, style }) => {
  return (
    <InputBase
      autoFocus
      style={{
        ...style,
        border: "1px solid var(--deep-blue)",
        borderRadius: 40,
        paddingLeft: "1em",
        boxShadow: "0px 12px 24px 4px rgba(0, 0, 0, 0.08)",
      }}
      placeholder="Search addresses"
      onChange={(e) => {
        onChange(e.target.value);
      }}
    />
  );
};
