import { Paper, Theme, withStyles } from "@material-ui/core";
import { FC } from "react";

const styles = (theme: Theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    backgroundColor: theme.palette.background.paper,
    borderTop: "13px solid var(--navy)",
  },
});

const PagePaper: FC<{ classes: any }> = ({ children, classes }) => {
  return (
    <Paper square elevation={1} className={classes.root}>
      {children}
    </Paper>
  );
};

export default withStyles(styles)(PagePaper);
