import { API, graphqlOperation } from "aws-amplify";

export const onUpdateSettings = (user: string) =>
  API.graphql(
    graphqlOperation(`
      subscription {
        onUpdateSettings(cognitoUsername:"${user}") {
          id
          cognitoUsername
        }
      }
    `)
  );
