import { API, graphqlOperation } from "aws-amplify";

export const listSettings = () =>
  API.graphql(
    graphqlOperation(`
      query listSettings {
        listSettings {
          items {
            id
            printer
            autoPrint
          }
        }
      }
    `)
  );
