import { API, graphqlOperation } from "aws-amplify";

export const listLocations = () =>
  API.graphql(
    graphqlOperation(`
      query listLocations {
        listV1_Locations {
          items {
            id
            title
            locked
            address{
              street_name
              street_number
              street_direction
              postal
              city
              unit
              state
              country
              coords_lat
              coords_long
            }
            contact {
              fname
              lname
              email
              phone
            }
            cognitoUsername
            url
          }
        }
      }
    `)
  );
