import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { round } from "../../helpers/TaskRunners";
import LoadingIcon from "../loading/LoadingIcon";

const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  menu: {
    width: 200,
  },
  formControl: {
    margin: theme.spacing.unit,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  formControl: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2,
    display: "block",
  },
  resetContainer: {
    padding: theme.spacing.unit * 2,
  },
});

class TextFields extends React.Component {
  state = {};

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  renderPackages(arr) {
    if (
      this.props.account &&
      this.props.account.user &&
      this.props.account.user.fixedPLD
    ) {
      return `${arr.length} package${arr.length > 1 ? `s` : ``}`;
    }
    if (arr.length > 1) {
      return [{}].concat(arr).map((note, i) => {
        if (i !== 0) {
          return (
            <Fragment>
              {note.length} inches x {note.width} inches x {note.height} inches,{" "}
              {note.weight} pounds
              <br />
            </Fragment>
          );
        }
      });
    } else {
      return (
        <Fragment>
          {arr[0]["length"]} inches x {arr[0].width} inches x {arr[0].height}{" "}
          inches, {arr[0].weight} pounds
          <br />
        </Fragment>
      );
    }
  }

  render() {
    const { classes } = this.props;
    // console.log(this.props)
    let rate = this.props.rates[this.props.selectedService].rate;
    rate = parseFloat(rate).toFixed(2);

    let sender = this.props.locations.filter(
      (e) => e.id === this.props.senderLocationID
    );
    sender = sender[0];

    return (
      <div>
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography variant="subheading">
            <div style={{ maxWidth: "250px", lineHeight: "36px" }}>
              <strong>
                {this.props.return === true ? `Sender` : `Receiver`}
              </strong>
              <Button
                color="secondary"
                style={{ verticalAlign: "middle", float: "right" }}
                onClick={() => this.props.handleGoToStep(0)}
              >
                Edit
              </Button>
            </div>
            {this.props.receiver.business && (
              <span>
                {this.props.receiver.business}
                <br />
              </span>
            )}
            Attn: {this.props.receiver.fname} {this.props.receiver.lname}
            <br />
            {this.props.receiver.unit && (
              <span>#{this.props.receiver.unit}&nbsp;-&nbsp;</span>
            )}
            {this.props.receiver.address}
            <br />
            {this.props.receiver.city}, {this.props.receiver.state}{" "}
            {this.props.receiver.zip}
          </Typography>
        </Paper>
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography variant="subheading">
            <div style={{ maxWidth: "250px", lineHeight: "36px" }}>
              <strong>
                {this.props.return === true ? `Receiver` : `Sender`}
              </strong>{" "}
              <Button
                color="secondary"
                style={{ verticalAlign: "middle", float: "right" }}
                onClick={() => this.props.handleGoToStep(1)}
              >
                Edit
              </Button>
            </div>
            {sender.title}
            <br />
            {sender.address.unit ? (
              <span>#{sender.address.unit}&nbsp;-&nbsp;</span>
            ) : (
              ``
            )}
            {sender.address.street_number} {sender.address.street_name}
            <br />
            {sender.address.city}, {sender.address.state}{" "}
            {sender.address.postal}
          </Typography>
        </Paper>
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography variant="subheading">
            <div style={{ maxWidth: "250px", lineHeight: "36px" }}>
              <strong>
                {this.props.package.dimensions.length == 1
                  ? `Package`
                  : `Packages`}
              </strong>{" "}
              <Button
                color="secondary"
                style={{ verticalAlign: "middle", float: "right" }}
                onClick={() => this.props.handleGoToStep(2)}
              >
                Edit
              </Button>
            </div>
            {this.renderPackages(this.props.package.dimensions)}
            {this.props.package.insured && (
              <Fragment>
                {this.props.package.description}, Qty{" "}
                {this.props.package.quantity}
                <br />${this.props.package.value} value
              </Fragment>
            )}
          </Typography>
        </Paper>
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography variant="subheading">
            <div style={{ maxWidth: "250px", lineHeight: "36px" }}>
              <strong>Service</strong>{" "}
              {this.props.account &&
              this.props.account.user &&
              this.props.account.user.resolver ? (
                ""
              ) : (
                <Button
                  color="secondary"
                  style={{ verticalAlign: "middle", float: "right" }}
                  onClick={() => this.props.handleGoToStep(3)}
                >
                  Edit
                </Button>
              )}
            </div>
            {this.props.rates[this.props.selectedService].service.service.name}
            <br />${round(rate)}
            <br />
            <p style={{ color: "grey" }}>
              <small>
                Base: $
                {round(this.props.rates[this.props.selectedService].subtotal)}{" "}
                CAD
                <br />
                {this.props.rates[this.props.selectedService].accessorials &&
                  this.props.rates[this.props.selectedService].accessorials.map(
                    (e) => (
                      <Fragment>
                        {e.name}: ${round(e.charge)} CAD
                        <br />
                      </Fragment>
                    )
                  )}
                {this.props.rates[this.props.selectedService].return_charge >
                0 ? (
                  <Fragment>
                    Return Charge: $
                    {round(
                      this.props.rates[this.props.selectedService].return_charge
                    )}{" "}
                    CAD
                    <br />
                  </Fragment>
                ) : (
                  ``
                )}
                {this.props.rates[this.props.selectedService].fuel_surcharge >
                0 ? (
                  <Fragment>
                    Fuel Surcharge: $
                    {round(
                      this.props.rates[this.props.selectedService]
                        .fuel_surcharge
                    )}{" "}
                    CAD
                    <br />
                  </Fragment>
                ) : (
                  ``
                )}
                {this.props.rates[this.props.selectedService].xc_charge > 0 ? (
                  <Fragment>
                    Extra Care: $
                    {round(
                      this.props.rates[this.props.selectedService].xc_charge
                    )}{" "}
                    CAD
                    <br />
                  </Fragment>
                ) : (
                  ``
                )}
                {this.props.rates[this.props.selectedService].ea_charge > 0 ? (
                  <Fragment>
                    Extended Area: $
                    {round(
                      this.props.rates[this.props.selectedService].ea_charge
                    )}{" "}
                    CAD
                    <br />
                  </Fragment>
                ) : (
                  ``
                )}
                {this.props.rates[this.props.selectedService].rural_charge >
                0 ? (
                  <Fragment>
                    Rural Area: $
                    {round(
                      this.props.rates[this.props.selectedService].rural_charge
                    )}{" "}
                    CAD
                    <br />
                  </Fragment>
                ) : (
                  ``
                )}
                {this.props.rates[this.props.selectedService]
                  .residential_charge > 0 ? (
                  <Fragment>
                    Residential Charge: $
                    {round(
                      this.props.rates[this.props.selectedService]
                        .residential_charge
                    )}{" "}
                    CAD
                    <br />
                  </Fragment>
                ) : (
                  ``
                )}
                {this.props.rates[this.props.selectedService].tax1_code ? (
                  <Fragment>
                    {this.props.rates[this.props.selectedService].tax1_code} (
                    {Number(
                      this.props.rates[this.props.selectedService].tax1_rate
                    ) * 100}
                    %): $
                    {round(
                      this.props.rates[this.props.selectedService].tax1_amount
                    )}{" "}
                    CAD
                    <br />
                  </Fragment>
                ) : (
                  ``
                )}
                {this.props.rates[this.props.selectedService].tax2_code ? (
                  <Fragment>
                    {this.props.rates[this.props.selectedService].tax2_code} (
                    {Number(
                      this.props.rates[this.props.selectedService].tax2_rate
                    ) * 100}
                    %): $
                    {round(
                      this.props.rates[this.props.selectedService].tax2_amount
                    )}{" "}
                    CAD
                    <br />
                  </Fragment>
                ) : (
                  ``
                )}
                {/* {this.props.rates[this.props.selectedService].tax2_code ? <Fragment>{this.props.rates[this.props.selectedService].tax2_code} ({Number(this.props.rates[this.props.selectedService].tax2_rate) * 100}%): ${round(this.props.rates[this.props.selectedService].tax2_amount} CAD<br/></Fragment>:``} */}
              </small>
              {/* {this.props.rates[this.props.selectedService].service.service.delivery_ground} days */}
            </p>
          </Typography>
        </Paper>
        <Paper square elevation={0} className={classes.resetContainer}>
          <Button
            disabled={this.props.isLoading}
            onClick={() => this.props.handleBack("activeStep")}
            className={classes.button}
          >
            Back
          </Button>
          <Button
            disabled={this.props.isLoading}
            onClick={() => this.props.createShipment()}
            className={classes.button}
            variant="contained"
            color="primary"
          >
            {!this.props.isLoading ? "Submit" : <LoadingIcon size={20} />}
          </Button>
        </Paper>
      </div>
    );
  }
}

TextFields.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TextFields);
