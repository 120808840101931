import dayjs from "dayjs";
import { PO_BOX_REGEX } from "helpers/regex";
import * as Yup from "yup";

const MIN_WINDOW = .5; // Minimum 2hr diff between
export const MIN_OPEN = 0; // 9am
const MIN_CLOSE = MIN_OPEN + MIN_WINDOW;
export const MAX_CLOSE = 24; // 6pm
const MAX_OPEN = MAX_CLOSE - MIN_WINDOW;

// 9 -> "09:00"
export function to24hr(hr24: number) {
  return `${hr24}`.padStart(2, "0") + ":00";
}

export const AddressFormSchema = Yup.object().shape(
  {
    business_name: Yup.string().max(
      30,
      "Business name must be less than 30 characters"
    ),
    phone: Yup.string()
      .trim()
      .matches(
        /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/,
        "Phone number must be valid"
      ),
    open_time: Yup.string()
      .trim()
      .matches(
        /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/,
        "Open time must be in 24hr format (HH:MM)"
      )
      .when("close_time", (close_time, schema) => {
        return close_time?.length
          ? schema.required("Require open time given close time")
          : schema;
      })
      .test(
        "earliest-open",
        `Must be on or after ${dayjs(to24hr(MIN_OPEN), "HH:mm").format("ha")}`,
        (open_time) => {
          const open = dayjs(open_time, "HH:mm");
          return !open.isBefore(dayjs(to24hr(MIN_OPEN), "HH:mm"));
        }
      )
      .test(
        "latest-open",
        `Must be on or before ${dayjs(to24hr(MAX_OPEN), "HH:mm").format("ha")}`,
        (open_time) => {
          const open = dayjs(open_time, "HH:mm");
          return !open.isAfter(dayjs(to24hr(MAX_OPEN), "HH:mm"));
        }
      ),
    close_time: Yup.string()
      .trim()
      .matches(
        /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/,
        "Close time must be in 24hr format (HH:MM)"
      )
      .when("open_time", (open_time, schema) => {
        return open_time?.length
          ? schema
              .required("Require close time given open time")
              .test(
                "window",
                `Must be at least ${MIN_WINDOW} hours after open time`,
                (close_time: string) => {
                  const open = dayjs(open_time, "HH:mm");
                  const close = dayjs(close_time, "HH:mm");
                  return close.diff(open, "hours") >= MIN_WINDOW;
                }
              )
          : schema;
      })
      .test(
        "earliest-close",
        `Must be on or after ${dayjs(to24hr(MIN_CLOSE), "HH:mm").format("ha")}`,
        (close_time) => {
          const close = dayjs(close_time, "HH:mm");
          return !close.isBefore(dayjs(to24hr(MIN_CLOSE), "HH:mm"));
        }
      )
      .test(
        "latest-close",
        `Must be on or before ${dayjs(to24hr(MAX_CLOSE), "HH:mm").format(
          "ha"
        )}`,
        (close_time) => {
          const close = dayjs(close_time, "HH:mm");
          return !close.isAfter(dayjs(to24hr(MAX_CLOSE), "HH:mm"));
        }
      ),
    first_name: Yup.string()
      .min(2, "Name must be between 2 and 20 characters")
      .max(20, "Name must be between 2 and 20 characters")
      .required("First name is required"),
    last_name: Yup.string()
      .min(2, "Name must be between 2 and 20 characters")
      .max(20, "Name must be between 2 and 20 characters")
      .required("Last name is required"),
    email: Yup.string()
      .email("Invalid email")
      .max(50, "Email must be less than 50 characters"),
    address1: Yup.string()
      .matches(
        /^[A-Za-z0-9- ]+$/,
        "Address must be alphanumeric (no special characters)"
      )
      .test(
        "not-a-po-box",
        "You may not ship to a PO box",
        (address1) => !PO_BOX_REGEX.test(address1!)
      )
      .required("Address is required"),
    city: Yup.string()
      .max(30, "City must be 30 characters or less")
      .required("City is required"),
    state: Yup.string()
      .oneOf(
        [
          "AB",
          "BC",
          "MB",
          "NB",
          "NS",
          "NL",
          "ON",
          "PE",
          "SK",
          "QC",
          "YT",
          "AL",
          "AK",
          "AZ",
          "AR",
          "CA",
          "CO",
          "CT",
          "DE",
          "DC",
          "FL",
          "GA",
          "HI",
          "ID",
          "IL",
          "IN",
          "IA",
          "KS",
          "KY",
          "LA",
          "ME",
          "MT",
          "NE",
          "NV",
          "NH",
          "NJ",
          "NM",
          "NY",
          "NC",
          "ND",
          "OH",
          "OK",
          "OR",
          "MD",
          "MA",
          "MI",
          "MN",
          "MS",
          "MO",
          "PA",
          "PR",
          "RI",
          "SC",
          "SD",
          "TN",
          "TX",
          "UT",
          "VT",
          "VA",
          "WA",
          "WV",
          "WI",
          "WY",
        ],
        "State/Province needs to be two letter code (ex. ON)"
      )
      .test(
        "supported-state-prov",
        "State/Province is currently unsupported",
        (state) => !["NU", "NT"].includes(state!)
      )
      .required("State/Province is required"),
    postal: Yup.string()
      .when("country", {
        is: (country: string) => country === "US",
        then: (schema) => schema.length(5, "Zip code must be 5 digits"),
        otherwise: (schema) =>
          schema.length(6, "Postal code must be 6 characters"),
      })
      .required("Postal code is required"),
    country: Yup.mixed()
      .oneOf(["CA", "US"], "Country code needs to be CA or US")
      .required("Country is required"),
    location_note: Yup.string().max(
      50,
      "Location note must be 50 characters or less"
    ),
  },
  [["open_time", "close_time"]] // Required to fix circular dependency checking they exist pairwise
);
