import {
  AddressBookRecord,
  Country,
  CreateAddressRequest,
  UpdateAddressRequest,
  UUID,
} from "@shared";
import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  createAddressBookEntry,
  deleteAddressBookEntry,
  fetchAddressBook,
  updateAddressBookEntry,
} from "../api/addressBook";

interface Props {
  addresses: AddressBookRecord[];
  listAddressBook: () => Promise<void>;
  createEntry: (data: CreateAddressRequest) => Promise<AddressBookRecord>;
  updateEntry: (id: UUID, data: UpdateAddressRequest) => Promise<void>;
  deleteEntry: (id: UUID) => Promise<void>;
  setSearchString: (searchString: string) => void;
  setCountry: (country: Country | undefined) => void;
  filteredAddresses: AddressBookRecord[];
  loadingAddresses: boolean;
  loadingAddressesError: boolean;
}

const AddressBookContext = createContext<Props>({} as Props);

export function displayedFields(
  isSmall: boolean
): Array<keyof AddressBookRecord> {
  if (isSmall) {
    return ["business_name", "first_name", "last_name"];
  } else {
    return ["business_name", "first_name", "last_name", "address1", "email"];
  }
}

// Ignore case for searches
export function searchTokens(search: string) {
  return search.toLowerCase().split(" ");
}

const AddressBookProvider: FC<{ isSmall: boolean }> = ({
  children,
  isSmall,
}) => {
  const [country, setCountry] = useState<Country>();
  const [addresses, setAddresses] = useState<AddressBookRecord[]>([]);
  const [searchString, setSearchString] = useState<string>("");
  const [filteredAddresses, setFilteredAddresses] = useState<
    AddressBookRecord[]
  >([]);
  const [loadingAddresses, setLoadingAddresses] = useState(false);
  const [loadingAddressesError, setLoadingAddressesError] = useState(false);

  const listAddressBook = useCallback(async () => {
    setLoadingAddresses(true);

    try {
      const response = await fetchAddressBook();
      setAddresses(response);
      setLoadingAddresses(false);
    } catch (error) {
      console.error(error);
      setLoadingAddressesError(true);
    }
  }, []);

  const deleteEntry = useCallback(
    async (id: UUID) => {
      await deleteAddressBookEntry(id);
      await listAddressBook();
    },
    [listAddressBook]
  );

  const createEntry = useCallback(
    async (data: CreateAddressRequest) => {
      const addressBookRecord = await createAddressBookEntry(data);
      await listAddressBook();
      return addressBookRecord;
    },
    [listAddressBook]
  );

  const updateEntry = useCallback(
    async (id: UUID, data: UpdateAddressRequest) => {
      await updateAddressBookEntry(id, data);
      await listAddressBook();
    },
    [listAddressBook]
  );

  // When country, search string or addresses change, re-run the search
  useEffect(() => {
    const prefiltered = country
      ? addresses.filter((address) => address.country === country)
      : addresses;

    if (!searchString.trim().length) {
      setFilteredAddresses(prefiltered);
    } else {
      // Keep the row if *all* of the tokens are contained *somewhere* in the address
      setFilteredAddresses(
        prefiltered.filter((address) =>
          searchTokens(searchString).every((token) =>
            displayedFields(isSmall).some((field) =>
              ((address[field] as string) ?? "").toLowerCase().includes(token)
            )
          )
        )
      );
    }
  }, [addresses, country, searchString, isSmall]);

  let value = {
    addresses,
    filteredAddresses,
    deleteEntry,
    createEntry,
    updateEntry,
    listAddressBook,
    loadingAddresses,
    loadingAddressesError,
    setSearchString,
    setCountry,
  };

  return (
    <AddressBookContext.Provider value={value}>
      {children}
    </AddressBookContext.Provider>
  );
};

function useAddressBook() {
  return useContext(AddressBookContext);
}

export { AddressBookProvider, AddressBookContext, useAddressBook };
