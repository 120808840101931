import { AddressBookRecord } from "@shared";
import AddressBookDialog from "./AddressBookDialog";
import AddressBookForm from "./AddressBookForm";

export default function EditAddressDialog(props: {
  address: AddressBookRecord;
  open: boolean;
  onClose: () => void;
}) {
  const handleFormSuccess = () => {
    props.onClose();
  };

  return (
    <AddressBookDialog
      title="Edit Address"
      open={props.open}
      onClose={props.onClose}
    >
      <AddressBookForm address={props.address} onSuccess={handleFormSuccess} />
    </AddressBookDialog>
  );
}
