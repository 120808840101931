import { API } from "aws-amplify";

const chargeCard = (data, callback) =>
  new Promise(async (resolve, reject) => {
    // console.log({
    //     body : data
    // })
    API.post("sqs", "/moneris/charge", {
      body: data,
    })
      .then((e) => {
        // console.log("MONERIS2",e)
        resolve(e);
      })
      .catch((e) => reject(e));
  });

export default chargeCard;
