// react
// import LoadingIcon from "../components/loading/LoadingIcon";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
// components
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
// icons
import Typography from "@material-ui/core/Typography";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import FaceOutlined from "@material-ui/icons/FaceOutlined";
import { Auth } from "aws-amplify";
import { getCookie } from "helpers/getCookie";
import React from "react";
import { Link } from "react-router-dom";
// snackbars
import SnackBar from "../../components/Snackbar";
import { getParameterByName } from "../../helpers/TaskRunners";
// helpers
import { isNotEmpty, isValidEmail } from "../../helpers/Validation";
// images
import Logo from "../../img/logo.svg";
// styles
import "../../styles/Login.css";

//---------------------------------------------

export default class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
      snackMessage: "",
      snackOpen: false,
      snackVariant: "error",
      formComplete: false,
    };
  }

  UNSAFE_componentWillMount = () => {
    // document.cookie = "language=FR";

    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });
  };

  language = {
    Theemailenteredisnotvalid: {
      FR: "L'e-mail entré n'est pas valide",
      EN: "The email entered is not valid",
    },
    Youhaveexceededthenumberofresetspleasewaitafewminutesbeforetryingagain: {
      FR: "Vous avez dépassé le nombre de réinitialisations, veuillez patienter quelques minutes avant de réessayer",
      EN: "You have exceeded the number of resets, please wait a few minutes before trying again",
    },
    ThisaccounthasnotbeenverifiedAnotherverificationemailhasbeensenttothisaddress:
      {
        FR: "Ce compte n'a pas été vérifié. Un autre e-mail de vérification a été envoyé à cette adresse.",
        EN: "This account has not been verified. Another verification email has been sent to this address.",
      },
    Passwordresetrequest: {
      FR: "Demande de réinitialisation du mot de passe",
      EN: "Password reset request",
    },
    EmailAddress: {
      FR: "Adresse électronique",
      EN: "Email Address",
    },
    Apasswordresetemailhasbeensenttotheprovidedaddress: {
      FR: "Un e-mail de réinitialisation du mot de passe a été envoyé à l'adresse fournie.",
      EN: "A password reset email has been sent to the provided address.",
    },
    Rememberyourpassword: {
      FR: "Je me souviens de mon mot de passe",
      EN: "Remember your password?",
    },
    Login: {
      FR: "S'identifier",
      EN: "Login",
    },
    BacktoLogin: {
      FR: "Revenir à la connexion",
      EN: "Back to Login",
    },
    Loading: {
      FR: "Attendre",
      EN: "Loading",
    },
    ResetPassword: {
      FR: "Réinitialiser le mot de passe",
      EN: "Reset Password",
    },
  };

  componentDidMount() {
    if (getParameterByName("user")) {
      this.setState({ email: getParameterByName("user") });
    }
  }

  validateForm() {
    return isNotEmpty(this.state.email) && isValidEmail(this.state.email);
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    // check form
    if (!this.validateForm()) {
      this.setState({
        snackMessage:
          this.language.Theemailenteredisnotvalid[this.state.language],
      });
      this.setState({ snackOpen: true });
      return;
    }

    await Auth.forgotPassword(this.state.email)
      .then(() => {
        this.setState({ isLoading: false });
        this.setState({ formComplete: true });
      })
      .catch((e) => {
        // console.log(e)
        switch (e.code) {
          case "LimitExceededException":
            this.setState({
              snackMessage:
                this.language
                  .Youhaveexceededthenumberofresetspleasewaitafewminutesbeforetryingagain[
                  this.state.language
                ],
            });
            this.setState({ snackVariant: "error" });
            this.setState({ snackOpen: true });
            break;
          case "InvalidParameterException":
            try {
              Auth.resendSignUp(this.state.email);
            } catch (e) {
              // console.log(e)
            }
            this.setState({
              snackMessage:
                this.language
                  .ThisaccounthasnotbeenverifiedAnotherverificationemailhasbeensenttothisaddress[
                  this.state.language
                ],
            });
            this.setState({ snackVariant: "error" });
            this.setState({ snackOpen: true });
            break;
          case "UserNotFoundException":
            this.setState({
              snackOpen: true,
              snackVariant: "error",
              snackMessage:
                "Accout email not found. Please check uppercase/lowercase version of your email address.",
            });
            break;
          default:
            this.setState({ isLoading: false });
            this.setState({ formComplete: true });
        }
      });
  };

  render() {
    return (
      <div className="Login">
        <SnackBar
          variant={this.state.snackVariant}
          open={this.state.snackOpen}
          message={this.state.snackMessage}
          handleClose={() => {
            this.setState({ snackOpen: false });
          }}
        />
        <Grow in={true} timeout={500}>
          <Grid item xs={12} sm={7} md={5} style={{ margin: "auto" }}>
            <img src={Logo} className="Paper" alt="Swiftpost Logo" />
            <Paper
              elevation={6}
              style={{ padding: "30px", maxWidth: "400px" }}
              className="Paper"
            >
              <Typography variant="headline" component="h3">
                {!this.state.formComplete
                  ? this.language.Passwordresetrequest[this.state.language]
                  : ""}
              </Typography>

              {!this.state.formComplete ? (
                <form onSubmit={this.handleSubmit}>
                  <Grid
                    className="Input no-margin"
                    style={{
                      width: "100%",
                    }}
                    container
                    spacing={8}
                    alignItems="flex-end"
                  >
                    <Grid item xs={1}>
                      <FaceOutlined />
                    </Grid>
                    <Grid item xs={11}>
                      <TextField
                        style={{ width: "100%" }}
                        id="email"
                        onChange={this.handleChange}
                        value={this.state.email}
                        type="email"
                        label={this.language.EmailAddress[this.state.language]}
                      />
                    </Grid>
                  </Grid>

                  <br />

                  <Button
                    className="loginSubmit"
                    variant="contained"
                    color="primary"
                    style={{
                      width: "100%",
                    }}
                    onClick={this.handleSubmit}
                    disabled={this.state.isLoading}
                  >
                    {!this.state.isLoading &&
                      this.language.ResetPassword[this.state.language]}
                    {this.state.isLoading &&
                      this.language.Loading[this.state.language]}
                  </Button>
                </form>
              ) : (
                <Grow timeout={1000} in={this.state.formComplete}>
                  <center>
                    <CheckIcon style={{ color: "green", fontSize: "80px" }} />

                    <h4>
                      {
                        this.language
                          .Apasswordresetemailhasbeensenttotheprovidedaddress[
                          this.state.language
                        ]
                      }
                    </h4>
                  </center>
                </Grow>
              )}
            </Paper>
            <Grid
              item
              style={{ margin: "auto", padding: "0px", maxWidth: "400px" }}
            >
              <Typography className="callout">
                {!this.state.formComplete
                  ? this.language.Rememberyourpassword[this.state.language]
                  : ""}
              </Typography>
              <Link to="/login">
                <Button
                  variant="contained"
                  color="secondary"
                  style={{
                    width: "100%",
                  }}
                >
                  {!this.state.formComplete
                    ? this.language.Login[this.state.language]
                    : this.language.BacktoLogin[this.state.language]}
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grow>
      </div>
    );
  }
}
