export function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2]);
}

export function removeDuplicatesFromArray(arr) {
  let unique_array = arr.filter(function (elem, index, self) {
    return index == self.indexOf(elem);
  });
  return unique_array;
}

export function round(value) {
  // alert(value)
  // alert(Number(Math.round(value+'e'+2)+'e-'+2))
  return Number(Math.round(value + "e" + 2) + "e-" + 2).toFixed(2);
  // alert(temp)
  // return temp;
}
