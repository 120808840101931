import { Button, TableCell, TableRow, Tooltip } from "@material-ui/core";
import { CheckCircle, Compare, Error, FileCopy } from "@material-ui/icons";
import { ReactNode, useState } from "react";
import { rowValue } from "../helpers";
import { ImportRecord } from "./CSVImportTable";
import EditImportRecordDialog from "./EditImportRecordDialog";
import { useAddressCSVImporter } from "./useAddressCSVImporter";

export const IMPORTED_ROW_COLOR = "#e8ffed";
export const DUPLICATE_ROW_COLOR = "#e8faff";
export const ERROR_ROW_COLOR = "#ffe9e8";
export const MISMATCH_ROW_COLOR = "#fffec4";
export const IMPORTED_ICON_COLOR = "#3e7349";
export const ERROR_ICON_COLOR = "#a33b36";
export const DUPLICATE_ICON_COLOR = "#496b8c";
export const MISMATCH_ICON_COLOR = "#878408";

export function RecordRow({
  record,
  className,
}: {
  record: ImportRecord;
  className?: string;
}) {
  function rowStyle(record: ImportRecord) {
    switch (record.importState) {
      case "imported":
        return { backgroundColor: IMPORTED_ROW_COLOR };
      case "duplicate":
        return { backgroundColor: DUPLICATE_ROW_COLOR };
      case "error":
        return { backgroundColor: ERROR_ROW_COLOR };
      case "mismatch":
        return { backgroundColor: MISMATCH_ROW_COLOR };

      default:
        return { backgroundColor: "#fff" };
    }
  }

  const displayAddress = record.verifiedAddress ?? record.importedAddress;

  return (
    <TableRow style={rowStyle(record)}>
      <TableCell style={{ padding: "0 10px" }}>
        <RecordRowStatusIcon record={record} />
      </TableCell>
      <TableCell className={className}>
        {rowValue(displayAddress.business_name)}
      </TableCell>
      <TableCell className={className}>
        {rowValue(displayAddress.first_name)}
      </TableCell>
      <TableCell className={className}>
        {rowValue(displayAddress.last_name)}
      </TableCell>
      <TableCell className={className}>
        {rowValue(displayAddress.phone)}
      </TableCell>
      <TableCell className={className}>
        {rowValue(displayAddress.email)}
      </TableCell>
      <TableCell className={className}>
        {rowValue(displayAddress.address1)}
      </TableCell>
      <TableCell className={className}>
        {rowValue(displayAddress.city)}
      </TableCell>
      <TableCell className={className}>
        {rowValue(displayAddress.state)}
      </TableCell>
      <TableCell className={className}>
        {rowValue(displayAddress.postal)}
      </TableCell>
      <ActionRow record={record} />
    </TableRow>
  );
}

function ActionRow({ record }: { record: ImportRecord }) {
  const [editing, setEditing] = useState(false);

  const { importRecord } = useAddressCSVImporter();

  async function handleAcceptMismatch() {
    importRecord(record, { updateState: true });
  }

  return (
    <TableCell>
      {(record.importState === "error" ||
        record.importState === "duplicate") && (
        <>
          <ActionButton onClick={() => setEditing(true)}>Edit</ActionButton>
          <EditImportRecordDialog
            record={record}
            open={editing}
            onClose={() => setEditing(false)}
          />
        </>
      )}
      {record.importState === "mismatch" && (
        <>
          <ActionButton onClick={() => setEditing(true)}>Edit</ActionButton>
          <ActionButton onClick={handleAcceptMismatch}>Accept</ActionButton>
          <EditImportRecordDialog
            record={record}
            open={editing}
            onClose={() => setEditing(false)}
          />
        </>
      )}
    </TableCell>
  );
}

export function ActionButton({
  onClick,
  children,
}: {
  onClick?: () => void;
  children: ReactNode;
}) {
  return (
    <Button
      size="small"
      onClick={() => onClick?.()}
      style={{ backgroundColor: "transparent", color: "black" }}
    >
      {children}
    </Button>
  );
}

export function RecordRowStatusIcon({ record }: { record: ImportRecord }) {
  function errorString(record: ImportRecord) {
    return record.errors.map((err) => err.message).join(" ");
  }

  switch (record.importState) {
    case "imported":
      return <RowImportedIcon />;
    case "error":
      // note: extra <div> helps tooltip work
      return (
        <Tooltip title={errorString(record)}>
          <div>
            <RowErrorIcon />
          </div>
        </Tooltip>
      );
    case "duplicate":
      // note: extra <div> helps tooltip work
      return (
        <Tooltip title="This address appears to be a duplicate of an existing address and will not be imported.">
          <div>
            <RowDuplicateIcon />
          </div>
        </Tooltip>
      );
    case "mismatch":
      // note: extra <div> helps tooltip work
      return (
        <Tooltip title="The verified address differs from what was entered. Please review the address and approve.">
          <div>
            <RowMismatchIcon />
          </div>
        </Tooltip>
      );
    default:
      return <></>;
  }
}

export const RowImportedIcon = () => (
  <CheckCircle style={{ color: IMPORTED_ICON_COLOR }} />
);

export const RowDuplicateIcon = () => (
  <FileCopy style={{ color: DUPLICATE_ICON_COLOR }} />
);

export const RowMismatchIcon = () => (
  <Compare style={{ color: MISMATCH_ICON_COLOR }} />
);

export const RowErrorIcon = () => <Error style={{ color: ERROR_ICON_COLOR }} />;
