import { Tooltip } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import red from "@material-ui/core/colors/red";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import { Warning, WatchLater } from "@material-ui/icons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { API, graphqlOperation } from "aws-amplify";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import Loading from "../../../components/loading/LoadingIcon";

const styles = (theme) => ({
  card: {
    [theme.breakpoints.up("lg")]: {
      width: "28%",
    },
    [theme.breakpoints.down("md")]: {
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    marginRight: 20,
    marginBottom: 20,
    float: "left",
  },
  actions: {
    display: "flex",
    float: "right",
  },
  avatarRed: {
    backgroundColor: red[500],
  },
  avatarGreen: {
    backgroundColor: "green",
  },
  avatarBlue: {
    backgroundColor: "var(--brand-cyan)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  avatar2: {
    backgroundColor: "#fff",
    height: "auto",
    width: 40,
  },
});

class ChildCard extends React.Component {
  state = {
    anchorEl: null,
    active: true,
  };

  handleRemove = async (id, email) => {
    // alert(email)

    await API.post("sqs", "/child-account/delete", {
      body: { email: email, cognitoSub: this.props.account.cognitoUsername },
    })
      .then(async (e) => {
        e.status
          ? await API.graphql(
              graphqlOperation(`mutation {
          deleteChildAccounts(input: { id:"${id}" }){
            id
            cognitoUsername
          }
        }`)
            )
          : alert("error deleting child account.");
      })
      .catch((e) => console.log("ERROR=>", e));
  };

  handleExpandClick = () => {
    this.setState((state) => ({ expanded: !state.expanded }));
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleEdit = () => {
    this.props.startEdit(this.props.data);
    this.setState({ anchorEl: null });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const { classes } = this.props;
    return (
      <Fragment>
        <Card className={classes.card} style={{ position: "relative" }}>
          {this.props.isLoading && (
            <div
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: "3",
                background: "rgba(255,255,255,.69)",
              }}
            >
              <center>
                <Loading
                  style={{
                    top: "30%",
                    position: "absolute",
                  }}
                />
              </center>
            </div>
          )}
          <CardHeader
            avatar={
              <Fragment>
                {this.props.data.status == "-1" ? (
                  <Tooltip
                    title={
                      "Address invalid, update needed before invite will send"
                    }
                  >
                    <Avatar aria-label="Recipe" className={classes.avatarRed}>
                      <Warning />
                    </Avatar>
                  </Tooltip>
                ) : (
                  ""
                )}
                {this.props.data.status == "0" ? (
                  <Tooltip
                    title={
                      "Invite Sent. Waiting for user to accept invite email"
                    }
                  >
                    <Avatar aria-label="Recipe" className={classes.avatarBlue}>
                      <WatchLater />
                    </Avatar>
                  </Tooltip>
                ) : (
                  ""
                )}
              </Fragment>
            }
            action={
              <div>
                <IconButton
                  aria-label="More"
                  aria-owns={anchorEl ? "long-menu" : null}
                  aria-haspopup="true"
                  onClick={this.handleClick}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="long-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={this.handleClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  {/* <MenuItem onClick={this.handleEdit}>Edit</MenuItem> */}
                  <MenuItem onClick={this.handleEdit}>Edit</MenuItem>
                  <MenuItem
                    onClick={() =>
                      this.handleRemove(
                        this.props.data.id,
                        this.props.data.email
                      )
                    }
                  >
                    Remove
                  </MenuItem>
                </Menu>
              </div>
            }
            title={this.props.data.business}
            subheader={
              <span>
                {this.props.data.fname} {this.props.data.lname}{" "}
                {this.props.data.phone}
                <br />
                {this.props.data.email}
                <br />
                {this.props.data.street_number} {this.props.data.street_name},{" "}
                {this.props.data.city} {this.props.data.state} <br />
                {this.props.data.postal}
              </span>
            }
          />
        </Card>
      </Fragment>
    );
  }
}

ChildCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChildCard);
