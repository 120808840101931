import { API, graphqlOperation } from "aws-amplify";

export const listEcommerceIntegrations = () =>
  API.graphql(
    graphqlOperation(`
      query listEcommerceIntegrations {
        listEcommerceIntegrations {
          items {
            id
            cognitoUsername
            platform
            sender_id
            date_created
            token
            secret
            shop_name
            shop_id
            address
            address2
            postal
            city
            state
            contact
            image_url
          }
        }
      }
    `)
  );
