import { Button, Divider, Typography, Grid, Hidden } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { AddCircleOutline } from "@material-ui/icons";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import React, { Component } from "react";
import EditAddress from "../components/EditAddress";
import FindAddress from "../components/FindAddress";
import LocationCard from "../components/LocationCard";
import "../styles.css";
import PagePaper from "./PagePaper";

const styles = (theme) => ({
  topActions: {
    display: "flex",
    justifyContent: "space-between",
    margin: "1em 0 2em",
  },
  root: {
    backgroundColor: theme.palette.background.paper,
  },
});

class Locations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      isEditing: false,
      editData: {},
    };
  }

  handleModalOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleModalClose = () => {
    this.setState({ modalOpen: false });
    this.setState({ isEditing: false });
  };

  editLocation = (e) => {
    // console.log(e);
    this.setState({ isEditing: true });
    this.setState({ editData: e });
  };

  UNSAFE_componentWillMount = () => {
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });
  };

  language = {
    YourLocations: {
      FR: "Vos Emplacements",
      EN: "Your Locations",
    },
    NewLocation: {
      FR: "Nouvel Emplacement",
      EN: "New Location",
    },
  };

  render() {
    return (
      <div className="Home">
        {!this.state.isEditing && (
          <FindAddress
            finalAction={(state) => {
              this.props.addLocation(state);
            }}
            open={this.state.modalOpen}
            handleClose={this.handleModalClose}
            {...this.props}
          />
        )}
        {this.state.isEditing && (
          <EditAddress
            data={this.state.editData}
            open={this.state.isEditing}
            handleClose={this.handleModalClose}
            {...this.props}
          />
        )}
        <Typography variant="h1">{this.language.YourLocations[this.state.language]}</Typography>
        <Grid item xs={12} md={8} lg={6} xl={4}>
          <PagePaper>
            <Hidden mdUp>
              <Button
                color="primary"
                onClick={this.handleModalOpen}
                style={{ margin: "0 0 1em", width: "100%" }}
              >
                <AddCircleOutline style={{ marginRight: "5px" }} />{" "}
                {this.language.NewLocation[this.state.language]}
              </Button>
            </Hidden>
            <Hidden smDown>
                <div className={this.props.classes.topActions}>
                  <div>
                    <Button color="primary" onClick={this.handleModalOpen}>
                      <AddCircleOutline style={{ marginRight: "5px" }} />{" "}
                      {this.language.NewLocation[this.state.language]}
                    </Button>
                  </div>
                </div>
            </Hidden>
            <Grid container>
            {this.props.locations.map((e, i) => (
              <LocationCard
                language={this.state.language}
                editLocation={() => this.editLocation(e)}
                data={e}
                key={i}
                number={i}
                {...this.props}
              />
            ))}
            </Grid>
            </PagePaper>
          </Grid>
      </div>
    );
  }
}

Locations.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Locations);
