const sandbox = {
  STRIPE_KEY: "pk_test_v1amvR35uoCNduJfkqGB8RLD",
  s3: {
    REGION: "us-east-1",
    BUCKET: "swiftpost-app",
  },
  app_apiGateway: {
    REGION: "us-east-1",
    URL: "https://iqr4dx6w70.execute-api.us-east-1.amazonaws.com/prod",
  },
  addressbook: {
    REGION: "us-east-1",
    URL: "https://lxetq6bkuk.execute-api.us-east-1.amazonaws.com/sandbox",
  },
  checkCognito: {
    REGION: "us-east-1",
    URL: "https://hj0lk4jp71.execute-api.us-east-1.amazonaws.com/prod",
  },
  child: {
    REGION: "us-east-1",
    URL: "https://2b5ziccche.execute-api.us-east-1.amazonaws.com/sandbox",
  },
  ecomm_test: {
    REGION: "us-east-1",
    URL: "https://2vtm62ame7.execute-api.us-east-1.amazonaws.com/prod",
  },
  etsy: {
    REGION: "us-east-1",
    URL: "https://67xrrpsg9j.execute-api.us-east-1.amazonaws.com/prod",
  },
  selfdelivery: {
    REGION: "us-east-1",
    URL: "https://bqtdk07jgb.execute-api.us-east-1.amazonaws.com/dev",
  },
  sp_sqs: {
    REGION: "us-east-1",
    URL: "https://hzjtlcvwsg.execute-api.us-east-1.amazonaws.com/sandbox",
  },
  uploadorders: {
    REGION: "us-east-1",
    URL: "https://umxxuwevsb.execute-api.us-east-1.amazonaws.com/sandbox",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_wNZpi6Kgr",
    APP_CLIENT_ID: "44b6pg4d87rdsn8m9al7vbplpf",
    IDENTITY_POOL_ID: "us-east-1:02f01a6f-43f0-4e64-b4d9-c44bc8a3a9e9",
  },
  routes: {
    API_URL: "app-staging.routes.io",
    APP_CLIENT_ID: "227to12k97robnie7d81tnja3l"
  }
};

const prod = {
  STRIPE_KEY: "pk_test_v1amvR35uoCNduJfkqGB8RLD",
  s3: {
    REGION: "us-east-1",
    BUCKET: "swiftpost-app",
  },
  app_apiGateway: {
    REGION: "us-east-1",
    URL: "https://iqr4dx6w70.execute-api.us-east-1.amazonaws.com/prod",
  },
  addressbook: {
    REGION: "us-east-1",
    URL: "https://989royztbe.execute-api.us-east-1.amazonaws.com/prod",
  },
  checkCognito: {
    REGION: "us-east-1",
    URL: "https://hj0lk4jp71.execute-api.us-east-1.amazonaws.com/prod",
  },
  child: {
    REGION: "us-east-1",
    URL: "https://taob7i8zn8.execute-api.us-east-1.amazonaws.com/prod",
  },
  ecomm_test: {
    REGION: "us-east-1",
    URL: "https://2vtm62ame7.execute-api.us-east-1.amazonaws.com/prod",
  },
  etsy: {
    REGION: "us-east-1",
    URL: "https://67xrrpsg9j.execute-api.us-east-1.amazonaws.com/prod",
  },
  selfdelivery: {
    REGION: "us-east-1",
    URL: "https://8fe3g0x2nf.execute-api.us-east-1.amazonaws.com/prod",
  },
  sp_sqs: {
    REGION: "us-east-1",
    URL: "https://q18mot7hgl.execute-api.us-east-1.amazonaws.com/prod",
  },
  uploadorders: {
    REGION: "us-east-1",
    URL: "https://zigxc78kck.execute-api.us-east-1.amazonaws.com/prod",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_wNZpi6Kgr",
    APP_CLIENT_ID: "44b6pg4d87rdsn8m9al7vbplpf",
    IDENTITY_POOL_ID: "us-east-1:02f01a6f-43f0-4e64-b4d9-c44bc8a3a9e9",
  },
  routes: {
    API_URL: "app.routes.io",
    APP_CLIENT_ID: "3fganrairp0is3bk3gg3bjk954"
  }
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === "prod" ? prod : sandbox;

export default config;
