import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Auth, API } from "aws-amplify";
import { CheckCircleOutline } from "@material-ui/icons";
import { Collapse } from "@material-ui/core";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginTop: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 200,
    },
  },
  textField2: {
    marginTop: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 415,
    },
  },
});

function DateAndTimePickers(props) {
  const { classes } = props;
  const [startDate, setStartDate] = React.useState(
    `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${new Date().getDate().toString().padStart(2, "0")}`
  );
  const [endDate, setEndDate] = React.useState(
    `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${new Date().getDate().toString().padStart(2, "0")}`
  );
  const [loading, setLoading] = React.useState(false);
  const [finished, setFinished] = React.useState(false);
  const [email, setEmail] = React.useState("");

  React.useEffect(() => {
    getEmail();
    // setEmail("");
    setFinished(false);
    setLoading(false);
    setStartDate(
      `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${new Date().getDate().toString().padStart(2, "0")}`
    );
    setEndDate(
      `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${new Date().getDate().toString().padStart(2, "0")}`
    );
  }, [props.open]);

  const getEmail = async () => {
    const user = await Auth.currentSession();
    setEmail(user.idToken.payload.email.toUpperCase());
  };

  const processExport = async () => {
    setLoading(true);
    // export-data
    // const email = await Auth.currentSession();
    // setEmail(email.idToken.payload.email.toUpperCase());
    API.post("uploadorders", "/export-data", {
      body: {
        email: email,
        startDate,
        endDate,
      },
    })
      .then((g) => {
        setFinished(true);
        setLoading(false);
      })
      .catch((g) => {
        console.log(g);
        alert(
          "There was an error processing your request. Please try again later."
        );
        setLoading(false);
      });
  };

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Export Shipment Data
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div>
              <TextField
                id="date"
                label="Start Date"
                type="date"
                disabled={finished || loading}
                value={startDate}
                defaultValue={`${new Date().getFullYear()}-${(
                  new Date().getMonth() + 1
                )
                  .toString()
                  .padStart(2, "0")}-${new Date()
                  .getDate()
                  .toString()
                  .padStart(2, "0")}`}
                className={classes.textField}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: "2008-01-01",
                  max: endDate,
                }}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <TextField
                disabled={finished || loading}
                id="date"
                label="End Date"
                type="date"
                value={endDate}
                variant="outlined"
                defaultValue={`${new Date().getFullYear()}-${(
                  new Date().getMonth() + 1
                )
                  .toString()
                  .padStart(2, "0")}-${new Date()
                  .getDate()
                  .toString()
                  .padStart(2, "0")}`}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: startDate,
                  max: `${new Date().getFullYear()}-${(
                    new Date().getMonth() + 1
                  )
                    .toString()
                    .padStart(2, "0")}-${new Date()
                    .getDate()
                    .toString()
                    .padStart(2, "0")}`,
                }}
                onChange={(e) => setEndDate(e.target.value)}
              />
              <TextField
                disabled={finished || loading}
                id="email"
                label="Email"
                type="text"
                value={email}
                variant="outlined"
                defaultValue={email}
                className={classes.textField2}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <br />
            <Collapse
              collapsedHeight="0px"
              in={finished}
              style={{
                background: "#c3e6cb",
                color: "#155724",
                padding: !finished ? "0" : "15px 30px",
                boxSizing: "border-box",
                borderRadius: "4px",
              }}
            >
              <CheckCircleOutline
                style={{
                  opacity: 0.7,
                  float: "left",
                  marginRight: "20px",
                  height: "100%",
                }}
              />
              An email containing the requested export
              <br />
              will be sent shortly
            </Collapse>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {finished ? (
            <Button onClick={props.handleClose} color="primary">
              Close
            </Button>
          ) : (
            <>
              <Button onClick={props.handleClose} color="primary">
                Cancel
              </Button>
              <Button
                disabled={loading}
                onClick={() => processExport()}
                color="primary"
              >
                Export
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withStyles(styles)(DateAndTimePickers);
