/* eslint-disable react/no-multi-comp */

import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import blue from "@material-ui/core/colors/blue";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import React from "react";

const styles = {
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
};

class PostalDialog extends React.Component {
  state = {
    open: false,
    postal: "",
  };

  handleListItemClick = (value) => {
    this.props.onClose(value);
  };

  handleChange = (name) => (event) => {
    let tempString = event.target.value;
    this.setState({ [name]: tempString.toUpperCase() });
  };

  componentDidMount = () => {
    this.setState({
      postal: this.props.data.postal ? this.props.data.postal : "",
    });
  };

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={() => {
            this.setState({ postal: "" });
            this.props.handleClose();
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent style={{ padding: "20px 50px" }}>
            <br />
            <small>
              <strong>Search by Postal Code</strong>
            </small>

            <TextField
              id={new Date().getTime()}
              label="Po&zwnj;s&zwnj;ta&zwnj;l Co&zwnj;de"
              margin="dense"
              error={this.props.zipError}
              fullWidth
              disabled={this.props.isLoading}
              autoFocus
              onChange={this.handleChange("postal")}
              variant="outlined"
              value={this.state.postal}
              style={{ marginTop: 25, marginBottom: 10 }}
              helperText={this.props.zipError ? "Postal code not found." : ""}
            />
            <br />
            <br />
            <Button
              disabled={this.props.isLoading}
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => this.props.searchPostal(this.state.postal)}
            >
              {this.props.isLoading ? "Loading" : "Search"}
            </Button>
            <br />
            <br />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default PostalDialog;
