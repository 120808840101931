import AddressBookDialog from "./AddressBookDialog";
import AddressBookForm from "./AddressBookForm";

export default function CreateAddressDialog(props: {
  open: boolean;
  onClose: () => void;
}) {
  const handleFormSuccess = () => {
    props.onClose();
  };

  return (
    <AddressBookDialog
      title="Create Address"
      open={props.open}
      onClose={props.onClose}
    >
      <AddressBookForm onSuccess={handleFormSuccess} />
    </AddressBookDialog>
  );
}
