import { Avatar, Button, Grid, ListItemText, Tooltip } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import { withStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { PrintOutlined } from "@material-ui/icons";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import LoadingIcon from "../components/loading/LoadingIcon";

const styles = (theme) => ({
  text: {
    paddingTop: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  //   button: {
  //     margin: theme.spacing.unit * 1.5,
  //   },
  list: {
    paddingTop: 0,
  },
  grey: {
    height: "20px",
    width: "20px",
    backgroundColor: "#FFC300",
  },
  yellow: {
    height: "20px",
    width: "20px",
    backgroundColor: "#FFC300",
  },
  red: {
    height: "20px",
    width: "20px",
    backgroundColor: "#C70039",
  },
  green: {
    height: "20px",
    width: "20px",
    backgroundColor: "#52BE80",
  },
  avatar: {
    background: "transparent",
    color: "grey",
    height: "20px",
    width: "20px",
    [theme.breakpoints.down("sm")]: {
      alignSelf: "flex-start",
      marginTop: "5px",
    },
    [theme.breakpoints.up("sm")]: {
      alignSelf: "center",
    },
  },
  activeAvatar: {
    background: "green",
    transition: ".1s all ease",
    [theme.breakpoints.down("sm")]: {
      alignSelf: "flex-start",
      marginTop: "5px",
    },
    [theme.breakpoints.up("sm")]: {
      alignSelf: "center",
    },
  },
  appBar: {
    top: "auto",
    bottom: 0,
  },
  toolbar: {
    alignItems: "center",
    justifyContent: "space-between",
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto",
  },
  activeButton: {
    background: "#eafaea",
    transition: ".3s all ease",
  },
  greybutton: {
    transition: ".3s all ease",
    background: "#f7f7f7",
  },
  marginbutton: {
    marginTop: "-8px",
    marginBottom: "8px",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  fixedBar: {
    position: "fixed",
    bottom: "5px",
    left: "50%",
    transform: "translateX(-50%)",
  },
  search: {
    position: "absolute",
    right: "20px",
    top: "50%",
    transform: "translateY(-50%)",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    // width: '100%',
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit,
      width: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 120,
      "&:focus": {
        width: 200,
      },
    },
  },
  formControl: {
    float: "right",
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  pageToggles: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "-5px",
    },
  },
});

class ShipmentRow extends React.Component {
  state = {
    isLoading: false,
  };

  UNSAFE_componentWillMount = () => {
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });
  };

  language = {
    LabelnotGenerated: {
      FR: "",
      EN: "Label not Generated",
    },
    PickupScheduled: {
      FR: "Ramassage Planifié",
      EN: "Shipment Created",
    },
    InboundtoSmarthub: {
      FR: "Entrant au Comptoir SmartHub",
      EN: "Inbound to Smarthub",
    },
    ReceivedatSmarthub: {
      FR: "Reçu à Smarthub",
      EN: "Received at Smarthub",
    },
    ScannedatSwiftpost: {
      FR: "Arrivée à Swiftpost",
      EN: "Scanned at Swiftpost",
    },
    InTransit: {
      FR: "En Transit",
      EN: "In Transit",
    },
    Exception: {
      FR: "Exception",
      EN: "Exception",
    },
    Delivered: {
      FR: "Livré",
      EN: "Delivered",
    },
    HFPU: {
      FR: "Retenu pour ramassage",
      EN: "Held for Customer Pickup",
    },
    Receiver: {
      FR: "Le Destinataire",
      EN: "Receiver",
    },
    Sender: {
      FR: "L'Expéditeur",
      EN: "Sender",
    },
    Print: {
      FR: "Imprimer",
      EN: "Print",
    },
    ReturnToSender: {
      FR: "Renvoyé à l'expéditeur",
      EN: "Return to Sender",
    },
  };

  getStatusMessage = (code) => {
    switch (code) {
      case "NR":
        return (
          <span style={{ color: "#e65100" }}>
            {this.language.LabelnotGenerated[this.state.language]}
          </span>
        );
      case "PU":
        return (
          <span style={{ color: "#4FC3F7" }}>
            {this.language.PickupScheduled[this.state.language]}
          </span>
        );
      case "IN":
        return (
          <span style={{ color: "#4FC3F7" }}>
            {this.language.InboundtoSmarthub[this.state.language]}
          </span>
        );
      case "SH":
        return (
          <span style={{ color: "#4FC3F7" }}>
            {this.language.ReceivedatSmarthub[this.state.language]}
          </span>
        );
      case "SP":
        return (
          <span style={{ color: "#4FC3F7" }}>
            {this.language.ScannedatSwiftpost[this.state.language]}
          </span>
        );
      case "TR":
        return (
          <span style={{ color: "#4FC3F7" }}>
            {this.language.InTransit[this.state.language]}
          </span>
        );
      case "EX":
        return (
          <span style={{ color: "#C70039" }}>
            {this.language.Exception[this.state.language]}
          </span>
        );
      case "DEL":
        return (
          <span style={{ color: "#52BE80" }}>
            {this.language.Delivered[this.state.language]}
          </span>
        );
      case "HFPU":
        return (
          <span style={{ color: "#4FC3F7" }}>
            {this.language.HFPU[this.state.language]}
          </span>
        );
      case "DP":
        return (
          <span style={{ color: "#4FC3F7" }}>
            {this.language.ScannedatSwiftpost[this.state.language]}
          </span>
        );
      case "RTS":
        return (
          <span style={{ color: "#C70039" }}>
            {this.language.ReturnToSender[this.state.language]}
          </span>
        );
      default:
        return <span style={{ color: "#4FC3F7" }}>In Transit</span>;
    }
  };

  ImagetoPrint = (source) => {
    //add QZ here
    return (
      "<html><head><style>@page { margin: 0; } @media print { html, body { width: 101.6mm; height: 151mm; } }</style>\n" +
      "<scri" +
      "pt>function step1(){\n" +
      "setTimeout('step2()', 10);}\n" +
      "function step2(){window.print();window.close()}\n" +
      "</scri" +
      "pt></head><body onload='step1()'>\n" +
      "<img src='" +
      source +
      "' style='height:100%' /></body></html>"
    );
  };

  PrintImage = (source) => {
    var Pagelink = "about:blank";
    var pwa = window.open(Pagelink, "_new");
    pwa.document.open();
    pwa.document.write(this.ImagetoPrint(source));
    pwa.document.close();
  };

  render() {
    const { classes } = this.props;

    // console.log("PROCESS=>",this.props.processQueue);
    // console.log("COMPLETED=>",this.props.completedQueue)

    return (
      <ListItem
        button
        onClick={async () => {
          this.props.shipmentStatus(
            this.getStatusMessage(this.props.shipment.sp_status)
          );
          this.props.setSelected({...this.props.shipment, image_url:this.props.shipment.image_url ? this.props.shipment.image_url : this.props.completedQueue.filter(
            (e) =>
              e.parent_id ===
              this.props.shipment.parent_id
          )[0].image_url[
            this.props.completedQueue
              .filter(
                (e) =>
                  e.parent_id ===
                  this.props.shipment.parent_id
              )[0]
              .ids.indexOf(this.props.shipment.id)
          ]});
          this.props.toggleModal();
        }}
        className={
          this.props.count % 2 === 0 ? classes.greybutton : classes.button
        }
      >
        <Avatar
          className={classes.avatar}
          src={
            this.props.shipment.receiver_country.toUpperCase() === "US"
              ? "https://images.swiftpost.com/usaflag.jpg"
              : "https://images.swiftpost.com/canflag.jpg"
          }
        />
        <ListItemText
          secondary={
            <React.Fragment>
              <Grid container spacing={0}>
                <Grid item xs={12} sm={6} md={6} lg={2}>
                  <div
                    style={{
                      verticalAlign: "middle",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <strong>{this.props.shipment.order_id}</strong>
                    {/* <Chip style={{height:'20px',marginLeft:'5px'}} label={`1 package`} className={classes.chip} /> */}
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={2}
                  style={{
                    verticalAlign: "middle",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    paddingRight: "5px",
                  }}
                >
                  <span>
                    <Fragment>
                      <Tooltip
                        title={`${this.props.shipment.service_courier.toUpperCase()} ${
                          this.props.shipment.service_name
                        }`}
                        placement="top"
                      >
                        <img
                          alt={"Courier Logo"}
                          src={`https://images.swiftpost.com/logo-${this.props.shipment.service_courier.toUpperCase()}.png`}
                          style={{ height: "14px", verticalAlign: "middle" }}
                        />
                      </Tooltip>
                      &nbsp;{this.props.shipment.service_name}
                    </Fragment>
                  </span>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={2}
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    paddingRight: "5px",
                  }}
                >
                  <strong>
                    {this.language.Receiver[this.state.language]}:
                  </strong>{" "}
                  {this.props.shipment.receiver_fname
                    ? `${this.props.shipment.receiver_fname.slice(0, 10)}
                  ${
                    this.props.shipment.receiver_fname.length > 10 ? "..." : ""
                  } 
                  ${this.props.shipment.receiver_lname.slice(0, 10)}
                  ${
                    this.props.shipment.receiver_fname.length > 10 ? "..." : ""
                  }`
                    : this.props.shipment.receiver_business}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={2}
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    paddingRight: "5px",
                  }}
                >
                  <strong>{this.language.Sender[this.state.language]}:</strong>{" "}
                  {this.props.shipment.sender_business}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={2}
                  style={{
                    verticalAlign: "middle",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    paddingRight: "5px",
                  }}
                >
                  <span>
                    <strong>
                      {this.getStatusMessage(this.props.shipment.sp_status)}
                    </strong>
                  </span>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={1}
                  style={{
                    verticalAlign: "middle",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    paddingRight: "5px",
                  }}
                >
                  <span>
                    <strong>
                      {new Date(
                        this.props.shipment.date_created
                      ).toLocaleDateString()}
                    </strong>
                  </span>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={1}
                  style={{ "text-align": "right" }}
                >
                  <span>
                    <strong>
                      {this.props.processQueue.indexOf(
                        this.props.shipment.parent_id
                      ) > -1 ? (
                        <center>
                          <LoadingIcon size={20} />
                        </center>
                      ) : this.props.shipment.image_url === "" ? (
                        this.props.completedQueue
                          .map((e) => e.parent_id)
                          .indexOf(this.props.shipment.parent_id) > -1 ? (
                          <Button
                            style={{
                              // height:'10px',
                              padding: "1px 6px",
                            }}
                            variant="outlined"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (
                                window.qz.websocket.isActive() &&
                                this.props.settings &&
                                this.props.settings.printer &&
                                this.props.settings.printer !== "none"
                              ) {
                                // alert('trying to print');
                                window.doPrint(
                                  this.props.shipment.image_url,
                                  this.props.settings.printer
                                );
                              } else {
                                this.PrintImage(
                                  this.props.completedQueue.filter(
                                    (e) =>
                                      e.parent_id ===
                                      this.props.shipment.parent_id
                                  )[0].image_url[
                                    this.props.completedQueue
                                      .filter(
                                        (e) =>
                                          e.parent_id ===
                                          this.props.shipment.parent_id
                                      )[0]
                                      .ids.indexOf(this.props.shipment.id)
                                  ]
                                );
                              }
                              // window.doPrint(this.props.shipment.image_url, this.props.qzPrinter);
                            }}
                            size="small"
                            color="primary"
                          >
                            <PrintOutlined style={{ height: "16px" }} />
                            {this.language.Print[this.state.language]}
                          </Button>
                        ) : (
                          <center>
                            <LoadingIcon size={20} />
                          </center>
                        )
                      ) : (
                        // <Button style={{
                        //   // height:'10px',
                        //   padding: '1px 6px'
                        // }} variant="outlined" href={`loop://${this.props.shipment.image_url}`} size="small" color="primary"><PrintOutlined
                        //     style={{ height: '16px' }} />
                        //   {this.language.Print[this.state.language]}
                        // </Button>
                        <Button
                          style={{
                            // height:'10px',
                            padding: "1px 6px",
                          }}
                          variant="outlined"
                          onClick={(e) => {
                            e.stopPropagation();
                            // alert(this.props.settings.printer)
                            if (
                              window.qz.websocket.isActive() &&
                              this.props.settings &&
                              this.props.settings.printer &&
                              this.props.settings.printer !== "none"
                            ) {
                              // alert('trying to print');

                              window.doPrint(
                                this.props.shipment.image_url,
                                this.props.settings.printer
                              );
                            } else {
                              this.PrintImage(this.props.shipment.image_url);
                            }
                            // window.doPrint(this.props.shipment.image_url);
                            // console.log(window.cfg)
                            // window.qz.print(window.cfg, printData).catch(e => console.log(e));
                          }}
                          size="small"
                          color="primary"
                        >
                          <PrintOutlined style={{ height: "16px" }} />
                          {this.language.Print[this.state.language]}
                        </Button>
                      )}
                    </strong>
                  </span>
                </Grid>
              </Grid>
            </React.Fragment>
          }
        />
      </ListItem>
    );
  }
}

ShipmentRow.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ShipmentRow);
