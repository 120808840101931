const serviceConstaints = [
  {
    name: "Ground",
    rate_type: "1",
    max_length: 108,
    max_width: 108,
    max_height: 108,
    max_weight: 150,
    delivery_min: 1,
    delivery_ground: 3,
    delivery_air: 5,
    additional_test1: {
      max: 165,
      multiplier: 2,
    },
  },
  // {
  //     name: "Select Letter",
  //     rate_type: "3",
  //     max_length:0,
  //     max_width:0,
  //     max_height:0,
  //     max_weight:70,
  //     delivery_ground: 3,
  //     delivery_air: 4,
  // },
  // {
  //     name: "Select Pak",
  //     rate_type: "4",
  //     max_length:0,
  //     max_width:0,
  //     max_height:0,
  //     max_weight:70,
  //     delivery_ground: 3,
  //     delivery_air: 4,
  // },
  // {
  //     name: "Select",
  //     rate_type: "5",
  //     max_length:108,
  //     max_width:108,
  //     max_height:108,
  //     max_weight:150,
  //     delivery_ground: 1,
  //     delivery_air: 2,
  //     additional_test1: {
  //         max: 165,
  //         multiplier: 2,
  //     },
  // },
  // {
  //     name: "Overnight Letter",
  //     rate_type: "C",
  //     max_length:0,
  //     max_width:0,
  //     max_height:0,
  //     max_weight:20,
  //     delivery_ground: 3,
  //     delivery_air: 0,
  // },
  // {
  //     name: "Overnight Pak",
  //     rate_type: "D",
  //     max_length:18,
  //     max_width:0,
  //     max_height:0,
  //     max_weight:20,
  //     delivery_ground: 3,
  //     delivery_air: 0,
  // },
  // {
  //     name: "Overnight",
  //     rate_type: "E",
  //     max_length:108,
  //     max_width:108,
  //     max_height:108,
  //     max_weight:150,
  //     delivery_ground: 1,
  //     delivery_air: 1,
  //     additional_test1: {
  //         max: 165,
  //         multiplier: 2,
  //     },
  // },
];

const additionalTest1 = (input, service) => {
  return "additional_test1" in service
    ? parseInt(parseFloat(input.length)) +
        parseInt(
          service.additional_test1.multiplier * parseFloat(input.width)
        ) +
        parseInt(
          service.additional_test1.multiplier * parseFloat(input.height)
        ) <=
        service.additional_test1.max
    : true;
};

const weightTest = (input, service) => {
  return service.max_weight
    ? parseFloat(input.weight) <= service.max_weight
    : true;
};

const dimensionTest = (input, service) => {
  return (
    (service.max_length
      ? parseFloat(input.length) <= service.max_length
      : true) &&
    (service.max_width ? parseFloat(input.width) <= service.max_width : true) &&
    (service.max_height ? parseFloat(input.height) <= service.max_height : true)
  );
};

const resolver = (input) => {
  // console.log(input)
  if (input.package.count == 1) {
    return serviceConstaints.map((service) => {
      return {
        service: service,
        qualify:
          dimensionTest(input.package.dimensions[0], service) &&
          weightTest(input.package.dimensions[0], service) &&
          additionalTest1(input.package.dimensions[0], service),
        dimensionTest: dimensionTest(input.package.dimensions[0], service),
        weightTest: weightTest(input.package.dimensions[0], service),
        additionalTest1: additionalTest1(input.package.dimensions[0], service),
      };
    });
  } else {
    let response = serviceConstaints.map((service) => {
      let test = input.package.dimensions.map((inp) => {
        return {
          qualify:
            dimensionTest(inp, service) &&
            weightTest(inp, service) &&
            additionalTest1(inp, service),
        };
      });

      return test.filter((e) => e.qualify === false).length === 0
        ? { service: service, qualify: true }
        : {
            qualify: false,
            service: service,
          };
    });
    // console.log('response',response)
    return response;
  }
};

export default resolver;
