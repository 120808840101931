import Button from "@material-ui/core/Button";
import CheckBox from "@material-ui/core/Checkbox";
import Collapse from "@material-ui/core/Collapse";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Step from "@material-ui/core/Step";
import StepContent from "@material-ui/core/StepContent";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { API } from "aws-amplify";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import resolver from "../../helpers/resolverCA";
import { isNotEmpty } from "../../helpers/Validation";
import LoadingIcon from "../loading/LoadingIcon";
import PackageMeasurements from "../PackageMeasurementsFulfillment";
import { ImCheckboxUnchecked, ImCheckboxChecked } from "react-icons/im";
import FulfillmentTypes from "./fulfillment_types";
import { Auth } from "aws-amplify";

const styles = (theme) => ({
  textField: {
    width: "100%",
    boxSizing: "border-box",
    fontSize: "1.5em",
  },
  menu: {
    width: 200,
  },
  formControl: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2,
  },
  resetContainer: {
    padding: theme.spacing.unit * 3,
  },
});

export const accessorials = [
  {
    interactable: true,
    readable: "Dunnage",
    post: "dunnage",
    code: "dunnage",
    description: "Removal and disposal of pallet packaging",
  },
  {
    interactable: true,
    readable: "Handling",
    post: "handling",
    code: "handling",
    description: "Over 30 minutes inside location required",
  },
  {
    interactable: true,
    readable: "Inside",
    pre: "Inside",
    code: "inside",
    description: "Entering location for pickup/dropoff, by weight",
  },
  {
    interactable: true,
    readable: "Liftgate",
    post: "liftgate",
    code: "liftgate",
    description: "Use of truck liftgate, no dock",
  },
  {
    interactable: true,
    readable: "Residential",
    pre: "Residential",
    code: "residential",
    description: "Non-business destination",
  },
  // {
  //   interactable: false,
  //   readable: "After hours",
  //   pre: "After hours",
  //   code: "after_hours",
  //   description: "Deliveries or pickups that have a window exceeding 6PM",
  // },
];

const ToggleAccessorial = ({
  setActive,
  active,
  accessorial,
  type,
  interactable,
}) => {
  return (
    <Grid
      item
      xs={6}
      onClick={() => (interactable ? setActive(accessorial.code) : {})}
      style={{ cursor: "pointer" }}
    >
      <Grid container spacing={0}>
        {/* <div onClick={() => setActive(accessorial.code)}> */}
        <Grid item xs={1} style={{ paddingTop: 5 }}>
          {active ? (
            <ImCheckboxChecked
              style={{
                color: interactable ? "#33CC33" : "#9E9E9E",
                fontSize: "1.1em",
              }}
            />
          ) : (
            <ImCheckboxUnchecked
              style={{ color: "#051E51", fontSize: "1.1em" }}
            />
          )}{" "}
        </Grid>
        <Grid item xs={11}>
          <span style={{ color: "#051E51", fontSize: "1.1em" }}>
            {accessorial.pre
              ? accessorial.pre + " " + type.toLowerCase()
              : type + " " + accessorial.post}
          </span>
          <br />
          <span style={{ color: "#9E9E9E" }}>{accessorial.description}</span>
        </Grid>
      </Grid>
      {/* </div> */}
    </Grid>
  );
};

class VerticalLinearStepper extends React.Component {
  state = {
    activeStep: 0,
    isLoading: false,
    connection_id: "",
  };

  //   getSteps = () => {
  //     return ["Package Type", "Package measurements"]; // 'Package contents'];
  //   };

  getSteps = () => {
    return [this.language.PackageMeasurements[this.state.language]];
  };

  componentDidMount = async () => {
    // alert('socket')
    const socket2 = new WebSocket(
      "wss://keuhz93lxj.execute-api.us-east-1.amazonaws.com/dev"
    );

    socket2.addEventListener("open", function (event) {
      socket2.send(JSON.stringify({ action: "sendMessage" }));
      // alert('connected')
    });

    socket2.addEventListener("message", (event) => {
      // alert('socket msg');
      console.log(JSON.parse(event.data));
      let data = JSON.parse(event.data);
      if (data.connectionId) {
        // alert(data.connectionId)
        this.setState({ connection_id: data.connectionId });
        // console.log("CONNECTED, ",data.connectionId)
      }
      if (data.rate) {
        if (data.rate.length) {
          this.props.setRates(
            "rates",
            data.rate.filter((f) => {
              return f.rate != 0;
            })
          );
          this.props.handleNext("activeStep");
        } else {
          this.setState({ rateError: true });
          this.setState({ isLoading: false });
        }
      }
    });
  };

  UNSAFE_componentWillMount = async () => {
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });
  };

  language = {
    PackageType: {
      FR: "Type d'emballage",
      EN: "Package Type",
    },
    PackageMeasurements: {
      FR: "Mesures de l'emballage",
      EN: "Package details",
    },
    PackageContents: {
      FR: "Contenu du colis",
      EN: "Package Contents",
    },
    CountryofOrigin: {
      FR: "Pays d'origine",
      EN: "Country of Origin",
    },
    Totalitemquantity: {
      FR: "Quantité totale d'articles",
      EN: "Total item quantity",
    },
    TotalValueOfShipmentUSD: {
      FR: "Valeur totale de l'expédition (USD)",
      EN: "Total value of shipment (USD)",
    },
    MaximumOf800USDPerShipment: {
      FR: "Maximum de 800 USD par envoi",
      EN: "Maximum of $800 USD per shipment",
    },
    Optional: {
      FR: "En option",
      EN: "Optional",
    },
    inches: {
      FR: "pouces",
      EN: "inches",
    },
    pounds: {
      FR: "livre",
      EN: "pounds",
    },
    Package: {
      FR: "Paquet",
      EN: "Package",
    },
    Packages: {
      FR: "Paquets",
      EN: "Packages",
    },
    Qty: {
      FR: "Qté",
      EN: "Qty",
    },
    value: {
      FR: "valeur",
      EN: "value",
    },
    Next: {
      FR: "Suivant",
      EN: "Next",
    },
    Back: {
      FR: "Arrière",
      EN: "Back",
    },
    ConfirmAndContinue: {
      FR: "Confirmer et continuer",
      EN: "Confirm and Continue",
    },
    HarmonizedCodes: {
      FR: "Code harmonisé d'article",
      EN: "Item(s) Harmonized Code(s)",
    },
    ItemsDescription: {
      FR: "Description de l'article",
      EN: "Item(s) Description",
    },
    Edit: {
      FR: "ÉDITER",
      EN: "EDIT",
    },
  };

  getStepContent = (step) => {
    const { classes } = this.props;
    switch (step) {
      case 0:
        return (
          <Fragment>
            <PackageMeasurements
              sends_oversized={
                this.props.account.user.sends_oversized ? true : false
              }
              isFreight={this.props.account.user.canShipFreight ? true : false}
              country={"CA"}
              maxQuantity={this.props.account.user.canShipFreight ? 7 : 25}
              error={this.state.measurementError}
              {...this.props}
            />
          </Fragment>
        );

      default:
        return "Unknown step";
    }
  };

  checkDimensions = async () => {
    if (this.props.package.type === "tube") {
      this.props.setObjData("height", "package", this.props.package.width);
    }

    let response = await this.props.package.dimensions.map((e, i) => {
      if (i + 1 <= this.props.package.count) {
        if (parseFloat(e.height) < 0.001) {
          this.props.setDimensionChange("height", i, "0.001");
        } else {
          this.props.setDimensionChange(
            "height",
            i,
            parseFloat(e.height).toFixed(3).toString()
          );
        }
        if (parseFloat(e.length) < 0.001) {
          this.props.setDimensionChange("length", i, "0.001");
        } else {
          this.props.setDimensionChange(
            "length",
            i,
            parseFloat(e.length).toFixed(3).toString()
          );
        }
        if (parseFloat(e.width) < 0.001) {
          this.props.setDimensionChange("width", i, "0.001");
        } else {
          this.props.setDimensionChange(
            "width",
            i,
            parseFloat(e.width).toFixed(3).toString()
          );
        }
        if (parseFloat(e.weight) < 0.001) {
          this.props.setDimensionChange("weight", i, "0.1");
        } else {
          this.props.setDimensionChange(
            "weight",
            i,
            parseFloat(e.weight).toFixed(3).toString()
          );
        }
        return (
          (this.props.package.type !== "tube" ? isNotEmpty(e.height) : true) &&
          isNotEmpty(e["length"]) &&
          isNotEmpty(e.width) &&
          isNotEmpty(e.weight) &&
          isNotEmpty(e.item_description) &&
          isNotEmpty(e.item_quantity) &&
          isNotEmpty(e.item_value) &&
          e.item_quantity > 0 &&
          e.item_value > 0
        );
      } else {
        return true;
      }
    });

    !response.filter((e) => e === false).length
      ? this.setState({ measurementError: false })
      : this.setState({ measurementError: true });

    return !response.filter((e) => e === false).length;

    // if(this.props.package.type === "tube"){ this.props.setObjData('height','package',this.props.package.width) }

    // isNotEmpty(this.props.package.height) ? this.setState({heightError: false}) : this.setState({heightError: true})
    // isNotEmpty(this.props.package.width) ? this.setState({widthError: false}) : this.setState({widthError: true})
    // isNotEmpty(this.props.package.length) ? this.setState({lengthError: false}) : this.setState({lengthError: true})
    // isNotEmpty(this.props.package.weight) ? this.setState({weightError: false}) : this.setState({weightError: true})

    // return isNotEmpty(this.props.package.height) &&
    // isNotEmpty(this.props.package.length) &&
    // isNotEmpty(this.props.package.width) &&
    // isNotEmpty(this.props.package.weight)
  };

  checkDescription = () => {
    if (this.props.package.insured) {
      isNotEmpty(this.props.package.description)
        ? this.setState({ descriptionError: false })
        : this.setState({ descriptionError: true });
      isNotEmpty(this.props.package.quantity) && this.props.package.quantity > 1
        ? this.setState({ quantityError: false })
        : this.setState({ quantityError: true });
      isNotEmpty(this.props.package.value) && this.props.package.value < 800
        ? this.setState({ valueError: false })
        : this.setState({ valueError: true });

      return (
        isNotEmpty(this.props.package.description) &&
        isNotEmpty(this.props.package.quantity) &&
        isNotEmpty(this.props.package.value) &&
        this.props.package.value < 800
      );
    }
    return true;
  };

  handleNext = async () => {
    if (this.props.twoStep == 0) {
      // this.checkDimensions().then((e) =>
      //   e ?
      this.props.handleNext("twoStep");
      //   : false
      // );
    } else if (this.props.twoStep == 1) {
      this.setState({ isLoading: true });

      let sample = this.props.locations.map((e, i) =>
        e.id === this.props.senderLocationID ? i : false
      );
      sample = sample.filter((e) => e !== false);
      sample = sample[0];
      let test = await resolver(this.props);

      var services_reduced = test.filter((service) => {
        return service.qualify;
      });

      const dimensions = [];

      this.props.package.dimensions.map((pkg) => {
        Array(pkg.quantity)
          .fill(0)
          .map((count) => {
            dimensions.push({
              ...FulfillmentTypes.filter(
                (type) => pkg.fulfillment_type === type.code
              )[0].measurements,
              ...pkg,
            });
          });
      });

      let email = await Auth.currentSession();
      email = email.idToken.payload.email;

      let body = {
        sender: {
          accessorials: this.props.sender_accessorials,
          ...this.props.locations[sample],
        },
        package: {
          ...this.props.package,
          dimensions,
        },
        receiver: {
          accessorials: this.props.receiver_accessorials,
          ...this.props.receiver,
        },
        services: services_reduced,
        connection_id: this.state.connection_id,
        cognitoUsername: this.props.account.user.userId,
        contents_type: this.props.contents_type,
        mode: "fulfillment",
        email,
      };
      console.log(body);
      if (this.props.account.user.ics_api) {
        body.ics_api = this.props.account.user.ics_api;
      }
      if (this.props.account.user.ics_rates) {
        body.ics_rates = this.props.account.user.ics_rates;
      }
      if (this.props.account.user.canpar_api) {
        body.canpar_api = this.props.account.user.canpar_api;
      }
      if (this.props.account.user.canpar_rates) {
        body.canpar_rates = this.props.account.user.canpar_rates;
      }
      this.setState({ rateError: false });
      API.post("sqs", "/sqs-rate", {
        body,
      });
    } else {
      this.props.handleNext("twoStep");
    }
  };

  renderPackages(arr) {
    if (arr.length > 1) {
      return [{}].concat(arr).map((note, i) => {
        if (i !== 0) {
          return (
            <Fragment>
              <strong>Fulfillment {i}:</strong>{" "}
              {
                FulfillmentTypes.filter(
                  (type) => type.code === note.fulfillment_type
                )[0].name
              }{" "}
              x {note.quantity}
              <br />
            </Fragment>
          );
        }
      });
    } else {
      return (
        <Fragment>
          <strong>Fulfillment 1:</strong>{" "}
          {
            FulfillmentTypes.filter(
              (type) => type.code === arr[0].fulfillment_type
            )[0].name
          }{" "}
          x {arr[0].quantity}
        </Fragment>
      );
    }
  }

  render() {
    const { classes } = this.props;
    const steps = this.getSteps();
    const activeStep = this.props.twoStep;

    return (
      <div>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                <StepContent>
                  <Typography>{this.getStepContent(index)}</Typography>
                  <div className={classes.actionsContainer}>
                    <div>
                      {activeStep === 0 ? (
                        <Button
                          onClick={() => this.props.handleBack("activeStep")}
                          className={classes.button}
                        >
                          {this.language.Back[this.state.language]}
                        </Button>
                      ) : (
                        <Button
                          onClick={() => this.props.handleBack("twoStep")}
                          className={classes.button}
                        >
                          {this.language.Back[this.state.language]}
                        </Button>
                      )}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          this.handleNext();
                        }}
                        className={classes.button}
                      >
                        {this.language.Next[this.state.language]}
                      </Button>
                      <Typography variant="subheading">
                        {this.state.errorMsg}
                      </Typography>
                    </div>
                  </div>
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
        {activeStep == steps.length && (
          <Paper square elevation={0} className={classes.resetContainer}>
            <Typography variant="subheading">
              {this.renderPackages(this.props.package.dimensions)}
              <br />
              <br />
              <Grid container spacing={24}>
                {this.props.sender_accessorials?.length ? (
                  <Grid item xs={4}>
                    <strong>Pickup considerations:</strong>
                    <br />
                    {this.props.sender_accessorials.map((f) => (
                      <>
                        {accessorials.filter((g) => g.code === f)[0].readable}
                        <br />
                      </>
                    ))}
                  </Grid>
                ) : (
                  <></>
                )}
                {this.props.receiver_accessorials.length ? (
                  <Grid item xs={6}>
                    <strong>Dropoff considerations:</strong>
                    <br />
                    {this.props.receiver_accessorials.map((f) => (
                      <>
                        {accessorials.filter((g) => g.code === f)[0].readable}
                        <br />
                      </>
                    ))}
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
              {this.props.package.insured === "true" && (
                <Fragment>
                  {this.props.package.description},{" "}
                  {this.language.Qty[this.state.language]}{" "}
                  {this.props.package.quantity}
                  <br />${this.props.package.value}{" "}
                  {this.language.Value[this.state.language]}
                </Fragment>
              )}
            </Typography>
            <Collapse
              in={this.state.rateError}
              style={{
                margin: "1rem 0",
                padding: this.state.rateError ? ".75rem 1.25rem" : "0",
                color: "#721c24",
                borderRadius: ".25rem",
                background: "#f8d7da",
              }}
            >
              There are no services available for shipping from{" "}
              <strong>
                {
                  this.props.locations.filter(
                    (location) => location.id === this.props.senderLocationID
                  )[0].address.postal
                }
              </strong>{" "}
              to <strong>{this.props.receiver.zip}</strong>
            </Collapse>
            <Button
              disabled={this.state.isLoading}
              onClick={() => {
                this.setState({ rateError: false });
                this.props.handleBack("twoStep");
              }}
              className={classes.button}
            >
              {this.language.Edit[this.state.language]}
            </Button>
            <Button
              disabled={this.state.isLoading || this.state.connection_id === ""}
              onClick={() => this.handleNext()}
              className={classes.button}
              variant="contained"
              color="primary"
            >
              {!this.state.isLoading && this.state.connection_id !== "" ? (
                this.language.ConfirmAndContinue[this.state.language]
              ) : (
                <LoadingIcon size={20} />
              )}
            </Button>
          </Paper>
        )}
      </div>
    );
  }
}

VerticalLinearStepper.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(VerticalLinearStepper);
