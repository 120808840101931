import { Button, Divider, Grid } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import { Component } from "react";
import ConnectionCard from "../components/connectionCard";
import EcommerceHelper from "../components/EcommerceHelper";
import EtsyLogo from "../img/etsy_logo.png";
import WoocommerceLogo from "../img/woocommerce_logo.png";
import "../styles.css";
import ShopifyModal from "./ShopifyModal";
import WoocommerceModal from "./WoocommerceModal";

const styles = (theme) => ({
  // card: {
  //   maxWidth: 345,
  // },
  // media: {
  //   height: 0,
  //   paddingTop: '56.25%', // 16:9
  // },
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  card: {
    maxWidth: 345,
    padding: 30,
    boxShadow: "none",
  },
  shopify: {
    display: "inline-block",
    position: "relative",
    borderRadius: "50%",
    margin: "10px",
    marginBottom: "10px",
    background: "#7fc140",
    [theme.breakpoints.down("md")]: {
      transform: "scale(.8)",
    },
    minWidth: 200,
    minHeight: 200, // },
    maxWidth: 200,
    maxHeight: 200,
    padding: 30,
  },
  etsy: {
    display: "inline-block",
    position: "relative",
    borderRadius: "50%",
    background: "#F1641E",
    margin: "10px",
    [theme.breakpoints.down("md")]: {
      transform: "scale(.8)",
    },
    minWidth: 200,
    minHeight: 200,
    maxWidth: 200,
    maxHeight: 200,
    // },
    padding: 30,
  },
  woocommerce: {
    display: "inline-block",
    position: "relative",
    borderRadius: "50%",
    margin: "10px",
    marginBottom: "10px",
    background: "#9a5c8f",
    [theme.breakpoints.down("md")]: {
      transform: "scale(.8)",
    },
    minWidth: 200,
    minHeight: 200, // },
    maxWidth: 200,
    maxHeight: 200,
    padding: 30,
  },
  media: {
    height: 140,
  },
  newConnection: false,
});

class Connections extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      isEditing: false,
      editData: {},
      new_window: "",
      shopifyModal: false,
      fulfillment: "",
    };
  }
  UNSAFE_componentWillMount = () => {
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });
  };

  language = {
    EcommerceConnections: {
      FR: "Les Connections eCommerce",
      EN: "E-Commerce Connections",
    },
    ConnectShop: {
      FR: "Connectez",
      EN: <>Connect&nbsp;Shop</>,
    },
    ConnectedShops: {
      FR: "Les Boutiques Connectées",
      EN: "Connected Shops",
    },
    EtsyDisclaimer: {
      FR: "Le terme «Etsy» est une marque commerciale d'Etsy, Inc. Cette application utilise l'API Etsy mais n'est ni approuvée ni certifiée par Etsy, Inc.",
      EN: "The term 'Etsy' is a trademark of Etsy, Inc. This application uses the Etsy API but is not endorsed or certified by Etsy, Inc.",
    },
  };

  componentDidMount = () => {
    this.props.socket.addEventListener("message", (event) => {
      let data = JSON.parse(event.data);
      console.log(data);
      if (data.etsyConnected == true) {
        this.setState({ newConnection: true, platform: "ETSY" });
        try {
          this.state.new_window.close();
        } catch (e) {}
      } else if (data.shopifyConnected == true) {
        this.setState({
          newConnection: true,
          platform: "SHOPIFY",
          fulfillment: data.fulfillment_location,
        });
        try {
          this.state.new_window.close();
        } catch (e) {}
      } else if (data.woocommerceConnected == true) {
        this.setState({ newConnection: true, platform: "WOOCOMMERCE" });
        try {
          this.state.new_window.close();
        } catch (e) {}
      }
    });
  };

  componentWillUnmount = () => {
    this.openShopifyWindow = null;
    this.openEtsyWindow = null;
    this.handleModalOpen = null;
    this.handleModalClose = null;
    this.editLocation = null;
    this.props.socket.removeEventListener("message", (event) => {
      let data = JSON.parse(event.data);
      console.log(data);
      if (data.etsyConnected == true) {
        this.setState({ newConnection: true, platform: "ETSY" });
        try {
          this.state.new_window.close();
        } catch (e) {}
      } else if (data.shopifyConnected == true) {
        this.setState({
          newConnection: true,
          platform: "SHOPIFY",
          fulfillment: data.fulfillment_location,
        });
        try {
          this.state.new_window.close();
        } catch (e) {}
      } else if (data.woocommerceConnected == true) {
        this.setState({ newConnection: true, platform: "WOOCOMMERCE" });
        try {
          this.state.new_window.close();
        } catch (e) {}
      }
    });
  };

  openWoocommerceWindow = (shop) => {
    this.setState({ woocommerceModal: false });
    this.setState({
      new_window: window.open(
        `https://zigxc78kck.execute-api.us-east-1.amazonaws.com/prod/woocomm/connect?&store=${shop}&client_id=${this.props.account.cognitoUsername}&connection_id=${this.props.socket_id}`,
        "targetWindow",
        `toolbar=no,
    location=no,
    status=no,
    menubar=no,
    scrollbars=yes,
    resizable=yes,
    width=800,
    height=800`
      ),
    });
  };

  openShopifyWindow = (shop) => {
    this.setState({ shopifyModal: false });
    this.setState({
      new_window: window.open(
        `https://67xrrpsg9j.execute-api.us-east-1.amazonaws.com/prod/shopify/request_token?client_id=${this.props.account.cognitoUsername}&store=${shop}&connection_id=${this.props.socket_id}`,
        "targetWindow",
        `toolbar=no,
    location=no,
    status=no,
    menubar=no,
    scrollbars=yes,
    resizable=yes,
    width=800,
    height=800`
      ),
    });
  };

  openEtsyWindow = () => {
    this.setState({
      new_window: window.open(
        `https://qh5u8akx3k.execute-api.us-east-1.amazonaws.com/dev/request_token?client_id=${
          this.props.account.cognitoUsername
        }&state=${new Date().getTime()}&connection_id=${this.props.socket_id}`,
        "targetWindow",
        `toolbar=no,
    location=no,
    status=no,
    menubar=no,
    scrollbars=yes,
    resizable=yes,
    width=800,
    height=800`
      ),
    });
  };

  handleModalOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleModalClose = () => {
    this.setState({ modalOpen: false });
    this.setState({ isEditing: false });
  };

  editLocation = (e) => {
    // console.log(e);
    this.setState({ isEditing: true });
    this.setState({ editData: e });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className="Home">
        {this.state.woocommerceModal && (
          <WoocommerceModal
            open={this.state.woocommerceModal}
            handleClose={() => {
              this.setState({ woocommerceModal: false });
            }}
            openShopify={(e) => {
              this.openWoocommerceWindow(e);
            }}
          />
        )}
        <ShopifyModal
          open={this.state.shopifyModal}
          handleClose={() => {
            this.setState({ shopifyModal: false });
          }}
          openShopify={(e) => {
            this.openShopifyWindow(e);
          }}
        />
        <EcommerceHelper
          open={this.state.newConnection}
          fulfillment={this.state.fulfillment}
          platform={this.state.platform}
          handleClose={() => {
            this.setState({ newConnection: false });
          }}
        />
        <div className="notes">
          <Typography variant="h1">
            {this.language.EcommerceConnections[this.state.language]}
          </Typography>
          <Divider />
          <br />
          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
              <Card className={classes.etsy}>
                <div
                  style={{
                    textAlign: "center",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                  }}
                >
                  <img src={EtsyLogo} style={{ height: "40px" }} />
                  <br />
                  <br />
                  <Button
                    variant="outlined"
                    small
                    style={{
                      fontSize: ".65em",
                      background: "#fff",
                      color: "#000",
                      fontWeight: "700",
                      boxShadow: "0px 5px 0px rgba(0,0,0,.2)",
                      opacity: 0.7,
                    }}
                    onClick={this.openEtsyWindow}
                  >
                    {this.language.ConnectShop[this.state.language]}
                  </Button>
                </div>
              </Card>

              <Card className={classes.shopify}>
                <div
                  style={{
                    textAlign: "center",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                  }}
                >
                  <img
                    src="https://images.swiftpost.com/ecommerce-integrations/shopify_logo_large.png"
                    style={{ height: "40px" }}
                  />
                  <br />
                  <br />
                  <Button
                    variant="contained"
                    small
                    style={{
                      fontSize: ".65em",
                      background: "#fff",
                      color: "#000",
                      fontWeight: "700",
                      boxShadow: "0px 5px 0px rgba(0,0,0,.2)",
                      opacity: 0.7,
                    }}
                    onClick={() => {
                      this.setState({ shopifyModal: true });
                    }}
                  >
                    {this.language.ConnectShop[this.state.language]}
                  </Button>
                </div>
              </Card>

              <Card className={classes.woocommerce}>
                <div
                  style={{
                    textAlign: "center",
                    position: "absolute",
                    top: "48%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                  }}
                >
                  <img
                    src={WoocommerceLogo}
                    style={{ height: "60px", marginBottom: "10px" }}
                  />
                  <br />
                  <Button
                    variant="contained"
                    small
                    style={{
                      fontSize: ".65em",
                      background: "#fff",
                      color: "#000",
                      fontWeight: "700",
                      boxShadow: "0px 5px 0px rgba(0,0,0,.2)",
                      opacity: 0.7,
                    }}
                    onClick={() => {
                      this.setState({ woocommerceModal: true });
                    }}
                  >
                    {this.language.ConnectShop[this.state.language]}
                  </Button>
                </div>
              </Card>
            </Grid>
          </Grid>
          <br />
          <br />
          <Divider />
          <br />
          <br />
          <Typography variant="h5" gutterBottom>
            {this.language.ConnectedShops[this.state.language]}
          </Typography>
          <br />

          <Grid container spacing={3}>
            {this.props.connections.map((e) => (
              <ConnectionCard data={e} />
            ))}
          </Grid>
          <div
            style={{
              position: "absolute",
              bottom: "0",
              color: "rgba(0,0,0,.35)",
              fontSize: ".8em",
              padding: "20px 10px",
            }}
          >
            {this.language.EtsyDisclaimer[this.state.language]}
          </div>
        </div>
      </div>
    );
  }
}

Connections.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Connections);
