import { API, graphqlOperation } from "aws-amplify";

export const createSettings = () =>
  API.graphql(
    graphqlOperation(`
      mutation createSettings {
        createSettings(input: {
          printer: "none"
          autoPrint: "false"
        }) {
          id
          cognitoUsername
        }
      }
    `)
  );
