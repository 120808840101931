import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import MobileStepper from "@material-ui/core/MobileStepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { withStyles } from "@material-ui/core/styles";
import { API, Auth, graphqlOperation } from "aws-amplify";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import UnconfirmedAddress from "../../dialogs/UnconfirmedAddress";
import resolver from "../../helpers/resolverCA";
import {
  isAlphaNumeric,
  isAlphaNumericWithApostrophe,
  isAlphaNumericWithSpace,
  isAlphaWithSpace,
  isDollarValue,
  isMeasurement,
  isNumericOnly,
  isValidPostal,
} from "../../helpers/Validation";
import SnackBar from "../Snackbar";
import CANStep1 from "./Step1";
import CANStep2 from "./Step2";
import CANStep3 from "./Step3";
import CANStep4 from "./Step4";
import CANStep5 from "./Step5";

const styles = (theme) => ({
  root: {
    background: "transparent",
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  dots: {
    margin: "auto",
  },
});

class HorizontalLabelPositionBelowStepper extends React.Component {
  state = {
    insured: false,
    insured_value: 0,
    rateError: false,
    phoneError: false,
    emailError: false,
    modalOpen: false,
    snackOpen: false,
    poBoxWarning: false,
    id: "",
    streetArr: [],
    activeStep: 0,
    oneStep: 0,
    twoStep: 0,
    receiver: {
      verified: false,
      business: "",
      fname: "",
      lname: "",
      attention: "",
      address: "",
      unit: "",
      city: "",
      state: "",
      zip: "",
      zip5: "",
      country: "CA",
      phone: "",
      email: "",
      street_name: "",
      street_number: "",
      is_residential: false,
    },
    package: {
      origin: "CA",
      ref: "",
      count: "1",
      description: "",
      quantity: "",
      value: "",
      insured: false,
      type:
        this.props.account &&
        this.props.account.user &&
        this.props.account.user.fixedPLD &&
        this.props.account.user.fixedPLD.type
          ? this.props.account.user.fixedPLD.type
          : "box",
      typeFixed:
        this.props.account &&
        this.props.account.user &&
        this.props.account.user.fixedPLD &&
        this.props.account.user.fixedPLD.type,
      dimensions: [
        {
          weight:
            this.props.account &&
            this.props.account.user &&
            this.props.account.user.fixedPLD
              ? this.props.account.user.fixedPLD.weight
              : "",
          height:
            this.props.account &&
            this.props.account.user &&
            this.props.account.user.fixedPLD
              ? this.props.account.user.fixedPLD.height
              : "",
          length:
            this.props.account &&
            this.props.account.user &&
            this.props.account.user.fixedPLD
              ? this.props.account.user.fixedPLD["length"]
              : "",
          width:
            this.props.account &&
            this.props.account.user &&
            this.props.account.user.fixedPLD
              ? this.props.account.user.fixedPLD.width
              : "",
        },
      ],
    },
    senderLocationID: this.props.locations.length
      ? this.props.locations[0].id
      : 0,
    senderLocation: this.props.locations.length ? this.props.locations[0] : "",
    selectedService: 0,
    rates: [],
    children: [],
    childLocationID: 0,
    childLocation: "",
  };

  rates = [];

  getSteps = () => {
    let arr = [
      `${this.props.return === true ? `Sender` : `Receiver`} information`,
      `${this.props.return === true ? `Receiver` : `Sender`} information`,
      "Package details",
      "Shipping service",
      "Confirm and submit",
    ];
    if (
      this.props.account &&
      this.props.account.user &&
      this.props.account.user.resolver
    ) {
      arr.splice(3, 1);
    }
    return arr;
  };

  handleChange = (name) => (event) => {
    this.setObjData(name, "receiver", event.value);
  };

  UNSAFE_componentWillMount = () =>
    new Promise(async (resolve, reject) => {
      var user = await Auth.user.username;
      if (user == undefined || user == "undefined" || user == null) {
        window.location.reload();
      }
      this.setState({ userName: user });

      // console.log(`query queryChildAccountsByIdCognitoUsernameIndex {
      //   queryChildAccountsByIdCognitoUsernameIndex(cognitoUsername:"${user}",first:500){
      //     items{
      //       id
      //       account_id
      //       location_id
      //       cognitoUsername
      //       email
      //       receiver
      //       fname
      //       lname
      //       business
      //       street_name
      //       street_number
      //       postal
      //       state
      //       status
      //       city
      //       child_sub
      //       phone
      //       unit
      //       country
      //     }
      //     nextToken
      //   }
      // }`)
      let somethingNewer = await API.graphql(
        graphqlOperation(`query queryChildAccountsByIdCognitoUsernameIndex {
              queryChildAccountsByIdCognitoUsernameIndex(cognitoUsername:"${user}",first:500){
                items{
                  id
                  account_id
                  location_id
                  cognitoUsername
                  email
                  receiver
                  fname
                  lname
                  business
                  street_name
                  street_number
                  postal
                  state
                  status
                  city
                  child_sub
                  phone
                  unit
                  country
                }
                nextToken
              }
            }`)
      );

      if (
        somethingNewer.data.queryChildAccountsByIdCognitoUsernameIndex.items.filter(
          (e) => (this.props.return == true ? e.status == 1 : true)
        ).length > 0
      ) {
        this.setState({
          children:
            somethingNewer.data.queryChildAccountsByIdCognitoUsernameIndex.items.filter(
              (e) => (this.props.return == true ? e.status == 1 : true)
            ),
          childLocation:
            somethingNewer.data.queryChildAccountsByIdCognitoUsernameIndex.items
              .length > 0
              ? somethingNewer.data.queryChildAccountsByIdCognitoUsernameIndex.items.filter(
                  (e) => (this.props.return == true ? e.status == 1 : true)
                )[0]
              : "",
          childLocationID:
            somethingNewer.data.queryChildAccountsByIdCognitoUsernameIndex.items
              .length > 0
              ? somethingNewer.data.queryChildAccountsByIdCognitoUsernameIndex.items.filter(
                  (e) => (this.props.return == true ? e.status == 1 : true)
                )[0].id
              : 0,
          receiver: {
            verified: false,
            business:
              somethingNewer.data.queryChildAccountsByIdCognitoUsernameIndex.items.filter(
                (e) => (this.props.return == true ? e.status == 1 : true)
              )[0].business,
            fname:
              somethingNewer.data.queryChildAccountsByIdCognitoUsernameIndex.items.filter(
                (e) => (this.props.return == true ? e.status == 1 : true)
              )[0].fname,
            lname:
              somethingNewer.data.queryChildAccountsByIdCognitoUsernameIndex.items.filter(
                (e) => (this.props.return == true ? e.status == 1 : true)
              )[0].lname,
            attention: `${
              somethingNewer.data.queryChildAccountsByIdCognitoUsernameIndex.items.filter(
                (e) => (this.props.return == true ? e.status == 1 : true)
              )[0].fname
            } ${
              somethingNewer.data.queryChildAccountsByIdCognitoUsernameIndex.items.filter(
                (e) => (this.props.return == true ? e.status == 1 : true)
              )[0].lname
            }`,
            address: `${
              somethingNewer.data.queryChildAccountsByIdCognitoUsernameIndex.items.filter(
                (e) => (this.props.return == true ? e.status == 1 : true)
              )[0].street_number
            } ${
              somethingNewer.data.queryChildAccountsByIdCognitoUsernameIndex.items.filter(
                (e) => (this.props.return == true ? e.status == 1 : true)
              )[0].street_name
            }`,
            unit: somethingNewer.data.queryChildAccountsByIdCognitoUsernameIndex.items.filter(
              (e) => (this.props.return == true ? e.status == 1 : true)
            )[0].unit,
            city: somethingNewer.data.queryChildAccountsByIdCognitoUsernameIndex.items.filter(
              (e) => (this.props.return == true ? e.status == 1 : true)
            )[0].city,
            state:
              somethingNewer.data.queryChildAccountsByIdCognitoUsernameIndex.items.filter(
                (e) => (this.props.return == true ? e.status == 1 : true)
              )[0].state,
            zip: somethingNewer.data.queryChildAccountsByIdCognitoUsernameIndex.items.filter(
              (e) => (this.props.return == true ? e.status == 1 : true)
            )[0].postal,
            zip5: "",
            country: "CA",
            phone:
              somethingNewer.data.queryChildAccountsByIdCognitoUsernameIndex.items.filter(
                (e) => (this.props.return == true ? e.status == 1 : true)
              )[0].phone,
            email:
              somethingNewer.data.queryChildAccountsByIdCognitoUsernameIndex.items.filter(
                (e) => (this.props.return == true ? e.status == 1 : true)
              )[0].email,
            street_name:
              somethingNewer.data.queryChildAccountsByIdCognitoUsernameIndex.items.filter(
                (e) => (this.props.return == true ? e.status == 1 : true)
              )[0].street_name,
            street_number:
              somethingNewer.data.queryChildAccountsByIdCognitoUsernameIndex.items.filter(
                (e) => (this.props.return == true ? e.status == 1 : true)
              )[0].street_number,
            is_residential: false,
          },
        });
      } else {
        this.setState({
          children: [],
          childLocation: "",
          childLocationID: 0,
        });
      }
    });

  handleObjChange = (name, objName) => (event) => {
    if (event.target.value.length < 30) {
      if (name === "email" || event.target.value === "") {
        let temp = this.state[objName];
        temp[name] = event.target.value;
        this.setState({ [objName]: temp });
      } else if (name === "fname" || name === "lname") {
        if (isAlphaWithSpace(event.target.value)) {
          let temp = this.state[objName];
          temp[name] = event.target.value;
          this.setState({ [objName]: temp });
        }
      } else if (name === "business") {
        if (isAlphaNumericWithApostrophe(event.target.value)) {
          let temp = this.state[objName];
          temp[name] = event.target.value;
          this.setState({ [objName]: temp });
        }
      } else if (name === "quantity") {
        if (isNumericOnly(event.target.value)) {
          let temp = this.state[objName];
          temp[name] = event.target.value;
          this.setState({ [objName]: temp });
          return;
        }
      } else if (name === "value") {
        if (isDollarValue(event.target.value)) {
          let temp = this.state[objName];
          temp[name] = event.target.value;
          this.setState({ [objName]: temp });
          return;
        }
      } else if (name === "street_name") {
        if (isAlphaNumericWithApostrophe(event.target.value)) {
          let temp = this.state[objName];
          temp[name] = event.target.value;
          this.setState({ [objName]: temp });
          return;
        }
      } else if (isAlphaNumericWithSpace(event.target.value)) {
        if (event.target.value === "") {
          let temp = this.state[objName];
          temp[name] = event.target.value;
          this.setState({ [objName]: temp });
        }
        if (name === "phone" && !isNumericOnly(event.target.value)) {
          return;
        }
        if (name === "zip" && !isAlphaNumeric(event.target.value)) {
          return;
        }
        let temp = this.state[objName];
        temp[name] = event.target.value;
        this.setState({ [objName]: temp });
      }
    }
  };

  adjustCount = async (val) => {
    let temp = this.state.package;
    temp.count = parseInt(temp.count) + parseInt(val);
    this.setState({ package: temp });
    if (val > 0) {
      temp.dimensions.push({
        weight:
          this.props.account &&
          this.props.account.user &&
          this.props.account.user.fixedPLD
            ? this.props.account.user.fixedPLD.weight
            : "",
        height:
          this.props.account &&
          this.props.account.user &&
          this.props.account.user.fixedPLD
            ? this.props.account.user.fixedPLD.height
            : "",
        length:
          this.props.account &&
          this.props.account.user &&
          this.props.account.user.fixedPLD
            ? this.props.account.user.fixedPLD["length"]
            : "",
        width:
          this.props.account &&
          this.props.account.user &&
          this.props.account.user.fixedPLD
            ? this.props.account.user.fixedPLD.width
            : "",
      });
    } else {
      temp.dimensions.pop();
    }
    this.setState({ package: temp });
  };

  handleDimensionChange = (i, name) => (event) => {
    if (name === "weight") {
      if (
        this.state.package.dimensions.reduce(
          (a, b) => Number(b.weight) + a,
          0
        ) +
          Number(event.target.value) >
        500
      ) {
        this.setState({ weightError: true });
        return;
      }
    }
    switch (name) {
      case "weight":
        if (Number(event.target.value) > 66) {
          return;
        } else {
          break;
        }
      case "length":
        if (Number(event.target.value) > 45) {
          return;
        } else {
          break;
        }
      case "width":
        if (Number(event.target.value) > 30) {
          return;
        } else {
          break;
        }
      case "height":
        if (Number(event.target.value) > 30) {
          return;
        } else {
          break;
        }
    }
    if (event.target.value === "" || isMeasurement(event.target.value)) {
      let copy = this.state.package;
      copy.dimensions[i][name] = event.target.value;
      this.setState({ package: copy });
    }
  };

  setDimensionChange = (name, i, val) => {
    if (isMeasurement(val)) {
      let copy = this.state.package;
      copy.dimensions[i][name] = val;
      this.setState({ package: copy });
    }
  };

  setData = (name, data) => {
    this.setState({
      [name]: data,
    });
  };

  setObjData = (name, objName, data) => {
    let temp = this.state[objName];
    temp[name] = data;
    this.setState({ [objName]: temp });
  };

  setRates = (name, value) => {
    this.setState({
      [name]: value,
    });
    this.rates = value;
  };

  toggleCheck = (name, objName) => (event) => {
    let temp = this.state[objName];
    temp[name] = event.target.checked;
    this.setState({ [objName]: temp });
  };

  createShipment = async () => {
    this.setState({ isLoading: true });
    await API.graphql(
      graphqlOperation(`mutation createShipment{
        createV1_Shipment(input:{
          ${
            this.props.return === true
              ? `shipForChild:"${this.props.return}"`
              : ``
          }
          date_created:"${new Date().toLocaleString()}"
          platform:"SWIFTPOST"
          sender_id:"${this.state.senderLocationID}"
          insured:"${this.state.insured}"
          rate:{
            ${
              this.rates[this.state.selectedService].accessorials &&
              this.rates[this.state.selectedService].accessorials.length > 0
                ? `accessorial_1_name:"${
                    this.rates[this.state.selectedService].accessorials[0].name
                  }"
              accessorial_1_charge:"${
                this.rates[this.state.selectedService].accessorials[0].charge
              }"`
                : ""
            }
            ${
              this.rates[this.state.selectedService].accessorials &&
              this.rates[this.state.selectedService].accessorials.length > 1
                ? `accessorial_2_name:"${
                    this.rates[this.state.selectedService].accessorials[1].name
                  }"
              accessorial_2_charge:"${
                this.rates[this.state.selectedService].accessorials[1].charge
              }"`
                : ""
            }
            ${
              this.rates[this.state.selectedService].accessorials &&
              this.rates[this.state.selectedService].accessorials.length > 2
                ? `accessorial_3_name:"${
                    this.rates[this.state.selectedService].accessorials[2].name
                  }"
              accessorial_3_charge:"${
                this.rates[this.state.selectedService].accessorials[2].charge
              }"`
                : ""
            }
            ${
              this.rates[this.state.selectedService].accessorials &&
              this.rates[this.state.selectedService].accessorials.length > 3
                ? `accessorial_4_name:"${
                    this.rates[this.state.selectedService].accessorials[3].name
                  }"
              accessorial_4_charge:"${
                this.rates[this.state.selectedService].accessorials[3].charge
              }"`
                : ""
            }
            amount:"${this.rates[this.state.selectedService].rate}"
            subtotal:"${this.rates[this.state.selectedService].subtotal}"
            rate_id:"${this.rates[this.state.selectedService].rate_id}"
            tax1_code:"${this.rates[this.state.selectedService].tax1_code}"
            ${
              this.rates[this.state.selectedService].tax2_code
                ? `tax2_code:"${
                    this.rates[this.state.selectedService].tax2_code
                  }"`
                : ``
            }
            tax1_rate:"${this.rates[this.state.selectedService].tax1_rate}"
            ${
              this.rates[this.state.selectedService].tax2_rate
                ? `tax2_rate:"${
                    this.rates[this.state.selectedService].tax2_rate
                  }"`
                : ``
            }
            tax1_amount:"${this.rates[this.state.selectedService].tax1_amount}"
            ${
              this.rates[this.state.selectedService].tax2_amount
                ? `tax2_amount:"${
                    this.rates[this.state.selectedService].tax2_amount
                  }"`
                : ``
            }
            residential_charge:"${
              this.rates[this.state.selectedService].residential_charge
            }"
            fuel_surcharge:"${
              this.rates[this.state.selectedService].fuel_surcharge
            }"
            rural_charge:"${
              this.rates[this.state.selectedService].rural_charge
            }"
            xc_charge:"${this.rates[this.state.selectedService].xc_charge}"
            service:{
              name: "${
                this.rates[this.state.selectedService].service.service.name
              }"
              courier_code: "${
                this.rates[this.state.selectedService].service.service.rate_type
              }"
              courier: "${
                this.rates[this.state.selectedService].service.service.courier
              }"
            }
          }
          ${this.props.return === true ? `sender` : `receiver`}:{
            ${
              this.state.receiver.business
                ? `title:"${this.state.receiver.business}"`
                : ``
            }
            address:{
              street_name:"${this.state.receiver.street_name}"
              street_number:"${this.state.receiver.street_number}"
              postal:"${this.state.receiver.zip}"
              state:"${this.state.receiver.state}"
              country:"CA"
              ${
                this.state.receiver.unit &&
                this.state.receiver.unit !== null &&
                this.state.receiver.unit !== "null" &&
                this.state.receiver.unit !== undefined &&
                this.state.receiver.unit !== "undefined"
                  ? `unit:"${this.state.receiver.unit}"`
                  : ``
              }
              city:"${this.state.receiver.city}"
            }
            contact: {
              fname:"${this.state.receiver.fname}"
              lname:"${this.state.receiver.lname}"
              ${
                this.state.receiver.email
                  ? `email:"${this.state.receiver.email}"`
                  : ``
              }
              ${
                this.state.receiver.phone
                  ? `phone:"${this.state.receiver.phone}"`
                  : ``
              }
            }
          }
          ${
            this.state.package.description
              ? `contents:{
            title: "${this.state.package.description}"
            item_quantity: "${this.state.package.quantity}"
            item_value: "${this.state.package.value}"
            origin: "${this.state.package.origin}"
          }`
              : ``
          }
          ${this.props.return === true ? `receiver` : `sender`}:{
            title:"${this.state.senderLocation.title}"
            address:{
              street_name:"${this.state.senderLocation.address.street_name}"
              street_number:"${this.state.senderLocation.address.street_number}"
              postal:"${this.state.senderLocation.address.postal}"
              state:"${this.state.senderLocation.address.state}"
              country:"${this.state.senderLocation.address.country}"
              ${
                this.state.senderLocation.address.unit &&
                this.state.senderLocation.address.unit !== null &&
                this.state.senderLocation.address.unit !== "null" &&
                this.state.senderLocation.address.unit !== undefined &&
                this.state.senderLocation.address.unit !== "undefined"
                  ? `unit:"${this.state.senderLocation.address.unit}"`
                  : ``
              }
              city:"${this.state.senderLocation.address.city}"
            }
            contact: {
              fname:"${this.state.senderLocation.contact.fname}"
              lname:"${this.state.senderLocation.contact.lname}"
              ${
                this.state.senderLocation.address.email ||
                this.state.senderLocation.address.email === null ||
                this.state.senderLocation.address.email === "null"
                  ? `email:"${this.state.senderLocation.contact.email}"`
                  : ``
              }
              ${
                this.state.senderLocation.contact.phone
                  ? `phone:"${this.state.senderLocation.contact.phone}"`
                  : ``
              }
            }
          }
          package: {
            entered_pld:[${this.state.package.dimensions
              .map((e) => {
                return `{
              width:"${e.width}"
              height:"${e.height}"
              length:"${e.length}"
              weight:"${e.weight}"
            }`;
              })
              .join(",")}]
          }
          ${
            this.state.package.ref
              ? `reference:"${this.state.package.ref}"`
              : `reference:"${
                  this.props.return
                    ? `RETURN_${new Date().getTime()}`
                    : new Date().getTime()
                }"`
          }
        }){
          id
          cognitoUsername
        }
      }`)
    ).catch((e) => {
      // console.log(e)
      this.setState({ isLoading: false });
    });
    this.props.goToOrders();
  };

  handleZipChange = (name) => (event) => {
    if (isAlphaNumeric(event.target.value) || event.target.value === "") {
      this.setObjData(name, "receiver", event.target.value.toUpperCase());
      if (event.target.value === "") {
        this.setObjData("state", "receiver", "");
        this.setObjData("city", "receiver", "");
      }
      if (isValidPostal(event.target.value)) {
        this.setState({ isLoading: true });
        API.post("sqs", "/address/verify", {
          body: {
            postal: this.state.receiver.zip,
            country: "CA",
          },
        })
          .then((e) => {
            // alert(e.is_residential)
            // console.log(e);
            this.setObjData("is_residential", "receiver", e.is_residential);
            this.setState({ zipError: false });
            this.setState({ isLoading: false });
            this.setObjData("state", "receiver", e.state);
            this.setObjData("city", "receiver", e.city);
            this.setObjData("zip", "receiver", e.postal);
            this.setState({
              response: Array.from(new Set(e.street_name)).map((f) => {
                return { value: f, label: f };
              }),
              streetArr: Array.from(new Set(e.street_name)),
            });
            // this.setObjData(
            //   "street_name",
            //   "receiver",
            //   Array.from(new Set(e.street_name))[0]
            // );
            this.setState({ searchDone: true });
            this.handleStep1Next();
          })
          .catch((e) => {
            this.setState({ isLoading: false });
            this.setState({ zipError: true });
          });
      }
    }
  };

  handleStep1Next = () => {
    this.setState((state) => ({
      oneStep: state.oneStep + 1,
    }));
  };

  handleLocationChange = (e) => {
    // console.log(e)
    this.setState({ senderLocation: e.location });
    this.setState({ senderLocationID: e.id });
  };

  handleChildChange = (e) => {
    // console.log(e)

    this.setState({ childLocation: e.child });
    this.setState({ childLocationID: e.id });
    this.setState({
      receiver: {
        verified: false,
        business: e.child.business,
        fname: e.child.fname,
        lname: e.child.lname,
        attention: `${e.child.fname} ${e.child.lname}`,
        address: `${e.child.street_number} ${e.child.street_name}`,
        city: e.child.city,
        state: e.child.state,
        unit: e.child.unit,
        zip: e.child.postal,
        zip5: "",
        country: "CA",
        phone: e.child.phone,
        email: e.child.email,
        street_name: e.child.street_name,
        street_number: e.child.street_number,
        is_residential: false,
      },
    });
  };

  getStepContent = (stepIndex) => {
    const { classes, ...childProps } = this.props;

    // console.log(this.props);

    let arr = [
      <CANStep1
        {...this.state}
        account={this.props.account}
        return={this.props.return}
        childLocationID={this.state.childLocationID}
        childLocation={this.state.childLocation}
        handleChildChange={this.handleChildChange}
        children={this.state.children}
        streetArr={this.state.streetArr}
        handleNext={(e) => this.handleNext(e)}
        handleBack={(e) => this.handleBack(e)}
        activeStep={this.state.oneStep}
        advance={() => this.handleNext("activeStep")}
        handleStep1Next={this.handleStep1Next}
        handleZipChange={(zip) => this.handleZipChange(zip)}
        handleChange={(name) => this.handleChange(name)}
        setData={(name, data) => this.setData(name, data)}
        setObjData={(name, objName, data) =>
          this.setObjData(name, objName, data)
        }
        handleObjChange={(name, objName) => this.handleObjChange(name, objName)}
      />,
      <CANStep2
        return={this.props.return}
        account={this.props.account}
        handleLocationChange={(e) => this.handleLocationChange(e)}
        handleBack={(e) => {
          this.handleBack(e);
        }}
        handleNext={(e) => {
          this.handleNext(e);
        }}
        locations={this.props.locations}
        {...this.state}
        {...childProps}
      />,
      <CANStep3
        return={this.props.return}
        account={this.props.account}
        setData={(name, data) => this.setData(name, data)}
        activeStep={this.state.twoStep}
        handleChange={(name) => this.handleChange(name)}
        handleBack={(e) => {
          this.handleBack(e);
        }}
        handleNext={(e) => {
          this.handleNext(e);
        }}
        setRates={(e, i) => {
          this.setRates(e, i);
        }}
        setObjData={(name, objName, data) =>
          this.setObjData(name, objName, data)
        }
        handleObjChange={(name, objName) => this.handleObjChange(name, objName)}
        sender={this.state.senderLocation}
        toggleCheck={(name, objName) => {
          this.toggleCheck(name, objName);
        }}
        handleDimensionChange={(i, name) => this.handleDimensionChange(i, name)}
        setDimensionChange={(name, i, val) =>
          this.setDimensionChange(name, i, val)
        }
        adjustCount={(val) => {
          this.adjustCount(val);
        }}
        getRates={this.props.getRates}
        {...this.state}
        {...childProps}
      />,
      <CANStep4
        setData={(name, data) => this.setData(name, data)}
        setObjData={(name, objName, data) =>
          this.setObjData(name, objName, data)
        }
        handleObjChange={(name, objName) => this.handleObjChange(name, objName)}
        handleBack={(e) => {
          this.handleBack(e);
        }}
        handleNext={(e) => {
          this.handleNext(e);
        }}
        {...this.state}
        {...childProps}
      />,
      <CANStep5
        {...this.state}
        {...childProps}
        return={this.props.return}
        handleGoToStep={(e) => this.handleGoToStep(e)}
        handleBack={(e) => {
          this.handleBack(e);
        }}
        handleNext={(e) => {
          this.handleNext(e);
        }}
        createShipment={() => {
          this.createShipment();
        }}
      />,
    ];
    if (
      this.props.account &&
      this.props.account.user &&
      this.props.account.user.resolver
    ) {
      arr.splice(3, 1);
    }
    return arr[stepIndex];
  };

  handleNext = (stepper) => {
    const activeStep = this.state[stepper];
    this.setState({
      [stepper]: activeStep + 1,
    });
  };

  handleBack = (stepper) => {
    const activeStep = this.state[stepper];
    // console.log(activeStep)
    this.setState({
      [stepper]: activeStep - 1,
    });
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  handleGoToStep = async (step) => {
    if (step === 3) {
      let sample = this.props.locations.filter(
        (e) => e.id === this.state.senderLocationID
      );
      sample = sample[0];
      let test = await resolver(this.state);

      await this.props.getRates(
        {
          ...this.state,
          sender: sample,
        },
        test,
        async (e) => {
          console.log("RATING =>>>>", e);
          this.setState({ isLoading: false });
          this.setRates(
            "rates",
            e.response.filter((f) => {
              return f.rate != 0;
            })
          );
          this.setState({
            activeStep: step,
          });
        }
      );
    } else {
      this.setState({
        activeStep: step,
      });
    }
  };

  render() {
    const { classes } = this.props;
    const steps = this.getSteps();
    const { activeStep } = this.state;

    return (
      <Fragment>
        <UnconfirmedAddress
          open={this.state.modalOpen}
          onClose={() => this.setState({ modalOpen: false })}
          modalContinue={() => {
            this.setState((state) => ({
              oneStep: state.oneStep + 1,
            }));
            this.setState({ modalOpen: false });
          }}
        />
        <SnackBar
          variant="error"
          action={[
            <Button
              style={{ color: "#ffffff" }}
              size="small"
              onClick={() => this.setState({ snackOpen: false })}
            >
              I understand
            </Button>,
          ]}
          duration="60000"
          open={this.state.snackOpen}
          message={
            "We were unable to find that address. Please confirm that the address is correct. Address corrections can incur additional fees as laid out in our Terms and Services."
          }
          handleClose={() => {
            this.setState({ snackOpen: false });
          }}
        />

        <SnackBar
          variant="error"
          duration="5000"
          open={this.state.rateError}
          message={
            "There was an issue getting rates for the provided shipment. Please confirm the pickup and destination address are valid."
          }
          handleClose={() => {
            this.setState({ rateError: false });
          }}
        />

        <SnackBar
          variant="error"
          duration="5000"
          open={this.state.weightError}
          message={"Total weight of all shipments cannot exceed 500lb."}
          handleClose={() => {
            this.setState({ weightError: false });
          }}
        />

        <SnackBar
          variant="error"
          duration="5000"
          open={this.state.poBoxWarning}
          message={"Your account is unable to ship to PO boxes in Canada."}
          handleClose={() => {
            this.setState({ poBoxWarning: false });
          }}
        />

        <Hidden smDown={false} mdUp={true}>
          <Typography className={"stepper"} variant={"strong"}>
            {steps[activeStep]}
          </Typography>
        </Hidden>

        <Hidden smDown={false} mdUp={true}>
          <MobileStepper
            variant="dots"
            steps={steps.length}
            position="static"
            activeStep={this.state.activeStep}
            classes={{
              root: classes.root, // class name, e.g. `classes-nesting-root-x`
              dots: classes.dots, // class name, e.g. `classes-nesting-label-x`
            }}
            // className={classes.dots}
            style={{ width: "100%", margin: "auto" }}
          />
        </Hidden>

        <Hidden smDown={true}>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            style={{ background: "transparent", width: "100%" }}
          >
            {steps.map((label) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Hidden>

        <div style={{ maxWidth: "900px", width: "100%" }}>
          {this.getStepContent(activeStep)}
          {this.state.activeStep === 100 && (
            <div>
              <br />
              <br />
              <Button
                disabled={activeStep === 0}
                onClick={() => this.handleBack("activeStep")}
                className={classes.backButton}
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.handleNext("activeStep")}
              >
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

HorizontalLabelPositionBelowStepper.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(HorizontalLabelPositionBelowStepper);
