import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import PropTypes from "prop-types";
import React from "react";
import listTransactions from "../api/moneris/listTransactions";
import { round } from "../helpers/TaskRunners";

const actionsStyles = (theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
  },
  button: {
    margin: theme.spacing.unit,
  },
});

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = (event) => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = (event) => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, {
  withTheme: true,
})(TablePaginationActions);

let counter = 0;
function createData(date_created, amount, auth_code, message) {
  counter += 1;
  return { id: counter, date_created, amount, auth_code, message };
}

const styles = (theme) => ({
  root: {
    width: "100%",
    maxWidth: "600px",
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    // minWidth: 300,
  },
  tableWrapper: {
    overflowX: "auto",
  },
});

class CustomPaginationActionsTable extends React.Component {
  state = {
    rows: [],
    page: 0,
    rowsPerPage: 5,
  };

  UNSAFE_componentWillMount = async () => {
    //YOU HAVE SELECTED TO EDIT A SHIPMENT, PREPOPULATE STATE
    var parse = window.location.pathname.split("/")[2];
    // console.log(parse)
    if (
      parse != "" &&
      this.props.billing.filter((e) => e.id === parse).length > 0
    ) {
      this.openNewWindow(parse);
    }

    const transactions = await listTransactions(this.state.page, 500);
    this.setState({
      rows: transactions.response.map((e) =>
        createData(e.date_created, e.amount, e.auth_code, e.message)
      ),
    });
    //   console.log(transactions.response.map(e=>createData(
    //     e.date_created,
    //     e.amount,
    //     e.auth_code,
    //     e.message
    // )))

    // let rows = this.props.billing
    // rows = this.sortByKey(rows, 'datetime')

    // rows = rows.map(e=>
    //   createData(
    //     <Button color="primary" disabled={!e.status} onClick={()=>this.openNewWindow(e.id)}>{e.id}</Button>,
    //     e.datetime,
    //     e.charge
    // ))

    // await this.setState({rows})

    // console.log(this.props.vault.filter(z => z.shipments.indexOf(z.id) > -1))
    // console.log(this.props.vault.filter(z => z.shipments.indexOf(z.id) > -1).map(f=>f.tracking.length))
    // console.log(this.props.vault.filter(z => z.shipments.indexOf(z.id) > -1).map(f=>f.tracking.length).reduce((a,b)=>a+b, 0))
  };

  handleChangePage = async (event, page) => {
    // this.setState({ isLoading: true });
    // const transactions = await listTransactions(page, this.state.rowsPerPage)
    // this.setState({
    //   rows: transactions.response.map(e=>createData(
    //       e.date_created,
    //       e.amount,
    //       e.auth_code,
    //       e.message
    //   ))
    // })
    this.setState({ page });
    // this.setState({ isLoading: false });
  };

  handleChangeRowsPerPage = async (event) => {
    this.setState({ rowsPerPage: event.target.value });

    // this.setState({ isLoading: true });
    // const transactions = await listTransactions(this.state.page, event.target.value);
    // console.log(transactions)
    // this.setState({
    //   rows: transactions.response.map(e=>createData(
    //       e.date_created,
    //       e.amount,
    //       e.auth_code,
    //       e.message
    //   ))
    // })
    // this.setState({ isLoading: false });
  };

  render() {
    const { classes } = this.props;
    const { rowsPerPage, page } = this.state;
    const rows = this.state.rows;
    // const rows = this.props.billing.map(e=>
    //   createData(
    //     <Button color="primary" disabled={!e.status} onClick={()=>this.openNewWindow(e.id)}>{e.id}</Button>,
    //     e.datetime,
    //     e.charge
    // ))
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
      <Paper
        className={classes.root}
        style={{
          overflow: "auto",
          // 'float':'left',
          width: "calc(100vw - 45px)",
          // 'marginBottom':'90px'
        }}
      >
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <TableBody>
              <TableRow key={-1}>
                <TableCell
                  align="left"
                  style={{ padding: "4px 10px 4px 20px" }}
                >
                  {this.props.language == "FR" ? "Date" : "Date"}
                </TableCell>
                <TableCell align="left" style={{ padding: "4px 10px" }}>
                  {this.props.language == "FR" ? "Montant" : "Amount"}
                </TableCell>
                <TableCell scope="row" style={{ padding: "4px 10px" }}>
                  {this.props.language == "FR"
                    ? "Code de confirmation"
                    : "Auth Code"}
                </TableCell>
              </TableRow>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow key={row.id}>
                      <TableCell
                        align="left"
                        style={{ padding: "4px 10px 4px 20px" }}
                      >
                        <strong>
                          {new Date(row.date_created).toLocaleDateString()}
                        </strong>
                        <br />
                        <small>
                          {new Date(
                            new Date(row.date_created).getTime() + 14400000
                          ).toLocaleTimeString()}
                        </small>
                      </TableCell>
                      <TableCell align="left" style={{ padding: "4px 10px" }}>
                        {Number(row.amount) > 0 ? (
                          <span style={{ color: "green" }}>
                            + ${round(row.amount)}
                          </span>
                        ) : (
                          <span style={{ color: "red" }}>
                            - ${round(row.amount).split("-")}
                          </span>
                        )}
                      </TableCell>
                      <TableCell scope="row" style={{ padding: "4px 10px" }}>
                        <span
                          style={{
                            whiteSpace: "nowrap",
                            textTransform: "uppercase",
                          }}
                        >
                          {row.auth_code}
                        </span>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  colSpan={2}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    native: true,
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActionsWrapped}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </Paper>
    );
  }
}

CustomPaginationActionsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomPaginationActionsTable);
