import { API } from "aws-amplify";

const listShipments = (
  parent,
  email,
  page,
  limit,
  country,
  status,
  courier,
  search,
  isParent,
  cognitoSub,
  platform
) =>
  new Promise(async (resolve, reject) => {
    // console.log({
    //   "page": page,
    //   "limit": limit,
    //   "country": country,
    //   "status": status,
    //   "courier": "none",
    //   "search": search ? search : "none"
    // })
    // console.log(page, limit, country, status, courier, search, isParent, cognitoSub, platform);
    let something = await API.post("sqs", "/child-account/inbound", {
      body: {
        parent_cognitoSub: parent,
        email: email,
        page: page,
        limit: limit,
        isParent: isParent,
      },
    }).catch((e) => resolve({ shipments: [] }));
    // console.log(something)
    resolve(something);
  });

export default listShipments;
