//react
//material ui
import {
  Button,
  Divider,
  Grid,
  Hidden,
  SnackbarContent,
  // Snackbar,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
//amplify + appsync
import Amplify, { API, Auth, graphqlOperation } from "aws-amplify";
import { createAccount } from "graphql/createAccount";
import { createBalance } from "graphql/createBalance";
import { createSettings } from "graphql/createSettings";
import { getLocation } from "graphql/getLocation";
import { getPaymentMethod } from "graphql/getPaymentMethod";
import { listAccounts } from "graphql/listAccounts";
import { listBalances } from "graphql/listBalances";
import { listEcommerceIntegrations } from "graphql/listEcommerceIntegrations";
import { listLocations } from "graphql/listLocations";
import { listPaymentMethods } from "graphql/listPaymentMethods";
import { listSettings } from "graphql/listSettings";
import { listShipments } from "graphql/listShipments";
import { onCreateAccount } from "graphql/onCreateAccount";
import { onCreateEcommerceIntegration } from "graphql/onCreateEcommerceIntegration";
import { onCreateLocation } from "graphql/onCreateLocation";
import { onCreatePaymentMethod } from "graphql/onCreatePaymentMethod";
import { onCreateShipment } from "graphql/onCreateShipment";
import { onDeleteEcommerceIntegration } from "graphql/onDeleteEcommerceIntegration";
import { onDeleteLocation } from "graphql/onDeleteLocation";
import { onDeletePaymentMethod } from "graphql/onDeletePaymentMethod";
import { onDeleteShipment } from "graphql/onDeleteShipment";
import { onUpdateAccount } from "graphql/onUpdateAccount";
import { onUpdateBalance } from "graphql/onUpdateBalance";
import { onUpdateLocation } from "graphql/onUpdateLocation";
import { onUpdateSettings } from "graphql/onUpdateSettings";
import { onUpdateShipment } from "graphql/onUpdateShipment";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import { Component, Fragment } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
//styling
import "./App.css";
import aws_exports from "./aws-exports";
import Snackbar from "./components/Snackbar";
import NewUser from "./dialogs/NewUser";
import InboundShipments from "./experimental/InboundShipments";
import Shipments from "./experimental/NewList";
import ShopifyImportModal from "./experimental/ShopifyImportModal";
import UploadAddressesDialog from "./experimental/UploadAddressesDialog";
import UploadOrdersDialog from "./experimental/UploadOrdersDialog";
//images
import Logo from "./img/logo.svg";
import Account from "./pages/Account";
import AddressBook from "./pages/address_book/AddressBook";
import AddressBookImport from "./pages/address_book/importer/AddressBookImport";
import ChangePassword from "./pages/app/ChangePassword";
import ForgotPassword from "./pages/app/ForgotPassword";
import Loading from "./pages/app/Loading";
import Login from "./pages/app/Login";
import Signup from "./pages/app/Signup";
import Verify from "./pages/app/Verify";
// import SocketTable from "./experimental/SocketList";
import Connections from "./pages/Connections";
import CreateShipment from "./pages/create_shipment/CreateShipment";
import CreateShipmentCA from "./pages/CreateShipmentCA";
import CreateFreightCA from "./pages/CreateFreightCA";
import ChildShipment from "./pages/CreateShipmentChild";
import PharmaShipment from "./pages/CreateShipmentPharma";
import ReturnShipment from "./pages/CreateShipmentReturn";
import CreateShipmentUS from "./pages/CreateShipmentUS";
import Locations from "./pages/Locations";
import MyOrders from "./pages/orders/MyOrders";
import PayAndShip from "./pages/PayAndShip";
// import FullScreen from './experimental/ShipmentForm/FullScreen';
import Payments from "./pages/Payments";
import ChildAccounts from "./pages/permissions/ChildAccounts";
import Settings from "./pages/Settings";
//components
import { AddressBookProvider } from "./providers/AddressBook";
import SwiftNav from "./SwiftNav";
import XCDialog from "./XCDialog";
import CreateFulfillmentCA from "pages/CreateFulfillmentCA";
import amber from "@material-ui/core/colors/amber";
import { SelfDelivery } from "pages/self_delivery/SelfDelivery";
import { CreateSelfDeliveryAccount } from "pages/self_delivery/CreateSelfDeliveryAccount";

// import AddressBook from "./components/AddressBook";

// import Websocket from 'react-websocket';

Amplify.configure(aws_exports);

//-------------------------------------------
// Styles
//-------------------------------------------
const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: 240,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing.unit * 9,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background,
    padding: theme.spacing.unit * 3,
    minHeight: "100vh",
  },
});

class App extends Component {
  state = {
    account: [],
    addressCorrections: [],
    addressImportOpen: false,
    authed: false,
    checkoutModalOpen: false,
    claims: [],
    completedQueue: [],
    connections: [],
    customSnackOpen: false,
    ecommerceLoading: false,
    goal: 0,
    importOpen: false,
    isBrewery: false,
    isLoading: true,
    isRemoving: false,
    isUploading: false,
    locations: [],
    new: true,
    paymentmethods: [],
    processQueue: [],
    prodWarnOpen: true,
    qzPrinter: "",
    rate: [],
    selectedShipments: [],
    shipments: [],
    signedXCForm: false,
    snackBtnText: "",
    snackMessage: "",
    snackOpen: false,
    trackStack: [],
    upload_chunk_completed: 0,
    upload_chunk_count: false,
    uploadModalOpen: false,
    uploadStep: 0,
    user: [],
    xmasDialog:
      Number(new Date().getTime()) <
      Number(new Date("2020-12-29 00:00:00").getTime()),
  };

  componentWillUnmount = () => {
    this.user = null;
    this.email = null;
    this.canparRate = null;
    this.uspsRate = null;
    this.protectedBalance = null;
    this.handleLogout = null;
    this.refreshSocket = null;
    this.createSnackBar = null;
    this.startSocket = null;
    this.userHasAuthenticated = null;
    this.isCheckingOut = null;
    this.pushProcessQueue = null;
    this.popProcessQueue = null;
    this.handleUserData = null;
    this.ecommerceRefresh = null;
  };

  UNSAFE_componentWillMount = async () => {
    let language = getCookie("language");
    this.setState({ language: language !== "" ? language : "EN" });

    this.user = "";
    this.email = "";
    this.canparRate = "";
    this.uspsRate = "";
    this.protectedBalance = {};

    this.upload_chunk_tracker = [];

    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        await this.userHasAuthenticated(user);
      }
      this.setState({ isLoading: false });
    } catch (e) {
      await Auth.signOut();
      this.setState({ authed: false });
      this.setState({ isLoading: false });
      //not authed
    }
  };

  handleLogout = async (event) => {
    this.user = "";
    await Auth.signOut();
    this.setState({ authed: false });
    this.props.history.push("/login");
  };

  refreshSocket = () =>
    new Promise((resolve, reject) => {
      this.state.socket.close();
      setTimeout(() => {
        resolve(true);
      }, 1000);
    });

  createSnackBar = (message, buttonText) => {
    this.setState({ snackMessage: message });
    this.setState({ snackBtnText: buttonText });
    this.setState({ customSnackOpen: true });
  };

  startSocket = () =>
    new Promise((resolve, reject) => {
      let socket = new WebSocket(
        "wss://keuhz93lxj.execute-api.us-east-1.amazonaws.com/dev"
      );

      // Connection opened
      socket.addEventListener("open", () => {
        socket.send(JSON.stringify({ action: "sendMessage" }));
      });

      socket.addEventListener("close", () => {
        // console.log(`disconnected from socket: ${this.state.connection_id}`)
        this.startSocket();
      });

      this.setState({ socket: socket });

      // Listen for messages
      socket.addEventListener("message", async (event) => {
        let data = JSON.parse(event.data);
        // if(data.rate){
        //   this.setState({rate: data.rate})
        // }
        if (data.shopify_import_complete) {
          setTimeout(
            () =>
              this.setState({
                isUploading: false,
                shopifyLoading: false,
                shopify_count: null,
              }),
            2000
          );
        }
        if (data.delete_completed) {
          this.refreshOrders();
          this.setState({ deleteOpen: false });
        }
        if (data.chunk_processed) {
          this.upload_chunk_tracker.push(1);
          this.setState({
            upload_chunk_completed: this.state.upload_chunk_completed + 1,
          });
          if (
            this.upload_chunk_tracker.length === this.state.upload_chunk_count
          ) {
            setTimeout(() => {
              this.upload_chunk_tracker = [];
              //COMEBACK
              this.refreshOrders();

              this.setState({
                uploadStep: 0,
                deleteOpen: false,
                upload_chunk_count: false,
                upload_chunk_completed: 0,
                importOpen: false,
              });
            }, 1200);
          }
        }
        if (data.connectionId) {
          // console.log(`connected to socket: ${data.connectionId}`)
          this.setState({ connection_id: data.connectionId });
          console.log("CONNECTED, ", data.connectionId);
          resolve(data.connectionId);
        }
        if (data.finished) {
          this.setState({ checkoutModalOpen: false });
          this.setState({ goal: data.parent_ids.length });
          this.setState({ processQueue: data.parent_ids });
          this.setState({ completedQueue: [] });
          this.props.history.push("/shipments");
        }
        // if(data.etsyConnected){
        //   alert('CONNECTED')
        // }
        if (data.uploadComplete) {
          setTimeout(
            () =>
              this.setState({
                importOpen: false,
                uploadModalOpen: false,
                uploadStep: 0,
              }),
            3000
          );
          setTimeout(() => this.toggleUploading(false), 3000);
          // this.setState({uploadModalOpen:false})
        }
        if (data.label) {
          // alert("LABEL")
          let temp = this.state.completedQueue;
          // alert(data.parent_id)
          temp.push({
            parent_id: data.parent_id,
            image_url: data.label,
            ids: data.ids,
          });
          this.setState({ completedQueue: temp });
          if (temp.length === this.state.goal) {
            this.setState({ snackOpen: true });
          }
          // this.setState({processQueue:[]})
          this.popProcessQueue(data.parent_id);

          if (
            this.state.settings &&
            this.state.settings.autoPrint &&
            window.qz.websocket.isActive()
          ) {
            data.label.map((url) =>
              window.doPrint(url, this.state.settings.printer)
            );
          }

          // window.open(data.label)
        }
      });
    });

  userHasAuthenticated = async (user) => {
    // `this.user` is just the Cognito Sub, not a full user object
    this.user = user.username;

    // Requesting currentSession will refresh the token if necessary
    const session = await Auth.currentSession();
    this.email = session.idToken.payload.email.toUpperCase();

    this.startSocket();

    // const betaCheck = await API.post("sqs", "/beta", {
    //   body: { email: session.idToken.payload.email.toUpperCase() },
    // });
    // console.log(betaCheck);
    // if (!betaCheck.status) {
    //   Auth.signOut();
    //   this.setState({ authed: false });
    //   return;
    // }

    await Promise.all([
      this.prepareAccount(),
      this.prepareBalance(),
      this.prepareIntegrations(),
      this.prepareLocations(),
      this.preparePaymentMethods(),
      this.prepareSettings(),
      this.prepareShipments(),
    ]);

    await this.handleUserData();

    this.setState({ authed: true });
  };

  async prepareSettings() {
    let sett = await listSettings();

    // No settings exist yet, so create some
    if (sett.data.listSettings.items.length === 0) {
      await createSettings();
      sett = await listSettings();
    }

    this.setState({ settings: sett.data.listSettings.items[0] });

    onUpdateSettings(this.user).subscribe({
      next: async (newData) => {
        const settings = await listSettings();
        this.setState({});
        this.setState({ settings: settings.data.listSettings.items[0] });
      },
    });
  }

  async prepareShipments() {
    const shipments = await listShipments();
    this.setState({
      shipments: shipments.data.listV1_Shipments.items.sort(function (a, b) {
        var keyA = a.reference,
          keyB = b.reference;
        // Compare the 2 dates
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      }),
    });

    onCreateShipment(this.user).subscribe({
      next: async (newData) => {
        const shipments = await listShipments();
        this.setState({
          shipments: shipments.data.listV1_Shipments.items.sort(function (
            a,
            b
          ) {
            var keyA = a.order_id,
              keyB = b.order_id;
            // Compare the 2 dates
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
          }),
        });
        if (!this.state.isUploading) {
          this.props.history.push("/");
        }
        this.setState({ isRemoving: true });
        this.setState({ isRemoving: false });
      },
    });

    onUpdateShipment(this.user).subscribe({
      next: async (newData) => {
        const shipments = await listShipments();
        this.setState({
          shipments: shipments.data.listV1_Shipments.items.sort(function (
            a,
            b
          ) {
            var keyA = a.order_id,
              keyB = b.order_id;
            // Compare the 2 dates
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
          }),
        });
        this.props.history.push("/");
        this.setState({ isRemoving: true });
        this.setState({ isRemoving: false });
      },
    });

    onDeleteShipment(this.user).subscribe({
      next: async (newData) => {
        this.setState({
          shipments: this.state.shipments.filter(
            (e) => e.id !== newData.value.data.onDeleteV1_Shipment.id
          ),
        });
        this.setState({ isRemoving: true });
        this.setState({ isRemoving: false });
      },
    });
  }

  async prepareIntegrations() {
    const connections = await listEcommerceIntegrations();
    this.setState({
      connections: connections.data.listEcommerceIntegrations.items,
    });

    onCreateEcommerceIntegration(this.user).subscribe({
      next: async (newData) => {
        const connections = await listEcommerceIntegrations();
        this.setState({
          connections: connections.data.listEcommerceIntegrations.items,
        });
      },
    });

    onDeleteEcommerceIntegration(this.user).subscribe({
      next: async (newData) => {
        this.setState({
          connections: this.state.connections.filter(
            (e) => e.id !== newData.value.data.onDeleteEcommerceIntegration.id
          ),
        });
      },
    });
  }

  async prepareAccount() {
    const account = await listAccounts();

    // Create an account if one doesn't exist
    if (account.data.listV1_Accounts.items.length === 0) {
      await createAccount(this.email);
      window.location.reload();
    }

    if (account.data.listV1_Accounts.items[0].signedXCForm === "true") {
      this.setState({ signedXCForm: true });
    }

    this.setState({ account: account.data.listV1_Accounts.items[0] });

    onCreateAccount(this.user).subscribe({
      next: async (newData) => {
        const account = await listAccounts();
        this.setState({ account: account.data.listV1_Accounts.items[0] });
        await this.handleUserData(account.data.listV1_Accounts.items[0]);
      },
    });

    onUpdateAccount(this.user).subscribe({
      next: async (newData) => {
        let account = await listAccounts();
        let tempUser = this.state.account.user;

        this.setState({
          account: { ...account.data.listV1_Accounts.items[0], user: tempUser },
        });
        await this.handleUserData({
          ...account.data.listV1_Accounts.items[0],
          user: tempUser,
        });
      },
    });
  }

  async prepareLocations() {
    const locations = await listLocations();
    this.setState({ locations: locations.data.listV1_Locations.items });

    onCreateLocation(this.user).subscribe({
      next: async (newData) => {
        const locations = await getLocation(
          newData.value.data.onCreateV1_Location.id
        );
        this.setState({
          locations: [...this.state.locations, locations.data.getV1_Location],
        });
      },
    });

    onUpdateLocation(this.user).subscribe({
      next: async (newData) => {
        const locations = await listLocations();
        this.setState({ locations: locations.data.listV1_Locations.items });
      },
    });

    onDeleteLocation(this.user).subscribe({
      next: async (newData) => {
        this.setState({
          locations: this.state.locations.filter(
            (e) => e.id !== newData.value.data.onDeleteV1_Location.id
          ),
        });
      },
    });
  }

  async preparePaymentMethods() {
    const paymentmethods = await listPaymentMethods();
    this.setState({
      paymentmethods: paymentmethods.data.listV1_PaymentMethods.items,
    });

    onCreatePaymentMethod(this.user).subscribe({
      next: async (newData) => {
        const paymentMethod = await getPaymentMethod(
          newData.value.data.onCreateV1_PaymentMethod.id
        );
        this.setState({
          paymentmethods: [
            ...this.state.paymentmethods,
            paymentMethod.data.getV1_PaymentMethod,
          ],
        });
      },
    });

    onDeletePaymentMethod(this.user).subscribe({
      next: async (newData) => {
        this.setState({
          paymentmethods: this.state.paymentmethods.filter(
            (e) => e.id !== newData.value.data.onDeleteV1_PaymentMethod.id
          ),
        });
      },
    });
  }

  async prepareBalance() {
    let balance = await listBalances();

    if (balance.data.listV1_Balances.items.length === 0) {
      balance = await createBalance();
      this.protectedBalance = balance.data.createV1_Balance;
      this.setState({ firstLoad: true });
    } else {
      this.protectedBalance = balance.data.listV1_Balances.items[0];
    }

    onUpdateBalance(this.user).subscribe({
      next: async (newData) => {
        this.protectedBalance = newData.value.data.onUpdateV1_Balance;
        this.setState({ update: true });
      },
    });
  }

  isCheckingOut = (state) => {
    this.setState({ checkoutModalOpen: state });
  };

  pushProcessQueue = (row_id, parent_id) => {
    var { processQueue } = this.state;
    //is already queued
    if (processQueue.indexOf(parent_id) > -1) {
      return;
    }
    processQueue.push(parent_id);
    this.setState({ processQueue: processQueue });
  };

  popProcessQueue = (parent_id) => {
    // console.log(parent_id)
    var { processQueue } = this.state;
    // console.log(processQueue)

    processQueue = processQueue.filter((e) => e !== parent_id);
    // console.log(processQueue)
    this.setState({ processQueue: processQueue });
  };

  handleUserData = async () => {
    const user = await Auth.currentSession();

    try {
      const account = await API.post("sqs", "/account/get", {
        body: { email: user.idToken.payload.email.toUpperCase() },
      });

      var childAccount = "";
      if (account.permissions) {
        if (account.permissions.extraPrint) {
          this.setState({ isBrewery: true });
          if (account.permissions.xc_exempt) {
            this.setState({ signedXCForm: true });
          } else {
            //COMEBACK
          }
        }

        if (!account.user) {
          let balance = await createBalance();

          //user does not have any data
          await API.post("sqs", "/account/create", {
            body: {
              email: user.idToken.payload.email.toUpperCase(),
              balanceID: balance.data.createV1_Balance.id,
            },
          });
          window.location.reload();
          return;
        }

        if (account.permissions.isParent) {
          this.setState({
            user: { ...account.user, ...account.permissions },
            new: false,
          });
          let accCopy = this.state.account ? this.state.account : {};
          let temp = { ...account.user, ...account.permissions };
          accCopy.user = temp;
          // console.log(accCopy)
          this.setState({ account: accCopy });
          this.setState({
            childAddress: {
              cognitoUsername: accCopy.cognitoUsername,
              email: accCopy.user.userId,
            },
          });
          return true;
        }

        if (account.permissions.isChild) {
          // console.log('is child')

          //get parent and child info
          childAccount = await API.graphql(
            graphqlOperation(`query {
        queryChildAccountsByIdEmailIndex (email: "${user.idToken.payload.email.toUpperCase()}") {
          items{
            id
            location_id
            cognitoUsername
            email
            receiver
            fname
            lname
            business
            street_name
            street_number
            postal
            state
            city
            status
            child_sub
            phone
            unit
            country
          }
        }
      }`)
          );
          // console.log(PaymentMethod.data.getV1_PaymentMethod)
          // let temp = this.state.paymentmethods
          // temp.push(PaymentMethod.data.getChildAccounts);
          // console.log(childAccount)

          if (
            childAccount.data.queryChildAccountsByIdEmailIndex.items.length > 0
          ) {
            if (
              childAccount.data.queryChildAccountsByIdEmailIndex.items[0]
                .status === "0"
            ) {
              // console.log('chop');

              await API.graphql(
                graphqlOperation(`mutation updateV1_Account {
            updateV1_Account(input:{
              id:"${this.state.account.id}"
              location:{
                title:"${
                  childAccount.data.queryChildAccountsByIdEmailIndex.items[0]
                    .business
                }"
                address:{
                  street_name:"${
                    childAccount.data.queryChildAccountsByIdEmailIndex.items[0]
                      .street_name
                  }"
                  street_number:"${
                    childAccount.data.queryChildAccountsByIdEmailIndex.items[0]
                      .street_number
                  }"
                  postal:"${
                    childAccount.data.queryChildAccountsByIdEmailIndex.items[0]
                      .postal
                  }"
                  state:"${
                    childAccount.data.queryChildAccountsByIdEmailIndex.items[0]
                      .state
                  }"
                  country:"CA"
                  ${
                    childAccount.data.queryChildAccountsByIdEmailIndex.items[0]
                      .unit
                      ? `unit:"${childAccount.data.queryChildAccountsByIdEmailIndex.items[0].unit}"`
                      : ``
                  }
                  city:"${
                    childAccount.data.queryChildAccountsByIdEmailIndex.items[0]
                      .city
                  }"
                }
                contact: {
                  fname:"${
                    childAccount.data.queryChildAccountsByIdEmailIndex.items[0]
                      .fname
                  }"
                  lname:"${
                    childAccount.data.queryChildAccountsByIdEmailIndex.items[0]
                      .lname
                  }"
                  email:"${
                    childAccount.data.queryChildAccountsByIdEmailIndex.items[0]
                      .email
                  }"
                  phone:"${
                    childAccount.data.queryChildAccountsByIdEmailIndex.items[0]
                      .phone
                  }"
                } 
              }
            }){
              id
              cognitoUsername
            }
          }`)
              ).catch((e) => console.log(e));

              let location = await API.graphql(
                graphqlOperation(`mutation {
            createV1_Location(input:{
              title:"${
                childAccount.data.queryChildAccountsByIdEmailIndex.items[0]
                  .business
              }"
              address:{
                street_name:"${
                  childAccount.data.queryChildAccountsByIdEmailIndex.items[0]
                    .street_name
                }"
                street_number:"${
                  childAccount.data.queryChildAccountsByIdEmailIndex.items[0]
                    .street_number
                }"
                postal:"${
                  childAccount.data.queryChildAccountsByIdEmailIndex.items[0]
                    .postal
                }"
                state:"${
                  childAccount.data.queryChildAccountsByIdEmailIndex.items[0]
                    .state
                }"
                country:"CA"
                ${
                  childAccount.data.queryChildAccountsByIdEmailIndex.items[0]
                    .unit
                    ? `unit:"${childAccount.data.queryChildAccountsByIdEmailIndex.items[0].unit}"`
                    : ``
                }
                city:"${
                  childAccount.data.queryChildAccountsByIdEmailIndex.items[0]
                    .city
                }"
              }
              contact: {
                fname:"${
                  childAccount.data.queryChildAccountsByIdEmailIndex.items[0]
                    .fname
                }"
                lname:"${
                  childAccount.data.queryChildAccountsByIdEmailIndex.items[0]
                    .lname
                }"
                email:"${
                  childAccount.data.queryChildAccountsByIdEmailIndex.items[0]
                    .email
                }"
                phone:"${
                  childAccount.data.queryChildAccountsByIdEmailIndex.items[0]
                    .phone
                }"
              }
              locked:"true"
            }){
              id
              cognitoUsername
            }
          }`)
              ).catch((e) => console.log(e));

              // console.log("LOCATION=>",location);

              await API.graphql(
                graphqlOperation(`mutation {
            updateChildAccounts(input:{
              id:"${childAccount.data.queryChildAccountsByIdEmailIndex.items[0].id}"
              status:"1"
              account_id:"${this.state.account.id}"
              location_id:"${location.data.createV1_Location.id}"
            }){
              id
              cognitoUsername
            }
          }`)
              ).catch((e) => console.log(e));
            }

            // //SUBSCRIBE TO ACCOUNT CREATION
            const SubscribeToUpdateChildAccounts = `
        subscription {
          onUpdateChildAccounts(email:"${user.idToken.payload.email.toUpperCase()}"){
            id
            cognitoUsername
          }
        }
      `;
            API.graphql(
              graphqlOperation(SubscribeToUpdateChildAccounts)
            ).subscribe({
              next: async (newData) => {
                // console.log('updated account')
                await this.handleUserData(this.state.account);
              },
            });

            this.setState({
              childAddress:
                childAccount.data.queryChildAccountsByIdEmailIndex.items[0],
            });

            this.setState({
              user: { ...account.user, ...account.permissions },
              new: false,
            });

            let accCopy = this.state.account ? this.state.account : {};
            let temp = { ...account.user, ...account.permissions };
            accCopy.user = temp;
            this.setState({ account: accCopy });

            return true;
          }
        }
      }

      this.setState({
        user: { ...account.user, ...account.permissions },
        new: !account.user || !this.state.account.location.address,
        // new: true
      });
      let accCopy = this.state.account ? this.state.account : {};
      let temp = { ...account.user, ...account.permissions };
      accCopy.user = temp;

      this.setState({ account: accCopy });
      return true;
    } catch (err) {
      console.error(err);
    }
  };

  toggleUploading = (state) => {
    // alert(state)
    this.setState({ isUploading: state });
    // alert()
  };

  refreshOrders = async () => {
    let shipments = await listShipments();
    // console.log(Shipment.data.getV1_Shipment)
    // let temp = this.state.shipments
    // console.log();
    // temp.push(Shipment.data.listV1_Shipments.items);
    this.setState({
      shipments: shipments.data.listV1_Shipments.items.sort(function (a, b) {
        var keyA = a.reference,
          keyB = b.reference;
        // Compare the 2 dates
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      }),
    });
    this.setState({ isRemoving: true });
    this.setState({ isRemoving: false });
  };

  ecommerceRefresh = (platform) => {
    // console.log(platform)
    if (platform === "ETSY") {
      this.setState({ etsyLoading: true });

      this.state.connections
        .filter((e) => e.platform === "ETSY")
        .forEach((e) => {
          API.post("uploadorders", "/etsy/orders", { body: e })
            .then((g) => {
              // console.log('g',g)
              if (g.count > 0) {
                //todo
                this.refreshOrders();
                this.setState({
                  customSnackOpen: true,
                  snackMessage: (
                    <span>
                      {g.count} {this.language.order[this.state.language]}
                      {g.count > 1 ? `s` : ""}{" "}
                      {this.language.importedfromEtsy[this.state.language]}.
                    </span>
                  ),
                  snackBtnText: this.language.Ok[this.state.language],
                });
              } else {
                //todo
                this.setState({
                  customSnackOpen: true,
                  snackMessage: (
                    <span>
                      {
                        this.language.NonewordersfoundonEtsy[
                          this.state.language
                        ]
                      }
                    </span>
                  ),
                  snackBtnText: "OK",
                });
              }
              this.setState({ etsyLoading: false });
            })
            .catch((g) => {
              console.log(g);
              this.setState({ etsyLoading: false });
            });
        });
    } else if (platform === "WOOCOMMERCE") {
      this.setState({ woocommerceLoading: true });

      this.state.connections
        .filter((e) => e.platform === "WOOCOMMERCE")
        .forEach((e) => {
          API.post("uploadorders", "/woocomm/orders", { body: e })
            .then((g) => {
              // console.log('g',g)
              if (g.count > 0) {
                //todo
                this.setState({
                  customSnackOpen: true,
                  snackMessage: (
                    <span>
                      {g.count} {this.language.order[this.state.language]}
                      {g.count > 1 ? `s` : ""} imported from WooCommerce.
                    </span>
                  ),
                  snackBtnText: this.language.Ok[this.state.language],
                });
                this.refreshOrders();
              } else {
                //todo
                this.setState({
                  customSnackOpen: true,
                  snackMessage: (
                    <span>No new orders found on WooCommerce.</span>
                  ),
                  snackBtnText: "OK",
                });
              }
              this.setState({ woocommerceLoading: false });
            })
            .catch((g) => {
              // console.log(g);
              this.setState({ woocommerceLoading: false });
            });
        });
    } else if (platform === "SHOPIFY") {
      if (
        this.state.account &&
        this.state.account.user &&
        this.state.account.user.shopifyAutofill
      ) {
        //FOR BELLWOODS ONLY
        this.setState({ shopifyLoading: true });
        this.setState({ isUploading: true });

        console.log({
          body: {
            ...this.state.connections.filter(
              (e) => e.platform === "SHOPIFY"
            )[0],
            socket: this.state.connection_id,
            permissions: this.state.account.user,
          },
        });

        //make call to endpoint for sqs, respond the total number of orders that will be imported
        API.post("ecomm_test", "/shopify/refresh", {
          body: {
            ...this.state.connections.filter(
              (e) => e.platform === "SHOPIFY"
            )[0],
            socket: this.state.connection_id,
            permissions: this.state.account.user,
          },
        })
          .then((g) => {
            // console.log('g',g)
            if (g.shopify_count > 0) {
              //todo
              this.setState({ shopify_count: g.shopify_count });
              // this.setState({customSnackOpen:true,snackMessage:<span>{g.count} {this.language.order[this.state.language]}{g.count > 1 ? `s` :''} {this.language.importedfromShopify[this.state.language]}.</span>, snackBtnText:this.language.Ok[this.state.language]})
            } else {
              this.setState({ shopifyLoading: false });
              this.setState({ isUploading: false });
              this.setState({
                customSnackOpen: true,
                snackMessage: (
                  <span>
                    {
                      this.language.NonewordersfoundonShopify[
                        this.state.language
                      ]
                    }
                  </span>
                ),
                snackBtnText: this.language.Ok[this.state.language],
              });
            }
          })
          .catch((g) => {
            // console.log(g);
            // alert("error");
            this.setState({ shopifyLoading: false });
            this.setState({ isUploading: false });
          });
      } else {
        this.setState({ shopifyLoading: true });
        this.state.connections
          .filter((e) => e.platform === "SHOPIFY")
          .forEach((e) => {
            API.post("shopify", "/shopify/refreshOrders", { body: e })
              .then((g) => {
                // console.log('g',g)
                if (g.count > 0) {
                  //todo
                  this.setState({
                    customSnackOpen: true,
                    snackMessage: (
                      <span>
                        {g.count} {this.language.order[this.state.language]}
                        {g.count > 1 ? `s` : ""}{" "}
                        {this.language.importedfromShopify[this.state.language]}
                        .
                      </span>
                    ),
                    snackBtnText: this.language.Ok[this.state.language],
                  });
                } else {
                  //todo
                  this.setState({
                    customSnackOpen: true,
                    snackMessage: (
                      <span>
                        {
                          this.language.NonewordersfoundonShopify[
                            this.state.language
                          ]
                        }
                        .
                      </span>
                    ),
                    snackBtnText: this.language.Ok[this.state.language],
                  });
                }
                this.setState({ shopifyLoading: false });
              })
              .catch((g) => {
                // console.log(g);
                this.setState({ shopifyLoading: false });
              });
          });
      }
    }
  };

  language = {
    order: {
      FR: "commande",
      EN: "order",
    },
    importedfromEtsy: {
      FR: "importé d'Etsy",
      EN: "imported from Etsy",
    },
    NonewordersfoundonEtsy: {
      FR: "Aucune nouvelle commande trouvée sur Etsy",
      EN: "No new orders found on Etsy",
    },
    importedfromShopify: {
      FR: "importé de Shopify",
      EN: "imported from Shopify",
    },
    NonewordersfoundonShopify: {
      FR: "Aucune nouvelle commande trouvée sur Shopify",
      EN: "No new orders found on Shopify",
    },
    GotoShipments: {
      FR: "Aller aux envois",
      EN: "Go to Shipments",
    },
    Close: {
      FR: "Proche",
      EN: "Close",
    },
    YourLabelsareReadytoPrint: {
      FR: "Vos étiquettes sont prêtes à imprimer!",
      EN: "Your labels are ready to print!",
    },
    Ok: {
      FR: "D'accord",
      EN: "OK",
    },
  };

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes, ...rest } = this.props;
    // console.log(this.state.addressCorrections.filter(e=>!e.datetime_updated))

    if (this.state.isLoading) {
      return <Loading />;
    }

    return (
      <div className={classes.root}>
        {this.state.authed ? (
          <Fragment>
            <AddressBookProvider>
              <Snackbar
                variant="success"
                action={[
                  <Button
                    style={{ color: "#ffffff" }}
                    size="small"
                    onClick={() => this.props.history.push("/shipments")}
                  >
                    {this.language.GotoShipments[this.state.language]}
                  </Button>,
                  <Button
                    style={{ color: "#ffffff" }}
                    size="small"
                    onClick={() => this.setState({ snackOpen: false })}
                  >
                    {this.language.Close[this.state.language]}
                  </Button>,
                ]}
                duration="10000"
                open={this.state.snackOpen}
                message={
                  this.language.YourLabelsareReadytoPrint[this.state.language]
                }
                handleClose={() => {
                  this.setState({ snackOpen: false });
                }}
              />
              {/* <Snackbar
            variant="warning"
            action={[
              <Button
                style={{ color: "#ffffff" }}
                size="small"
                onClick={() => this.setState({ prodWarnOpen: false })}
              >
                OK
              </Button>
            ]}
            duration="10000"
            open={this.state.prodWarnOpen}
            message={
              "You are currently in a production environment."
            }
            handleClose={() => {
              this.setState({ snackOpen: false });
            }}
          /> */}

              {/* <Snackbar
                variant="info"
                action={[
                  <Button
                    style={{ color: "#ffffff" }}
                    size="small"
                    onClick={() => this.setState({ prodWarnOpen: false })}
                  >
                    OK
                  </Button>,
                ]}
                duration="10000"
                open={this.state.prodWarnOpen}
                message={
                  "You are currently in a sandbox environment. Rates displayed may be inaccurate."
                }
                handleClose={() => {
                  this.setState({ snackOpen: false });
                }}
              /> */}

              {/* <Snackbar
                variant="none"
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                action={[
                  <Button
                    style={{ color: "#ffffff" }}
                    size="small"
                    onClick={() => this.setState({ prodWarnOpen: false })}
                  >
                    OK
                  </Button>,
                ]}
                open={this.state.prodWarnOpen}
                message={
                  <>
                    As of February 22, Swiftpost is no longer in partnership
                    with ICS and Canpar. While we always intend to provide
                    sufficient notice to our customers regarding service
                    changes, the timing of this event was unfortunately out of
                    our control.
                    <br />
                    <br />
                    We appreciate your patience and understanding as we expand
                    our delivery services and broaden our resilient delivery
                    network. It is, as always, our mission to provide reliable,
                    high quality, and cost efficient logistics solutions for all
                    Canadians. Please reach out directly to your account
                    representative should you have any questions.
                  </>
                }
                style={{ backgroundColor: amber[700] }}
              /> */}

              {/* <Snackbar
            variant="warning"
            action={[
              <Button
                style={{ color: "#ffffff" }}
                size="small"
                onClick={() => this.setState({ prodWarnOpen: false })}
              >
                OK
              </Button>
            ]}
            duration="10000"
            open={this.state.prodWarnOpen}
            message={
              // "We are performing scheduled maintenance from 10 PM until 1 AM EST. Payment processing will not be available during this period."
              // "We are currently experiencing a service outage with partner systems from Canpar and ICS. We are working to solve this technical issue to have everything back up and running as soon as possible. USPS services are still working normally and available for use. If you have any questions please email us at support@swiftpost.com or call us at 1-888-655-6258"
              // "We are currently experiencing a service outage with partner systems from USPS for select services. We are working to solve this technical issue to have everything back up and running as soon as possible. Canpar and ICS services are still working normally and available for use. If you have any questions please email us at support@swiftpost.com or call us at 1-888-655-6258"
              "We are currently experiencing intermittent issues with elements of our platform. We are working to solve this technical issue to have everything back up and running as soon as possible. If you have any questions please email us at support@swiftpost.com or call us at 1-888-655-6258"
            }
            handleClose={() => {
              this.setState({ snackOpen: false });
            }}
          /> */}

              <XCDialog
                open={this.state.isBrewery && !this.state.signedXCForm}
                onClose={async () => {
                  //UPDATE PERMISSIONS TO ADD SIGNED FLAG
                  // console.log(`mutation updateAccount {
                  //   updateV1_Account(input:{
                  //     id:"${this.state.account.id}"
                  //     signedXCForm:"true"
                  //   }`);
                  await API.graphql(
                    graphqlOperation(`mutation updateAccount {
                updateV1_Account(input:{
                  id:"${this.state.account.id}"
                  signedXCForm:"true"
                      }){
                          id
                          cognitoUsername
                        }
                      }`)
                  ).then(async (e) => {
                    this.setState({ signedXCForm: true });
                  });
                }}
              />

              <Snackbar
                variant="success"
                action={
                  this.state.snackBtnText !== "" ? (
                    <Button
                      style={{ color: "#ffffff" }}
                      size="small"
                      onClick={() => this.setState({ customSnackOpen: false })}
                    >
                      {this.language.Ok[this.state.language]}
                    </Button>
                  ) : (
                    ""
                  )
                }
                duration="10000"
                open={this.state.customSnackOpen}
                message={this.state.snackMessage}
                handleClose={() => {
                  this.setState({ customSnackOpen: false });
                }}
              />

              {this.state.addressImportOpen && (
                <UploadAddressesDialog
                  uploadStep={this.state.uploadStep}
                  connection_id={this.state.connection_id}
                  isUploading={this.state.isUploading}
                  toggleUploading={this.toggleUploading}
                  account={this.state.account}
                  locations={this.state.locations}
                  completed={this.state.upload_chunk_completed}
                  upload_chunk_count={this.state.upload_chunk_count}
                  set_upload_chunk_count={(count) =>
                    this.setState({ upload_chunk_count: count })
                  }
                  open={this.state.addressImportOpen}
                  increaseUploadStep={() =>
                    this.setState({ uploadStep: this.state.uploadStep + 1 })
                  }
                  handleClose={() =>
                    this.setState({ addressImportOpen: false, uploadStep: 0 })
                  }
                />
              )}

              {this.state.importOpen && (
                <UploadOrdersDialog
                  uploadStep={this.state.uploadStep}
                  connection_id={this.state.connection_id}
                  isUploading={this.state.isUploading}
                  toggleUploading={this.toggleUploading}
                  account={this.state.account}
                  locations={this.state.locations}
                  // open={true}
                  // upload_chunk_count={}
                  // completed={0}
                  completed={this.state.upload_chunk_completed}
                  upload_chunk_count={this.state.upload_chunk_count}
                  set_upload_chunk_count={(count) =>
                    this.setState({ upload_chunk_count: count })
                  }
                  open={this.state.importOpen}
                  increaseUploadStep={() =>
                    this.setState({ uploadStep: this.state.uploadStep + 1 })
                  }
                  handleClose={() =>
                    this.setState({ importOpen: false, uploadStep: 0 })
                  }
                />
              )}

              <ShopifyImportModal
                shopify_count={this.state.shopify_count}
                uploadStep={this.state.uploadStep}
                connection_id={this.state.connection_id}
                isUploading={this.state.isUploading}
                toggleUploading={this.toggleUploading}
                account={this.state.account}
                locations={this.state.locations}
                open={this.state.uploadModalOpen || this.state.isUploading}
                increaseUploadStep={() =>
                  this.setState({ uploadStep: this.state.uploadStep + 1 })
                }
                handleClose={() =>
                  this.setState({ uploadModalOpen: false, uploadStep: 0 })
                }
              />

              {this.state.new ? (
                <NewUser
                  history={this.props.history}
                  balanceID={this.protectedBalance.id}
                  user={this.state.user}
                  account={this.state.account}
                  handleUserData={this.handleUserData}
                />
              ) : (
                ""
              )}
              {/* {!this.state.user && this.state.isAuthenticated && <NewUser />} */}
              <SwiftNav
                {...rest}
                toggleUploading={this.toggleUploading}
                etsyLoading={this.state.etsyLoading}
                shopifyLoading={this.state.shopifyLoading}
                woocommerceLoading={this.state.woocommerceLoading}
                ecommerceLoading={this.state.ecommerceLoading}
                ecommerceRefresh={this.ecommerceRefresh}
                connections={this.state.connections}
                createSnackBar={this.createSnackBar}
                account={this.state.account}
                shipments={this.state.shipments}
                handleLogout={this.handleLogout}
                balance={{
                  ...this.protectedBalance,
                  update: this.state.update,
                }}
                paymentmethods={this.state.paymentmethods}
              />
              <main className={classes.content}>
                <Switch>
                  <Route
                    path="/shipments"
                    render={(props) => (
                      <Shipments
                        settings={this.state.settings}
                        completedQueue={this.state.completedQueue}
                        account={this.state.account}
                        processQueue={this.state.processQueue}
                        popProcessQueue={this.popProcessQueue}
                        pushProcessQueue={this.pushProcessQueue}
                      />
                    )}
                  />
                  <Route
                    path="/account"
                    render={(props) => (
                      <Account account={this.state.account} {...rest} />
                    )}
                  />
                  <Route
                    path="/locations"
                    render={(props) => (
                      <Locations
                        account={this.state.account}
                        locations={this.state.locations}
                        {...rest}
                      />
                    )}
                  />
                  <Route
                    path="/addressbook/import"
                    render={(props) => (
                      <AddressBookImport
                        account={this.state.account}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/addressbook"
                    render={(props) => (
                      <AddressBook
                        account={this.state.account}
                        {...props}
                        handleLogout={this.handleLogout}
                      />
                    )}
                  />
                    <Route
                      path="/edit/ca/:id"
                      render={(props) => (
                        <CreateShipmentCA
                          edit={true}
                          {...rest}
                          account={this.state.account}
                          rate={this.state.rate}
                          getRate={this.getRateCA}
                          shipments={this.state.shipments}
                          locations={this.state.locations}
                          handleLogout={this.handleLogout}
                        />
                      )}
                    />
                    <Route
                      path="/new/ca"
                      render={(props) => (
                        <CreateShipmentCA
                          {...rest}
                          account={this.state.account}
                          rate={this.state.rate}
                          getRate={this.getRateCA}
                          locations={this.state.locations}
                          handleLogout={this.handleLogout}
                        />
                      )}
                    />
                  <Route
                    path="/payandship"
                    render={(props) => (
                      <PayAndShip
                        createSnackBar={this.createSnackBar}
                        refreshSocket={this.refreshSocket}
                        socket={this.state.socket}
                        checkoutModalOpen={this.state.checkoutModalOpen}
                        isCheckingOut={this.isCheckingOut}
                        connection_id={this.state.connection_id}
                        paymentmethods={this.state.paymentmethods}
                        account={this.state.account}
                        balance={{
                          ...this.protectedBalance,
                          update: this.state.update,
                        }}
                        user={this.state.user}
                        shipment={this.state.shipments}
                        selectedShipments={this.state.selectedShipments}
                        setSelectedShipments={(e) =>
                          this.setState({ selectedShipments: e })
                        }
                        {...rest}
                        locations={this.state.locations}
                      />
                    )}
                  />

                  <Route
                    path="/settings"
                    render={(props) => (
                      <Settings settings={this.state.settings} {...rest} />
                    )}
                  />

                    <Route
                      path="/self-delivery/create"
                      render={(props) => (
                        <CreateSelfDeliveryAccount
                          account={this.state.account}
                          locations={this.state.locations}
                          history={this.props.history}
                        />
                      )}
                    />


                    <Route
                      path="/self-delivery"
                      render={(props) => (
                        <SelfDelivery
                          account={this.state.account}
                          locations={this.state.locations}
                          history={this.props.history}
                        />
                      )}
                    />

                  <Route
                    path="/"
                    render={(props) => (
                      <MyOrders
                        account={this.state.account}
                        checkoutModalOpen={this.state.checkoutModalOpen}
                        isCheckingOut={this.isCheckingOut}
                        completed={this.state.upload_chunk_completed}
                        upload_chunk_count={this.state.upload_chunk_count}
                        set_upload_chunk_count={(count) =>
                          this.setState({ upload_chunk_count: count })
                        }
                        connection_id={this.state.connection_id}
                        deleteOpen={this.state.deleteOpen}
                        toggleDeleteModal={() =>
                          this.setState({ deleteOpen: !this.state.deleteOpen })
                        }
                        refreshOrders={this.refreshOrders}
                        updateOrders={() => {
                          this.setState({ isRemoving: true });
                          this.setState({ isRemoving: false });
                        }}
                        settings={this.state.settings}
                        toggleImport={() =>
                          this.setState({ importOpen: !this.state.importOpen })
                        }
                        toggleUploading={this.toggleUploading}
                        toggleModal={() =>
                          this.setState({
                            uploadModalOpen: !this.state.uploadModalOpen,
                          })
                        }
                        isUploading={this.state.isUploading}
                        locations={this.state.locations}
                        isRemoving={this.state.isRemoving}
                        deleteShipments={this.deleteShipments}
                        selectedShipments={this.state.selectedShipments}
                        setSelectedShipments={(e) =>
                          this.setState({ selectedShipments: e })
                        }
                        shipments={this.state.shipments}
                        {...rest}
                      />
                    )}
                  />
                </Switch>
              </main>
            </AddressBookProvider>
          </Fragment>
        ) : (
          <main className={classes.content} style={{ padding: 0 }}>
            <Switch>
              <Route
                path="/login"
                render={(props) => (
                  <Login
                    userHasAuthenticated={this.userHasAuthenticated}
                    {...rest}
                  />
                )}
              />
              <Route path="/signup" render={(props) => <Signup {...rest} />} />
              <Route path="/verify" render={(props) => <Verify {...rest} />} />
              <Route
                path="/forgotpassword"
                render={(props) => <ForgotPassword {...rest} />}
              />
              <Route
                path="/changepassword"
                render={(props) => <ChangePassword {...rest} />}
              />
              <Route
                path="/"
                render={(props) => (
                  <Login
                    userHasAuthenticated={this.userHasAuthenticated}
                    {...rest}
                  />
                )}
              />
            </Switch>
            <footer>
              <Grid container lg={6} md={8} sm={10} style={{ margin: "auto" }}>
                <Grid item md={4} xs={12}>
                  <center>
                    <img
                      src={Logo}
                      alt={"Swiftpost Logo"}
                      style={{ maxWidth: "150px", marginTop: "10px" }}
                    />
                  </center>
                </Grid>
                <Grid item md={4} xs={12}>
                  <center>
                    <Typography style={{ color: "#eee", marginTop: "5px" }}>
                      2130 S Service Rd W, Oakville, ON L6L 5N1
                    </Typography>
                  </center>
                  <Hidden mdUp>
                    <Divider style={{ background: "#aaa", margin: "20px" }} />
                  </Hidden>
                </Grid>
                <Grid item md={4} xs={12}>
                  <center>
                    <ul>
                      <li>
                        <a href="https://www.swiftpost.com/privacy-policy">
                          Privacy Policy
                        </a>
                      </li>
                      <li>
                        <a href="https://www.swiftpost.com/terms-of-use">
                          Terms of Use
                        </a>
                      </li>
                      {/* <li><a href="#">Refund Policy</a></li> */}
                    </ul>
                  </center>
                </Grid>
                {/* <Grid item md={4} xs={12}>
                  <center>
                    <ul>
                      <li><a href="#">About</a></li>
                      <li><a href="#">Contact Us</a></li>
                      <li><a href="#">Delivery Policy</a></li>
                      </ul>
                  </center>
                  </Grid> */}
              </Grid>
            </footer>
          </main>
        )}
      </div>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(App));
