import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { API } from "aws-amplify";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import React from "react";
// import LoadingIcon from '../components/loading/LoadingIcon';
// import StreetAutocomplete from './StreetAutocomplete';
//snackbars
// import SnackBar from '../components/snacks/Snackbar';
import {
  isNotEmpty,
  isNumericOnly,
  isValidPostal,
} from "../helpers/Validation";
// import { removeDuplicatesFromArray } from '../helpers/TaskRunners';

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    marginRight: "5%",
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    width: "45%",
    maxWidth: "50%",
  },
});

class FormDialog extends React.Component {
  state = {
    isLoading: false,
    open: false,
    postal: "",
    searchDone: false,
    address: "",
    street: "",
    province: "",
    response: [],
    snackMessage: "",
    snackOpen: false,
    snackVariant: "error",
    formError: false,
  };

  UNSAFE_componentWillMount() {
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });
    this.setState({ postal: this.props.data.postal });
    this.setState({ province: this.props.data.province });
    this.setState({ address: this.props.data.address });
    this.setState({ street: this.props.data.street });
    this.setState({ unit: this.props.data.unit });
    this.setState({ alias: this.props.data.alias });
    this.setState({ id: this.props.data.id });
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  language = {
    Warning: {
      FR: (
        <span>
          En appuyant sur <em>Continuer malgré tout</em>, vous reconnaissez et
          acceptez tous les frais supplémentaires pour les changements
          d'adresse, comme indiqué dans notre{" "}
          <a href="https://www.swiftpost.com/terms-of-use/" target="_new">
            Termes et conditions
          </a>
          .<br />
          <br />
        </span>
      ),
      EN: (
        <span>
          By pressing <em>Continue Anyway</em> you acknowledge and accept any
          additional charge for address changes, as laid out in our{" "}
          <a href="https://www.swiftpost.com/terms-of-use/" target="_new">
            Terms and Conditions
          </a>
          .<br />
          <br />
        </span>
      ),
    },
    InvalidPostalCode: {
      FR: "Code postal invalide",
      EN: "Invalid Postal Code",
    },
    PostalCodeCouldNotFound: {
      FR: "Le code postal est introuvable",
      EN: "Postal Code could not be found",
    },
    AddressCouldNotBeValidated: {
      FR: "L'adresse n'a pas pu être validée",
      EN: "Address could not be validated",
    },
    AddressSuccessfullyAdded: {
      FR: "Adresse ajoutée avec succès",
      EN: "Address successfully added",
    },
    LocationName: {
      FR: "Nom de famille",
      EN: "LocationName",
    },
    StreetNumber: {
      FR: "Numéro de rue",
      EN: "Street Number",
    },
    StreetName: {
      FR: "Nom de rue",
      EN: "Street Name",
    },
    UnitApt: {
      FR: "Unité/Appartemant",
      EN: "Unit/Apt #",
    },
    City: {
      FR: "Ville",
      EN: "City",
    },
    PostalCode: {
      FR: "Code Postale",
      EN: "Postal Code",
    },
    Province: {
      FR: "Province",
      EN: "Province",
    },
    Cancel: {
      FR: "Annuler",
      EN: "Cancel",
    },
    AddLocation: {
      FR: "Ajouter l'emplacement",
      EN: "Add Location",
    },
    Loading: {
      FR: "Attendre",
      EN: "Loading",
    },
    Results: {
      FR: "Résultats",
      EN: "Results",
    },
    WeWereUnableToLocateTheProvidedAddressBelow: {
      FR: "Impossible de trouver l'adresse ci-dessous",
      EN: "We were unable to locate the provided address below",
    },
    GoBack: {
      FR: "Derrière",
      EN: "GoBack",
    },
    ContinueAnyway: {
      FR: "Continuer",
      EN: "ContinueAnyway",
    },
  };

  handlePostalInput = (event) => {
    this.setState({
      [event.target.id]: event.target.value.toUpperCase(),
    });
  };

  handleSelectChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleStreetSelect = (name) => (value) => {
    this.setState({
      [name]: value.value,
    });
  };

  handleClose = async () => {
    this.setState({ alias: "" });
    this.setState({ postal: "" });
    this.setState({ address: "" });
    this.setState({ street: "" });
    await this.props.handleClose();
    this.setState({ searchDone: false });
  };

  validateForm = () => {
    return isValidPostal(this.state.postal) && isNotEmpty(this.state.postal);
  };

  handleSubmit = async () => {
    if (!this.validateForm()) {
      this.handleClose();
      this.setState({ snackVariant: "error" });
      this.setState({
        snackMessage: this.language.InvalidPostalCode[this.state.language],
      });
      this.setState({ snackOpen: true });
      return;
    }
    await this.setState({ postal: this.state.postal.replace(" ", "") });
    await this.searchPostal(this.state.postal);
  };

  searchPostal() {
    this.setState({ isLoading: true });
    API.post("canpar", "/canpar/search-postal", {
      body: {
        postal: this.state.postal,
        province: "",
        alias: "",
      },
    })
      .then((e) => {
        this.setState({ isLoading: false });
        this.setState({ postal: e.address[0][0]["ax27:postal_code"][0] });
        this.setState({ city: e.address[0][0]["ax27:city"][0] });
        this.setState({ province: e.address[0][0]["ax27:province"][0] });
        e.address = e.address[0].map((item, i) => {
          let temp = item["ax27:address_line_1"][0].split(" ");
          temp.shift();
          temp = temp.join(" ");
          return temp;
        });
        //TODO, FIX
        //   e.address = removeDuplicatesFromArray(e.address);
        e.address = e.address.map((e, i) => {
          return {
            value: e,
            label: e,
          };
        });
        this.setState({ response: e.address });

        if (e.address.length === 1) {
          this.setState({ street: e.address[0].label });
        }

        this.setState({ searchDone: true });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
        this.handleClose();
        this.setState({ snackVariant: "error" });
        this.setState({
          snackMessage:
            this.language.PostalCodeCouldNotFound[this.state.language],
        });
        this.setState({ snackOpen: true });
      });
  }

  addressPreConfirm = () => {
    //testing has shown that canadapost/canpar does not verify properly if the street number is over 9 characters or contains a letter
    return this.state.address.length <= 9 && isNumericOnly(this.state.address);
  };

  confirmAddress = () => {
    this.setState({ isLoading: true });
    // console.log(this.state);

    //this is used to catch known bad addresses without posting to the database
    if (!this.addressPreConfirm()) {
      // console.log('failed preflight');
      this.setState({ isLoading: false });
      this.setState({ formError: true });
      this.setState({ snackVariant: "error" });
      this.setState({
        snackMessage:
          this.language.AddressCouldNotBeValidated[this.state.language],
      });
      this.setState({ snackOpen: true });
      return;
    }

    API.post("canpar", "/canpar/validate-address", {
      body: this.state,
    })
      .then(async (e) => {
        await this.props.addLocation(this.state);
        this.setState({ isLoading: false });
        this.handleClose();
        this.setState({ snackVariant: "success" });
        this.setState({
          snackMessage:
            this.language.AddressSuccessfullyAdded[this.state.language],
        });
        this.setState({ snackOpen: true });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
        this.setState({ formError: true });
        this.setState({ snackVariant: "error" });
        this.setState({
          snackMessage:
            this.language.AddressCouldNotBeValidated[this.state.language],
        });
        this.setState({ snackOpen: true });
      });
  };

  renderProvinces() {
    let provinces = [
      "AB",
      "BC",
      "MB",
      "NB",
      "NS",
      "NL",
      "SK",
      "ON",
      "PE",
      "QC",
    ];
    provinces.sort();

    return [{}].concat(provinces).map((item, i) => {
      if (Object.keys(item).length === 0 && item.constructor === Object) return;
      return <MenuItem value={item}>{item}</MenuItem>;
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        {/* <SnackBar style={{zIndex:999999,position:'fixed'}} variant={this.state.snackVariant} open={this.state.snackOpen} message={this.state.snackMessage} handleClose={()=>{this.setState({snackOpen:false})}} /> */}
        {!this.state.formError && (
          <Dialog
            open={this.props.open}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
          >
            <div>
              <DialogTitle id="form-dialog-title">
                {this.language.Results[this.state.language]}
              </DialogTitle>
              <DialogContent>
                <DialogContentText></DialogContentText>
                <FormControl
                  fullWidth
                  style={{ width: "95%", maxWidth: "95%", marginRight: 0 }}
                  className={classes.formControl}
                >
                  <TextField
                    disabled={this.state.isLoading}
                    autoFocus
                    margin="dense"
                    id="alias"
                    name="alias"
                    label={this.language.LocationName[this.state.language]}
                    type="text"
                    fullWidth
                    key={1}
                    value={this.state.alias}
                    onChange={this.handleChange}
                  />
                </FormControl>
                <FormControl
                  className={classes.formControl}
                  style={{ width: "30%", maxWidth: "30%" }}
                >
                  <TextField
                    disabled={this.state.isLoading}
                    margin="dense"
                    id="address"
                    name="address"
                    label={this.language.StreetNumber[this.state.language]}
                    type="text"
                    fullWidth
                    key={2}
                    value={this.state.address}
                    onChange={this.handleChange}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  className={classes.formControl}
                  style={{ width: "60%", maxWidth: "60%" }}
                >
                  <TextField
                    disabled={this.state.isLoading}
                    margin="dense"
                    id="street"
                    name="street"
                    label={this.language.StreetName[this.state.language]}
                    type="text"
                    fullWidth
                    key={2}
                    value={this.state.street}
                    onChange={this.handleChange}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    disabled={this.state.isLoading}
                    margin="dense"
                    id="unit"
                    name="unit"
                    label={this.language.UnitApt[this.state.language]}
                    type="text"
                    fullWidth
                    key={4}
                    value={this.state.unit}
                    onChange={this.handleChange}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    disabled={this.state.isLoading}
                    margin="dense"
                    id="city"
                    name="city"
                    label={this.language.City[this.state.language]}
                    type="text"
                    fullWidth
                    key={4}
                    value={this.state.city}
                    onChange={this.handleChange}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    disabled={this.state.isLoading}
                    margin="dense"
                    id="postal"
                    name="postal"
                    label={this.language.PostalCode[this.state.language]}
                    type="text"
                    fullWidth
                    key={3}
                    value={this.state.postal}
                    onChange={this.handleChange}
                  />
                </FormControl>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="province">
                    {this.language.Province[this.state.language]}
                  </InputLabel>
                  <Select
                    disabled={this.state.isLoading}
                    fullWidth
                    value={this.state.province}
                    onChange={this.handleSelectChange}
                    inputProps={{
                      name: "province",
                      id: "province",
                    }}
                    key={5}
                  >
                    {this.renderProvinces()}
                  </Select>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button
                  disabled={this.state.isLoading}
                  onClick={this.handleClose}
                  color="primary"
                >
                  {this.language.Cancel[this.state.language]}
                </Button>
                <Button
                  disabled={this.state.isLoading}
                  onClick={() => {
                    this.confirmAddress();
                  }}
                  color="primary"
                >
                  {!this.state.isLoading
                    ? this.language.AddLocation[this.state.language]
                    : this.language.Loading[this.state.language]}
                </Button>
              </DialogActions>
            </div>
          </Dialog>
        )}
        {this.state.formError && (
          <Dialog
            open={this.props.open}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
          >
            <div>
              <DialogTitle id="form-dialog-title">
                {this.language.Results[this.state.language]}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {
                    this.language.WeWereUnableToLocateTheProvidedAddressBelow[
                      this.state.language
                    ]
                  }
                  :<br />
                  <br />
                  <strong>
                    {this.state.address} {this.state.street}
                    <br />
                    {this.state.city}, {this.state.province} {this.state.postal}
                  </strong>
                  <br />
                  <br />
                  {this.language.Warning[this.state.language]}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    this.setState({ formError: false });
                  }}
                  color="primary"
                >
                  {this.language.GoBack[this.state.language]}
                </Button>
                <Button
                  onClick={() => {
                    this.props.addLocation(this.state);
                    this.handleClose();
                    this.setState({ formError: false });
                  }}
                  color="primary"
                >
                  {this.language.ContinueAnyway[this.state.language]}
                </Button>
              </DialogActions>
            </div>
          </Dialog>
        )}
      </div>
    );
  }
}

FormDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FormDialog);
