//react
// import LoadingIcon from '../components/loading/LoadingIcon';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
//components
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
//icons
import Typography from "@material-ui/core/Typography";
import FaceOutlined from "@material-ui/icons/FaceOutlined";
import LockOutlined from "@material-ui/icons/LockOutlined";
import { API, Auth } from "aws-amplify";
import { getCookie } from "helpers/getCookie";
import React from "react";
import { Link } from "react-router-dom";
//snackbars
import SnackBar from "../../components/Snackbar";
//helpers
import { isNotEmpty, isValidEmail } from "../../helpers/Validation";
//images
import Logo from "../../img/logo.svg";
//styles
import "../../styles/Login.css";
import { getParameterByName } from "../../helpers/TaskRunners";

//---------------------------------------------

export default class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
      password: "",
      errorMessage: "",
      errorOpen: false,
      redirectToReferrer: false,
    };
  }

  componentWillUnmount = () => {
    this.validateForm = null;
    this.handleChange = null;
    this.handleSubmit = null;
  };

  UNSAFE_componentWillMount = () => {
    // document.cookie = "language=FR";

    if (getParameterByName("user")) {
      this.setState({ email: getParameterByName("user") });
    }

    let language = getCookie("language");
    this.setState({ language: language !== "" ? language : "EN" });

    this.validateForm = () => {
      return (
        isNotEmpty(this.state.email) &&
        isNotEmpty(this.state.password) &&
        isValidEmail(this.state.email)
      );
    };

    this.handleChange = (event) => {
      this.setState({
        [event.target.id]: event.target.value,
      });
    };

    this.handleSubmit = async (event) => {
      event.preventDefault();

      // return;
      // alert('trying')

      // this.setState({ errorMessage: 'The login server is currently disabled.' });
      // this.setState({ errorOpen: true });

      if (!this.validateForm()) {
        this.setState({
          errorMessage:
            this.language.Theinformationenteredisnotvalid[this.state.language],
        });
        this.setState({ errorOpen: true });
        return;
      }

      this.setState({ isLoading: true });

      // const betaCheck = await API.post("sqs", "/beta", {
      //   body: { email: this.state.email.toUpperCase() },
      // }).catch((e) => {
      //   console.log(e);
      // });
      // // console.log(betaCheck)
      // if (!betaCheck) {
      //   return;
      // }

      // if (!betaCheck.status) {
      //   // if(this.state.email.toUpperCase() !== "TEST@SWIFTPOST.COM" &&
      //   // this.state.email.toUpperCase() !== "A@SWIFTPOST.COM" &&
      //   // this.state.email.toUpperCase() !== "B@SWIFTPOST.COM" &&
      //   // this.state.email.toUpperCase() !== "C@SWIFTPOST.COM" &&
      //   // this.state.email.toUpperCase() !== "D@SWIFTPOST.COM" &&
      //   // this.state.email.toUpperCase() !== "BAG@SWIFTPOST.COM"){
      //   this.setState({ isLoading: false });
      //   this.setState({ snackVariant: "error" });
      //   this.setState({
      //     errorMessage:
      //       this.language.Youarenotauthorizedtousethisplatform[
      //         this.state.language
      //       ],
      //   });
      //   this.setState({ errorOpen: true });
      //   return;
      // }

      try {
        const user = await Auth.signIn(
          this.state.email,
          this.state.password
        ).catch((e) => {
          console.log(e);
          throw Error("Incorrect username or password.");
        });

        console.log({ user });
        this.props.userHasAuthenticated(user);
        this.props.history.push("/");
      } catch (e) {
        console.log("Unable to authenticate", e);
        this.setState({ isLoading: false });
        this.setState({ errorMessage: e.message });
        this.setState({ errorOpen: true });
      }
    };
  };

  language = {
    Theinformationenteredisnotvalid: {
      FR: "Les informations saisies ne sont pas valides",
      EN: "The information entered is not valid",
    },
    Youarenotauthorizedtousethisplatform: {
      FR: "Vous n'êtes pas autorisé à utiliser cette plateforme",
      EN: "You are not authorized to use this platform",
    },
    Signintoyouraccount: {
      FR: "Connectez-vous à votre compte",
      EN: "Sign in to your account",
    },
    EmailAddress: {
      FR: "Adresse électronique",
      EN: "Email Address",
    },
    Password: {
      FR: "Mot de passe",
      EN: "Password",
    },
    ForgotPassword: {
      FR: "Mot de passe oublié",
      EN: "Forgot Password",
    },
    Login: {
      FR: "S'identifier",
      EN: "Login",
    },
    Loading: {
      FR: "Attendre",
      EN: "Loading",
    },
    Donthaveanaccount: {
      FR: "Vous n'avez pas de compte?",
      EN: "Don't have an account?",
    },
    SignUpforFree: {
      FR: "Inscription gratuite",
      EN: "Sign Up for Free",
    },
  };

  render() {
    return (
      <div className="Login" style={{
        padding:"60px",
        minHeight: "100vh",
        boxSizing: "border-box",
        background: "var(--navy)"
      }}>
        <SnackBar
          variant="error"
          open={this.state.errorOpen}
          message={this.state.errorMessage}
          handleClose={() => {
            this.setState({ errorOpen: false });
          }}
        />
        {/* <SnackBar
          variant="error"
          open={true}
          message={
            "We are currently experiencing issues with our login service. Please be patient while we investigate the issue. If you have any questions please email us at support@swiftpost.com or call us at 1-888-655-6258"
          }
          handleClose={() => {
            this.setState({ errorOpen: false });
          }}
        /> */}
        <Grow in={true} timeout={500}>
          <Grid item xs={12} sm={7} md={5} style={{ margin: "auto" }}>
            <img src={Logo} className="Paper" alt="Swiftpost Logo" />
            <Paper
              elevation={6}
              style={{ padding: "30px", maxWidth: "400px" }}
              className="Paper"
            >
              <Typography variant="headline" component="h3">
                {this.language.Signintoyouraccount[this.state.language]}
              </Typography>

              <form onSubmit={this.handleSubmit}>
                <Grid
                  className="Input no-margin"
                  style={{
                    width: "100%",
                  }}
                  container
                  spacing={8}
                  alignItems="flex-end"
                >
                  <Grid item xs={1}>
                    <FaceOutlined />
                  </Grid>
                  <Grid item xs={11}>
                    <TextField
                      style={{ width: "100%" }}
                      id="email"
                      onChange={this.handleChange}
                      value={this.state.email}
                      type="email"
                      label={this.language.EmailAddress[this.state.language]}
                    />
                  </Grid>
                </Grid>

                <Grid
                  className="Input no-margin"
                  style={{
                    width: "100%",
                  }}
                  container
                  spacing={8}
                  alignItems="flex-end"
                >
                  <Grid item xs={1}>
                    <LockOutlined />
                  </Grid>
                  <Grid item xs={11}>
                    <TextField
                      style={{ width: "100%" }}
                      id="password"
                      onChange={this.handleChange}
                      value={this.state.password}
                      type="password"
                      label={this.language.Password[this.state.language]}
                    />
                  </Grid>
                </Grid>

                <Grid
                  className="callout text-left"
                  style={{
                    width: "100%",
                  }}
                  container
                  spacing={8}
                  alignItems="flex-end"
                >
                  <Grid item xs={1} />
                  <Grid item xs={11}>
                    <Link to={`/forgotpassword?user=${this.state.email}`}>
                      {this.language.ForgotPassword[this.state.language]}
                    </Link>
                  </Grid>
                </Grid>

                <br />

                <Button
                  type="submit"
                  className="loginSubmit"
                  variant="contained"
                  color="primary"
                  style={{
                    width: "100%",
                  }}
                  onClick={this.handleSubmit}
                  disabled={this.state.isLoading}
                >
                  {!this.state.isLoading &&
                    this.language.Login[this.state.language]}
                  {this.state.isLoading &&
                    this.language.Loading[this.state.language]}
                </Button>
              </form>
            </Paper>
            <Grid
              item
              style={{ margin: "auto", padding: "0px", maxWidth: "400px" }}
            >
              <Typography className="callout">
                {this.language.Donthaveanaccount[this.state.language]}
              </Typography>
              <center>
                {this.state.isLoading ? (
                  <Button
                    disabled
                    variant="contained"
                    color="secondary"
                    style={{
                      width: "100%",
                    }}
                  >
                    {this.language.SignUpforFree[this.state.language]}
                  </Button>
                ) : (
                  <Link to="/signup">
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{
                        width: "100%",
                      }}
                    >
                      {this.language.SignUpforFree[this.state.language]}
                    </Button>
                  </Link>
                )}
              </center>
            </Grid>
          </Grid>
        </Grow>
      </div>
    );
  }
}
