import { Button, Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grow from "@material-ui/core/Grow";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { withStyles } from "@material-ui/core/styles";
import { API, graphqlOperation } from "aws-amplify";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import uuid from "uuid";
import getActiveDropoff from "../api/app/getActiveDropoff";
import Snackbar from "../components/Snackbar";
import Checkout from "../dialogs/Checkout";
import { isMeasurement } from "../helpers/Validation";
import Step0 from "./demo/pay0";
import Step1 from "./demo/pay1";
import Step2 from "./demo/pay2";
import Step3 from "./demo/pay3";

const styles = (theme) => ({
  root: {
    background: "transparent",
  },
  backButton: {
    marginRight: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
});

class PayAndShip extends React.Component {
  state = {
    weightError: false,
    snackOpen: false,
    selectedDropoff:
      this.props.account &&
      this.props.account.user &&
      this.props.account.user.fixedDropoff
        ? this.props.account.user.fixedDropoff
        : "",
    paymentId: 0,
    activeStep: 0,
    runningTotal: 0,
    selectedService: 0,
    total: 0,
    dropoff: "",
    card: -1,
    printed: 0,
    insured: false,
    insured_amount: 0,
    dropoffObj: {
      name: "",
      address: "",
      phone: "",
    },
    pickupPhone: "5555555555",
    tab:
      this.props.account &&
      this.props.account.user &&
      this.props.account.user.cannotDropoff
        ? this.props.account &&
          this.props.account.user &&
          this.props.account.user.hasDailyPickup
          ? 2
          : 0
        : 1,
    senderLocation: this.props.locations[0],
    senderLocationID: this.props.locations[0].id,
    package: {
      ref: "SWIFTPOST_BUNDLE",
      count: "1",
      insured: false,
      type: "box",
      dimensions: [
        {
          weight: "",
          height: "",
          length: "",
          width: "",
        },
      ],
    },
    fullBundleRate: 0,
    pickupStep: 0,
    cutoff: (
      <CircularProgress className={styles.progress} size={20} thickness={5} />
    ),
    bundleRate: (
      <CircularProgress className={styles.progress} size={20} thickness={5} />
    ),
    bundleSubtotal: 0.0,
    bundleTaxType: "",
    bundleTaxRate: 0.0,
    selectedPayment: false,
    hasUS: false,
    isLoading: false,
    progress: "",
    modalOpen: false,
    errorShipments: [],
    failedImages: [],
  };

  getSteps = () => {
    let arr = [
      // this.language.SelectMethod[this.state.language],
      // this.language.ConfirmTotal[this.state.language],
      // this.language.Checkavailablebalance[this.state.language],
      this.language.Confirmandsubmit[this.state.language],
    ];
    // if (
    //   this.props.account &&
    //   this.props.account.user &&
    //   this.props.account.user.fixedDropoff
    // ) {
    //   arr.splice(0, 1);
    // } else if (
    //   this.props.account &&
    //   this.props.account.user &&
    //   this.props.account.user.hideBilling
    // ) {
    //   arr.splice(1, 2);
    // } else if (
    //   this.props.account &&
    //   this.props.account.user &&
    //   this.props.account.user.noWallet
    // ) {
    //   arr.splice(2, 1);
    // }
    return arr;
  };

  setData = (name, data) => {
    this.setState({
      [name]: data,
    });
  };

  UNSAFE_componentWillMount = async () => {
    if (!this.props.selectedShipments.length) {
      this.props.history.push("/");
    }
    let language = getCookie("language");
    this.setState({ language: language !== "" ? language : "EN" });

    if (this.props.account.user.fixedDropoff) {
      console.log(true);
      console.log(this.props.account.user.fixedDropoff);
      let dropoffLocations = await getActiveDropoff();
      console.log(dropoffLocations);
      // (dropoffLocations) => {
      //   console.log(dropoffLocations);
      this.setState({
        dropoffObj: dropoffLocations.response.filter(
          (dropoffLocation) =>
            dropoffLocation.id === this.props.account.user.fixedDropoff
        )[0],
      });
      // });
    }
  };

  language = {
    SelectMethod: {
      FR: "Sélectionnez la méthode",
      EN: "Select Method",
    },
    ConfirmTotal: {
      FR: "Confirmer le total",
      EN: "Confirm Total",
    },
    Checkavailablebalance: {
      FR: "Vérifier les fonds disponibles",
      EN: "Check available balance",
    },
    Confirmandsubmit: {
      FR: "Confirmer et soumettre",
      EN: "Confirm and submit",
    },
    PayAndShip: {
      FR: "Payer et expédier",
      EN: "Print Labels",
    },
  };

  componentDidMount() {
    var test = this.props.shipment
      .filter((e) => this.props.selectedShipments.indexOf(e.id) > -1)
      .map((e) => Number(e.rate.amount));
    var total = test.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    this.setState({ runningTotal: Number(total).toFixed(2) });
  }

  setObjData = (name, objName, data) => {
    let temp = this.state[objName];
    temp[name] = data;
    this.setState({ [objName]: temp });
  };

  handlePaymentChange = (e) => {
    // console.log(e.id)
    this.setState({ selectedPayment: e.id });
  };

  handleObjChange = (name, objName) => (event) => {
    let temp = this.state[objName];
    temp[name] = event.target.value;
    this.setState({ [objName]: temp });
  };

  adjustCount = async (val) => {
    let temp = this.state.package;
    temp.count = parseInt(temp.count) + parseInt(val);
    this.setState({ package: temp });
    if (val > 0) {
      temp.dimensions.push({
        length: "",
        width: "",
        height: "",
        weight: "",
      });
    } else {
      temp.dimensions.pop();
    }
    this.setState({ package: temp });
  };

  getStepContent = (stepIndex) => {
    const { classes, ...childProps } = this.props;
    let arr = [
      <Grow in={true}>
        <Fragment>
          <Checkout
            failedShipments={this.state.errorShipments}
            {...this.state}
            open={this.props.checkoutModalOpen}
            progress={this.state.progress}
          />
          <Step3
            {...childProps}
            {...this.state}
            setData={this.setData}
            handleNext={this.handleNext}
            handleBack={this.handleBack}
            hasUs={this.state.hasUS}
          />
        </Fragment>
      </Grow>,
    ];
    return arr[stepIndex];
  };

  handleNext = async (stepper) => {
    let test = this.getSteps();

    if (test.length - 1 === this.state.activeStep) {
      this.props.isCheckingOut(true);

      // if(this.props.socket.readyState !== 1){
      // }

      //get balance
      const balance = await API.graphql(
        graphqlOperation(`query listBalances {
        listV1_Balances {
          items{
            id
            balance
            cognitoUsername
          }
        }
      }`)
      );

      if (
        this.roundTo(
          Number(balance.data.listV1_Balances.items[0].balance) -
            Number(this.state.runningTotal),
          2
        ) < 0 &&
        !this.props.account.user.isInvoiced
      ) {
        //force client refresh, state was messed with
        window.location.reload();
        return;
      }

      var tempBody = {
        body: {
          connection_id: this.props.connection_id,
          tab: this.state.tab,
          balance: balance.data.listV1_Balances.items[0].balance,
          amount: this.state.runningTotal,
          invoice_date: new Date().toLocaleDateString(),
          shipments: this.props.shipment.filter(
            (e) => this.props.selectedShipments.indexOf(e.id) > -1
          ),
          account: this.props.account,
        },
      };

      if (this.state.tab === 0) {
        if (this.state.hasUS) {
          tempBody.body.shipments.push({
            id: uuid.v4(),
            isNotAnOrder: true,
            reference: "SWIFTPOST BUNDLE",
            tracking: null,
            label: null,
            insured: false,
            contents: null,
            sender: this.state.senderLocation,
            receiver: {
              title: "SWIFTPOST",
              contact: {
                fname: "SWIFTPOST",
                lname: "RECEIVING",
                email: "noreply@swiftpost.com",
                phone: "18886556258",
              },
              address: {
                city: "OAKVILLE",
                street_name: "S SERVICE RD W",
                street_number: "2130",
                state: "ON",
                postal: "L6L5N1",
                country: "CA",
              },
            },
            rate: this.state.fullBundleRate,
            package: {
              entered_pld: this.state.package.dimensions,
            },
          });
        }
        tempBody.body.pickupAddress = this.state.senderLocation;
        tempBody.body.pickupPhone = this.state.pickupPhone;
      } else {
        tempBody.body.dropoff_printRequested = this.state.printed;
        tempBody.body.dropoffLocation_id = this.state.selectedDropoff;
      }

      await API.post("sqs", "/checkout", tempBody);

      return;
    } else if (test.length - 2 === this.state.activeStep) {
      // await this.props.refreshSocket();
    }
    this.setState({
      activeStep: this.state.activeStep + 1,
    });
  };

  roundTo = (n, digits) => {
    if (n === 0) {
      return 0;
    }
    var negative = false;
    if (digits === undefined) {
      digits = 0;
    }
    if (n < 0) {
      negative = true;
      n = n * -1;
    }
    var multiplicator = Math.pow(10, digits);
    n = parseFloat((n * multiplicator).toFixed(11));
    n = (Math.round(n) / multiplicator).toFixed(2);
    if (negative) {
      n = (n * -1).toFixed(2);
    }
    return n;
  };

  handleBack = (stepper) => {
    const activeStep = this.state.activeStep;
    if (activeStep === 0) {
      this.props.history.push("/");
    }
    if (activeStep === 3) {
      this.setState({
        activeStep: this.state.activeStep - 2,
      });
    }
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };

  checkInput = () => {
    return true;
  };

  handleLocationChange = (e) => {
    this.setState({ senderLocation: e.location });
    this.setState({ senderLocationID: e.id });
  };

  handleDimensionChange = (i, name) => (event) => {
    if (name === "weight") {
      if (
        this.state.package.dimensions.reduce(
          (a, b) => Number(b.weight) + a,
          0
        ) +
          Number(event.target.value) >
        500
      ) {
        this.setState({ weightError: true });
        return;
      }
    }

    switch (name) {
      case "weight":
        if (Number(event.target.value) > 66) {
          return;
        } else {
          break;
        }
      case "length":
        if (Number(event.target.value) > 45) {
          return;
        } else {
          break;
        }
      case "width":
        if (Number(event.target.value) > 30) {
          return;
        } else {
          break;
        }
      case "height":
        if (Number(event.target.value) > 30) {
          return;
        } else {
          break;
        }
      default:
        break;
    }

    if (event.target.value === "" || isMeasurement(event.target.value)) {
      let copy = this.state.package;
      copy.dimensions[i][name] = event.target.value;
      this.setState({ package: copy });
    }
  };

  render() {
    const steps = this.getSteps();
    const { activeStep } = this.state;
    // alert(this.props.account && this.props.account.user && this.props.account.user.userId ? this.props.account.user.userId : '')

    // console.log(this.props.account.user);

    return (
      <Fragment>
        <Snackbar
          variant="error"
          duration="5000"
          open={this.state.weightError}
          message={"Total weight of all shipments cannot exceed 500lb."}
          handleClose={() => {
            this.setState({ weightError: false });
          }}
        />
        <Snackbar
          variant="error"
          action={[
            <Button
              style={{ color: "#ffffff" }}
              size="small"
              onClick={() => this.setState({ snackOpen: false })}
            >
              OK
            </Button>,
          ]}
          duration="60000"
          open={this.state.snackOpen}
          message={
            "We were unable to process your order. If this issue continues, please contact us."
          }
          handleClose={() => {
            this.setState({ snackOpen: false });
          }}
        />
        {/* TODO NOW */}

        <div className="Home">
          <Typography variant="h1">
            {this.language.PayAndShip[this.state.language]}
          </Typography>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            style={{ background: "transparent" }}
          >
            {steps.map((label) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {this.getStepContent(activeStep)}
        </div>
      </Fragment>
    );
  }
}

PayAndShip.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(PayAndShip);
