import { Tooltip } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import red from "@material-ui/core/colors/red";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import { Lock } from "@material-ui/icons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { API, graphqlOperation } from "aws-amplify";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import React, { Fragment } from "react";

const styles = (theme) => ({
  card: {
    [theme.breakpoints.up("lg")]: {
      width: "45%",
    },
    [theme.breakpoints.down("md")]: {
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    marginRight: 20,
    marginBottom: 20,
    float: "left",
  },
  actions: {
    display: "flex",
    float: "right",
  },
  avatar: {
    backgroundColor: red[500],
  },
  avatar2: {
    backgroundColor: "#fff",
    height: "auto",
    width: 40,
  },
});

class LocationCard extends React.Component {
  state = {
    anchorEl: null,
    active: true,
  };

  UNSAFE_componentWillMount = () => {
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });
  };

  language = {};

  handleRemove = async (id) => {
    let location = await API.graphql(
      graphqlOperation(`mutation {
      deleteV1_Location(input: { id:"${id}" }){
        id
        cognitoUsername
      }
    }`)
    );
    this.handleClose();
  };

  handleExpandClick = () => {
    this.setState((state) => ({ expanded: !state.expanded }));
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const { classes } = this.props;
    // console.log(this.props.data)
    return (
      <Card className={classes.card}>
        <CardHeader
          avatar={
            this.props.data.url ? (
              // <Avatar src={`data:image/png;base64, ${this.props.data.logo}`} aria-label="Recipe" className={classes.avatar2} />
              <img src={`${this.props.data.url}`} className={classes.avatar2} />
            ) : (
              <Avatar aria-label="Recipe" className={classes.avatar}>
                {this.props.data.title[0]}
                {this.props.data.title.indexOf(" ") > -1
                  ? this.props.data.title.split(" ")[1][0]
                  : ``}
              </Avatar>
            )
          }
          action={
            this.props.data.locked === "true" ? (
              <Tooltip title={"Locked location"}>
                <Lock style={{ color: "#999", fontSize: "18px" }} />
              </Tooltip>
            ) : (
              <div>
                <IconButton
                  aria-label="More"
                  aria-owns={anchorEl ? "long-menu" : null}
                  aria-haspopup="true"
                  onClick={this.handleClick}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="long-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={this.handleClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  {/* <MenuItem onClick={this.handleEdit}>Edit</MenuItem> */}
                  <MenuItem
                    onClick={() => this.handleRemove(this.props.data.id)}
                  >
                    {this.props.language == "FR" ? "Retirer" : "Remove"}
                  </MenuItem>
                </Menu>
              </div>
            )
          }
          title={this.props.data.title}
          subheader={
            <Fragment>
              {this.props.data.address.unit
                ? `#${this.props.data.address.unit} - `
                : ``}
              {this.props.data.address.street_number}{" "}
              {this.props.data.address.street_name}
              <br />
              {this.props.data.address.city}, {this.props.data.address.state}{" "}
              {this.props.data.address.postal}
            </Fragment>
          }
        />
        {/* <CardActions className={classes.actions} disableActionSpacing>
          <IconButton aria-label="Add to favorites">
            <FavoriteIcon />
          </IconButton>
          <IconButton aria-label="Share">
            <ShareIcon />
          </IconButton>
        </CardActions> */}
      </Card>
    );
  }
}

LocationCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LocationCard);

// import React, { Fragment } from 'react';
// import PropTypes from 'prop-types';
// import { withStyles } from '@material-ui/core/styles';
// import Card from '@material-ui/core/Card';
// import Grow from '@material-ui/core/Grow';
// import CardHeader from '@material-ui/core/CardHeader';
// import CardMedia from '@material-ui/core/CardMedia';
// import CardContent from '@material-ui/core/CardContent';
// import IconButton from '@material-ui/core/IconButton';
// import Typography from '@material-ui/core/Typography';
// import yellow from '@material-ui/core/colors/yellow';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';

// const styles = theme => ({
// card: {
//     [theme.breakpoints.up('md')]: {
//       width: 345,
//     },
//     [theme.breakpoints.down('sm')]: {
//       width: '100%',
//     },
//   marginRight: 20,
//   marginBottom: 20,
//   float:'left',
// },
//   media: {
//     height: 0,
//     paddingTop: '56.25%', // 16:9
//   },
//   actions: {
//     display: 'flex',
//   },
//   expand: {
//     transform: 'rotate(0deg)',
//     transition: theme.transitions.create('transform', {
//       duration: theme.transitions.duration.shortest,
//     }),
//     marginLeft: 'auto',
//     [theme.breakpoints.up('sm')]: {
//       marginRight: -8,
//     },
//   },
//   expandOpen: {
//     transform: 'rotate(180deg)',
//   },
//   avatar: {
//     backgroundColor: yellow[500],
//   },
// });

// class LocationCard extends React.Component {
// state = {
//   anchorEl: null,
//   active: true,
// };

// handleClick = event => {
//   this.setState({ anchorEl: event.currentTarget });
// };

// handleClose = () => {
//   this.setState({ anchorEl: null });
// };

//   handleRemove = () => {

//     //TODO, finish removal logic
//     // let sample = this.props.user.location.map((e,i) => e.id === this.props.data.id ?  e.id : false)
//     // sample = sample.filter(e=>e !== false)
//     // if(this.props.shipment.filter(e =>{ return e.sender === sample[0]; }).length > 0){
//     //   alert(`This location is currently being used by ${this.props.shipment.filter(e => e.sender === sample[0]).length} pending shipments. Please remove or send your pending shipments before removing this location.`)
//     //   return
//     // }
//     //END

//     this.handleClose();alert("TODO");
//     // this.props.removeLocation(this.props.data.id);

//   };

//   // handleEdit = () => {
//   //   // this.setState({ active: false });
//   //   this.handleClose();
//   //   this.props.editLocation(this.props.data.id)
//   // };

//   render() {
//     const { anchorEl } = this.state;
//     const { classes } = this.props;

//     return (
//     <Grow in={this.state.active} timeout={(this.props.number+1) * 300} key={this.props.number} >
//         <Card className={classes.card} square>
//         <CardMedia
//           // style={{'height': 0,'paddingTop': '56.25%'}}
//           // image={`https://s3.amazonaws.com/swiftpost-img/maps/${this.props.data.image}`}
//           title={this.props.data.title}
//         />
//         <CardHeader style={{'paddingBottom':'0px'}}
// action={
//   <div>
//     <IconButton
//       aria-label="More"
//       aria-owns={anchorEl ? 'long-menu' : null}
//       aria-haspopup="true"
//       onClick={this.handleClick}
//     >
//       <MoreVertIcon />
//     </IconButton>
//     <Menu
//       id="long-menu"
//       anchorEl={anchorEl}
//       open={Boolean(anchorEl)}
//       onClose={this.handleClose}
//       anchorOrigin={{
//           vertical: 'top',
//           horizontal: 'right',
//       }}
//       transformOrigin={{
//           vertical: 'top',
//           horizontal: 'right',
//       }}
//   >
//     {/* <MenuItem onClick={this.handleEdit}>Edit</MenuItem> */}
//     <MenuItem onClick={this.handleRemove}>Remove</MenuItem>
//   </Menu>
// </div>
//           }
//         title={<Fragment>{this.props.data.logo && <img style={{height:30,verticalAlign:'top',float:'right'}} src={`data:image/png;base64,${this.props.data.logo}`}/>}{<div style={{'margin': '5px 0 10px 0', 'fontSize':'1.2rem'}}>{this.props.data.title}</div>}</Fragment>}
//         />
//         <CardContent style={{'paddingTop':'0px'}}>
//           <Typography component="p">
//             {this.props.data.address.unit != -1 && `#${this.props.data.address.unit} - `}{this.props.data.address.street_number} {this.props.data.address.street_name}<br/>
//             {this.props.data.address.city},&nbsp;{this.props.data.address.state}&nbsp;{this.props.data.address.postal}
//           </Typography>
//         </CardContent>
//       </Card>
//     </Grow>
//     );
//   }
// }

// LocationCard.propTypes = {
//   classes: PropTypes.object.isRequired,
// };

// export default withStyles(styles)(LocationCard);

// // import React, { Fragment } from 'react';
// // import PropTypes from 'prop-types';
// // import { withStyles } from '@material-ui/core/styles';
// // import classnames from 'classnames';
// // import Card from '@material-ui/core/Card';
// // import Grow from '@material-ui/core/Grow';
// // import CardHeader from '@material-ui/core/CardHeader';
// // import CardMedia from '@material-ui/core/CardMedia';
// // import CardContent from '@material-ui/core/CardContent';
// // import CardActions from '@material-ui/core/CardActions';
// // import Collapse from '@material-ui/core/Collapse';
// // import Avatar from '@material-ui/core/Avatar';
// // import IconButton from '@material-ui/core/IconButton';
// // import Typography from '@material-ui/core/Typography';
// // import yellow from '@material-ui/core/colors/yellow';
// // import FavoriteIcon from '@material-ui/icons/Favorite';
// // import ShareIcon from '@material-ui/icons/Share';
// // import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// // import MoreVertIcon from '@material-ui/icons/MoreVert';
// // import Menu from '@material-ui/core/Menu';
// // import MenuItem from '@material-ui/core/MenuItem';

// // import {
// //     AddCircleOutline,
// //     MoreVert,
// //     Star,
// //     StarBorder,
// //     Delete,
// // } from '@material-ui/icons';

// // const styles = theme => ({
// //   card: {
// //       [theme.breakpoints.up('md')]: {
// //         width: 345,
// //       },
// //       [theme.breakpoints.down('sm')]: {
// //         width: '100%',
// //       },
// //     marginRight: 20,
// //     marginBottom: 20,
// //     float:'left',
// //   },
// //   media: {
// //     height: 0,
// //     paddingTop: '56.25%', // 16:9
// //   },
// //   actions: {
// //     display: 'flex',
// //   },
// //   expand: {
// //     transform: 'rotate(0deg)',
// //     transition: theme.transitions.create('transform', {
// //       duration: theme.transitions.duration.shortest,
// //     }),
// //     marginLeft: 'auto',
// //     [theme.breakpoints.up('sm')]: {
// //       marginRight: -8,
// //     },
// //   },
// //   expandOpen: {
// //     transform: 'rotate(180deg)',
// //   },
// //   avatar: {
// //     backgroundColor: yellow[500],
// //   },
// // });

// // class LocationCard extends React.Component {
// //   state = {
// //     anchorEl: null,
// //     active: true,
// //   };

// //   handleClick = event => {
// //     this.setState({ anchorEl: event.currentTarget });
// //   };

// //   handleClose = () => {
// //     this.setState({ anchorEl: null });
// //   };

// //   handleRemove = () => {

// //     //TODO, finish removal logic
// //     // let sample = this.props.user.location.map((e,i) => e.id === this.props.data.id ?  e.id : false)
// //     // sample = sample.filter(e=>e !== false)
// //     // if(this.props.shipment.filter(e =>{ return e.sender === sample[0]; }).length > 0){
// //     //   alert(`This location is currently being used by ${this.props.shipment.filter(e => e.sender === sample[0]).length} pending shipments. Please remove or send your pending shipments before removing this location.`)
// //     //   return
// //     // }
// //     //END

// //     this.handleClose();
// //     this.props.removeLocation(this.props.data.id)
// //   };

// //   handleEdit = () => {
// //     // this.setState({ active: false });
// //     this.handleClose();
// //     this.props.editLocation(this.props.data.id)
// //   };

// //   render() {
// //     const { anchorEl } = this.state;
// //     const { classes } = this.props;

// //     return (
// //     <Grow in={this.state.active} timeout={(this.props.number+1) * 300} key={this.props.number} >
// //         <Card className={classes.card}>
// //         <CardMedia
// //           // style={{'height': 0,'paddingTop': '56.25%'}}
// //           // image={`https://s3.amazonaws.com/swiftpost-img/maps/${this.props.data.image}`}
// //           title={this.props.data.title}
// //         />
// //         <CardHeader style={{'paddingBottom':'0px'}}
// //           action={
// //             <div>
// //               <IconButton
// //                 aria-label="More"
// //                 aria-owns={anchorEl ? 'long-menu' : null}
// //                 aria-haspopup="true"
// //                 onClick={this.handleClick}
// //               >
// //                 <MoreVertIcon />
// //               </IconButton>
// //               <Menu
// //                 id="long-menu"
// //                 anchorEl={anchorEl}
// //                 open={Boolean(anchorEl)}
// //                 onClose={this.handleClose}
// //                 anchorOrigin={{
// //                     vertical: 'top',
// //                     horizontal: 'right',
// //                 }}
// //                 transformOrigin={{
// //                     vertical: 'top',
// //                     horizontal: 'right',
// //                 }}
// //               >
// //                 {/* <MenuItem onClick={this.handleEdit}>Edit</MenuItem> */}
// //                 <MenuItem onClick={this.handleRemove}>Remove</MenuItem>
// //               </Menu>
// //             </div>
// //           }
// //         title={<Fragment>{this.props.data.logo && <img style={{height:30,verticalAlign:'top',float:'right'}} src={`data:image/png;base64,${this.props.data.logo}`}/>}{<div style={{'margin': '5px 0 10px 0', 'fontSize':'1.2rem'}}>{this.props.data.title}</div>}</Fragment>}
// //         />
// //         <CardContent style={{'paddingTop':'0px'}}>
// //           <Typography component="p">
// //             {this.props.data.address.unit != -1 && `#${this.props.data.address.street_number} - `}{this.props.data.address.street_name}<br/>
// //             {this.props.data.address.city},&nbsp;{this.props.data.address.state}&nbsp;{this.props.data.address.postal}
// //           </Typography>
// //         </CardContent>
// //       </Card>
// //     </Grow>
// //     );
// //   }
// // }

// // LocationCard.propTypes = {
// //   classes: PropTypes.object.isRequired,
// // };

// // export default withStyles(styles)(LocationCard);
