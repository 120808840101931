/* eslint-disable react/no-multi-comp */

import React, { Fragment } from "react";
import { Auth } from "aws-amplify";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import blue from "@material-ui/core/colors/blue";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Tooltip from "@material-ui/core/Tooltip";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Stepper from "./Stepper";
import { TextField, Typography, Divider } from "@material-ui/core";
import uuid from "uuid";
import CSVReader from "react-csv-reader";
import $ from "jquery";
import LoadingIcon from "../../../components/loading/LoadingIcon";
import {
  isValidEmail,
  isAlphaNumericWithApostrophe,
  isValidPhoneNumber,
  isValidPostal,
  isNotEmpty,
  isAlphaWithSpace,
  isAlphaNumericWithSpace,
} from "../../../helpers/Validation";

const styles = {
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
};

class UploadDialog extends React.Component {
  state = {
    open: false,
    postal: "",
    data: [],
    cleanData: [],
    isLoading: true,
  };

  UNSAFE_componentWillMount = async () => {
    let emails = this.props.data.map((e) => e.email.toUpperCase());
    emails = Array.from(new Set(emails));

    this.props.data.map((e) => {
      console.log([
        isAlphaNumericWithApostrophe(e.business),
        isValidEmail(e.email),
        isValidPhoneNumber(e.phone),
        isAlphaNumericWithSpace(e.fname),
        isAlphaNumericWithSpace(e.lname),
        isAlphaNumericWithSpace(e.street_number),
        isAlphaNumericWithApostrophe(e.street_name),
        isAlphaWithSpace(e.city),
        isAlphaWithSpace(e.state),
        isValidPostal(e.postal),
        isAlphaWithSpace(e.country),
      ]);
    });

    let step1 = this.props.data.filter((e) => {
      return (
        isAlphaNumericWithApostrophe(e.business) &&
        isValidEmail(e.email) &&
        isValidPhoneNumber(e.phone) &&
        isAlphaNumericWithSpace(e.fname) &&
        isAlphaNumericWithSpace(e.lname) &&
        isAlphaNumericWithSpace(e.street_number) &&
        isAlphaNumericWithApostrophe(e.street_name) &&
        isAlphaWithSpace(e.city) &&
        isAlphaWithSpace(e.state) &&
        isValidPostal(e.postal) &&
        isAlphaWithSpace(e.country)
      );
    });

    console.log("Step1", step1);

    const badDataCount = this.props.data.length - step1.length;

    let badEmails = emails.filter(
      (e) =>
        step1.filter((f) => f.email.toUpperCase() === e.toUpperCase()).length >
        1
    );
    // console.log("BAD EMAILS",badEmails)
    let step2 = this.props.data.filter(
      (e) => badEmails.indexOf(e.email.toUpperCase()) <= -1
    );
    // console.log("step2",step2)
    const repeatEmailCount = step1.length - step2.length;
    let dupeEmails = [];

    // console.log(step2.map(e=>e.email.toUpperCase()));

    dupeEmails = await this.props.checkEmails(
      step2.map((e) => e.email.toUpperCase())
    );
    console.log("DUPES=>", dupeEmails);
    let step3 = step2.filter(
      (e) => !(dupeEmails.indexOf(e.email.toUpperCase()) > -1)
    );
    // console.log("step3",step3);
    const duplicateEmailCount = step2.length - step3.length;
    // console.log(duplicateEmailCount);

    // console.log(step2)
    this.setState({ cleanData: step3 });

    console.log();
    this.setState({
      validCount: step3.length,
      invalidCount: badDataCount,
      emailCount: repeatEmailCount + duplicateEmailCount,
      isLoading: false,
    });
  };

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={() => {
            this.setState({ postal: "" });
            this.props.handleClose();
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="form-dialog-title">Results from upload</DialogTitle>
          <DialogContent style={{ padding: "20px 50px" }}>
            <br />
            <div>
              {this.state.isLoading ? (
                <center>
                  <LoadingIcon />
                </center>
              ) : (
                <Fragment>
                  <h3>
                    Valid rows:{" "}
                    <span style={{ color: "green" }}>
                      {this.state.validCount}
                    </span>
                    <br />
                    <br />
                    <Divider />
                    <br />
                    {this.state.invalidCount > 0 && (
                      <span>
                        Invalid Data:{" "}
                        <span style={{ color: "red" }}>
                          {this.state.invalidCount}
                        </span>
                        <br />
                      </span>
                    )}
                    {this.state.emailCount > 0 && (
                      <span>
                        Duplicate Emails:{" "}
                        <span style={{ color: "red" }}>
                          {this.state.emailCount}
                        </span>
                      </span>
                    )}
                    <br />
                    <br />
                  </h3>
                  <small></small>
                </Fragment>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            {this.state.validCount === 0 && (
              <Button
                onClick={this.props.handleClose}
                disabled={this.state.isLoading}
                color="primary"
              >
                Close
              </Button>
            )}
            {this.state.validCount > 0 && (
              <Button
                onClick={this.props.handleClose}
                disabled={this.state.isLoading}
                color="primary"
              >
                Cancel
              </Button>
            )}
            {this.state.validCount > 0 && (
              <Button
                disabled={this.state.isLoading}
                onClick={() => {
                  this.props.createAccount(this.state.cleanData);
                }}
                color="primary"
              >
                Import ({this.state.validCount})
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default UploadDialog;
