import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Theme,
  withStyles,
} from "@material-ui/core";
import { DialogProps } from "@material-ui/core/Dialog";
import { Close } from "@material-ui/icons";
import { Country } from "@shared";
import AddressBookTable from "pages/address_book/AddressBookTable";
import { SearchAddressesInput } from "pages/address_book/SearchAddresssesInput";
import { FC, useState } from "react";
import { AddressBookRecord } from "../../../shared/types/address";

const styles = (theme: Theme) => ({
  closeButton: {
    position: "absolute" as const,
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
});

const AddressBookPickerDialog: FC<{
  country: Country;
  open: boolean;
  onClose: DialogProps["onClose"];
  onSelect: (address: AddressBookRecord) => void;
  handleLogout: () => void;
  classes: any;
}> = ({ country, open, onClose, onSelect, handleLogout, classes }) => {
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle>
        Select address
        <IconButton
          aria-label="Close"
          onClick={onClose}
          className={classes.closeButton}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div style={{ marginBottom: "2em" }}>
          <SearchAddressesInput onChange={setSearchTerm} />
        </div>
        <AddressBookTable
          country={country}
          searchTerm={searchTerm}
          onRowClicked={onSelect}
          handleLogout={handleLogout}
        />
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(AddressBookPickerDialog);
