import { AddressBookRecord } from "@shared";
import { createAddressBookEntry, verifyAddress } from "api/addressBook";
import { withoutEmptyStrings } from "helpers/forms";
import { ParseResult } from "papaparse";
import { useAddressBook } from "providers/AddressBook";
import {
  createContext,
  Dispatch,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";
import uuid from "uuid";
import { ValidationError } from "yup";
import { AddressFormSchema } from "../AddressFormSchema";
import { hasVerifiedAddressMismatch, recordToString } from "../helpers";
import { ImportRecord } from "./CSVImportTable";

interface Options {
  updateState?: boolean;
}
interface AddressCSVImporterContextValues {
  processAndImportCSV: (csvData: any) => Promise<void>;
  buildImportRecords: (addresses: AddressBookRecord[]) => ImportRecord[];
  importRecords: ImportRecord[];
  setImportRecords: Dispatch<React.SetStateAction<ImportRecord[]>>;
  setCsv: Dispatch<
    React.SetStateAction<ParseResult<AddressBookRecord> | undefined>
  >;
  csv: ParseResult<AddressBookRecord> | undefined;
  importRecord(record: ImportRecord, options?: Options): Promise<ImportRecord>;
  updateImportRecord(record: ImportRecord): void;
}

type CSVRow = Omit<AddressBookRecord, "address2"> & {
  unit_number?: string;
};

export const AddressCSVImporterContext =
  createContext<AddressCSVImporterContextValues>(
    {} as AddressCSVImporterContextValues
  );

export function AddressCSVImporterProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [csv, setCsv] = useState<Papa.ParseResult<CSVRow> | undefined>(
    undefined
  );
  const { addresses: allAddresses } = useAddressBook();
  const [importRecords, setImportRecords] = useState<ImportRecord[]>([]);

  // Create "ImportRecord" objects from AddressBook address data
  // These objects wrap the basic address with properties for keeping track of meta data regarding
  // the status of the import process.
  const buildImportRecords = useCallback((csvData: CSVRow[]) => {
    // Map "CSVRow" to "Address" as needed
    // "unit_number" in CSV -> "address2"
    const addresses = csvData.map((row) => {
      const unitNumber = row.unit_number;
      delete row.unit_number;
      const address: AddressBookRecord = { ...row, address2: unitNumber };
      return address;
    });

    const importRecords = addresses.map((address) => {
      const record: ImportRecord = {
        refId: uuid.v4(),
        importedAddress: address,
        errors: [],
        importState: "processing",
      };

      return record;
    });

    return importRecords;
  }, []);

  // Client-side validate ImportRecords by determining if any thing appears to be wrong with them before
  // sending them along the process of verification and creation
  const validateImportRecords = useCallback((records: ImportRecord[]) => {
    return records.map((record) => {
      if (record.importState !== "processing") {
        return record;
      }

      // Strip whitespace from postal code
      if (record.importedAddress.postal) {
        record.importedAddress.postal =
          record.importedAddress.postal.replaceAll(/\W/g, "");
      }

      // Remove blank strings as validation step will count those as value
      const scrubbedAddress = withoutEmptyStrings(record.importedAddress);

      try {
        // Do not abort early or we won't get _all_ errors for the row
        AddressFormSchema.validateSync(scrubbedAddress, {
          abortEarly: false,
        });
      } catch (error) {
        if (error instanceof ValidationError) {
          record.errors.push({
            message: error.errors.join(". "),
          });
        } else {
          record.errors.push({
            message: "This record was not valid for an unknown reason",
          });
        }

        record.importState = "error";
      }

      return record;
    });
  }, []);

  const verifyRecord = useCallback(async (record: ImportRecord) => {
    if (record.importState === "error") {
      return record;
    }

    try {
      const data = await verifyAddress(record.importedAddress);
      const verifiedAddress = { ...record.importedAddress, ...data };

      record.verifiedAddress = verifiedAddress;
      record.importState = "verified";

      return record;
    } catch (error) {
      console.error(error);
      record.errors.push({ message: "Error while verifying this address" });
      record.importState = "error";
      return record;
    }
  }, []);

  const verifyRecords = useCallback(
    (records: ImportRecord[]) => {
      const promises = records.map(async (record) => {
        if (record.importState === "processing") {
          return await verifyRecord(record);
        } else {
          return record;
        }
      });

      return Promise.all(promises);
    },
    [verifyRecord]
  );

  // Check if two AddressBookRecord's match, ignoring case, spacing, etc
  const addressRecordMatches = useCallback(
    (address1: AddressBookRecord, address2: AddressBookRecord) => {
      const normalizedAddress1 = recordToString(address1).toUpperCase();
      const normalizedAddress2 = recordToString(address2).toUpperCase();
      return normalizedAddress1 === normalizedAddress2;
    },
    []
  );

  const checkForMistmatches = useCallback((records: ImportRecord[]) => {
    return records.map((record) => {
      if (record.importState === "error") {
        return record;
      }

      if (hasVerifiedAddressMismatch(record)) {
        record.importState = "mismatch";
      }

      return record;
    });
  }, []);

  const checkForDuplicates = useCallback(
    (records: ImportRecord[]) => {
      return records.map((record) => {
        if (record.importState === "error") {
          return record;
        }

        if (!record.verifiedAddress) {
          return record;
        }

        const duplicate = allAddresses.find((address) => {
          return addressRecordMatches(record.verifiedAddress!, address);
        });

        if (duplicate) {
          record.importState = "duplicate";
        }

        return record;
      });
    },
    [allAddresses, addressRecordMatches]
  );

  const updateImportRecord = useCallback((record: ImportRecord) => {
    setImportRecords((records) =>
      records.map((r) => {
        if (r.refId === record.refId) {
          return record;
        } else {
          return r;
        }
      })
    );
  }, []);

  const importRecord = useCallback(
    async (record: ImportRecord, options?: Options) => {
      try {
        if (record.verifiedAddress) {
          await createAddressBookEntry(
            withoutEmptyStrings(record.verifiedAddress)
          );
          record.importState = "imported";

          if (options?.updateState) {
            updateImportRecord(record);
          }
        } else {
          throw new Error("No verified address");
        }
      } catch (error) {
        record.errors.push({ message: "Error while importing record" });
        record.importState = "error";
      }

      return record;
    },
    [updateImportRecord]
  );

  const importMultipleRecords = useCallback(
    (records: ImportRecord[]) => {
      return Promise.all(
        records.map(async (record) => await importRecord(record))
      );
    },
    [importRecord]
  );

  const processAndImportCSV = useCallback(
    async (csvData) => {
      let records = buildImportRecords(csvData);
      records = validateImportRecords(records);
      records = await verifyRecords(records);
      records = checkForDuplicates(records);
      records = checkForMistmatches(records);
      await importMultipleRecords(
        records.filter((r) => r.importState === "verified")
      );
      setImportRecords(records);
    },
    [
      importMultipleRecords,
      buildImportRecords,
      validateImportRecords,
      verifyRecords,
      checkForDuplicates,
      checkForMistmatches,
    ]
  );

  return (
    <AddressCSVImporterContext.Provider
      value={{
        csv,
        setCsv,
        importRecord,
        updateImportRecord,
        processAndImportCSV,
        buildImportRecords,
        importRecords,
        setImportRecords,
      }}
    >
      {children}
    </AddressCSVImporterContext.Provider>
  );
}

export function useAddressCSVImporter() {
  return useContext(AddressCSVImporterContext);
}
