import { API, graphqlOperation } from "aws-amplify";

export const listShipments = () =>
  API.graphql(
    graphqlOperation(`
      query listShipments {
        listV1_Shipments {
          items {
            location_note
            open_time
            close_time
            shipForChild
            receiver_invalid
            date_created
            date_updated
            platform
            platform_receiptid
            platform_shopid
            platform_orderid
            sender_id
            id
            cognitoUsername
            reference
            tracking
            label
            insured
            insured_value
            customer_note
            quick_search
            packing_slip{
              title
              item_quantity
              item_value
            }
            contents_type
            contents{
                title
                item_quantity
                item_value
                origin
                hs_code
            }
            sender{
              title
              contact{
                fname
                lname
                email
                phone
              }
              address{
                city
                street_name
                street_number
                state
                postal
                country
                unit
              }
              open_time
              close_time
            }
            receiver{
              title
              contact{
                fname
                lname
                email
                phone
              }
              address{
                city
                street_name
                street_number
                address2
                state
                postal
                country
                unit
              }
              open_time
              close_time
            }
            rate{
              amount
              subtotal
              rate_id
              tax1_code
              tax2_code
              tax1_rate
              tax2_rate
              tax1_amount
              tax2_amount
              residential_charge
              fuel_surcharge
              rural_charge
              xc_charge
              ea_charge
              return_charge
              sender_accessorials
              receiver_accessorials
              accessorial_1_name
              accessorial_1_charge
              accessorial_2_name
              accessorial_2_charge
              accessorial_3_name
              accessorial_3_charge
              accessorial_4_name
              accessorial_4_charge
              service{
                name
                courier
                courier_code
              }
            }
            package{
              entered_pld{
                weight
                height
                length
                width
                item_description
                item_value
                item_quantity
                fulfillment_type
              }
            }
          }
        }
      }
    `)
  );
