import {
  Button,
  Divider,
  FilledInput,
  FormControl,
  Hidden,
  InputBase,
  InputLabel,
  Select,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
// import LushOpenClaim from './LushOpenClaim';
import { fade } from "@material-ui/core/styles/colorManipulator";
import Typography from "@material-ui/core/Typography";
import {
  ArrowDropDownCircle,
  ChevronLeft,
  ChevronRight,
  CloseOutlined,
} from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import { Auth } from "aws-amplify";
import axios from "axios";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import listShipments2 from "../api/listShipments2";
import LoadingIcon from "../components/loading/LoadingIcon";
import ExportModal from "./ExportModal";
import Shipment from "./Shipment";
import ShipmentRow from "./ShipmentRow";
import RoutesLogo from "../img/routes_logo.svg";
import { querySelfDeliveryAccount } from "api/selfDelivery";
// import MobileFixedSearch from './MobileFixedSearch';

const styles = (theme) => ({
  text: {
    paddingTop: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  button: {
    margin: theme.spacing.unit * 1.5,
  },
  paper: {
    // paddingBottom: 50,
  },
  list: {
    // marginBottom: theme.spacing.unit * 2,
    // maxHeight: '500px',
    // overflow: 'scroll',
    paddingTop: 0,
  },
  subHeader: {
    // backgroundColor: theme.palette.background.paper,
  },
  grey: {
    height: "20px",
    width: "20px",
    backgroundColor: "#FFC300",
  },
  yellow: {
    height: "20px",
    width: "20px",
    backgroundColor: "#FFC300",
  },
  red: {
    height: "20px",
    width: "20px",
    backgroundColor: "#C70039",
  },
  green: {
    height: "20px",
    width: "20px",
    backgroundColor: "#52BE80",
  },
  avatar: {
    background: "transparent",
    color: "grey",
    height: "20px",
    width: "20px",
    [theme.breakpoints.down("sm")]: {
      alignSelf: "flex-start",
      marginTop: "5px",
    },
    [theme.breakpoints.up("sm")]: {
      alignSelf: "center",
    },
  },
  activeAvatar: {
    background: "green",
    transition: ".1s all ease",
    [theme.breakpoints.down("sm")]: {
      alignSelf: "flex-start",
      marginTop: "5px",
    },
    [theme.breakpoints.up("sm")]: {
      alignSelf: "center",
    },
  },
  appBar: {
    top: "auto",
    bottom: 0,
  },
  toolbar: {
    alignItems: "center",
    justifyContent: "space-between",
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto",
  },
  activeButton: {
    background: "#eafaea",
    transition: ".3s all ease",
  },
  button: {
    transition: ".3s all ease",
  },
  greybutton: {
    transition: ".3s all ease",
    background: "#f7f7f7",
  },
  marginbutton: {
    marginTop: "-8px",
    marginBottom: "8px",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  floatMD: {
    display: "inline-block",
    float: "none",
    width: "100%",
    margin: "auto",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      float: "right",
      display: "block",
    },
  },
  fixedBar: {
    position: "fixed",
    bottom: "5px",
    left: "50%",
    transform: "translateX(-50%)",
  },
  search: {
    position: "absolute",
    right: "20px",
    top: "50%",
    transform: "translateY(-50%)",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    // width: '100%',
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit,
      width: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 120,
      "&:focus": {
        width: 200,
      },
    },
  },
  formControl: {
    float: "right",
    margin: theme.spacing.unit,
    minWidth: 160,
  },
  pullLeft: {
    margin: theme.spacing.unit,
    width: "auto",
    dislay: "block",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
      minWidth: 120,
    },
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  pageToggles: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "-5px",
    },
  },
});

class NewList extends React.Component {
  state = {
    events: [],
    search: "",
    selected: "",
    modalOpen: false,
    claimOpen: false,
    filtered: [],
    page: 0,
    display: 10,
    sort: '{"status":["none"]}',
    sortPlatform: "none",
    itemsCount: 0,
    selectedStatus: "",
    sortCountry: "none",
    isLoading: true,
    shipments: [],
    disabled: false,
    status: "",
    hasBeenCorrected: false,
    isSearching: false,
    exportModal: false,
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  componentWillUnmount = () => {
    this.itemsCount = null;
  };

  checkSelfDelivery = async () => {
    const account = await querySelfDeliveryAccount();
    this.setState({selfDelivery:account ? true : false});
  }

  UNSAFE_componentWillMount = async () => {

    this.checkSelfDelivery();

    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });

    let shipments = await listShipments2(
      this.state.page,
      this.state.display,
      "none",
      JSON.parse(this.state.sort).status,
      "none",
      this.state.search,
      this.props.account &&
        this.props.account.user &&
        this.props.account.user.isParent
        ? true
        : false,
      await Auth.user.username,
      this.state.sortPlatform
    );
    // console.log(shipments);
    await this.setState({ shipments: shipments.response });
    // alert(this.state.display)
    this.setState({ filtered: this.state.shipments });
    this.itemsCount = this.state.shipments.length;
    this.setState({ isLoading: false });
  };

  language = {
    MyShipments: {
      FR: "Mes Envois",
      EN: "My Shipments",
    },
    AdditionalLabels: {
      FR: "Impressions supplémentaires",
      EN: "Additional Labels",
    },
    Shipments: {
      FR: "Les Envois",
      EN: "Shipments",
    },
    SearchShipments: {
      FR: "Rechercher des envois",
      EN: "Search Shipments",
    },
    NoShipmentsFound: {
      FR: "Aucun envoi trouvé",
      EN: "No Shipments Found",
    },
    ResetFilters: {
      FR: "Réinitialiser les filtres",
      EN: "Reset Filters",
    },
    All: {
      FR: "Tout",
      EN: "All",
    },
    FilterbyPlatform: {
      FR: "Filtrer par plateforme",
      EN: "Filter by Platform",
    },
    FilterbyCountry: {
      FR: "Filtrer par pays",
      EN: "Filter by Country",
    },
    FilterbyStatus: {
      FR: "Filtrer par statut",
      EN: "Filter by Status",
    },
    InTransit: {
      FR: "En Transit",
      EN: "In Transit",
    },
    HFPU: {
      FR: "Tenir pour le ramassage",
      EN: "Held for Pickup",
    },
    Delivered: {
      FR: "Livré",
      EN: "Delivered",
    },
    Exception: {
      FR: "Exception",
      EN: "Exception",
    },
    Itemsperpage: {
      FR: "Objets par page",
      EN: "Items per page",
    },
    ReturnToSender: {
      FR: "Renvoyé à l'expéditeur",
      EN: "Return to Sender",
    },
  };

  handleClose = () => {
    this.setState({ modalOpen: false });
  };

  handleSelectChange = (name) => async (event) => {
    await this.setState({ [name]: event.target.value });
    await this.renderShipments("0", false);
    this.setState({ page: 0 });
    return true;
  };

  clearSearchAndRender = async () => {
    this.setState({ isLoading: true });
    let sample = await listShipments2(
      this.state.page,
      this.state.display,
      this.state.sortCountry,
      JSON.parse(this.state.sort).status,
      "none",
      this.props.account &&
        this.props.account.user &&
        this.props.account.user.isParent
        ? true
        : false,
      await Auth.user.username,
      this.state.sortPlatform
    ).catch((e) => {
      window.location.reload();
    });
    this.setState({ isLoading: false, isSearching: false });
    // console.log(sample);
    if (sample.response.length > 0) {
      this.setState({ shipments: sample.response });
      this.setState({ disabled: false });
      return true;
    } else {
      this.setState({ shipments: [] });
      this.setState({ disabled: true });
      return false;
    }
  };

  fetchEvents = async (tracking) => {
    try{
      this.setState({events:[]});
      const response = await axios.get("https://www.swiftpost.com/track/"+tracking);
      this.setState({events:response?.data?.events ?? []});
    } catch(e){
      this.setState({events:[]});
    }
  }

  renderShipments = async (page, isNext) => {
    this.setState({ isLoading: true });

    let sample = await listShipments2(
      page,
      this.state.display,
      this.state.sortCountry,
      JSON.parse(this.state.sort).status,
      "none",
      this.state.search,
      this.props.account &&
        this.props.account.user &&
        this.props.account.user.isParent
        ? true
        : false,
      await Auth.user.username,
      this.state.sortPlatform
    ).catch((e) => {
      window.location.reload();
    });
    this.setState({ isLoading: false, isSearching: false });
    // console.log(sample);
    if (sample.response.length > 0) {
      this.setState({ shipments: sample.response });
      this.setState({ disabled: false });
      return true;
    } else {
      if (!isNext) {
        this.setState({ shipments: [] });
      }
      this.setState({ disabled: true });
      return false;
    }
  };

  updateVisibleShipments = (obj) => {
    var { shipments } = this.state;
    shipments = shipments.map((e) => {
      if (obj.row_ids.indexOf(e.id) > -1) {
        let temp = e;
        let index = obj.row_ids.indexOf(e.id);
        temp.tracking = obj.tracking[index];
        temp.image_url = obj.label_url[index];
        return temp;
      } else {
        return e;
      }
    });
    this.setState({ shipments: shipments });
  };

  ImagetoPrint = (source) => {
    //add QZ here
    return (
      "<html><head><style>@page { margin: 0; } @media print { html, body { width: 101.6mm; height: 151mm; } }</style>\n" +
      "<scri" +
      "pt>function step1(){\n" +
      "setTimeout('step2()', 10);}\n" +
      "function step2(){window.print();window.close()}\n" +
      "</scri" +
      "pt></head><body onload='step1()'>\n" +
      "<img src='" +
      source +
      "' style='height:100%' /></body></html>"
    );
  };

  PrintImage = (source) => {
    var Pagelink = "about:blank";
    var pwa = window.open(Pagelink, "_new");
    pwa.document.open();
    pwa.document.write(this.ImagetoPrint(source));
    pwa.document.close();
  };

  render() {
    const { classes } = this.props;

    return (
      <div class="Home">
        <Fragment>
          {/* <MobileFixedSearch className={classes.fixedBar} search={this.state.search} applyFilter={this.handleChange} clearFilter={this.clearFilter} /> */}
          <Typography variant="h1">
            {this.language.MyShipments[this.state.language]}
          </Typography>
          {this.state.selfDelivery ? <><a href="https://app.routes.io/" target="_blank" rel="noreferrer">
            <Button
              style={{
                background:"#49A641",
                color:"#fff"
              }}
            >
              <div style={{display:'flex', maxHeight:'30px', alignItems:'center',margin:0, padding:0}}>
                <img
                  src={RoutesLogo}
                  style={{ height: "20px",marginTop:2,marginRight:8 }}
                  alt="Routes.io Logo"
                />
                <p style={{fontWeight:'normal', fontFamily: "Matter Regular"}}>dispatch</p>
                </div>
            </Button>
          </a>
          <br/><br/>
          </> : <></>}

          

          {this.state.selected !== "" && (
            <Shipment
              events={this.state.events}
              addressCorrected={this.state.hasBeenCorrected}
              status={this.state.status}
              open={this.state.modalOpen}
              handleClose={this.handleClose}
              shipment={this.state.selected}
              image_url={
                this.state.selected.dropoff_reprint_url
                  ? this.state.selected.dropoff_reprint_url
                  : false
              }
              account={this.props.account}
            />
          )}
          {/* {this.state.selected != "" && <LushOpenClaim refreshClaims={this.props.refreshClaims} open={this.state.claimOpen} handleClaimClose={this.handleClaimClose} shipment={this.state.selected} /> } */}

          <Paper square className={classes.paper}>
            <Typography
              className={classes.text}
              variant="h5"
              style={{
                position: "relative",
                background: "var(--brand-cyan)",
                color: "#fff",
              }}
            >
              {this.language.Shipments[this.state.language]}

              <Hidden smDown>
                <Paper
                  style={{
                    float: "right",
                    padding: "0px 4px",
                    display: "flex",
                    alignItems: "center",
                    maxWidth: 400,
                    height: "35px",
                  }}
                  elevation={1}
                >
                  <InputBase
                    value={this.state.search}
                    onChange={this.handleChange("search")}
                    style={{
                      marginLeft: 8,
                      flex: 1,
                      width: "auto",
                    }}
                    placeholder={
                      this.language.SearchShipments[this.state.language]
                    }
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        this.setState({ isSearching: true });
                        this.renderShipments(0, true);
                      }
                    }}
                  />

                  {this.state.search !== "" && !this.state.isSearching ? (
                    <IconButton
                      color="secondary"
                      style={{ padding: 5 }}
                      aria-label="Clear Search"
                      onClick={() => {
                        this.setState({ search: "" });
                        this.clearSearchAndRender();
                      }}
                    >
                      <CloseOutlined />
                    </IconButton>
                  ) : (
                    ""
                  )}

                  <Divider className={{ width: 1, height: 23, margin: 4 }} />

                  {!this.state.isSearching ? (
                    <IconButton
                      style={{ padding: 5 }}
                      aria-label="Search"
                      onClick={() => {
                        this.setState({ isSearching: true });
                        this.renderShipments(0, true);
                      }}
                    >
                      <SearchIcon />
                    </IconButton>
                  ) : (
                    <LoadingIcon size={20} style={{ marginRight: "5px" }} />
                  )}
                </Paper>
              </Hidden>
            </Typography>

            <Hidden mdUp>
              <div
                style={{
                  background: "var(--brand-cyan)",
                  padding: "0 20px 20px 20px",
                  boxSizing: "border-box",
                }}
              >
                <Paper
                  style={{
                    padding: "0px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    boxSizing: "border-box",
                    height: "35px",
                  }}
                  elevation={1}
                >
                  <InputBase
                    value={this.state.search}
                    onChange={this.handleChange("search")}
                    style={{
                      marginLeft: 8,
                      flex: 1,
                      width: "auto",
                    }}
                    placeholder={
                      this.language.SearchShipments[this.state.language]
                    }
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        this.setState({ isSearching: true });
                        this.renderShipments(0, true);
                      }
                    }}
                  />

                  {this.state.search !== "" && !this.state.isSearching ? (
                    <IconButton
                      color="secondary"
                      style={{ padding: 5 }}
                      aria-label="Clear Search"
                      onClick={() => {
                        this.setState({ search: "" });
                        this.clearSearchAndRender();
                      }}
                    >
                      <CloseOutlined />
                    </IconButton>
                  ) : (
                    ""
                  )}

                  <Divider className={{ width: 1, height: 23, margin: 4 }} />

                  {!this.state.isSearching ? (
                    <IconButton
                      style={{ padding: 5 }}
                      aria-label="Search"
                      onClick={() => {
                        this.setState({ isSearching: true });
                        this.renderShipments(0, true);
                      }}
                    >
                      <SearchIcon />
                    </IconButton>
                  ) : (
                    <LoadingIcon size={20} style={{ marginRight: "5px" }} />
                  )}
                </Paper>
              </div>
            </Hidden>

            <List className={classes.list}>
              {this.state.isLoading && <LinearProgress color="secondary" />}
              {!this.state.isLoading && this.state.shipments.length === 0 && (
                <ListItem>
                  <p>
                    {this.language.NoShipmentsFound[this.state.language]}
                    .&nbsp;&nbsp;
                    <Button
                      // variant="outlined"
                      color="primary"
                      onClick={async () => {
                        this.setState({ sort: '{"status":["none"]}' });
                        this.setState({ sortCountry: "none" });
                        this.setState({ display: 10 });
                        this.setState({ page: 0 });
                        let shipments = await listShipments2(
                          0,
                          10,
                          "none",
                          ["none"],
                          "none",
                          this.state.search,
                          this.props.account &&
                            this.props.account.user &&
                            this.props.account.user.isParent
                            ? true
                            : false,
                          await Auth.user.username,
                          this.state.sortPlatform
                        );
                        this.setState({ shipments: shipments.response });
                        this.setState({ isLoading: false });
                      }}
                    >
                      {this.language.ResetFilters[this.state.language]}
                    </Button>
                  </p>
                </ListItem>
              )}
              {this.state.shipments.map((shipment, count) => (
                <ShipmentRow
                  settings={this.props.settings}
                  completedQueue={this.props.completedQueue}
                  updateVisibleShipments={this.updateVisibleShipments}
                  processQueue={this.props.processQueue}
                  pushProcessQueue={this.props.pushProcessQueue}
                  popProcessQueue={this.props.popProcessQueue}
                  count={count}
                  shipment={shipment}
                  shipmentStatus={(e) => this.setState({ status: e })}
                  openShipment={() => this.setState({ modalOpen: true })}
                  setSelected={(e) => {
                    this.setState({ selected: e });
                    this.fetchEvents(e.tracking);
                }}
                  toggleModal={(e) =>
                    this.setState({ modalOpen: !this.state.modalOpen })
                  }
                  toggleLoading={(e) =>
                    this.setState({ isLoading: !this.state.isLoading })
                  }
                />
              ))}
            </List>
          </Paper>
          <ExportModal
            handleClose={() => this.setState({ exportModal: false })}
            open={this.state.exportModal}
          />
          <Button
            color="primary"
            variant="outlined"
            className={classes.pullLeft}
            onClick={() => this.setState({ exportModal: true })}
          >
            Export to CSV
          </Button>
          {this.props.account &&
          this.props.account.user &&
          (this.props.account.user.isParent ||
            this.props.account.user.isChild) ? (
            ""
          ) : (
            <FormControl variant="filled" className={classes.formControl}>
              <InputLabel
                ref={(ref) => {
                  this.InputLabelRef = ref;
                }}
                htmlFor="outlined-age-native-simple"
              >
                {this.language.FilterbyPlatform[this.state.language]}
              </InputLabel>
              <Select
                native
                value={this.state.sortPlatform}
                onChange={this.handleSelectChange("sortPlatform")}
                input={
                  <FilledInput
                    name="age"
                    labelWidth={this.state.labelWidth}
                    id="outlined-age-native-simple"
                  />
                }
              >
                {/* <option value={'date_shipped'}>Date Shipped</option> */}
                <option value={"none"}>
                  {this.language.All[this.state.language]}
                </option>
                <option value={"ETSY"}>Etsy</option>
                <option value={"SHOPIFY"}>Shopify</option>
                {/* <option value={'transit'}>Days in Transit</option> */}
              </Select>
            </FormControl>
          )}
          <FormControl variant="filled" className={classes.formControl}>
            <InputLabel
              ref={(ref) => {
                this.InputLabelRef = ref;
              }}
              htmlFor="outlined-age-native-simple"
            >
              {this.language.FilterbyCountry[this.state.language]}
            </InputLabel>
            <Select
              native
              value={this.state.sortCountry}
              onChange={this.handleSelectChange("sortCountry")}
              input={
                <FilledInput
                  name="age"
                  labelWidth={this.state.labelWidth}
                  id="outlined-age-native-simple"
                />
              }
            >
              {/* <option value={'date_shipped'}>Date Shipped</option> */}
              <option value={"none"}>
                {this.language.All[this.state.language]}
              </option>
              <option value={"US"}>🇺🇸 US</option>
              <option value={"CA"}>🇨🇦 Canada</option>

              {/* <option value={'transit'}>Days in Transit</option> */}
            </Select>
          </FormControl>
          <FormControl variant="filled" className={classes.formControl}>
            <InputLabel
              ref={(ref) => {
                this.InputLabelRef = ref;
              }}
              htmlFor="outlined-age-native-simple"
            >
              {this.language.FilterbyStatus[this.state.language]}
            </InputLabel>
            <Select
              native
              value={this.state.sort}
              onChange={this.handleSelectChange("sort")}
              input={
                <FilledInput
                  name="age"
                  labelWidth={this.state.labelWidth}
                  id="outlined-age-native-simple"
                />
              }
            >
              {/* <option value={'date_shipped'}>Date Shipped</option> */}
              <option value='{"status":["none"]}'>
                {this.language.All[this.state.language]}
              </option>
              {/* <option value='{"status":["IN","PU"]}'>Awaiting Scan</option> */}
              <option value='{"status":["SH","TR","SP","IN","PU","DP"]}'>
                {this.language.InTransit[this.state.language]}
              </option>
              <option value='{"status":["SH","HFPU"]}'>
                {this.language.HFPU[this.state.language]}
              </option>
              <option value='{"status":["DEL"]}'>
                {this.language.Delivered[this.state.language]}
              </option>
              <option value='{"status":["EX"]}'>
                {this.language.Exception[this.state.language]}
              </option>
              <option value='{"status":["RTS"]}'>
                {this.language.ReturnToSender[this.state.language]}
              </option>
            </Select>
          </FormControl>
          <FormControl variant="filled" className={classes.formControl}>
            <InputLabel
              ref={(ref) => {
                this.InputLabelRef = ref;
              }}
              htmlFor="outlined-age-native-simple"
            >
              {this.language.Itemsperpage[this.state.language]}
            </InputLabel>
            <Select
              native
              value={this.state.display}
              onChange={this.handleSelectChange("display")}
              input={
                <FilledInput
                  name="age"
                  labelWidth={this.state.labelWidth}
                  id="outlined-age-native-simple"
                />
              }
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </Select>
          </FormControl>
        </Fragment>
      </div>
    );
  }
}

NewList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NewList);
