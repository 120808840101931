import { CircularProgress, Theme, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PagePaper from "pages/PagePaper";
import { useAddressBook } from "providers/AddressBook";
import { useEffect, useState } from "react";
import CSVImportTable from "./CSVImportTable";
import ImportCSVPanel from "./ImportCSVPanel";
import {
  AddressCSVImporterProvider,
  useAddressCSVImporter,
} from "./useAddressCSVImporter";

const styles = (theme: Theme) => ({});

export function AddressBookImport(props: { classes: any }) {
  const { listAddressBook } = useAddressBook();

  // Ensure addresses are loaded and up-to-date as they will be referenced
  // for duplication check
  useEffect(() => {
    listAddressBook();
  }, [listAddressBook]);

  return (
    <div className="Home">
      <Typography variant="h1">Import Addresses</Typography>
      <PagePaper>
        <AddressCSVImporterProvider>
          <InnerPage />
        </AddressCSVImporterProvider>
      </PagePaper>
    </div>
  );
}

function InnerPage() {
  const { loadingAddresses } = useAddressBook();
  const { csv, setCsv } = useAddressCSVImporter();
  const [importCSVDialogOpen, setImportCSVDialogOpen] = useState(true);

  return (
    <div>
      {loadingAddresses ? (
        <CircularProgress size={42} thickness={5} />
      ) : (
        <>
          {!csv && (
            <ImportCSVPanel
              open={importCSVDialogOpen}
              onClose={() => setImportCSVDialogOpen(false)}
              onParsed={(result) => setCsv(result)}
            />
          )}
        </>
      )}
      {csv && <CSVImportTable csv={csv} />}
    </div>
  );
}

export default withStyles(styles)(AddressBookImport);
