import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Input,
  InputAdornment,
  MobileStepper,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { API, graphqlOperation } from "aws-amplify";
import { PaymentMethod } from "components/BalanceButton";
import { getLanguage, Language } from "helpers/language";
import React, { ChangeEvent, Fragment } from "react";
import monerisChargeCard from "../api/moneris/chargeCard";
import LoadingIcon from "../components/loading/LoadingIcon";
import PaymentPicker from "../components/PaymentPicker";
import { round } from "../helpers/TaskRunners";

type ListV1BalancesResponse = {
  data: {
    listV1_Balances: {
      items: Array<{ id: string; balance: string }>;
    };
  };
};

class AddFundsDialog extends React.Component<
  {
    account: {
      user: {
        email: string;
      };
    };
    activeStep: number;
    addPayment: () => void;
    balance: {
      balance: number;
    };
    checkQualify?: () => void;
    classes: any;
    diff: number;
    enteredValue: string;
    handleBack: () => void;
    handleClose: () => void;
    handleChange: (event: ChangeEvent<{}>, value: string) => void;
    handleNext: () => void;
    handlePaymentChange: (paymentMethod: PaymentMethod) => void;
    handleTextChange: (event: ChangeEvent<HTMLInputElement>) => void;
    openModal: (successMessage: string, doneMessage: string) => void;
    open: boolean;
    paymentmethods: PaymentMethod[];
    selectedPayment: string | boolean;
    value: string;
  },
  {
    language: Language;
    isLoading: boolean;
    chargeError: boolean;
    chargeSuccess: boolean;
    newBalance: number;
    snackOpen: boolean;
  }
> {
  state = {
    isLoading: false,
    chargeError: false,
    chargeSuccess: false,
    newBalance: 0,
    snackOpen: false,
    language: getLanguage(),
  };

  chargeCard = async () => {
    this.setState({ isLoading: true });
    await monerisChargeCard({
      account: this.props.account,
      card: {
        exp: this.props.paymentmethods.filter(
          (e) => e.id === this.props.selectedPayment
        )[0].card.exp,
        token: this.props.paymentmethods.filter(
          (e) => e.id === this.props.selectedPayment
        )[0].card.token,
      },
      amount:
        this.props.value === "other"
          ? this.props.enteredValue
          : this.props.value,
      email: this.props.account.user.email,
    })
      .then(async (e) => {
        if (!e.approved) {
          this.setState({ chargeError: true });
          this.setState({ isLoading: false });
          return;
        }

        const balance = (await API.graphql(
          graphqlOperation(`query listBalances {
          listV1_Balances {
            items{
              id
              balance
              cognitoUsername
            }
          }
        }`)
        )) as ListV1BalancesResponse;

        const originalBalanceInPennies =
          Number(balance.data.listV1_Balances.items[0].balance) * 100;

        const toAddInPennies =
          Number(
            this.props.value === "other"
              ? this.props.enteredValue
              : this.props.value
          ) * 100;

        const newAmountInDollars =
          (originalBalanceInPennies + toAddInPennies) / 100;

        await API.graphql(
          graphqlOperation(`mutation {
          updateV1_Balance(input:{
              id:"${balance.data.listV1_Balances.items[0].id}"
              balance:"${newAmountInDollars}"
          }){
            id
            balance
            cognitoUsername
          }
        }`)
        );

        //close the hung modal
        this.props.handleClose();

        this.props.openModal(
          this.language.Youhavesuccessfullyaddedfundstoyouraccount[
            this.state.language
          ],
          this.language.Done[this.state.language]
        );

        if (this.props.checkQualify) {
          this.props.checkQualify();
        }

        //this is a persistent component, so it must be reset
        this.setState({ isLoading: false });
        this.setState({ newBalance: 0 });
        this.setState({ chargeError: false });
      })
      .catch((e) => {
        this.setState({ chargeError: true });
        this.setState({ isLoading: false });
        return;
      });
  };

  language = {
    Youhavesuccessfullyaddedfundstoyouraccount: {
      FR: "Ajoutez des fonds à votre compte",
      EN: "You have successfully added funds to your account",
    },
    Done: {
      FR: "Terminé",
      EN: "Done",
    },
    AddFundsToYourAccount: {
      FR: "Ajoutez des fonds à votre compte",
      EN: "Add Funds To Your Account",
    },
    OtherAmount: {
      FR: "Autre montant",
      EN: "Other Amount",
    },
    PaymentMethod: {
      FR: "Paiement total",
      EN: "Payment Method",
    },
    PaymentAmount: {
      FR: "Paiement total",
      EN: "Payment Amount",
    },
    NewBalance: {
      FR: "Nouveau solde total",
      EN: "New Balance",
    },
    Error: {
      FR: "Erreur",
      EN: "Error",
    },
    IssueProcessingSelectedCard: {
      FR: "Problème de traitement de la carte de crédit sélectionnée",
      EN: "Issue Processing Selected Card",
    },
    Next: {
      FR: "Suivant",
      EN: "Next",
    },
    Cancel: {
      FR: "Annuler",
      EN: "Cancel",
    },
    Back: {
      FR: "Arrière",
      EN: "Back",
    },
    ConfirmDetails: {
      FR: "Confirmer les détails",
      EN: "ConfirmDetails",
    },
    Process: {
      FR: "Processus",
      EN: "Process",
    },
  };

  isEnteredValueValid(): boolean {
    // We only need to check validity if we have a custom entered value
    if (this.props.value !== "other") {
      return true;
    }

    const asNumber = +this.props.enteredValue;

    // e.g. "$1" or "1,000"
    if (isNaN(asNumber)) {
      return false;
    }

    // Must be at least 1 dollar
    return asNumber >= 1;
  }

  render() {
    const { classes } = this.props;
    const { activeStep } = this.props;

    const tutorialSteps = [
      <div style={{ minWidth: "300px" }}>
        <DialogTitle id="form-dialog-title">
          {this.language.AddFundsToYourAccount[this.state.language]}
        </DialogTitle>
        <DialogContent>
          <FormControl className={classes.formControl}>
            <RadioGroup
              aria-label="Gender"
              name="value"
              className={classes.group}
              value={this.props.value}
              onChange={this.props.handleChange}
            >
              <FormControlLabel
                value="25"
                disabled={this.props.diff > 25}
                control={<Radio color="primary" />}
                label="$25 CAD"
              />
              <FormControlLabel
                value="50"
                disabled={this.props.diff > 50}
                control={<Radio color="primary" />}
                label="$50 CAD"
              />
              <FormControlLabel
                value="100"
                disabled={this.props.diff > 100}
                control={<Radio color="primary" />}
                label="$100 CAD"
              />
              <FormControlLabel
                value="other"
                control={<Radio color="primary" />}
                label={
                  <Input
                    value={this.props.enteredValue}
                    onChange={this.props.handleTextChange}
                    name="enteredValue"
                    disabled={this.props.value !== "other"}
                    id="standard-bare"
                    error={!this.isEnteredValueValid()}
                    className={classes.textField}
                    placeholder={this.language.OtherAmount[this.state.language]}
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                  />
                }
              />
            </RadioGroup>
            {this.props.enteredValue && !this.isEnteredValueValid() && (
              <FormHelperText>Invalid number format</FormHelperText>
            )}
          </FormControl>
        </DialogContent>
      </div>,
      <div style={{ minWidth: "300px" }}>
        <br />
        <br />
        <DialogContent>
          <PaymentPicker
            account={this.props.account}
            paymentmethods={this.props.paymentmethods}
            handlePaymentChange={this.props.handlePaymentChange}
            selectedPayment={this.props.selectedPayment}
            addPayment={this.props.addPayment}
          />
        </DialogContent>
      </div>,
      <div style={{ minWidth: "300px" }}>
        <DialogTitle id="alert-dialog-title">
          {this.language.ConfirmDetails[this.state.language]}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="subheading">
              <strong>
                {this.language.PaymentMethod[this.state.language]}
              </strong>
              <br />
              {this.props.paymentmethods
                .filter((e) => e.id === this.props.selectedPayment)
                .map((e) => (
                  <Fragment>
                    {e.title} {e.card.pan.substr(-4)}
                    <br />
                    <small>{e.card.type}</small>
                  </Fragment>
                ))}
              <br />
              <br />
            </Typography>
            <Typography variant="subheading">
              <strong>
                {this.language.PaymentAmount[this.state.language]}
              </strong>
              <br />$
              {this.props.value === "other"
                ? round(this.props.enteredValue)
                : round(this.props.value)}{" "}
              <small>CAD</small>
              <br />
              <br />
            </Typography>
            <Divider />
            <br />
            <Typography variant="subheading">
              <strong>{this.language.NewBalance[this.state.language]}</strong>
              <br />${round(this.state.newBalance)} <small>CAD</small>
              <br />
              <br />
            </Typography>
            {this.state.chargeError && (
              <Typography variant="subheading" style={{ color: "red" }}>
                <strong>{this.language.Error[this.state.language]}</strong>
                <br />
                {this.language.IssueProcessingSelectedCard[this.state.language]}
                <br />
                <br />
              </Typography>
            )}
          </DialogContentText>
        </DialogContent>
      </div>,
    ];
    const maxSteps = tutorialSteps.length;

    return (
      <Dialog
        open={this.props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div>
          {tutorialSteps[activeStep]}
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            className={classes.mobileStepper}
            nextButton={
              this.props.activeStep === tutorialSteps.length - 1 ? (
                <Button
                  size="small"
                  onClick={() => this.chargeCard()}
                  disabled={this.state.isLoading}
                >
                  {this.state.isLoading ? (
                    <LoadingIcon size={20} />
                  ) : (
                    this.language.Process[this.state.language]
                  )}
                  {<KeyboardArrowRight />}
                </Button>
              ) : (
                <Button
                  size="small"
                  onClick={() => {
                    if (this.props.activeStep === 1) {
                      this.setState({
                        newBalance:
                          (Number(
                            this.props.value === "other"
                              ? this.props.enteredValue
                              : this.props.value
                          ) *
                            100 +
                            Number(this.props.balance.balance * 100)) /
                          100,
                      });
                    }
                    this.props.handleNext();
                  }}
                  disabled={
                    (this.props.activeStep === 1 &&
                      !this.props.selectedPayment) ||
                    !this.isEnteredValueValid()
                  }
                >
                  {this.language.Next[this.state.language]}
                  {<KeyboardArrowRight />}
                </Button>
              )
            }
            backButton={
              this.props.activeStep === 0 ? (
                <Button size="small" onClick={this.props.handleClose}>
                  {this.language.Cancel[this.state.language]}
                </Button>
              ) : (
                <Button
                  size="small"
                  disabled={this.state.isLoading || this.state.chargeSuccess}
                  onClick={() => {
                    this.props.handleBack();
                    this.setState({ chargeError: false });
                  }}
                >
                  {<KeyboardArrowLeft />}
                  {this.language.Back[this.state.language]}
                </Button>
              )
            }
          />
        </div>
      </Dialog>
    );
  }
}

export default withStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing.unit,
  },
  group: {
    margin: `5px 0`,
  },
}))(AddFundsDialog);
