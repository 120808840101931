import {
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { MoreVert, UnarchiveOutlined } from "@material-ui/icons";
import { API } from "aws-amplify";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import DeleteAllInterstitial from "../../components/DeleteAllInterstitial";
import Snackbar from "../../components/Snackbar";
import OrderTable from "./OrderTable";
import Checkout from "../../dialogs/Checkout";

const styles = (theme) => ({
  text: {
    paddingTop: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  paper: {
    // paddingBottom: 50,
  },
  list: {
    // marginBottom: theme.spacing.unit * 2,
    // maxHeight: '500px',
    // overflow: 'scroll',
    paddingTop: 0,
  },
  subHeader: {
    // backgroundColor: theme.palette.background.paper,
  },
  avatar: {
    background: "transparent",
    border: "2px solid grey",
    color: "grey",
    [theme.breakpoints.down("sm")]: {
      alignSelf: "flex-start",
    },
    [theme.breakpoints.up("sm")]: {
      alignSelf: "center",
    },
  },
  activeAvatar: {
    background: "green",
    transition: ".1s all ease",
    [theme.breakpoints.down("sm")]: {
      alignSelf: "flex-start",
    },
    [theme.breakpoints.up("sm")]: {
      alignSelf: "center",
    },
  },
  appBar: {
    top: "auto",
    bottom: 0,
  },
  toolbar: {
    alignItems: "center",
    justifyContent: "space-between",
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto",
  },
  activeButton: {
    background: "#eafaea",
    transition: ".3s all ease",
  },
  button: {
    transition: ".3s all ease",
  },
  marginbutton: {
    marginTop: "-8px",
    marginBottom: "8px",
  },
});

class MyOrders extends React.Component {
  state = {
    selected: [],
    expanded: false,
    checkout: false,
    snackOpen: false,
    flashEdit: false,
    anchorEl: null,
    search: "",
    updating: false,
  };

  UNSAFE_componentWillMount = () => {
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });
  };

  language = {
    MyOrders: {
      FR: "Mes Commandes",
      EN: "My Orders",
    },
    PendingOrders: {
      FR: "Les Commandes en Attente",
      EN: "Pending Orders",
    },
    PayAndPrint: {
      FR: "Payer et imprimer",
      EN: "Create Labels",
    },
    ProcessShipments: {
      FR: "Traiter les envois",
      EN: "Process Shipments",
    },
    IncompleteOrder: {
      FR: "Un ou plusieurs envois sélectionnés sont incomplets.",
      EN: "One or more selected shipments are incomplete.",
    },
  };

  toggleSelected = (id) => {
    var temp = this.state.selected;
    if (temp.indexOf(id) > -1) {
      temp = temp.filter((e) => e != id);
      this.setState({ selected: temp });
    } else {
      temp.push(id);
      this.setState({ selected: temp });
      // console.log(temp);
    }
  };

  clearSelected = () => {
    this.setState({ selected: [] });
  };

  toggleExpanded = () => {
    let temp = this.state.expanded;
    this.setState({ expanded: !temp });
  };

  toggleSelectAll = () => {
    if (this.state.selected.length === this.props.shipments.length) {
      this.clearSelected();
    } else {
      let temp = this.props.shipments.map((e) => e.id);
      this.setState({ selected: temp });
    }
  };

  goToCheckout = () => {
    // this.setState({checkout:true});
    // this.props.setSelected({})
    this.props.history.push("/payandship");
  };

  componentDidMount = () => {
    this.props.setSelectedShipments([]);
  };


  printLabels = async () => {
      this.props.isCheckingOut(true);
      var tempBody = {
        body: {
          connection_id: this.props.connection_id,
          tab: 0,
          balance: 0,
          amount: 0,
          invoice_date: new Date().toLocaleDateString(),
          shipments: this.props.shipments.filter(
            (e) => this.props.selectedShipments.indexOf(e.id) > -1
          ),
          account: this.props.account,
        },
      };
      console.log(tempBody);
      await API.post("sqs", "/checkout", tempBody);
      return;
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    const { classes } = this.props;

    return (
      <>
        <Checkout
            failedShipments={[]}
            failedImages={[]}
            {...this.state}
            open={this.props.checkoutModalOpen}
            // progress={this.state.progress}
        />
        <div className="Home">
          {this.props.deleteOpen && (
            <DeleteAllInterstitial
              doDelete={async () => {
                let response = await API.post(
                  "uploadorders",
                  "/delete-pending",
                  { body: { connection_id: this.props.connection_id } }
                );
                console.log(response);
                this.props.set_upload_chunk_count(response.chunk_count);
                this.props.toggleDeleteModal(false);
              }}
              open={this.props.deleteOpen}
              handleClose={() => this.props.toggleDeleteModal(false)}
            />
          )}
          <Fragment>

            <Grid
              container
              alignItems="center"
              direction="row"
              style={{ position: "relative" }}
            >
              <Typography variant="h1">
                {this.language.MyOrders[this.state.language]}
              </Typography>
            </Grid>
            <Grid
              container
              alignItems="center"
              direction="row"
              style={{ position: "relative" }}
            >
              {this.props.selectedShipments.length == 0 ? (
                <Tooltip
                  title={
                    <span>You must select one or more orders to checkout</span>
                  }
                >
                  <div>
                    <Button
                      disabled={true}
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                    >
                      {this.language.PayAndPrint[this.state.language]}
                    </Button>
                  </div>
                </Tooltip>
              ) : this.props.shipments.filter(
                  (e) =>
                    this.props.selectedShipments.indexOf(e.id) > -1 &&
                    (e.rate?.service?.courier == "CANPAR" ||
                      e.rate?.service?.courier == "ICS")
                ).length ? (
                <Tooltip
                  title={
                    <span>
                      You cannot checkout with an ICS or CANPAR shipment
                    </span>
                  }
                >
                  <div>
                    <Button
                      disabled={true}
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                    >
                      {this.language.PayAndPrint[this.state.language]}
                    </Button>
                  </div>
                </Tooltip>
              ) : (
                <>
                  {/* <Link to="/payandship"> */}
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                      onClick={()=>this.printLabels()}
                    >
                      {this.props.account &&
                      this.props.account.user &&
                      this.props.account.user.isChild
                        ? this.language.ProcessShipments[this.state.language]
                        : this.language.PayAndPrint[this.state.language]}
                    </Button>
                </>
              )}
              <Tooltip title={"Import orders via CSV file"}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: "20px" }}
                  className={classes.button}
                  onClick={() => this.props.toggleImport(true)}
                >
                  <UnarchiveOutlined style={{ fontSize: "1.2em" }} />
                  &nbsp;&nbsp;Import CSV
                </Button>
              </Tooltip>
              <div style={{ position: "absolute", top: "-5px", right: "10px" }}>
                {/* <IconButton>
                  <SearchIcon onClick={() => {
                    console.log('open')
                  }} />
                </IconButton> */}
                <IconButton
                  aria-label="More"
                  aria-owns={open ? "long-menu" : undefined}
                  aria-haspopup="true"
                  onClick={this.handleClick}
                >
                  <MoreVert />
                </IconButton>
                <Menu
                  id="long-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={this.handleClose}
                >
                  <MenuItem
                    key={1}
                    onClick={() => {
                      this.setState({ anchorEl: null });
                      this.props.toggleDeleteModal(true);
                    }}
                  >
                    Delete All Pending Orders
                  </MenuItem>
                </Menu>
              </div>
            </Grid>

            <br />
            <br />
            {/* <Paper className={classes.paper} style={{
            // width:'90%',height:'100vh','boxShadow':'none','background':'transparent'
            'overflow':'auto',
            'float':'left',
            'width': 'calc(100vw - 45px)',
            }}> */}
            {!this.props.isRemoving && !this.state.updating ? (
              <OrderTable
                searching={this.state.search != ""}
                settings={this.state.settings}
                // toggleFullScreen={(id)=>{console.log(id);console.log(this.props.shipments.filter(e=>e.id === id));this.setState({flashEditData:this.props.shipments.filter(e=>e.id === id)[0], flashEdit: !this.state.flashEdit})}}
                toggleModal={this.props.toggleModal}
                isUploading={this.props.isUploading}
                toggleUploading={this.props.toggleUploading}
                shipments={this.props.shipments}
                account={this.props.account}
                locations={this.props.locations}
                deleteShipments={this.props.deleteShipments}
                setSelectedShipments={this.props.setSelectedShipments}
                data={
                  this.state.search != ""
                    ? this.props.shipments.filter(
                        (shipment) =>
                          JSON.stringify(shipment)
                            .toUpperCase()
                            .indexOf(this.state.search.toUpperCase()) > -1
                      )
                    : this.props.shipments
                }
              />
            ) : (
              "LOADING"
            )}

            {/* </Paper> */}
          </Fragment>
        </div>
      </>
    );
  }
}

MyOrders.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MyOrders);
