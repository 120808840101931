import {
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  AddCircleOutline,
  Autorenew,
  CloudDownloadOutlined,
  DoneOutline,
} from "@material-ui/icons";
import { API, Auth, graphqlOperation } from "aws-amplify";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import getReportData from "../../api/getChildReport";
import "../../styles.css";
import ChildCard from "./components/ChildCard";
import ChildDialog from "./components/ChildDialog";
import EditDialog from "./components/EditDialog";
import PostalDialog from "./components/PostalDialog";
import ReportCard from "./components/ReportCard";
import UploadDialog from "./components/UploadDialog";

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
});

class ChildAccounts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: "",
      error: false,
      modalOpen: false,
      isEditing: false,
      isLoading: false,
      editData: {},
      children: [],
      childData: [],
      name: "",
      business: "",
      postal: "",
      street_number: "",
      street_name: "",
      unit: "",
      city: "",
      state: "",
      email: "",
      phone: "",
      country: "CA",
      streetArr: [],
      account_id: "",
      isUploading: false,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  UNSAFE_componentWillMount = async () => {
    var user = await Auth.user.username;
    if (user == undefined || user == "undefined" || user == null) {
      window.location.reload();
    }
    this.setState({ userName: user });

    this.setState({ isLoading: true });
    var date = new Date();
    this.setState({
      start_date: this.formatDate(
        new Date(date.getFullYear(), date.getMonth(), 1)
      ),
    });
    this.setState({
      end_date: this.formatDate(
        new Date(date.getFullYear(), date.getMonth() + 1, 0)
      ),
    });
    let response = await getReportData(
      this.formatDate(new Date(date.getFullYear(), date.getMonth(), 1)),
      this.formatDate(new Date(date.getFullYear(), date.getMonth() + 1, 0)),
      this.props.account.cognitoUsername
    ).catch((e) => {
      this.setState({
        error: true,
        errorMsg: "There was an error getting the report.",
      });
    });
    this.setState({ isLoading: false });

    if (response && response.children && response.children.length) {
      this.setState({ error: false });
      this.setState({ searchSuccess: true });
      this.setState({ delivered: response.shipments.delivered });
      this.setState({ in_transit: response.shipments.in_transit });
      this.setState({ childData: response.children });
    } else {
      this.setState({
        error: true,
        errorMsg: "No shipments found in date range.",
      });
    }

    // alert(this.state.userName)

    let children = await API.graphql(
      graphqlOperation(`query queryChildAccountsByIdCognitoUsernameIndex {
        queryChildAccountsByIdCognitoUsernameIndex(cognitoUsername:"${user}", first:500){
          items{
            id
            account_id
            location_id
            cognitoUsername
            email
            receiver
            fname
            lname
            business
            street_name
            street_number
            postal
            state
            status
            child_sub
            phone
            city
            unit
            country
          }
          nextToken
        }
      }`)
    );

    // console.log(children);

    this.setState({
      nextToken:
        children.data.queryChildAccountsByIdCognitoUsernameIndex.nextToken,
    });

    this.setState({
      children: children.data.queryChildAccountsByIdCognitoUsernameIndex.items,
    });
    const SubscribeToCreateChildAccount = `
      subscription {
        onCreateChildAccounts(cognitoUsername:"${user}"){
          id
          cognitoUsername
        }
      }
    `;
    API.graphql(graphqlOperation(SubscribeToCreateChildAccount)).subscribe({
      next: async (newData) => {
        // console.log("SUBSCRIPTION >>>>>>>>>>>>",newData)
        let children = await API.graphql(
          graphqlOperation(`query getChildAccounts {
            getChildAccounts(id:"${newData.value.data.onCreateChildAccounts.id}"){
                  id
                  account_id
                  location_id
                  cognitoUsername
                  email
                  receiver
                  fname
                  lname
                  business
                  street_name
                  street_number
                  postal
                  state
                  city
                  status
                  child_sub
                  phone
                  unit
                  country
                }
          }`)
        );
        // console.log(children);
        let temp = this.state.children;
        if (
          temp.filter((e) => e.id === children.data.getChildAccounts.id)
            .length === 0
        ) {
          temp.push(children.data.getChildAccounts);
          this.setState({ children: temp });
        }
      },
    });
    const SubscribeToDeleteChildAccounts = `
      subscription {
        onDeleteChildAccounts(cognitoUsername:"${user}"){
          id
          cognitoUsername
        }
      }
    `;
    API.graphql(graphqlOperation(SubscribeToDeleteChildAccounts)).subscribe({
      next: async (newData) => {
        // console.log("SUBSCRIPTION >>>>>>>>>>>>",newData)
        // newData.value.data.onDeleteChildAccounts.id

        // console.log(children);
        let temp = this.state.children;
        temp = temp.filter(
          (e) => e.id !== newData.value.data.onDeleteChildAccounts.id
        );
        this.setState({ children: temp });
      },
    });
    // const SubscribeToUpdateChildAccount = `
    //   subscription {
    //     onUpdateChildAccounts(cognitoUsername:"${user}"){
    //       id
    //       cognitoUsername
    //     }
    //   }
    // `;
    // API.graphql(graphqlOperation(SubscribeToUpdateChildAccount)).subscribe({
    //   next: async newData => {
    //     // console.log("SUBSCRIPTION >>>>>>>>>>>>",newData)
    //     let children = await API.graphql(
    //       graphqlOperation(`query getChildAccounts {
    //         getChildAccounts(id:"${newData.value.data.onUpdateChildAccounts.id}"){
    //               id
    //               location_id
    //               cognitoUsername
    //               email
    //               receiver
    //               fname
    //               lname
    //               business
    //               street_name
    //               street_number
    //               postal
    //               state
    //               status
    //               child_sub
    //               phone
    //               unit
    //               country
    //             }
    //       }`)
    //     );
    //     console.log(children);

    //   let temp = this.state.children;
    //   temp.map(e=>e.id === children.data.getChildAccounts.id ? children.data.getChildAccounts : e);
    //   // temp.push(children.data.getChildAccounts);
    //   this.setState({ children: temp });
    // }
    // });
  };

  isEmailAvailable = (email) =>
    this.state.children.filter(
      (e) => e.email.toUpperCase() === email.toUpperCase()
    ).length === 0;

  checkEmails = (emails) =>
    new Promise(async (resolve, reject) => {
      await API.post("sqs", "/beta", { body: { email: emails } }).then((e) => {
        // console.log("CHECKHERE",e);
        resolve(e.status);
      });
    });

  handleScroll = async () => {
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom >= docHeight - 2000) {
      this.setState({ loadingItems: false });
      let children = await API.graphql(
        graphqlOperation(`query queryChildAccountsByIdCognitoUsernameIndex {
                queryChildAccountsByIdCognitoUsernameIndex(cognitoUsername:"${this.state.userName}",first:200,after:"${this.state.nextToken}"){
                  items{
                    id
                    account_id
                    location_id
                    cognitoUsername
                    email
                    receiver
                    fname
                    lname
                    business
                    street_name
                    street_number
                    postal
                    state
                    status
                    city
                    child_sub
                    phone
                    unit
                    country
                  }
                  nextToken
                }
              }`)
      ).catch((e) => {
        this.setState({ loadingItems: true });
      });

      // console.log(children);
      // console.log(this.state.nextToken);
      //   console.log(children.data.queryChildAccountsByIdCognitoUsernameIndex.items.length)
      if (children) {
        this.setState({
          nextToken:
            children.data.queryChildAccountsByIdCognitoUsernameIndex.nextToken,
        });
        let temp = this.state.children;
        temp = temp.concat(
          children.data.queryChildAccountsByIdCognitoUsernameIndex.items
        );
        this.setState({ children: temp });
      }
    } else {
      // console.log("not yet");
    }
  };

  updateAccount = async (data) => {
    // console.log(data)

    if (data.needsEmail) {
      await API.post("sqs", "/child-account/send", {
        body: {
          cognitoSub: this.props.account.cognitoUsername,
          email: data.email,
          parent_name: this.props.account.location.title,
        },
      }).catch((e) => {
        console.log(e);
      });
    }

    // console.log('i think i sent the email')

    await API.graphql(
      graphqlOperation(`mutation updateChildAccounts {
          updateChildAccounts(input:{
                  id:"${data.id}"
                  fname:"${data.fname}"
                  lname:"${data.lname}"
                  business:"${data.business}"
                  street_name:"${data.street_name}"
                  street_number:"${data.street_number}"
                  postal:"${data.postal}"
                  city:"${data.city}"
                  state:"${data.state}"
                  phone:"${data.phone}"
                  ${data.unit !== "" ? `unit:"${data.unit}"` : ``}
                  status:"${data.status}"
                }){
                  id
                  cognitoUsername
                }
              }`)
    )
      .then(async (e) => {
        if (data.status == "0") {
          let temp = this.state.children;
          temp = temp.map((e) => (e.id === data.id ? data : e));

          // temp.push(children.data.getChildAccounts);
          this.setState({ children: temp });
          this.handleModalClose();
          this.setState({ id: "" });
          return;
        }
        // console.log('update 1')

        await API.graphql(
          graphqlOperation(`mutation updateV1_Account {
          updateV1_Account(input:{
            id:"${data.account_id}"
            location:{
              title:"${data.business}"
              address:{
                street_name:"${data.street_name}"
                street_number:"${data.street_number}"
                postal:"${data.postal}"
                state:"${data.state}"
                country:"CA"
                ${data.unit ? `unit:"${data.unit}"` : ``}
                city:"${data.city}"
              }
              contact: {
                fname:"${data.fname}"
                lname:"${data.lname}"
                email:"${data.email}"
                phone:"${data.phone}"
              } 
            }
          }){
            id
            cognitoUsername
          }
        }`)
        )
          .then(async (f) => {
            // console.log('update 2')

            await API.graphql(
              graphqlOperation(`mutation updateV1_Location {
            updateV1_Location(input:{
              id:"${data.location_id}"
                title:"${data.business}"
                address:{
                  street_name:"${data.street_name}"
                  street_number:"${data.street_number}"
                  postal:"${data.postal}"
                  state:"${data.state}"
                  country:"CA"
                  ${data.unit ? `unit:"${data.unit}"` : ``}
                  city:"${data.city}"
                }
                contact: {
                  fname:"${data.fname}"
                  lname:"${data.lname}"
                  email:"${data.email}"
                  phone:"${data.phone}"
                } 
            }){
              id
              cognitoUsername
            }
          }`)
            )
              .then((f) => {
                // console.log('update 3')

                let temp = this.state.children;
                temp = temp.map((e) => (e.id === data.id ? data : e));

                // temp.push(children.data.getChildAccounts);
                this.setState({ children: temp });
                this.handleModalClose();
                this.setState({ id: "" });
              })
              .catch((e) => {
                console.log(e);
              });
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  getReport = async () => {
    this.setState({ isLoading: true });
    this.setState({ searchSuccess: false });
    // var date = new Date();
    // this.setState({start_date:this.formatDate(new Date(date.getFullYear(), date.getMonth(), 1))});
    // this.setState({end_date:this.formatDate(new Date(date.getFullYear(), date.getMonth() + 1, 0))})
    let response = await getReportData(
      this.state.start_date,
      this.state.end_date,
      this.props.account.cognitoUsername
    ).catch((e) => {
      this.setState({
        error: true,
        errorMsg: "There was an error getting the report.",
      });
    });
    this.setState({ isLoading: false });

    if (response && response.children && response.children.length) {
      this.setState({ error: false });
      this.setState({ searchSuccess: true });
      this.setState({ delivered: response.shipments.delivered });
      this.setState({ in_transit: response.shipments.in_transit });
      this.setState({ childData: response.children });
    } else {
      this.setState({
        error: true,
        errorMsg: "No shipments found in date range.",
      });
    }
  };

  createAccount = (data) =>
    new Promise(async (resolve, reject) => {
      // console.log(this.props.account)
      // console.log({
      //   body: {
      //     parent_name: this.props.account.location.title,
      //     accounts: data.map(e=>{
      //       return {
      //         fname: e.fname.toUpperCase(),
      //         lname: e.lname.toUpperCase(),
      //         business: e.business.toUpperCase(),
      //         postal: e.postal.toUpperCase(),
      //         street_number: e.street_number.toUpperCase(),
      //         street_name: e.street_name.toUpperCase(),
      //         unit: e.unit.toUpperCase(),
      //         state: e.state.toUpperCase(),
      //         email: e.email.toUpperCase(),
      //         phone: e.phone.toUpperCase(),
      //         city: e.city.toUpperCase(),
      //         country: e.country.toUpperCase(),
      //       }
      //     }),
      //     cognitoSub: this.state.userName,
      //     permissions: this.props.account.user.childSettings
      //   }
      // })
      // console.log(this.props.user.childSettings)
      await API.post("sqs", "/child-account", {
        body: {
          parent_name: this.props.account.location.title,
          accounts: data.map((e) => {
            return {
              fname: e.fname.toUpperCase(),
              lname: e.lname.toUpperCase(),
              business: e.business.toUpperCase(),
              postal: e.postal.replace(/ /g, "").toUpperCase(),
              street_number: e.street_number.toUpperCase(),
              street_name: e.street_name.toUpperCase(),
              unit: e.unit.toUpperCase(),
              state: e.state.toUpperCase(),
              email: e.email.toUpperCase(),
              phone: e.phone.toUpperCase(),
              city: e.city.toUpperCase(),
              country: e.country.toUpperCase(),
            };
          }),
          cognitoSub: this.state.userName,
          permissions: this.props.account.user.childSettings,
        },
      })
        .then((e) => {
          // console.log(e)
          this.setState({ isLoading: false });
          this.handleModalClose();
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          resolve(false);
        });
    });

  handleModalOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleModalClose = () => {
    this.setState({ isUploading: false });
    this.setState({ modalOpen: false });
    this.setState({ postalOpen: false });
    this.setState({ isEditing: false });
    this.setState({
      needsEmail: false,
      uploadData: "",
      location_id: "",
      business: "",
      fname: "",
      lname: "",
      street_name: "",
      street_number: "",
      unit: "",
      email: "",
      phone: "",
      country: "CA",
      state: "",
      postal: "",
      city: "",
      account_id: "",
      status: 0,
      id: "",
    });
  };

  searchPostal = (postal) => {
    let tempPostal = postal.replace(/\s/g, "");
    this.setState({ isLoading: true });
    API.post("sqs", "/address/verify", {
      body: {
        postal: tempPostal,
        country: "CA",
      },
    })
      .then((e) => {
        // alert(e.is_residential)
        // console.log(e);
        this.setState({ zipError: false });
        this.setState({ isLoading: false });
        this.setState({
          state: e.state,
          city: e.city,
          postal: e.postal,
        });
        this.setState({
          streetArr: Array.from(new Set(e.street_name)),
        });
        this.setState({ modalOpen: false });
        this.setState({ postalOpen: false });
        this.setState({ isEditing: true });
      })
      .catch((e) => {
        // console.log(e)
        this.setState({ isLoading: false });
        this.setState({ zipError: true });
      });
  };

  startEdit = (data) => {
    this.setState({
      needsEmail: data.needsEmail,
      id: data.id,
      business: data.business,
      location_id: data.location_id,
      fname: data.fname,
      lname: data.lname,
      street_name: data.street_name,
      street_number: data.street_number,
      unit: data.unit,
      email: data.email,
      phone: data.phone,
      country: data.country,
      state: data.state,
      postal: data.postal,
      city: data.city,
      status: data.status,
      isEditing: true,
      account_id: data.account_id,
    });
  };

  formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  changeDate = (event) => {
    // console.log(event.target.value);
    // console.log(event.target.id);
    this.setState({ [event.target.id]: event.target.value });
    console.log(this.state.end_date);
    console.log(this.state.start_date);
  };

  startUpload = (data) => {
    // alert('changing data')
    this.setState({
      isUploading: true,
      uploadData: data,
    });
    this.setState({ modalOpen: false });
    this.setState({ postalOpen: false });
    this.setState({ isEditing: false });
  };

  createCSV = () => {
    console.log(this.state.childData);
    let csvContent =
      "data:text/csv;charset=utf-8," +
      `Location Name,Location Email,Shipments Sent,Shipments Received\n` +
      this.state.childData
        .map(
          (e) =>
            `"${e.location}",${e.email},"${e.sender_count}","${e.receiver_count}"`
        )
        .join("\n");
    var encodedUri = encodeURI(csvContent);
    console.log(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute(
      "download",
      `${this.props.account.location.title}_${this.state.start_date}_${this.state.end_date}.csv`
    );
    document.body.appendChild(link); // Required for FF

    link.click();
  };

  render() {
    return (
      <div className="Home">
        <ChildDialog
          startUpload={this.startUpload}
          open={this.state.modalOpen}
          handleClose={this.state.isLoading ? "" : this.handleModalClose}
          searchPostal={this.searchPostal}
          zipError={this.state.zipError}
          singleAccount={() =>
            this.setState({ postalOpen: true, modalOpen: false })
          }
        />
        {this.state.postalOpen && (
          <PostalDialog
            isLoading={this.state.isLoading}
            open={this.state.postalOpen}
            handleClose={this.state.isLoading ? "" : this.handleModalClose}
            searchPostal={this.searchPostal}
            zipError={this.state.zipError}
            data={{
              postal: this.state.postal,
            }}
          />
        )}
        {this.state.isEditing && (
          <EditDialog
            checkEmails={this.checkEmails}
            createAccount={(e) => this.createAccount(e)}
            streetArr={this.state.streetArr}
            isEmailAvailable={this.isEmailAvailable}
            data={{
              needsEmail: this.state.needsEmail,
              isEditing: this.state.isEditing,
              id: this.state.id,
              business: this.state.business,
              location_id: this.state.location_id,
              fname: this.state.fname,
              lname: this.state.lname,
              street_name: this.state.street_name,
              street_number: this.state.street_number,
              unit: this.state.unit,
              email: this.state.email,
              phone: this.state.phone,
              country: this.state.country,
              state: this.state.state,
              postal: this.state.postal,
              city: this.state.city,
              status: this.state.status,
              account_id: this.state.account_id,
            }}
            updateAccount={(e) => this.updateAccount(e)}
            editPostal={(state) => {
              this.setState({ isEditing: false, postalOpen: true });
              this.setState({
                needsEmail: state.needsEmail,
                business: state.business,
                location_id: state.location_id,
                fname: state.fname,
                lname: state.lname,
                street_name: state.street_name,
                street_number: state.street_number,
                unit: state.unit,
                email: state.email,
                phone: state.phone,
                country: state.country,
                state: state.state,
                postal: state.postal,
                city: state.city,
                status: state.status,
                account_id: state.account_id,
              });
            }}
            open={this.state.isEditing}
            handleClose={this.state.isLoading ? "" : this.handleModalClose}
            searchPostal={this.searchPostal}
            zipError={this.state.zipError}
          />
        )}
        {this.state.isUploading && (
          <UploadDialog
            checkEmails={this.checkEmails}
            createAccount={this.createAccount}
            isEmailAvailable={this.isEmailAvailable}
            data={this.state.uploadData}
            open={this.state.isUploading}
            handleClose={this.state.isLoading ? "" : this.handleModalClose}
          />
        )}
        <div className="notes">
          <Typography variant="h1">
            {this.props.account.user.childType
              ? `${this.props.account.user.childType} Accounts`
              : "Child Accounts"}
          </Typography>
          <Button color="secondary" onClick={this.handleModalOpen}>
            <AddCircleOutline style={{ marginRight: "5px" }} /> New account
          </Button>
          <Divider />
          <Grid container lg={8} md={12} sm={12}>
            <Grid item xs={12} md={12}>
              <div
                // className={classes.dateContainer}
                style={{ padding: "20px 20px" }}
              >
                <h3>
                  {this.props.account.user.childType
                    ? `${this.props.account.user.childType} `
                    : "Child "}
                  Reporting
                </h3>
                <TextField
                  id="start_date"
                  label="Start Date"
                  type="date"
                  value={this.state.start_date}
                  onChange={this.changeDate}
                  style={{ "padding-right": "10px" }}
                  // className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={this.state.isLoading}
                />
                <TextField
                  id="end_date"
                  label="End Date"
                  type="date"
                  value={this.state.end_date}
                  onChange={this.changeDate}
                  // className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={this.state.isLoading}
                />
                {this.state.isLoading ? (
                  <Button
                    disabled={true}
                    style={{ marginTop: "5px", marginLeft: "15px" }}
                    variant="contained"
                    size="small"
                    color="secondary"
                    // className={classes.margin}
                  >
                    Generating...
                  </Button>
                ) : (
                  <Fragment>
                    <Button
                      disabled={
                        this.state.start_date > this.state.end_date ||
                        this.state.isLoading
                      }
                      onClick={this.getReport}
                      variant="contained"
                      size="small"
                      color="secondary"
                      // className={classes.margin}
                      style={{ marginTop: "5px", marginLeft: "15px" }}
                    >
                      Create Report
                    </Button>
                    {this.state.error && (
                      <small style={{ color: "red", paddingLeft: "10px" }}>
                        {this.state.errorMsg}
                      </small>
                    )}
                  </Fragment>
                )}
                <br />
                <br />
              </div>
            </Grid>
            {this.state.searchSuccess && (
              <Fragment>
                <Grid item xs={12} sm={12} md={4}>
                  <ReportCard
                    color="#52be80"
                    icon={
                      <DoneOutline
                        style={{
                          color: "#52be80",
                          fontSize: "2rem",
                          height: "2rem",
                          width: "2rem",
                        }}
                      />
                    }
                    pretitle="Delivered"
                    title={this.state.delivered}
                    posttitle=""
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <ReportCard
                    color="var(--brand-cyan)"
                    icon={
                      <Autorenew
                        style={{
                          color: "var(--brand-cyan)",
                          fontSize: "2rem",
                          height: "2rem",
                          width: "2rem",
                        }}
                      />
                    }
                    pretitle="In-Transit"
                    title={this.state.in_transit}
                    posttitle=""
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
                  <ReportCard
                    button
                    color="#eee"
                    icon={
                      <CloudDownloadOutlined
                        style={{
                          color: "#aaa",
                          fontSize: "2rem",
                          height: "2rem",
                          width: "2rem",
                        }}
                      />
                    }
                    pretitle={
                      <span style={{ color: "#aaa" }}>Download Report</span>
                    }
                    title={
                      <Button
                        disabled={
                          this.state.start_date > this.state.end_date ||
                          this.state.isLoading ||
                          this.state.childData.length === 0
                        }
                        onClick={this.createCSV}
                        variant="contained"
                        size="small"
                        color="secondary"
                        // className={classes.margin}
                        style={{ marginTop: "5px" }}
                      >
                        Download&nbsp;CSV
                      </Button>
                    }
                  />
                </Grid>
              </Fragment>
            )}
          </Grid>
          <br />
          <Divider />
          <br />
          {/* <Grid container spacing={24}> */}
          {this.state.children.map((e) => (
            <ChildCard
              key={e.id}
              isLoading={this.state.id === e.id}
              account={this.props.account}
              startEdit={this.startEdit}
              data={e}
              editLocation={() => {}}
            />
          ))}
          {/* </Grid> */}
        </div>
      </div>
    );
  }
}

ChildAccounts.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChildAccounts);
