import AppBar from "@material-ui/core/AppBar";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Slide from "@material-ui/core/Slide";
import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import ShipmentMenu from "../components/ShipmentMenu";
const styles = {
  appBar: {
    position: "relative",
    background: "var(--brand-cyan)",
  },
  flex: {
    flex: 1,
  },
};

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class FullScreenDialog extends React.Component {
  state = {
    open: false,
    shipment: {},
  };

  UNSAFE_componentWillMount = async () => {};

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;

    return (
      this.props.shipment != undefined && (
        <div>
          <Dialog
            fullScreen
            open={this.props.open}
            onClose={this.props.handleClose}
            TransitionComponent={Transition}
          >
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton
                  color="inherit"
                  onClick={this.props.handleClose}
                  aria-label="Close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography
                  variant="h6"
                  color="inherit"
                  className={classes.flex}
                >
                  Order ID: {this.props.shipment.order_id}
                </Typography>
                {this.props.shipment.dropoff_print_requested === "true" ? (
                  <ShipmentMenu
                    account={this.props.account}
                    rate_id={this.props.shipment.rate_id}
                    image_url={this.props.shipment.dropoff_reprint_url}
                  />
                ) : (
                  ""
                )}
              </Toolbar>
            </AppBar>
            <List>
              <ListItem button>
                <ListItemText
                  primary="Status"
                  secondary={
                    <Fragment>
                      <strong>{this.props.status}</strong>
                    </Fragment>
                  }
                />
              </ListItem>
              <Divider />
              {this.props.shipment.receiver_business ? (
                <ListItem button>
                  <ListItemText
                    primary="Business"
                    secondary={this.props.shipment.receiver_business}
                  />
                </ListItem>
              ) : (
                ""
              )}
              {this.props.shipment.receiver_fname &&
              this.props.shipment.receiver_lname ? (
                <ListItem button>
                  <ListItemText
                    primary="Receiver Name"
                    secondary={
                      <Fragment>
                        {this.props.shipment.receiver_fname}{" "}
                        {this.props.shipment.receiver_lname}
                      </Fragment>
                    }
                  />
                </ListItem>
              ) : (
                ""
              )}
              {this.props.shipment.receiver_email ? (
                <ListItem
                  button
                  onClick={() =>
                    this.copyToClipboard(this.props.shipment.receiver_email)
                  }
                >
                  <ListItemText
                    primary="Email Address"
                    secondary={this.props.shipment.receiver_email}
                  />
                </ListItem>
              ) : (
                ""
              )}
              {this.props.shipment.receiver_phone ? (
                <ListItem button>
                  <ListItemText
                    primary="Phone Number"
                    secondary={this.props.shipment.receiver_phone}
                  />
                </ListItem>
              ) : (
                ""
              )}
              {this.props.shipment.receiver_address ? (
                <ListItem button>
                  <ListItemText
                    primary="Receiver Address"
                    secondary={
                      <Fragment>
                        {this.props.shipment.receiver_unit
                          ? `${this.props.shipment.receiver_unit} - `
                          : ""}
                        {this.props.shipment.receiver_address},{" "}
                        {this.props.shipment.receiver_city},{" "}
                        {this.props.shipment.receiver_state}{" "}
                        {this.props.shipment.receiver_postal}{" "}
                        {this.props.shipment.receiver_country}
                      </Fragment>
                    }
                  />
                </ListItem>
              ) : (
                ""
              )}
              <Divider />
              {this.props.shipment.sender_business ? (
                <ListItem button>
                  <ListItemText
                    primary="Business"
                    secondary={this.props.shipment.sender_business}
                  />
                </ListItem>
              ) : (
                ""
              )}
              {this.props.shipment.sender_fname &&
              this.props.shipment.sender_lname ? (
                <ListItem button>
                  <ListItemText
                    primary="Sender Name"
                    secondary={
                      <Fragment>
                        {this.props.shipment.sender_fname}{" "}
                        {this.props.shipment.sender_lname}
                      </Fragment>
                    }
                  />
                </ListItem>
              ) : (
                ""
              )}
              {this.props.shipment.sender_email ? (
                <ListItem
                  button
                  onClick={() =>
                    this.copyToClipboard(this.props.shipment.sender_email)
                  }
                >
                  <ListItemText
                    primary="Email Address"
                    secondary={this.props.shipment.sender_email}
                  />
                </ListItem>
              ) : (
                ""
              )}
              {this.props.shipment.sender_phone ? (
                <ListItem button>
                  <ListItemText
                    primary="Phone Number"
                    secondary={this.props.shipment.sender_phone}
                  />
                </ListItem>
              ) : (
                ""
              )}
              {this.props.shipment.sender_address ? (
                <ListItem button>
                  <ListItemText
                    primary="Sender Address"
                    secondary={
                      <Fragment>
                        {this.props.shipment.sender_unit
                          ? `${this.props.shipment.sender_unit} - `
                          : ""}
                        {this.props.shipment.sender_address},{" "}
                        {this.props.shipment.sender_city},{" "}
                        {this.props.shipment.sender_state}{" "}
                        {this.props.shipment.sender_postal}{" "}
                        {this.props.shipment.sender_country}
                      </Fragment>
                    }
                  />
                </ListItem>
              ) : (
                ""
              )}
              <Divider />
              {this.props.shipment.tracking ? (
                <a
                  target="_blank"
                  href={`https://www.swiftpost.com/tracking?t=${this.props.shipment.tracking}`}
                  rel="noreferrer"
                >
                  <ListItem button>
                    <ListItemText
                      primary="Tracking"
                      secondary={
                        <Fragment>
                          <img
                            src={`https://s3.amazonaws.com/swiftpost-img/logo-${this.props.shipment.service_courier.toUpperCase()}.png`}
                            style={{ height: "10px", marginRight: "10px" }}
                          />
                          {this.props.shipment.tracking}
                        </Fragment>
                      }
                    />
                  </ListItem>
                </a>
              ) : (
                ""
              )}
              {this.props.shipment.image_url ? (
                <Fragment>
                  <a
                    target="_blank"
                    href={this.props.shipment.image_url}
                    rel="noreferrer"
                  >
                    <ListItem button>
                      <ListItemText
                        primary="Label"
                        secondary={
                          <Fragment>
                            <img
                              src={this.props.shipment.image_url}
                              style={{ height: "15px", marginRight: "10px" }}
                            />
                            Click to view
                          </Fragment>
                        }
                      />
                    </ListItem>
                  </a>
                  <Divider />
                </Fragment>
              ) : (
                ""
              )}
              <ListItem button>
                <ListItemText
                  primary="Entered Package Details"
                  secondary={
                    <Fragment>
                      Weight:{" "}
                      {Number(this.props.shipment.package_weight).toFixed(2)} lb
                      <br />
                      Length:{" "}
                      {Number(this.props.shipment.package_length).toFixed(2)} in
                      <br />
                      Width:{" "}
                      {Number(this.props.shipment.package_width).toFixed(2)} in
                      <br />
                      Height:{" "}
                      {Number(this.props.shipment.package_height).toFixed(2)} in
                      <br />
                    </Fragment>
                  }
                />
              </ListItem>
              {this.props.shipment.package_weight_measured ? (
                <ListItem button>
                  <ListItemText
                    primary="Measured Package Details"
                    secondary={
                      <Fragment>
                        Weight:{" "}
                        {Number(
                          this.props.shipment.package_weight_measured
                        ).toFixed(2)}{" "}
                        lb
                        <br />
                        Length:{" "}
                        {Number(
                          this.props.shipment.package_length_measured
                        ).toFixed(2)}{" "}
                        in
                        <br />
                        Width:{" "}
                        {Number(
                          this.props.shipment.package_width_measured
                        ).toFixed(2)}{" "}
                        in
                        <br />
                        Height:{" "}
                        {Number(
                          this.props.shipment.package_height_measured
                        ).toFixed(2)}{" "}
                        in
                        <br />
                      </Fragment>
                    }
                  />
                </ListItem>
              ) : (
                ""
              )}
            </List>
          </Dialog>
        </div>
      )
    );
  }
}

FullScreenDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FullScreenDialog);
