import { API, graphqlOperation } from "aws-amplify";

export const getPaymentMethod = (id: string) =>
  API.graphql(
    graphqlOperation(`
      query {
        getV1_PaymentMethod (id: "${id}") {
          id
          title
          card {
            token
            exp
            pan
            year
            month
            type
          }
          cognitoUsername
        }
      }
    `)
  );
