import { Drawer, Grid, IconButton } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { MoreVert } from "@material-ui/icons";
import { API } from "aws-amplify";
import React from "react";
import toRenderProps from "recompose/toRenderProps";
import withState from "recompose/withState";
import LoadingIcon from "./loading/LoadingIcon";

const WithState = toRenderProps(withState("anchorEl", "updateAnchorEl", null));

class RenderPropsMenu extends React.Component {
  state = {
    image_url: this.props.dropoff_reprint_image,
    isLoading: true,
  };
  handleClose = () => {
    this.setState({ image_url: "" });
    this.setState({ top: false });
  };
  render() {
    return (
      <WithState>
        {({ anchorEl, updateAnchorEl }) => {
          const open = Boolean(anchorEl);
          const handleClose = () => {
            updateAnchorEl(null);
          };

          return (
            <React.Fragment>
              <Drawer
                anchor="top"
                open={this.state.top}
                onClose={this.handleClose}
              >
                <div
                  style={{ height: "50vh" }}
                  tabIndex={0}
                  role="button"
                  onClick={this.handleClose}
                  onKeyDown={this.handleClose}
                >
                  {this.state.isLoading ? (
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%,-50%)",
                      }}
                    >
                      <center>
                        <LoadingIcon size={50} />
                      </center>
                    </div>
                  ) : (
                    <Grid xs={12} sm={12} md={6} lg={4}>
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%,-50%)",
                          width: "100%",
                        }}
                      >
                        <center>
                          <img
                            src={this.state.image_url}
                            style={{
                              padding: "10px",
                              boxSizing: "border-box",
                              maxWidth: "100%",
                              height: "auto",
                            }}
                          />
                        </center>
                      </div>
                    </Grid>
                  )}
                </div>
              </Drawer>
              <IconButton
                aria-owns={open ? "render-props-menu" : undefined}
                aria-haspopup="true"
                onClick={(event) => {
                  updateAnchorEl(event.currentTarget);
                }}
              >
                <MoreVert style={{ color: "#fff" }} />
              </IconButton>
              <Menu
                id="render-props-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    if (this.props.image_url) {
                      this.setState({ image_url: this.props.image_url });
                      this.setState({ top: true });
                      this.setState({ isLoading: false });
                    } else {
                      this.setState({ top: true });
                      // console.log({
                      //     body:{
                      //         account: this.props.account,
                      //         rate_id: this.props.rate_id,
                      //     }
                      // })
                      API.post("sqs", "/dropoff/reprint", {
                        body: {
                          account: this.props.account,
                          rate_id: this.props.rate_id,
                        },
                      }).then((e) => {
                        this.setState({ top: true });

                        this.setState({ image_url: e.url });
                        this.setState({ isLoading: false });
                      });
                    }
                  }}
                >
                  Dropoff Reprint Barcode
                </MenuItem>
              </Menu>
            </React.Fragment>
          );
        }}
      </WithState>
    );
  }
}
export default RenderPropsMenu;
