//react
//components
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
// import LoadingIcon from '../components/loading/LoadingIcon';
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
//icons
import FaceOutlined from "@material-ui/icons/FaceOutlined";
import LockOutlined from "@material-ui/icons/LockOutlined";
import Replay from "@material-ui/icons/Replay";
import { API, Auth } from "aws-amplify";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
//snackbars
import SnackBar from "../../components/Snackbar";
//helpers
import {
  isNotEmpty,
  isValidEmail,
  isValidPassword,
} from "../../helpers/Validation";
//images
import Logo from "../../img/logo.svg";
//styles
import "../../styles/Signup.css";

//---------------------------------------------

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2,
  },
  resetContainer: {
    padding: theme.spacing.unit * 3,
  },
});

class Signup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0,
      isLoading: false,
      email: "",
      password: "",
      confirmPassword: "",
      confirmationCode: "",
      newUser: null,
      snackOpen: false,
      snackMessage: "",
      snackVariant: "error",
      formComplete: false,
    };
  }

  validateForm() {
    this.setState({ isLoading: true });
    if (!isValidEmail(this.state.email) || !isNotEmpty(this.state.email)) {
      this.setState({ isLoading: false });
      this.setState({ snackVariant: "error" });
      this.setState({
        snackMessage: this.language.InvalidEmailAddress[this.state.language],
      });
      this.setState({ snackOpen: true });
      return false;
    }
    if (
      !isValidPassword(this.state.password) ||
      !isNotEmpty(this.state.password) ||
      this.state.password != this.state.confirmPassword
    ) {
      this.setState({ isLoading: false });
      this.setState({ snackVariant: "error" });
      this.setState({
        snackMessage: this.language.PasswordMismatch[this.state.language],
      });
      this.setState({ snackOpen: true });
      return false;
    }
    // this.setState({ isLoading: false });
    return true;
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleSubmit = async (event) => {
    this.setState({ isLoading: true });

    event.preventDefault();
    if (!this.validateForm()) {
      return;
    }

    // const betaCheck = await API.post("sqs", "/beta", {
    //   body: { email: this.state.email.toUpperCase() },
    // });
    // if (!betaCheck.status) {
    //   this.setState({ isLoading: false });
    //   this.setState({ snackVariant: "warning" });
    //   this.setState({
    //     snackMessage: this.language.EarlyAccess[this.state.language],
    //   });
    //   this.setState({ snackOpen: true });
    //   return;
    // }

    // console.log(this.state.email)
    // console.log(this.state.password)

    try {
      await Auth.signUp({
        username: this.state.email,
        password: this.state.password,
      }).then((e) => {
        console.log(e);
      });
      this.setState({
        formComplete: true,
      });
    } catch (e) {
      // alert(e.message);
      this.setState({ isLoading: false });
      this.setState({ snackVariant: "error" });
      this.setState({ snackMessage: e.message });
      this.setState({ snackOpen: true });
    }

    this.setState({ isLoading: false });
  };

  UNSAFE_componentWillMount = async () => {
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });
  };

  language = {
    InvalidEmailAddress: {
      FR: "L'adresse e-mail fournie n'est pas valide.",
      EN: "Email address provided is invalid.",
    },
    PasswordMismatch: {
      FR: "Les mots de passe saisis ne sont pas valides ou ne correspondent pas. Veuillez confirmer que vous avez au moins 8 caractères avec 1 symbole, 1 chiffre, 1 lettre majuscule et 1 lettre minuscule.",
      EN: "The passwords entered are not valid or do not match. Please confirm you have at least 8 characters with 1 symbol, 1 number, 1 uppercase letter and 1 lowercase letter.",
    },
    EarlyAccess: {
      FR: "Nous sommes actuellement en accès anticipé. Veuillez attendre notre lancement public avant de vous inscrire.",
      EN: "Due to the overwhelming number of people looking for shipping alternatives because of COVID-19, we have limited signup access to Swiftpost. To get a Swiftpost account please contact support@swiftpost.com or call us at 1-888-655-6258 and we'll be happy to set you up with an account.",
    },
    Createanaccount: {
      FR: "Créer un compte",
      EN: "Create an account",
    },
    EmailAddress: {
      FR: "Adresse électronique",
      EN: "Email Address",
    },
    PasswordConstraints: {
      FR: "Minimum 8 caractères, doit contenir 1 symbole, 1 chiffre, 1 lettre majuscule et 1 lettre minuscule",
      EN: "Minimum 8 characters, must contain 1 symbol, 1 number, 1 uppercase letter and 1 lowercase letter",
    },
    Password: {
      FR: "Mot de passe",
      EN: "Password",
    },
    ConfirmPassword: {
      FR: "Confirmez le mot de passe",
      EN: "Confirm Password",
    },
    TermsAndConditions: {
      FR: (
        <>
          En cliquant sur <em> Créer un compte </em> ci-dessous, vous acceptez{" "}
          <a
            href="https://www.swiftpost.com/terms-of-use/"
            target="_blank"
            style={{ color: "var(--brand-cyan)" }}
            rel="noreferrer"
          >
            les conditions générales de Swiftpost
          </a>
          .
        </>
      ),
      EN: (
        <>
          By clicking <em>Create Account</em> below, you are accepting
          Swiftpost's&nbsp;
          <a
            href="https://www.swiftpost.com/terms-of-use/"
            target="_blank"
            style={{ color: "var(--brand-cyan)" }}
            rel="noreferrer"
          >
            terms and conditions
          </a>
          .
        </>
      ),
    },
    CreateAccount: {
      FR: "Créer un compte",
      EN: "Create Account",
    },
    Loading: {
      FR: "Attendre",
      EN: "Loading",
    },
    Averificationemailhasbeensentto: {
      FR: "Un email de vérification a été envoyé à",
      EN: "A verification email has been sent to",
    },
    Anotherverificationemailhasbeensent: {
      FR: "Un autre e-mail de vérification a été envoyé",
      EN: "Another verification email has been sent",
    },
    ResendConfirmationEmail: {
      FR: "Renvoyer l'e-mail de confirmation",
      EN: "Resend Confirmation Email",
    },
    AlreadyHaveanAccount: {
      FR: "Vous avez déjà un compte?",
      EN: "Already Have an Account?",
    },
    ReadytoLogin: {
      FR: "Prêt à vous connecter?",
      EN: "Ready to Login?",
    },
    Login: {
      FR: "S'identifier",
      EN: "Login",
    },
  };

  render() {
    return (
      <div className="Login">
        <SnackBar
          variant={this.state.snackVariant}
          open={this.state.snackOpen}
          message={this.state.snackMessage}
          handleClose={() => {
            this.setState({ snackOpen: false });
          }}
        />
        <Grow in={true} timeout={500}>
          <Grid item xs={12} sm={7} md={5} style={{ margin: "auto" }}>
            <img src={Logo} className="Paper" alt="Swiftpost Logo" />
            <Paper
              elevation={6}
              style={{ padding: "30px", maxWidth: "400px" }}
              className="Paper"
            >
              <Typography variant="headline" component="h3">
                {!this.state.formComplete
                  ? this.language.Createanaccount[this.state.language]
                  : ""}
              </Typography>

              {!this.state.formComplete ? (
                <form onSubmit={this.handleSubmit}>
                  <Grid
                    className="Input"
                    style={{
                      width: "100%",
                    }}
                    container
                    spacing={8}
                    alignItems="flex-end"
                  >
                    <Grid item xs={1}>
                      <FaceOutlined />
                    </Grid>
                    <Grid item xs={11}>
                      <TextField
                        style={{ width: "100%" }}
                        id="email"
                        onChange={this.handleChange}
                        value={this.state.email}
                        type="email"
                        label={this.language.EmailAddress[this.state.language]}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    className="Input"
                    style={{
                      width: "100%",
                    }}
                    container
                    spacing={8}
                    alignItems="flex-end"
                  >
                    <Grid item xs={1}>
                      <LockOutlined style={{ marginBottom: "30px" }} />
                    </Grid>
                    <Grid item xs={11}>
                      <TextField
                        helperText={
                          this.language.PasswordConstraints[this.state.language]
                        }
                        style={{ width: "100%" }}
                        id="password"
                        onChange={this.handleChange}
                        value={this.state.password}
                        type="password"
                        label={this.language.Password[this.state.language]}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    className="Input"
                    style={{
                      width: "100%",
                    }}
                    container
                    spacing={8}
                    alignItems="flex-end"
                  >
                    <Grid item xs={1}>
                      <Replay />
                    </Grid>
                    <Grid item xs={11}>
                      <TextField
                        style={{ width: "100%" }}
                        id="confirmPassword"
                        onChange={this.handleChange}
                        value={this.state.confirmPassword}
                        type="password"
                        label={
                          this.language.ConfirmPassword[this.state.language]
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    className="Input no-margin"
                    style={{
                      width: "100%",
                    }}
                    container
                    spacing={8}
                    alignItems="flex-end"
                  >
                    <Grid item xs={12}>
                      <Typography>
                        {this.language.TermsAndConditions[this.state.language]}
                      </Typography>
                    </Grid>
                  </Grid>

                  <br />

                  <Button
                    className="loginSubmit"
                    variant="contained"
                    color="primary"
                    style={{
                      width: "100%",
                    }}
                    onClick={this.handleSubmit}
                    disabled={this.state.isLoading}
                  >
                    {!this.state.isLoading ?
                      this.language.CreateAccount[this.state.language] :
                      this.language.Loading[this.state.language]}
                  </Button>
                </form>
              ) : (
                <Grow timeout={1000} in={this.state.formComplete}>
                  <center>
                    <CheckIcon style={{ color: "green", fontSize: "80px" }} />
                    <h4>
                      {
                        this.language.Averificationemailhasbeensentto[
                          this.state.language
                        ]
                      }{" "}
                      {this.state.email}
                    </h4>
                    <br />
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        width: "100%",
                      }}
                      onClick={() => {
                        Auth.resendSignUp(this.state.email);
                        this.setState({ snackVariant: "success" });
                        this.setState({
                          snackMessage:
                            this.language.Anotherverificationemailhasbeensent[
                              this.state.language
                            ],
                        });
                        this.setState({ snackOpen: true });
                      }}
                    >
                      {
                        this.language.ResendConfirmationEmail[
                          this.state.language
                        ]
                      }
                    </Button>
                  </center>
                </Grow>
              )}
            </Paper>
            <Grid
              item
              style={{ margin: "auto", padding: "0px", maxWidth: "400px" }}
            >
              <Typography className="callout">
                {!this.state.formComplete
                  ? this.language.AlreadyHaveanAccount[this.state.language]
                  : this.language.ReadytoLogin[this.state.language]}
              </Typography>
              {this.state.isLoading ? (
                <Button
                  disabled
                  variant="contained"
                  color="secondary"
                  style={{
                    width: "100%",
                  }}
                >
                  {this.language.Login[this.state.language]}
                </Button>
              ) : (
                <Link to="/login">
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{
                      width: "100%",
                    }}
                  >
                    {this.language.Login[this.state.language]}
                  </Button>
                </Link>
              )}
            </Grid>
          </Grid>
        </Grow>
      </div>
    );
  }
}

Signup.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(Signup);
