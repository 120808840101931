import { Typography } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";
import FulfillmentTypes from "./fulfillment_shipment/fulfillment_types";
import CopyIcon from "@material-ui/icons/FileCopyRounded";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import React, { Fragment } from "react";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: 15,
    marginRight: theme.spacing.unit,
    width: "100px",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 80,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  avatar: {
    fontSize: "1em",
    margin: 10,
    display: "inline-flex",
    // marginTop: "40px",
  },
  orangeAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: "blue",
  },
  row: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    width: 80,
    margin: theme.spacing.unit,
  },
});

class SimpleSelect extends React.Component {
  state = {
    quantities: [1],
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  UNSAFE_componentWillMount = async () => {
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });
  };

  language = {
    Add: {
      FR: "Ajouter le",
      EN: "Add",
    },
    package: {
      FR: "paquet",
      EN: "package",
    },
    inches: {
      FR: "",
      EN: "inches",
    },
    pounds: {
      FR: "livre",
      EN: "pounds",
    },
    Package: {
      FR: "Paquet",
      EN: "Package",
    },
    MaximumReached: {
      FR: "Maximum atteint",
      EN: "Maximum Reached",
    },
    Remove: {
      FR: "Retirer",
      EN: "Remove",
    },
    Weight: {
      FR: "Poids",
      EN: "Weight",
    },
    lb: {
      FR: "livre",
      EN: "lb",
    },
    Length: {
      FR: "Longueur",
      EN: "Length",
    },
    in: {
      FR: "pouce",
      EN: "in",
    },
    Width: {
      FR: "Largeur",
      EN: "Width",
    },
    Height: {
      FR: "Taille",
      EN: "Height",
    },
  };

  renderFixedPLD = (pkg) => (
    <Typography>
      {pkg.dimensions.length} {this.language.package[this.state.language]}
      {pkg.dimensions.length > 1 ? `s` : ``}
    </Typography>
  );

  renderPackages = (pkg) => {
    return pkg.dimensions.map((dims, i) => (
      <form key={i} className={styles.container} noValidate autoComplete="off">
        <Grid container justifyContent="center" alignItems="center">
          <Avatar
            className={styles.avatar}
            style={{ display: "inline-flex", marginTop: "0px" }}
          >
            {i + 1}
          </Avatar>
          <Select
            style={{ marginLeft: 10, width: 200 }}
            native
            value={dims.fulfillment_type}
            onChange={(event) =>
              this.props.handleFulfillmentChange(
                event.target.value,
                i,
                dims.quantity
              )
            }
            inputProps={{
              name: `fulfillment-${i}`,
              id: `fulfillment-${i}`,
            }}
          >
            {FulfillmentTypes.map((fulfillment_type) => (
              <option value={fulfillment_type.code}>
                {fulfillment_type.name}
              </option>
            ))}
          </Select>
          <TextField
            style={{
              marginLeft: 10,
              marginBottom: 16,
              marginTop: 0,
              width: "75px",
              textAlign: "right",
            }}
            required
            type="number"
            error={this.props.error}
            className={styles.textField}
            label={"Quantity"}
            value={dims.quantity}
            onChange={(event) => {
              this.props.handleFulfillmentChange(
                dims.fulfillment_type,
                i,
                event.target.value < 1 ? 1 : Math.ceil(event.target.value)
              );
            }}
            margin="normal"
            inputProps={{
              min: 1,
            }}
          />
        </Grid>
      </form>
    ));
  };

  ending = (num) =>
    this.state.language == "FR"
      ? ["e", "er", "e", "e", "e", "e", "e", "e", "e", "e"][num % 10]
      : num > 10 && num < 14
      ? "th"
      : ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"][num % 10];

  render() {
    const { classes } = this.props;

    // console.log("PKG MEASUREMENTS =>>>>> ", this.props);

    return (
      <FormControl className={classes.formControl}>
        {this.props.fixedPLD
          ? this.renderFixedPLD(this.props.package)
          : this.renderPackages(this.props.package)}
        <br />
        <div>
          {this.props.maxQuantity !== 1 && (
            <Fragment>
              <Button
                disabled={
                  this.props.package.count >= this.props.maxQuantity ||
                  this.props.disabled
                }
                style={{ width: "auto", float: "left", marginRight: 15 }}
                variant="outlined"
                color="primary"
                className={styles.button}
                onClick={() => {
                  let copy = this.state.quantities;
                  copy.push(1);
                  console.log(copy);
                  this.setState({ quantities: copy });
                  this.props.adjustCount(1);
                }}
              >
                {this.props.package.dimensions.length < this.props.maxQuantity
                  ? `${this.language.Add[this.state.language]} ${
                      Number(this.props.package.dimensions.length) + 1
                    }${this.ending(
                      Number(this.props.package.dimensions.length) + 1
                    )} ${this.language.Package[this.state.language]}`
                  : `${this.language.MaximumReached[this.state.language]}`}
              </Button>
              <Button
                style={{ width: 150, float: "left" }}
                disabled={this.props.package.count == 1 || this.props.disabled}
                color="secondary"
                className={styles.button}
                onClick={() => {
                  let copy = this.state.quantities;
                  this.setState({ quantities: copy.slice(0, -1) });
                  this.props.adjustCount(-1);
                }}
              >
                {this.language.Remove[this.state.language]}
              </Button>
            </Fragment>
          )}
        </div>
        <br />
        <br />
      </FormControl>
    );
  }
}

SimpleSelect.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleSelect);
