import { CssBaseline } from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import * as Sentry from "@sentry/react";
import Amplify from "aws-amplify";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import config from "./config";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

// const memoryHistory = createMemoryHistory(options);

if (process.env.REACT_APP_STAGE === "prod") {
  Sentry.init({
    dsn: "https://defdd4ed7c374a0f93c04db57575a6e0@o777026.ingest.sentry.io/4504175965044736",
  });
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#00addb",
      contrastText: "#fff",
    },
    secondary: {
      main: "#ff7f43",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Matter Regular",
    h1: {
      fontFamily: "Matter SemiBold",
      fontSize: "2rem",
    },
    button: {
      fontFamily: "Matter SemiBold",
    },
    useNextVariants: true,
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: "2em",
        fontSize: "1rem",
        textTransform: "none",
        transition: ".5s cubic-bezier(0, 0, .1, 1)",
      },
      containedPrimary: {
        backgroundColor: "var(--deep-blue)",
        color: "var(--white)",

        "&$disabled": {
          backgroundColor: "#cecece",
          color: "#939393",
        },

        "&:hover": {
          backgroundColor: "var(--navy)",
          color: "var(--yellow)",
        },
      },
      flatPrimary: {
        backgroundColor: "var(--deep-blue)",
        color: "var(--white)",

        "&$disabled": {
          backgroundColor: "#cecece",
          color: "#939393",
        },

        "&:hover": {
          backgroundColor: "var(--navy)",
          color: "var(--yellow)",
        },
      },
      outlined: {
        backgroundColor: "transparent",

        "&$disabled": {
          border: "1px solid #939393",
          color: "#939393",
        },
      },
      sizeSmall: {
        padding: "0.4em 1em",
      },
      text: {
        padding: "0.4em 1em",
      },
    },
    MuiStepConnector: {
      lineHorizontal: {
        borderColor: "transparent",
      },
    },
    MuiStepLabel: {
      label: {
        fontSize: 16,
      },
      active: {
        fontFamily: "Matter SemiBold",
      },
    },
    MuiStep: {
      vertical: {
        "& text": {
          display: "none",
        },
      },
    },
    MuiDivider: {
      root: {
        margin: "28px 0 0 0",
      },
    },
  },
});

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [
      {
        name: "app",
        endpoint: config.app_apiGateway.URL,
        region: config.app_apiGateway.REGION,
      },
      {
        name: "addressbook",
        endpoint: config.addressbook.URL,
        region: config.addressbook.REGION,
      },
      {
        name: "check",
        endpoint: config.checkCognito.URL,
        region: config.checkCognito.REGION,
      },
      {
        name: "child",
        endpoint: config.child.URL,
        region: config.child.REGION,
      },
      {
        name: "ecomm_test",
        endpoint: config.ecomm_test.URL,
        region: config.ecomm_test.REGION,
      },
      {
        name: "selfdelivery",
        endpoint: config.selfdelivery.URL,
        region: config.selfdelivery.REGION,
      },
      {
        name: "shopify",
        endpoint: config.etsy.URL,
        region: config.etsy.REGION,
      },
      {
        name: "sqs",
        endpoint: config.sp_sqs.URL,
        region: config.sp_sqs.REGION,
      },
      {
        name: "uploadorders",
        endpoint: config.uploadorders.URL,
        region: config.uploadorders.REGION,
      },
    ],
  },
});

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    {/* <Support /> */}
    <Router>
      <App />
    </Router>
  </MuiThemeProvider>,
  document.getElementById("root") as HTMLElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
