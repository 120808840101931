/* eslint-disable react/no-multi-comp */

import Button from "@material-ui/core/Button";
import blue from "@material-ui/core/colors/blue";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import React from "react";

const styles = {
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
};

class LogoutDialog extends React.Component {
  UNSAFE_componentWillMount = () => {
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });
  };

  language = {
    LogoutAlert: {
      FR: "Toutes les données saisies sur cette page peuvent être perdues après la déconnexion",
      EN: "Any data entered on this page may be lost after signing out.",
    },
    LogoutAlertTitle: {
      FR: "Êtes-vous sûr de vouloir vous déconnecter?",
      EN: "Are you sure you want to logout?",
    },
    No: {
      FR: "Non",
      EN: "No",
    },
    YesLogout: {
      FR: "Oui, déconnecte",
      EN: "Yes, logout",
    },
    Logout: {
      FR: "Déconnexion",
      EN: "Logout",
    },
  };

  handleClose = () => {
    this.props.onClose(this.props.selectedValue);
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {this.language.LogoutAlertTitle[this.state.language]}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {this.language.LogoutAlert[this.state.language]}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            {this.language.No[this.state.language]}
          </Button>
          <Button onClick={this.props.handleLogout} color="primary" autoFocus>
            {this.language.YesLogout[this.state.language]}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

LogoutDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  selectedValue: PropTypes.string,
};

const LogoutDialogWrapped = withStyles(styles)(LogoutDialog);

class Logout extends React.Component {
  state = {
    open: false,
  };

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  UNSAFE_componentWillMount = () => {
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });
  };

  language = {
    Logout: {
      FR: "Déconnexion",
      EN: "Logout",
    },
  };

  handleClose = (value) => {
    this.setState({ selectedValue: value, open: false });
  };

  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Tooltip
          title={this.language.Logout[this.state.language]}
          placement="right"
        >
          <ListItem button onClick={this.handleClickOpen}>
            <ListItemIcon className="navicon">
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary={this.language.Logout[this.state.language]} />
          </ListItem>
        </Tooltip>
        <LogoutDialogWrapped
          selectedValue={this.state.selectedValue}
          open={this.state.open}
          onClose={this.handleClose}
          handleLogout={this.props.handleLogout}
          {...rest}
        />
      </div>
    );
  }
}

export default Logout;
