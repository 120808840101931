/* eslint-disable react/no-multi-comp */

import { CircularProgress, Typography } from "@material-ui/core";
import blue from "@material-ui/core/colors/blue";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { API } from "aws-amplify";
import React, { Fragment } from "react";

const styles = {
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
};

class ShopifyImportModal extends React.Component {
  state = {
    open: false,
    postal: "",
    error: false,
    activeStep: 0,
    senderLocationID:
      this.props.locations.length > 0 ? this.props.locations[0].id : "",
    senderLocation:
      this.props.locations.length > 0 ? this.props.locations[0] : "",
  };

  UNSAFE_componentWillMount = () => {
    // const socket2 = new WebSocket('wss://keuhz93lxj.execute-api.us-east-1.amazonaws.com/dev');
    // socket2.addEventListener('open', function (event) {
    //     socket2.send(JSON.stringify({'action':'sendMessage'}));
    //     // alert('connected')
    //   });
    // socket2.addEventListener('message', event => {
    //     // alert('socket msg');
    //     // console.log(JSON.parse(event.data));
    //     let data = JSON.parse(event.data);
    //     if(data.connectionId){
    //         // alert(data.connectionId)
    //         this.setState({connection_id: data.connectionId})
    //         // console.log("CONNECTED, ",data.connectionId)
    //     }
    //     // if(data.failed_row){
    //     //   this.
    //     // }
    //     // if(data.success_row){
    //     // }
    // })
  };

  handleListItemClick = (value) => {
    this.props.onClose(value);
  };

  handleChange = (name) => (event) => {
    let tempString = event.target.value;
    this.setState({ [name]: tempString.toUpperCase() });
  };

  handleLocationChange = (e) => {
    // alert('trying')
    this.setState({ senderLocation: e.location });
    this.setState({ senderLocationID: e.id });
  };

  getFileData = (e) => {
    if (e.target.files && e.target.files[0]) {
      var FR = new FileReader();

      // this.setState({isLoading: true});

      FR.addEventListener("load", async (e) => {
        this.setState({ error: false });

        var temp = e.target.result.replace(/^data*.*base64,/gm, "");
        temp = atob(temp);
        const csv = require("csvtojson");
        csv()
          .fromString(temp)
          .then((csvRow) => {
            console.log(csvRow);
            // if(csvRow.filter(e=>!(e.reference) || !(e.package_height_in) || !(e.package_length_in) || !(e.package_width_in) || !(e.package_weight_lb) || !(e.receiver_business) || !(e.receiver_fname) || !(e.receiver_lname) || !(e.recevier_address1) || !(e.receiver_address2) || !(e.receiver_unit) || !(e.receiver_city) || !(e.receiver_state) || !(e.receiver_postal) || !(e.receiver_country) || !(e.package_contents_title) || !(e.package_contents_total_value_usd) || !(e.package_contents_item_quantity) || !(e.service_level) || !(e.package_contents_origin_country)).length === 0){
            this.setState({ rows: csvRow });
            // this.props.startUpload(csvRow);
            this.props.increaseUploadStep();
            // this.setState({data: csvRow}) // => [["1","2","3"], ["4","5","6"], ["7","8","9"]]
            // }else{
            // this.setState({error:true})
            // }
          });
      });
      FR.readAsDataURL(e.target.files[0]);
    }
  };

  importOrders = (rows) => {
    // alert('Uploading...');
    //COMEBACK
    this.setState({ isLoading: true });
    this.props.toggleUploading(true);
    console.log({
      body: {
        shipments: rows,
        cogsub: this.props.account.cognitoUsername,
        senderLocation: this.state.senderLocation,
        senderLocationID: this.state.senderLocationID,
        connection_id: this.props.connection_id,
      },
    });
    API.post("uploadorders", "/upload-orders", {
      body: {
        shipments: rows,
        cogsub: this.props.account.cognitoUsername,
        senderLocation: this.state.senderLocation,
        senderLocationID: this.state.senderLocationID,
        connection_id: this.props.connection_id,
      },
    })
      .then((e) => {
        // alert("success")
        //   console.log(e);
      })
      .catch((e) => {
        this.setState({ isLoading: false });
        this.props.toggleUploading(false);
        alert(
          "There was an error uploading your file. Please try again later."
        );
        console.log(e);
      });
  };

  handleBack = () => {
    // alert('trying to bak')
  };

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={() => {
            if (!this.state.isLoading) {
              this.props.handleClose();
            }
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Fragment>
            <DialogContent style={{ padding: "20px 50px" }}>
              <center>
                <CircularProgress width="20" />
                <Typography variant="h1">Importing Shopify Orders</Typography>
                {this.props.shopify_count ? (
                  <>
                    <small style={{ color: "var(--brand-cyan)" }}>
                      {this.props.shopify_count} ELIGIBLE ORDERS FOUND.
                    </small>
                    <br />
                    <br />
                  </>
                ) : (
                  ""
                )}
                <small style={{ color: "rgba(0,0,0,.3)" }}>PLEASE WAIT</small>
              </center>
            </DialogContent>
          </Fragment>
        </Dialog>
      </div>
    );
  }
}

export default ShopifyImportModal;
