import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from "@material-ui/core";
import { withStyles, withTheme } from "@material-ui/core/styles";
import TablePagination from "@material-ui/core/TablePagination";
import { unstable_useMediaQuery as useMediaQuery } from "@material-ui/core/useMediaQuery";
import { FavoriteBorder } from "@material-ui/icons";
import { Country } from "@shared";
import PropTypes from "prop-types";
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { AddressBookRecord } from "../../../../shared/types/address";
import { useDebounce } from "../../hooks/useDebounce";
import {
  displayedFields,
  searchTokens,
  useAddressBook,
} from "../../providers/AddressBook";
import "../../styles.css";
import AddressTableRow, { FAVORITE_PADDING } from "./AddressTableRow";

const styles = (theme: Theme) => ({
  tableUtilityHeader: {
    display: "flex",
    padding: 16,
    background: "var(--brand-cyan)",
    justifyContent: "space-between",
  },
  tableUtilityHeaderTitle: {
    position: "relative" as const,
    color: "#fff",
  },
  tableUtilityHeaderSearch: {
    maxWidth: 240,
    padding: "0px 4px",
    boxSizing: "border-box" as const,
  },
  tableHeadCell: {
    borderTop: "1px solid var(--deep-blue)",
    borderBottom: "1px solid var(--deep-blue)",
    "&:first-child": {
      borderLeft: "1px solid var(--deep-blue)",
      borderTopLeftRadius: "8px",
    },
    "&:last-child": {
      borderRight: "1px solid var(--deep-blue)",
      borderTopRightRadius: "8px",
    },
  },
});

function AddressBookTable(props: {
  classes: any;
  theme: any;
  searchTerm: string;
  onRowClicked: (address: AddressBookRecord) => void;
  handleLogout: () => void;
  country?: Country;
}) {
  const { classes, theme, handleLogout } = props;

  const {
    listAddressBook,
    filteredAddresses,
    loadingAddresses,
    loadingAddressesError,
    setSearchString,
    setCountry,
  } = useAddressBook();

  const debouncedSearchTerm = useDebounce(props.searchTerm, 0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [requireLogoutOpen, setRequireLogoutOpen] = useState(false);

  const handleChangePage = (_: any, page: number) => setPage(page);
  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) =>
    setRowsPerPage(parseInt(event.target.value));

  // On mount, get addresses
  useEffect(() => {
    listAddressBook();
  }, [listAddressBook]);

  // If we're passed a country to filter by, pass that along to the provider
  useEffect(() => setCountry(props.country), [props.country, setCountry]);

  // When debounced search term is changed, fire off a request
  useEffect(() => {
    if (
      typeof debouncedSearchTerm !== "string" ||
      debouncedSearchTerm.length === 0
    ) {
      setSearchString("");
    } else {
      setSearchString(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, setSearchString]);

  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setRequireLogoutOpen(loadingAddressesError);
  }, [loadingAddressesError]);

  if (requireLogoutOpen) {
    return (
      <LogoutRequiredDialog
        onClose={() => setRequireLogoutOpen(false)}
        handleLogout={handleLogout}
      />
    );
  }

  return (
    <>
      <Table style={{ tableLayout: "fixed", borderCollapse: "separate" }}>
        <TableHead>
          <TableRow>
            {!isSmall && (
              <TableCell
                style={{ padding: FAVORITE_PADDING, width: 45 }}
                className={classes.tableHeadCell}
                id="favorite"
              >
                <FavoriteBorder />
              </TableCell>
            )}
            <TableCell className={classes.tableHeadCell}>
              Business Name
            </TableCell>
            <TableCell className={classes.tableHeadCell}>First Name</TableCell>
            <TableCell className={classes.tableHeadCell}>Last Name</TableCell>
            {!isSmall && (
              <>
                <TableCell className={classes.tableHeadCell}>Address</TableCell>
                <TableCell className={classes.tableHeadCell}>Email</TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <AddressTableBody
          loading={loadingAddresses}
          error={loadingAddressesError}
          rows={filteredAddresses}
          tokens={searchTokens(debouncedSearchTerm)}
          page={page}
          rowsPerPage={rowsPerPage}
          onRowClicked={props.onRowClicked}
          isSmall={isSmall}
        />
      </Table>
      <TablePagination
        rowsPerPageOptions={isSmall ? [] : [5, 10, 25, 50]}
        component="div"
        count={filteredAddresses?.length ?? 0}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          "aria-label": "Previous Page",
        }}
        nextIconButtonProps={{
          "aria-label": "Next Page",
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}

const AddressTableBody: FC<{
  loading: boolean;
  error: boolean;
  rows: AddressBookRecord[];
  tokens: string[];
  page: number;
  rowsPerPage: number;
  onRowClicked: (address: AddressBookRecord) => void;
  isSmall: boolean;
}> = ({
  loading,
  error,
  rows,
  tokens,
  page,
  rowsPerPage,
  onRowClicked,
  isSmall,
}) => {
  if (error) {
    return <AddressLoadingError isSmall={isSmall} />;
  }

  // Could show a loading indicator...
  if (loading || rows === undefined) {
    return <AddressLoading isSmall={isSmall} />;
  }

  // No search results
  if (!rows.length) {
    return <AddressTableEmpty isSmall={isSmall} />;
  }

  const paginated = rows.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <>
      <TableBody>
        {paginated.map((address) => (
          <AddressTableRow
            key={address.id}
            address={address}
            tokens={tokens}
            onRowClicked={onRowClicked}
            isSmall={isSmall}
          />
        ))}
      </TableBody>
    </>
  );
};

const AddressLoading: FC<{ isSmall: boolean }> = ({ isSmall }) => {
  return (
    <TableBody>
      <FullWidthTableRow isSmall={isSmall}>
        <div style={{ padding: 32 }}>
          <CircularProgress />
        </div>
      </FullWidthTableRow>
    </TableBody>
  );
};

const AddressLoadingError: FC<{ isSmall: boolean }> = ({ isSmall }) => {
  return (
    <TableBody>
      <FullWidthTableRow isSmall={isSmall}>
        There was a problem loading the addresses. Please login again.
      </FullWidthTableRow>
    </TableBody>
  );
};

const AddressTableEmpty: FC<{ isSmall: boolean }> = ({ isSmall }) => {
  return (
    <TableBody>
      <FullWidthTableRow isSmall={isSmall}>
        No addresses found.
      </FullWidthTableRow>
    </TableBody>
  );
};

const FullWidthTableRow: FC<{ isSmall: boolean }> = ({ children, isSmall }) => {
  return (
    <TableRow>
      <TableCell
        colSpan={displayedFields(isSmall).length + 1}
        style={{ textAlign: "center" }}
      >
        {children}
      </TableCell>
    </TableRow>
  );
};

const LogoutRequiredDialog = ({
  onClose,
  handleLogout,
}: {
  onClose: () => void;
  handleLogout: () => void;
}) => {
  return (
    <Dialog open={true}>
      <DialogTitle>Address Book Error</DialogTitle>
      <DialogContent>
        <DialogContentText>
          There was an issue loading the address book. If this is your first
          time using the address book please try logging out and back in again.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={handleLogout} autoFocus>
          Logout
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Highlight all matches for any tokens in the string
export function highlightTokens(
  search: string | undefined,
  tokens: string[] | undefined
) {
  if (!search) {
    return;
  }

  if (!tokens) {
    return search;
  }

  let content: Array<JSX.Element | string> = [search];

  // Walk through each token and replace any instances of it in any pure strings with a `strong` element
  tokens.forEach((token) => {
    content = content.flatMap((segment) => {
      // Don't try to look within existing matches (JSX.Elements)
      if (typeof segment !== "string") {
        return segment;
      }

      // If we find the token, return an array with that section wrapped in `strong`
      const firstIndex = segment.toLowerCase().indexOf(token);
      if (firstIndex >= 0) {
        return [
          segment.substring(0, firstIndex),
          <strong>
            {segment.substring(firstIndex, firstIndex + token.length)}
          </strong>,
          segment.substring(firstIndex + token.length),
        ];
      }
      // No match in this segment, so return it as-is
      else {
        return segment;
      }
    });
  });

  return content.map((c, idx) => <span key={idx}>{c}</span>);
}

AddressBookTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTheme()(withStyles(styles)(AddressBookTable));
