import { API, graphqlOperation } from "aws-amplify";

export const createBalance = () =>
  API.graphql(
    graphqlOperation(`
      mutation createBalance {
        createV1_Balance(input: { balance: "0.00" }) {
          id
          balance
          cognitoUsername
        }
      }
    `)
  );
