import { Theme } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { AddressBookRecord } from "../../../../../shared/types/address";
import AddressBookDialog from "../AddressBookDialog";
import AddressBookForm from "../AddressBookForm";
import { ImportRecord } from "./CSVImportTable";
import { useAddressCSVImporter } from "./useAddressCSVImporter";

const styles = (theme: Theme) => ({
  closeButton: {
    position: "absolute" as const,
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
});

function EditImportRecordDialog(props: {
  open: boolean;
  record: ImportRecord;
  onClose?: () => void;
  onSuccess?: () => void;
  classes: any;
}) {
  //const { listAddressBook } = useAddressBook();
  const { updateImportRecord } = useAddressCSVImporter();

  const handleFormSuccess = (address: AddressBookRecord) => {
    const updatedImportedRecord: ImportRecord = {
      ...props.record,
      verifiedAddress: address,
      importState: "imported",
    };

    updateImportRecord(updatedImportedRecord);
    props.onSuccess?.();
    props.onClose?.();
  };

  return (
    <AddressBookDialog
      title="Edit Import"
      open={props.open}
      onClose={props.onClose}
    >
      <AddressBookForm
        onSuccess={(address) => address && handleFormSuccess(address)}
        address={props.record.importedAddress}
      />
    </AddressBookDialog>
  );
}

export default withStyles(styles)(EditImportRecordDialog);
