const FulfillmentTypes = [
  // {
  //   code: "6PAK",
  //   name: "Beer, 6Pak",
  //   measurements: { weight: 2, length: 8, width: 5.5, height: 8 },
  // },
  {
    code: "CASE_BEER",
    name: "Beer, Case",
    measurements: { weight: 30, length: 13, width: 8, height: 10 },
  },
  {
    code: "20L_KEG",
    name: "Keg, 20L",
    measurements: { weight: 24, length: 24, width: 10, height: 10 },
  },
  {
    code: "30L_KEG",
    name: "Keg, 30L",
    measurements: { weight: 24, length: 24, width: 12, height: 12 },
  },
  {
    code: "50L_KEG",
    name: "Keg, 50L",
    measurements: { weight: 30, length: 24, width: 16, height: 16 },
  },
];

export default FulfillmentTypes;
