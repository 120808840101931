//react
import React from "react";
import { Auth } from "aws-amplify";
import { getParameterByName } from "../../helpers/TaskRunners";
import { Link } from "react-router-dom";

//containers
// import Loading from './Loading';

//icons
import ErrorIcon from "@material-ui/icons/ErrorOutline";

//components
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

export default class Verify extends React.Component {
  state = {
    noError: true,
  };

  async componentDidMount() {
    await Auth.confirmSignUp(
      getParameterByName("id"),
      getParameterByName("code"),
      {}
    )
      .then((e) => {
        this.props.history.push("/login?user=" + getParameterByName("user"));
      })
      .catch((e) => {
        // console.log(e)
        switch (e.code) {
          case "CodeMismatchException":
            Auth.resendSignUp(getParameterByName("user"));
            this.setState({
              errorMessage:
                "The link used has expired. A new verification link has been sent to your email.",
            });
            this.setState({ noError: false });
            break;
          case "LimitExceededException":
            this.setState({
              errorMessage:
                "You have exceeded the number of attempts to verify your account. Please wait a while before trying again.",
            });
            this.setState({ noError: false });
            break;
          default:
            this.props.history.push(
              "/login?user=" + getParameterByName("user")
            );
        }
      });
  }

  render() {
    return (
      <div>
        {this.state.noError ? (
          // <Loading text={'Verifying Account'} />
          <div style={{height:'100vh',width:'100%',background:'var(--navy)'}}></div>
        ) : (
          <div className="Loading">
            <ErrorIcon style={{ color: "#fff" }} className="loadingIcon" />
            <Typography className="loadingText">
              {this.state.errorMessage} <br />
              <br />
              <Link to="/login">
                <Button
                  variant="contained"
                  color="secondary"
                  style={{
                    width: "100%",
                  }}
                >
                  Back to Login
                </Button>
              </Link>
            </Typography>
          </div>
        )}
      </div>
    );
  }
}
