import { API } from "aws-amplify";
  
export const querySelfDeliveryAccount = async () => {
    try{
            const account = (await API.get(
            "selfdelivery",
            "/self-delivery",
            undefined
            ));
            console.log(account);
            return account;
    } catch(e){
        return null;
    }
  };

export const createSelfDeliveryAccount = async ({origin_center, radius_km, contact_email, contact_phone, state} :any) => {
    try{
        const account = (await API.post(
            "selfdelivery",
            "/self-delivery",
            {
                body: {
                    origin_center,
                    radius_km,
                    contact_email,
                    contact_phone,
                    state
                }
            }
        ));
        return account;
    } catch(e){
        console.log(e);
        return null;
    }
};

export const updateSelfDeliveryAccount = async ({origin_center, radius_km, contact_email, contact_phone, enabled} :any) => {
    try{
        const account = (await API.post(
            "selfdelivery",
            "/self-delivery",
            {
                body: {
                    origin_center,
                    radius_km,
                    contact_email,
                    contact_phone,
                    enabled
                }
            }
        ));
        console.log(account);
        return account;
    } catch(e){
        return {};
    }
};