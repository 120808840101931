/* eslint-disable react/no-multi-comp */

import {
  CircularProgress,
  Collapse,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import blue from "@material-ui/core/colors/blue";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { WarningOutlined } from "@material-ui/icons";
import { API, Auth } from "aws-amplify";
import $ from "jquery";
import React, { Fragment } from "react";
import CSV from "./../img/spreadsheet.png";

const styles = {
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
};

class UploadAddressesDialog extends React.Component {
  state = {
    error: false,
    open: false,
    postal: "",
    error: false,
    activeStep: 0,
    rows: [],
    inputID: new Date().getTime(),
    senderLocationID:
      this.props.locations && this.props.locations.length > 0
        ? this.props.locations[0].id
        : "",
    senderLocation:
      this.props.locations && this.props.locations.length > 0
        ? this.props.locations[0]
        : "",
  };

  UNSAFE_componentWillMount = () => {
    // const socket2 = new WebSocket('wss://keuhz93lxj.execute-api.us-east-1.amazonaws.com/dev');
    // socket2.addEventListener('open', function (event) {
    //     socket2.send(JSON.stringify({'action':'sendMessage'}));
    //     // alert('connected')
    //   });
    // socket2.addEventListener('message', event => {
    //     // alert('socket msg');
    //     // console.log(JSON.parse(event.data));
    //     let data = JSON.parse(event.data);
    //     if(data.connectionId){
    //         // alert(data.connectionId)
    //         this.setState({connection_id: data.connectionId})
    //         // console.log("CONNECTED, ",data.connectionId)
    //     }
    //     // if(data.failed_row){
    //     //   this.
    //     // }
    //     // if(data.success_row){
    //     // }
    // })
  };

  componentWillUnmount = () => {
    this.setState({ isLoading: false });
  };

  handleListItemClick = (value) => {
    this.props.onClose(value);
  };

  handleChange = (name) => (event) => {
    let tempString = event.target.value;
    this.setState({ [name]: tempString.toUpperCase() });
  };

  handleLocationChange = (e) => {
    // alert('trying')
    this.setState({ senderLocation: e.location });
    this.setState({ senderLocationID: e.id });
  };

  getFileData = (e) => {
    this.setState({ rows: [] });

    if (e.target.files && e.target.files[0]) {
      this.setState({ errors: [] });

      var FR = new FileReader();

      // this.setState({isLoading: true});

      FR.addEventListener("load", async (e) => {
        this.setState({ error: false });

        var temp = e.target.result.replace(/^data*.*base64,/gm, "");
        temp = atob(temp);
        const csv = require("csvtojson");
        csv()
          .fromString(temp)
          .then((csvRow) => {
            // console.log(csvRow)
            let errors = [];

            if (csvRow.length > 501) {
              errors.push("File cannot exceed 500 data rows");
            }

            if (
              !csvRow[0].location_note ||
              !csvRow[0].email ||
              !csvRow[0].phone ||
              !csvRow[0].first_name ||
              !csvRow[0].last_name ||
              !csvRow[0].location_name ||
              !csvRow[0].open_time ||
              !csvRow[0].close_time ||
              !csvRow[0].address1 ||
              !csvRow[0].address2 ||
              !csvRow[0].city ||
              !csvRow[0].state ||
              !csvRow[0].postal ||
              !csvRow[0].country
            ) {
              errors.push("Header row is invalid on line 0");
            }

            try {
              csvRow.map((f, count) => {
                if (errors.length == 0) {
                  let cleanRows = csvRow.map((f) => {
                    let item = {
                      location_note: f.location_note
                        ? f.location_note.trim().substr(0, 50).toUpperCase()
                        : "",
                      email: f.email
                        ? f.email.trim().substr(0, 50).toUpperCase()
                        : "",
                      phone: f.phone
                        ? f.phone.trim().substr(0, 50).toUpperCase()
                        : "",
                      first_name: f.first_name
                        ? f.first_name.trim().substr(0, 50).toUpperCase()
                        : "",
                      last_name: f.last_name
                        ? f.last_name.trim().substr(0, 50).toUpperCase()
                        : "",
                      location_name: f.location_name
                        ? f.location_name.trim().substr(0, 50).toUpperCase()
                        : "",
                      open_time: f.open_time
                        ? f.open_time.trim().substr(0, 50).toUpperCase()
                        : "",
                      close_time: f.close_time
                        ? f.close_time.trim().substr(0, 50).toUpperCase()
                        : "",
                      address1: f.address1
                        ? f.address1.trim().substr(0, 50).toUpperCase()
                        : "",
                      address2: f.address2
                        ? f.address2.trim().substr(0, 50).toUpperCase()
                        : "",
                      city: f.city
                        ? f.city.trim().substr(0, 50).toUpperCase()
                        : "",
                      state: f.state
                        ? f.state.trim().substr(0, 50).toUpperCase()
                        : "",
                      postal: f.postal
                        ? f.postal.trim().substr(0, 50).toUpperCase()
                        : "",
                      country: f.country
                        ? f.country.trim().substr(0, 50).toUpperCase()
                        : "",
                    };
                    return item;
                  });
                  this.setState({
                    rows: cleanRows,
                    inputID: new Date().getTime(),
                  });
                  this.props.increaseUploadStep();
                } else {
                  this.setState({ rows: [] });
                  this.setState({
                    errors: errors,
                    inputID: new Date().getTime(),
                  });
                  this.setState({ error: true });
                }
              });
            } catch (e) {
              this.setState({ errors: errors, inputID: new Date().getTime() });
              this.setState({ error: true });
            }
          });
      });
      FR.readAsDataURL(e.target.files[0]);
    }
  };

  importOrders = async (rows) => {
    this.setState({ isLoading: true });
    let email = await Auth.currentSession();
    // console.log(email.idToken.payload.email);
    API.post("addressbook", "/import", {
      body: {
        addresses: rows,
      },
    })
      .then((e) => {
        console.log(e);
        alert("success");
        this.setState({ isLoading: false });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
        this.setState({ error: true });
        alert(
          "There was an error uploading your file. Please try again later."
        );
      });
  };

  handleBack = () => {
    // alert('trying to bak')
  };

  render() {
    return (
      <div>
        <input
          style={{
            zIndex: "-2",
            opacity: "0",
            position: "fixed",
            top: -300,
            left: -500,
          }}
          accept=".csv"
          id="contained-button-file"
          key={this.state.inputID}
          type="file"
          onChange={(e) => {
            this.getFileData(e);
          }}
        />
        <Dialog
          open={this.props.open}
          // onClose={()=>{if(!(this.state.isLoading)){this.props.handleClose()}}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {this.state.isLoading ? (
            <Fragment>
              <DialogContent style={{ padding: "20px 50px" }}>
                <center>
                  {!this.props.upload_chunk_count && (
                    <CircularProgress width="20" />
                  )}
                  <Typography variant="h1">Importing Orders</Typography>
                  {!this.props.upload_chunk_count ? (
                    <small style={{ color: "rgba(0,0,0,.3)" }}>
                      PLEASE WAIT
                    </small>
                  ) : (
                    <>
                      <small style={{ color: "rgba(0,0,0,.3)" }}>
                        PROCESSING FILE
                      </small>
                      <br />
                      <br />
                      <LinearProgress
                        variant="determinate"
                        value={
                          (this.props.completed /
                            this.props.upload_chunk_count) *
                          100
                        }
                      />
                      <br />
                      <small>
                        <strong style={{ color: "var(--brand-cyan)" }}>
                          {Math.ceil(
                            (this.props.completed /
                              this.props.upload_chunk_count) *
                              100
                          )}
                          %
                        </strong>
                      </small>
                    </>
                  )}
                </center>
              </DialogContent>
            </Fragment>
          ) : this.props.uploadStep == 0 ? (
            <Fragment>
              <DialogContent style={{ padding: "20px 50px" }}>
                <img
                  src={CSV}
                  style={{
                    padding: "25px 30px 15px 0px",
                    marginTop: "15px",
                    marginBottom: "10px",
                    maxWidth: "25%",
                    float: "left",
                  }}
                />
                <small
                  style={{
                    color: "var(--brand-cyan)",
                    textTransform: "uppercase",
                  }}
                >
                  <strong>
                    <br />
                    Import Addresses from a File
                  </strong>
                </small>
                <br />
                <br />
                <small>
                  You can import addresses directly into Swiftpost by following{" "}
                  <a
                    style={{
                      color: "var(--brand-cyan)",
                      textDecoration: "underline",
                      fontWeight: "bold",
                    }}
                    href="https://images.swiftpost.com/csv/Swiftpost+Import+Orders+Template.csv"
                    target="_blank"
                    rel="noreferrer"
                  >
                    this CSV template
                  </a>
                  .<br />
                </small>
                {/* <Button fullWidth variant="contained" color="secondary" onClick={()=>$('input[type=file]').trigger('click')}>Import multiple accounts</Button> */}
                <br />
                <br />
                {this.state.error ? (
                  <Collapse in={this.state.error}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        background: "rgba(255,0,0,.1)",
                        padding: "10px 25px",
                        borderRadius: "5px",
                        color: "red",
                        fontSize: ".9em",
                      }}
                    >
                      <WarningOutlined
                        style={{ float: "left", marginRight: "15px" }}
                      />{" "}
                      <div>
                        <strong>Unable to process file</strong>
                        <br />
                        {this.state.errors.map((e) => (
                          <li>{e}</li>
                        ))}
                      </div>
                    </div>
                  </Collapse>
                ) : (
                  ``
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  onClick={() => $("input[type=file]").trigger("click")}
                >
                  Import&nbsp;File
                </Button>
                <Button onClick={this.props.handleClose} color="default">
                  Cancel
                </Button>
              </DialogActions>
            </Fragment>
          ) : (
            <Fragment>
              <DialogContent style={{ padding: "20px 50px" }}>
                <small
                  style={{
                    color: "var(--brand-cyan)",
                    textTransform: "uppercase",
                  }}
                >
                  <strong>
                    <br />
                    Select Sender Location
                  </strong>
                </small>
                <br />
                <br />
                <br />
                {/* <LocationPicker
                  senderLocationID={this.state.senderLocationID}
                  account={this.props.account}
                  showButtons={false}
                  locations={this.props.locations}
                  handleLocationChange={(e) => {
                    this.handleLocationChange(e);
                  }}
                  handleBack={(e) => this.handleBack(e)}
                /> */}
                <Collapse in={this.state.error}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      background: "rgba(255,0,0,.1)",
                      padding: "10px 25px",
                      borderRadius: "5px",
                      color: "red",
                      fontSize: ".9em",
                    }}
                  >
                    <WarningOutlined
                      style={{ float: "left", marginRight: "15px" }}
                    />{" "}
                    <div>
                      <strong>Unable to process your file</strong>
                      <br />
                      <small>
                        Please confirm that your selected file is formatted
                        correctly according to our{" "}
                        <a href="#">CSV Import Template</a>
                      </small>
                    </div>
                  </div>
                </Collapse>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  onClick={() => this.importOrders(this.state.rows)}
                >
                  Import orders ({this.state.rows.length} rows)
                </Button>
                <Button
                  onClick={() => {
                    this.props.handleClose();
                  }}
                  color="default"
                >
                  Cancel
                </Button>
              </DialogActions>
            </Fragment>
          )}
        </Dialog>
      </div>
    );
  }
}

export default UploadAddressesDialog;
