/* eslint-disable react/no-multi-comp */

import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import PersonIcon from "@material-ui/icons/LocationOn";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import blue from "@material-ui/core/colors/blue";
import FindAddress from "./FindAddress";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";

const styles = (theme) => ({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2,
  },
});

class SimpleDialog extends React.Component {
  state = {
    reference: "",
  };

  render() {
    const { classes, onClose, selectedValue, ...other } = this.props;
    return (
      <Dialog
        onClose={() => this.props.onClose(-1)}
        aria-labelledby="simple-dialog-title"
        {...other}
      >
        <DialogTitle id="simple-dialog-title">Select a location</DialogTitle>
        <div>
          <List>
            {this.props.children
              .sort(function (a, b) {
                return a.business[0]
                  .toLowerCase()
                  .localeCompare(b.business[0].toLowerCase());
              })
              .map((child, i) => (
                <ListItem
                  button
                  onClick={() =>
                    this.props.onClose({ id: child.id, child: child })
                  }
                  key={i}
                >
                  <ListItemAvatar>
                    <Avatar className={classes.avatar}>
                      <PersonIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={child.business}
                    secondary={`${child.unit ? `${child.unit} ` : ``}${
                      child.street_number
                    } ${child.street_name}. ${child.city}, ${child.state} ${
                      child.postal
                    }`}
                  />
                </ListItem>
              ))}
          </List>
        </div>
      </Dialog>
    );
  }
}

SimpleDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  selectedValue: PropTypes.string,
};

const SimpleDialogWrapped = withStyles(styles)(SimpleDialog);

class ChildPicker extends React.Component {
  state = {
    open: false,
    selectedValue: "",
    modalOpen: false,
  };

  UNSAFE_componentWillMount = () => {
    // console.log(this.props)
  };

  handleClickOpen = () => {
    if (this.props.children.length === 0) {
      this.props.history.push("/child-accounts");
    }
    this.setState({
      open: true,
    });
  };

  handleClose = (value) => {
    // console.log(value)
    if (value != -1) {
      this.props.handleChildChange(value);
    }
    this.setState({ open: false });
  };

  handleModalClose = () => {
    this.setState({ modalOpen: false });
  };

  handleModalOpen = () => {
    this.setState({ modalOpen: true });
  };

  render() {
    // alert(this.props.childLocationID)
    const { classes } = this.props;
    // var childId = 0
    // if(this.props.children.length > 0){
    //   childId = this.props.children[0].id
    // } else{
    //   childId = this.props.childLocationID
    // }
    // console.log(locationID)
    let sample = this.props.childLocation;

    // console.log(sample)

    return (
      <div>
        {this.props.children.length > 0 ? (
          <Button
            onClick={this.handleClickOpen}
            variant={this.props.children.length > 0 ? "outlined" : "contained"}
            color="primary"
          >
            Select{" "}
            {this.props.account &&
            this.props.account.user &&
            this.props.account.user.childType
              ? this.props.account.user.childType
              : `Child`}
          </Button>
        ) : (
          <Link to="/child-accounts">
            <Button
              variant={
                this.props.children.length > 0 ? "outlined" : "contained"
              }
              color="primary"
            >
              Create{" "}
              {this.props.account &&
              this.props.account.user &&
              this.props.account.user.childType
                ? this.props.account.user.childType
                : `Child`}{" "}
              Account
            </Button>
          </Link>
        )}
        <br />
        <br />
        {this.props.children.length > 0 ? (
          <Typography variant="subheading">
            <strong>
              {this.props.account &&
              this.props.account.user &&
              this.props.account.user.childType
                ? this.props.account.user.childType
                : `Child`}{" "}
              Selected:
            </strong>
            <br />
            {sample.business}
            <br />
            {sample.fname} {sample.lname}
            <br />
            {sample.unit && <span>#{sample.unit}&nbsp;-&nbsp;</span>}
            {sample.street_number} {sample.street_name}
            <br />
            {sample.city}, {sample.state} {sample.postal}
            <br />
          </Typography>
        ) : (
          <span>
            No{" "}
            {this.props.account &&
            this.props.account.user &&
            this.props.account.user.childType
              ? this.props.account.user.childType
              : `Child`}{" "}
            accounts added, create one by clicking the button above.
            <br />
          </span>
        )}
        <br />
        <SimpleDialogWrapped
          account={this.props.account}
          children={this.props.children}
          selectedValue={this.props.childLocation}
          open={this.state.open}
          onClose={(e) => this.handleClose(e)}
          handleModalOpen={() => this.handleModalOpen()}
          {...this.props}
        />
        <br />
        <br />
        {this.props.showButtons && (
          <div className={classes.actionsContainer}>
            <div>
              {this.props.hideBack ? (
                ""
              ) : (
                <Button
                  onClick={() => this.props.handleBack("activeStep")}
                  className={classes.button}
                  disabled={true}
                >
                  Back
                </Button>
              )}
              <Button
                disabled={this.props.children.length == 0}
                variant="contained"
                color="primary"
                onClick={() => {
                  this.props.handleNext("activeStep");
                }}
                className={classes.button}
              >
                Next
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ChildPicker;
