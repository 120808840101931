import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";

class AlertDialog extends React.Component {
  handleClose = () => {
    this.props.handleClose();
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        //   onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Successully connected!"}
        </DialogTitle>
        <DialogContent>
          {this.props.platform == "ETSY" ? (
            <DialogContentText id="alert-dialog-description">
              Pull unshipped Etsy orders into 'My Orders' by clicking the Etsy
              badge&nbsp;in&nbsp;the&nbsp;Swiftpost&nbsp;header.
              <br />
              <br />
              <img
                src="https://images.swiftpost.com/ecommerce-integrations/etsyhelp-refresh.png"
                width="100%"
                alt="Etsy Refresh Button"
              />
              <br />
              <br />
              Upon checkout, the tracking number will be sent back to your
              e-commerce platform, and the order will be marked as shipped.
              <br />
              <br />
            </DialogContentText>
          ) : this.props.platform == "SHOPIFY" ? (
            <DialogContentText id="alert-dialog-description">
              Pull unshipped Shopify orders into 'My Orders' by clicking the
              Shopify badge&nbsp;in&nbsp;the&nbsp;Swiftpost&nbsp;header.
              <br />
              <br />
              <img
                src="https://images.swiftpost.com/ecommerce-integrations/shopifyhelp-refresh.png"
                width="100%"
                alt="Shopify Refresh Button"
              />
              <br />
              <br />
              Upon checkout,{" "}
              <strong>
                all orders imported from Shopify will be automatically fulfilled
                by the location{" "}
                <u style={{ color: "var(--brand-cyan)" }}>
                  {this.props.fulfillment}
                </u>{" "}
                in Shopify
              </strong>
              , along with the tracking number and corresponding tracking link.
              <br />
              <br />
            </DialogContentText>
          ) : (
            <DialogContentText id="alert-dialog-description">
              Pull pending WooCommerce orders into 'My Orders' by clicking the
              WooComerce badge&nbsp;in&nbsp;the&nbsp;Swiftpost&nbsp;header.
              <br />
              <br />
              <img
                src="https://images.swiftpost.com/ecommerce-integrations/woocommercehelp-refresh.png"
                width="100%"
                alt="WooCommerce Refresh Button"
              />
              <br />
              <br />
              Upon checkout,{" "}
              <strong>
                all orders imported from WooCommerce will be automatically
                marked as completed
              </strong>
              .<br />
              <br />
              <br />* If you are using the{" "}
              <strong>
                <a
                  style={{ color: "var(--brand-cyan)" }}
                  href="https://woocommerce.com/products/shipment-tracking/"
                  target="_blank"
                  rel="noreferrer"
                >
                  WooCommerce Shipment Tracking Extension
                </a>
              </strong>
              , tracking numbers and tracking links will be automatically
              populated in WooCommerce prior to marking the order as completed.
              <br />
              <br />
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default AlertDialog;
