import Button from "@material-ui/core/Button";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { API, graphqlOperation } from "aws-amplify";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import React from "react";
import BalanceButton from "../../components/BalanceButton";
import LoadingIcon from "../../components/loading/LoadingIcon";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 220,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  table: {
    minWidth: 700,
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginLeft: theme.spacing.unit * 3,
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2,
  },
  resetContainer: {
    padding: theme.spacing.unit * 3,
  },
  progress: {
    margin: theme.spacing.unit * 2,
    marginLeft: "20px",
  },
});

class SimpleSelect extends React.Component {
  state = {
    age: "0",
    name: "hai",
    isLoading: true,
    qualify: false,
    diff: 0,
  };

  qualify = false;

  UNSAFE_componentWillMount = async () => {
    let language = getCookie("language");
    this.setState({ language: language != "" ? language : "EN" });
    this.checkQualify();
  };

  language = {
    Checkingcurrentbalance: {
      FR: "Vérification du solde de votre compte",
      EN: "Checking current balance",
    },
    InsufficientFunds: {
      FR: "Fonds insuffisants, veuillez en ajouter d'autres ci-dessous pour continuer.",
      EN: "Insufficient funds, please add more below to continue.",
    },
    SufficientFunds: {
      FR: "Vous disposez actuellement de fonds suffisants pour le paiement.",
      EN: "You currently have sufficient funds for payment.",
    },
    Back: {
      FR: "Arrière",
      EN: "Back",
    },
    Continue: {
      FR: "Continuer",
      EN: "Continue",
    },
  };

  checkQualify = async () => {
    const balance = await API.graphql(
      graphqlOperation(`query listBalances {
      listV1_Balances {
        items{
          id
          balance
          cognitoUsername
        }
      }
    }`)
    );
    // console.log(balance.data.listV1_Balances.items[0].balance)
    if (
      Number(balance.data.listV1_Balances.items[0].balance) >=
      Number(this.props.runningTotal)
    ) {
      // alert("qualify")
      this.setState({ isLoading: false });
      this.setState({ qualify: true });
      this.qualify = true;
    } else {
      this.setState({
        diff: this.roundTo(
          Number(this.props.runningTotal) -
            Number(balance.data.listV1_Balances.items[0].balance),
          2
        ),
      });
      this.setState({ isLoading: false });
      this.setState({ qualify: false });
      this.qualify = false;
      this.qualify = false;
    }
  };

  roundTo = (n, digits) => {
    var negative = false;
    if (digits === undefined) {
      digits = 0;
    }
    if (n < 0) {
      negative = true;
      n = n * -1;
    }
    var multiplicator = Math.pow(10, digits);
    n = parseFloat((n * multiplicator).toFixed(11));
    n = (Math.round(n) / multiplicator).toFixed(2);
    if (negative) {
      n = (n * -1).toFixed(2);
    }
    return n;
  };

  handleChange = (event) => {
    // this.setState({ [event.target.name]: event.target.value });
    // console.log(event.target.value)
    this.props.setData("card", event.target.value);
  };

  render() {
    const { classes, ...childProps } = this.props;
    // alert(this.props.paymentmethods)
    return (
      <Grow in={true}>
        <Paper className={classes.root}>
          {this.state.isLoading ? (
            <div style={{ padding: "40px" }}>
              <Typography component="h4">
                {this.language.Checkingcurrentbalance[this.state.language]}
              </Typography>
              <br />
              <LoadingIcon size={40} />
            </div>
          ) : (
            <form autoComplete="off" style={{ padding: "40px" }}>
              {this.state.qualify === false ? (
                <Typography component="h4">
                  {this.language.InsufficientFunds[this.state.language]}
                  <br />
                  <br />
                </Typography>
              ) : (
                <Typography component="h4">
                  {this.language.SufficientFunds[this.state.language]}
                  <br />
                  <br />
                </Typography>
              )}
              {/* <TopUpStepper {...this.props} paymentmethods={this.props.paymentmethods} diff={this.state.diff} user={this.props.user} qualify={this.checkQualify} addPayment={this.props.addPayment} handleUserData={this.props.handleUserData} /> */}
              <BalanceButton
                createSnackBar={this.props.createSnackBar}
                checkQualify={() => this.checkQualify()}
                diff={this.state.diff}
                account={this.props.account}
                paymentmethods={this.props.paymentmethods}
                balance={this.props.balance}
                user={this.props.user}
                addPayment={this.props.addPayment}
                handleUserData={this.props.handleUserData}
              />
            </form>
          )}
          <br />
          <div className={classes.actionsContainer}>
            <div>
              <Button
                onClick={this.props.handleBack}
                className={classes.button}
              >
                {this.language.Back[this.state.language]}
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={!this.state.qualify}
                onClick={() => this.props.handleNext()}
                className={classes.button}
              >
                {this.language.Continue[this.state.language]}
              </Button>
            </div>
          </div>
        </Paper>
      </Grow>
    );
  }
}

SimpleSelect.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleSelect);
