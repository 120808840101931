import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Theme,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { ReactNode, useEffect, useState } from "react";

const styles = (theme: Theme) => ({
  closeButton: {
    position: "absolute" as const,
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
});

function AddressBookDialog(props: {
  open: boolean;
  title?: string;
  onClose?: () => void;
  classes: any;
  children: ReactNode;
}) {
  const { classes, onClose, children } = props;
  const [open, setOpen] = useState(props.open);

  useEffect(() => setOpen(props.open), [props.open]);

  const handleClose = () => {
    setOpen(false);
    onClose?.();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      disableBackdropClick
    >
      {props.title && (
        <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
      )}

      <IconButton
        aria-label="Close"
        className={classes.closeButton}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}

export default withStyles(styles)(AddressBookDialog);
