import { Button } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { Edit, WarningOutlined } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { API, graphqlOperation } from "aws-amplify";
import classNames from "classnames";
import { getCookie } from "helpers/getCookie";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import DeleteInterstitial from "../../components/DeleteInterstitial";
import FlashEdit from "../../experimental/FlashEdit";
import PackingSlipModal from "../../experimental/PackingSlipModal";
import { round } from "../../helpers/TaskRunners";
import Small_WooCommerce from "../../img/woocommerce_logo_small.png";

let counter = 0;
function createData(
  order_id,
  date,
  ready,
  id,
  name,
  receiver,
  sender,
  service,
  cost
) {
  counter += 1;
  if (cost === "NOACCESS") {
    return { order_id, id: id, name, receiver, sender, service, ready, date };
  }
  return {
    order_id,
    id: id,
    name,
    receiver,
    sender,
    service,
    cost,
    ready,
    date,
  };
}

function desc(a, b, orderBy) {
  // console.log("A", a, b, orderBy)
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  // console.log(array)
  const stabilizedThis = array.map((el, index) => [el, index]);
  // console.log(stabilizedThis);
  stabilizedThis.sort((a, b) => {
    // console.log(a[0], b[0])
    const order = cmp(a[0].order_id, b[0].order_id);
    // console.log(order)
    if (order !== 0) return order;
    // console.log(a[0].order_id, b[0].order_id)
    // console.log(a[0].order_id > b[0].order_id)
    return a[0].order_id > b[0].order_id;
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

class EnhancedTableHead extends React.Component {
  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const language = {
      Order: {
        FR: "Numéro de Commande",
        EN: "Orders",
      },
      Receiver: {
        FR: "Récepteur",
        EN: "Receiver",
      },
      Sender: {
        FR: "Expéditeur",
        EN: "Sender",
      },
      Service: {
        FR: "Service",
        EN: "Service",
      },
      Cost: {
        FR: "Coût (CAD)",
        EN: "Cost (CAD)",
      },
    };
    //   const language = [
    //     {
    //     Order: {
    //       FR:"Numéro de Commande",
    //       EN:"Orders"
    //   },
    //   Receiver: {
    //       FR:"",
    //       EN:"Receiver"
    //   },
    //   Sender: {
    //       FR:"",
    //       EN:"Sender"
    //   },
    //   Service: {
    //       FR:"",
    //       EN:"Service"
    //   },
    //   Cost: {
    //       FR:"",
    //       EN:"Cost (CAD)"
    //   }
    // ];
    const test = [];
    const rows = [
      {
        id: "name",
        numeric: false,
        disablePadding: true,
        label: language.Order[this.props.language],
      },
      {
        id: "receiver",
        numeric: true,
        disablePadding: false,
        label: language.Receiver[this.props.language],
      },
      {
        id: "sender",
        numeric: true,
        disablePadding: false,
        label: language.Sender[this.props.language],
      },
      {
        id: "service",
        numeric: true,
        disablePadding: false,
        label: language.Service[this.props.language],
      },
      // {
      //   id: "cost",
      //   numeric: true,
      //   disablePadding: false,
      //   label: language.Cost[this.props.language],
      // },
    ];
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      selected,
      row_ids,
    } = this.props;

    // console.log(orderBy);
    const newRows =
      this.props.account &&
      this.props.account.user &&
      this.props.account.user.hideBilling
        ? rows.map((e) => e.id !== "cost")
        : rows;
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={
                selected.filter((e) => row_ids.indexOf(e) > -1).length ===
                rowCount
              }
              onChange={onSelectAllClick}
            />
          </TableCell>
          {newRows.map(
            (row) => (
              <TableCell
                key={row.id}
                align={"left"}
                padding={row.disablePadding ? "none" : "default"}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  selected: PropTypes.array.isRequired,
  row_ids: PropTypes.array.isRequired,
};

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
    minWidth: "150px",
  },
  title: {
    flex: "0 0 auto",
  },
});

let EnhancedTableToolbar = (props) => {
  const {
    numSelected,
    items,
    classes,
    count,
    shipments,
    goToPayAndShip,
    deleteShipments,
    modalOpen,
    language,
  } = props;
  // console.log(shipments)
  const text = {
    PendingOrders: {
      FR: "Les Commandes en Attente",
      EN: "Pending Orders",
    },
    Selected: {
      FR: "sélectionné",
      EN: "selected",
    },
  };
  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} {text.PendingOrders[language]}{" "}
            {numSelected === 50 && <strong>(Maximum selected)</strong>}
          </Typography>
        ) : (
          <Fragment>
            <Typography variant="h6" id="tableTitle">
              {text.PendingOrders[language]} ({count})
              {/* <Hidden smDown>
              <Paper
                style={{
                  float: "right",
                  padding: "0px 4px",
                  display: "flex",
                  alignItems: "center",
                  maxWidth: 400,
                  height: "35px"
                }}
                elevation={1}
              >
                <InputBase
                  value={this.state.search}
                  onChange={this.handleChange("search")}
                  style={{
                    marginLeft: 8,
                    flex: 1,
                    width:'auto'
                  }}
                  placeholder="Search Shipments"
                  onKeyPress={event => {
                    if (event.key === "Enter") {
                      this.setState({ isSearching: true });
                      this.renderShipments(0);
                    }
                  }}
                />

                {this.state.search !== "" && !this.state.isSearching ? (
                  <IconButton
                    color="secondary"
                    style={{ padding: 5 }}
                    aria-label="Clear Search"
                    onClick={() => {
                      this.setState({ search: "" });
                      this.clearSearchAndRender();
                    }}
                  >
                    <CloseOutlined />
                  </IconButton>
                ) : (
                  ""
                )}

                <Divider className={{ width: 1, height: 23, margin: 4 }} />

                {!this.state.isSearching ? (
                  <IconButton
                    style={{ padding: 5 }}
                    aria-label="Search"
                    onClick={() => {
                      this.setState({ isSearching: true });
                      this.renderShipments(0);
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                ) : (
                  <LoadingIcon size={20} style={{ marginRight: "5px" }} />
                )}
              </Paper></Hidden> */}
              {/* <Tooltip title="Upload CSV">
                <Button onClick={modalOpen}
                  style={{ marginLeft:'40px' }}
                  color="secondary"
                  className={classes.button}
                >
                  <NoteAdd /> Upload CSV
                </Button>
                </Tooltip> */}
            </Typography>

            {/* <Hidden mdUp>
              <div style={{
                background:'var(--brand-cyan)',
                padding:'0 20px 20px 20px',
                boxSizing:'border-box'
              }}>
              <Paper
                style={{
                  padding: "0px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: '100%',
                  boxSizing:'border-box',
                  height: "35px"
                }}
                elevation={1}
              >
                <InputBase
                  value={this.state.search}
                  onChange={this.handleChange("search")}
                  style={{
                    marginLeft: 8,
                    flex: 1,
                    width:'auto'
                  }}
                  placeholder="Search Shipments"
                  onKeyPress={event => {
                    if (event.key === "Enter") {
                      this.setState({ isSearching: true });
                      this.renderShipments(0);
                    }
                  }}
                />

                {this.state.search !== "" && !this.state.isSearching ? (
                  <IconButton
                    color="secondary"
                    style={{ padding: 5 }}
                    aria-label="Clear Search"
                    onClick={() => {
                      this.setState({ search: "" });
                      this.clearSearchAndRender();
                    }}
                  >
                    <CloseOutlined />
                  </IconButton>
                ) : (
                  ""
                )}

                <Divider className={{ width: 1, height: 23, margin: 4 }} />

                {!this.state.isSearching ? (
                  <IconButton
                    style={{ padding: 5 }}
                    aria-label="Search"
                    onClick={() => {
                      this.setState({ isSearching: true });
                      this.renderShipments(0);
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                ) : (
                  <LoadingIcon size={20} style={{ marginRight: "5px" }} />
                )}
              </Paper></div></Hidden> */}
          </Fragment>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected === 1 ? (
          <Link
            to={`/edit/ca/${items[0]}`}
          >
            <Tooltip title="Edit">
              <IconButton aria-label="Edit" style={{ display: "inline-block" }}>
                <Edit />
              </IconButton>
            </Tooltip>
          </Link>
        ) : (
          <span />
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton
              aria-label="Delete"
              onClick={deleteShipments}
              style={{ display: "inline-block", float: "right" }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <span />
        )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  shipments: PropTypes.array.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    [theme.breakpoints.down("md")]: {
      width: "calc(100vw - 45px)",
    },
    [theme.breakpoints.up("md")]: {
      width: "auto",
    },
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: "auto",
  },
});

class OrderTable extends React.Component {
  state = {
    order: "desc",
    orderBy: "order_id",
    selected: [],
    data: "",
    page: 0,
    rowsPerPage: 50,
    modalOpen: false,
    language: "",
    deleteInterstitial: false,
  };

  UNSAFE_componentWillMount = () => {
    let language = getCookie("language");
    // this.setState({language: language != "" ? language : "EN"});ƒ
    this.setState({
      language: language != "" ? language : "EN",
      data: this.props.data.map((e) =>
        createData(
          e.reference,
          new Date(e.date_created),
          Number(e?.rate?.service?.amount) > 0,
          e.id,
          e.platform === "ETSY" ? (
            <span>
              <Tooltip
                title={`Imported from Etsy on ${new Date(
                  e.date_created
                ).toDateString()}`}
              >
                <img
                  src="https://images.swiftpost.com/ecommerce-integrations/etsy_logo.png"
                  style={{
                    verticalAlign: "bottom",
                    height: "15px",
                    borderRadius: "50%",
                    width: "15px",
                    marginRight: "5px",
                  }}
                />
              </Tooltip>
              &nbsp;{e.reference}
            </span>
          ) : e.platform === "SHOPIFY" ? (
            <span>
              <Tooltip
                title={`Imported from Shopify on ${new Date(
                  e.date_created
                ).toDateString()}`}
              >
                <img
                  src="https://images.swiftpost.com/ecommerce-integrations/shopify_logo.png"
                  style={{
                    verticalAlign: "bottom",
                    height: "15px",
                    borderRadius: "50%",
                    width: "15px",
                    marginRight: "5px",
                  }}
                />
              </Tooltip>
              &nbsp;{e.reference}
              {this.props.account.user.shopifyAutofill ? (
                <>
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <small
                    onClick={(event) => {
                      event.stopPropagation();
                      this.setState({ viewPackingSlip: e });
                    }}
                    style={{
                      cursor: "pointer",
                      color: "var(--brand-cyan)",
                      fontWeight: "bold",
                    }}
                  >
                    VIEW PACKING SLIP
                  </small>
                </>
              ) : (
                ""
              )}
            </span>
          ) : e.platform === "WOOCOMMERCE" ? (
            <span>
              <Tooltip
                title={`Imported from WooCommerce on ${new Date(
                  e.date_created
                ).toDateString()}`}
              >
                <img
                  src={Small_WooCommerce}
                  style={{
                    verticalAlign: "bottom",
                    height: "15px",
                    borderRadius: "50%",
                    width: "15px",
                    marginRight: "5px",
                  }}
                />
              </Tooltip>
              &nbsp;{e.reference}
              {this.props.account.user.shopifyAutofill ? (
                <>
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <small
                    onClick={(event) => {
                      event.stopPropagation();
                      this.setState({ viewPackingSlip: e });
                    }}
                    style={{
                      cursor: "pointer",
                      color: "var(--brand-cyan)",
                      fontWeight: "bold",
                    }}
                  >
                    VIEW PACKING SLIP
                  </small>
                </>
              ) : (
                ""
              )}
            </span>
          ) : (
            e.reference
          ),
          <Grid container>
            <span
              style={{
                "text-align": "left",
                color:
                  e.receiver_invalid && e.receiver_invalid.toString() == "true"
                    ? "rgba(255,0,0,.9)"
                    : "rgba(0,0,0,.9)",
              }}
            >
              {e.receiver_invalid && e.receiver_invalid.toString() == "true" ? (
                <span>
                  <Tooltip arrow title={"Address could not be verified"}>
                    <WarningOutlined
                      style={{
                        color: "red",
                        fontSize: ".9em",
                        display: "inline-block",
                      }}
                    />
                  </Tooltip>
                </span>
              ) : (
                ``
              )}{" "}
              {e.receiver.contact.fname} {e.receiver.contact.lname}
              <br />
              <small
                style={{
                  color:
                    e.receiver_invalid &&
                    e.receiver_invalid.toString() == "true"
                      ? "rgba(255,0,0,.5)"
                      : "rgba(0,0,0,.5)",
                }}
              >
                {e.receiver.address.unit ? `${e.receiver.address.unit}, ` : ""}
                {e.receiver.address.street_number
                  ? `${e.receiver.address.street_number} `
                  : ""}
                {e.receiver.address.street_name}, {e.receiver.address.city},{" "}
                {e.receiver.address.country}
              </small>
            </span>
          </Grid>,
          <span style={{ "text-align": "left", color: "rgba(0,0,0,.9)" }}>
            {e.sender.title}
            <br />
            <small style={{ color: "rgba(0,0,0,.5)" }}>
              {e.sender.address.unit ? `${e.sender.address.unit}, ` : ""}
              {e.sender.address.street_number
                ? `${e.sender.address.street_number} `
                : ""}
              {e.sender.address.street_name}, {e.sender.address.city},{" "}
              {e.sender.address.country}
            </small>
          </span>,
          <Fragment>
            {e?.rate?.service?.courier != "TBD" ? (
              <Tooltip
                title={`${e?.rate?.service?.courier?.toUpperCase()} ${
                  e?.rate?.service?.name
                }`}
                placement="top"
              >
                <img
                  alt={"Courier Logo"}
                  src={`https://images.swiftpost.com/logo-${e?.rate?.service?.courier?.toUpperCase()}.png`}
                  style={{ height: "10px", verticalAlign: "middle" }}
                />
              </Tooltip>
            ) : (
              <span>&nbsp;</span>
            )}
            &nbsp;
            {e?.rate?.service?.name != "TBD" ? (
              <>
                <small
                  style={{
                    textTransform: "uppercase",
                    color: "rgba(0,0,0,.5)",
                  }}
                >
                  {e?.rate?.service?.name}
                </small>
                <br />
                <table>
                  <tr>
                    <td style={{ width: "100px" }}>
                      <small
                        style={{
                          "text-align": "left",
                          color: "rgba(0,0,0,.7)",
                          fontWeight: "bold",
                        }}
                      >
                        {e.package.entered_pld.length}&nbsp;BOX
                        {e.package.entered_pld.length > 1 ? `ES` : ``}
                        &nbsp;&nbsp;<span style={{ color: "#000" }}>|</span>
                        &nbsp;&nbsp;
                        {e.package.entered_pld
                          .reduce((a, b) => Number(a) + Number(b.weight), 0)
                          .toFixed(2)}
                        &nbsp;LB&nbsp;&nbsp;
                      </small>
                    </td>
                    <td>
                      <small>
                        &nbsp;&nbsp;
                        {this.props.account.user.shopifyAutofill ? (
                          <span
                            size="small"
                            color="primary"
                            style={{
                              color: "var(--brand-cyan)",
                              fontWeight: "bold",
                              cursor: "pointer",
                            }}
                            onClick={(event) => {
                              event.stopPropagation();
                              this.setState({
                                flashEdit: true,
                                flashEditData: this.props.shipments.filter(
                                  (f) => f.id === e.id
                                )[0],
                              });
                            }}
                          >
                            EDIT
                          </span>
                        ) : (
                          ""
                        )}
                      </small>
                    </td>
                  </tr>
                </table>
              </>
            ) : (
              <Link
                to={`/edit/${e.receiver.address.country.toLowerCase()}/${e.id}`}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ margin: 0, padding: "7px 10px", fontSize: "1em" }}
                >
                  Complete&nbsp;Order
                </Button>
              </Link>
            )}
          </Fragment>,
          this.props.account &&
            this.props.account.user &&
            this.props.account.user.hideBilling
            ? "NOACCESS"
            : e?.rate?.amount
        )
      ),
    });
  };

  language = {
    Order: {
      FR: "Numéro de Commande",
      EN: "Orders",
    },
    Receiver: {
      FR: "",
      EN: "Receiver",
    },
    Sender: {
      FR: "",
      EN: "Sender",
    },
    Service: {
      FR: "",
      EN: "Service",
    },
    Cost: {
      FR: "",
      EN: "Cost (CAD)",
    },
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event) => {
    let items = stableSort(
      this.state.data,
      getSorting(this.state.order, this.state.orderBy)
    )
      .slice(
        this.state.page * this.state.rowsPerPage,
        this.state.page * this.state.rowsPerPage + this.state.rowsPerPage
      )
      .map((n) => n.id);
    if (event.target.checked) {
      if (
        this.state.selected.filter((e) => items.indexOf(e) > -1).length !=
        items.length
      ) {
        if (this.state.selected.length < 50) {
          if (this.state.selected.length > 0) {
            items = items.filter((e) => this.state.selected.indexOf(e) <= -1);
          }
          items = items.concat(this.state.selected);
        } else {
          return;
        }
      } else {
        items = this.state.selected.filter((e) => items.indexOf(e) <= -1);
      }
      this.setState({ selected: items });
      // this.setState(state => ({ selected: state.data.map(n => n.id) }));
      this.props.setSelectedShipments(items);
      return;
    }
    // alert('this')
    this.setState({ selected: [] });
    this.props.setSelectedShipments([]);
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      if (this.state.selected.length < 50) {
        newSelected = newSelected.concat(selected, id);
      } else {
        return;
      }
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    this.props.setSelectedShipments(newSelected);
    this.setState({ selected: newSelected });
    // console.log(newSelected)
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  doDelete = async () => {
    const { selected } = this.state;
    // console.log(this.props.data)
    // console.log("SELECTED",selected)
    // let test = this.props.shipmentList.filter(e=>this.props.selectedShipments.indexOf(e.id)>-1).map(e=>this.props.data[e - 1].id)
    this.setState({ isLoading: true });

    // alert("trying to promise all")
    Promise.all(
      selected.map(
        async (id) =>
          new Promise(async (resolve, reject) => {
            await API.graphql(
              graphqlOperation(`mutation {
          deleteV1_Shipment(input: { id:"${id}" }){
            id
            cognitoUsername
          }
      }`)
            );
            resolve(true);
          })
      )
    );
    this.props.setSelectedShipments([]);
    this.setState({ selected: [] });
    this.setState({ isLoading: false });

    // })
    // await API.graphql(graphqlOperation(`mutation {
    //     deleteV1_Shipment(input: { id:"${id}" }){
    //       id
    //       cognitoUsername
    //     }
    // }`));
    // }))).then(e=>{
    //   this.setState({isLoading:false});
    //   this.forceUpdate();
    // }).catch(e=>{
    //   this.setState({isLoading:false});
    // })
  };

  startUpload = (order_arr) => {
    this.setState({ queueLength: order_arr.length, isLoading: true });
    Array.from(new Set(order_arr.map((e) => e.order_id))).map((e) => {
      order_arr
        .filter((order) => order.order_id)
        .map((order) => {
          return {
            length: order.package_length_in,
            width: order.package_width_in,
            height: order.package_height_in,
            weight: order.package_weight_lb,
            item_description: order.package_contents_title,
            item_quantity: order.package_contents_item_quantity,
            item_value: order.package_contents_total_value_usd,
            item_origin: order.package_contents_origin_country,
          };
        });
    });
  };

  renderRows = () => {
    this.setState({
      data: this.props.data.map((e) =>
        createData(
          e.reference,
          new Date(e.date_created),
          Number(e?.rate?.service?.amount) > 0,
          e.id,
          e.platform === "ETSY" ? (
            <span>
              <Tooltip
                title={`Imported from Etsy on ${new Date(
                  e.date_created
                ).toDateString()}`}
              >
                <img
                  src="https://images.swiftpost.com/ecommerce-integrations/etsy_logo.png"
                  style={{
                    verticalAlign: "bottom",
                    height: "15px",
                    borderRadius: "50%",
                    width: "15px",
                    marginRight: "5px",
                  }}
                />
              </Tooltip>
              &nbsp;{e.reference}
            </span>
          ) : e.platform === "SHOPIFY" ? (
            <span>
              <Tooltip
                title={`Imported from Shopify on ${new Date(
                  e.date_created
                ).toDateString()}`}
              >
                <img
                  src="https://images.swiftpost.com/ecommerce-integrations/shopify_logo.png"
                  style={{
                    verticalAlign: "bottom",
                    height: "15px",
                    borderRadius: "50%",
                    width: "15px",
                    marginRight: "5px",
                  }}
                />
              </Tooltip>
              &nbsp;{e.reference}
            </span>
          ) : (
            e.reference
          ),
          <>
            {e.receiver_invalid == true ? (
              <span>
                <Tooltip title={"Address could not be verified"}>
                  <WarningOutlined style={{ color: "red" }} />
                </Tooltip>
              </span>
            ) : (
              ``
            )}
            <span style={{ "text-align": "left", color: "rgba(0,0,0,.9)" }}>
              {e.receiver.contact.fname} {e.receiver.contact.lname}
              <br />
              <small style={{ color: "rgba(0,0,0,.5)" }}>
                {e.receiver.address.unit ? `${e.receiver.address.unit}, ` : ""}
                {e.receiver.address.street_number
                  ? `${e.receiver.address.street_number} `
                  : ""}
                {e.receiver.address.street_name}, {e.receiver.address.city},{" "}
                {e.receiver.address.country}
              </small>
            </span>
          </>,
          <span style={{ "text-align": "left", color: "rgba(0,0,0,.9)" }}>
            {e.sender.title}
            <br />
            <small style={{ color: "rgba(0,0,0,.5)" }}>
              {e.sender.address.unit ? `${e.sender.address.unit}, ` : ""}
              {e.sender.address.street_number
                ? `${e.sender.address.street_number} `
                : ""}
              {e.sender.address.street_name}, {e.sender.address.city},{" "}
              {e.sender.address.country}
            </small>
          </span>,
          <Fragment>
            {e?.rate?.service?.courier != "TBD" ? (
              <Tooltip
                title={`${e?.rate?.service?.courier?.toUpperCase()} ${
                  e?.rate?.service?.name
                }`}
                placement="top"
              >
                <img
                  alt={"Courier Logo"}
                  src={`https://images.swiftpost.com/logo-${e?.rate?.service?.courier?.toUpperCase()}.png`}
                  style={{ height: "10px", verticalAlign: "middle" }}
                />
              </Tooltip>
            ) : (
              <span>&nbsp;</span>
            )}
            &nbsp;
            {e?.rate?.service?.name != "TBD" ? (
              <>
                <small
                  style={{
                    textTransform: "uppercase",
                    color: "rgba(0,0,0,.5)",
                  }}
                >
                  {e?.rate?.service?.name}
                </small>
                <br />
                <table>
                  <tr>
                    <td style={{ width: "100px" }}>
                      <small
                        style={{
                          "text-align": "left",
                          color: "rgba(0,0,0,.7)",
                          fontWeight: "bold",
                        }}
                      >
                        {e?.package?.entered_pld?.length}&nbsp;BOX
                        {e?.package?.entered_pld?.length > 1 ? `ES` : ``}
                        &nbsp;&nbsp;<span style={{ color: "#000" }}>|</span>
                        &nbsp;&nbsp;
                        {e?.package?.entered_pld
                          .reduce((a, b) => Number(a) + Number(b.weight), 0)
                          .toFixed(2)}
                        &nbsp;LB&nbsp;&nbsp;
                      </small>
                    </td>
                    <td>
                      <small>
                        &nbsp;&nbsp;
                        {this.props.account.user.shopifyAutofill ? (
                          <span
                            size="small"
                            color="primary"
                            style={{
                              color: "var(--brand-cyan)",
                              fontWeight: "bold",
                              cursor: "pointer",
                            }}
                            onClick={(event) => {
                              event.stopPropagation();
                              this.setState({
                                flashEdit: true,
                                flashEditData: this.props.shipments.filter(
                                  (f) => f.id === e.id
                                )[0],
                              });
                            }}
                          >
                            EDIT
                          </span>
                        ) : (
                          ""
                        )}
                      </small>
                    </td>
                  </tr>
                </table>
              </>
            ) : (
              <Link
                to={`/edit/${e.receiver.address.country.toLowerCase()}/${e.id}`}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ margin: 0, padding: "7px 10px", fontSize: "1em" }}
                >
                  Complete&nbsp;Order
                </Button>
              </Link>
            )}
          </Fragment>,
          this.props.account &&
            this.props.account.user &&
            this.props.account.user.hideBilling
            ? "NOACCESS"
            : e?.rate?.amount
        )
      ),
    });
    // this.setState({isLoading:true});
    // setTimeout(()=>this.setState({isLoading:false}));
  };

  render() {
    const { classes } = this.props;
    const { data, order, orderBy, selected, rowsPerPage, page } = this.state;
    // console.log(this.props.shipments)
    return (
      <Paper
        className={classes.root}
        style={{
          overflow: "auto",

          marginTop: "0",
          marginBottom: "75px",
        }}
      >
        {this.state.viewPackingSlip ? (
          <PackingSlipModal
            handleClose={() => this.setState({ viewPackingSlip: null })}
            shipment={this.state.viewPackingSlip}
          />
        ) : (
          ""
        )}
        {this.state.flashEdit ? (
          <FlashEdit
            updateOrders={() => this.renderRows()}
            flashEditData={this.state.flashEditData}
            account={this.props.account}
            open={this.state.flashEdit}
            handleClose={() => {
              this.setState({ flashEdit: !this.state.flashEdit });
            }}
          />
        ) : (
          ""
        )}
        <DeleteInterstitial
          open={this.state.deleteInterstitial}
          handleClose={() => this.setState({ deleteInterstitial: false })}
          doDelete={this.doDelete}
        />
        {!this.state.isLoading && (
          <Fragment>
            <EnhancedTableToolbar
              count={data.length}
              numSelected={selected.length}
              items={selected}
              shipments={this.props.shipments}
              language={this.state.language}
              modalOpen={() => this.props.toggleModal()}
              deleteShipments={() =>
                this.setState({ deleteInterstitial: true })
              }
            />
            <div className={classes.tableWrapper}>
              <Table className={classes.table} aria-labelledby="tableTitle">
                <EnhancedTableHead
                  selected={this.state.selected}
                  row_ids={stableSort(data, getSorting(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((n) => n.id)}
                  language={this.state.language}
                  account={this.props.account}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={this.handleSelectAllClick}
                  onRequestSort={this.handleRequestSort}
                  rowCount={data.length}
                  goToPayAndShip={this.goToPayAndShip}
                />
                <TableBody>
                  {stableSort(data, getSorting(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((n) => {
                      const isSelected = this.isSelected(n.id);
                      return (
                        <TableRow
                          hover
                          onClick={(event) => this.handleClick(event, n.id)}
                          role="checkbox"
                          aria-checked={isSelected}
                          tabIndex={-1}
                          key={n.id}
                          selected={isSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox checked={isSelected} />
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            padding="none"
                            style={{
                              "text-overflow": "ellipsis",
                              "max-width": "200px",
                              "white-space": "nowrap",
                              overflow: "hidden",
                            }}
                          >
                            {n.name}
                          </TableCell>
                          <TableCell align="left">
                            <div
                              style={{
                                "text-overflow": "ellipsis",
                                "max-width": "200px",
                                "white-space": "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {n.receiver}
                            </div>
                          </TableCell>
                          <TableCell align="left">
                            <div
                              style={{
                                "text-overflow": "ellipsis",
                                "max-width": "200px",
                                "white-space": "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {n.sender}
                            </div>
                          </TableCell>
                          <TableCell align="left">{n.service}</TableCell>
                          {/* {this.props.account &&
                          this.props.account.user &&
                          this.props.account.user.hideBilling ? (
                            ""
                          ) : (
                            <TableCell align="left">
                              {Number(n.cost) > 0 ? (
                                `$${round(n.cost)}`
                              ) : (
                                <WarningOutlined
                                  style={{
                                    fontSize: "18px",
                                    color: "rgba(0,0,0,.2)",
                                  }}
                                />
                              )}
                            </TableCell>
                          )} */}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                "aria-label": "Previous Page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page",
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Fragment>
        )}
      </Paper>
    );
  }
}

OrderTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OrderTable);
